import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Modal,
  Button as RButton,
  Spinner,
  Card as BCard,
} from "react-bootstrap";
import { Button, Card, CardAction, IconButton, Badge } from "ui-neumorphism";
import { CartItem } from "components";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { razorpayCheckout, PaypalCheckout } from "utilities";
import * as actions from "actions/AuthActions";
import { Breakpoint, useCurrentBreakpointName } from "react-socks";
import {
  AiOutlineClose,
  AiOutlineCheck,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { IoChevronBack } from "react-icons/io5";
import getSymbolFromCurrency from "currency-symbol-map";
import { useTransition, animated } from "react-spring";
import "core-ui/pages/Cart.scss";
import { emptycart, znanyelogin, greentick, greytick } from "assets";
import { Loader, GSTInformation } from "components";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { FcCheckmark } from "react-icons/fc";
import { Button as BootstrapButton } from "react-bootstrap";

const OrderDetails = (props) => {
  const { setLoginShow } = props;
  const breakpoint = useCurrentBreakpointName();
  const location = useLocation();
  const form = useRef();
  const userForm = useRef(null);
  const orderDetailsView = useRef(null);
  const [changingBorder, setChangingBroder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showGstModal, setShowGstModal] = useState(false); //opens this modal on checkout
  const [checkForm, setCheckForm] = useState(true); //Form 1 on the modal - user input YES/NO
  const [gstForm, setGstForm] = useState(false); //Form 2 on the modal - user input GST details
  const [continueWithoutGst, setContinueWithoutGst] = useState(true);
  const navigate = useNavigate();
  const [cartVisible, setCartVisible] = useState(true);
  const [showPaypalCheckout, setShowPaypalCheckout] = useState(false);

  // const [orderAmount, setOrderAmount] = useState(0);
  const [paypalOrder, setOrder] = useState({});
  // const [youSaved, setYouSaved] = useState(false);
  // const [finalPrice, setFinalPrice] = useState(false);
  const [isHidingOrderdetails, setIsHidingOrderdetails] = useState(true);

  const [open, setOpen] = useState(false);
  const transition = useTransition(open, {
    from: { y: "100%" },
    enter: {
      y: "0%",
    },
    leave: {
      y: "100%",
    },
    config: { duration: 300 },
  });

  const [profileOpen, setProfileOpen] = useState(false);
  const profileTransition = useTransition(profileOpen, {
    from: { y: "100%" },
    enter: {
      y: "0%",
    },
    leave: {
      y: "100%",
    },
    config: { duration: 300 },
  });

  const currency = useSelector((state) => state.authReducer.currency);
  const user = useSelector((state) => state.authReducer.user);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  // const gstNumber = useSelector((state) => state.authReducer.user.gst_number);
  const [haveGst, setHaveGst] = useState(user?.gst_number ? true : false); //check if the user has a GST number or not

  // comment to test the empty cart page
  const cartItems = user.cartproducts;
  const saveForLater = user.saveforlater;
  // console.log("CARTITEMS", user.cartproducts);
  const [changeGst, setChangeGst] = useState(user.gst ? true : false);
  // uncomment to test the empty cart pagealign-items-lg-center
  // const cartItems = [];
  let totalprice = 0;
  cartItems?.map((item) => {
    totalprice = totalprice + item?.product?.price;
  });

  let saved = 0;
  cartItems?.map((item) => {
    if (item?.applied_coupon) {
      if (item?.applied_coupon?.coupon_category === "ABSOLUTE") {
        saved = saved + item?.applied_coupon?.flat_off;
      } else {
        saved =
          saved + (item?.product?.price * item?.applied_coupon?.flat_off) / 100;
      }
    }
  });

  const dispatch = useDispatch();

  const successNavigation = (url) => {
    setLoading(false);
    handleNavigationClick(url);
    // navigate(url);
  };

  const onFailure = () => {
    setLoading(false);
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setIsHidingOrderdetails(false);
    } else if (scrolled <= 300) {
      setIsHidingOrderdetails(true);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  useEffect(() => {
    dispatch(actions.getMyProfile());
  }, []);

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(userForm.current);
    const response = await actions.updateProfile(formData, user.id);
    if (response) {
      dispatch(actions.profileUpdate(response));
      setShowProfileModal(false);
      setShowGstModal(true);
      setProfileOpen(false);
      setOpen(true);
    }
    setLoading(false);
  };

  const handleGstInfoUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log("loading", loading);
    const formData = new FormData(form.current);
    const response = await actions.updateProfile(formData, user.id);
    if (response) {
      dispatch(actions.profileUpdate(response));
      setGstForm(false);
      setShowGstModal(false);
      setLoading(false);
      setOpen(false);
      // toast.success("You can continue with Checking Out!");
    }
  };

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const handleOrder = async (e) => {
    e.preventDefault();
    if (isAuthenticated) {
      if (
        user.name !== null &&
        user.name !== "" &&
        user.email !== "" &&
        user.email !== null
      ) {
        // if (user?.gst_number || continueWithoutGst) {
        // setLoading(true);
        const data = {
          user: user.id,
          payment_method:
            user.phone_code === "+91" || user.phone_code === "91" ? "RZ" : "PP",
        };
        try {
          setLoading(true);
          const order = await actions.createOrder(data);

          if (order) {
            setOrder(order);
            setLoading(false);
            const params = {
              order: order,
              name: user.name,
              contact: user.mobile,
              email: user.email,
            };
            if (order.total >= 0.5) {
              if (user.phone_code === "+91" || user.phone_code === "91") {
                console.log("RAZORPAY");
                razorpayCheckout(params, successNavigation, onFailure);
              } else {
                console.log("PAYPAL");
                setShowPaypalCheckout(true);
              }
            } else {
              const response = actions.upgradeZeroDollarOrder({
                orderId: order.id,
                user: user.id,
              });
              if (response) {
                handleNavigationClick(`/cart/thankyou?orderId=${order.id}`);
                // navigate(`/cart/thankyou?orderId=${order.id}`);
              }
            }
          } else {
            setLoading(false);
          }
        } catch (e) {
          setLoading(false);
        }
        // } else {
        //   setShowGstModal(true);
        //   setOpen(true);
        // }
      } else {
        setShowProfileModal(true);
        setProfileOpen(true);
      }
    } else {
      if (
        breakpoint === "xsmall" ||
        breakpoint === "small" ||
        breakpoint === "medium"
      ) {
        navigate("/login");
      } else {
        setLoginShow(true);
      }
    }
  };

  if (cartItems?.length === 0 && saveForLater?.length === 0) {
    return (
      <React.Fragment>
        {/* <Helmet>
          <title>{`Znanye - The cart holds all that u need to build a future in VR`}</title>
          <meta
            name="description"
            content="No messy Wishlist hacks, just add 3D models and VR assets to the cart. Shop now and elevate your project and bring it to life with Znanye."
          />
          <link
            rel="canonical"
            href={`https://www.znanye.com${location.pathname}`}
          />
          <link
            rel="alternate"
            hreflang="x-default"
            href={`https://www.znanye.com${location.pathname}`}
          />
        </Helmet> */}
        <Breakpoint large up>
          {loading && <Loader faded={true} />}
          <Container
            className="d-flex w-100 justify-content-center align-items-center h-100 mt-0 pt-5 px-5 home-background-categories"
            fluid
            style={styles.emptyCartContainer}
          >
            <div>
              <div className="d-flex justify-content-center">
                <Image src={emptycart} alt="Empty Cart" />
              </div>
              <div className="d-flex justify-content-center text-center mb-5">
                <h4 className="text-primary m-1 mt-5">OOPS! </h4>
                <h4 className="text-white m-1 mt-5">
                  You have not added any product in your cart.
                </h4>
              </div>
              <div className="d-flex justify-content-center mt-5 mb-5">
                <Link
                  onClick={() => handleNavigationClick("/3dmodels")}
                  // to="/3dmodels"
                  style={{ textDecoration: "none" }}
                >
                  <BootstrapButton
                    className="explore-button-no-neo btn btn-primary position-relative"
                    dark={true}
                    size="large"
                    style={{ border: changingBorder ? "0px" : "0px" }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}
                  >
                    ADD ITEM
                  </BootstrapButton>
                </Link>
              </div>
            </div>
          </Container>
        </Breakpoint>
        <Breakpoint medium down>
          <Container
            className="h-100 pt-5 home-background-categories"
            fluid
            style={styles.emptyCartContainer}
          >
            <div className="d-flex justify-content-center">
              <Image
                src={emptycart}
                alt="Empty Cart"
                style={{ width: 250, marginTop: 80 }}
              />
            </div>
            <div className="text-center mb-5 oopsZIndex h4">
              <div className="text-primary m-1" style={{ fontSize: 35 }}>
                OOPS !{" "}
              </div>
              <div className="text-white m-1" style={{ fontSize: 30 }}>
                You have not added any product in your cart.
              </div>
            </div>
            {/* <div> */}
            {/* <Link to="/3dmodels" style={{ textDecoration: "none" }}> */}
            <BootstrapButton
              // as={Link}
              // to={"/3dmodels"}
              onClick={() => handleNavigationClick("/3dmodels")}
              className="explore-button-no-neo border-radius-zero d-flex justify-content-center align-items-center btn btn-primary  m-0 p-0 w-100 position-fixed"
              dark={true}
              size="large"
              style={{
                bottom: 0,
                borderRadius: 0,
                border: changingBorder ? "0px" : "0px",
                height: "60px",
                left: 0,
              }}
              onMouseEnter={() => setChangingBroder(true)}
              onMouseLeave={() => setChangingBroder(false)}
            >
              ADD ITEM
            </BootstrapButton>
            {/* </Link> */}
            {/* </div> */}
          </Container>
        </Breakpoint>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Breakpoint large up>
        {loading && <Loader faded={true} />}
        <Container
          className="mt-4 home-background-categories"
          fluid
          style={{ paddingTop: 80 }}
        >
          <h4 className="text-white mx-5 px-3 py-3 subtitle">Cart</h4>

          {cartItems?.length === 0 && (
            <>
              <Container
                className="d-flex w-100 justify-content-center align-items-center h-100 mt-5  ps-5 home-background-categories"
                fluid
                style={styles.emptyCartContainer}
              >
                <div className="text-center  oopsZIndex h4 d-flex flex-column  align-items-center">
                  <div className="text-primary m-1" style={{ fontSize: 35 }}>
                    OOPS !{" "}
                  </div>
                  <div className="text-white m-1" style={{ fontSize: 30 }}>
                    You have not added any product in your cart.
                  </div>
                  <div
                    className="text-white d-flex justify-content-center"
                    style={{ fontSize: 30 }}
                  >
                    Scroll down to view the Saved Products!
                  </div>
                </div>
              </Container>
            </>
          )}
          <Row>
            {cartItems?.length !== 0 && (
              <>
                <Col
                  sm={7}
                  className="ps-5 py-1 mb-5 scroll-y"
                  style={styles.cartCard}
                >
                  <div className="ps-4">
                    {cartItems &&
                      cartItems.map((cartItem, index) => {
                        let item = cartItems[cartItems.length - 1 - index];
                        return <CartItem items={item} />;
                      })}
                  </div>
                </Col>
                <Col
                  sm={5}
                  className="d-flex justify-content-center"
                  style={styles.orderCard}
                >
                  <div className={`${cartItems.length == 0 ? "d-none" : ""}`}>
                    <BCard
                      className="order-details-card p-0 position-relative"
                      dark={true}
                      style={{
                        border: "2px solid #3D3D3D",
                        backgroundColor: "#141415",
                      }}
                    >
                      <CardAction
                        className="order-card-action m-0"
                        style={styles.cardAction}
                      >
                        <div className="ms-4">
                          <h4 className="p-0 text-white">Order Details</h4>
                        </div>
                      </CardAction>
                      <div className="m-4 mb-3">
                        {cartItems?.map((item) => {
                          return (
                            <div
                              className="mb-0 text-white d-flex justify-content-between"
                              key={item.id}
                            >
                              <p
                                className="poppins-400"
                                style={{ fontSize: 17 }}
                              >
                                {item.product?.title}
                              </p>
                              <p
                                className="poppins-400"
                                style={{ fontSize: 16 }}
                              >
                                {getSymbolFromCurrency(currency.name) +
                                  " " +
                                  (
                                    item?.product?.price * currency.rate
                                  ).toFixed(2)}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      <div style={styles.underline}></div>
                      <div className="mx-4 mb-2 my-4 d-flex justify-content-between text-white">
                        <p className="p-0 poppins-500" style={{ fontSize: 20 }}>
                          Total Price
                        </p>
                        <span
                          className="p-0 poppins-500"
                          style={{ fontSize: 20 }}
                        >
                          {getSymbolFromCurrency(currency.name) +
                            " " +
                            (totalprice * currency.rate).toFixed(2)}
                        </span>
                      </div>
                      {saved > 0 && <div style={styles.underline}></div>}
                      <div
                        className={`${
                          saved > 0
                            ? "mx-4 mb-2 my-4 d-flex justify-content-between"
                            : "d-none"
                        }`}
                        style={styles.youSavedColor}
                      >
                        <p className="p-0 poppins-500" style={{ fontSize: 20 }}>
                          You Saved
                        </p>
                        <span
                          className="p-0 poppins-500"
                          style={{ fontSize: 20 }}
                        >
                          {getSymbolFromCurrency(currency.name) +
                            " " +
                            (saved * currency.rate).toFixed(2)}
                        </span>
                      </div>
                      <div style={styles.underline}></div>
                      {/* Never Remove this part of the code - START */}
                      <div
                        className="mx-4"
                        style={{
                          height: 0,
                          width: 390,
                        }}
                      ></div>
                      {/* Never Remove this part of the code - END */}
                      <div
                        className={`${
                          saved > 0
                            ? "m-4 d-flex justify-content-between text-white"
                            : "d-none"
                        }`}
                      >
                        <h5 className="p-0">Final Price</h5>
                        <h5 className="p-0">
                          {getSymbolFromCurrency(currency.name) +
                            " " +
                            ((totalprice - saved) * currency.rate).toFixed(2)}
                        </h5>
                      </div>
                      <div style={styles.underline}></div>
                      <div className="m-4 d-flex align-items-center text-white">
                        <div
                          // className={`${haveGst ? "explore-slider-icon d-flex justify-content-center align-items-center" : ""}`}
                          className="d-flex justify-content-center align-items-center"
                          // style={{ width: "24px", height: "24px", borderRadius: "5px", border: `${haveGst ? 0 : "1px solid white"}` }}
                          onClick={(e) => {
                            debugger;
                            if (!isAuthenticated) {
                              if (
                                breakpoint === "xsmall" ||
                                breakpoint === "small" ||
                                breakpoint === "medium"
                              ) {
                                navigate("/login");
                              } else {
                                setLoginShow(true);
                              }
                            } else {
                              if (user?.gst_number) {
                                setChangeGst(!changeGst);
                              } else {
                                e.preventDefault();
                                setHaveGst(!haveGst);
                                setShowGstModal(true);
                              }
                            }
                          }}
                        >
                          {changeGst ? (
                            <Image src={greentick} alt="Have GST" />
                          ) : (
                            <Image src={greytick} alt="No GST" />
                          )}
                          {/* <AiOutlineCheck className={`${haveGst ? "d-block" : "d-none"}`} style={{ margin: "0px" }} size={20} /> */}
                        </div>
                        <div className="p-0 mx-2">
                          I have a GST No.{" "}
                          {user?.gst_number ? `(${user.gst_number})` : ""}
                        </div>
                      </div>
                      <div style={styles.underline}></div>
                      <div className="my-4 pb-1 d-flex flex-column justify-content-center align-items-center w-100">
                        <BootstrapButton
                          className="explore-button-no-neo btn btn-primary position-"
                          dark={true}
                          size="large"
                          disabled={loading}
                          style={{ border: changingBorder ? "0px" : "0px" }}
                          onMouseEnter={() => setChangingBroder(true)}
                          onMouseLeave={() => setChangingBroder(false)}
                          onClick={(e) => {
                            handleOrder(e);
                          }}
                        >
                          {loading ? (
                            <span>
                              <Spinner
                                className="me-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              PROCESSING...
                            </span>
                          ) : (
                            <>
                              <span className="poppins text-white">
                                CHECKOUT & PAY
                              </span>
                            </>
                          )}
                        </BootstrapButton>
                      </div>
                    </BCard>
                  </div>
                </Col>
              </>
            )}
          </Row>

          {saveForLater?.length > 0 && (
            <>
              <h4 className="text-white mx-5 px-3 py-3 subtitle">
                Saved For Later
              </h4>
              <Row>
                <Col
                  sm={7}
                  className="ps-5 py-1 mb-5 scroll-y"
                  style={styles.cartCard}
                >
                  <div className="ps-4">
                    {saveForLater.map((saveFor, index) => {
                      let item = saveForLater[saveForLater.length - 1 - index];
                      return <CartItem type="saveforlater" items={item} />;
                    })}
                  </div>
                </Col>
              </Row>
            </>
          )}
          <Modal
            show={showProfileModal ? true : false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={isAuthenticated ? "static" : true}
            backdropClassName="modal-backdrop login-modal-backdrop"
          >
            <Modal.Header
              className="d-flex flex-row justify-content-center"
              style={{
                backgroundColor: "#1F1F1F",
                border: "2.5px solid #BEBEBE",
              }}
            >
              <Modal.Title id="contained-modal-title-vcenter">
                <Image src={znanyelogin} alt="Znanye Login" className="my-4" />
              </Modal.Title>
            </Modal.Header>
            <IconButton
              size="large"
              className="d-flex justify-content-center removeButtonRight position-absolute text-white"
              dark={true}
              onClick={() => {
                setShowProfileModal(false);
              }}
            >
              <AiOutlineClose size={20} />
            </IconButton>
            <Modal.Body
              style={{
                border: "2.5px solid #BEBEBE",
                backgroundColor: "#2E2E2E",
              }}
            >
              <Form
                ref={userForm}
                className="text-start"
                onSubmit={(e) => handleProfileSubmit(e)}
              >
                <div
                  className={`${
                    isAuthenticated && (user.name === null || user.email === "")
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  <Form.Group className="w-100" controlId="formDetails">
                    <Form.Label className="text-white">
                      Please complete your profile details!
                    </Form.Label>
                    <br />
                    <br />
                    <Form.Label className="text-white">Enter Name</Form.Label>
                    <Form.Control
                      required
                      name="name"
                      type="text"
                      size="lg"
                      placeholder="Full Name"
                      className="mb-4 text-white"
                      style={{ background: "#141414", height: 50 }}
                    />
                    <Form.Label className="text-white">Enter Email</Form.Label>
                    <Form.Control
                      required
                      name="email"
                      type="email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      size="lg"
                      placeholder="Email"
                      className="mb-3 text-white"
                      style={{ background: "#141414", height: 50 }}
                      onInvalid={(e) => {
                        return e.target.setCustomValidity(
                          "Please enter valid email id."
                        );
                      }}
                      onChange={(e) => {
                        return e.target.setCustomValidity("");
                      }}
                    />
                  </Form.Group>
                  <div className="text-center">
                    <RButton
                      className="btn btn-link border-0 my-4 wrapped-button"
                      style={{
                        background: "transparent",
                        textDecoration: "none",
                      }}
                      type="submit"
                    >
                      <Button
                        className={`body-text2 explore-button`}
                        // disabled={!next}
                        size="large"
                        dark={true}
                      >
                        Submit
                      </Button>
                    </RButton>
                  </div>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            show={showPaypalCheckout ? true : false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={isAuthenticated ? "static" : true}
            backdropClassName="modal-backdrop login-modal-backdrop"
          >
            <Modal.Header
              className="d-flex flex-row justify-content-center"
              style={{
                backgroundColor: "#1F1F1F",
                border: "2.5px solid #BEBEBE",
              }}
            >
              <Modal.Title id="contained-modal-title-vcenter">
                <Image src={znanyelogin} alt="Znanye Logo" className="my-4" />
              </Modal.Title>
            </Modal.Header>
            <IconButton
              size="large"
              className="d-flex justify-content-center removeButtonRight position-absolute text-white"
              dark={true}
            >
              <AiOutlineClose
                size={20}
                onClick={() => {
                  setShowPaypalCheckout(false);
                }}
              />
            </IconButton>
            <Modal.Body
              style={{
                border: "2.5px solid #BEBEBE",
                backgroundColor: "#2E2E2E",
              }}
            >
              <PaypalCheckout
                order={paypalOrder}
                setShowPaypalCheckout={setShowPaypalCheckout}
              />
            </Modal.Body>
          </Modal>

          <Modal
            style={{ borderRadius: "20px" }}
            // show={showGstModal && continueWithoutGst === false ? true : false}
            show={showGstModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={"static"}
            contentClassName="border-R20 user-select-none"
            onHide={() => {
              setShowGstModal(false);
              // setHaveGst(false)
            }}
            onExited={() => {
              setShowGstModal(false);
              // setHaveGst(false)
            }}
          >
            <Modal.Header
              className="d-flex flex-row justify-content-between"
              style={{
                backgroundColor: "#292929",
                border: "2px solid #666666",
                borderRadius: "20px 20px 0px 0px",
              }}
            >
              <Modal.Title>
                <Image
                  src={znanyelogin}
                  alt="Znanye Login"
                  className="my-2"
                  style={{ height: 48 }}
                />
              </Modal.Title>
              <IconButton
                size="large"
                className="d-flex justify-content-center text-white"
                dark={true}
                onClick={() => {
                  setShowGstModal(false);
                  // setHaveGst(false)
                }}
              >
                <AiOutlineClose size={20} />
              </IconButton>
            </Modal.Header>
            <Modal.Body
              style={{
                border: "2px solid #666666",
                backgroundColor: "#141415",
                borderRadius: "0px 0px 20px 20px",
              }}
            >
              <GSTInformation
                form={form}
                handleGstInfoUpdate={handleGstInfoUpdate}
                gstForm={gstForm}
                mode="create"
                user={user}
              />
            </Modal.Body>
          </Modal>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        {loading && <Loader faded={true} />}
        <Container
          className="w-100 home-background-categories"
          fluid
          style={{ paddingTop: 100 }}
        >
          <Row className="mb-5">
            <Container fluid>
              <div className="w-100 px-2">
                <Card
                  dark={true}
                  className="border-0 w-100 d-flex border-radius-zero"
                  outlined
                  style={{
                    backgroundColor: "#333333",
                    borderRadius: "0px !important",
                  }}
                >
                  <Col xs={6} className="w-50">
                    <Button
                      text
                      color={cartVisible ? "white" : "#777"}
                      dark={true}
                      className="w-100"
                      style={{ padding: 22 }}
                      onClick={() => setCartVisible(true)}
                    >
                      {/* <Badge
                        bgColor="var(--success)"
                        content={cartItems?.length}
                        size={1}
                      >
                        Cart
                      </Badge> */}

                      <div className="body-text2">
                        Cart[{cartItems?.length}]
                      </div>
                    </Button>
                  </Col>
                  <div
                    className="poppins"
                    style={{ fontSize: 30, color: "#777" }}
                  >
                    |
                  </div>
                  <Col xs={6} className="w-50">
                    <Button
                      text
                      color={!cartVisible ? "white" : "#777"}
                      dark={true}
                      className="w-100"
                      style={{ padding: 22 }}
                      onClick={() => setCartVisible(false)}
                    >
                      {/* <Badge
                        bgColor="var(--error)"
                        content={saveForLater?.length}
                        size={1}
                      >
                        Saved
                      </Badge> */}

                      <div className="body-text2">
                        Saved[{saveForLater?.length}]
                      </div>
                    </Button>
                  </Col>
                </Card>
              </div>
            </Container>
            {/* <h3 className="text-white m-lg-0 ps-3">Cart</h3> */}
            <div className={`${cartVisible ? "d-block" : "d-none"}`}>
              {cartItems?.length == 0 && (
                <Container
                  className="h-100 pt-5 home-background-categories"
                  fluid
                  style={styles.emptyCartContainer}
                >
                  <div className="d-flex justify-content-center">
                    <Image
                      src={emptycart}
                      alt="Empty Cart"
                      style={{ width: 150, marginTop: 80 }}
                    />
                  </div>
                  <div className="text-center mb-5 oopsZIndex h4">
                    <div className="text-primary m-1" style={{ fontSize: 24 }}>
                      OOPS !{" "}
                    </div>
                    <div className="text-white m-1" style={{ fontSize: 20 }}>
                      You have not added any product in your cart.
                    </div>
                  </div>
                  {/* <div> */}
                  {/* <Link to="/3dmodels" style={{ textDecoration: "none" }}> */}
                  <BootstrapButton
                    // as={Link}
                    // to={"/3dmodels"}
                    onClick={() => handleNavigationClick("/3dmodels")}
                    className="explore-button-no-neo border-radius-zero d-flex justify-content-center align-items-center btn btn-primary  m-0 p-0 w-100 position-fixed"
                    dark={true}
                    size="large"
                    style={{
                      bottom: 0,
                      borderRadius: 0,
                      border: changingBorder ? "0px" : "0px",
                      height: "60px",
                      left: 0,
                    }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}
                  >
                    ADD ITEM
                  </BootstrapButton>
                  {/* </Link> */}
                  {/* </div> */}
                </Container>
              )}
              <Col
                className="overflow-auto mt-4 mb-5 pb-5"
                style={styles.cartCard}
              >
                {cartItems.map((cartItem, index) => {
                  let item = cartItems[cartItems.length - 1 - index];
                  return <CartItem items={item} />;
                })}
              </Col>
            </div>
            <div className={`${!cartVisible ? "d-block" : "d-none"}`}>
              {saveForLater?.length == 0 && (
                <Container
                  className="h-100 pt-5 home-background-categories"
                  fluid
                  style={styles.emptyCartContainer}
                >
                  <div className="d-flex justify-content-center">
                    <Image
                      src={emptycart}
                      alt="Empty Cart"
                      style={{ width: 150, marginTop: 80 }}
                    />
                  </div>
                  <div className="text-center mb-5 oopsZIndex h4">
                    <div className="text-primary m-1" style={{ fontSize: 24 }}>
                      OOPS !{" "}
                    </div>
                    <div className="text-white m-1" style={{ fontSize: 20 }}>
                      You have not saved products.
                    </div>
                  </div>
                  {/* <div> */}
                  {/* <Link to="/3dmodels" style={{ textDecoration: "none" }}> */}
                  <BootstrapButton
                    // as={Link}
                    // to={"/3dmodels"}
                    onClick={() => handleNavigationClick("/3dmodels")}
                    className="explore-button-no-neo border-radius-zero d-flex justify-content-center align-items-center btn btn-primary  m-0 p-0 w-100 position-fixed"
                    dark={true}
                    size="large"
                    style={{
                      bottom: 0,
                      borderRadius: 0,
                      border: changingBorder ? "0px" : "0px",
                      height: "60px",
                      left: 0,
                    }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}
                  >
                    ADD ITEM
                  </BootstrapButton>
                  {/* </Link> */}
                  {/* </div> */}
                </Container>
              )}
              <Col className="overflow-auto py-1 mt-4" style={styles.cartCard}>
                {saveForLater.map((saveFor, index) => {
                  let item = saveForLater[saveForLater.length - 1 - index];
                  return <CartItem type="saveforlater" items={item} />;
                })}
              </Col>
            </div>
          </Row>
        </Container>
        {cartVisible && cartItems.length > 0 && (
          <Container
            className="home-background-categories text-white position-relative w-100 p-0"
            fluid
            style={{ paddingTop: 10, left: 0, bottom: "60px" }}
            ref={orderDetailsView}
          >
            <BCard
              className="m-0 p-0 w-100"
              dark={true}
              style={{ borderRadius: 0, backgroundColor: "#292929" }}
            >
              <div
                className="subtitle d-flex justify-content-between align-items-center"
                style={{
                  fontSize: 18,
                  width: "100%",
                  backgroundColor: "#292929",
                  height: 48,
                }}
              >
                <span style={{ paddingLeft: 10 }}>Order Details</span>
              </div>
            </BCard>
            <BCard
              className="my-0 p-2 w-100"
              dark={true}
              style={{ borderRadius: 0, backgroundColor: "#141415" }}
            >
              {cartItems.map((item) => (
                <div className=" my-2 subtitle d-flex justify-content-between">
                  {item.product.title}
                  <div className="text-end">
                    {getSymbolFromCurrency(currency.name) +
                      (item.product.price * currency.rate).toFixed(2)}
                  </div>
                </div>
              ))}
              <div style={styles.underline}></div>
              <div className="subtitle my-3  d-flex justify-content-between">
                <div>Total Price</div>
                <div className="text-end">
                  {getSymbolFromCurrency(currency.name) +
                    " " +
                    (totalprice * currency.rate).toFixed(2)}
                </div>
              </div>
              {saved > 0 && <div style={styles.underline}></div>}
              <div
                className={`${
                  saved > 0
                    ? "subtitle my-3  d-flex justify-content-between"
                    : "d-none"
                }`}
                style={styles.youSavedColor}
              >
                <div>You Saved</div>
                <div className="text-end" onClick={() => setOpen(!open)}>
                  {getSymbolFromCurrency(currency.name) +
                    " " +
                    (saved * currency.rate).toFixed(2)}
                </div>
              </div>
              <div style={styles.underline}></div>
              {/* <div
              className={`${saved > 0
                ? "subtitle mb-2 d-flex justify-content-between"
                : "d-none"
                }`}
            > */}
              <div className="subtitle my-3 d-flex justify-content-between">
                <div>Final Price</div>
                <div className="text-end">
                  {getSymbolFromCurrency(currency.name) +
                    " " +
                    ((totalprice - saved) * currency.rate).toFixed(2)}
                </div>
              </div>
              <div>
                <div style={styles.underline}></div>
              </div>
              <div className="d-flex align-items-center text-white">
                <div
                  onClick={(e) => {
                    if (!isAuthenticated) {
                      console.log("bhbh");
                      if (
                        breakpoint === "xsmall" ||
                        breakpoint === "small" ||
                        breakpoint === "medium"
                      ) {
                        navigate("/login");
                      } else {
                        setLoginShow(true);
                      }
                    } else {
                      if (user?.gst_number) {
                        setChangeGst(!changeGst);
                      } else {
                        e.preventDefault();
                        setHaveGst(!haveGst);
                        setShowGstModal(true);
                      }
                    }
                  }}
                >
                  {changeGst ? (
                    <Image src={greentick} alt="Have GST" />
                  ) : (
                    <Image src={greytick} alt="No yes GST" />
                  )}
                </div>

                <div className="p-0 mx-2 my-3">
                  I have a GST No.{" "}
                  {user?.gst_number ? `(${user.gst_number})` : ""}
                </div>
              </div>
            </BCard>
          </Container>
        )}
        {cartVisible && cartItems.length > 0 && (
          <BootstrapButton
            className="explore-button-no-neo btn btn-primary position-fixed"
            dark={true}
            size="large"
            disabled={loading}
            style={{
              bottom: 0,
              border: 0,
              width: "100%",
              borderRadius: 0,
              height: "60px",
              border: changingBorder ? "0px" : "0px",
              // zIndex:"999"
              // marginLeft:"-8px"
            }}
            onMouseEnter={() => setChangingBroder(true)}
            onMouseLeave={() => setChangingBroder(false)}
            onClick={(e) => {
              console.log("checkout");
              handleOrder(e);
            }}
          >
            {loading ? (
              <span>
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                PROCESSING...
              </span>
            ) : (
              <span className="poppins"> CHECKOUT & PAY </span>
            )}
          </BootstrapButton>
        )}
        {cartVisible && cartItems.length > 0 && (
          <div
            className={`${isHidingOrderdetails ? "position-fixed" : "d-none"}`}
            style={{
              bottom: 50,
              border: 0,
              width: "100%",
              borderRadius: 0,
              height: "60px",
              // zIndex:"-1",
              border: changingBorder ? "0px" : "0px",
            }}
          >
            <BCard
              className={`${
                isHidingOrderdetails ? "m-0 p-0 w-100 subtitle" : "d-none"
              }`}
              dark={true}
              style={{ borderRadius: 0, backgroundColor: "#292929" }}
              onClick={() => {
                console.log("Hello");
                orderDetailsView.current.scrollIntoView();
              }}
            >
              <div
                className="px-2 text-white d-flex align-items-center"
                style={{
                  fontSize: 18,
                  width: "100%",
                  backgroundColor: "#292929",
                  height: 48,
                }}
              >
                Order Details
              </div>
            </BCard>
          </div>
        )}
        {transition(
          (props, item) =>
            item && (
              <Container
                as={animated.div}
                className=" position-fixed p-0"
                fluid
                style={{
                  width: "100%",
                  // height: "100vh",
                  top: 0,
                  y: props.y,
                  bottom: 0,
                  left: 0,
                  backgroundColor: "#1f1f1f",
                  zIndex: 99999,
                  // boxShadow: "0px -2px 10px #555555",
                }}
              >
                <div
                  className="d-flex flex-row justify-content-between"
                  style={{
                    backgroundColor: "#292929",
                    padding: "0px !important",
                    // border: "2px solid #666666",
                    // borderRadius: "20px 20px 0px 0px"
                    height: 96,
                    left: 0,

                    width: 100 + "%",
                  }}
                >
                  <Image
                    // className="d-flex justfiy-content-start align items-center"
                    src={znanyelogin}
                    alt="Znanye Login"
                    className="my-3 mx-3"
                    style={{ height: 48, left: 0 }}
                  />
                  <IconButton
                    size="large"
                    className=" mx-3 my-3  d-flex justify-content-center align-items-center text-white"
                    dark={true}
                    onClick={() => {
                      setOpen(false);
                      setShowGstModal(false);
                      setHaveGst(false);
                    }}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center "
                      style={{
                        width: "48px",
                        height: "48px",
                        border: "1px solid grey",
                        borderRadius: 5,
                      }}
                    >
                      <AiOutlineClose className="my-2" size={16} />
                    </div>
                  </IconButton>
                </div>

                <GSTInformation
                  form={form}
                  handleGstInfoUpdate={handleGstInfoUpdate}
                  gstForm={gstForm}
                  mode="createmobile"
                  user={user}
                />
              </Container>
            )
        )}
        {profileTransition(
          (props, item) =>
            item && (
              <Container
                as={animated.div}
                className="position-fixed p-0"
                fluid
                style={{
                  width: "100%",
                  height: "55vh",
                  y: props.y,
                  bottom: 0,
                  left: 0,
                  backgroundColor: "#1f1f1f",
                  zIndex: 999,
                  boxShadow: "0px -2px 10px #555555",
                }}
              >
                <IconButton
                  size="large"
                  className="d-flex justify-content-center removeButtonRight position-absolute text-white"
                  dark={true}
                >
                  <AiOutlineClose
                    size={20}
                    onClick={() => {
                      setProfileOpen(false);
                    }}
                  />
                </IconButton>
                <IconButton
                  size="large"
                  className={`${
                    gstForm
                      ? "d-flex justify-content-center text-white mt-2"
                      : "d-none"
                  }`}
                  dark={true}
                >
                  <IoChevronBack
                    size={20}
                    onClick={() => {
                      setGstForm(false);
                      setCheckForm(true);
                    }}
                  />
                </IconButton>
                <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
                  <Form
                    ref={userForm}
                    className="text-start"
                    onSubmit={(e) => handleProfileSubmit(e)}
                  >
                    <div
                      className={`${
                        isAuthenticated &&
                        (user.name === null || user.email === "")
                          ? "d-block px-3"
                          : "d-none"
                      }`}
                    >
                      <Form.Group className="w-100" controlId="formDetails">
                        <Form.Label className="text-white">
                          Hello, please fill in your profile details!
                        </Form.Label>
                        <Form.Label className="text-white">
                          Enter Name
                        </Form.Label>
                        <Form.Control
                          required
                          name="name"
                          type="text"
                          size="lg"
                          placeholder="Full Name"
                          className="mb-4 text-white"
                          style={{ background: "#141414", height: 50 }}
                        />
                        <Form.Label className="text-white">
                          Enter Email
                        </Form.Label>
                        <Form.Control
                          required
                          name="email"
                          type="email"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          size="lg"
                          placeholder="Email"
                          className="mb-3 text-white"
                          style={{ background: "#141414", height: 50 }}
                          onInvalid={(e) => {
                            return e.target.setCustomValidity(
                              "Please enter valid email id."
                            );
                          }}
                          onChange={(e) => {
                            return e.target.setCustomValidity("");
                          }}
                        />
                      </Form.Group>
                      <div className="text-center">
                        <RButton
                          className="btn btn-link border-0 my-4 wrapped-button"
                          style={{
                            background: "transparent",
                            textDecoration: "none",
                          }}
                          type="submit"
                        >
                          <Button
                            className={`body-text2 explore-button`}
                            // disabled={!next}
                            size="large"
                            dark={true}
                          >
                            Submit
                          </Button>
                        </RButton>
                      </div>
                    </div>
                  </Form>
                </div>
              </Container>
            )
        )}
        <Modal
          show={showPaypalCheckout ? true : false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={isAuthenticated ? "static" : true}
          backdropClassName="modal-backdrop login-modal-backdrop"
        >
          <Modal.Header
            className="d-flex flex-row justify-content-center"
            style={{
              backgroundColor: "#1F1F1F",
              border: "2.5px solid #BEBEBE",
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <Image src={znanyelogin} alt="Znanye Logo" className="my-4" />
            </Modal.Title>
          </Modal.Header>
          <IconButton
            size="large"
            className="d-flex justify-content-center removeButtonRight position-absolute text-white"
            dark={true}
          >
            <AiOutlineClose
              size={20}
              onClick={() => {
                setShowPaypalCheckout(false);
              }}
            />
          </IconButton>
          <Modal.Body
            style={{
              border: "2.5px solid #BEBEBE",
              backgroundColor: "#2E2E2E",
            }}
          >
            <PaypalCheckout
              order={paypalOrder}
              setShowPaypalCheckout={setShowPaypalCheckout}
            />
          </Modal.Body>
        </Modal>
      </Breakpoint>
    </React.Fragment>
  );
};

const styles = {
  emptyCartContainer: {
    minHeight: "100vh",
  },
  progressBarContainer: {
    minHeight: 100,
  },
  cartCard: {
    height: 700,
  },
  cardAction: {
    backgroundColor: "#2E2E2E",
  },
  underline: {
    height: 1,
    backgroundColor: "#3D3D3D",
    // color:"#3D3D3D"
  },
  youSavedColor: {
    color: "#58D31B",
  },
  orderCard: {
    minHeight: 800,
  },
};
export default OrderDetails;

import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Card as BCard,
  Badge,
  Spinner,
  Button as RButton,
} from "react-bootstrap";
import { Card, Button, IconButton } from "ui-neumorphism";
import { AiOutlineClose } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { download, imagenotavailable } from "assets";

import "./CartItem.scss";
import { ZDropdown, AddToCartButton } from "components";
import Heart from "react-animated-heart";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "actions/AuthActions";
import { Breakpoint } from "react-socks";
import getSymbolFromCurrency from "currency-symbol-map";

const CartItem = ({ type, items }) => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";
  const currency = useSelector((state) => state.authReducer.currency);
  const user = useSelector((state) => state.authReducer.user);
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);
  const [changingBorder, setChangingBroder] = useState(false);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };
  // console.log("----------------------------",items)

  const productFileDownload = async (version, productId, productName) => {
    setDownloading(true);
    const response = await actions.downloadProductFile(
      version,
      productId,
      productName
    );
    if (response) {
      setDownloading(false);
    }
  };

  const [savingForLater, setSavingForLater] = useState(false);
  const [unsavingForLater, setUnsavingForLater] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  // const [isHidingOrderdetails, setIsHidingOrderdetails] = useState(false);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  const handleFavorite = (productId, userId) => {
    let fav = { product_id: productId };
    dispatch(actions.removeFavorite(fav, userId));
  };

  const handleSaveForLater = async (productId) => {
    setSavingForLater(true);
    const data = { product_id: productId };
    // dispatch(actions.addToSaveForLater(data, user.id));
    const response = await actions.addToSaveForLater(data, user.id);
    if (response) {
      dispatch(actions.saveForLaterAdd(response));
      setSavingForLater(false);
    } else {
      dispatch(actions.getMyProfile());
    }
    setSavingForLater(false);
  };

  const handleAddToCart = async (productId) => {
    // dispatch(actions.addCartproduct({ id: productId }, user?.id))
    setUnsavingForLater(true);
    const response = await actions.addProductToCart(
      { id: productId },
      user?.id
    );
    if (response) {
      dispatch(actions.cartProductAdd(response));
      setUnsavingForLater(false);
    } else {
      dispatch(actions.getMyProfile());
    }
    setUnsavingForLater(false);
  };

  const handleRemoveFromSaveForLater = async (productId) => {
    setIsRemoving(true);
    const data = { product_id: productId };
    const response = await actions.removeFromSaveForLater(data, user.id);
    if (response) {
      dispatch(actions.saveForLaterRemove(data));
      setIsRemoving(false);
    } else {
      dispatch(actions.getMyProfile());
    }
    setIsRemoving(false);
  };

  const handleRemoveCartproduct = async (productId) => {
    setIsRemoving(true);
    const data = { product_id: productId };
    const response = await actions.removeProductFromCart(data, user.id);
    if (response) {
      dispatch(actions.cartProductRemove(data));
      setIsRemoving(false);
    } else {
      dispatch(actions.getMyProfile());
    }
    setIsRemoving(false);
  };

  const handleUpdateCartproduct = (selectedCoupon, productId) => {
    // console.log("Handle update cart product", productId);
    // console.log("New selectedCoupon", selectedCoupon);
    const data = {
      product_id: productId,
      applied_coupon_id: selectedCoupon.value,
    };
    dispatch(actions.updateCartproduct(data, user.id));
  };

  switch (type) {
    case "successful":
      const successfulFileExtensions = Object.keys(
        items?.product?.file_statistics?.extension_stats
      );
      return (
        <>
          <Breakpoint large up>
            <Container>
              <Row>
                <Col
                  sm="auto"
                  className="ps-4 py-3"
                  // as={Link}
                  // to={`/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`}
                  onClick={() =>
                    handleNavigationClick(
                      `/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`
                    )
                  }
                >
                  <BCard
                    className="d-flex justify-content-center align-items-center cursor-pointer"
                    style={styles.successfulImageCard}
                    dark={true}
                    key={items.product?.slug}
                  >
                    <Image
                      src={
                        items.product?.productimage_set.length !== 0
                          ? items.product?.productimage_set[0]?.image_file
                          : imagenotavailable
                      }
                      alt={items.product?.title}
                      style={styles.successfulImage}
                    />
                  </BCard>
                </Col>
                <Col sm={8}>
                  <div className="text-white body-text2 d-flex flex-column justify-content-between">
                    <p className="mt-3 mb-2">
                      Product Name - {items.product?.title}
                    </p>
                    <p className="mb-2">
                      File Formats -{" "}
                      {successfulFileExtensions.map((extension) => {
                        return <>{extension} </>;
                      })}
                    </p>
                    <p>
                      File Size - {items?.product?.file_statistics?.total_size}
                    </p>
                  </div>
                </Col>
                <Col sm="auto" className="d-flex align-items-center">
                  <IconButton
                    className="explore-button"
                    disabled={downloading}
                    dark={true}
                    style={styles.znanyeDownloadButton}
                    onClick={() =>
                      productFileDownload(
                        items?.version,
                        items?.product?.id,
                        items?.product?.title
                      )
                    }
                  >
                    {downloading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <Image
                        src={download}
                        alt="Download"
                        style={styles.downloadItem}
                      />
                    )}
                  </IconButton>
                </Col>
                <div style={styles.underline}></div>
              </Row>
            </Container>
          </Breakpoint>
          <Breakpoint medium down>
            <Container fluid className="px-0">
              <Row className="d-flex">
                <Col xs="auto">
                  <Link
                    // to={`/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`}
                    onClick={() =>
                      handleNavigationClick(
                        `/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`
                      )
                    }
                  >
                    <BCard
                      className="mt-4 ms-3 d-flex justify-content-center align-items-center cursor-pointer"
                      style={styles.successfulImageMobileCard}
                      dark={true}
                      key={items.product?.id}
                    >
                      <Image
                        src={
                          items.product?.productimage_set.length !== 0
                            ? items.product?.productimage_set[0]?.image_file
                            : imagenotavailable
                        }
                        alt={items.product?.title}
                        style={{ maxWidth: 100, maxHeight: 100 }}
                      />
                    </BCard>
                  </Link>
                </Col>
                <Col
                  className="flex-grow-1 pe-4"
                  style={{ overflowWrap: "anywhere" }}
                >
                  <div className="ms-3 mt-4 text-white">
                    <div className="body-text2">Product Name</div>
                    <div className="subtitle mb-4">{items.product?.title}</div>
                    <div className="body-text2">File Format Available</div>
                    <div className="subtitle mb-4">
                      {successfulFileExtensions?.map((extension) => {
                        return <>{extension} </>;
                      })}
                    </div>
                    <div className=" mb-4 d-flex justify-content-between">
                      <div>
                        <div className="body-text2">File Size</div>
                        <div className="subtitle">
                          {items?.product?.file_statistics?.total_size}
                        </div>
                      </div>
                      <div>
                        <IconButton
                          className="explore-button mt-1"
                          disabled={downloading}
                          dark={true}
                          style={styles.znanyeDownloadButton}
                          onClick={() =>
                            productFileDownload(
                              items?.version,
                              items?.product?.id,
                              items?.product?.title
                            )
                          }
                        >
                          {downloading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            <Image
                              src={download}
                              alt="Download"
                              style={styles.downloadItem}
                            />
                          )}
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div style={styles.underline}></div>
            </Container>
          </Breakpoint>
        </>
      );

    case "favourites":
      return (
        <>
          <Breakpoint large up>
            {items?.map((element, index) => {
              let item = items[items.length - 1 - index];
              // // let coupons = [];
              // // item?.product?.coupons_data.map((item) =>
              // //   coupons.push({ value: item.id, label: item.code })
              // // );
              // let coupons = [];
              // items?.product?.coupons_data.map((item) =>
              //   coupons.push({ value: item.id, label: item.code })
              // );
              const fileExtensions = Object.keys(
                item?.product?.file_statistics?.extension_stats
              );
              return (
                <BCard
                  className="position-relative my-5 mt-0 p-0 d-flex flex-column item-details-card"
                  style={{
                    border: "1px solid #3D3D3D",
                    backgroundColor: "#141415",
                  }}
                  key={items?.product?.id}
                >
                  <Row>
                    <Col
                      sm="auto"
                      // as={Link}
                      // to={`/3dmodels/${item.product?.category_data?.slug}/${item.product?.slug}`}
                      onClick={() =>
                        handleNavigationClick(
                          `/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`
                        )
                      }
                      style={{ textDecoration: "none" }}
                    >
                      {/* {console.log(items, "###", item)} */}
                      <Card
                        className="mt-5 ms-5 d-flex justify-content-center align-items-center border-0 cursor-pointer"
                        style={styles.itemImageCard}
                        dark={true}
                        outlined
                      >
                        <Image
                          src={
                            item?.product?.productimage_set.length != 0
                              ? item?.product?.productimage_set[0]?.image_file
                              : imagenotavailable
                          }
                          alt={item?.product?.title}
                          style={{ maxWidth: 95, maxHeight: 95 }}
                        />
                      </Card>
                    </Col>
                    <Col className="mt-5 body-text2 text-white">
                      <p>
                        Product Name - <b>{item?.product?.title}</b>
                      </p>
                      <p>
                        File Formats -{" "}
                        {fileExtensions.map((extension) => {
                          return <b>{extension} </b>;
                        })}
                      </p>
                      <p>
                        File Size -{" "}
                        <b>{item?.product?.file_statistics?.total_size}</b>
                      </p>
                    </Col>
                  </Row>
                  <div className="mt-3 mx-5" style={styles.underline}></div>
                  <div className="ms-5 me-2 my-3">
                    <Row>
                      <Col sm={4} className="text-white">
                        <p>
                          Applicable Coupons -{" "}
                          {item?.product?.coupons_data.map((item) => {
                            return (
                              <Badge
                                className="p-2 m-1 body-text2"
                                bg="black-gray"
                              >
                                {item.code}
                              </Badge>
                            );
                          })}
                        </p>
                      </Col>
                      <Col
                        sm={8}
                        className="d-flex justify-content-end align-items-center"
                      >
                        <div
                          className={`text-primary mb-0  h4 ${
                            item?.applied_coupon ? "me-2" : "me-4"
                          }`}
                        >
                          {item?.applied_coupon ? (
                            <span>
                              {item?.applied_coupon?.coupon_category ==
                              "ABSOLUTE"
                                ? getSymbolFromCurrency(currency.name) +
                                  (
                                    (item?.product?.price -
                                      item?.applied_coupon?.flat_off) *
                                    currency.rate
                                  ).toFixed(2)
                                : getSymbolFromCurrency(currency.name) +
                                  (
                                    (item?.product?.price -
                                      (item?.product?.price *
                                        item?.applied_coupon?.flat_off) /
                                        100) *
                                    currency.rate
                                  ).toFixed(2)}
                            </span>
                          ) : (
                            <span>
                              {console.log(item?.product)}
                              {getSymbolFromCurrency(currency.name) +
                                (item?.product?.price * currency.rate).toFixed(
                                  2
                                )}
                            </span>
                          )}
                        </div>
                        <div
                          className={`${
                            item?.applied_coupon ? "d-flex me-4" : "d-none"
                          } flex-column justify-content-center align-items-start`}
                        >
                          <strike className="text-white">
                            {getSymbolFromCurrency(currency.name) +
                              (item?.product?.price * currency.rate).toFixed(2)}
                          </strike>
                        </div>

                        <div className=" d-flex justify-content-center align-items-center m-2 p-2">
                          <AddToCartButton product={item?.product} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex justify-content-center removeFavouriteButtonRight position-absolute">
                    {/* <Heart
                      isClick={true}
                      onClick={() => handleFavorite(item.product_id, item.user)}
                    /> */}
                    <RButton
                      size="large"
                      className="d-flex justify-content-center removeButtonRight p-2 btn btn-primary position-absolute m-3 text-white trash-icon"
                      onClick={() => handleFavorite(item.product_id, item.user)}
                      style={{
                        border: changingBorder ? "0px" : "0px",
                        right: "15px",
                        top: "15px",
                      }}
                      onFocus={() => setChangingBroder(true)}
                      onMouseEnter={() => setChangingBroder(true)}
                      onMouseLeave={() => setChangingBroder(false)}
                    >
                      {isRemoving ? (
                        <Spinner
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <FiTrash size={20} />
                      )}
                    </RButton>
                  </div>
                </BCard>
              );
            })}
          </Breakpoint>
          <Breakpoint medium down>
            <Container fluid className="p-1">
              {items?.map((element, index) => {
                let item = items[items.length - 1 - index];
                let coupons = [];
                item?.product?.coupons_data.map((item) =>
                  coupons.push({ value: item.id, label: item.code })
                );
                const fileExtensions = Object.keys(
                  item?.product?.file_statistics?.extension_stats
                );
                return (
                  <BCard
                    className="position-relative my-5 mt-0 p-0 d-flex flex-column item-details-card"
                    style={{
                      border: "1px solid #3D3D3D",
                      backgroundColor: "#141415",
                    }}
                    key={items?.product?.id}
                  >
                    <div className="d-flex">
                      <div>
                        <Link
                          // to={`/3dmodels/${item.product?.category_data?.slug}/${item.product?.slug}`}
                          onClick={() =>
                            handleNavigationClick(
                              `/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`
                            )
                          }
                        >
                          <BCard
                            className="mt-4 ms-4 d-flex justify-content-center align-items-center cursor-pointer"
                            style={styles.itemImageMobileCard}
                            dark={true}
                          >
                            <Image
                              src={
                                item?.product?.productimage_set.length != 0
                                  ? item?.product?.productimage_set[0]
                                      ?.image_file
                                  : imagenotavailable
                              }
                              alt={item.product?.title}
                              style={{ maxWidth: 100, maxHeight: 100 }}
                            />
                          </BCard>
                        </Link>
                        <h4 className="text-primary mt-4 ms-4 text-center">
                          {getSymbolFromCurrency(currency.name) +
                            " " +
                            (item?.product?.price * currency.rate).toFixed(2)}
                        </h4>
                      </div>
                      <div className="ms-3 mt-4 text-white">
                        <div className="body-text2 text-white">
                          Product Name
                        </div>
                        <div className="subtitle mb-4">
                          {item?.product?.title}
                        </div>
                        <div className="body-text2 text-white">
                          File Format Available
                        </div>
                        <div className="subtitle mb-4">
                          {fileExtensions?.map((extension) => {
                            return <>{extension} </>;
                          })}
                        </div>
                        <div className=" mb-4 d-flex justify-content-between">
                          <div>
                            <div className="body-text2 text-white">Views</div>
                            <div className="subtitle  text-white">
                              {item?.product?.view_count}
                            </div>
                          </div>
                          <div>
                            <div className="body-text2 text-white">Size</div>
                            <div className="subtitle">
                              {item?.product?.file_statistics?.total_size}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={styles.underline} />
                    <div className="my-4 d-flex justify-content-center">
                      <AddToCartButton product={item?.product} />
                    </div>
                    <div className="d-flex justify-content-center removeFavouriteButtonRight position-absolute">
                      <Heart
                        isClick={true}
                        onClick={() =>
                          handleFavorite(item.product_id, item.user)
                        }
                      />
                    </div>
                  </BCard>
                );
              })}
            </Container>
          </Breakpoint>
        </>
      );

    case "saveforlater":
      let s_fileExtensions = null;
      if (items != null) {
        if (items?.product?.file_statistics?.extension_stats) {
          s_fileExtensions = Object.keys(
            items?.product?.file_statistics?.extension_stats
          );
        }
      }

      return (
        <>
          <Breakpoint large up>
            <BCard
              className="position-relative my-5 mt-0 p-0 d-flex flex-column item-details-card"
              style={{
                border: "1px solid #3D3D3D",
                backgroundColor: "#141415",
              }}
              key={items?.product?.id}
            >
              <Row>
                <Col
                  sm="auto"
                  as={Link}
                  // to={`/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`}
                  onClick={() =>
                    handleNavigationClick(
                      `/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`
                    )
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    className="mt-5 ms-5 d-flex justify-content-center align-items-center border-0 cursor-pointer"
                    style={styles.itemImageCard}
                    dark={true}
                    outlined
                  >
                    <Image
                      src={
                        items?.product?.productimage_set.length != 0
                          ? items?.product?.productimage_set[0]?.image_file
                          : imagenotavailable
                      }
                      alt={items.product?.title}
                      style={{ maxWidth: 95, maxHeight: 95 }}
                    />
                  </Card>
                </Col>
                <Col className="mt-5 body-text2 text-white">
                  <p>
                    Product Name - <b>{items?.product?.title}</b>
                  </p>
                  <p>
                    File Formats -{" "}
                    {s_fileExtensions?.map((extension) => {
                      return <b>{extension} </b>;
                    })}
                  </p>
                  <p>
                    File Size -{" "}
                    <b>{items?.product?.file_statistics?.total_size}</b>
                  </p>
                </Col>
              </Row>
              <div className="my-3 mx-5" style={styles.underline}></div>
              <div className="mx-4 mb-3 text-white">
                <Row>
                  <Col sm={4}></Col>
                  <Col
                    sm={8}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <div
                      className={`text-primary mb-0  h4 ${
                        items?.applied_coupon ? "me-2" : "me-4"
                      }`}
                    >
                      {items?.applied_coupon ? (
                        <span>
                          {items?.applied_coupon?.coupon_category == "ABSOLUTE"
                            ? getSymbolFromCurrency(currency.name) +
                              (
                                (items?.product?.price -
                                  items?.applied_coupon?.flat_off) *
                                currency.rate
                              ).toFixed(2)
                            : getSymbolFromCurrency(currency.name) +
                              (
                                (items?.product?.price -
                                  (items?.product?.price *
                                    items?.applied_coupon?.flat_off) /
                                    100) *
                                currency.rate
                              ).toFixed(2)}
                        </span>
                      ) : (
                        <span>
                          {getSymbolFromCurrency(currency.name) +
                            (items?.product?.price * currency.rate).toFixed(2)}
                        </span>
                      )}
                    </div>
                    <div
                      className={`${
                        items?.applied_coupon ? "d-flex me-4" : "d-none"
                      } flex-column justify-content-center align-items-start`}
                    >
                      <strike className="text-white">
                        {getSymbolFromCurrency(currency.name) +
                          (items?.product?.price * currency.rate).toFixed(2)}
                      </strike>
                    </div>

                    <div sm={{ span: 3 }}>
                      <RButton
                        className="explore-border-button mb-2 w-100 explore-button-large-no-neo"
                        variant="dark"
                        style={{ backgroundColor: "#27292A" }}
                        disabled={unsavingForLater}
                        onClick={() => {
                          // productFileDownload(orderproduct?.product?.id)
                          handleAddToCart(items?.product?.id);
                        }}
                      >
                        {unsavingForLater ? (
                          <span className="SEND">
                            <Spinner
                              className="me-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            MOVING TO CART ...
                          </span>
                        ) : (
                          <span className="poppins">MOVE TO CART</span>
                        )}
                      </RButton>
                    </div>
                  </Col>
                </Row>
              </div>
              <RButton
                size="large"
                className="d-flex justify-content-center p-2 btn btn-primary removeButtonRight text-white position-absolute m-3 trash-icon"
                style={{
                  border: changingBorder ? "0px" : "0px",
                  right: "15px",
                  top: "15px",
                }}
                onFocus={() => setChangingBroder(true)}
                onMouseEnter={() => setChangingBroder(true)}
                onMouseLeave={() => setChangingBroder(false)}
              >
                {isRemoving ? (
                  <Spinner
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <FiTrash
                    size={20}
                    onClick={() =>
                      handleRemoveFromSaveForLater(items.product?.id)
                    }
                  />
                )}
              </RButton>
            </BCard>
          </Breakpoint>
          <Breakpoint medium down>
            <BCard
              className="position-relative m-2 mb-5 p-0 d-flex flex-column cursor-pointer"
              dark={true}
              key={items?.product?.id}
              style={{ backgroundColor: "#141415", borderRadius: "8px" }}
              styles
            >
              <div className="d-flex">
                <div className="d-flex">
                  <Link
                    // to={`/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`}
                    onClick={() =>
                      handleNavigationClick(
                        `/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`
                      )
                    }
                  >
                    <BCard
                      className="mt-4 ms-4 d-flex justify-content-center align-items-center"
                      style={styles.itemImageMobileCard}
                      dark={true}
                    >
                      <Image
                        src={
                          items?.product?.productimage_set.length != 0
                            ? items?.product?.productimage_set[0]?.image_file
                            : imagenotavailable
                        }
                        alt={items.product?.title}
                        style={{
                          maxWidth: 100,
                          maxHeight: 100,
                          borderRadius: "10px",
                          backgroundColor: "#2E2E2E",
                        }}
                      />
                    </BCard>
                  </Link>
                </div>
                <div className="ms-3 mt-4">
                  <div className="overline text-white">Product Name</div>
                  <div className="subtitle text-white mb-4">
                    {items?.product?.title}
                  </div>
                  <div className="overline text-white">
                    File Format Available
                  </div>
                  <div className="subtitle text-white mb-4">
                    {s_fileExtensions?.map((extension) => {
                      return <>{extension} </>;
                    })}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="ms-4 d-flex" style={{ flex: 1 }}>
                  <div className="bg-primary me-2" style={{ width: 2 }}></div>
                  <div>
                    <span className="poppins overline text-white">Price</span>
                    <span className="text-primary subtitle">
                      {items?.applied_coupon ? (
                        items?.applied_coupon?.coupon_category == "ABSOLUTE" ? (
                          getSymbolFromCurrency(currency.name) +
                          (
                            (items?.product?.price -
                              items?.applied_coupon?.flat_off) *
                            currency.rate
                          ).toFixed(2)
                        ) : (
                          getSymbolFromCurrency(currency.name) +
                          (
                            (items?.product?.price -
                              (items?.product?.price *
                                items?.applied_coupon?.flat_off) /
                                100) *
                            currency.rate
                          ).toFixed(2)
                        )
                      ) : (
                        <p className="mb-2">
                          {getSymbolFromCurrency(currency.name) +
                            (items?.product?.price * currency.rate).toFixed(2)}
                        </p>
                      )}
                    </span>
                    <strike
                      className={`${
                        items?.applied_coupon ? "d-block" : "d-none"
                      } text-white text-center`}
                    >
                      {getSymbolFromCurrency(currency.name) +
                        (items?.product?.price * currency.rate).toFixed(2)}
                    </strike>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div className="overline text-white">Views</div>
                  <div className="subtitle text-white ">
                    {items?.product?.view_count}
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div className="overline text-white">File Size</div>
                  <div className="subtitle text-white">
                    {items?.product?.file_statistics?.total_size}
                  </div>
                </div>
              </div>
              <div
                className={`${items?.applied_coupon ? "d-block" : "d-none"} `}
                style={styles.underline}
              />
              <div
                className="d-flex  justify-content-between mt-2"
                style={{ borderTop: "1px solid #333" }}
              >
                {/* <div style={{ flex: 1, borderRight: "1px solid #333" }}>
                 */}
                <div style={{ flex: 1 }}>
                  <Button
                    dark={true}
                    disabled={unsavingForLater}
                    text
                    className="btn-block w-100 explore-button-large"
                    onClick={() => handleAddToCart(items?.product?.id)}
                  >
                    {unsavingForLater ? (
                      <span className="SEND">
                        <Spinner
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Moving ...
                      </span>
                    ) : (
                      <span className="poppins">Move To Cart</span>
                    )}
                  </Button>
                </div>
                {/* <div style={{ flex: 1 }}> */}
                {/* <Button
                    dark={true}
                    disabled={isRemoving}
                    text
                    className="btn-block w-100 explore-button-large"
                    onClick={() => handleRemoveFromSaveForLater(items.product?.id)}
                  >
                    {isRemoving ? (
                      <span className="SEND">
                        <Spinner
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Removing ...
                      </span>
                    ) : (
                      <span className="poppins">Remove</span>
                    )}
                  </Button> */}
                {/* </div> */}
              </div>
            </BCard>
          </Breakpoint>
        </>
      );

    default:
      let coupons = [];
      items?.product?.coupons_data.map((item) =>
        coupons.push({ value: item.id, label: item.code })
      );
      let fileExtensions = null;
      if (items != null) {
        fileExtensions = Object.keys(
          items?.product?.file_statistics?.extension_stats
        );
      }
      return (
        <>
          <Breakpoint large up>
            <BCard
              className="position-relative my-5 mt-0 p-0 d-flex flex-column item-details-card"
              style={{
                border: "1px solid #3D3D3D",
                backgroundColor: "#141415",
              }}
              key={items?.product?.id}
            >
              <Row>
                <Col
                  sm="auto"
                  // as={Link}
                  // to={`/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`}
                  style={{ textDecoration: "none" }}
                  onClick={() =>
                    handleNavigationClick(
                      `/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`
                    )
                  }
                >
                  <Card
                    className="mt-5 ms-5 d-flex justify-content-center align-items-center border-0 cursor-pointer"
                    style={styles.itemImageCard}
                    dark={true}
                    outlined
                  >
                    <Image
                      src={
                        items?.product?.productimage_set.length != 0
                          ? items?.product?.productimage_set[0]?.image_file
                          : imagenotavailable
                      }
                      alt={items.product?.title}
                      style={{ maxWidth: 95, maxHeight: 95 }}
                    />
                  </Card>
                </Col>
                <Col className="mt-5 body-text2 text-white">
                  <p>
                    Product Name - <b>{items?.product?.title}</b>
                  </p>
                  <p>
                    File Formats -{" "}
                    {fileExtensions?.map((extension) => {
                      return <b>{extension} </b>;
                    })}
                  </p>
                  <p>
                    File Size -{" "}
                    <b>{items?.product?.file_statistics?.total_size}</b>
                  </p>
                </Col>
              </Row>
              <div className="my-3 mx-5" style={styles.underline}></div>
              <div className="mx-5 mb-3">
                <Row>
                  <Col sm={4} className="d-flex align-items-center">
                    <div
                      className={`${
                        items?.applied_coupon ? "d-block w-100" : "d-none"
                      }`}
                    >
                      <ZDropdown
                        options={coupons}
                        mode="cartcouponselect"
                        handleUpdateCartproduct={handleUpdateCartproduct}
                        thisProductId={items?.product_id}
                        couponOption={items?.applied_coupon_id}
                        is_disabled={isAuthenticated ? false : true}
                      />
                    </div>
                    <div
                      className={`${
                        items?.applied_coupon ? "d-none" : "d-none"
                      }`}
                      style={{ color: "#555555" }}
                    >
                      No Coupons Applied!
                    </div>
                  </Col>
                  <Col
                    sm={8}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <div
                      className={`text-primary mb-0  h4 ${
                        items?.applied_coupon ? "me-2" : "me-4"
                      }`}
                    >
                      {items?.applied_coupon ? (
                        <span>
                          {items?.applied_coupon?.coupon_category == "ABSOLUTE"
                            ? getSymbolFromCurrency(currency.name) +
                              (
                                (items?.product?.price -
                                  items?.applied_coupon?.flat_off) *
                                currency.rate
                              ).toFixed(2)
                            : getSymbolFromCurrency(currency.name) +
                              (
                                (items?.product?.price -
                                  (items?.product?.price *
                                    items?.applied_coupon?.flat_off) /
                                    100) *
                                currency.rate
                              ).toFixed(2)}
                        </span>
                      ) : (
                        <span>
                          {getSymbolFromCurrency(currency.name) +
                            (items?.product?.price * currency.rate).toFixed(2)}
                        </span>
                      )}
                    </div>
                    <div
                      className={`${
                        items?.applied_coupon ? "d-flex me-4" : "d-none"
                      } flex-column justify-content-center align-items-start`}
                    >
                      <strike className="text-white">
                        {getSymbolFromCurrency(currency.name) +
                          (items?.product?.price * currency.rate).toFixed(2)}
                      </strike>
                    </div>
                    {/* </Col>
                  <Col sm={4}> */}
                    {isAuthenticated && (
                      <div className="my-3">
                        <Button
                          className="grey-button d-flex justify-content-center align-items-center text-white w-100 py-4"
                          disabled={savingForLater}
                          dark={true}
                          onClick={() => {
                            // productFileDownload(orderproduct?.product?.id)
                            handleSaveForLater(items?.product?.id);
                          }}
                        >
                          {savingForLater ? (
                            <span className="SEND">
                              <Spinner
                                className="me-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              SAVING FOR LATER ...
                            </span>
                          ) : (
                            <span className="poppins-500">SAVE FOR LATER</span>
                          )}
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
                {/* <Col
                        sm={2}
                        className={`${items?.applied_coupon ? "d-flex" : "d-none"
                          } flex-column justify-content-center align-items-start`}
                      >
                        <strike className="text-white">
                          {getSymbolFromCurrency(currency.name) +
                            (items?.product?.price * currency.rate).toFixed(2)}
                        </strike>
                      </Col>
                      <Col sm={{ span: 3, offset: items?.applied_coupon ? 0 : 2 }}>
                        <Button
                          className="explore-button-large w-100"
                          dark={true}
                          onClick={() => {
                            // productFileDownload(orderproduct?.product?.id)
                            handleSaveForLater(items?.product?.id)
                          }}
                        >
                          SAVE FOR LATER
                        </Button>
                      </Col> */}

                <Row>
                  <div
                    className={`${
                      items?.applied_coupon ? "d-block" : "d-none"
                    }`}
                  >
                    {items?.applied_coupon_id ===
                    items?.product?.best_coupon ? (
                      <p className="m-0" style={styles.couponColor}>
                        Best Coupon applied
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
              </div>
              <RButton
                size="large"
                className="d-flex justify-content-center removeButtonRight p-2 btn btn-primary position-absolute m-3 text-white trash-icon"
                onClick={() => handleRemoveCartproduct(items.product?.id)}
                style={{
                  border: changingBorder ? "0px" : "0px",
                  right: "15px",
                  top: "15px",
                }}
                onFocus={() => setChangingBroder(true)}
                onMouseEnter={() => setChangingBroder(true)}
                onMouseLeave={() => setChangingBroder(false)}
              >
                {isRemoving ? (
                  <Spinner
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <FiTrash size={20} />
                )}
              </RButton>
            </BCard>
          </Breakpoint>
          <Breakpoint medium down>
            <BCard
              className="position-relative m-2 mb-5 p-0 d-flex flex-column cursor-pointer"
              dark={true}
              style={{ backgroundColor: "#141415", borderRadius: "8px" }}
              key={items?.product?.id}
            >
              <div className="d-flex">
                <div className="d-flex">
                  <Link
                    // to={`/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`}
                    onClick={() =>
                      handleNavigationClick(
                        `/3dmodels/${items.product?.category_data?.slug}/${items.product?.slug}`
                      )
                    }
                  >
                    <BCard
                      className="mt-4 ms-4 d-flex justify-content-center align-items-center"
                      style={styles.itemImageMobileCard}
                      dark={true}
                    >
                      <Image
                        src={
                          items?.product?.productimage_set.length != 0
                            ? items?.product?.productimage_set[0]?.image_file
                            : imagenotavailable
                        }
                        alt={items.product?.title}
                        style={{
                          maxWidth: 100,
                          maxHeight: 100,
                          borderRadius: "10px",
                          backgroundColor: "#2E2E2E",
                        }}
                      />
                    </BCard>
                  </Link>
                </div>
                <div className="ms-3 mt-4">
                  <div className="overline text-white">Product Name</div>
                  <div className="subtitle text-white mb-4">
                    {items?.product?.title}
                  </div>
                  <div className="overline text-white">
                    File Format Available
                  </div>
                  <div className="subtitle  text-white mb-4">
                    {fileExtensions?.map((extension) => {
                      return <>{extension} </>;
                    })}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="ms-2 d-flex" style={{ flex: 1 }}>
                  <div className="bg-primary me-2" style={{ width: 2 }}></div>
                  <div>
                    <span className="poppins overline text-white">Price</span>
                    <br></br>
                    <span className="text-primary subtitle">
                      {items?.applied_coupon ? (
                        items?.applied_coupon?.coupon_category == "ABSOLUTE" ? (
                          getSymbolFromCurrency(currency.name) +
                          (
                            (items?.product?.price -
                              items?.applied_coupon?.flat_off) *
                            currency.rate
                          ).toFixed(2)
                        ) : (
                          getSymbolFromCurrency(currency.name) +
                          (
                            (items?.product?.price -
                              (items?.product?.price *
                                items?.applied_coupon?.flat_off) /
                                100) *
                            currency.rate
                          ).toFixed(2)
                        )
                      ) : (
                        <p className="mb-2">
                          {getSymbolFromCurrency(currency.name) +
                            (items?.product?.price * currency.rate).toFixed(2)}
                        </p>
                      )}
                    </span>
                    <strike
                      className={`${
                        items?.applied_coupon ? "d-block  " : "d-none"
                      } text-white text-center overline`}
                    >
                      {getSymbolFromCurrency(currency.name) +
                        (items?.product?.price * currency.rate).toFixed(2)}
                    </strike>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div className="overline text-white">Views</div>
                  <div className="subtitle  text-white ">
                    {items?.product?.view_count}
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div className="overline text-white">File Size</div>
                  <div className="subtitle text-white">
                    {items?.product?.file_statistics?.total_size}
                  </div>
                </div>
              </div>
              <div
                className={`${
                  items?.applied_coupon ? "my-2 d-block" : "d-none"
                } `}
                style={styles.underline}
              />
              <div className={`${items?.applied_coupon ? "m-2" : "d-none"}`}>
                <ZDropdown
                  options={coupons}
                  mode="cartcouponselect"
                  handleUpdateCartproduct={handleUpdateCartproduct}
                  thisProductId={items?.product_id}
                  couponOption={items?.applied_coupon_id}
                  is_disabled={isAuthenticated ? false : true}
                />
              </div>
              <div
                className="d-flex justify-content-center mt-2"
                style={{ borderTop: "1px solid #333" }}
              >
                {isAuthenticated && (
                  <div style={{ flex: 1, borderRight: "1px solid #333" }}>
                    <Button
                      dark={true}
                      disabled={savingForLater}
                      text
                      className="btn-block w-100"
                      style={{ flex: 1, height: 40 }}
                      onClick={() => {
                        handleSaveForLater(items?.product?.id);
                      }}
                    >
                      {savingForLater ? (
                        <span className="SEND">
                          <Spinner
                            className="me-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Saving ...
                        </span>
                      ) : (
                        <span className="poppins text-white">
                          Save For Later
                        </span>
                      )}
                    </Button>
                  </div>
                )}
                <div style={{ flex: 1 }}>
                  <Button
                    dark={true}
                    disabled={isRemoving}
                    text
                    style={{ flex: 1 }}
                    className="btn-block w-100 "
                    onClick={() => handleRemoveCartproduct(items.product?.id)}
                  >
                    {isRemoving ? (
                      <span className="SEND">
                        <Spinner
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Removing ...
                      </span>
                    ) : (
                      <span className="poppins text-white">Remove</span>
                    )}
                  </Button>
                </div>
              </div>
            </BCard>
          </Breakpoint>
        </>
      );
  }

  // if (param2 == "successful") {
  //   let orderproducts = [];
  //   items.map((item) => {
  //     orderproducts.push(item.orderproducts);
  //   });
  //   return (
  //     <>
  //       <Breakpoint large up>
  //         {orderproducts[0]?.map((orderproduct) => {
  //           const fileExtensions = Object.keys(
  //             orderproduct?.product?.file_statistics?.extension_stats
  //           );
  //           return (
  //             <Container>
  //               <Row>
  //                 <Col
  //                   sm={2}
  //                   className="px-4 py-3"
  //                   as={Link}
  //                   to={`/product/` + orderproduct.product?.id}
  //                 >
  //                   <Card
  //                     className="d-flex justify-content-center align-items-center"
  //                     style={styles.successfulImageCard}
  //                     dark={true}
  //                     key={orderproduct.product?.id}
  //                   >
  //                     <Image
  //                       src={
  //                         orderproduct.product?.productimage_set.length !== 0
  //                           ? orderproduct.product?.productimage_set[0]
  //                               ?.image_file
  //                           : imagenotavailable
  //                       }
  //                       style={styles.successfulImage}
  //                     />
  //                   </Card>
  //                 </Col>
  //                 <Col sm={8}>
  //                   <div>
  //                     <p className="mt-3 mb-1">
  //                       Product Name - {orderproduct.product?.title}
  //                     </p>
  //                     <p className="mb-1">
  //                       File Formats -{" "}
  //                       {fileExtensions.map((extension) => {
  //                         return <>{extension} </>;
  //                       })}
  //                     </p>
  //                     <p className="mb-3">
  //                       File Size -{" "}
  //                       {orderproduct?.product?.file_statistics?.total_size}
  //                     </p>
  //                   </div>
  //                 </Col>
  //                 <Col sm={2} className="d-flex align-items-center">
  //                   <IconButton
  //                     className="explore-button"
  //                     dark={true}
  //                     text={false}
  //                     style={styles.znanyeDownloadButton}
  //                     onClick={() =>
  //                       productFileDownload(orderproduct?.product?.id)
  //                     }
  //                   >
  //                     <Image src={download} style={styles.downloadItem} />
  //                   </IconButton>
  //                 </Col>
  //                 <div style={styles.underline}></div>
  //               </Row>
  //             </Container>
  //           );
  //         })}
  //       </Breakpoint>
  //       <Breakpoint medium down>
  //         {orderproducts[0]?.map((orderproduct) => {
  //           const fileExtensions = Object.keys(
  //             orderproduct?.product?.file_statistics?.extension_stats
  //           );
  //           return (
  //             <Container fluid className="px-0">
  //               <div className="d-flex">
  //                 <div>
  //                   <Link to={`/product/` + orderproduct.product?.id}>
  //                     <Card
  //                       className="mt-4 ms-3 d-flex justify-content-center align-items-center"
  //                       style={styles.successfulImageMobileCard}
  //                       dark={true}
  //                       key={orderproduct.product?.id}
  //                     >
  //                       <Image
  //                         src={
  //                           orderproduct.product?.productimage_set.length !== 0
  //                             ? orderproduct.product?.productimage_set[0]
  //                                 ?.image_file
  //                             : imagenotavailable
  //                         }
  //                         style={{ maxWidth: 100, maxHeight: 100 }}
  //                       />
  //                     </Card>
  //                   </Link>
  //                 </div>
  //                 <div>
  //                   <div className="ms-3 mt-4">
  //                     <div className="body-text2">Product Name</div>
  //                     <div className="subtitle mb-4">
  //                       {orderproduct.product?.title}
  //                     </div>
  //                     <div className="body-text2">File Format Available</div>
  //                     <div className="subtitle mb-4">
  //                       {fileExtensions?.map((extension) => {
  //                         return <>{extension} </>;
  //                       })}
  //                     </div>
  //                     <div className=" mb-4 d-flex justify-content-between">
  //                       <div>
  //                         <div className="body-text2">File Size</div>
  //                         <div className="subtitle">
  //                           {orderproduct?.product?.file_statistics?.total_size}
  //                         </div>
  //                       </div>
  //                       <div>
  //                         <IconButton
  //                           className="explore-button mt-1"
  //                           dark={true}
  //                           text={false}
  //                           style={styles.znanyeDownloadButton}
  //                           onClick={() =>
  //                             productFileDownload(orderproduct?.product?.id)
  //                           }
  //                         >
  //                           <Image src={download} style={styles.downloadItem} />
  //                         </IconButton>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div style={styles.underline}></div>
  //             </Container>
  //           );
  //         })}
  //       </Breakpoint>
  //     </>
  //   );
  // } else if (param1 == "favourites") {
  //   return (
  //     <>
  //       <Breakpoint large up>
  //         {items?.map((element, index) => {
  //           let item = items[items.length - 1 - index];
  //           let coupons = [];
  //           item?.product?.coupons_data.map((item) =>
  //             coupons.push({ value: item.id, label: item.code })
  //           );
  //           const fileExtensions = Object.keys(
  //             item?.product?.file_statistics?.extension_stats
  //           );
  //           return (
  //             <Card
  //               className="position-relative m-5 mx-lg-4 mt-0 p-0 d-flex flex-column item-details-card"
  //               dark={true}
  //               key={item?.product?.id}
  //             >
  //               <Row>
  //                 <Col
  //                   sm="auto"
  //                   as={Link}
  //                   to={`/product/` + item.product_id}
  //                   style={{ textDecoration: "none" }}
  //                 >
  //                   <Card
  //                     className="mt-5 ms-5 d-flex justify-content-center align-items-center"
  //                     style={styles.itemImageCard}
  //                     dark={true}
  //                   >
  //                     <Image
  //                       src={
  //                         item?.product?.productimage_set.length != 0
  //                           ? item?.product?.productimage_set[0]?.image_file
  //                           : imagenotavailable
  //                       }
  //                       style={{ maxWidth: 150, maxHeight: 150 }}
  //                     />
  //                   </Card>
  //                 </Col>
  //                 <Col className="mt-5">
  //                   <p>
  //                     Product Name - <b>{item?.product?.title}</b>
  //                   </p>
  //                   <p>
  //                     File Formats -{" "}
  //                     {fileExtensions.map((extension) => {
  //                       return <b>{extension} </b>;
  //                     })}
  //                   </p>
  //                   <p>
  //                     File Size -{" "}
  //                     <b>{item?.product?.file_statistics?.total_size}</b>
  //                   </p>
  //                 </Col>
  //               </Row>
  //               <div className="mt-3 mx-5" style={styles.underline}></div>
  //               <div className="mx-5 my-2">
  //                 <Row>
  //                   <Col sm={5}>
  //                     <p>
  //                       Applicable Coupons -{" "}
  //                       {item?.product?.coupons_data.map((item) => {
  //                         return (
  //                           <Badge
  //                             className="p-2 m-1 body-text2"
  //                             bg="black-gray"
  //                           >
  //                             {item.code}
  //                           </Badge>
  //                         );
  //                       })}
  //                     </p>
  //                   </Col>
  //                   <Col
  //                     sm={2}
  //                     className="d-flex flex-column justify-content-center align-items-center"
  //                   >
  //                     <h4 className="text-primary mb-0 text-center">
  //                       {getSymbolFromCurrency(currency.name) +
  //                         " " +
  //                         (item?.product?.price * currency.rate).toFixed(2)}
  //                     </h4>
  //                   </Col>
  //                   <Col
  //                     sm={5}
  //                     className="d-flex justify-content-end align-items-center"
  //                   >
  //                     <AddToCartButton product={item?.product} />
  //                   </Col>
  //                 </Row>
  //               </div>
  //               <div className="d-flex justify-content-center removeFavouriteButtonRight position-absolute">
  //                 <Heart
  //                   isClick={true}
  //                   onClick={() => handleFavorite(item.product_id, item.user)}
  //                 />
  //               </div>
  //             </Card>
  //           );
  //         })}
  //       </Breakpoint>
  //       <Breakpoint medium down>
  //         <Container fluid className="p-1">
  //           {items?.map((element, index) => {
  //             let item = items[items.length - 1 - index];
  //             let coupons = [];
  //             item?.product?.coupons_data.map((item) =>
  //               coupons.push({ value: item.id, label: item.code })
  //             );
  //             const fileExtensions = Object.keys(
  //               item?.product?.file_statistics?.extension_stats
  //             );
  //             return (
  //               <Card
  //                 className="position-relative m-2 mb-5 p-0 d-flex flex-column"
  //                 dark={true}
  //                 key={item?.product?.id}
  //               >
  //                 <div className="d-flex">
  //                   <div>
  //                     <Link to={`/product/` + item.product_id}>
  //                       <Card
  //                         className="mt-4 ms-4 d-flex justify-content-center align-items-center"
  //                         style={styles.itemImageMobileCard}
  //                         dark={true}
  //                       >
  //                         <Image
  //                           src={
  //                             item?.product?.productimage_set.length != 0
  //                               ? item?.product?.productimage_set[0]?.image_file
  //                               : imagenotavailable
  //                           }
  //                           style={{ maxWidth: 100, maxHeight: 100 }}
  //                         />
  //                       </Card>
  //                     </Link>
  //                     <h4 className="text-primary mt-4 ms-4 text-center">
  //                       {getSymbolFromCurrency(currency.name) +
  //                         " " +
  //                         (item?.product?.price * currency.rate).toFixed(2)}
  //                     </h4>
  //                   </div>
  //                   <div className="ms-3 mt-4">
  //                     <div className="body-text2">Product Name</div>
  //                     <div className="subtitle mb-4">
  //                       {item?.product?.title}
  //                     </div>
  //                     <div className="body-text2">File Format Available</div>
  //                     <div className="subtitle mb-4">
  //                       {fileExtensions?.map((extension) => {
  //                         return <>{extension} </>;
  //                       })}
  //                     </div>
  //                     <div className=" mb-4 d-flex justify-content-between">
  //                       <div>
  //                         <div className="body-text2">Views</div>
  //                         <div className="subtitle">
  //                           {item?.product?.view_count}
  //                         </div>
  //                       </div>
  //                       <div>
  //                         <div className="body-text2">Size</div>
  //                         <div className="subtitle">
  //                           {item?.product?.file_statistics?.total_size}
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div style={styles.underline} />
  //                 <div className="my-4 d-flex justify-content-center">
  //                   <AddToCartButton product={item?.product} />
  //                 </div>
  //                 <div className="d-flex justify-content-center removeFavouriteButtonRight position-absolute">
  //                   <Heart
  //                     isClick={true}
  //                     onClick={() => handleFavorite(item.product_id, item.user)}
  //                   />
  //                 </div>
  //               </Card>
  //             );
  //           })}
  //         </Container>
  //       </Breakpoint>
  //     </>
  //   );
  // }

  // return (
  //   <>
  //     <Breakpoint large up>
  //       {items?.map((element, index) => {
  //         let item = items[items.length - 1 - index];
  //         let coupons = [];
  //         item?.product?.coupons_data.map((item) =>
  //           coupons.push({ value: item.id, label: item.code })
  //         );
  //         let fileExtensions = null;
  //         if (item != null) {
  //           fileExtensions = Object.keys(
  //             item?.product?.file_statistics?.extension_stats
  //           );
  //         }
  //         return (
  //           <Card
  //             className="position-relative my-5 mt-0 p-0 d-flex flex-column item-details-card"
  //             dark={true}
  //             key={item?.product?.id}
  //           >
  //             <Row>
  //               <Col
  //                 sm="auto"
  //                 as={Link}
  //                 to={`/product/` + item?.product?.id}
  //                 style={{ textDecoration: "none" }}
  //               >
  //                 <Card
  //                   className="mt-5 ms-5 d-flex justify-content-center align-items-center"
  //                   style={styles.itemImageCard}
  //                   dark={true}
  //                 >
  //                   <Image
  //                     src={
  //                       item?.product?.productimage_set.length != 0
  //                         ? item?.product?.productimage_set[0]?.image_file
  //                         : imagenotavailable
  //                     }
  //                     style={{ maxWidth: 150, maxHeight: 150 }}
  //                   />
  //                 </Card>
  //               </Col>
  //               <Col className="mt-5">
  //                 <p>
  //                   Product Name - <b>{item?.product?.title}</b>
  //                 </p>
  //                 <p>
  //                   File Formats -{" "}
  //                   {fileExtensions?.map((extension) => {
  //                     return <b>{extension} </b>;
  //                   })}
  //                 </p>
  //                 <p>
  //                   File Size -{" "}
  //                   <b>{item?.product?.file_statistics?.total_size}</b>
  //                 </p>
  //               </Col>
  //             </Row>
  //             <div className="my-3 mx-5" style={styles.underline}></div>
  //             <div className="mx-5 mb-3">
  //               <Row>
  //                 <Col sm={7}>
  //                   <div
  //                     className={`${
  //                       item?.applied_coupon ? "d-block" : "d-none"
  //                     }`}
  //                   >
  //                     <ZDropdown
  //                       options={coupons}
  //                       mode="cartcouponselect"
  //                       handleUpdateCartproduct={handleUpdateCartproduct}
  //                       thisProductId={item?.product_id}
  //                       couponOption={item?.applied_coupon_id}
  //                       is_disabled={isAuthenticated ? false : true}
  //                     />
  //                   </div>
  //                   <div
  //                     className={`${
  //                       item?.applied_coupon ? "d-none" : "d-block"
  //                     }`}
  //                     style={{ color: "#555555" }}
  //                   >
  //                     No Coupons Applied!
  //                   </div>
  //                 </Col>
  //                 <Col
  //                   sm={3}
  //                   className="d-flex flex-column justify-content-center align-items-end"
  //                 >
  //                   <h4 className="text-primary mb-0 text-center">
  //                     {item?.applied_coupon ? (
  //                       item?.applied_coupon?.coupon_category == "ABSOLUTE" ? (
  //                         getSymbolFromCurrency(currency.name) +
  //                         (
  //                           (item?.product?.price -
  //                             item?.applied_coupon?.flat_off) *
  //                           currency.rate
  //                         ).toFixed(2)
  //                       ) : (
  //                         getSymbolFromCurrency(currency.name) +
  //                         (
  //                           (item?.product?.price -
  //                             (item?.product?.price *
  //                               item?.applied_coupon?.flat_off) /
  //                               100) *
  //                           currency.rate
  //                         ).toFixed(2)
  //                       )
  //                     ) : (
  //                       <p>
  //                         {getSymbolFromCurrency(currency.name) +
  //                           (item?.product?.price * currency.rate).toFixed(2)}
  //                       </p>
  //                     )}
  //                   </h4>
  //                 </Col>
  //                 <Col
  //                   sm={2}
  //                   className={`${
  //                     item?.applied_coupon ? "d-flex" : "d-none"
  //                   } flex-column justify-content-center align-items-start`}
  //                 >
  //                   <strike className="text-white">
  //                     {getSymbolFromCurrency(currency.name) +
  //                       (item?.product?.price * currency.rate).toFixed(2)}
  //                   </strike>
  //                 </Col>
  //               </Row>
  //               {/* <div className={`${item?.applied_coupon ? "my-3" : "d-none"}`}>
  //                 {item?.applied_coupon_id === item?.product?.best_coupon ? (
  //                   <p style={styles.couponColor}>Best Coupon applied</p>
  //                 ) : (
  //                   ""
  //                 )}
  //               </div> */}
  //             </div>
  //             <IconButton
  //               size="large"
  //               className="d-flex justify-content-center removeButtonRight position-absolute"
  //             >
  //               <AiOutlineClose
  //                 size={20}
  //                 onClick={() => handleRemoveCartproduct(item.product?.id)}
  //               />
  //             </IconButton>
  //           </Card>
  //         );
  //       })}
  //     </Breakpoint>
  //     <Breakpoint medium down>
  //       {items?.map((element, index) => {
  //         let item = items[items.length - 1 - index];
  //         let coupons = [];
  //         item?.product?.coupons_data.map((item) =>
  //           coupons.push({ value: item.id, label: item.code })
  //         );
  //         const fileExtensions = Object.keys(
  //           item?.product?.file_statistics?.extension_stats
  //         );
  //         return (
  //           <Card
  //             className="position-relative m-2 mb-5 p-0 d-flex flex-column"
  //             dark={true}
  //             key={item?.product?.id}
  //           >
  //             <div className="d-flex">
  //               <div className="d-flex flex-column">
  //                 <Link to={`/product/` + item?.product_id}>
  //                   <Card
  //                     className="mt-4 ms-4 d-flex justify-content-center align-items-center"
  //                     style={styles.itemImageMobileCard}
  //                     dark={true}
  //                   >
  //                     <Image
  //                       src={
  //                         item?.product?.productimage_set.length != 0
  //                           ? item?.product?.productimage_set[0]?.image_file
  //                           : imagenotavailable
  //                       }
  //                       style={{ maxWidth: 100, maxHeight: 100 }}
  //                     />
  //                   </Card>
  //                 </Link>
  //                 <h4 className="text-primary mt-4 ms-4 text-center">
  //                   {item?.applied_coupon ? (
  //                     item?.applied_coupon?.coupon_category == "ABSOLUTE" ? (
  //                       getSymbolFromCurrency(currency.name) +
  //                       (
  //                         (item?.product?.price -
  //                           item?.applied_coupon?.flat_off) *
  //                         currency.rate
  //                       ).toFixed(2)
  //                     ) : (
  //                       getSymbolFromCurrency(currency.name) +
  //                       (
  //                         (item?.product?.price -
  //                           (item?.product?.price *
  //                             item?.applied_coupon?.flat_off) /
  //                             100) *
  //                         currency.rate
  //                       ).toFixed(2)
  //                     )
  //                   ) : (
  //                     <p>
  //                       {getSymbolFromCurrency(currency.name) +
  //                         (item?.product?.price * currency.rate).toFixed(2)}
  //                     </p>
  //                   )}
  //                 </h4>
  //                 <strike
  //                   className={`${
  //                     item?.applied_coupon ? "d-block" : "d-none"
  //                   } text-white ms-4 text-center`}
  //                 >
  //                   {getSymbolFromCurrency(currency.name) +
  //                     (item?.product?.price * currency.rate).toFixed(2)}
  //                 </strike>
  //               </div>
  //               <div className="ms-3 mt-4">
  //                 <div className="body-text2">Product Name</div>
  //                 <div className="subtitle mb-4">{item?.product?.title}</div>
  //                 <div className="body-text2">File Format Available</div>
  //                 <div className="subtitle mb-4">
  //                   {fileExtensions?.map((extension) => {
  //                     return <>{extension} </>;
  //                   })}
  //                 </div>
  //                 <div className=" mb-4 d-flex justify-content-between">
  //                   <div>
  //                     <div className="body-text2">Views</div>
  //                     <div className="subtitle">
  //                       {item?.product?.view_count}
  //                     </div>
  //                   </div>
  //                   <div>
  //                     <div className="body-text2">Size</div>
  //                     <div className="subtitle">
  //                       {item?.product?.file_statistics?.total_size}
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div
  //               className={`${item?.applied_coupon ? "d-block" : "d-none"} `}
  //               style={styles.underline}
  //             />
  //             <div className={`${item?.applied_coupon ? "m-2" : "d-none"}`}>
  //               <ZDropdown
  //                 options={coupons}
  //                 mode="cartcouponselect"
  //                 handleUpdateCartproduct={handleUpdateCartproduct}
  //                 thisProductId={item?.product_id}
  //                 couponOption={item?.applied_coupon_id}
  //                 is_disabled={isAuthenticated ? false : true}
  //               />
  //             </div>
  //             {/* <div
  //               className={`${item?.applied_coupon
  //                 ? "mx-2 d-flex justify-content-start"
  //                 : "d-none"
  //                 }`}
  //             >
  //               {item?.applied_coupon_id === item?.product?.best_coupon ? (
  //                 <p style={{ color: "green" }}>Best Coupon applied</p>
  //               ) : (
  //                 ""
  //               )}
  //             </div> */}
  //             <IconButton
  //               size="large"
  //               className="d-flex justify-content-center removeButtonRight position-absolute"
  //             >
  //               <AiOutlineClose
  //                 size={20}
  //                 onClick={() => handleRemoveCartproduct(item.product?.id)}
  //               />
  //             </IconButton>
  //           </Card>
  //         );
  //       })}
  //     </Breakpoint>
  //   </>
  // );
};

const styles = {
  itemImageCard: {
    width: 95,
    height: 95,
    backgroundColor: "#2E2E2E",
  },
  itemImageMobileCard: {
    width: 100,
    height: 100,
    borderRadius: "10px",
    backgroundColor: "#2E2E2E",
    // backgroundColor: "#141415",
  },
  itemPriceCard: {
    width: 130,
    height: 55,
    backgroundColor: "#2E2E2E",
  },
  underline: {
    height: 2,
    backgroundColor: "#292929",
  },
  successfulImageCard: {
    width: 95,
    height: 95,
    backgroundColor: "#1f1f1f",
    borderRadius: "10px",
    border: "1px solid #292929",
  },
  successfulImageMobileCard: {
    width: 100,
    height: 100,
    backgroundColor: "#1f1f1f",
  },
  successfulImage: {
    width: 90,
    height: 90,
  },
  znanyeDownloadButton: {
    width: 10,
    height: 20,
    borderRadius: "4px",
  },
  downloadItem: {
    width: "24px",
    height: "24px",
  },
  couponColor: {
    color: "#58D31B",
  },
};

export default CartItem;

import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "./store";
import Routers from "./Routers";

import { overrideThemeVariables } from "ui-neumorphism";
import "react-toastify/dist/ReactToastify.css";

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

import "./App.scss";
import "./App_no_neo.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";

// persistor.purge();

const App = () => {
  setDefaultBreakpoints([
    { xsmall: 0 }, // all mobile devices
    { small: 576 }, // mobile devices (not sure which one's this big)
    { medium: 768 }, // ipad, ipad pro, ipad mini, etc
    { large: 992 }, // smaller laptops
    { xlarge: 1200 }, // laptops and desktops
    { xxlarge: 1400 }, // laptops and desktops
  ]);

  useEffect(() => {
    overrideThemeVariables({
      "--light-bg": "#E9B7B9",
      "--light-bg-dark-shadow": "#ba9294",
      "--light-bg-light-shadow": "#ffdcde",
      "--dark-bg": "#1B1C1D",
      "--dark-bg-dark-shadow": "#000000",
      "--dark-bg-light-shadow": "#555",
      "--primary": "#8672FB",
      "--primary-dark": "#4526f9",
      "--primary-light": "#c7befd",
    });

    //consolelog(process);
  }, []);

  // persistor.flush();

  return (
    <GoogleOAuthProvider clientId="539203210787-o7qctg1psslb9h9b9qj97d7kf3hbm66q.apps.googleusercontent.com">
      <BreakpointProvider>
        <Provider store={store}>
          <Routers />
        </Provider>
      </BreakpointProvider>
    </GoogleOAuthProvider>
  );
};

export default App;

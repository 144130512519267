import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Card as BCard,
  Breadcrumb,
  Button as RButton,
} from "react-bootstrap";
import { Card, CardAction, Button } from "ui-neumorphism";
import { Link, useLocation } from "react-router-dom";
import { BsChevronDoubleDown, BsChevronDoubleUp } from "react-icons/bs";
import { useCurrentBreakpointName } from "react-socks";

// import {
//   bigentrygate,
//   smallentrygate,
//   girlvr,
//   boyvr,
//   ferriswheel,
//   merrygoround,
//   archery,
//   rushikeshveer,
//   ballsyvrjan,
//   deshmanepatil,
//   pastelcube,
// } from "assets_old/business";
import {
  bigentrygate,
  smallentrygate,
  girlvr,
  boyvr,
  ferriswheel,
  merrygoround,
  archery,
  rushikeshveer,
  ballsyvrjan,
  deshmanepatil,
  pastelcube,
} from "assets/business";
import { ZSlider, ReviewSlider, ZBreadcrumb } from "components";
import { Breakpoint } from "react-socks";
import { AnimatedSlider } from "components";
import { Helmet } from "react-helmet";

const rides = [
  {
    id: 1,
    name: "Ferris Wheel",
    image: ferriswheel,
  },
  {
    id: 2,
    name: "Merry Go Round",
    image: merrygoround,
  },
  {
    id: 3,
    name: "Archery",
    image: archery,
  },
  // {
  //   id: 4,
  //   name: "Another Ferris Wheel",
  //   image: ferriswheel,
  // },
];

const reviews = [
  {
    id: 1,
    name: "BallsyVRJan",
    review: `It's the only VR theme park around!
      Look, it's not without imperfections, but it's free and it gives you a virtual reality theme park, there is literally no other Quest 2 game like it! You can see a full exploration and review here: https://youtu.be/Ge75lcVRapU</a>`,
    image: ballsyvrjan,
  },
  {
    id: 2,
    name: "deshmane-patil",
    review:
      "Imaginative Theme Park. Visitors can enjoy rides, as well as an entire theme park, equipped with a food area, the park to play the game.",
    image: deshmanepatil,
  },
  {
    id: 3,
    name: "Pastelcube",
    review: `Comfort. Goes easy on the eyes. Testimonials for ZNanyeland Case study`,
    image: pastelcube,
  },
];

const CaseStudy = () => {
  const location = useLocation();

  const breakpoint = useCurrentBreakpointName();
  const [isReadMore, setIsReadMore] = useState(
    breakpoint == "xsmall" ? false : true
  );

  return (
    <React.Fragment>
      {/* <Helmet>
        <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
      </Helmet> */}
      <Container
        className="h-100 mt-5 pt-5 casestudy-background-case px-lg-5"
        fluid
      >
        <Breakpoint large up>
          <ul className="breadcrumb subtitle mx-4 mb-3">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/business">CaseStudy</a>
            </li>
            <li>ZnanyeLand</li>
          </ul>
          <Card
            className="position-relative mx-4 custom-card"
            dark={true}
            style={styles.cardImage}
          >
            <Image
              className="m-auto"
              src={bigentrygate}
              alt="Znanye Entry Gate"
              style={{ width: "100%" }}
            />
            <CardAction
              className="m-0 p-3 position-absolute d-flex justify-content-between"
              style={styles.cardActionOpacity}
            >
              <h4 className="m-2 text-white">Znanyeland</h4>
              <a
                href="https://api.znanye.com/znanye_game/ZnanyeLand/"
                className="text-decoration-none"
              >
                <RButton
                  className="me-2 text-white explore-button h-50"
                  dark={true}
                >
                  <span className="poppins">WEB VR</span>
                </RButton>
              </a>
            </CardAction>
          </Card>
        </Breakpoint>
        <Breakpoint medium down>
          <BCard
            className="position-relative"
            dark={true}
            style={styles.cardImage}
          >
            <div className="d-flex justify-content-center">
              <Image
                className="m-0"
                src={smallentrygate}
                alt="Znanye Entry Gate"
                style={{ width: 340 }}
              />
            </div>
          </BCard>
          <h2 className="mt-4 text-white d-flex justify-content-center">
            Znanyeland
          </h2>
          {/* <div className="d-flex flex-column justify-content-center align-items-center">
            <a
              href="https://znanye.com/znanye_games/ZnanyeLand/"
              className="text-decoration-none"
            >
              <Button
                className="mt-5 text-white explore-button h-50 w-100"
                dark={true}
              >
                <span className="poppins">WEB VR</span>
              </Button>
            </a>
          </div> */}
        </Breakpoint>
      </Container>
      <Container
        className="d-flex w-100 h-100 pt-5 ps-lg-5 casestudy-background-case text-white text-center"
        fluid
        style={styles.projectContainer}
      >
        <div className="px-lg-4 text-lg-start d-flex flex-column align-items-center align-items-lg-start">
          <div>
            <h4>Why We started this project</h4>
          </div>
          <div style={styles.underline}></div>
          <div className="pe-lg-4">
            <div className="mt-4">
              Pandemic forced us to stay indoors. We missed outdoor activities.
              The child in us missed the fun we were used to. We wanted
              individuals to be able to experience the outdoor life they were
              used to. Relive the life we were missing.
            </div>
          </div>
        </div>
      </Container>
      <Container
        className="d-flex w-100 h-50 pt-5 p-lg-5 casestudy-background-case text-white text-center"
        fluid
        style={styles.solutionContainer}
      >
        <div className="px-lg-4 text-lg-start d-flex flex-column align-items-center align-items-lg-start">
          <div>
            <h4>How we come up with this solution</h4>
          </div>
          <div style={styles.underline}></div>
          <div className="pe-lg-4">
            <div className="mt-4">
              Our earliest memories of an outdoor activity go back with a walk
              in the park with our parents. The park would have rides, people,
              dogs, trees… it was fun! We wanted to recreate this in Znanyeland!
            </div>
            {/* <div>
              Auctor sollicitudin at libero in. Nec quisque nisl faucibus
              fermentum. Magna ac feugiat adipiscing massa eu amet elit
              dignissim augue. Ultrices malesuada enim varius quam pretium
              purus. Cras adipiscing curabitur nulla vulputate porttitor
              volutpat felis. Amet lectus eu elementum velit. Arcu vitae nunc
              ullamcorper faucibus at dignissim felis, scelerisque congue.
              Pellentesque sagittis aenean adipiscing tempor imperdiet felis
              nibh mattis. Dictum nec dui et quisque tempus, vehicula.
            </div>
            <div>
              Quisque urna est, purus amet sed sem ornare eu. Sed porta nunc
              nunc donec nibh eget eget ridiculus. Pharetra dictum ultrices
              velit a adipiscing sit ullamcorper faucibus ut. Non lacus lectus
              tristique feugiat vel. Blandit in enim orci maecenas pellentesque
              eget laoreet mus massa. Tristique feugiat velit nam sagittis,
              ultricies convallis arcu scelerisque. A, molestie mattis auctor
              posuere volutpat, nibh diam odio et. Gravida nisi, aliquet
              volutpat sapien purus at nec. Dictum blandit posuere risus eu.
              Quis viverra pulvinar dui et in quis accumsan id consectetur.
              Facilisis faucibus penatibus vitae arcu vulputate in turpis eu
              tellus. Et scelerisque mauris a vulputate etiam nec venenatis.
              Aenean nulla vitae, euismod gravida enim, nibh tempus suspendisse
              tempor.
            </div>
            <span className={`${isReadMore ? "d-block" : "d-none"}`}>
              <div>
                Aliquet pellentesque congue sed tristique. Risus sapien
                imperdiet odio ullamcorper. Lectus sit id nisl diam odio orci
                tempus non vitae. Erat mauris turpis curabitur ornare. Mi ut sed
                eu facilisi pellentesque ultrices fames. Nibh ut sit egestas
                auctor netus quis. Sed eget praesent dictum varius arcu.
              </div>
              <div>
                Adipiscing a, at aliquam pellentesque diam. Ridiculus nunc
                mauris consectetur porta enim enim consectetur. Quam in cum
                purus, in lectus. Senectus diam vulputate donec.
              </div>
            </span> */}

            {/* <span className={`${isReadMore ? "d-block" : "d-none"}`}>
              <div className="mt-3">
                Velit enim risus aliquet volutpat bibendum pellentesque in. Eu
                nisl volutpat fermentum, leo eget velit. Volutpat arcu amet
                nulla tincidunt nisi integer odio eu morbi. Platea netus nec
                imperdiet vitae. Eget turpis arcu amet faucibus pretium ornare
                imperdiet. Vulputate id tristique pretium proin. Ante tempor, id
                hendrerit quis consectetur dui risus, volutpat, magna. Sed
                habitant interdum eu, amet fermentum sem libero, sed adipiscing.
                Commodo dis dolor, felis augue felis. Urna, etiam velit nec in
                phasellus vel in. Duis urna, tincidunt semper lectus pulvinar
                lorem quisque. Purus fermentum, diam et pellentesque ac semper
                ac at ultricies. Convallis pellentesque adipiscing volutpat hac
                enim. Feugiat eget tellus, sagittis, aliquet sed massa a pretium
                elementum.
              </div>
              <div>
                Leo convallis viverra magna cursus diam. Bibendum pellentesque
                viverra morbi tellus, et et. Porttitor morbi turpis nunc, nam
                vivamus eget vestibulum. Adipiscing ultricies hendrerit viverra
                posuere non. Ultricies ac eros sed mattis tristique quis congue
                nisl nisl. Fermentum adipiscing blandit feugiat scelerisque.
              </div>
              <div>
                Auctor non gravida aliquam, dictumst. Molestie dignissim vivamus
                turpis augue sed morbi. Sed vitae ac purus in quam orci mauris
                vestibulum. Convallis nisl imperdiet blandit porttitor felis
                quis vestibulum. Ultrices senectus in vel pharetra ultricies
                aenean. Luctus faucibus congue mauris, nunc pellentesque eget
                consequat auctor. Sit pharetra enim felis enim in. Id eget
                scelerisque aliquam risus pellentesque. Diam vel, metus
                tincidunt nullam lorem fames.
              </div>
              <div>
                Laoreet in eget luctus id nulla sagittis et. Nunc ut diam
                elementum mauris pulvinar tellus. Neque risus at ac suspendisse
                augue convallis. Egestas neque, varius lorem viverra cursus.
                Enim, venenatis, vel posuere lectus lacus scelerisque at.
                Interdum arcu quam at fames. Tristique felis, volutpat, mattis
                velit vestibulum, lectus mollis ut ultricies. Mi massa sagittis
                pulvinar vitae ultricies natoque at arcu. Risus pretium pretium,
                tincidunt nibh rhoncus rhoncus dolor. Feugiat ac mauris erat
                faucibus diam viverra. Quam rhoncus cursus imperdiet ultrices
                diam. Nec laoreet sapien viverra nibh diam. Adipiscing tristique
                pharetra varius nulla. Quis consectetur blandit viverra tortor
                quis at sit.
              </div>
            </span> */}
          </div>
          <Container className="w-100 d-flex justify-content-center">
            <Row>
              {rides.map((item, index) => {
                return (
                  <Col md={4}>
                    <Card
                      className="position-relative my-4 mx-lg-4 d-flex justify-content-center custom-card"
                      dark={true}
                      style={styles.cardImage}
                    >
                      <Image
                        className="m-0"
                        src={item.image}
                        alt={item.name}
                        style={{ width: "100%" }}
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>
            {/* <Card
                className="position-relative my-3 mx-lg-3"
                dark={true}
                style={styles.cardImage}
              >
                <Image className="m-0" src={girlvr} style={{ width: "100%" }} />
              </Card>
              <Card
                className="position-relative my-3 mx-lg-3"
                dark={true}
                style={styles.cardImage}
              >
                <Image className="m-0" src={boyvr} style={{ width: "100%" }} />
              </Card> */}
          </Container>
          <div
            className={`${
              isReadMore ? "d-block" : "d-none"
            } d-flex flex-column align-items-center align-items-lg-start`}
          >
            <div className="mt-5">
              <h4>How does this satisfy our users</h4>
            </div>
            <div style={styles.underline}></div>
            <div className="pe-lg-4">
              <div className="mt-4">
                Most of us remember holds the hands of our parents and walking
                tio parks and carnivals. We were in our homes for over 2 years
                now. We missed our outdoor life. Znanayeland allows us to
                virtually walk in the park. See the rides, see the dogs bark and
                the overhead rail zoom past. It brings back the memories you
                were longing to experience allover.
              </div>
              {/* <div>
                Dolor, tristique in sit parturient tristique bibendum in.
                Montes, eleifend vitae massa sit sapien nibh consequat neque.
                Commodo, commodo eleifend felis tempus risus pulvinar adipiscing
                ac. Elit purus eget elementum id auctor. Aliquet erat proin
                magna lacus nisl, gravida id. Faucibus posuere felis in congue.
                Vulputate volutpat convallis nunc convallis senectus interdum.
                Vulputate nunc nisl adipiscing molestie porttitor.
              </div> */}
            </div>
          </div>
        </div>
      </Container>
      <Breakpoint medium down>
        <Container
          className="d-flex w-100 flex-column justify-content-center align-items-center h-100 pt-3"
          fluid
        >
          <div
            className="text-secondary poppins-500"
            style={styles.readMoreButton}
            onClick={() => {
              setIsReadMore(!isReadMore);
            }}
          >
            {isReadMore ? (
              <>
                READ LESS <BsChevronDoubleUp size="18" />
              </>
            ) : (
              <>
                READ MORE <BsChevronDoubleDown size="18" />
              </>
            )}
          </div>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          className="d-flex w-100 flex-column justify-content-center align-items-center h-100 pt-5"
          fluid
          style={styles.userReviewContainer}
        >
          <Container style={{ height: "100%", margin: -40 }}>
            <div className="my-5">
              <ZSlider type="review" items={reviews} />
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint large up>
        <Container
          className="d-flex w-100 flex-column justify-content-center align-items-center casestudy-background-case"
          fluid
          style={styles.userReviewContainer}
        >
          <Container style={{ height: "100%" }}>
            <div className="my-5">
              <AnimatedSlider reviews={reviews} />
            </div>
          </Container>
        </Container>
      </Breakpoint>
      {/* <Container
        className="d-flex flex-column w-100 h-100 pt-5 px-1 px-lg-5 casestudy-background-case text-white align-items-center align-items-lg-start"
        fluid
        style={styles.ridesContainer}
      >
        <div className="px-5 d-flex flex-column align-items-center align-items-lg-start">
          <h4>Rides you can enjoy</h4>
          <div style={styles.underline}></div>
        </div>
        <Container style={{ height: "100%" }}>
          <div className="my-5">
            <ZSlider type="casestudy" items={rides} />
          </div>
        </Container>
      </Container> */}
    </React.Fragment>
  );
};

const styles = {
  gateContainer: {
    minHeight: "90vh",
  },
  projectContainer: {
    minHeight: "20vh",
  },
  solutionContainer: {
    minHeight: "50vh",
  },
  userReviewContainer: {
    minHeight: "50vh",
  },
  ridesContainer: {
    minHeight: "50vh",
  },
  cardActionOpacity: {
    backgroundColor: "rgba(0,0,0,0.8)",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    bottom: 0,
    right: 0,
    left: 0,
  },
  cardImage: {
    borderRadius: 20,
    backgroundColor: "#1f1f1f",
  },
  underline: {
    height: 1,
    width: 120,
    backgroundColor: "white",
  },
  readMoreButton: {
    color: "red",
  },
};

export default CaseStudy;

import * as actions from "actions/AuthActions";
import { toast } from "react-toastify";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const notify = (msg, type) => {
  switch (type) {
    case "success":
      toast.success(msg);
      break;
    case "error":
      toast.error(msg);
      break;
    case "warning":
      toast.warning(msg);
      break;
    default:
      toast.error(msg);
  }
  // alert();
};

const razorpayCheckout = async (params, successNavigation, onFailure) => {
  //consolelog(params);
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  //consolelog("---------&&&&&&&&&&&&-----",params)

  if (!res) {
    notify("Razorpay SDK failed to load. Are you online?", "error");
    return;
  }

  // creating a new order

  // Getting the order details back
  // const { amount, id: order_id, currency } = result.data;

  const options = {
    key: process.env.REACT_APP_RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
    // amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "USD",

    name: "PastelCube",
    description: "This transaction is for buying Pastecube products.",
    image:
      // "https://znanye-bucket.s3.amazonaws.com/static/znanye/pastelcube.png",
      "https://api.znanye.com/static/znanye/pastelcube.png",
    // callback_url: 'http://api.znanyetest.teson.co.in/v1/order/upgrade_order/',
    order_id: params.order.razorpay_order_id, 
    amount: params.order.total*100, 
    //This is a sample Order ID. Pass the `id` obtained in the previous step
    international: true,
    handler: async function (response) {
      //consolelog(response);
      // alert(response.razorpay_payment_id);
      // alert(response.razorpay_order_id);
      // alert(response.razorpay_signature);
      console.log(response);
      try {
        const payment_response = await actions.upgradeOrder(response);
        //consolelog(payment_response);
        if (payment_response) {
          successNavigation("/cart/thankyou?orderId=" + payment_response.order_id);
          notify(payment_response.msg, "success");
        }
        return payment_response;
      } catch (err) {
        notify("Order is having some issue. Wait for 20 minutes.", "error");
        onFailure();
      }

      // post razorpay payment details to znanye server
    },
    prefill: {
      name: params.name,
      email: params.email,
      contact: params.contact,
    },
    notes: {
      address: "Pastelcube Order Payment",
    },
    theme: {
      color: "#111",
    },
    modal: {
      ondismiss: function () {
        onFailure();
      },
    },
  };

  const paymentObject = new window.Razorpay(options).on("close", function () {
    alert();
  });
  paymentObject.on("payment.failed", function (response) {
    onFailure();
    notify(response.error, "error");
  });
  paymentObject.on("payment.success", function (response) {
    // notify(response.error, "error");
    //consolelog(response);
    return response;
  });
  paymentObject.open();
};

export default razorpayCheckout;

import React, { useState, useEffect } from "react";
import "./OrderCard.scss";
import { OrderProductCard } from "components";

const OrderCard = ({ items, retryPayment }) => {

  const getOrderStatus = (order_status) => {
    switch (order_status) {
      case "PL":
        return "Successful";
      case "CL":
        return "Cancelled";
      default:
        return "Pending";
    }
  };

  // return (
  //   <>
  //     {items.map((item) => {
  //       return (
  //         <Card className="mb-5 w-100 order-card" dark={true} key={item?.id}>
  //           <div
  //             className="m-0 px-4 py-3 text-primary w-100"
  //             style={styles.cardAction}
  //           >
  //             <Row>
  //               <Col sm={3}>
  //                 <div>{getOrderStatus(item.order_status)}</div>
  //                 <div className="text-white">
  //                   {moment(item.timestamp).format("DD MMMM YYYY")}
  //                 </div>
  //               </Col>
  //               <Col sm={4}>
  //                 <div>Total</div>
  //                 <div className="text-white">₹ {item.total}</div>
  //               </Col>
  //               <Col sm={2} className="d-flex flex-column align-items-end">
  //                 {item.order_status == "PL" ? <div>
  //                   <div>INVOICE</div>
  //                   <a className="text-white" href="#" onClick={(e) => {
  //                     e.preventDefault();
  //                     actions.downloadInvoice(item.id);
  //                   }}>Download</a>
  //                 </div> : (
  //                   <div className="d-flex flex-column align-items-end">
  //                     <Button
  //                       dark={true}
  //                       className="explore-button"
  //                       onClick={(e) => {
  //                         e.preventDefault();
  //                         retryPayment(item);
  //                       }}
  //                     >
  //                       Retry Payment
  //                     </Button>
  //                   </div>
  //                 )}
  //               </Col>
  //               <Col sm={3} className="d-flex flex-column align-items-end">
  //                 <div>ORDER #</div>
  //                 <div className="text-white">{item.order_id}</div>
  //               </Col>
  //             </Row>
  //           </div>
  //           <div>
  //             {item.orderproducts.map((orderproduct) => {
  //               let applied_coupon = orderproduct?.product?.coupons_data.filter(
  //                 (coupon) => coupon.id === orderproduct?.coupon
  //               );
  //               let discounted_price =
  //                 applied_coupon[0]?.coupon_category === "ABSOLUTE"
  //                   ? orderproduct?.product?.price - applied_coupon[0]?.flat_off
  //                   : orderproduct?.product?.price -
  //                   (orderproduct?.product?.price *
  //                     applied_coupon[0]?.flat_off) /
  //                   100;
  //               console.log("discounted_price", discounted_price);
  //               return (
  //                 <Container className=" border-top h-100n w-100">
  //                   <Row>
  //                     <Col sm={2}>
  //                       <Card
  //                         className="my-3"
  //                         dark={true}
  //                         style={styles.itemImageCard}
  //                       >
  //                         <Image
  //                           src={
  //                             orderproduct?.product?.productimage_set[0]
  //                               .image_file
  //                           }
  //                           style={{ width: 100, height: 100 }}
  //                         />
  //                       </Card>
  //                     </Col>
  //                     <Col sm={5}>
  //                       <div className="mt-3">
  //                         <p className="text-primary">
  //                           <Link
  //                             to={
  //                               `/product/` +
  //                               orderproduct?.product?.id +
  //                               "#viewproduct"
  //                             }
  //                             style={{ textDecoration: "none" }}
  //                           >
  //                             {orderproduct?.product?.title}
  //                           </Link>
  //                         </p>
  //                         <div
  //                           className={`${orderproduct?.coupon ? "d-block" : "d-none"
  //                             }`}
  //                         >
  //                           <p className="m-0">
  //                             <strike>₹ {orderproduct?.price}</strike>
  //                             <span className="text-primary">
  //                               {" "}
  //                               ₹ {discounted_price}
  //                             </span>
  //                           </p>
  //                         </div>
  //                         <div
  //                           className={`${orderproduct?.coupon ? "d-none" : "d-block"
  //                             }`}
  //                         >
  //                           <p className="m-0 text-primary">
  //                             ₹ {orderproduct?.price}
  //                           </p>
  //                         </div>
  //                         <div
  //                           className={`${orderproduct?.coupon ? "d-block" : "d-none"
  //                             }`}
  //                         >
  //                           <p className="coupon-applied">
  //                             Coupon Applied -{" "}
  //                             <spam className="coupon-applied-text">
  //                               {applied_coupon[0]?.code}
  //                             </spam>
  //                           </p>
  //                         </div>
  //                       </div>
  //                     </Col>
  //                     <Col
  //                       sm={5}
  //                       className="d-flex flex-column align-items-end"
  //                     >
  //                       {item.order_status == "PL" && (
  //                         <div className="d-flex flex-column justify-content-between align-items-end my-3 h-100">
  //                           <IconButton
  //                             className="mb-2 explore-button"
  //                             dark={true}
  //                             text={false}
  //                             style={styles.znanyeDownloadButton}
  //                             onClick={() =>
  //                               productFileDownload(orderproduct?.product?.id)
  //                             }
  //                           >
  //                             <Image
  //                               src={download}
  //                               style={styles.downloadItem}
  //                             />
  //                           </IconButton>
  //                           <Link
  //                             to={
  //                               `/product/` +
  //                               orderproduct?.product?.id +
  //                               "#review"
  //                             }
  //                             style={{ textDecoration: "none" }}
  //                           >
  //                             <Button dark={true}>
  //                               Write a product review
  //                             </Button>
  //                           </Link>
  //                         </div>
  //                       )}
  //                     </Col>
  //                   </Row>
  //                 </Container>
  //               );
  //             })}
  //           </div>
  //         </Card>
  //       );
  //     })}
  //   </>
  // );

  return (
    <>
      {/* <Breakpoint large up> */}
      {items.map((item) => {
        return (
          <OrderProductCard item={item} retryPayment={retryPayment} />
        );
      })}
    </>
  );
};

const styles = {
  itemImageCard: {
    width: 150,
    height: 150,
    backgroundColor: "#2E2E2E",
  },
  itemImageMobileCard: {
    width: 100,
    height: 100,
    backgroundColor: "#2E2E2E",
  },
  itemPriceCard: {
    width: 130,
    height: 55,
    backgroundColor: "#2E2E2E",
  },
  underline: {
    height: 2,
    backgroundColor: "#292929",
  },
  orderDivision: {
    height: 2,
    width: "100%",
    backgroundColor: "#595959",
  },
  successfulImageCard: {
    width: 80,
    height: 80,
    backgroundColor: "#1f1f1f",
  },
  successfulImage: {
    width: 70,
    height: 70,
  },
  znanyeDownloadButton: {
    width: 10,
    height: 20,
    borderRadius: "4px",
  },
  downloadItem: {
    width: "24px",
    height: "24px",
  },
  cardAction: {
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#111",
  },
};

export default OrderCard;

import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Spinner,
  Button as RButton,
} from "react-bootstrap";
import { Button as NuButton, IconButton } from "ui-neumorphism";
// import { loginright } from "assets_old/admin";
import { loginright } from "assets/admin";
import * as actions from "actions/AuthActions";
import * as userActions from "actions/UserActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { eye, eye_off } from "assets";

const Login = () => {
  const [next, setNext] = useState(false);
  const [username, setUsername] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("+91");
  const [isSending, setIsSending] = useState(false);
  const [eyeOn, setEyeOn] = useState(false);

  const form = useRef(null);
  const dispatch = useDispatch();

  const onLogin = async (e) => {
    e.preventDefault();
    setIsSending(true);
    let formData = new FormData(form.current);
    formData.set("admin", true);
    const response = await actions.loggingIn(formData);
    if (response) {
      dispatch(actions.login(response));
      setIsSending(false);
    } else {
      setNext(true);
      setIsSending(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onLogin(e);
    }
  };

  useEffect(async () => {
    const response = await userActions.loadCountries();
    setCountries(response);
  }, []);

  return (
    <React.Fragment>
      <Container
        className="h-100 text-center home-background-categories pt-2"
        fluid
        style={styles.parentContainer}
      >
        <Container
          className="d-flex flex-column justify-content-center align-items-center pt-5 mt-5"
          style={{ height: "100%" }}
        >
          <Row>
            <Col
              sm={5}
              className="d-flex flex-column justify-content-center align-items-start"
            >
              <p className="body-text2 text-white">Welcome To Znanye</p>
              <p className="h3 poppins-400 text-primary mb-3 user-select-none text-start">
                Upload Your 3D Models
              </p>
              <Form
                ref={form}
                className="text-start"
                onSubmit={(e) => onLogin(e)}
              >
                <Form.Group className="my-4 w-100" controlId="formUsername">
                  <Form.Label className="text-white">Enter Email</Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      name="email"
                      type="text"
                      size="lg"
                      placeholder="Enter your Email"
                      className="text-white"
                      style={{ background: "#141414", height: 50 }}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="my-4 w-100" controlId="formOtp">
                  <Form.Label className="text-white">Enter Password</Form.Label>
                  <div className="d-flex justify-content-end align-items-center position-relative">
                    <Form.Control
                      name="password"
                      type={`${eyeOn ? "text" : "password"}`}
                      size="lg"
                      placeholder="Enter your Password"
                      className="text-white"
                      style={{ background: "#141414", height: 50 }}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        if (e.target.value != "" && username != "") {
                          setNext(true);
                        } else {
                          setNext(false);
                        }
                      }}
                    />
                    {!eyeOn && (
                      <RButton
                        type="button"
                        className="wrapped-button position-absolute mx-1"
                        style={{ backgroundColor: "#141414", border: 0 }}
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                          e.preventDefault();
                          setEyeOn(true);
                        }}
                      >
                        <Image src={eye} alt="Eye" />
                      </RButton>
                    )}
                    {eyeOn && (
                      <RButton
                        type="button"
                        className="wrapped-button position-absolute mx-1"
                        style={{ backgroundColor: "#141414", border: 0 }}
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                          e.preventDefault();
                          setEyeOn(false);
                        }}
                      >
                        <Image src={eye_off} alt="Eye Off" />
                      </RButton>
                    )}
                  </div>
                </Form.Group>
                <RButton
                  className="btn btn-link border-0 wrapped-button w-100 p-0"
                  style={{
                    background: "transparent",
                    textDecoration: "none",
                  }}
                  type="submit"
                  disabled={isSending}
                >
                  <NuButton
                    // className={`py-4 body-text2 w-100 ${!next
                    //   ? "explore-button"
                    //   : "znanye-button znanye-button-large"
                    //   }`}
                    className="py-4 body-text2 w-100 explore-button znanye-button-large"
                    disabled={isSending}
                    size="large"
                    dark={true}
                  >
                    {isSending ? (
                      <span>
                        <Spinner
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        LOGGING ...
                      </span>
                    ) : (
                      <span className="poppins">LOGIN</span>
                    )}
                  </NuButton>
                </RButton>
              </Form>
            </Col>
            <Col sm={7}>
              <Image src={loginright} alt="Login" />
            </Col>
          </Row>
        </Container>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
};

export default Login;

import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Spinner,
  Button as RButton,
} from "react-bootstrap";
import {
  Button as NuButton,
  Carousel,
  CarouselItem,
  IconButton,
} from "ui-neumorphism";
// import { loginright } from "assets_old/admin";
import { loginright } from "assets/admin";
import {
  bicycle,
  calender,
  graph,
  LoginPose,
  eye,
  eye_off,
  seller_signup_back,
} from "assets";
// import { earnmore, inspiration, recognized } from "assets_old/business";
import { earnmore, inspiration, recognized } from "assets/business";

import {
  seller_login_slide_1,
  seller_login_slide_2,
  seller_login_slide_3,
  seller_login_slide_4,
} from "assets";
import * as actions from "actions/AuthActions";
import * as userActions from "actions/UserActions";
import { useDispatch } from "react-redux";
import { BsCircle } from "react-icons/bs";
import { toast } from "react-toastify";
import { IoChevronDown } from "react-icons/io5";
import { Breakpoint } from "react-socks";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  login_emoji,
  signup_seller_emoji,
  create_account_mascot,
  email_sent,
  celebrate_mascot,
  forgot_mascot,
  happy,
} from "assets";
import AppleGoogleFacebookLogin from "components/LoginBox/AppleGoogleFacebookLogin";

const SellerLogin = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState("");
  const [consent, setConsent] = useState(false);
  const [next, setNext] = useState(false);
  const [forOtp, setForOtp] = useState(false);
  const [timer, setTimer] = useState(0);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState();
  const [isSending, setIsSending] = useState(false);
  const [changingBorder, setChangingBroder] = useState(false);
  const [login, setLogin] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [emailSentNote, setEmailSentNote] = useState(false);
  const [emailResetNote, setEmailResetNote] = useState(false);
  const [resetSending, setResetSending] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eyeOn, setEyeOn] = useState(false);
  const [eyeOnTwo, setEyeOnTwo] = useState(false);
  const uniqueCountries = [];
  // const [isSelectOpen, setIsSelectOpen] = useState(false);
  // const selectRef = useRef();

  const form = useRef(null);
  const consentRef = useRef(null);
  const userForm = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  const handleNavigationClick = (url) => {
    window.location.href = url;
  };
  useEffect(() => {
    if (isAuthenticated) {
      handleNavigationClick("/sell-3d/uploadproducts");
      // navigate("/sell-3d/uploadproducts");
    }
  }, [isAuthenticated]);

  // const handleToggleSelect = () => {
  //   setIsSelectOpen((prevState) => !prevState);
  //   if (isSelectOpen) {
  //     selectRef.current.focus();
  //   }
  // };

  // useEffect(()=>{
  //   console.log("@@",isSelectOpen)

  // },[isSelectOpen])

  const onLogin = async (e) => {
    e.preventDefault();
    setIsSending(true);
    const formData = new FormData(form.current);
    const response = await actions.loggingIn(formData);
    if (response) {
      dispatch(actions.login(response));
      setIsSending(false);
    } else {
      setNext(true);
      setIsSending(false);
    }
    // const response = await actions.updateProfile(formData, user.id);
    // if (response) {
    //   dispatch(actions.profileUpdate(response));
    //   setLoginShow(false);
    // }
    // setIsSubmitting(false);
  };
  // const checkAccountVerification = async (e) => {
  //   const response = await actions.checkAccountVerification({
  //     username: username,
  //   });
  //   if (response) {
  //     ////console.log(response.status);
  //     return response.status;
  //   }
  // };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(userForm.current);
    formData.append("is_seller", true);
    formData.set("phone_code", formData.get("phone_code").split("__")[0]);
    if (formData.get("password") === formData.get("confirm_password")) {
      const response = await actions.signUp(formData);
      if (response) {
        switch (response.status) {
          case "ALREADY_EXISTS":
          case "PROVIDE_ALL_DETAILS":
            toast.error(response.msg);
            break;
          case "UNEXPECTED":
            toast.error(response.msg);
            break;
          case "SUCCESSFUL":
            //   toast.success(response.msg);
            //   setCreateAccount(false);
            //   setAccountCreated(true);
            //   setTimeout(() => { setLogin(true); setAccountCreated(false); }, 5000);
            //   break;
            // default:
            //   break;
            setCreateAccount(false);
            setEmailSentNote(true);

            const interval = setInterval(async () => {
              const response = await actions.checkAccountVerification({
                username: username,
              });
              if (response.status) {
                clearInterval(interval);
                setEmailSentNote(false);
                setAccountCreated(true);
              }
            }, 10000);

            break;
          default:
            break;
        }
      }
    } else {
      toast.error("Password and Confirm Password doesn't match!");
    }
    setIsSubmitting(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onLogin(e);
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();
    setResetSending(true);
    let email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (username.match(email_regex)) {
      const response = await actions.reset_password_request({
        email: username,
      });
      if (response) {
        switch (response.status) {
          case "DOES_NOT_EXIST":
            // toast.error(response.msg);
            break;
          case "EMAIL_SENT":
            setForgotPassword(false);
            setEmailResetNote(true);
            break;
          default:
            alert("some error");
        }
      }
    } else {
      setResetSending(false);

      toast.error("Please enter a valid email!");
    }
    setResetSending(false);
  };

  const fetchCountries = async () => {
    const response = await userActions.loadCountries();
    setCountries(response);
    return () => {
      setTimer(0);
    };
  };
  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  return (
    <React.Fragment>
      <Breakpoint large up>
        <Container fluid>
          <Row>
            <Col className="p-0">
              <Container
                className="d-flex flex-column justify-content-center home-background-categories h-100"
                style={styles.firstHalfContainer}
                fluid
              >
                <div className="pe-3 me-5">
                  <div className={`${login ? "d-block" : "d-none"}`}>
                    <p className="title-text-seller-login text-white pb-4 user-select-none text-start">
                      {/* <img src={seller_signup_back}  */}
                      {/* // onClick={()=>setLoginShow(false)} */}
                      Happy to See You again{" "}
                      <span>
                        <img src={login_emoji} alt="Happy Emoji" />
                      </span>
                    </p>
                  </div>
                  <div className={`${!createAccount ? "d-none" : "d-block"}`}>
                    <p className="title-text-seller-login text-white pb-4 user-select-none text-start">
                      <span
                        onClick={() => {
                          setForgotPassword(false);
                          setLogin(true);
                          setCreateAccount(false);
                          setEmailSentNote(false);
                          setEmailResetNote(false);
                          setAccountCreated(false);
                        }}
                        className="cursor-pointer"
                      >
                        <img src={seller_signup_back} alt="Go Back" />
                      </span>

                      <span className="mx-2">
                        We are happy that you choose us!
                      </span>
                      <span>
                        <img src={signup_seller_emoji} alt="Happy Emoji" />
                      </span>
                    </p>
                  </div>
                  <div className={`${forgotPassword ? "d-block" : "d-none"}`}>
                    <p className="title-text-seller-login text-white pb-2 user-select-none text-start">
                      <span
                        onClick={() => {
                          setForgotPassword(false);
                          setLogin(true);
                          setCreateAccount(false);
                          setEmailSentNote(false);
                          setEmailResetNote(false);
                          setAccountCreated(false);
                        }}
                        className="cursor-pointer"
                      >
                        <img src={seller_signup_back} alt="Go Back" />
                      </span>

                      <span className="mx-2">
                        Don't worry ! we've got your back
                      </span>
                      <span>
                        <img src={happy} alt="Happy Emoji" />
                      </span>
                    </p>
                  </div>
                  <div className={`${login ? "d-block" : "d-none"}`}>
                    <p className="subtitle text-white mb-2 user-select-none text-start">
                      Login to Znanye
                    </p>
                    <p className="body-text2 pb-3 text-white">
                      Sell Your artwork to the world
                    </p>
                  </div>
                  <div
                    className={`${
                      !isAuthenticated && createAccount ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="subtitle text-white mb-2 user-select-none text-start">
                      Create Account
                    </p>
                    <p className="body-text2 pb-3 text-white">
                      Enter Your Details
                    </p>
                  </div>
                  {(createAccount || login) && (
                    <AppleGoogleFacebookLogin
                      buyer={false}
                      seller={true}
                      onLogin={onLogin}
                      onSignUp={handleProfileSubmit}
                    />
                  )}
                  {/* <div className={`${forgotPassword ? "d-block" : "d-none"}`}>

                    <p className="body-text2 text-white">FORGOT PASSWORD ?</p>
                    <p className="subtitle text-white pb-5 user-select-none text-start">
                      Enter Your Registered Email Id
                    </p>
                  </div> */}
                  <Form
                    ref={form}
                    className={`${login ? "d-block text-start" : "d-none"}`}
                    onSubmit={(e) => onLogin(e)}
                  >
                    <Form.Group className="mb-3" controlId="formUsername">
                      <div className="d-flex">
                        <Form.Control
                          name="email"
                          type="text"
                          size="lg"
                          placeholder="Enter Your Email"
                          className=" my-forms text-white seller-login-inputs br-10 custom-form-control"
                          required
                          onChange={(e) => {
                            setUsername(e.target.value);
                            if (e.target.value !== "" && password !== "") {
                              setNext(true);
                            } else {
                              setNext(false);
                            }
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="my-4 w-100 d-flex justify-content-end align-items-center position-relative"
                      controlId="formOtp"
                    >
                      <Form.Control
                        name="password"
                        type={`${eyeOn ? "text" : "password"}`}
                        size="lg"
                        placeholder="Password"
                        className=" my-forms text-white seller-login-inputs br-10 custom-form-control"
                        required
                        value={password}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (e.target.value !== "" && username !== "") {
                            setNext(true);
                          } else {
                            setNext(false);
                          }
                        }}
                      />
                      {!eyeOn && (
                        <RButton
                          type="button"
                          className="wrapped-button position-absolute m-1"
                          style={{ backgroundColor: "#0D0D0D", border: 0 }}
                          onKeyDown={(e) => e.preventDefault()}
                          onClick={(e) => {
                            e.preventDefault();
                            setEyeOn(true);
                          }}
                        >
                          <Image src={eye} alt="Eye" />
                        </RButton>
                      )}
                      {eyeOn && (
                        <RButton
                          type="button"
                          className="wrapped-button position-absolute m-1"
                          style={{ backgroundColor: "#0D0D0D", border: 0 }}
                          onKeyDown={(e) => e.preventDefault()}
                          onClick={(e) => {
                            e.preventDefault();
                            setEyeOn(false);
                          }}
                        >
                          <Image src={eye_off} alt="Eye Off" />
                        </RButton>
                      )}
                    </Form.Group>
                    <div className="text-white text-end my-2">
                      <RButton
                        className="wrapped-button caption"
                        onClick={() => {
                          setLogin(false);
                          setForgotPassword(true);
                          setCreateAccount(false);
                          setEmailSentNote(false);
                          setEmailResetNote(false);
                          setAccountCreated(false);
                        }}
                        variant="link"
                      >
                        Forgot Password
                      </RButton>
                    </div>

                    <RButton
                      className="btn btn-link border-0 btn btn-primary wrapped-button w-100 p-0"
                      style={{
                        background: "transparent",
                        textDecoration: "none",
                      }}
                      type="submit"
                    >
                      <RButton
                        className={`py-4 body-text2 btn btn-primary explore-button-no-neo w-100 br-10 d-flex justify-content-center align-items-center`}
                        disabled={isSending}
                        size="large"
                        dark={true}
                        style={{
                          height: 60,
                          border: changingBorder ? "0px" : "0px",
                        }}
                        onMouseEnter={() => setChangingBroder(true)}
                      >
                        {isSending ? (
                          <span>
                            <Spinner
                              className="me-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            LOGGING IN ...
                          </span>
                        ) : (
                          <span className="poppins">GET ME IN</span>
                        )}
                      </RButton>
                    </RButton>
                    <div className="d-flex justify-content-center align-items-center py-4">
                      <div className="text-white caption">
                        Don't have an account? Don't worry!{" "}
                      </div>
                      <RButton
                        className="wrapped-button caption"
                        onClick={() => {
                          setCreateAccount(true);
                          setForgotPassword(false);
                          setLogin(false);
                          setEmailSentNote(false);
                          setEmailResetNote(false);
                          setAccountCreated(false);
                        }}
                        variant="link"
                      >
                        Create an Account
                      </RButton>
                    </div>
                  </Form>
                  <div
                    className={`${
                      emailSentNote && !isAuthenticated
                        ? "d-flex flex-column justify-content-center align-items-start h-100"
                        : "d-none"
                    }`}
                  >
                    <p className="text-white subtitle mx-4">
                      We have sent you a verification link
                    </p>
                    <div
                      className="text-white d-flex align-items-center h4 m-4"
                      style={{ fontWeight: 500, lineHeight: "36px" }}
                    >
                      <p>
                        Kindly Verify your email{" "}
                        <b>
                          <br></br>
                          <i>{username}</i>
                        </b>
                      </p>
                    </div>
                  </div>
                  <div
                    className={`${
                      accountCreated && !isAuthenticated
                        ? "d-flex justify-content-center align-items-center"
                        : "d-none"
                    }`}
                  >
                    <div
                      className="text-white d-flex flex-column justify-content-start  h4 m-5 "
                      // style={{ fontWeight: 400 }}
                      style={{
                        paddingLeft: "1rem",
                      }}
                    >
                      <p className="h3 mb-5 poppins-500">
                        Yeahhh !!! Account Has Been Created.
                      </p>
                      <p className="h4 poppins-500">
                        You Are Now The Part Of The Znanye Members.
                      </p>
                    </div>
                  </div>

                  {/* <div
                    className={`${
                      emailResetNote && !isAuthenticated ? "d-block" : "d-none"
                    }`}
                  >
                    <div
                      className="text-white d-flex align-items-center h4 m-4"
                      style={{ fontWeight: 400 }}
                    >
                      <p>
                        We have shared a link for reset your password on your
                        email Id <i>{username}</i> Kindly check your email.
                      </p>
                    </div>
                  </div> */}

                  <Form
                    // ref={form}
                    className={`${
                      forgotPassword ? "d-block text-start" : "d-none"
                    }`}
                    onSubmit={(e) => handleReset(e)}
                  >
                    <Form.Group className="w-100">
                      <Form.Label className="text-white subtitle mt-3 poppins-500">
                        Forgot Password
                      </Form.Label>
                      <p className="text-white caption pb-3 poppins-400 ">
                        Enter Your Registered Email Id
                      </p>
                      <div className="d-flex flex-column">
                        <Form.Control
                          required
                          name="username"
                          type="email"
                          size="lg"
                          placeholder="Enter your Email"
                          className=" my-forms text-white custom-form-control "
                          style={{
                            background: "#141414",
                            height: 50,
                            borderColor: "#3D3D3D",
                            borderRadius: 10,
                          }}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>
                    <div className="text-center">
                      <RButton
                        className={`my-5 body-text2 explore-button-no-neo w-100 btn-btn-primary explore-button-large custom-form-control  `}
                        // disabled={!next}
                        size="large"
                        dark={true}
                        style={{ borderRadius: "10px" }}
                        disabled={resetSending}
                        onClick={async (e) => {
                          e.preventDefault();
                          setResetSending(true);
                          let email_regex =
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                          // alert(username)
                          if (username.match(email_regex)) {
                            const response =
                              await actions.reset_password_request({
                                email: username,
                              });
                            if (response) {
                              switch (response.status) {
                                case "DOES_NOT_EXIST":
                                  // toast.error(response.msg);
                                  break;
                                case "EMAIL_SENT":
                                  setForgotPassword(false);
                                  setEmailResetNote(true);
                                  break;
                                default:
                                  alert("some error");
                              }
                            }
                          } else {
                            setResetSending(false);

                            toast.error("Please enter a valid email!");
                          }
                          setResetSending(false);
                        }}
                      >
                        {resetSending ? (
                          <span>
                            <Spinner
                              className="me-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            SENDING...
                          </span>
                        ) : (
                          <span className="poppins">SEND ME A RESET LINK</span>
                        )}
                      </RButton>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="text-white caption">
                        Already have an account{" "}
                      </div>
                      <RButton
                        className="wrapped-button caption"
                        onClick={() => {
                          setForgotPassword(false);
                          setLogin(true);
                          setCreateAccount(false);
                          setEmailSentNote(false);
                          setEmailResetNote(false);

                          setAccountCreated(false);
                        }}
                        variant="link"
                      >
                        Log In Here
                      </RButton>
                      <div
                        className={`${
                          timer !== 0 ? "text-white mx-2 caption" : "d-none"
                        }`}
                      >
                        {`after ${timer} seconds`}
                      </div>
                    </div>
                  </Form>
                  <Form
                    ref={userForm}
                    className={`${
                      createAccount ? "d-block text-start" : "d-none"
                    }`}
                    onSubmit={(e) => handleProfileSubmit(e)}
                  >
                    <Form.Group className="w-100" controlId="formDetails">
                      {/* <Form.Label className="text-white">Select Country</Form.Label> */}
                      <div className="d-flex">
                        <Form.Control
                          required
                          name="name"
                          placeholder="Your Name"
                          type="text"
                          size="lg"
                          style={{
                            background: "#141414",
                            borderColor: "#3D3D3D",
                            borderRadius: 10,
                          }}
                          className="mb-4 my-forms text-white seller-login-inputs"
                          // style={{ background: "#141414", height: 50, borderColor: "#3D3D3D", borderRadius: 10 }}
                        />
                      </div>
                      {/* <Form.Label className="text-white">
                        Enter Email
                      </Form.Label> */}
                      <Form.Control
                        required
                        name="email"
                        type="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        placeholder="Email"
                        size="lg"
                        style={{
                          // background: "#141414",
                          // borderColor: "#3D3D3D",
                          borderRadius: 10,
                        }}
                        className="mb-4 my-forms text-white seller-login-inputs"
                        // style={{ background: "#141414", height: 50, borderColor: "#3D3D3D", borderRadius: 10 }}
                        onInvalid={(e) => {
                          return e.target.setCustomValidity(
                            "Please enter valid email id."
                          );
                        }}
                        onChange={(e) => {
                          setUsername(e.target.value);
                          return e.target.setCustomValidity("");
                        }}
                      />
                      <div className="position-relative d-flex justify-content-end align-items-center mb-4">
                        <Form.Select
                          required
                          // ref={selectRef}
                          name="phone_code"
                          type="text"
                          className=" my-select text-white subtitle seller-login-inputs pe-0 py-2"
                          placeholder="Country"
                          style={{
                            //   background: "#141414",
                            //   borderColor: "#3D3D3D",
                            borderRadius: 10,
                          }}
                          value={countryCode}
                          onChange={(e) => {
                            setCountryCode(e.target.value);
                            // console.log("@@",e.target.value);
                          }}
                          // onFocus={() => setIsSelectOpen(true)}
                          // onBlur={() => setIsSelectOpen(false)}
                        >
                          {countries
                            ?.filter((option) => {
                              if (
                                !uniqueCountries.some(
                                  (country) => country.name === option.name
                                )
                              ) {
                                uniqueCountries.push(option);
                                return true;
                              }
                              return false;
                            })
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((option) => {
                              if (option.code === "IN") {
                                return (
                                  <option
                                    key={option.phone_code}
                                    value={option.phone_code}
                                    selected
                                  >
                                    {option.name}
                                  </option>
                                );
                              } else {
                                return (
                                  <option
                                    key={option.phone_code}
                                    value={option.phone_code}
                                  >
                                    {option.name}
                                  </option>
                                );
                              }
                            })}
                        </Form.Select>
                        {/* <IoChevronDown
                          color="#FFF"
                          size={20}
                          className="position-absolute mx-3"
                        /> */}
                      </div>
                      <Form.Group className="d-flex justify-content-end align-items-start position-relative">
                        <Form.Control
                          required
                          name="password"
                          type={`${eyeOn ? "text" : "password"}`}
                          size="lg"
                          // style={{
                          //   background: "#141414",
                          //   borderColor: "#3D3D3D !important",
                          //   borderRadius: 10,
                          // }}
                          placeholder="Password"
                          className="mb-4 my-forms text-white seller-login-inputs custom-form-control"
                          // style={{ background: "#141414", height: 50, borderColor: "#3D3D3D", borderRadius: 10 }}
                        />
                        {!eyeOn && (
                          <RButton
                            type="button"
                            className="wrapped-button position-absolute m-2"
                            style={{ backgroundColor: "#0D0D0D", border: 0 }}
                            onKeyDown={(e) => e.preventDefault()}
                            onClick={(e) => {
                              e.preventDefault();
                              setEyeOn(true);
                            }}
                          >
                            <Image src={eye} alt="Eye" />
                          </RButton>
                        )}
                        {eyeOn && (
                          <RButton
                            type="button"
                            className="wrapped-button position-absolute m-2"
                            style={{ backgroundColor: "#0D0D0D", border: 0 }}
                            onKeyDown={(e) => e.preventDefault()}
                            onClick={(e) => {
                              e.preventDefault();
                              setEyeOn(false);
                            }}
                          >
                            <Image src={eye_off} alt="Eye Off" />
                          </RButton>
                        )}
                      </Form.Group>
                      <Form.Group className="d-flex justify-content-end align-items-start position-relative">
                        <Form.Control
                          required
                          name="confirm_password"
                          type={`${eyeOnTwo ? "text" : "password"}`}
                          size="lg"
                          // style={{
                          //   background: "#141414",
                          //   borderColor: "#3D3D3D !important",
                          //   borderRadius: 10,
                          // }}

                          placeholder="Confirm Password"
                          className="mb-4 my-forms text-white seller-login-inputs custom-form-control"
                          // style={{ background: "#141414", height: 50, borderColor: "#3D3D3D", borderRadius: 10 }}
                        />
                        {!eyeOnTwo && (
                          <RButton
                            type="button"
                            className="wrapped-button position-absolute m-2"
                            style={{ backgroundColor: "#0D0D0D", border: 0 }}
                            onKeyDown={(e) => e.preventDefault()}
                            onClick={(e) => {
                              e.preventDefault();
                              setEyeOnTwo(true);
                            }}
                          >
                            <Image src={eye} alt="Eye" />
                          </RButton>
                        )}
                        {eyeOnTwo && (
                          <RButton
                            type="button"
                            className="wrapped-button position-absolute m-2"
                            style={{ backgroundColor: "#0D0D0D", border: 0 }}
                            onKeyDown={(e) => e.preventDefault()}
                            onClick={(e) => {
                              e.preventDefault();
                              setEyeOnTwo(false);
                            }}
                          >
                            <Image src={eye_off} alt="Eye Off" />
                          </RButton>
                        )}
                      </Form.Group>
                    </Form.Group>
                    {/* <Form.Group className="mb-5" controlId="formBasicCheckbox">
                      <div className="d-flex">
                        <Form.Check
                          className="mx-2"
                          name="consent"
                          type="checkbox"
                          ref={consentRef}
                          required
                          onChange={(e) => {
                            setConsent(e.target.checked);
                          }}
                        />
                        <div className="text-white">
                          By Creating an account you agree to the terms of
                          use and privacy policy
                        </div>
                      </div>
                    </Form.Group> */}
                    <div className="text-center">
                      <RButton
                        className="btn btn-link border-0 mt-3 mb-2 wrapped-button w-100 p-0"
                        style={{
                          background: "transparent",
                          textDecoration: "none",
                        }}
                        type="submit"
                      >
                        <RButton
                          className=" explore-button-no-neo w-100 br-10 btn btn-primary d-flex justify-content-center align-items-center"
                          style={{ border: changingBorder ? "0px" : "0px" }}
                          onMouseEnter={() => setChangingBroder(true)}
                          onMouseLeave={() => setChangingBroder(false)}
                          size="lg"
                          dark={true}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <span>
                              <Spinner
                                className="me-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              SUBMITTING ...
                            </span>
                          ) : (
                            <span className="poppins">Create My Account</span>
                          )}
                        </RButton>
                      </RButton>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="text-white mx-1 caption">
                        Already have an account?{" "}
                      </div>
                      <RButton
                        className="wrapped-button caption ps-1"
                        onClick={() => {
                          setForgotPassword(false);
                          setLogin(true);
                          setCreateAccount(false);
                          setEmailSentNote(false);
                          setEmailResetNote(false);

                          setAccountCreated(false);
                        }}
                        variant="link"
                      >
                        Log In
                      </RButton>
                      <div
                        className={`${
                          timer !== 0 ? "text-white mx-2 caption" : "d-none"
                        }`}
                      >
                        {`after ${timer} seconds`}
                      </div>
                    </div>
                  </Form>
                  <div
                    className={`${
                      accountCreated
                        ? "d-flex justify-content-center align-items-center h-100"
                        : "d-none"
                    }`}
                  ></div>
                  <div
                    className={`${
                      emailResetNote && !isAuthenticated
                        ? "d-flex justify-content-center align-items-center h-100"
                        : "d-none"
                    }`}
                  >
                    <div
                      className="text-white d-flex align-items-center h4 m-4"
                      style={{ fontWeight: 400 }}
                    >
                      <p>
                        We have shared a link for reset your password on your
                        email Id{" "}
                        <b>
                          <i>{username}</i>
                        </b>{" "}
                        Kindly check your email.
                      </p>
                    </div>
                  </div>
                </div>
              </Container>
            </Col>
            {login && (
              <Col className="p-0">
                <Container
                  className="d-flex flex-column justify-content-center align-items-center h-100"
                  style={styles.secondHalfContainer}
                  fluid
                >
                  <Carousel
                    height={700}
                    cycle
                    // delimiterIcon={<IconButton rounded text={false} disabled className="explore-button" dark={true} outlined={false}></IconButton>}
                    delimiterIcon={
                      <BsCircle
                        color="#1F1F1F"
                        className="mx-2"
                        style={{ borderRadius: 20, backgroundColor: "#1F1F1F" }}
                      />
                    }
                    activeDelimiterIcon={
                      <BsCircle
                        className="mx-2 explore-slider-icon"
                        style={{ borderRadius: 20 }}
                      />
                    }
                  >
                    <CarouselItem className="mt-5">
                      <div className="d-flex flex-column align-items-center">
                        <Image
                          src={seller_login_slide_1}
                          alt="Earn More"
                          style={{ width: 400 }}
                        />
                        <div
                          className="text-white subtitle text-center my-5"
                          style={styles.carouselText}
                        >
                          Experience a powerful 3D viewer to showcase your
                          products.
                        </div>
                      </div>
                    </CarouselItem>
                    <CarouselItem className="mt-5">
                      <div className="d-flex flex-column align-items-center">
                        <Image
                          src={seller_login_slide_2}
                          alt="Inspiration"
                          style={{ width: 400 }}
                        />
                        <div
                          className="text-white subtitle text-center my-5"
                          style={styles.carouselText}
                        >
                          We charge you less so you can keep more of your
                          hard-earned money.
                        </div>
                      </div>
                    </CarouselItem>
                    <CarouselItem className="mt-5">
                      <div className="d-flex flex-column align-items-center">
                        <Image
                          src={seller_login_slide_3}
                          alt="Recognized"
                          style={{ width: 400 }}
                        />
                        <div
                          className="text-white subtitle text-center my-5"
                          style={styles.carouselText}
                        >
                          Marketing Campaigns lined up that will Drive Traffic
                          to your products. You may turn out to be an
                          inspiration for many others.
                        </div>
                      </div>
                    </CarouselItem>
                    <CarouselItem className="mt-5">
                      <div className="d-flex flex-column align-items-center">
                        <Image
                          src={seller_login_slide_4}
                          alt="Recognized"
                          style={{ width: 400 }}
                        />
                        <div
                          className="text-white subtitle text-center my-5"
                          style={styles.carouselText}
                        >
                          Let the new virtual world recognize your creative
                          work.
                        </div>
                      </div>
                    </CarouselItem>
                  </Carousel>
                </Container>
              </Col>
            )}
            {(createAccount || forgotPassword) && (
              <Col className="d-flex flex-column justify-content-around align-items-center">
                <Container
                  className="d-flex flex-column justify-content-center align-items-center h-100"
                  style={{
                    ...styles.secondHalfContainer,
                    maxHeight: 70 + "vh",
                  }}
                  fluid
                >
                  <div
                    className={`${
                      emailSentNote || accountCreated ? "d-none" : "d-flex"
                    } justify-content-center align-items-end`}
                  >
                    <div className="p-4 text-white notch-div text-center">
                      {forgotPassword && <span>Oops !</span>}
                      {createAccount && <span>Cheers !</span>}
                    </div>
                    <div className="notch"></div>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    {forgotPassword && (
                      <Image
                        src={forgot_mascot}
                        alt="Password Mascot"
                        loading="lazy"
                      />
                    )}
                    {createAccount && (
                      <Image
                        src={create_account_mascot}
                        alt="Create Account Mascot"
                        loading="lazy"
                      />
                    )}
                  </div>
                </Container>
              </Col>
            )}
            {(emailSentNote || emailResetNote) && (
              <Col className="p-0">
                <Container
                  className="d-flex flex-column justify-content-center align-items-center h-100"
                  style={styles.secondHalfContainer}
                  fluid
                >
                  <Image
                    src={email_sent}
                    size="100px"
                    alt="Email Sent"
                    loading="lazy"
                  />
                </Container>
              </Col>
            )}
            {accountCreated && (
              <Col className="p-0">
                <Container
                  className="d-flex flex-column justify-content-center align-items-center h-100"
                  style={styles.secondHalfContainer}
                  fluid
                >
                  <Image
                    src={celebrate_mascot}
                    alt="Celebrate Mascot"
                    loading="lazy"
                  />
                </Container>
              </Col>
            )}
          </Row>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          fluid
          className="pt-5 d-flex flex-column justify-content-between"
          style={styles.parentContainer}
        >
          <div>
            <div className="mt-5 text-white text-center h3 poppins-500 pt-4">
              <p className="pt-4">
                It Seems that you are trying to login through mobile !
              </p>
              <p className="pt-4">
                You Need To Login Through the Desktop To sell Your Products
              </p>
            </div>
            <div className="d-flex justify-content-center py-4">
              <Link className="text-decoration-none" to={"/sell-3d"}>
                <RButton
                  dark={true}
                  className="explore-button-no-neo btn btn-ptimary mt-5"
                  style={{ backgroundColor: "#27292A" }}
                >
                  GO BACK
                </RButton>
              </Link>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Image src={LoginPose} alt="Login through desktop" />
          </div>
        </Container>
      </Breakpoint>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    paddingTop: 50,
  },
  firstHalfContainer: {
    minHeight: "100vh",
    padding: 100,
    // backgroundColor:"#141415"
  },
  secondHalfContainer: {
    minHeight: "100vh",
    backgroundColor: "#292929",
  },
  carouselText: {
    maxWidth: 437 + "px",
  },
};

export default SellerLogin;

import React, { useEffect, useState } from "react";
import { Row, Col, Image, Button as RButton } from "react-bootstrap";
import { Button, Card } from "ui-neumorphism";
import { sample_blog } from "assets";
import { MdChevronRight } from "react-icons/md";
import { Link } from "react-router-dom";

const FeaturedBlogCard = ({blog, read_url}) => {
    const { title, content, published_date, author_name, read_time } = blog;
    return (
        <Col>
            <Link className="text-decoration-none" to={read_url}>
            <Card className="border-0 blog-card position-relative overflow-hidden d-flex justify-content-center align-items-end mt-5" dark={true} outlined style={{ marginTop: 32, height: 320 }}>
                <Image style={{ height: 288 }} className="position-absolute mx-auto h-100" src={blog?.blog_image} alt={title} />
                <div className="w-100" style={{zIndex: 10}}>
                    <div className="text-center m-2 p-3" style={styles.titleBox}>
                        {title}
                    </div>
                </div>
            </Card>
            </Link>
        </Col>
    );
}

const styles = {
    titleBox: {
        backgroundColor: "#27292A",
        borderRadius: 10,
        fontSize: 12
    }
}

export default FeaturedBlogCard;
const initialState = {
  user: {
    id: null,
    username: null,
    name: null,
    email: null,
    region: null,
    gst_number: null,
    business_name: null,
    billing_address: null,
    is_registered: false,
    sign_up_type: null,
    is_admin: false,
    cartproducts: [],
    favourites: [],
    saveforlater: [],
    orders: [],
  },
  seller: {
    id: null,
    user: null,
    user_data: null,
    business_name: null,
    consent: false,
    bankaccount: null,
    step: 1,
    is_completed: false,
  },
  token: null,
  isAuthenticated: false,
  currency: {
    is: null,
    name: "USD",
    rate: 1,
    last_update_utc: null,
    next_update_utc: null,
  },
  offer_status_popup_expiry: null,
  banner_offer_status_popup_expiry: null,
};

export const authReducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    ////////// USER //////////
    case "LOGIN":
      // console.log("STATE:", state);
      state.token = action.payload.token;
      state.isAuthenticated = action.payload.token ? true : false;
      const cartproducts = state?.user?.cartproducts;
      state.user = action.payload.user;
      console.log(state.user);
      for (let index in cartproducts) {
        state.user.cartproducts.push(cartproducts[index]);
      }
      break;
    case "LOGOUT":
      // console.log("STATE:", state);
      state.user = initialState.user;
      state.seller = initialState.seller;
      state.token = null;
      state.isAuthenticated = false;
      state.offer_status_popup_expiry = null;
      state.banner_offer_status_popup_expiry = null;
      break;
    case "MY_PROFILE":
      // console.log("STATE for My Profile:", state);
      state.user = action.payload;
      break;
    case "UPDATE_PROFILE":
      // console.log("STATE", state);
      state.user = action.payload;
      break;
    // case "UPDATE_CURRENCY":
    //   state.currency = action.payload;
    //   console.log("STATE", state);
    //   break;

    ////////// CURRENCY //////////
    case "LOAD_CURRENT_CURRENY_RATE":
      // console.log("STATE:", state);
      state.currency = action.payload;
      break;

    ////////// FAVOURITES //////////
    case "LOAD_FAVORITES":
      // console.log("STATE:", state);
      state.user.favourites = action.payload;
      break;
    case "ADD_FAVORITE":
      // console.log("Action of add:", action.payload);
      // console.log("STATE:", state);
      state.user.favourites.push(action.payload);
      break;
    case "REMOVE_FAVORITE":
      console.log("Action of favorite remove:", action.payload);
      // console.log("STATE:", state);
      state.user.favourites = state.user.favourites.filter(
        (item) => item.product_id !== action.payload.product_id
      );
      break;
    ////////// SAVEFORLATER //////////
    case "LOAD_SAVEFORLATER":
      // console.log("STATE:", state);
      state.user.saveforlater = action.payload;
      break;
    case "ADD_SAVEFORLATER":
      state.user.saveforlater.push(action.payload);
      state.user.cartproducts = state.user.cartproducts.filter(
        (item) => item.product_id !== action.payload.product_id
      );
      break;
    case "REMOVE_SAVEFORLATER":
      // console.log("Action of favorite remove:", action.payload);
      // console.log("STATE:", state);
      state.user.saveforlater = state.user.saveforlater.filter(
        (item) => item.product_id !== action.payload.product_id
      );
      break;
    ////////// CART PRODUCTS //////////
    case "LOAD_CART_PRODUCT":
      // console.log("STATE:", state);
      state.user.cartproducts = action.payload;
      break;
    case "ADD_CART_PRODUCT":
      // console.log("STATE:", state);
      state.user.cartproducts.push(action.payload);
      state.user.saveforlater = state.user.saveforlater.filter(
        (item) => item.product_id !== action.payload.product_id
      );
      break;
    case "UPDATE_CART_PRODUCT":
      // console.log("STATE:", state);
      state.user.cartproducts = state.user.cartproducts.map((item) =>
        item.product_id === action.payload.product_id ? action.payload : item
      );
      break;
    case "REMOVE_CART_PRODUCT":
      // console.log("STATE:", action.payload);
      state.user.cartproducts = state.user.cartproducts.filter(
        (item) => item.product_id !== action.payload.product_id
      );
      // console.log(state.user.cartproducts);
      break;

    ////////// ORDERS //////////
    case "LOAD_ORDERS":
      // console.log("STATE:", state);
      state.user.orders = action.payload;
      break;
    case "CREATE_ORDER":
      // console.log("STATE:", state);
      state.user.orders.push(action.payload);
      break;
    case "CLEAR_CART":
      state.user.cartproducts = [];
      break;

    ////////// SELLER //////////
    case "LOAD_SELLER":
      // console.log("STATE:", state);
      state.seller = action.payload;
      break;
    case "ADD_SELLER":
      // console.log("STATE:", state);
      state.seller = action.payload;
      break;
    case "UPDATE_SELLER":
      // console.log("STATE:", state);
      state.seller = action.payload;
      break;

    ////////// BANK ACCOUNT //////////
    case "LOAD_BANK_ACCOUNT":
      // console.log("STATE:", state);
      state.seller.bankaccount = action.payload;
      break;
    case "ADD_BANK_ACCOUNT":
      // console.log("STATE:", state);
      state.seller.bankaccount = action.payload;
      break;
    case "UPDATE_BANK_ACCOUNT":
      // console.log("STATE:", state);
      state.seller.bankaccount = action.payload;
      break;

    ////////// OFFER //////////
    case "RELOAD_OFFER":
      state.offer_status_popup_expiry = action.payload;
      break;
    case "RELOAD_BANNER_OFFER":
      state.banner_offer_status_popup_expiry = action.payload;
      break;

    default:
      // console.log("STATE:", state);
      console.log("");
  }
  return state;
};

import React, { useRef, useState } from "react";
import { Col, Container, Image, Row, Card, Button } from "react-bootstrap";
import {
  RoyaltyRate,
  Wink,
  moneybag,
  new50,
  new80,
  new88,
  royaltyrateunderline,
  slider11,
  slider12,
  slider13,
  slider14,
  slider15,
  slider16,
  slider17,
  slider18,
  slider19,
  slider110,
  slider111,
  slider112,
  slider113,
  slider114,
  slider115,
  slider116,
  slider117,
  slider118,
  slider21,
  slider22,
  slider23,
  slider24,
  slider25,
  slider26,
  slider27,
  slider28,
  slider29,
  slider210,
  slider211,
  slider212,
  slider213,
  slider214,
  slider215,
  slider216,
  slider217,
  slider218,
  znanyecomparelogo,
  cgtradercomparelogo,
  sketchfabcomparelogo,
  turbosquidcomparelogo,
  uploadlimit,
  uploadlimitmobile,
  analytics,
} from "assets";
import { not_found_gif, smile_seller_gif } from "assets";
// import { Button as NuButton } from "ui-neumorphism";
import { FiArrowRight } from "react-icons/fi";
import {
  Breakpoint,
  useCurrentBreakpointName,
  useCurrentWidth,
} from "react-socks";
import { SellerCarousel } from "components";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import { useSpring, animated } from "react-spring";

import { FcCheckmark } from "react-icons/fc";
import { AiOutlineClose, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import MobileTableComparison from "components/Seller/MobileTableComparison";
import "core-ui/pages/Seller.scss";
import FAQSpringElement from "./FAQSpringElement";
import { useTransition } from "react-spring";
import * as easings from "d3-ease";

const SellerLanding = () => {
  const location = useLocation();
  const videoRef = useRef();
  const breakpoint = useCurrentBreakpointName();
  const [sectionThree, setSectionThree] = useState("3d_viewer");
  const [selectedFaq, setSelectedFaq] = useState(1);
  const [openClose, setOpenClose] = useState(false);

  let showSlides = 7.5;

  const width = useCurrentWidth();
  // const animationStyle = useSpring({
  //   from: { y: -500, transformOrigin: "top center" },
  //   to: { y: 0, transformOrigin: "top center" },
  //   delay: 3000,
  //   config: { duration: 3000 }
  // });
  const animationStyle = useSpring({
    from: { marginTop: -200, opacity: 0 },
    opacity: 1,
    marginTop: 5,
    config: { mass: 1, tension: 150, friction: 10, duration: 10000 },
  });
  const [x, setX] = useState(true);
  const [y, setY] = useState(false);

  const transitionsX = useTransition(x, {
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
    // reverse: toggle,
    delay: 500,
    config: { duration: 1000, easing: easings.easeCubicOut },
    onRest: () => {
      setY(true);
      setX(false);
    },
  });

  const slider1 = [
    { id: 1, img: slider11, alt: "Slider Image" },
    { id: 2, img: slider12, alt: "Slider Image" },
    { id: 3, img: slider13, alt: "Slider Image" },
    { id: 4, img: slider14, alt: "Slider Image" },
    { id: 5, img: slider15, alt: "Slider Image" },
    { id: 6, img: slider16, alt: "Slider Image" },
    { id: 7, img: slider17, alt: "Slider Image" },
    { id: 8, img: slider18, alt: "Slider Image" },
    { id: 9, img: slider19, alt: "Slider Image" },
    { id: 10, img: slider110, alt: "Slider Image" },
    { id: 11, img: slider111, alt: "Slider Image" },
    { id: 12, img: slider112, alt: "Slider Image" },
    { id: 13, img: slider113, alt: "Slider Image" },
    { id: 14, img: slider114, alt: "Slider Image" },
    { id: 15, img: slider115, alt: "Slider Image" },
    { id: 16, img: slider116, alt: "Slider Image" },
    { id: 17, img: slider117, alt: "Slider Image" },
    { id: 18, img: slider118, alt: "Slider Image" },
  ];

  const slider2 = [
    { id: 1, img: slider21, alt: "Slider Image" },
    { id: 2, img: slider22, alt: "Slider Image" },
    { id: 3, img: slider23, alt: "Slider Image" },
    { id: 4, img: slider24, alt: "Slider Image" },
    { id: 5, img: slider25, alt: "Slider Image" },
    { id: 6, img: slider26, alt: "Slider Image" },
    { id: 7, img: slider27, alt: "Slider Image" },
    { id: 8, img: slider28, alt: "Slider Image" },
    { id: 9, img: slider29, alt: "Slider Image" },
    { id: 10, img: slider210, alt: "Slider Image" },
    { id: 11, img: slider211, alt: "Slider Image" },
    { id: 12, img: slider212, alt: "Slider Image" },
    { id: 13, img: slider213, alt: "Slider Image" },
    { id: 14, img: slider214, alt: "Slider Image" },
    { id: 15, img: slider215, alt: "Slider Image" },
    { id: 16, img: slider216, alt: "Slider Image" },
    { id: 17, img: slider217, alt: "Slider Image" },
    { id: 18, img: slider218, alt: "Slider Image" },
  ];

  if (breakpoint === "xxlarge") {
    showSlides = 8.5;
  } else if (breakpoint === "xlarge") {
    showSlides = 7.5;
  } else if (breakpoint === "large") {
    showSlides = 6.5;
  } else if (breakpoint === "medium") {
    showSlides = 6.5;
  } else if (breakpoint === "small") {
    showSlides = 4.5;
  } else if (breakpoint === "xsmall") {
    showSlides = 3.5;
  }

  const settingsSlider1 = {
    // infinite: true,
    // slidesToShow: showSlides,
    // slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2,
    // rtl: true,
    // easing: "",
    // // cssEase: "linear",
    dots: false,
    infinite: true,
    slidesToShow: showSlides,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    rtl: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    pauseOnFocus: false,
    pauseOnDotsHover: false,
    draggable: false,
  };

  const settingsSlider2 = {
    // infinite: true,
    // slidesToShow: showSlides,
    // slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 1,
    // // rtl: true,
    // easing: "linear",
    // // cssEase: "linear",
    dots: false,
    infinite: true,
    slidesToShow: showSlides,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    rtl: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    swipeToSlide: false,
    swipe: false,
    touchMove: false,
    pauseOnFocus: false,
    pauseOnDotsHover: false,
    draggable: false,
  };

  const comparision = [
    {
      id: 1,
      header: "Upload Limit Per Month",
      text1: "Unlimited",
      text2: "Unlimited",
      text3: "10",
      text4: "Unlimited",
    },
    {
      id: 2,
      header: "Upload Size Per Model",
      text1: (
        <div className="d-flex justify-content-center align-items-center">
          <div>500MB</div>
          {/* <div className="body-text2" style={{ color: "#666666" }}>
            &nbsp;( Beta )
          </div> */}
        </div>
      ),
      text2: "5.5 GB",
      text3: "100 MB",
      text4: "-",
    },
    {
      id: 3,
      header: "Royalty Rate",
      text1: "upto 91 %",
      text2: "70 - 80 %",
      text3: "88 %",
      text4: "40 - 60 %",
    },
    {
      id: 4,
      header: "3D Viewer to showcase your model",
      text1: <FcCheckmark size={25} />,
      text2: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text3: <FcCheckmark size={25} />,
      text4: <AiOutlineClose style={{ color: "red" }} size={25} />,
    },
    {
      id: 5,
      header: "Editor for 3D viewer",
      text1: <FcCheckmark size={25} />,
      text2: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text3: <FcCheckmark size={25} />,
      text4: <AiOutlineClose style={{ color: "red" }} size={25} />,
    },
    {
      id: 6,
      header: "Able to upload renders",
      text1: <FcCheckmark size={25} />,
      text2: <FcCheckmark size={25} />,
      text3: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text4: <FcCheckmark size={25} />,
    },
    {
      id: 7,
      header: "Analytics",
      text1: (
        <div className="d-flex justify-content-center align-items-center">
          <FcCheckmark size={25} />
          {/* <div className="body-text2" style={{ color: "#666666" }}>
            &nbsp;( Beta )
          </div> */}
        </div>
      ),
      text2: <FcCheckmark size={25} />,
      text3: <FcCheckmark size={25} />,
      text4: <AiOutlineClose style={{ color: "red" }} size={25} />,
    },
    {
      id: 8,
      header: "Marketing Campaign for your models",
      text1: <FcCheckmark size={25} />,
      text2: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text3: <FcCheckmark size={25} />,
      text4: <AiOutlineClose style={{ color: "red" }} size={25} />,
    },
    {
      id: 9,
      header: "Quality Inspection for 3D model",
      text1: <FcCheckmark size={25} />,
      text2: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text3: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text4: <FcCheckmark size={25} />,
    },
  ];

  const faqs = [
    {
      id: 1,
      question: "What file formats are supported?",
      answer:
        "We support a variety of file formats, including FBX, OBJ, and MTL and many more. But GLTF is the best suited for the 3D viewer and is highly recommended to view your model in the 3D viewer. Please refer to our documentation for a list of supported formats.",
    },
    {
      id: 2,
      question: "How do I sell my 3D models on this website?",
      answer:
        "To buy a 3D model, search for the one you want and add it to your cart. Then, complete the checkout process and download the model",
    },
    {
      id: 3,
      question: "How to create a seller account?",
      answer:
        "To create a seller account, go to znanye and visit the seller page. Register yourself by filling in the details. ",
    },
    {
      id: 4,
      question: "What is the uploading procedure for 3D models?",
      answer:
        "Firstly, you have to create your account on the website. You have to wait for some significant time for the verification. Once your account is verified, you can upload your 3D models",
    },
    {
      id: 5,
      question: "How do I get paid?",
      answer: "We use PayPal and bank transfers to pay!",
    },
    {
      id: 6,
      question: "When do I get paid?",
      answer:
        "You are paid when the model you uploaded sells! By the 5th of the following month, the payment will be credited directly to your linked account!",
    },
  ];

  return (
    <React.Fragment>
      {/* <Helmet>
        <title>{`Znanye | Sell 3D assets and VR models to earn money`}</title>
        <meta
          name="description"
          content="Now you can earn one of the highest commissions of up to 91% on our 3D marketplace. Sell your 3D assets on our 3D marketplace, Znanye."
        />
        <link
          rel="canonical"
          href={`https://www.znanye.com${location.pathname}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.znanye.com${location.pathname}`}
        />
      </Helmet> */}
      <Container fluid className="text-white p-0 user-select-none">
        <Breakpoint large up>
          <Container
            fluid
            className="mt-5 pt-5"
            style={{ backgroundColor: "#1F1F1F" }}
          >
            <div
              className="d-flex justify-content-center mt-5 p-5 text-center"
              style={{ zIndex: 20 }}
            >
              <Image
                src={moneybag}
                alt="Money Bag"
                style={{ width: "56px", height: "56px" }}
              />
              <h1 className="earn-by-selling poppins-500 p-0">
                Earn by <span className="text-primary">Selling</span> your{" "}
                <span className="text-primary">3D Models</span>
              </h1>
            </div>
            <Row>
              <Col className="text-center" lg={{ span: 6, offset: 3 }}>
                <p className="h4 text-center" style={{ lineHeight: "36px" }}>
                  Keep<span className="text-primary"> larger commissions </span>
                  by selling your{" "}
                  <a className="text-decoration-none" href={`/3dmodels`}>
                    3D Assets
                  </a>{" "}
                  on Znanye. Let the world recognize your creations in VR. Sell
                  your VR creations and earn handsome returns
                </p>
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <a className="text-decoration-none" href={"/sell-3d-login"}>
                <Button
                  // dark={true}
                  // className="explore-button explore-button-large my-4"
                  className="explore-button-no-neo  explore-button-large-no-neo my-4"
                  style={{ backgroundColor: "#27292A" }}
                >
                  START SELLING
                </Button>
              </a>
            </div>
            <Slider as={Row} {...settingsSlider1} className="overflow-hidden">
              {slider1.map((item, index) => {
                return (
                  <Col
                    key={item.id}
                    className="pt-4 pb-2 px-0 d-flex justify-content-center"
                  >
                    <Card
                      style={{
                        zIndex: 200,
                        backgroundColor: "#141415",
                        borderRadius: "16px",
                        border: 0,
                      }}
                    >
                      <Image src={item.img} alt={item.alt} rounded />
                    </Card>
                  </Col>
                );
              })}
            </Slider>
            <Slider as={Row} {...settingsSlider2} className="overflow-hidden">
              {slider2.map((item, index) => {
                return (
                  <Col
                    key={item.id}
                    className="pt-2 pb-5 px-0 d-flex justify-content-center"
                  >
                    <Card
                      style={{
                        zIndex: 200,
                        backgroundColor: "#141415",
                        borderRadius: "16px",
                        border: 0,
                      }}
                    >
                      <Image src={item.img} alt={item.alt} rounded />
                    </Card>
                  </Col>
                );
              })}
            </Slider>
          </Container>
        </Breakpoint>
        <Breakpoint medium down>
          <Container
            fluid
            className="mt-5 pt-5 mx-0 px-0"
            style={{ backgroundColor: "#1F1F1F" }}
          >
            <div
              className="mt-5 d-flex flex-column justify-content-center align-items-center text-center"
              style={{ zIndex: 20 }}
            >
              <Image
                src={moneybag}
                alt="Money Bag"
                style={{ width: "56px", height: "56px" }}
              />
              <h1 className="earn-by-selling poppins-500 px-2 py-0 m-0">
                Earn by <span className="text-primary">Selling</span> your{" "}
                <span className="text-primary">3D Models</span>
              </h1>
            </div>
            <p className="h3 p-4 my-4 text-center">
              Keep<span className="text-primary"> larger commissions </span>
              by selling your{" "}
              <a className="text-decoration-none" href={`/3dmodels`}>
                3D Assets
              </a>{" "}
              on Znanye. Let the world recognize your creations in VR. Sell your
              VR creations and earn handsome returns
            </p>
            <div className="d-flex justify-content-center mb-5">
              <a className="text-decoration-none" href={"/sell-3d-login"}>
                <Button
                  // dark={true}
                  className="explore-button-no-neo explore-button-large-no-neo"
                  style={{ backgroundColor: "#27292A" }}
                >
                  START SELLING
                </Button>
              </a>
            </div>
            <Slider as={Row} {...settingsSlider1} className="overflow-hidden">
              {slider1.map((item, index) => {
                return (
                  <Col
                    key={item.id}
                    className="pt-4 pb-2 px-2 d-flex justify-content-center"
                  >
                    <Card
                      style={{
                        zIndex: 200,
                        backgroundColor: "#141415",
                        borderRadius: "16px",
                        border: 0,
                      }}
                    >
                      <Image src={item.img} alt={item.alt} rounded />
                    </Card>
                  </Col>
                );
              })}
            </Slider>
            <Slider as={Row} {...settingsSlider2} className="overflow-hidden">
              {slider2.map((item, index) => {
                return (
                  <Col
                    key={item.id}
                    className="pt-2 pb-5 px-2 d-flex justify-content-center"
                  >
                    <Card
                      style={{
                        zIndex: 200,
                        backgroundColor: "#141415",
                        borderRadius: "16px",
                        border: 0,
                      }}
                    >
                      <Image src={item.img} alt={item.alt} rounded />
                    </Card>
                  </Col>
                );
              })}
            </Slider>
          </Container>
        </Breakpoint>
        <Breakpoint large up>
          <Container
            fluid
            className="p-0 h-100vh d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "#1F1F1F" }}
          >
            <Card
              className="mx-2 border-R20 seller_section2_background"
              style={{
                backgroundColor: "#141415",
                border: "1px solid #3D3D3D",
                maxWidth: "1254px",
              }}
            >
              <Row className="p-0 m-0">
                <Col
                  sm={6}
                  className="px-lg-5 d-flex flex-column justify-content-center"
                >
                  <Breakpoint large up>
                    <h2 className="mb-1 h3 p-0 mb-4">
                      <span className="text-primary">91</span> is better
                    </h2>
                  </Breakpoint>
                  <p className="body-text1">
                    Why let someone get rich at the expense of your creativity?
                    You should be able to keep most of your hard-earned money.
                    We help you keep most of your earnings. You can earn one of
                    the highest commissions of up to 91% on our 3D Marketplace.
                    Sell your 3D assets in our 3D Marketplace, Znanye. You can
                    also enjoy additional benefits periodically based on your
                    aggregated revenues with us.
                  </p>
                </Col>
                <Col
                  sm={6}
                  className="py-5 mt-3 d-flex flex-column justify-content-center align-items-center"
                >
                  <div className="pb-5 text-center">
                    <Image
                      className="w-lg-100 w-75"
                      src={RoyaltyRate}
                      alt="Royalty Rate"
                    />
                  </div>
                  <div>
                    <p className="subtitle p-0 m-0">
                      <span className="text-primary">Royalty Rate</span> -
                      Better Than These{" "}
                      <Image className="ms-3" src={Wink} alt="Wink Emoji" />
                    </p>
                    <Breakpoint large up>
                      <Image src={royaltyrateunderline} alt="Underline" />
                    </Breakpoint>
                  </div>
                  <div className="d-flex justify-content-center text-white py-4">
                    <Image
                      className="px-lg-3"
                      src={new88}
                      alt="88%"
                      style={
                        breakpoint === "xxlarge"
                          ? { width: "auto" }
                          : { maxHeight: "80px" }
                      }
                    />
                    <Image
                      className="px-lg-3"
                      src={new80}
                      alt="80%"
                      style={
                        breakpoint === "xxlarge"
                          ? { width: "auto" }
                          : { maxHeight: "80px" }
                      }
                    />
                    <Image
                      className="px-lg-3"
                      src={new50}
                      alt="50%"
                      style={
                        breakpoint === "xxlarge"
                          ? { width: "auto" }
                          : { maxHeight: "80px" }
                      }
                    />
                  </div>
                  <p className="m-0 p-0 text-center">
                    Other Platform’s Royalty Rate
                  </p>
                </Col>
              </Row>
            </Card>
          </Container>
        </Breakpoint>
        <Breakpoint medium down className="h-100">
          <Container
            fluid
            className="p-0 mb-3 d-flex justify-content-center align-items-start"
            style={{ backgroundColor: "#1F1F1F" }}
          >
            <Card
              className="mx-2 border-R20 seller_section2_background"
              style={{
                backgroundColor: "#141415",
                border: "1px solid #3D3D3D",
                maxWidth: "1254px",
                minHeight: "max-content",
              }}
            >
              <Row className="p-0 m-0">
                <Col
                  sm={6}
                  className="px-lg-5 d-flex flex-column justify-content-center"
                >
                  <Breakpoint medium down>
                    <div className="mt-5 mb-4 font-size32 poppins-500 p-0">
                      <span className="text-primary">91</span> is better
                    </div>
                  </Breakpoint>
                  <p className="body-text1">
                    Why let someone get rich at the expense of your creativity?
                    You should be able to keep most of your hard-earned money.
                    We help you keep most of your earnings. You can earn one of
                    the highest commissions of up to 91% on our 3D Marketplace.
                    Sell your 3D assets in our 3D Marketplace, Znanye. You can
                    also enjoy additional benefits periodically based on your
                    aggregated revenues with us.
                  </p>
                </Col>
                <Col
                  sm={6}
                  className="py-5 mt-3 d-flex flex-column justify-content-center align-items-center"
                >
                  <div className="pb-5 text-center">
                    <Image
                      className="w-lg-100 w-75"
                      src={RoyaltyRate}
                      alt="Royalty Rate"
                    />
                  </div>
                  <p className="subtitle p-0 m-0">
                    <span className="text-primary">Royalty Rate</span> - Better
                    Than These{" "}
                    <Image className="ms-3" src={Wink} alt="Wink Emoji" />
                  </p>
                  <div className="d-flex justify-content-center text-white py-4">
                    <Image
                      className="px-3"
                      src={new88}
                      alt="88%"
                      style={{ maxHeight: "80px" }}
                    />
                    <Image
                      className="px-3"
                      src={new80}
                      alt="80%"
                      style={{ maxHeight: "80px" }}
                    />
                    <Image
                      className="px-3"
                      src={new50}
                      alt="50%"
                      style={{ maxHeight: "80px" }}
                    />
                  </div>
                  <p className="m-0 p-0 text-center">
                    Other Platform’s Royalty Rate
                  </p>
                </Col>
              </Row>
            </Card>
          </Container>
        </Breakpoint>
        <Breakpoint large up>
          <Container
            fluid
            className="p-0 h-90vh d-flex justify-content-center align-items-center"
          >
            <Card
              className="px-5 w-100 d-flex align-items-center"
              style={{
                backgroundColor: "#141415",
                borderTop: "1px solid #3D3D3D",
                borderBottom: "1px solid #3D3D3D",
                // minHeight: "750px",
              }}
            >
              <Card
                className="py-5"
                style={{
                  backgroundColor: "#141415",
                  border: 0,
                  minHeight: "750px",
                  maxWidth: "1254px",
                }}
              >
                <div>
                  <div className="d-flex mt-4">
                    <div
                      className={`${
                        sectionThree === "3d_viewer" ? "text-primary" : ""
                      } subtitle d-flex flex-column mx-4`}
                      onClick={() => setSectionThree("3d_viewer")}
                    >
                      <span>3D Viewer</span>
                      <span
                        className={`${
                          sectionThree === "3d_viewer" ? "d-block" : ""
                        } mt-2`}
                        style={
                          sectionThree === "3d_viewer"
                            ? styles.selectedSectionThree
                            : styles.unselectedSectionThree
                        }
                      />
                    </div>
                    <div
                      className={`${
                        sectionThree === "upload_limit" ? "text-primary" : ""
                      } subtitle d-flex flex-column mx-4`}
                      onClick={() => setSectionThree("upload_limit")}
                    >
                      <span>Upload Limit</span>
                      <span
                        className={`${
                          sectionThree === "upload_limit" ? "d-block" : ""
                        } mt-2`}
                        style={
                          sectionThree === "upload_limit"
                            ? styles.selectedSectionThree
                            : styles.unselectedSectionThree
                        }
                      />
                    </div>
                    <div
                      className={`${
                        sectionThree === "analytics" ? "text-primary" : ""
                      } subtitle d-flex flex-column mx-4`}
                      onClick={() => setSectionThree("analytics")}
                    >
                      <span>Analytics</span>
                      <span
                        className={`${
                          sectionThree === "analytics" ? "d-block" : ""
                        } mt-2`}
                        style={
                          sectionThree === "analytics"
                            ? styles.selectedSectionThree
                            : styles.unselectedSectionThree
                        }
                      />
                    </div>
                  </div>
                  {sectionThree === "3d_viewer" && (
                    <Row>
                      <Col sm={6} className="text-start px-4 mt-5">
                        <h3 className="mb-3 h3">
                          <span className="text-primary">Seeing</span> is
                          Believing
                        </h3>
                        <p className="subtitle my-4 text-start">
                          Let the world admire your creativity. Create and
                          upload your{" "}
                          <a className="text-decoration-none" href={`/`}>
                            professional 3D models.
                          </a>{" "}
                          Use our web-based 3D product viewer to showcase your
                          3D creations. It acts like a 360 product viewer and
                          allows customers to rotate the 3D model for the
                          highest quality 3D experience. Sell on Znanye and see
                          your portfolio and earnings grow.
                        </p>
                        <a
                          className="text-decoration-none"
                          href={"/3dmodels/food/burger-3d-model/viewer/"}
                          state={{ previousPage: "seller" }}
                        >
                          <Button
                            // dark={true}
                            className="explore-button-no-neo explore-button-large-no-neo mt-5"
                            style={{ backgroundColor: "#27292A" }}
                          >
                            SEE OUR 3D VIEWER
                          </Button>
                        </a>
                      </Col>
                      <Col sm={6}>
                        <Breakpoint large up>
                          <div className="m-lg-5 my-4 py-4">
                            <video
                              ref={videoRef}
                              autoPlay="autoplay"
                              loop="loop"
                              playsInline
                              muted
                              // poster={Section1Image}
                              className="w-100"
                              style={{ borderRadius: 24 }}
                            >
                              <source
                                src={
                                  "https://dukqh1e9lwqr5.cloudfront.net/ZnanyeVRVideo.webm"
                                }
                                alt="Burger Video"
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        </Breakpoint>
                        <Breakpoint
                          medium
                          down
                          className="position-relative"
                          style={{ height: 400 }}
                        >
                          <div
                            className="my-5 overflow-hidden w-100 d-flex justify-content-center position-absolute"
                            style={{ borderRadius: 20 }}
                          >
                            <video
                              ref={videoRef}
                              autoPlay="autoplay"
                              loop="loop"
                              playsInline
                              muted
                              // poster={Section1Image}
                              style={{ borderRadius: 24, height: 350 }}
                            >
                              <source
                                src={
                                  "https://dukqh1e9lwqr5.cloudfront.net/ZnanyeVRVideo.webm"
                                }
                                alt="Burger Video"
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        </Breakpoint>
                      </Col>
                    </Row>
                  )}
                  {sectionThree === "upload_limit" && (
                    <Row>
                      <Col sm={5} className="text-start px-4 mt-5">
                        <h3 className="mb-3 h3">
                          <span className="text-primary">
                            Upload As many as you want !{" "}
                          </span>
                          We don't charge like other platforms
                        </h3>
                        <p className="subtitle my-4 text-start">
                          We are in beta phase so your uploading limit is
                          <b> 500mb per user</b> , but you are allowed to upload
                          unlimited models 😉
                        </p>
                        <a
                          className="text-decoration-none"
                          href={"/sell-3d-login"}
                        >
                          <Button
                            // dark={true}
                            className="explore-button-no-neo explore-button-large-no-neo mt-5"
                            style={{ backgroundColor: "#27292A" }}
                          >
                            START UPLOADING
                          </Button>
                        </a>
                      </Col>
                      <Col sm={7} className="d-flex justify-content-center">
                        <Breakpoint large up>
                          <div className="">
                            <Image src={uploadlimit} />
                          </div>
                        </Breakpoint>
                      </Col>
                    </Row>
                  )}
                  {sectionThree === "analytics" && (
                    <Row className="p-0">
                      <Col sm={5} className="text-start px-4 mt-5">
                        <h3 className="mb-3 h3">
                          <span className="text-primary">Get Insights </span>
                          of your models !
                        </h3>
                        <p className="subtitle my-4 text-start">
                          Find out the impressions of your models, Track which
                          model is selling more, Which categories in trending
                          right now and many other feature
                        </p>
                        <a
                          className="text-decoration-none"
                          href={"/sell-3d-login"}
                        >
                          <Button
                            // dark={true}
                            className="explore-button-no-neo explore-button-large-no-neo mt-5"
                            style={{ backgroundColor: "#27292A" }}
                          >
                            START UPLOADING
                          </Button>
                        </a>
                      </Col>
                      <Col sm={7} className="d-flex justify-content-end p-0">
                        <Breakpoint large up>
                          <div>
                            <Image src={analytics} />
                          </div>
                        </Breakpoint>
                      </Col>
                    </Row>
                  )}
                </div>
                <div className="w-100 d-flex justify-content-end">
                  <div
                    className="d-flex pb-4"
                    style={{ position: "absolute", bottom: "10px" }}
                  >
                    <Card
                      className={`${
                        sectionThree === "3d_viewer" ? "" : "cursor-pointer"
                      } p-3 mx-3`}
                      style={{
                        background: "#292929",
                        border: "1px solid #3D3D3D",
                      }}
                      onClick={() => {
                        if (sectionThree === "upload_limit") {
                          setSectionThree("3d_viewer");
                        } else if (sectionThree === "analytics") {
                          setSectionThree("upload_limit");
                        }
                      }}
                    >
                      <BsArrowLeft size={25} />
                    </Card>
                    <Card
                      className={`${
                        sectionThree === "analytics" ? "" : "cursor-pointer"
                      } p-3`}
                      style={{
                        background: "#292929",
                        border: "1px solid #3D3D3D",
                      }}
                      onClick={() => {
                        if (sectionThree === "3d_viewer") {
                          setSectionThree("upload_limit");
                        } else if (sectionThree === "upload_limit") {
                          setSectionThree("analytics");
                        }
                      }}
                    >
                      <BsArrowRight color="white" size={25} />
                    </Card>
                  </div>
                </div>
              </Card>
            </Card>
          </Container>
        </Breakpoint>
        <Breakpoint medium down>
          <Container
            fluid
            className="p-0 d-flex justify-content-center align-items-center"
          >
            <Card
              className="w-100 py-5 d-flex align-items-center"
              style={{
                backgroundColor: "#141415",
                borderTop: "1px solid #3D3D3D",
                borderBottom: "1px solid #3D3D3D",
                minHeight: "1000px",
              }}
            >
              <div className="d-flex justify-content-between font-size18 px-3">
                <div
                  className={`${
                    sectionThree === "3d_viewer" ? "text-primary" : ""
                  } d-flex flex-column mx-2`}
                  onClick={() => setSectionThree("3d_viewer")}
                >
                  <span>3D Viewer</span>
                  <span
                    className={`${
                      sectionThree === "3d_viewer" ? "d-block" : ""
                    } mt-2`}
                    style={
                      sectionThree === "3d_viewer"
                        ? styles.selectedSectionThree
                        : styles.unselectedSectionThree
                    }
                  />
                </div>
                <div
                  className={`${
                    sectionThree === "upload_limit" ? "text-primary" : ""
                  } d-flex flex-column mx-2`}
                  onClick={() => setSectionThree("upload_limit")}
                >
                  <span>Upload Limit</span>
                  <span
                    className={`${
                      sectionThree === "upload_limit" ? "d-block" : ""
                    } mt-2`}
                    style={
                      sectionThree === "upload_limit"
                        ? styles.selectedSectionThree
                        : styles.unselectedSectionThree
                    }
                  />
                </div>
                <div
                  className={`${
                    sectionThree === "analytics" ? "text-primary" : ""
                  } d-flex flex-column mx-2`}
                  onClick={() => setSectionThree("analytics")}
                >
                  <span>Analytics</span>
                  <span
                    className={`${
                      sectionThree === "analytics" ? "d-block" : ""
                    } mt-2`}
                    style={
                      sectionThree === "analytics"
                        ? styles.selectedSectionThree
                        : styles.unselectedSectionThree
                    }
                  />
                </div>
              </div>
              {sectionThree === "3d_viewer" && (
                <Row className="px-4 w-100">
                  <Col sm={6} className="text-start" style={{ minHeight: 425 }}>
                    <div className="pt-5 mb-3 font-size32 poppins-500">
                      <span className="text-primary">Seeing</span> is Believing
                    </div>
                    <p className="subtitle my-4 text-start">
                      Let the world admire your creativity. Create and upload
                      your{" "}
                      <a className="text-decoration-none" href={`/`}>
                        professional 3D models.
                      </a>{" "}
                      Use our web-based 3D product viewer to showcase your 3D
                      creations. It acts like a 360 product viewer and allows
                      customers to rotate the 3D model for the highest quality
                      3D experience. Sell on Znanye and see your portfolio and
                      earnings grow.
                    </p>
                    <a
                      className="text-decoration-none"
                      href={"/3dmodels/food/burger-3d-model/viewer/"}
                    >
                      <Button
                        // dark={true}
                        className="explore-button-no-neo explore-button-large-no-neo"
                        style={{ backgroundColor: "#27292A" }}
                      >
                        See Our 3D Viewer
                      </Button>
                    </a>
                  </Col>
                  <Col sm={6}>
                    <Breakpoint
                      medium
                      down
                      className="position-relative"
                      style={{ height: 400 }}
                    >
                      <div
                        className="my-5 overflow-hidden w-100 d-flex justify-content-center position-absolute"
                        style={{ borderRadius: 20 }}
                      >
                        <video
                          ref={videoRef}
                          autoPlay="autoplay"
                          loop="loop"
                          playsInline
                          muted
                          // poster={Section1Image}
                          style={{ borderRadius: 24, height: 350 }}
                        >
                          <source
                            src={
                              "https://dukqh1e9lwqr5.cloudfront.net/ZnanyeVRVideo.webm"
                            }
                            alt="Burger Video"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </Breakpoint>
                  </Col>
                </Row>
              )}
              {sectionThree === "upload_limit" && (
                <Row className="px-4 w-100">
                  <Col sm={6} className="text-start" style={{ minHeight: 425 }}>
                    <div className="pt-5 mb-3 font-size32 poppins-500">
                      <span className="text-primary">
                        Upload As many as you want !{" "}
                      </span>
                      We don't charge like other platforms
                    </div>
                    <p className="subtitle my-4 text-start">
                      We are in beta phase so your uploading limit is
                      <b> 500mb per user</b> , but you are allowed to upload
                      unlimited models 😉
                    </p>
                    <a className="text-decoration-none" href={"/sell-3d-login"}>
                      <Button
                        // dark={true}
                        className="explore-button-no-neo explore-button-large-no-neo"
                        style={{ backgroundColor: "#27292A" }}
                      >
                        START UPLOADING
                      </Button>
                    </a>
                  </Col>
                  <Col sm={6}>
                    <Breakpoint
                      medium
                      down
                      className="position-relative"
                      style={{ width: "100%", height: 400 }}
                    >
                      <div className="my-5 overflow-hidden w-100 d-flex justify-content-center position-absolute p-0">
                        <Image src={uploadlimitmobile} />
                      </div>
                    </Breakpoint>
                  </Col>
                </Row>
              )}
              {sectionThree === "analytics" && (
                <Row className="px-4 w-100">
                  <Col sm={6} className="text-start" style={{ minHeight: 425 }}>
                    <div className="pt-5 mb-3 font-size32 poppins-500">
                      <span className="text-primary">Get Insights </span>
                      of your models !
                    </div>
                    <p className="subtitle my-4 text-start">
                      Find out the impressions of your models, Track which model
                      is selling more, Which categories in trending right now
                      and many other feature
                    </p>
                    <a className="text-decoration-none" href={"/sell-3d-login"}>
                      <Button
                        // dark={true}
                        className="explore-button-no-neo explore-button-large-no-neo"
                        style={{ backgroundColor: "#27292A" }}
                      >
                        START UPLOADING
                      </Button>
                    </a>
                  </Col>
                  <Col sm={6}>
                    <Breakpoint
                      medium
                      down
                      className="position-relative"
                      style={{ width: "100%", height: 400 }}
                    >
                      <div className="my-2 overflow-hidden w-100 d-flex justify-content-center position-absolute p-0">
                        <Image src={analytics} style={{ width: "350px" }} />
                      </div>
                    </Breakpoint>
                  </Col>
                </Row>
              )}
              <div className="w-100 mt-2 d-flex justify-content-center">
                <div className="d-flex">
                  <Card
                    className={`${
                      sectionThree === "3d_viewer" ? "" : "cursor-pointer"
                    } p-3 mx-3`}
                    style={{
                      background: "#292929",
                      border: "1px solid #3D3D3D",
                    }}
                    onClick={() => {
                      if (sectionThree === "upload_limit") {
                        setSectionThree("3d_viewer");
                      } else if (sectionThree === "analytics") {
                        setSectionThree("upload_limit");
                      }
                    }}
                  >
                    <BsArrowLeft size={25} />
                  </Card>
                  <Card
                    className={`${
                      sectionThree === "analytics" ? "" : "cursor-pointer"
                    } p-3`}
                    style={{
                      background: "#292929",
                      border: "1px solid #3D3D3D",
                    }}
                    onClick={() => {
                      if (sectionThree === "3d_viewer") {
                        setSectionThree("upload_limit");
                      } else if (sectionThree === "upload_limit") {
                        setSectionThree("analytics");
                      }
                    }}
                  >
                    <BsArrowRight color="white" size={25} />
                  </Card>
                </div>
              </div>
            </Card>
          </Container>
        </Breakpoint>
        <Breakpoint large up>
          <Container
            fluid
            className="mt-5 pt5"
            style={{ backgroundColor: "#1F1F1F" }}
          >
            <div
              className="d-flex justify-content-center mt-5 p-4 text-center"
              style={{ zIndex: 20 }}
            >
              <h1 className="h3 poppins-500 p-0" style={{ lineHeight: "56px" }}>
                <span className="text-primary">Free Subscription</span> to First
                200 Sellers
              </h1>
            </div>
            <Row className="d-flex justify-content-center align-items-center">
              <Col className="text-center" lg={{ span: 6 }}>
                {/* <p className="subtitle text-center">
                  We are offering
                  <span className="text-primary"> free subscription </span>
                  so you will get maximum commission ( 91 % ) on each sell and
                  for non subscriber commission rate is ( 80 % )
                </p> */}
                <p className="p-4" style={{ lineHeight: "25.5px" }}>
                  We are offering free subscription to our first few sellers,
                  that comes with the opportunity to{" "}
                  <span className="poppins" style={{ fontWeight: 700 }}>
                    {" "}
                    earn upto 91%{" "}
                  </span>
                  commission on your creations. This is a limited period offer.
                </p>
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-items-center p-5">
              <Card
                className="mx-5 border-R20 seller_section4_background d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#141415",
                  border: "1px solid #3D3D3D",
                  width: 1000,
                  height: 300,
                }}
              >
                <h1 className="p-0 d-flex justify-content-center align-items-center text-primary">
                  169 / 200
                </h1>
                {transitionsX((styleProps) => (
                  <animated.div
                    className="position-absolute  d-flex justify-content-center align-items-center "
                    style={{ marginLeft: 700, top: "70px" }}
                  >
                    <Image
                      className="mx-4"
                      style={{ width: 180, height: 180 }}
                      src={smile_seller_gif}
                      alt="Smile"
                    />
                  </animated.div>
                ))}
                {/* <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ color: "#666666" }}
                  >
                    Registered sellers till date
                  </div> */}
              </Card>
            </div>
            <div className="d-flex justify-content-center">
              <a className="text-decoration-none" href={"/sell-3d-login"}>
                <Button
                  // dark={true}
                  // className="explore-button explore-button-large my-4"
                  className="explore-button-no-neo  explore-button-large-no-neo my-4"
                  style={{ backgroundColor: "#27292A" }}
                >
                  START SELLING
                </Button>
              </a>
            </div>
          </Container>
        </Breakpoint>
        <Breakpoint large up>
          <Container
            fluid
            className="p-0 h-100vh d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "#1F1F1F" }}
          >
            <Card
              className="mx-5 border-R20 w-100 seller_section5_background d-flex justify-content-center"
              style={{
                backgroundColor: "#141415",
                border: "1px solid #3D3D3D",
                maxWidth: 1254,
                minHeight: 633,
              }}
            >
              <div
                className="d-flex justify-content-center mt-5 p-5 text-center"
                style={{ zIndex: 20 }}
              >
                <Row className="p-0 m-0">
                  <Col className="text-center" lg={{ span: 8, offset: 2 }}>
                    <h1 className="h3 poppins-500 p-0">
                      <span className="text-primary">Marketing Campaigns</span>{" "}
                      lined up that will Drive Traffic to your products.
                    </h1>
                  </Col>
                </Row>
              </div>
              <Row className="p-0 m-0">
                <Col className="text-center" lg={{ span: 6, offset: 3 }}>
                  <p className="subtitle text-center">
                    We are putting in efforts to manage and grow traffic on the
                    platform that may allow your product the opportunity to grab
                    the limelight.
                  </p>
                </Col>
              </Row>
              <div className="p-5 d-flex justify-content-center">
                <a className="text-decoration-none" href={"/sell-3d-login"}>
                  <Button
                    // dark={true}
                    className="explore-button-no-neo explore-button-large-no-neo my-4"
                    style={{ backgroundColor: "#27292A" }}
                  >
                    UPLOAD MY 3D MODEL
                  </Button>
                </a>
              </div>
            </Card>
          </Container>
        </Breakpoint>
        <Breakpoint medium down>
          <Container
            fluid
            className="p-0 my-5 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "#1F1F1F" }}
          >
            <Card
              className="mx-2 border-R20 w-100 seller_section5_background d-flex justify-content-center"
              style={{
                backgroundColor: "#141415",
                border: "1px solid #3D3D3D",
                minHeight: 550,
              }}
            >
              <div className="" style={{ zIndex: 20 }}>
                <div className="font-size32 poppins-500 px-4">
                  <span className="text-primary">Marketing Campaigns</span>{" "}
                  lined up that will Drive Traffic to your products.
                </div>
              </div>
              <p className="subtitle px-4 mt-4">
                We are putting in efforts to manage and grow traffic on the
                platform that may allow your product the opportunity to grab the
                limelight.
              </p>
              <div className="px-4">
                <a className="text-decoration-none" href={"/sell-3d-login"}>
                  <Button
                    // dark={true}
                    className="explore-button-no-neo explore-button-large-no-neo my-4"
                    style={{ backgroundColor: "#27292A" }}
                  >
                    UPLOAD MY 3D MODEL
                  </Button>
                </a>
              </div>
            </Card>
          </Container>
        </Breakpoint>
        <Breakpoint large up>
          <Container
            fluid
            className={`${
              breakpoint === "xlarge" || breakpoint === "xxlarge"
                ? "h-200vh"
                : "h-150vh"
            } p-0 d-flex justify-content-center align-items-center`}
          >
            <Card
              className="w-100 seller_section6_background"
              style={{
                backgroundColor: "#141415",
                // border: "1px solid #3D3D3D",
                borderTop: "1px solid #3D3D3D",
                borderBottom: "1px solid #3D3D3D",
              }}
            >
              <h1 className="h3 poppins-500 p-5 m-5 text-center">
                " Why We <span className="text-primary">Stand Out </span>"
              </h1>
              <div className="d-flex justify-content-end">
                <Card
                  className="p-3 mx-5 mb-4 w-75 d-flex justify-content-between text-center"
                  style={{
                    backgroundColor: "#0d0d0d",
                    border: "1px solid #3D3D3D",
                    borderRadius: "16px",
                  }}
                >
                  <Row>
                    {[
                      znanyecomparelogo,
                      cgtradercomparelogo,
                      sketchfabcomparelogo,
                      turbosquidcomparelogo,
                    ].map((item) => {
                      return (
                        <Col>
                          <Image src={item} />
                        </Col>
                      );
                    })}
                  </Row>
                  <Row>
                    {[1, 2, 3, 4].map((item) => {
                      return (
                        <Col key={item}>
                          <div
                            className="body-text2"
                            style={{ color: "#666666" }}
                          >
                            ( Free Version )
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Card>
              </div>
              {comparision.map((company) => {
                return (
                  <Card
                    className="p-4 mb-5 text-center"
                    style={{
                      backgroundColor: "#141415",
                      borderTop: "1px solid #3D3D3D",
                      borderBottom: "1px solid #3D3D3D",
                    }}
                  >
                    <Row>
                      <Col>
                        <div
                          className="body-text1 text-start"
                          style={{ color: "#666666" }}
                        >
                          {company.header}
                        </div>
                      </Col>
                      <Card
                        className="mx-5 w-75"
                        style={{ backgroundColor: "#141415" }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <Col>
                            <div className="subtitle">{company.text1}</div>
                          </Col>
                          <Col>
                            <div className="subtitle">{company.text2}</div>
                          </Col>
                          <Col>
                            <div className="subtitle">{company.text3}</div>
                          </Col>
                          <Col>
                            <div className="subtitle">{company.text4}</div>
                          </Col>
                        </div>
                      </Card>
                    </Row>
                  </Card>
                );
              })}
            </Card>
          </Container>
        </Breakpoint>
        <Breakpoint medium down>
          <Container
            fluid
            className={`${
              breakpoint === "xlarge" || breakpoint === "xxlarge"
                ? "h-200vh"
                : "my-5"
            } p-0 d-flex justify-content-center align-items-center`}
          >
            <Card
              className="w-100 seller_section6_background"
              style={{
                backgroundColor: "#141415",
                // border: "1px solid #3D3D3D",
                borderTop: "1px solid #3D3D3D",
                borderBottom: "1px solid #3D3D3D",
              }}
            >
              <h1 className="h3 poppins-500 mb-2 mt-4 text-center">
                " Why We <span className="text-primary">Stand Out </span>"
              </h1>
              <MobileTableComparison />
            </Card>
          </Container>
        </Breakpoint>
        <Breakpoint large up>
          <Container
            fluid
            className="p-0 h-90vh d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "#1F1F1F" }}
          >
            <Card
              className="mx-5 w-100 seller_section7_background"
              style={{
                backgroundColor: "#141415",
                border: "1px solid #3D3D3D",
                borderRadius: "40px",
                maxWidth: 1254,
              }}
            >
              <Row>
                <Col className="d-flex justify-content-center align-items-center">
                  <h1 className="h3 poppins-500 p-0 m-5">
                    Be A Part of the best 3D Selling Platform
                  </h1>
                </Col>
                <Col className="d-flex p-4 justify-content-end me-3">
                  <a className="text-decoration-none" href={"/sell-3d-login"}>
                    <Card
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        borderRadius: "40px",
                        minWidth: "200px",
                        minHeight: "300px",
                      }}
                    >
                      <BsArrowRight color="black" size={25} />
                      <div className="text-black poppins-500">
                        START SELLING
                      </div>
                    </Card>
                  </a>
                </Col>
              </Row>
            </Card>
          </Container>
        </Breakpoint>
        <Breakpoint medium down>
          <Container
            fluid
            className="p-0 my-5 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "#1F1F1F" }}
          >
            <Card
              className="mx-2 p-0 w-100 seller_section7_background d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#141415",
                border: "1px solid #3D3D3D",
                borderRadius: "40px",
                minHeight: 500,
              }}
            >
              <h1 className="poppins-500 p-3">
                Be A Part of the best 3D Selling Platform
              </h1>
              <a className="text-decoration-none" href={"/sell-3d-login"}>
                <Card
                  className="px-5 w-100 d-flex flex-row justify-content-center align-items-center"
                  style={{
                    borderRadius: "40px",
                    height: "70px",
                    minWidth: 300,
                  }}
                >
                  <div className="text-black poppins-500 me-3">
                    START SELLING
                  </div>
                  <BsArrowRight color="black" size={25} />
                </Card>
              </a>
            </Card>
          </Container>
        </Breakpoint>
        <Breakpoint large up>
          <Container
            fluid
            // className="p-0 h-80vh d-flex justify-content-center"
            className="py-5 my-5 h-80vh d-flex justify-content-center"
            style={{ backgroundColor: "#1F1F1F" }}
          >
            <Card
              className="p-0"
              style={{
                backgroundColor: "#1F1F1F",
                border: 0,
                maxWidth: 1254,
              }}
            >
              <Row className="p-0 m-0">
                <Col
                  sm={5}
                  className="px-5 d-flex flex-column seller_section8_background"
                  style={{ height: "80vh" }}
                >
                  <div className="caption">Support</div>
                  <h1 className="h3 poppins-500 px-0 py-2 text-primary">
                    FAQs
                  </h1>
                  <div>
                    Everything you need to know about the product and payment.
                    Cant find answer you're looking for? Please mail us at
                    <a
                      href="mailto:talk2us@znanye.com?subject=Contact%20us"
                      style={{ textDecoration: 0 }}
                      className="mx-1 text-primary  poppins"
                    >
                      talk2us@znanye.com
                    </a>
                    {/* talk2us@znanye.com */}
                  </div>
                </Col>
                <Col sm={6} className="p-0 mx-5">
                  {faqs.map((faq) => {
                    return (
                      <div
                        key={faq.id}
                        className="py-3 mt-2 "
                        style={{ borderBottom: "1px solid #3D3D3D" }}
                      >
                        <div className="d-flex justify-content-between">
                          <div
                            className="subtitle p-0"
                            onClick={() => setSelectedFaq(faq.id)}
                          >
                            {faq.question}
                          </div>
                          {selectedFaq === faq.id ? (
                            <AiOutlineMinus
                              size={25}
                              className="cursor-pointer"
                              onClick={() => {
                                setSelectedFaq(0);
                                setOpenClose(false);
                              }}
                            />
                          ) : (
                            <AiOutlinePlus
                              size={25}
                              className="cursor-pointer"
                              onClick={() => {
                                setSelectedFaq(faq.id);
                                setOpenClose(true);
                              }}
                            />
                          )}
                        </div>
                        {selectedFaq === faq.id && (
                          <FAQSpringElement
                            answer={faq.answer}
                            openClose={openClose}
                          />
                        )}
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Card>
          </Container>
        </Breakpoint>
        <Breakpoint medium down>
          <Container
            fluid
            className="p-0 my-5 d-flex justify-content-center"
            style={{ backgroundColor: "#1F1F1F" }}
          >
            <Card
              className="p-0"
              style={{
                backgroundColor: "#1F1F1F",
                border: 0,
              }}
            >
              <div className="px-4">
                <div className="caption">Support</div>
                <div className="font-size32 poppins-500 px-0 py-2 text-primary">
                  FAQs
                </div>
                <div>
                  Everything you need to know about the product and payment.
                  Cant find answer you're looking for? Please mail us at
                  <a
                    href="mailto:talk2us@znanye.com?subject=Contact%20us"
                    style={{ textDecoration: 0 }}
                    className="mx-1 text-primary poppins"
                  >
                    talk2us@znanye.com
                  </a>
                  {/* talk2us@znanye.com */}
                </div>
              </div>
              <div className="px-4">
                {faqs.map((faq) => {
                  return (
                    <div
                      key={faq.id}
                      className="py-lg-3 pb-3 mt-2"
                      style={{ borderBottom: "1px solid #3D3D3D" }}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: 20, zIndex: 30 }}
                      >
                        <div
                          className="font-size18 p-0"
                          onClick={() => setSelectedFaq(faq.id)}
                        >
                          {faq.question}
                        </div>
                        {selectedFaq === faq.id ? (
                          <AiOutlineMinus
                            size={25}
                            onClick={() => setSelectedFaq(0)}
                          />
                        ) : (
                          <AiOutlinePlus
                            size={25}
                            onClick={() => setSelectedFaq(faq.id)}
                          />
                        )}
                      </div>
                      {selectedFaq === faq.id && (
                        <FAQSpringElement
                          answer={faq.answer}
                          openClose={openClose}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </Card>
          </Container>
        </Breakpoint>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  selectedSectionThree: {
    height: "2px",
    backgroundColor: "#f7941d",
  },
  unselectedSectionThree: {
    height: "0px",
  },
};

export default SellerLanding;

import React, { useState } from "react";
import { CardContent, ListItemGroup, ListItem, Button } from "ui-neumorphism";
import { Image, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "actions/AuthActions";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  profileicon,
  shoppingbagicon,
  hearticon,
  shoppingcarticon,
  uploadicon,
  harddriveicon,
  logouticon,
} from "assets";

const ProfileDropdown = ({ profileDropdown, in_admin_panel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state?.authReducer?.user?.is_admin);
  const seller = useSelector((state) => state?.authReducer?.seller);
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";
  const [componentHover, setComponentHover] = useState("");
  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  return (
    <Card
      // dark={true}
      className={` custom-card-profile position-absolute my-2 ${
        profileDropdown ? "d-block" : "d-none"
      }`}
      // style={{ borderRadius: 20, right: 0, border: "1px solid #BEBEBE" }}
      style={{
        borderRadius: 20,
        right: 0,
        minWidth: "230px",
        backgroundColor: "#141414",
        border: "2px solid #3d3d3d",
      }}
    >
      {/* <CardContent className="p-0 m-0"> */}
      <a
        href="/profile"
        style={{ textDecoration: "none" }}
        onClick={() => handleNavigationClick("/profile")}
      >
        <div className="d-flex justify-content-start align-items-center p-3">
          <Image
            src={profileicon}
            alt="Profile Icon"
            style={styles.dropdownInactiveIcon}
          />
          <div
            className="ms-2 w-100"
            block
            dark={true}
            onMouseEnter={() => {
              setComponentHover("profile");
            }}
            onMouseLeave={() => {
              setComponentHover("");
            }}
          >
            <span
              className={`${
                componentHover === "profile"
                  ? "text-primary fw-bold"
                  : "text-white"
              } poppins-500 ps-2 d-flex justify-content-start`}
              style={{
                backgroundColor: `${
                  componentHover === "profile" ? "#2E2E2E" : ""
                }`,
              }}
            >
              PROFILE
            </span>
          </div>
        </div>
      </a>
      <div className="w-100" style={styles.dropdownTopBorder}>
        <div className="">
          {/* <Link to="/orders" style={{ textDecoration: 0 }}>
              <Image src={shoppingbagicon} alt="Shopping Bag Icon" style={styles.dropdownInactiveIcon} />
              <Button
                text
                dark={true}
                onMouseEnter={() => {
                  setComponentHover("orders")
                }}
                onMouseLeave={() => {
                  setComponentHover("")
                }}
              >
                <span
                  className={`${componentHover === "orders" ? "text-primary fw-bold" : "text-white"}`}
                  style={{ backgroundColor: `${componentHover === "orders" ? "#2E2E2E" : ""}` }}
                >
                  My Orders
                </span>
              </Button>
            </Link> */}
          {/* <Link
            onClick={() => handleNavigationClick("/orders")}
            to="/orders"
            style={{ textDecoration: 0 }}
          > */}
          <a
            href="/orders"
            style={{ textDecoration: "none" }}
            onClick={() => handleNavigationClick("/orders")}
          >
            <div className="d-flex justify-content-start align-items-center px-3 pt-3">
              <Image
                src={shoppingbagicon}
                alt="Shopping Bag Icon"
                style={styles.dropdownInactiveIcon}
              />
              <div
                className="ms-2 w-100"
                block
                dark={true}
                onMouseEnter={() => {
                  setComponentHover("orders");
                }}
                onMouseLeave={() => {
                  setComponentHover("");
                }}
              >
                <span
                  className={`${
                    componentHover === "orders"
                      ? "text-primary fw-bold"
                      : "text-white"
                  } poppins-500 ps-2 d-flex justify-content-start`}
                  style={{
                    backgroundColor: `${
                      componentHover === "orders" ? "#2E2E2E" : ""
                    }`,
                  }}
                >
                  MY ORDERS
                </span>
              </div>
            </div>
          </a>
          {/* <Link
            onClick={() => handleNavigationClick("/favourites")}
            to="/favourites"
            style={{ textDecoration: 0 }}
          > */}
          <a
            href="/favourites"
            style={{ textDecoration: "none" }}
            onClick={() => handleNavigationClick("/favourites")}
          >
            <div className="d-flex justify-content-start align-items-center px-3 pt-3">
              <Image
                src={hearticon}
                alt="Heart Icon"
                style={styles.dropdownInactiveIcon}
              />
              <div
                className="ms-2 w-100"
                block
                dark={true}
                onMouseEnter={() => {
                  setComponentHover("favourites");
                }}
                onMouseLeave={() => {
                  setComponentHover("");
                }}
              >
                <span
                  className={`${
                    componentHover === "favourites"
                      ? "text-primary fw-bold"
                      : "text-white"
                  } poppins-500 ps-2 d-flex justify-content-start`}
                  style={{
                    backgroundColor: `${
                      componentHover === "favourites" ? "#2E2E2E" : ""
                    }`,
                  }}
                >
                  FAVOURITES
                </span>
              </div>
            </div>
          </a>
          {/* <Link
            onClick={() => handleNavigationClick("/cart")}
            to="/cart"
            style={{ textDecoration: 0 }}
          > */}
          <a
            href="/cart"
            style={{ textDecoration: "none" }}
            onClick={() => handleNavigationClick("/cart")}
          >
            <div className="d-flex justify-content-start align-items-center p-3">
              <Image
                src={shoppingcarticon}
                alt="Shopping Cart Icon"
                style={styles.dropdownInactiveIcon}
              />
              <div
                className="ms-2 w-100"
                block
                dark={true}
                onMouseEnter={() => {
                  setComponentHover("cart");
                }}
                onMouseLeave={() => {
                  setComponentHover("");
                }}
              >
                <span
                  className={`${
                    componentHover === "cart"
                      ? "text-primary fw-bold"
                      : "text-white"
                  } poppins-500 ps-2 d-flex justify-content-start`}
                  style={{
                    backgroundColor: `${
                      componentHover === "cart" ? "#2E2E2E" : ""
                    }`,
                  }}
                >
                  CART
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="w-100" style={styles.dropdownTopBorder}>
        {/* <Link
          onClick={() => handleNavigationClick("/sell-3d/uploadproducts")}
          to="/sell-3d/uploadproducts"
          style={{ textDecoration: 0 }}
        > */}
        <a
          href="/sell-3d/uploadproducts"
          style={{ textDecoration: "none" }}
          onClick={() => handleNavigationClick("/orders")}
        >
          <div className="d-flex justify-content-start align-items-center px-3 pt-3">
            <Image
              src={uploadicon}
              alt="Upload Icon"
              style={styles.dropdownInactiveIcon}
            />
            <div
              className="ms-2 w-100"
              block
              dark={true}
              onMouseEnter={() => {
                setComponentHover("uploadproducts");
              }}
              onMouseLeave={() => {
                setComponentHover("");
              }}
            >
              <span
                className={`${
                  componentHover === "uploadproducts"
                    ? "text-primary fw-bold"
                    : "text-white"
                } poppins-500 ps-2 d-flex justify-content-start`}
                style={{
                  backgroundColor: `${
                    componentHover === "uploadproducts" ? "#2E2E2E" : ""
                  }`,
                }}
              >
                UPLOAD MODEL
              </span>
            </div>
          </div>
        </a>
        <a
          onClick={() => handleNavigationClick("/sell-3d/myproducts")}
          href="/sell-3d/myproducts"
          style={{ textDecoration: 0 }}
        >
          <div className="d-flex justify-content-start align-items-center p-3">
            <Image
              src={harddriveicon}
              alt="Hard Drive Icon"
              style={styles.dropdownInactiveIcon}
            />
            <div
              className="ms-2 w-100"
              block
              dark={true}
              onMouseEnter={() => {
                setComponentHover("myproducts");
              }}
              onMouseLeave={() => {
                setComponentHover("");
              }}
            >
              <span
                className={`${
                  componentHover === "myproducts"
                    ? "text-primary fw-bold"
                    : "text-white"
                } poppins-500 ps-2 d-flex justify-content-start`}
                style={{
                  backgroundColor: `${
                    componentHover === "myproducts" ? "#2E2E2E" : ""
                  }`,
                }}
              >
                MY PRODUCTS
              </span>
            </div>
          </div>
        </a>
      </div>
      <div className="w-100" style={styles.dropdownTopBorder}>
        <a
          // to={`${param1 === "admin" ? "/admin" : "/"}`}

          style={{ textDecoration: 0 }}
        >
          <div className="d-flex justify-content-start align-items-center p-3">
            <Image
              src={logouticon}
              alt="Logout Icon"
              style={styles.dropdownInactiveIcon}
            />
            <div
              className="ms-2 w-100"
              block
              dark={true}
              onMouseEnter={() => {
                setComponentHover("logout");
              }}
              onMouseLeave={() => {
                setComponentHover("");
              }}
              onClick={() => {
                dispatch(actions.loggingOut());
                // handleNavigationClick(`${param1 === "admin" ? "/admin" : "/"}`);
              }}
            >
              <span
                className={`${
                  componentHover === "logout"
                    ? "text-primary fw-bold"
                    : "text-white"
                } poppins-500 ps-2 d-flex justify-content-start`}
                style={{
                  backgroundColor: `${
                    componentHover === "logout" ? "#2E2E2E" : ""
                  }`,
                }}
              >
                LOGOUT
              </span>
            </div>
          </div>
        </a>
      </div>
      {/* </CardContent> */}
    </Card>
  );
};

const styles = {
  dropdownTopBorder: {
    borderTop: "2px solid #3d3d3d",
    // marginTop: "10px",
    // paddingTop: "10px",
  },
  dropdownInactiveIcon: {
    width: "17px",
    height: "17px",
  },
  dropdownActiveIcon: {
    width: "17px",
    height: "17px",
    backgroundColor: "#2E2E2E",
    color: "#f7941d",
  },
};

export default ProfileDropdown;

import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Button as RButton,
} from "react-bootstrap";
import { Button, Card, CardAction, IconButton } from "ui-neumorphism";
import * as actions from "actions/AuthActions";

const SalesTable = (props) => {
    const { monthlySales } = props;
    // const monthlySales = [];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return (
        <>
            <Row clasName="w-100 h-100 m-0">
                <Col sm={3} className="py-5" style={styles.tableRightBorder}>
                    <div className="body-text2">MONTH</div>
                </Col>
                <Col sm={3} className="py-5" style={styles.tableRightBorder}>
                    <div className="body-text2">SALES</div>
                </Col>
                <Col sm={3} className="py-5" style={styles.tableRightBorder}>
                    <div className="body-text2">EARNED</div>
                </Col>
                <Col sm={3} className="py-5">
                    <div className="body-text2">PAYMENT RECEIPT</div>
                </Col>
            </Row>
            {monthlySales.length === 0 &&
                <div>
                    <Row clasName="w-100 h-100 m-0">
                        <Col sm={3} className="py-4 d-flex justify-content-center align-items-center" style={styles.tableNoRecordRightBorder}>
                            -
                        </Col>
                        <Col sm={3} className="py-4 d-flex justify-content-center align-items-center" style={styles.tableNoRecordRightBorder}>
                            -
                        </Col>
                        <Col sm={3} className="py-4 d-flex justify-content-center align-items-center" style={styles.tableNoRecordRightBorder}>
                            -
                        </Col>
                        <Col sm={3} className="py-4 d-flex justify-content-center align-items-center" style={{ ...styles.recordBackground, maxHeight: "43px", color: "#51C0FF" }}>
                            -
                        </Col>
                    </Row>
                    <Row className="">
                        <Col sm={3} className="py-4" style={styles.tableRightBorder}>
                        </Col>
                        <Col sm={3} className="py-4" style={styles.tableRightBorder}>
                        </Col>
                        <Col sm={3} className="py-4" style={styles.tableRightBorder}>
                        </Col>
                        <Col sm={3} className="py-4">
                        </Col>
                    </Row>
                </div>}
            {monthlySales?.map((monthlySale, index) => {
                //consolelog("index", index);
                return (
                    <>
                        <div className={`${index > 0 ? "d-block" : "d-none"}`}>
                            <Row>
                                <Col sm={3} className="py-4" style={styles.tableRightBorder}>
                                </Col>
                                <Col sm={3} className="py-4" style={styles.tableRightBorder}>
                                </Col>
                                <Col sm={3} className="py-4" style={styles.tableRightBorder}>
                                </Col>
                                <Col sm={3} className="py-4">
                                </Col>
                            </Row>
                        </div>
                        <Row clasName="w-100 h-100 mt-5" style={styles.recordBackground}>
                            <Col sm={3} className="py-4" style={styles.tableRightBorder}>
                                <div className="body-text2">{months[monthlySale.month - 1] + " " + monthlySale.year}</div>
                            </Col>
                            <Col sm={3} className="py-4" style={styles.tableRightBorder}>
                                <div className="body-text2">{monthlySale.sold_count}</div>
                            </Col>
                            <Col sm={3} className="py-4" style={styles.tableRightBorder}>
                                <div className="body-text2">{monthlySale.payable}</div>
                            </Col>
                            <Col sm={3} className="py-4">
                                <b>
                                    <a
                                        className="explore-text"
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            actions.downloadPayoutInvoice(monthlySale.id);
                                        }}
                                        style={{ textDecoration: 0 }}
                                    >
                                        DOWNLOAD
                                    </a>
                                </b>
                            </Col>
                        </Row>
                    </>
                );
            })}
        </>
    );
}

const styles = {
    descriptionDiv: {
        borderRadius: "40px",
        backgroundColor: "#141415",
        padding: 100,
    },
    recordBackground: {
        backgroundColor: "#1f1f1f"
    },
    tableRightBorder: {
        // borderLeft: "1px solid #1f1f1f",
        borderRight: "1px solid #3D3D3D",
    },
    tableNoRecordRightBorder: {
        backgroundColor: "#1f1f1f",
        borderRight: "1px solid #3D3D3D",
        maxHeight: "43px"

    }
};

export default SalesTable;
import { useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

const VRTraining = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param2 = params.length > 1 ? params[1] : "";

  useEffect(() => {
    if (!param2) {
      navigate("/vrsolutions");
    }
  }, [param2]);

  if (param2) {
    return <Outlet />;
  }

  return <></>;
};

export default VRTraining;

import {
  account_updates,
  purchase_confirmation,
  approval,
  denial,
  feedback,
  new_comment,
  new_model_release,
  new_review,
  review_or_rate,
  sale_confirmation,
  sale_promotion,
  views,
} from "assets";

export const notification_types = {
  Purchase_confirmation:
    "Send a notification to users when they successfully purchase a 3D model.",
  Sale_confirmation:
    "Send a notification to sellers when one of their models is purchased.",
  New_model_releases:
    "Send a notification to users when a new model is added to the site in a category they have shown interest in.",
  Sale_promotions:
    "Send notifications to users about sales or discounts on specific models or categories.",
  Account_updates:
    "Send notifications to users when there are updates to their account information or when they need to take action, such as verifying their email address",
  New_Activity: "New comment or Review on your product.",
  Feedback:
    "Feedback Form to improve user experience ( Users who are using site continuously or if they spent more than 5 min on the site or they leave too early )",
  Review_or_rate:
    "Review or rate “Bike 3D Model” that you have purchased ( after 2 days )",
  Product_views:
    "Views on product - seller ( 100,200,500,1000,2000,3000,5000,10000 )",
  Approval: "Model approved or denied by admin ",
};

export const notification_icons = {
  account_updates: account_updates,
  purchase_confirmation: purchase_confirmation,
  approval: approval,
  denial: denial,
  feedback: feedback,
  new_comment: new_comment,
  new_model_release: new_model_release,
  new_review: new_review,
  review_or_rate: review_or_rate,
  sale_confirmation: sale_confirmation,
  sale_promotion: sale_promotion,
  views: views,
};

export const dummy_user_notification = [
  {
    type: "purchase_confirmation",
    content: "You have just purchased <strong>“Burger 3D Model“</strong>",
  },
  {
    type: "sale_confirmation",
    content:
      "Congratulations ! You Have New Sell, Someone just bought <strong>“Car 3D Model“</strong>",
  },
  {
    type: "new_model_release",
    content:
      "New Models arrived in your favorite category  <strong>“Food 3D Model“</strong>",
  },
  {
    type: "sale_promotion",
    content:
      "New coupon Available ! use <strong>“znanye30”</strong> and get 30%OFF",
  },
  {
    type: "new_comment",
    content:
      "Someone just posted comment on your <strong>“UFO 3D Model”</strong>",
  },
  {
    type: "new_review",
    content: "Someone just posted review on your <strong>“Spacesuite”</strong>",
  },
  {
    type: "feedback",
    content:
      "Feel free to give us feedback , We are continuously improving our experience !<br/><a href='https://www.google.com'>click here</a>",
  },
  {
    type: "review_or_rate",
    content:
      "Review or rate <strong>“Bike 3D Model”</strong> that you have purchased",
  },
  {
    type: "approval",
    content:
      "Congratulations ! Your model <strong>“Burger 3D model”</strong> is approved",
  },
  {
    type: "views",
    content: "100 views on you <strong>“bicycle 3d model“</strong>",
  },
  {
    type: "account_updates",
    content: "Bank Details are missing . Please complete the profile",
  },
];

export const styles = {
  desktopContainer: {
    maxHeight: 600 + "px",
    // minWidth: 90+'%',
    width: 450 + "px",
    minHeight: 250 + "px",
    position: "absolute",
    top: 80,
    right: 360,
    backgroundColor: "#141415",
    borderRadius: "16px",
    overflow: "scroll",
    zIndex: 10,
    border:"2px solid rgb(61, 61, 61)",
    userSelect: "none", 
    WebkitUserSelect: "none", 
    MozUserSelect: "none", 
    msUserSelect: "none" 

    
  },
  mobileContainer: {
    width: 100 + '%',
    backgroundColor: "#141415",
    borderRadius: "16px",
    position: "static",
    paddingTop: 60,
    paddingBottom: 60,
    minHeight:"100vh",
    userSelect: "none", 
    WebkitUserSelect: "none", 
    MozUserSelect: "none", 
    msUserSelect: "none" 

  },
  clear: {
    color: "#51C0FF",
    cursor: "pointer",
  },
  clearDisabled: {
    color: "#51C0FF",
    opacity: 0.2,
    cursor: "not-allowed",
  },
  header: {
    padding: 20 + "px",
    position: "sticky",
    top: 0,
    backgroundColor: "#141415",
  },
  notification_container: {
    textOverflow: "ellipsis",
    // height: 80,
    padding:"18px"
  },
  notification_title: {
    color: "#FFFFFF",
    margin: 0,
    marginLeft: 10,
  },
  hr: {
    color: "#3D3D3D",
    height: 1
  },
  icon: {
    width: 24 + "px",
    height: 24 + "px",
  },
  underline: {
    height: 1,
    backgroundColor: "#3D3D3D",
    // color:"#3D3D3D"
  },
};

import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Button, IconButton, Card, Alert } from "ui-neumorphism";
import { Link, Outlet, useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "core-ui/pages/Business.scss";
import {
  FaChevronDown,
  FaChevronUp,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { ZSlider, ContactForm, ContactFormBusiness } from "components";

// import {
//   modelling,
//   animation,
//   unitydevelopment,
//   optimization,
//   deviceintegration,
//   conceptdesign,
//   slide,
// } from "assets_old/business";
import {
  modelling,
  animation,
  unitydevelopment,
  optimization,
  deviceintegration,
  conceptdesign,
  slide,
} from "assets/business";

// import {
//   guyvrmobile,
//   whoarewemobile,
//   tyre_company,
//   darkden,
//   entry_gate,
// } from "assets_old/business";

import { tyre_company, darkden, entry_gate } from "assets/business";

import { Breakpoint, useCurrentWidth } from "react-socks";
import { useDispatch } from "react-redux";
import { getintouchwithznanye } from "assets";
import * as actions from "actions/UserActions";
import { Helmet } from "react-helmet";
import { Button as BootstrapButton } from "react-bootstrap";

const process = [
  {
    id: 1,
    title: "Concept Design",
    description:
      "Our concept designers come up with innovative ideas as to how the product experience can look like.",
    image: conceptdesign,
  },
  {
    id: 2,
    title: "Modelling",
    description:
      "The 3D artist then creates realistic models to make the components come to life.",
    image: modelling,
  },
  {
    id: 3,
    title: "Animation",
    description:
      "We add animations to the models so that they can move and interact with the users of the product.",
    image: animation,
  },
  {
    id: 4,
    title: "Unity Development",
    description:
      "Our developers then fuses the models and the animations together in an interactive environment.",
    image: unitydevelopment,
  },
  {
    id: 5,
    title: "Optimization",
    description:
      "Some of our math geeks then optimize the VR experience so that it doesn't take a lifetime to dow load the products on your devices.",
    image: optimization,
  },
  {
    id: 6,
    title: "Device Integration",
    description:
      "Finally, we create device specific releases so that the product can run on any VR headset, on a phone or on a PC.",
    image: deviceintegration,
  },
];

const casestudies = [
  {
    id: 1,
    title: "ZnanyeLand",
    image: entry_gate,
    link: "/casestudy/znanyeland",
  },
  {
    id: 2,
    title: "AR Solution for Tyre Company",
    image: tyre_company,
    link: "/casestudy/ar-solution-for-tyre-company",
  },
  { id: 3, title: "Dark Den", image: darkden, link: "/casestudy/dark-den" },
];

const Business = () => {
  // useEffect(() => {

  //   // returned function will be called on component unmount
  //   return () => {
  //     window.scrollTo(0, 0)
  //   }
  // }, [])
  const [currentState, setCurrentState] = useState(0);

  const form = useRef(null);
  const myRefForm = useRef(null);
  const dispatch = useDispatch();

  const location = useLocation();

  const width = useCurrentWidth();

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    //consolelog(location.hash);
    if (location.hash == "#demoForm") {
      myRefForm.current.scrollIntoView();
      // window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <React.Fragment>
      {/* <Helmet>
        <title>{`Znanye - Sell VR creations n 3D models on our 3D asset store`}</title>
        <meta name="description" content="Get access to the best deals on VR and 3D models at our marketplace with a vast collection of models. Buy and sell the products hassle-free" />
        <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
      </Helmet> */}
      {/* <Container
        className="d-flex w-100 flex-column justify-content-lg-center justify-content-between h-lg-100 mt-5 py-5 ps-lg-5 home-background-business"
        fluid
        style={styles.vrGuyContainer}
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="text-lg-start text-center mt-5 ps-lg-4 user-select-none" style={{ zIndex: 200 }}>
          <p className="text-white my-1 h2">Changing the way you</p>
          <h2 className="text-primary my-1">Imagine Life</h2>
          <p className="text-white poppins-500 my-5 me-5 w-50" style={{ fontSize: 24 }}>
            Manage your 3D assets. Distribute 3D & AR experiences. Collaborate with others. Showcase your work. Buy & sell 3D models.
          </p>
        </div>
        <div className="mt-5 mt-lg-4 text-center text-lg-start ps-lg-4">
          <Button
            dark={true}
            className="explore-button explore-button-large my-lg-5"
            size="large"
            onClick={() => {
              myRefForm.current.scrollIntoView();
            }}
          >
            <span className="poppins">Book a Demo</span>
          </Button>
        </div>
        <Breakpoint
          medium
          down
          className="d-flex justify-content-center"
          style={{ marginBottom: -48 }}
        >
          <Image className="mt-5 pt-5" src={guyvrmobile} />
        </Breakpoint>
        <iframe
          className="position-absolute"
          style={{ backgroundColor: 'transparent', right: 0 }}
          src={`${axios.defaults.baseURL}viewer/business_model/`}
          width="1000"
          height="700"
        />
      </Container> */}
      <Container
        className="d-flex w-100 flex-column justify-content-lg-center justify-content-between h-lg-100 mt-5 py-5 ps-lg-5 home-background-business"
        fluid
        style={styles.vrGuyContainer}
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Row>
          <Col
            xs={{ span: 12, order: 2 }}
            lg={{ span: width > 1500 ? 6 : 7, order: 1 }}
            className="d-flex align-items-center"
          >
            <div className="text-lg-start text-center mt-5 ps-lg-4 user-select-none position-lg-absolute">
              <Breakpoint large up>
                <h1 className="text-white my-1 p-0 h2">Changing the way you</h1>
                <h1 className="text-primary my-1 p-0 h2">Imagine Life</h1>
                <p
                  className="text-white poppins-500 my-5 me-lg-5 text-left"
                  style={{ fontSize: 24, maxWidth: 600 }}
                >
                  Experience Virtual Reality (VR). Play an Immersive VR Game. Go
                  through VR training.{" "}
                  <a
                    href="/contact-us/#Buy-VR-Headset"
                    style={{ textDecoration: "none" }}
                  >
                    Buy a VR headset.
                  </a>{" "}
                  Buy a VR Asset. Sell your VR creation. The possibilities are
                  infinite.
                </p>
              </Breakpoint>
              <Breakpoint medium down style={{ fontSize: "30px" }}>
                <h1 className="text-white my-1 p-0 h2">Changing the way you</h1>
                <h1 className="text-primary my-1 p-0 h2">Imagine Life</h1>
                <p
                  className="text-white poppins-300 my-5 me-lg-5 text-lg-left text-xs-center"
                  style={{ fontSize: 24, maxWidth: 600 }}
                >
                  Experience Virtual Reality (VR). Play an Immersive VR Game. Go
                  through VR training.{" "}
                  <a
                    href="/contact-us/#Buy-VR-Headset"
                    style={{ textDecoration: "none" }}
                  >
                    Buy a VR headset.
                  </a>{" "}
                  Buy a VR Asset. Sell your VR creation. The possibilities are
                  infinite.
                </p>
              </Breakpoint>
              <BootstrapButton
                dark={true}
                className="mt-5 explore-button-no-neo btn btn-primary"
                size="lg"
                onClick={() => {
                  myRefForm.current.scrollIntoView();
                }}
              >
                <span className="poppins">CONTACT US</span>
              </BootstrapButton>
            </div>
          </Col>
          <Col
            lg={{ span: width > 1500 ? 6 : 5, order: 2 }}
            xs={{ order: 1, span: 12 }}
          >
            <Breakpoint large up>
              <iframe
                style={{ backgroundColor: "transparent" }}
                src={`${axios.defaults.baseURL}viewer/business_model/`}
                width="100%"
                height="700"
              />
            </Breakpoint>
            <Breakpoint medium down className="my-5">
              <iframe
                style={{ backgroundColor: "transparent" }}
                src={`${axios.defaults.baseURL}viewer/business_model/`}
                width="100%"
                height="400"
              />
            </Breakpoint>
          </Col>
        </Row>
        {/* <Breakpoint
          medium
          down
          className="d-flex justify-content-center"
          style={{ marginBottom: -48 }}
        >
          <Image className="mt-5 pt-5" src={guyvrmobile} />
        </Breakpoint> */}
      </Container>
      <Breakpoint large up>
        <Container
          className="d-flex flex-column justify-content-center align-items-center p-0 h-100 text-center business-background-case"
          fluid
          // style={styles.caseContainer}
        >
          <h2 className="h3 poppins-400 text-white text-shadow pt-5 mt-lg-5 user-select-none">
            Our Case Studies
          </h2>
          <Container
            className="d-flex flex-column justify-content-center align-items-center px-lg-5"
            fluid
            // style={{ height: "100%" }}
          >
            <div
              className="my-lg-2 px-lg-5 d-flex justify-content-center align-items-center"
              style={{ width: 1400 }}
            >
              <ZSlider type="business" items={casestudies} />
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          className="d-flex flex-column justify-content-center align-items-center p-0 h-100 text-center business-background-case"
          fluid
          // style={styles.caseContainer}
        >
          <h2 className="h3 poppins-400 text-white text-shadow user-select-none">
            Our Case Studies
          </h2>
          <Container
            className="d-flex flex-column justify-content-center align-items-center"
            fluid
          >
            {/* <div
              className="w-100 d-flex justify-content-center align-items-center"
            > */}
            <ZSlider type="business" items={casestudies} />
            {/* </div> */}
          </Container>
          <Image className="mb-3" src={slide} alt="Slide" />
        </Container>
      </Breakpoint>
      <Container
        className="d-flex w-100 flex-column align-items-center h-100 text-center py-5 home-background business-home-we"
        fluid
      >
        <Row>
          <Col lg={{ span: 10, offset: 1 }}>
            <h3 className="h3 p-0 text-white p-5 d-flex justify-content-center text-shadow user-select-none">
              The Online Virtual Reality World
            </h3>
            <div
              className="poppins-400 text-white body-text1 user-select-none"
              style={styles.whoAreWe}
            >
              Wake Up. Snooze. Get Up. Eat. Do Something. Exist. Eat some more.
              Breadth. Sleep. Wake Up. All Over Again: Wake Up. Snooze. Get Up.
              Eat. Do Something. Exist. Eat some more. Breadth. Sleep. Wake Up.
              <br />
              <br />
              Gauche.
              <br />
              <br />
              Your existing life is becoming too predictable. It is mundane. It
              is repetitive. We are working on changing the way you imagine
              life. <br />
              <br />
              Play one of the best virtual reality games, read case studies with
              solutions, explore virtual reality training solutions, experience
              the immersive technology market, visit the 3-D asset store,
              <a style={{ textDecoration: "none" }} href="/3dmodels">
                {" "}
                download 3D models
              </a>
              , create your own virtual reality. A world of new virtual reality
              world awaits you. <br />
              <br />
              Welcome to the New World- a new virtual reality world.
              <br />
              <br />
              Imagine. Download. Create. Inspire. Buy. Sell. Play. Learn. Have
              Fun! A VR World built using VR technology. A VR World that offers
              VR Games, allows you to download 3D models. A VR World that allows
              you to explore VR case studies, offers virtual reality training
              solutions. A VR World that looks very different from your
              repetitive life. If you are a VR enthusiast, a VR connoisseur, or
              just someone getting your foot through the door, we can also help
              you create your own virtual reality world making use of immersive
              technology. Visit our Business Pages to explore virtual reality
              case studies, virtual reality problems and solutions. If you are a
              developer, we can work with you to help you create virtual reality
              games. You may end up creating one of the best virtual reality
              games the world has seen! Get in touch with us. You can also visit
              our 3D asset store and shop for 3D models. Our integrated payment
              gateway on our payment page will make your purchase faster and
              smother. If you are a seller, you can earn more, become an
              inspiration and do much more by{" "}
              <a
                href="/sell-3d/uploadproducts"
                style={{ textDecoration: "none" }}
              >
                selling your 3D models online
              </a>{" "}
              on our store. Start here at Znanye.
              <br />
              <br />
              Au Courant.
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Container
        className="d-flex flex-column align-items-center h-100 text-center py-5 business-background-process"
        fluid
      >
        <Breakpoint medium up>
          <h3 className="poppins-400 text-white py-5 text-shadow user-select-none">
            The Process
          </h3>
        </Breakpoint>
        <Container fluid style={{ height: "100%" }}>
          <Row>
            <Col lg={2}></Col>
            <Col
              lg={8}
              className="text-center d-flex flex-column align-items-center"
            >
              <h4 className="text-white user-select-none">
                {process[currentState].title}
              </h4>
              <div
                className="text-white text-center processDescription body-text2 user-select-none"
              // style={styles.processDescription}
              >
                {process[currentState].description}
              </div>
              <div
                className="p-3 d-flex justify-content-center"
                key={process[currentState].id}
              >
                <Image
                  src={process[currentState].image}
                  className="w-100 mt-3"
                  style={styles.processImage}
                />
              </div>
            </Col>
            <Col
              lg={2}
              className="d-flex flex-lg-column justify-content-between align-items-center"
            >
              <IconButton
                dark={true}
                rounded
                size="large"
                text={false}
                onClick={() => {
                  if (currentState != 0) {
                    setCurrentState(currentState - 1);
                  }
                }}
              >
                <Breakpoint large up>
                  <FaChevronUp />
                </Breakpoint>
                <Breakpoint medium down>
                  <FaChevronLeft />
                </Breakpoint>
              </IconButton>
              <div className="text-white slider-icon-button d-flex align-items-center justify-content-center">
                {currentState - 1 > 0 ? currentState - 1 : "."}
              </div>
              <div className="text-white slider-icon-button d-flex align-items-center justify-content-center">
                {currentState > 0 ? currentState : "."}
              </div>
              <Alert
                dark={true}
                inset
                text
                type="info"
                className="text-white slider-icon-button d-flex justify-content-center align-items-center"
                style={{ width: 45, height: 45 }}
              >
                <div className="d-flex justify-content-end align-items-center">
                  {currentState + 1}
                </div>
              </Alert>
              <div className="text-white slider-icon-button d-flex align-items-center justify-content-center">
                {currentState + 2 < 7 ? currentState + 2 : "."}
              </div>
              <div className="text-white slider-icon-button d-flex align-items-center justify-content-center">
                {currentState + 3 < 7 ? currentState + 3 : "."}
              </div>
              <IconButton
                dark={true}
                rounded
                size="large"
                text={false}
                onClick={() => {
                  if (currentState != 5) {
                    setCurrentState(currentState + 1);
                  }
                }}
              >
                <Breakpoint large up>
                  <FaChevronDown />
                </Breakpoint>
                <Breakpoint medium down>
                  <FaChevronRight />
                </Breakpoint>
              </IconButton>
            </Col>
          </Row>
        </Container>
      </Container> */}
      <Container
        className="d-flex w-100 flex-column align-items-center h-100 text-center p-4 p-lg-5 my-5"
        fluid
        style={styles.freeDemoContainer}
        ref={myRefForm}
      >
        {/* <div className="d-flex justify-content-center mt-5 mb-5 mb-lg-none text-shadow user-select-none">
          <h3 className="text-white m-1">Get In Touch</h3>
        </div> */}
        <Container
          className="p-0 my-5"
          style={{
            width: "100%",
            backgroundColor: "#292929",
            borderRadius: "40px",
            margin: "0px 150px 0px 0px",
          }}
        >
          <Container
            className="p-0"
            style={{
              width: "100%",
              backgroundColor: "#141415",
              borderRadius: "40px",
              margin: "-70px 70px 70px 70px",
            }}
          >
            <Row className="">
              <Col
                lg={6}
                className="d-flex justify-content-center align-items-center"
              >
                <Breakpoint large up>
                  <div className="">
                    <Image
                      src={getintouchwithznanye}
                      alt="Get in Touch with Znanye"
                    />
                  </div>
                </Breakpoint>
                <Breakpoint medium down className="text-center">
                  <div className="mt-5 d-flex justify-content-center align-items-center">
                    <p className="h3 poppins-400 text-white search-label user-select-none">
                      Get In Touch With{" "}
                      <span className="text-primary">Znanye</span>
                    </p>
                  </div>
                </Breakpoint>
              </Col>
              <Col lg={6} className={`my-3`}>
                <Row>
                  <Col lg={10} className="text-start">
                    <ContactForm form={form} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  caseContainer: {
    minHeight: "100vh",
  },
  freeDemoContainer: {
    minHeight: "90vh",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  processImage: {
    width: 534,
    height: 300,
  },
  whoAreWe: {
    // wordBreak: 'break-all',
    // wordWrap: "break-word",
    textAlign: "justify",
    textJustify: "inner-word",
    lineHeight: 1.5,
  },
};

export default Business;

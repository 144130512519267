import React, { useState, useEffect } from "react";
import {
  Form,
  FormControl,
  Row,
  Col,
  Image,
  Button as RButton,
  Card,
} from "react-bootstrap";
import { CardContent, Button, Subtitle2, IconButton } from "ui-neumorphism";
import { search } from "assets";
import { searchresult, imagenotavailable } from "assets";
import { Link, Navigate, Redirect, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "actions/UserActions";
import { Breakpoint } from "react-socks";

const SearchBarBlogs = (props) => {
  const { type, items, variant, searchWord, setSearchWord } = props;

  const [searchDropdown, setSearchDropdown] = useState(false);
  // const [searchWord, setSearchWord] = useState("");
  const [selected, setSelected] = useState(null);

  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);

  useEffect(async () => {
    const response = await actions.searchProduct(searchWord);
    if (response) {
      setProducts(
        response.filter((item) => item.is_verified === true).slice(0, 5)
      );
    }
  }, [searchWord]);

  return (
    <div className="w-100 position-relative py-0" style={{ zIndex: 1000 }}>
      <Breakpoint large up>
        <Form>
          <Card style={styles.navSearchbar}>
            <CardContent className="d-flex py-2 bg-transparent my-0">
              <FormControl
                type="search"
                placeholder="Search Blogs Here"
                className="me-2 my-1 border-0 outline-none body-text1 text-white"
                aria-label="Search"
                style={{ background: "none" }}
                onFocus={() => {
                  //consolelog(products);
                  setSearchDropdown(true);
                  if (products.length > 0) {
                    setSelected(0);
                  } else {
                    setSelected(null);
                  }
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setSearchDropdown(false);
                    setSelected(null);
                  }, 200);
                }}
                // onKeyDown={(e) => {
                //   if (e.key == "ArrowDown") {
                //     setSelected((selected + 1) % products.length);

                //   }
                //   if (e.key == "ArrowUp") {
                //     setSelected(((selected - 1) + products.length) % products.length);
                //   }
                //   if (e.key == "Enter") {
                //     if (selected) {
                //       navigate(`${variant == 'admin' ? '/admin' : ''}/product/${products[selected].id}/`)
                //     }
                //   }
                // }}
                onChange={(e) => setSearchWord(e.target.value)}
              />
              <IconButton
                size="large"
                className="my-1"
                dark={true}
                rounded
                disabled
              >
                <img src={search} alt="Search Bar" className="p-0 m-0" />
              </IconButton>
            </CardContent>
          </Card>
        </Form>
      </Breakpoint>
      <Breakpoint medium down>
        <Form>
          <Card style={styles.navMobileSearchbar}>
            <CardContent className="bg-transparent m-0 p-0 me-1 d-flex justify-content-center align-items-center">
              <FormControl
                type="search"
                placeholder="Search Blogs"
                className="me-1 border-0 outline-none body-text1 text-white"
                style={{ background: "none", height: "50px" }}
                onFocus={() => {
                  //consolelog(products);
                  setSearchDropdown(true);
                  if (products.length > 0) {
                    setSelected(0);
                  } else {
                    setSelected(null);
                  }
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setSearchDropdown(false);
                    setSelected(null);
                  }, 200);
                }}
                onChange={(e) => setSearchWord(e.target.value)}
              />
              <IconButton
                size="small"
                className="d-flex justify-content-center align-items-center"
                dark={true}
                rounded
                disabled
              >
                <Image
                  src={search}
                  alt="Search Bar"
                  className="p-0 m-0"
                  style={{ width: "20px", height: "20px" }}
                />
              </IconButton>
            </CardContent>
          </Card>
        </Form>
      </Breakpoint>
    </div>
  );
};

const styles = {
  searchBox: {
    backgroundColor: "#1B1C1D",
    borderRadius: 12,
  },
  navSearchbar: {
    backgroundColor: "#0d0d0d",
    borderRadius: 12,
    border: "1px solid #3D3D3D",
  },
  navMobileSearchbar: {
    height: "50px",
    backgroundColor: "#0d0d0d",
    borderRadius: 8,
    border: "1px solid #3D3D3D",
  },
};

export default SearchBarBlogs;

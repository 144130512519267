import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { IconButton } from "ui-neumorphism";
import { OrderCard } from "components";
import "core-ui/pages/MyAccount.scss";
import { emptypurchaselist, znanyelogin } from "assets";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "actions/AuthActions";
import { useEffect } from "react";
import { razorpayCheckout, PaypalCheckout } from "utilities";
import { Breakpoint } from "react-socks";
import { AiOutlineClose } from "react-icons/ai";

const Orders = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authReducer.token);
  const [paypalOrder, setPaypalOrder] = useState({});
  const [showPaypalCheckout, setShowPaypalCheckout] = useState(false);

  // alert(token);
  useEffect(() => {
    if (token) {
      dispatch(actions.loadOrders());
    }
  }, [token]);

  const user = useSelector((state) => state.authReducer.user);
  const orders = useSelector((state) => state.authReducer.user.orders);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  //consolelog("Orders:", orders);

  const navigate = useNavigate();
  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const successNavigation = (url) => {
    handleNavigationClick("/orders");
    // navigate("/orders");
    dispatch(actions.loadOrders());
  };

  const onFailure = () => {
    // navigate("/orders");
    handleNavigationClick("/orders");
  };

  const retryPayment = (order) => {
    const params = {
      order: order,
      name: user.name,
      contact: user.mobile,
      email: user.email,
    };

    if (user.phone_code === "+91" || user.phone_code === "91") {
      razorpayCheckout(params, successNavigation, onFailure);
    } else {
      setPaypalOrder(order);
      setShowPaypalCheckout(true);
    }
  };

  // uncomment to test the empty purchase list page
  // const orders = [];

  if (orders.length === 0) {
    return (
      <React.Fragment>
        <Breakpoint large up>
          <Container
            className="d-flex w-100 justify-content-center align-items-center h-100 mt-5 pt-5 ps-5 home-background-categories"
            fluid
            style={styles.emptyOrderListContainer}
          >
            <div>
              <div className="d-flex justify-content-center">
                <Image
                  src={emptypurchaselist}
                  alt="Empty Purchase List"
                  style={{ width: 250 }}
                />
              </div>
              <div className="d-flex justify-content-center mt-5 mb-5">
                <h4 className="text-primary m-1">OOPS ! </h4>
                <h4 className="text-white m-1">
                  You do not have any orders yet
                </h4>
              </div>
              <div className="d-flex justify-content-center mt-5 mb-5">
                <Link to="/3dmodels" style={{ textDecoration: "none" }}>
                  <Button
                    dark={true}
                    className="explore-button-no-neo btn btn-primary h-100"
                    size="large"
                  >
                    Buy Items
                  </Button>
                </Link>
              </div>
            </div>
          </Container>
        </Breakpoint>
        <Breakpoint medium down>
          <Container
            className="h-100 pt-5 home-background-categories"
            fluid
            style={styles.emptyOrderListContainer}
          >
            <div>
              <div className="d-flex justify-content-center">
                <Image
                  src={emptypurchaselist}
                  alt="Empty Purchase List"
                  style={{ width: 250, marginTop: 100 }}
                />
              </div>
              <div className="text-center mb-5 h4">
                <div className="text-primary m-1" style={{ fontSize: 35 }}>
                  OOPS !{" "}
                </div>
                <div className="text-white m-1" style={{ fontSize: 30 }}>
                  You haven't purchased any products
                </div>
              </div>
              <div>
                <Link to="/3dmodels" style={{ textDecoration: "none" }}>
                  <Button
                    dark={true}
                    className="explore-button-no-neo btn btn-primary position-fixed w-100 py-4"
                    size="large"
                    style={{ left: 0, bottom: 0, height: "60px" }}
                  >
                    Buy Items
                  </Button>
                </Link>
              </div>
            </div>
          </Container>
        </Breakpoint>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Container
        className="w-100 px-lg-5 home-background-categories"
        fluid
        style={{ paddingTop: 100 }}
      >
        <h3 className="text-white pb-lg-2 mx-lg-4 ps-2 ps-lg-0 pt-lg-5 subtitle">
          Orders
        </h3>
        <Row>
          <Col sm={9}>
            <div className="px-2 px-lg-4 py-4">
              <OrderCard items={orders} retryPayment={retryPayment} />
            </div>
          </Col>
          <Col sm={3}></Col>
        </Row>
        <Modal
          show={showPaypalCheckout ? true : false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={isAuthenticated ? "static" : true}
          backdropClassName="modal-backdrop login-modal-backdrop"
        >
          <Modal.Header
            className="d-flex flex-row justify-content-center"
            style={{
              backgroundColor: "#1F1F1F",
              border: "2.5px solid #BEBEBE",
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <Image src={znanyelogin} alt="Znanye Login" className="my-4" />
            </Modal.Title>
          </Modal.Header>
          <IconButton
            size="large"
            className="d-flex justify-content-center removeButtonRight position-absolute text-white"
            dark={true}
          >
            <AiOutlineClose
              size={20}
              onClick={() => {
                setShowPaypalCheckout(false);
              }}
            />
          </IconButton>
          <Modal.Body
            style={{
              border: "2.5px solid #BEBEBE",
              backgroundColor: "#2E2E2E",
            }}
          >
            <PaypalCheckout
              order={paypalOrder}
              setShowPaypalCheckout={setShowPaypalCheckout}
            />
          </Modal.Body>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  emptyOrderListContainer: {
    minHeight: "100vh",
  },
  orderCard: {
    height: 500,
  },
  cardAction: {
    backgroundColor: "#2E2E2E",
  },
  underline: {
    height: 1,
    backgroundColor: "white",
  },
};
export default Orders;

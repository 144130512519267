import { BlogCard, BlogTopSection, SearchBarBlogs } from "components";
import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { MdChevronRight } from "react-icons/md";
import { Button, Card } from "ui-neumorphism";
import * as adminActions from "actions/AdminActions";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { CKEditor } from "ckeditor4-react";

const BlogUpdate = () => {
  const { id } = useParams();
  const [blogTypes, setBlogTypes] = useState([]);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [tableContent, setTableContent] = useState(null);
  const [blogType, setBlogType] = useState(null);
  const [blogTypeData, setBlogTypeData] = useState(null);
  const [readTime, setReadTime] = useState(null);
  const [authorName, setAUthorName] = useState(null);
  const [publishedDate, setPublishedDate] = useState(null);
  const [blog, setBlog] = useState(null);
  const formRef = useRef();

  const findBlogTypes = async () => {
    const response = await adminActions.loadBlogTypes();
    setBlogTypes(response);
  };

  useEffect(async () => {
    findBlogTypes();
  }, []);

  useEffect(async () => {
    if (id) {
      const response = await adminActions.loadBlog(id);
      setBlog(response);
      setTitle(response?.title);
      setContent(response?.content);
      setTableContent(response?.table_content);
      setBlogType(response?.blog_type);
      setBlogTypeData(response?.blog_type_data);
      setReadTime(response?.read_time);
      setAUthorName(response?.author_name);
      setPublishedDate(response?.published_date);
    }
  }, [id]);

  const editorConfig = {
    extraPlugins: ["tableresize", "uploadimage", "createpdf", "toc"],
    skin: "moono-dark",
    removePlugins: [
      "exportpdf",
      "forms",
      "smiley",
      "language",
      "iframe",
      "about",
    ],
    // filebrowserBrowseUrl: 'http://localhost:4000/media/24b358cf-8e56-4b03-9843-29fabf589b8d/template/home-advt_jzx7KBU.jpeg',
    filebrowserImageUploadUrl:
      process.env.REACT_APP_BASE_URL + `blog/upload_blog_image/`,
    fileTools_requestHeaders: axios.defaults.headers.common,
    uploadUrl: process.env.REACT_APP_BASE_URL + `blog/upload_blog_image/`,
    contentsCss: [
      // "https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css",
      process.env.REACT_APP_CLOUDFRONT_URL +
        "/static/ckeditor/4.19.0/full-all/document-style.css",
    ],
    bodyClass: "document-editor",
    // height: 900,
    // width: 1000,
    height: "15.8cm",
    // width: "18.6cm",
    width: "100%",
    allowedContent: true,
    // extraPlugins: [
    //     'timestamp'
    // ]
    // exportPdf_options: {
    //     header_html: '<div class="styled">This is the Header</div>',
    //     footer_html: '<div class="styled-counter"><span class="date></span></div>',
    //     header_and_footer_css: '.styled { font-weight: bold; padding: 10px; display: flex; color: red; } .styled-counter {font-size: 10px; color: red; }',
    //     margin_top: '2cm',
    //     margin_bottom: '10cm',
    // }
    // exportPdf_options: {
    //     format: "A3"
    // }
    exportPdf_options: {
      header_html: '<div class="styled">Header content</div>',
      footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
      header_and_footer_css:
        ".styled { font-weight: bold; padding: 10px; text-align: center; color: red; }",
      margin_left: "1cm",
      margin_right: "2cm",
      format: "A5",
      page_orientation: "landscape",
    },
    protectedSource: [/{%[\s\S]*?%}+/g, /{{[\s\S]*?}}+/g],
    // formatOutput: false,
    // formatSource: false
  };

  const updateBlog = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    formData.append("content", content);
    formData.append("table_content", tableContent);
    formData.append("blog_type", blogType);
    formData.append("read_time", readTime);
    formData.append("author_name", authorName);
    formData.append("published_date", publishedDate);
    adminActions.updateBlog(id, formData);
  };

  if (!blog) {
    return <div></div>;
  }

  return (
    <Container className="my-5 py-5">
      <ul className="breadcrumb subtitle mx-1 mb-3">
        <li>
          <a href="/admin/blogs">Blogs</a>
        </li>
        <li>
          <a href="/admin/blogs">{blogTypeData?.title}</a>
        </li>
        <li>
          <a href={`/admin/blogs/${blog?.slug}`}>{blog?.title}</a>
        </li>
        <li>Edit</li>
      </ul>
      <Form ref={formRef} onSubmit={(e) => updateBlog(e)}>
        <Row>
          <Col lg={12}>
            <Card
              className="m-2 d-flex flex-column border-R20 admin-filedrop-card"
              dark={true}
            >
              <Row>
                <Col lg={4} className="p-5 body-text py-4 mb-0">
                  <Form.Label>Meta Title</Form.Label>
                  <Form.Control
                    name="meta_title"
                    type="text"
                    required
                    className="text-white"
                    placeholder="Enter Meta Title"
                    defaultValue={blog?.meta_title}
                    style={{
                      background: "#141414",
                      height: 50,
                      border: 0,
                    }}
                  />
                </Col>
                <Col lg={8} className="p-5 body-text py-4 mb-0">
                  <Form.Label>Meta Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    name="meta_description"
                    type="text"
                    required
                    className="text-white"
                    placeholder="Enter Meta Description"
                    defaultValue={blog?.meta_description}
                    style={{
                      background: "#141414",
                      border: 0,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="p-5 body-text py-4 mb-0">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    type="text"
                    required
                    className="text-white"
                    placeholder="Enter Title"
                    style={{
                      background: "#141414",
                      height: 50,
                      border: 0,
                    }}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4} className="p-5 body-text py-4 mb-0">
                  <Form.Label>Blog Type</Form.Label>
                  <Form.Select
                    required
                    name="blog_type"
                    type="text"
                    className="text-white body-text1 bank-type-select"
                    value={blogType}
                    onChange={(e) => setBlogType(e.target.value)}
                  >
                    {blogTypes.map((option) => {
                      return <option value={option.id}>{option.title}</option>;
                    })}
                  </Form.Select>
                </Col>
                <Col lg={4} className="p-5 body-text py-4 mb-0">
                  <Form.Label>Read Time</Form.Label>
                  <Form.Control
                    name="read_time"
                    type="text"
                    required
                    className="text-white"
                    placeholder="Enter Title"
                    style={{
                      background: "#141414",
                      height: 50,
                      border: 0,
                    }}
                    value={readTime}
                    onChange={(e) => setReadTime(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4} className="p-5 body-text py-4 mb-0">
                  <Form.Label>Author Name</Form.Label>
                  <Form.Control
                    name="author_name"
                    type="text"
                    required
                    className="text-white"
                    placeholder="Enter Title"
                    style={{
                      background: "#141414",
                      height: 50,
                      border: 0,
                    }}
                    value={authorName}
                    onChange={(e) => setAUthorName(e.target.value)}
                  />
                </Col>
                <Col lg={4} className="p-5 body-text py-4 mb-0">
                  <Form.Label>Published Date</Form.Label>
                  <Form.Control
                    name="published_date"
                    type="date"
                    required
                    className="text-white"
                    placeholder="Enter Title"
                    style={{
                      background: "#141414",
                      height: 50,
                      border: 0,
                    }}
                    value={publishedDate}
                    onChange={(e) => setPublishedDate(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4} className="p-5 body-text py-4 mb-0">
                  <Form.Label>Blog Image</Form.Label>
                  <Form.Control
                    name="blog_image"
                    type="file"
                    required
                    className="text-white"
                    placeholder="Enter Title"
                    style={{
                      background: "#141414",
                      height: 50,
                      border: 0,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="p-5 body-text text-white py-4 mb-0">
                  <Form.Label>Table of Content</Form.Label>
                  <CKEditor
                    initData={blog ? blog?.table_content : ""}
                    config={{ ...editorConfig, height: 400 }}
                    editorUrl={
                      process.env.REACT_APP_CLOUDFRONT_URL +
                      "/static/ckeditor/4.19.0/full-all/ckeditor.js"
                    }
                    onInstanceReady={(e) => {
                      e.editor.document.getBody().setStyle("color", "white");
                      e.editor.document
                        .getBody()
                        .setStyle("background-color", "#1F1F1F");
                      // e.editor.document.getBody().setKeystroke(17 + 118, 'pastetext')
                      // e.editor.document.getBody().setKeystroke(17 + 16 + 118, 'paste')
                      // in case the user switches to source and back
                      e.editor.on("contentDom", function () {
                        e.editor.document
                          .getBody()
                          .setStyle("background-color", "#1F1F1F");
                      });
                      if (blog) {
                        setTableContent(blog?.table_content);
                      }
                    }}
                    onChange={(event) => {
                      const data = event.editor.getData();
                      // console.log({ event, editor, data });
                      setTableContent(data);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="p-5 body-text py-4 mb-0 text-success">
                  <Form.Label>Content</Form.Label>
                  <CKEditor
                    initData={blog ? blog?.content : ""}
                    config={{
                      ...editorConfig,
                      height: 400,
                      dialog_backgroundCoverColor: "rgb(255, 254, 253)",
                    }}
                    editorUrl={
                      process.env.REACT_APP_CLOUDFRONT_URL +
                      "/static/ckeditor/4.19.0/full-all/ckeditor.js"
                    }
                    onInstanceReady={(e) => {
                      e.editor.document.getBody().setStyle("color", "white");
                      e.editor.document
                        .getBody()
                        .setStyle("background-color", "#1F1F1F");
                      // in case the user switches to source and back
                      e.editor.on("contentDom", function () {
                        e.editor.document
                          .getBody()
                          .setStyle("background-color", "#1F1F1F");
                      });

                      if (blog) {
                        setContent(blog?.content);
                      }
                    }}
                    onChange={(event) => {
                      const data = event.editor.getData();
                      // console.log({ event, editor, data });
                      setContent(data);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-center p-5">
                  <Button
                    dark={true}
                    className="explore-button"
                    style={{ width: 300 }}
                  >
                    <MdChevronRight size={20} /> Update Blog
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default BlogUpdate;

import React, { useRef, useEffect, useState, lazy } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button as BootstrapButton } from "react-bootstrap";
import { BsMeta } from "react-icons/bs";
import { Helmet } from "react-helmet";
import { Link, useLocation, Outlet } from "react-router-dom";
import { splash_screen_render, link_news } from "assets";
import { Breakpoint } from "react-socks";
import { Image } from "react-bootstrap";
import image1 from "../../assets/vr-training/vr-training-1.webp";
import image2 from "../../assets/vr-training/vr-training-2.webp";
import image3 from "../../assets/vr-training/vr-training-3.webp";
import image4 from "../../assets/vr-training/vr-training-4.webp";
import image5 from "../../assets/vr-training/vr-training-5.webp";
import image6 from "../../assets/vr-training/vr-training-6.webp";

const VRSolutions = () => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";
  const [changingBorder, setChangingBroder] = useState(false);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  if (param2) {
    return <Outlet />;
  }

  return (
    <React.Fragment>
      <Breakpoint large up>
        <Container
          fluid
          className="d-flex flex-column align-items-center py-5 my-5 text-white vrsolutions-background-case"
        >
          <Container
            className="my-5 position-relative d-flex"
            style={{
              width: "840px",
              height: "472px",
              backgroundColor: "#141415",
              borderRadius: "40px",
              margin: "0px -50px 0px 0px",
            }}
          >
            <div
              style={{
                minWidth: "840px",
                minHeight: "472px",
                overflow: "hidden",
                margin: "50px 50px 0px 0px",
                marginLeft: "-55px",
              }}
            >
              <Image
                loading="lazy"
                style={{ width: "840px", height: "100%" }}
                src={splash_screen_render}
                alt="Splash Screen Render"
              />
            </div>
          </Container>
          <div className="d-flex my-5 justify-content-center align-items-center">
            <a
              className="sitemap-link text-decoration-none"
              href="https://www.oculus.com/experiences/quest/6369043279819217/"
              target="_blank"
            >
              <BootstrapButton
                className="explore-button-no-neo"
                dark={true}
                size="large"
                style={{ border: "0px" }}
                onMouseEnter={() => setChangingBroder(true)}
                onMouseLeave={() => setChangingBroder(false)}
              >
                VISIT NOW <BsMeta></BsMeta>
              </BootstrapButton>
            </a>
          </div>
          <div className="my-5" style={{ maxWidth: 840, minWidth: 840 }}>
            <h3>"Master the Sky"</h3>
            <span className="d-block mt-5 mb-4 subtitle">
              Why We Started this Project:
            </span>
            <span className="d-block body-text1">
              Imagine a world where anyone can effortlessly command the skies,
              capturing breathtaking aerial footage, and embarking on daring
              missions with unparalleled precision. We started this project
              because we believe that the sky shouldn't be a limit but an
              invitation to explore, create, and thrive. We wanted to break free
              from traditional training methods and offer an experience that
              transcends ordinary boundaries. 
            </span>
            <span className="d-block mt-5 mb-4 subtitle">
              How We came up with this Solution:
            </span>
            <span className="d-block mb-3 body-text1">
              Our solution didn't come from simple thinking outside the box; we
              shattered the box. We couldn't ignore the fact that drone
              technology was revolutionizing countless industries and unleashing
              boundless creative possibilities. We saw a gap between the growing
              demand for skilled drone operators and the lack of engaging and
              effective training options available. We wanted to give wings to
              the dreams of every aspiring pilot, enabling them to maneuver
              through captivating environments and master the art of flight.
            </span>
            <Row>
              <div style={{ width: "50%" }}>
                <Image
                  className="my-3"
                  style={{ borderRadius: "16px", width: "100%" }}
                  src={image1}
                  alt="image"
                />
              </div>
              <div style={{ width: "50%" }}>
                <Image
                  className="my-3"
                  style={{ borderRadius: "16px", width: "100%" }}
                  src={image2}
                  alt="image"
                />
              </div>
              <div style={{ width: "50%" }}>
                <Image
                  className="my-3"
                  style={{ borderRadius: "16px", width: "100%" }}
                  src={image3}
                  alt="image"
                />
              </div>
              <div style={{ width: "50%" }}>
                <Image
                  className="my-3"
                  style={{ borderRadius: "16px", width: "100%" }}
                  src={image4}
                  alt="image"
                />
              </div>
              <div style={{ width: "50%" }}>
                <Image
                  className="my-3"
                  style={{ borderRadius: "16px", width: "100%" }}
                  src={image5}
                  alt="image"
                />
              </div>
              <div style={{ width: "50%" }}>
                <Image
                  className="my-3"
                  style={{ borderRadius: "16px", width: "100%" }}
                  src={image6}
                  alt="image"
                />
              </div>
            </Row>
            <span className="d-block mt-5 mb-4 subtitle">
              How does this satisfy our users:
            </span>
            <span className="d-block body-text1">
              Prepare to be spellbound by the wonders of "Master the Sky"! Our
              training program satisfies your deepest desires by: Enchanting
              immersion, thrilling challenges, personalized growth, empowering
              community. Unleash the power within you and master the skies with
              "Master the Sky." Let the enchantment of{" "}
              <a
                href="/blogs/apprenticeship/"
                style={{ textDecoration: "none" }}
              >
                drone training
              </a>{" "}
              bewitch your senses, ignite your imagination, and elevate your
              skills to new heights. Embrace the journey, embrace the magic, and
              get ready to soar like never before.
            </span>
            <span className="d-block mb-5 body-text1">
              And as a plus point, the damage of the drone lessens to zero as
              master the sky is a immersive experience of drone and easily
              available at home!
              {/* er through captivating environments and master the art of flight. */}
            </span>
          </div>
          <div className="body-text2">
            Kindly refer this privacy policy for more details -
            <a
              href="/vrsolutions/privacy_policy"
              style={{ textDecoration: "none" }}
            >
              <span style={{ color: "#51C0FF" }}> Users Privacy Policy </span>
              <img src={link_news} alt="link" style={styles.linkIcon} />
            </a>
          </div>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        {/* <Container
          fluid
          className="d-flex flex-column align-items-center py-5 my-5 text-white vrsolutions-background-case"
        > */}
        <Container fluid style={{ paddingTop: 60 }}>
          {/* <Container
            className="my-5 position-relative d-flex justify-content-center align-items-center p-0"
            style={{
              width: "90vw",
              // height: "auto",
              height: "200px",
              backgroundColor: "#141415",
              borderRadius: "16px",
              margin: "50px -50px 0px 0px",
            }}
          >
          </Container> */}
          <div
            className="my-5 d-flex justify-content-center"
            style={
              {
                // minWidth: "100%",
                // minHeight: "472px",
                // overflow: "hidden",
                // margin: "50px 50px 0px 0px",
                // marginLeft: "-55px",
              }
            }
          >
            <Image
              loading="lazy"
              style={{ width: "90vw" }}
              src={splash_screen_render}
              alt="Master the Sky"
            />
          </div>
          <div className="d-flex my-5 justify-content-center align-items-center">
            <a
              className="sitemap-link text-decoration-none"
              href="https://www.oculus.com/experiences/quest/6369043279819217/"
              target="_blank"
            >
              <BootstrapButton
                className="explore-button-no-neo"
                dark={true}
                size="large"
                style={{ border: "0px" }}
                onMouseEnter={() => setChangingBroder(true)}
                onMouseLeave={() => setChangingBroder(false)}
              >
                VISIT NOW <BsMeta></BsMeta>
              </BootstrapButton>
            </a>
          </div>
          <div className="w-100 mx-2 my-5 text-white ">
            {/* <h3>"Master the Sky"</h3> */}
            <span className="d-block mt-5 mb-4 font-size18">
              Why We Started this Project:
            </span>
            <span className="d-block body-text1">
              Imagine a world where anyone can effortlessly command the skies,
              capturing breathtaking aerial footage, and embarking on daring
              missions with unparalleled precision. We started this project
              because we believe that the sky shouldn't be a limit but an
              invitation to explore, create, and thrive. We wanted to break free
              from traditional training methods and offer an experience that
              transcends ordinary boundaries. 
            </span>
            <span className="d-block mt-5 mb-4  font-size18">
              How We came up with this Solution:
            </span>
            <span className="d-block mb-3 body-text1">
              Our solution didn't come from simple thinking outside the box; we
              shattered the box. We couldn't ignore the fact that drone
              technology was revolutionizing countless industries and unleashing
              boundless creative possibilities. We saw a gap between the growing
              demand for skilled drone operators and the lack of engaging and
              effective training options available. We wanted to give wings to
              the dreams of every aspiring pilot, enabling them to maneuver
              through captivating environments and master the art of flight.
            </span>
          </div>
          <Row className="p-0 m-0">
            <div style={{ width: "100%" }}>
              <Image
                className="my-4"
                style={{ borderRadius: "16px", width: "100%" }}
                src={image1}
                alt="image"
              />
            </div>
            <div style={{ width: "100%" }}>
              <Image
                className="my-4"
                style={{ borderRadius: "16px", width: "100%" }}
                src={image2}
                alt="image"
              />
            </div>
            <div style={{ width: "100%" }}>
              <Image
                className="my-4"
                style={{ borderRadius: "16px", width: "100%" }}
                src={image3}
                alt="image"
              />
            </div>
            <div style={{ width: "100%" }}>
              <Image
                className="my-4"
                style={{ borderRadius: "16px", width: "100%" }}
                src={image4}
                alt="image"
              />
            </div>
            <div style={{ width: "100%" }}>
              <Image
                className="my-4"
                style={{ borderRadius: "16px", width: "100%" }}
                src={image5}
                alt="image"
              />
            </div>
            <div style={{ width: "100%" }}>
              <Image
                className="my-4"
                style={{ borderRadius: "16px", width: "100%" }}
                src={image6}
                alt="image"
              />
            </div>
          </Row>
          <div className="w-100 mx-2 my-5 text-white">
            <span className="d-block mt-5 mb-4 font-size18">
              How does this satisfy our users:
            </span>
            <span className="d-block body-text1">
              Prepare to be spellbound by the wonders of "Master the Sky"! Our
              training program satisfies your deepest desires by: Enchanting
              immersion, thrilling challenges, personalized growth, empowering
              community. Unleash the power within you and master the skies with
              "Master the Sky." Let the enchantment of{" "}
              <a
                href="/blogs/apprenticeship/"
                style={{ textDecoration: "none" }}
              >
                drone training
              </a>{" "}
              bewitch your senses, ignite your imagination, and elevate your
              skills to new heights. Embrace the journey, embrace the magic, and
              get ready to soar like never before.
            </span>
            <span className="d-block mb-5 body-text1">
              And as a plus point, the damage of the drone lessens to zero as
              master the sky is a immersive experience of drone and easily
              available at home!
              {/* er through captivating environments and master the art of flight. */}
            </span>
          </div>
          <div className="m-2 font-size18 text-white">
            Kindly refer this privacy policy for more details -<br></br>
          </div>
          <div className=" m-2 ">
            <a
              href="/vrsolutions/privacy_policy"
              style={{ textDecoration: "none" }}
            >
              <span style={{ color: "#51C0FF" }}> Users Privacy Policy </span>
              <img src={link_news} alt="link" style={styles.linkIcon} />
            </a>
          </div>
        </Container>
      </Breakpoint>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    backgroundColor: "#161616",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  linkIcon: {
    width: "14px",
    height: "14px",
  },
};

export default VRSolutions;

import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Container,
  Image,
  Form,
  Button as RButton,
} from "react-bootstrap";
import { ZDropdown } from "components";
import moment from "moment";
import { Button, TabItem } from "ui-neumorphism";

const CouponForm = (props) => {
  const [date, setDate] = useState("");

  const {
    form,
    handleCreate,
    handleUpdate,
    validTillRef,
    couponCategoryRef,
    couponActivity,
    coupon,
  } = props;

  useEffect(() => {
    if (couponActivity == "update") {
      setDate(moment(coupon.valid_till).format("YYYY-MM-DD"));
    } else {
      setDate("");
    }
  }, [couponActivity]);

  return (
    <Form
      ref={form}
      onSubmit={couponActivity == "add" ? handleCreate : handleUpdate}
    >
      <Row className="py-3 subtitle d-flex align-items-center text-center border-bottom">
        <Col sm={3} className="p-2">
          <Form.Control
            required
            name="code"
            type="text"
            className="text-white body-text1"
            placeholder="Enter Coupon Name"
            defaultValue={couponActivity == "update" ? coupon.code : ""}
            style={{
              background: "#141414",
              padding: 10,
              border: 0,
            }}
          />
        </Col>
        <Col className="p-2">
          <ZDropdown
            options={[
              { value: "ABSOLUTE", label: "Absolute" },
              { value: "RELATIVE", label: "Relative" },
            ]}
            mode="couponselect"
            couponCategoryRef={couponCategoryRef}
            couponOption={coupon?.coupon_category}
          />
        </Col>
        <Col className="p-2">
          <Form.Control
            required
            name="flat_off"
            type="text"
            className="text-white body-text1 text-center"
            placeholder="Flat off"
            defaultValue={couponActivity == "update" ? coupon.flat_off : ""}
            style={{
              background: "#141414",
              padding: 10,
              border: 0,
            }}
          />
        </Col>
        <Col className="p-2">
          <Form.Control
            required
            ref={validTillRef}
            type="date"
            className="text-white body-text1 text-center"
            placeholder="Offer ends on"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            style={{
              background: "#141414",
              padding: 10,
              border: 0,
            }}
          />
        </Col>
        <Col sm={2} className="text-center">
          <Button
            dark={true}
            className="explore-button text-white"
            size="large"
            type="submit"
          >
            {couponActivity == "add" ? "SAVE" : "UPDATE"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CouponForm;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Spinner, Image, Button as BootstrapButton } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  Home,
  Product,
  RedirectUrl,
  SearchResults,
  Viewer,
  ImageViewer,
  BlogsIndex,
  Blogs,
  Blog,
  BlogList,
  Try3D,
} from "pages";

import {
  Login as AdminLogin,
  Product as AdminProduct,
  ProductMain as AdminProductMain,
  Categories as AdminCategories,
  CategoriesMain as AdminCategoriesMain,
  CategoryProducts as AdminCategoryProducts,
  Coupons as AdminCoupons,
  Payouts as AdminPayouts,
  Products as AdminProducts,
  BlogsIndex as AdminBlogsIndex,
  Blogs as AdminBlogs,
  Blog as AdminBlog,
  NewBlog as AdminNewBlog,
  BlogUpdate as AdminBlogUpdate,
  Editor,
  Approvals,
} from "pages/admin";

import {
  Cart,
  OrderDetails,
  PaymentDetails,
  OrderSuccessful,
} from "pages/Cart";
import ProductFiles from "pages/admin/Product/ProductFiles";
import { Profile, Favourites, Orders } from "pages/Account";
import { CategoryProducts, Categories } from "pages/categories";
import {
  Navbar,
  Footer,
  NotFound,
  MobileLogin,

  SellerCreated,
  TermsAndConditions,
} from "components";
import ProductEditor from "pages/ProductEditor/ProductEditor";

import { MyProducts, Seller, SellerLogin, SellerLanding } from "pages/Seller";
import Admin from "pages/admin/Admin";
import Terms from "pages/Terms/Terms";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import RefundAndCancellation from "pages/RefundAndCancellation/RefundAndCancellation";
import SellerTermsOfUse from "pages/TermsOfUse/SellerTermsOfUse";
import UserTermsOfUse from "pages/TermsOfUse/UserTermsOfUse";
import SellerPrivacyPolicy from "pages/PrivacyPolicy/SellerPrivacyPolicy";
import UserPrivacyPolicy from "pages/PrivacyPolicy/UserPrivacyPolicy";
import CookiesPolicy from "pages/PrivacyPolicy/CookiesPolicy";

import AboutUsPolicy from "pages/AboutUsPolicy/AboutUsPolicy";
import VerifyAccount from "pages/Account/VerifyAccount";
import ResetPassword from "pages/Account/ResetPassword";
import ContactUs from "pages/ContactUs";
import SiteMap from "pages/SiteMap/SiteMap";

import axios from "axios";
import * as actions from "actions/AuthActions";
import { Breakpoint, useCurrentWidth } from "react-socks";

import { ToastContainer } from "react-toastify";
import { Offline, Online } from "react-detect-offline";
import { offline } from "assets";
import { ScrollToTop } from "components";
import {
  Business,
  BusinessHome,
  CaseStudy,
  CaseStudy2,
  CaseStudy3,
} from "pages/Business";
import { IconButton } from "ui-neumorphism";
import { FaChevronUp } from "react-icons/fa";
import Error from "pages/Error/Error";
import { persistor } from "store";
import MobileNotification from "components/Notifications/MobileNotification";
import WeAreInNewsPage from "pages/WeAreInNewsPage";
import License from "pages/License/License";
import VRSolutions from "pages/VRSolutions/VRSolutions";
import VRSolutionsPrivacyPolicy from "pages/VRSolutions/VRSolutionsPrivacyPolicy";
import VRTraining from "pages/VRSolutions/VRTraining";
import VRTrainingPrivacyPolicy from "pages/VRSolutions/VRTrainingPrivacyPolicy";

const Routers = () => {
  const token = useSelector((state) => state.authReducer.token);
  const user = useSelector((state) => state.authReducer.user);
  const [executed, setExecuted] = useState(false);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  const deviceWidth = useCurrentWidth();

  const isAdmin = useSelector((state) => state?.authReducer?.user?.is_admin);

  const cartProducts = useSelector(
    (state) => state?.authReducer?.user?.cartproducts
  );

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  const [loading, setLoading] = useState(true);
  const [loginShow, setLoginShow] = useState(false);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1000) {
      setVisible(true);
    } else if (scrolled <= 1000) {
      setVisible(false);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!user) {
      persistor.purge();
      // console.log("PURGED");
    }
  }, [user]);

 
  const handleHistory = async (url) => {
   const urlList = url.split("/");
    if (!urlList.includes("search")) {
      const data = {
        user: user.id,
        url_visited: url
      };
    try {
      const response = await axios.post("/user/save_history/", data, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      // console.log("__Response", response);
    } catch (error) {
      // console.error("__Error:", error);
    }
  }
};

  useEffect(() => {
    console.log("location",window.location.href)
    handleHistory(window.location.href,user)
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        user.name == "" ||
        user.name == null ||
        user.email == "" ||
        user.email == null
      ) {
        setLoginShow(true);
      }
    }
  }, [isAuthenticated]);

  // axios.defaults.baseURL = "http://192.168.0.120:82/v1"; // Tejas - office
  // axios.defaults.baseURL = "http://192.168.0.124:3000/v1"; // HP - office
  // axios.defaults.baseURL = "http://192.168.221.64:3000/v1"; // Hanuman - office
  // axios.defaults.baseURL = "http://192.168.1.8:3000/v1"; // Home
  // axios.defaults.baseURL = "http://api.znanyetest.teson.co.in/v1"; // Tejas
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (user && token) {
      if (!executed) {
        dispatch(actions.getMyProfile(cartProducts));
        setExecuted(true);
      }
    }
  }, [user, token]);

  window.addEventListener("scroll", toggleVisible);

  if (loading) {
    return (
      <Container
        fluid
        className="d-flex justify-content-center align-items-center position-fixed w-100 h-100 home-background"
      >
        <Spinner animation="border" style={{ color: "#555" }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Router>
      <ScrollToTop />
      <Container fluid className="px-0 poppins">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* <Offline
          polling={{
            enabled: true,
            url: "https://api.znanye.com/",
          }}
        >
          <Container fluid className="home-background-categories h-100">
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <Image src={offline} alt="Offline" style={{ width: 200 }} />
              <h4 className="my-3 text-white text-center poppins">
                You are offline!
                <br />
                Please check your internet connection!
              </h4>
            </div>
          </Container>
        </Offline> */}

        <Navbar
          loginShow={loginShow}
          setLoginShow={setLoginShow}
        // categoryDropdown={categoryDropdown}
        // setCategoryDropdown={setCategoryDropdown}
        // categoriesRef={categoriesRef}
        />
        {/* <Online
          polling={{
            enabled: true,
            url: "https://api.znanye.com/",
          }}
        > */}
        {/* <IconButton */}
        {/* text={false} */}
        {/* dark={true} */}
        {/* color={"#fff"} */}
        {/* size="large" */}
        {/* className={`position-fixed ${visible ? "d-flex" : "d-none"} `} */}
        {/* style={{ */}
        {/* bottom: deviceWidth > 768 ? 100 : 50, */}
        {/* right: 50, */}
        {/* zIndex: 200, */}
        {/* }} */}
        {/* onClick={() => { */}
        {/* window.scrollTo({ */}
        {/* top: 0, */}
        {/* behavior: "smooth", */}
        {/* you can also use 'auto' behaviour
                   in place of 'smooth' */}
        {/* }); */}
        {/* }} */}
        {/* > */}
        {/* <FaChevronUp /> */}
        {/* </IconButton> */}

        <BootstrapButton
          variant="dark"
          size="lg"
          className={`position-fixed ${visible ? "d-flex" : "d-none"}`}
          style={{
            bottom: deviceWidth > 768 ? 100 : 65,
            right: deviceWidth > 768 ? 50 : 10,
            zIndex: 200,
            color: "#fff",
            backgroundColor: "#1B1C1D",
            borderRadius: "5px",
            border: "1px solid #3D3D3D",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            width: "45px",
            height: "42px",
          }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <FaChevronUp style={{ color: "#fff" }} />
        </BootstrapButton>


        <Routes>
          {/* <Route path="" element={<Home categoriesRef={categoriesRef} />} /> */}
          <Route path="try3d" element={<Try3D />} />
          <Route path="login" element={<MobileLogin />} />
          <Route path="sitemap" element={<SiteMap />} />
          <Route path="verify/:email_hash" element={<VerifyAccount />} />
          <Route
            path="reset_password/:email_hash"
            element={<ResetPassword />}
          />
          <Route path="privacy_policy" element={<PrivacyPolicy />} />
          {/* <Route path="terms_and_conditions" element={<Terms />} /> */}
          <Route path="refund_policy" element={<RefundAndCancellation />} />
          {/* <Route path="privacy_policy_user" element={<UserPrivacyPolicy />} />
          <Route path="privacy_policy_seller" element={<SellerPrivacyPolicy />} /> */}
          <Route path="cookies_policy" element={<CookiesPolicy />} />
          <Route path="terms_of_use_user" element={<UserTermsOfUse />} />
          <Route path="terms_of_use_seller" element={<SellerTermsOfUse />} />
          <Route path="about-us-policy" element={<AboutUsPolicy />} />
          <Route path="business" element={<Business />}>
            <Route path="" element={<BusinessHome />} />
          </Route>
          <Route path="casestudy/znanyeland" element={<CaseStudy />} />
          <Route
            path="casestudy/ar-solution-for-tyre-company"
            element={<CaseStudy2 />}
          />
          <Route path="casestudy/dark-den" element={<CaseStudy3 />} />
          <Route path="" element={<Home />} />
          <Route path="contact-us" element={<ContactUs />} />
          {/* <Route path="product" element={<SearchResults />}>
            <Route
              path=":productId"
              element={<Product setLoginShow={setLoginShow} />}
            />
            <Route path=":productId/viewer" element={<Viewer />} />
            <Route path=":productId/imageviewer" element={<ImageViewer />} />
          </Route> */}
          <Route path="categories" element={<RedirectUrl />}>
            <Route path="product" element={<RedirectUrl />} />
          </Route>
          <Route path="product" element={<RedirectUrl />}>
            <Route path=":productId" element={<RedirectUrl />} />
          </Route>
          <Route path="search" element={<SearchResults />}>
            <Route path=":productId" element={<RedirectUrl />} />
            <Route path=":productId/viewer" element={<RedirectUrl />} />
            {/* <Route path=":productId/imageviewer" element={<ImageViewer />} /> */}
          </Route>
          <Route path="cart" element={<Cart />}>
            <Route
              path=""
              element={<OrderDetails setLoginShow={setLoginShow} />}
            />
            {/* <Route path="payment" element={<PaymentDetails />} /> */}
            <Route path="thankyou" element={<OrderSuccessful />} />
          </Route>
          <Route path="3dmodels" element={<Categories />}>
            <Route path=":categorySlug" element={<CategoryProducts />}>
              <Route
                path=":productId"
                element={<Product setLoginShow={setLoginShow} />}
              />
              <Route path=":productId/viewer" element={<Viewer />} />
              <Route path=":productId/imageviewer" element={<ImageViewer />} />

            </Route>
          </Route>
          {/* <Route path="search" element={<SearchResults />} /> */}
          {/* <Route path="" element={<AdminProduct />} /> */}

          {isAuthenticated && isAdmin && (
            <Route path="admin" element={<Admin />}>
              {/* <Route path="" element={<AdminProduct mode="admin" />} /> */}
              <Route path="" element={<ProductFiles mode="admin" />} />
              <Route path="product" element={<AdminProduct />}>
                <Route
                  path=":productId"
                  element={<AdminProductMain type="update" mode="admin" />}
                />
                {/* <Route
                  path=":productId"
                  element={<AdminProductMain type="upload" mode="admin" />}
                /> */}
              </Route>
              <Route path="products" element={<AdminProducts />} />
              <Route path="categories" element={<AdminCategories />}>
                <Route path="" element={<AdminCategoriesMain />} />
                <Route path=":categoryId" element={<AdminCategoryProducts />} />
              </Route>
              <Route path="approvals" element={<Approvals />} />
              <Route path="coupons" element={<AdminCoupons />} />
              <Route path="payouts" element={<AdminPayouts />} />
              <Route path="blogs" element={<AdminBlogsIndex />}>
                <Route index element={<AdminBlogs />} />
                <Route path="new" element={<AdminNewBlog />} />
                <Route path=":id" element={<AdminBlog />} />
                <Route path=":id/edit/" element={<AdminBlogUpdate />} />
              </Route>
            </Route>
          )}

          <Route path="admin" element={<AdminLogin />} />
          <Route path="profile" element={<Profile />} />
          <Route path="favourites" element={<Favourites />} />
          <Route path="orders" element={<Orders />} />

          {isAuthenticated && (
            <>
              <Route path="sell-3d" element={<Seller />}>
                <Route
                  path="uploadproducts"
                  element={<ProductFiles />}
                />
                {/* <Route path=":productId" element */}

                <Route path="uploadproducts/producteditor" element={<ProductEditor />} />
                <Route path="uploadproducts/product" element={<AdminProduct mode="seller" />} />

                <Route path="product" element={<AdminProduct />}>
                  <Route
                    path=":productId"
                    element={<AdminProductMain type="update" mode="seller" />}
                  />
                  {/* <Route
                    path=":productId"
                    element={<AdminProductMain type="upload" mode="seller" />}
                  /> */}
                </Route>
                <Route path="drafts" element={<MyProducts type="drafts" />} />
                <Route
                  path="myproducts"
                  element={<MyProducts type="approved_products" />}
                />
                <Route
                  path="pendingapprovals"
                  element={<MyProducts type="pending_approvals" />}
                />
                <Route path="categories" element={<RedirectUrl />}>
                  <Route path=":categoryId" element={<RedirectUrl />} />
                </Route>
                <Route path="coupons" element={<RedirectUrl />} />
              </Route>
              <Route path="notifications" element={<MobileNotification />} />
            </>
          )}

          <Route path="vrtraining" element={<VRTraining />}>
            <Route path="privacy_policy" element={<VRTrainingPrivacyPolicy />} />
          </Route>
          <Route path="vrsolutions" element={<VRSolutions />}>
            <Route path="privacy_policy" element={<VRSolutionsPrivacyPolicy />} />
          </Route>
          <Route path="sell-3d-login" element={<SellerLogin />} />
          <Route path="sell-3d" element={<SellerLanding />} />
          <Route path="verify_seller/:email_hash" element={<VerifyAccount />} />
          <Route
            path="reset_seller_password/:email_hash"
            element={<ResetPassword />}
          />
          <Route path="sellercreated" element={<SellerCreated />} />
          <Route path="blogs" element={<BlogsIndex />}>
            <Route index element={<Blogs />} />
            <Route path=":id" element={<Blog />} />
          </Route>
          {/* <Route path="blogs" element={<Blogs />}>
            <Route path=":typeId" element={<BlogList />}>
              <Route path=":id" element={<Blog />} />
            </Route>
          </Route> */}
          <Route path="we-are-in-news" element={<WeAreInNewsPage />} />
          <Route path="license" element={<License />}></Route>
          <Route path="*" element={<Error />}></Route>
        </Routes>
        <Footer />
        {/* </Online> */}
      </Container>
    </Router>
  );
};

export default Routers;

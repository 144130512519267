import React, { useState } from "react";
import { Container, Row, Col, Image, Form } from "react-bootstrap";
import { Button, Card, CardAction } from "ui-neumorphism";
import { CartItem } from "components";

import "core-ui/pages/MyAccount.scss";

import { heartanimation, swiftdezire, mitsubishi } from "assets";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "actions/AuthActions";
import { Breakpoint } from "react-socks";
import { Button as BootstrapButton } from "react-bootstrap";

const Favourites = () => {
  const favouriteItems = useSelector(
    (state) => state.authReducer.user.favourites
  );

  // uncomment to test the empty purchase list page
  // const favouriteItems = [];

  if (favouriteItems.length === 0) {
    return (
      <React.Fragment>
        <Breakpoint large up>
          <Container
            className="d-flex w-100 justify-content-center align-items-center h-100 mt-5 pt-5 ps-5 home-background-categories"
            fluid
            style={styles.emptyFavouritesListContainer}
          >
            <div>
              <div className="d-flex justify-content-center">
                <Image src={heartanimation} alt="Heart" style={{ width: 250 }} />
              </div>
              <div className="d-flex justify-content-center mt-5 mb-5">
                <h4 className="text-primary m-1">OOPS ! </h4>
                <h4 className="text-white m-1">
                  You do not have any favourite products added
                </h4>
              </div>
              <div className="d-flex justify-content-center mt-5 mb-5">
                <Link to="/3dmodels" style={{ textDecoration: "none" }}>
                  <BootstrapButton
                    className="mt-5 explore-button-no-neo btn btn-primary"
                    dark = {true}
                    size="lg"
                  >
                    <span className="poppins">ADD FAVOURITES</span>
                  </BootstrapButton>
                </Link>
              </div>
            </div>
          </Container>
        </Breakpoint>
        <Breakpoint medium down>
          <Container
            className="h-100 pt-5 home-background-categories"
            fluid
            style={styles.emptyFavouritesListContainer}
          >
            <div>
              <div className="d-flex justify-content-center">
                <Image
                  src={heartanimation}
                  alt="Heart"
                  style={{ width: 250, marginTop: 100 }}
                />
              </div>
              <div className="text-center mb-5 h4">
                <div className="text-primary m-1" style={{ fontSize: 35 }}>
                  OOPS !{" "}
                </div>
                <div className="text-white m-1" style={{ fontSize: 30 }}>
                  You do not have any favourite products added
                </div>
              </div>
              <div>
                <Link to="/3dmodels" style={{ textDecoration: "none" }}>
                  <BootstrapButton
                    className="explore-button-no-neo w-100 btn btn-primary position-fixed"
                    dark={true}
                    size="large"
                    style={{ left: 0, bottom: 0 }}
                  >
                   ADD FAVOURITES
                  </BootstrapButton>
                </Link>
              </div>
            </div>
          </Container>
        </Breakpoint>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Container
        className="d-flex flex-row px-lg-5 home-background-categories"
        fluid
        style={{ paddingTop: 100 }}
      >
        <Row>
          <h3 className="text-white pb-lg-2 mx-lg-4 ps-2 ps-lg-3 pt-lg-5 subtitle">Favourites</h3>
          <Col className="overflow-auto py-1 mt-4 mb-5" style={styles.cartCard}>
            <CartItem type="favourites" items={favouriteItems} />
          </Col>
        </Row >
      </Container >
    </React.Fragment >
  );
};

const styles = {
  emptyFavouritesListContainer: {
    minHeight: "100vh",
  },
  progressBarContainer: {
    minHeight: 100,
  },
  cartCard: {
    height: 700,
  },
  cardAction: {
    backgroundColor: "#2E2E2E",
  },
  underline: {
    height: 1,
    backgroundColor: "white",
  },
  youSavedColor: {
    color: "#58D31B",
  },
};
export default Favourites;

import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Container,
  Breadcrumb,
  Row,
  Col,
  Image,
  Badge,
  Form,
  Spinner,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Button as RButton,
  Modal,
  Card,
  Overlay,
  Popover,
} from "react-bootstrap";
import md5 from "md5";
import {
  // Card,
  // CardContent,
  Button,
  IconButton,
} from "ui-neumorphism";
// import { styled } from '@mui/material/styles';
// import FormGroup from '@mui/material/FormGroup';
// import { FormControlLabel } from '@mui/material';
// import Switch, { SwitchProps } from '@mui/material/Switch';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
import {
  biggreentick,
  biggreytick,
  checkedtrue,
  view3d,
  view3d_znanye,
} from "assets";

import { ZSlider, ZDropdown, ToggleButton } from "components";
import Switch from "react-switch";
import "core-ui/pages/Product.scss";
import { emptyimage } from "assets";
import { Link, useNavigate } from "react-router-dom";
import { downarrows } from "assets";
import { MdDelete } from "react-icons/md";
import "core-ui/pages/admin/Product.scss";
import { BsCircleFill } from "react-icons/bs";
import { FiInfo } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import Dropzone, { useDropzone } from "react-dropzone";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Progress } from "antd";
import {
  upload,
  seehow,
  seehow_popup,
  freelicense,
  paidlicense,
  upload_icon,
} from "assets/admin";
import * as actions from "actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Outlet, useParams } from "react-router-dom";
import Editor from "./Editor";
import { TagForm } from "components";
import { CustomSlider } from "components";
import getSymbolFromCurrency from "currency-symbol-map";
import { toast } from "react-toastify";
import { FcCheckmark } from "react-icons/fc";
import moment from "moment";
import { CKEditor } from "ckeditor4-react";
import axios from "axios";
import { Breakpoint } from "react-socks";
import { Try3DFile } from "pages";
import ProductEditor from "pages/ProductEditor/ProductEditor";
import { FiUpload } from "react-icons/fi";
import { AiOutlinePlayCircle, AiOutlineInfoCircle } from "react-icons/ai";
import ProductMain from "./ProductMain";
import { profileUpdate } from "actions/AuthActions";
// import { product } from "assets_old";
import ProgressBar from "react-bootstrap/ProgressBar";
import { update } from "react-spring";

const ProductFiles = ({ type, mode }) => {
  const is_admin = useSelector((state) => state.authReducer.user.is_admin);
  const form = useRef(null);
  const [productFiles, setProductFiles] = useState([]);
  const [draft, setDraft] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [isDrafting, setIsDrafting] = useState(false);
  const { productId } = useParams();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [productImages, setProductImages] = useState([]);
  const extensionRef = useRef(null);
  const [seeInfoTooltip, setSeeInfoTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const [toVerify, setToVerify] = useState(false);
  const [sendForVerification, setSendForVerification] = useState(false);
  const [seeHowModal, setSeeHowModal] = useState(false);
  const [seeHelpModal, setSeeHelpModal] = useState(false);

  const [publish, setPublish] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [editorExtensionsPresent, setEditorExtensionsPresent] = useState(false);
  const [editorEnabled, setEditorEnabled] = useState(false);
  const [viewerSwitch, setViewerSwitch] = useState(true);
  const [editorValues, setEditorValues] = useState([]);
  const inputRef = useRef(null);
  const [productNewFiles, setProductNewFiles] = useState([]);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [progressBarName, setProgressBarName] = useState("");
  const [filesId, setProductNewFilesId] = useState();
  const [filesIdPresent, setFilesIDPResent] = useState(false);
  const [changingBorder, setChangingBroder] = useState(false);
  const [productName, setProductName] = useState([]);
  const [productSlug, setProductSlug] = useState();
  const [isProceeding, setIsProceeding] = useState(false);
  const [filesIdSent, setFilesIdSent] = useState([]);
  const [isFileSending, setIsFileSending] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [skip, setSkip] = useState(false);
  const [showProceed, setShowProceed] = useState();

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    // ////consolelog("formdata",formData);

    let productUploadType = "";
    if (draft) {
      setIsDrafting(true);
      productUploadType = "draft";
      formData.append("is_in_draft", true);
      formData.append("is_verified", false);
    } else if (toVerify) {
      productUploadType = "toVerify";
      formData.append("is_in_draft", false);
      formData.append("is_verified", false);
      setSendForVerification(true);
    } else if (publish) {
      setIsPublishing(true);
      productUploadType = "publish";
      formData.append("is_in_draft", false);
      formData.append("is_verified", true);
    }

    productFiles.map((item) => {
      if (item.id) {
        return formData.append(
          "productfile_set",
          JSON.stringify({ id: item.id })
        );
      }
      return formData.append("productfile_set", item);
    });

    if (currentProduct) {
      const response = await actions.updateProduct(productId, formData);
      if (response) {
        dispatch(actions.productUpdate(response));
        if (draft) {
          toast.success(
            "Your product is saved in draft. You can see your product under My Products' IN DRAFT."
          );
        } else if (toVerify) {
          toast.success(
            "We are delighted that you decided to partner with us in selling your VR Asset. Your asset is under review. If we need any clarification, we will come back; else, your asset will automatically Go Live on our store."
          );
        } else if (publish) {
          toast.success("Product updated successfully!");
        }
        switch (productUploadType) {
          case "draft":
            // navigate("/sell-3d/drafts");
            handleNavigationClick("/sell-3d/drafts");

            break;
          case "toVerify":
            // navigate("/sell-3d/pendingapprovals");
            handleNavigationClick("/sell-3d/pendingapprovals");

            break;
          case "publish":
            // navigate("/admin/products");
            handleNavigationClick("/admin/products");

            break;
          default:
          ////consolelog(productUploadType);
        }
      }
    } else {
      const response = await actions.createProduct(formData);

      if (response) {
        dispatch(actions.productAdd(response));
        if (draft) {
          toast.success(
            "Your product is saved in draft. You can see your product under My Products' IN DRAFT."
          );
        } else if (toVerify) {
          toast.success(
            "We are delighted that you decided to partner with us in selling your VR Asset. Your asset is under review. If we need any clarification, we will come back; else, your asset will automatically Go Live on our store."
          );
        } else if (publish) {
          toast.success("Product created successfully!");
        }
        switch (productUploadType) {
          case "draft":
            handleNavigationClick("/sell-3d/drafts");
            // navigate("/sell-3d/drafts");
            break;
          case "toVerify":
            handleNavigationClick("/sell-3d/pendingapprovals");

            // navigate("/sell-3d/pendingapprovals");
            break;
          case "publish":
            handleNavigationClick("/admin/products");

            // navigate("/admin/products");
            break;
          default:
          ////consolelog(productUploadType);
        }
      }
    }
  };

  const checkEditorExtensions = (submittedFiles) => {
    let flag = false;
    for (let i = 0; i < submittedFiles.length; i++) {
      // ////consolelog(submittedFiles[i].name);
      let extension = submittedFiles[i].name.split(".").pop().toLowerCase();
      ////consolelog(extension);
      if (["gltf", "glb", "fbx", "obj"].includes(extension)) {
        flag = true;
        break;
      }
    }
    return flag;
  };

  const onDrop = (acceptedFiles) => {
    ////consolelog(acceptedFiles);
    ////consolelog(productImages);
    // ////consolelog(productFiles.concat(acceptedFiles));
    setProductFiles(productFiles.concat(acceptedFiles));
    // setProductNewFiles(productNewFiles.concat(acceptedFiles));
    // ////consolelog(acceptedFiles);
    // Do something with the files
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    setEditorExtensionsPresent(checkEditorExtensions(productFiles));
    // ////consolelog("&&&&&&&&&", productFiles);
    setProductName(productFiles.length > 0 ? productFiles[0].name : "");
  }, [productFiles]);

  //

  useEffect(() => {
    // console.log("productFiles", productFiles);
    if (productFiles.length > 0) {
      const updatedFiles = productFiles.map((item) => ({
        ...item,
        file: item,
        progress: 0,
        md5: "secretKey",
        status: 0,
      }));

      setProductNewFiles(updatedFiles);
    }
  }, [productFiles]);

  useEffect(() => {
    if (productNewFiles.length > 0) {
      const filteredFiles = productNewFiles.filter(
        (file) => file.progress != 0
      );

      if (filteredFiles.length > 0) {
        const largestFile = filteredFiles.reduce((prevFile, currFile) => {
          return currFile.file.size > prevFile.file.size ? currFile : prevFile;
        });
        // console.log('##',largestFile)
        setProgressBarName(largestFile.file.name);
        if (largestFile.progress > 100) {
          setProgressBarValue(100);
        } else {
          setProgressBarValue(largestFile.progress);
        }
      }
      //   let totalProgress = 0;
      //   for (let i = 0; i < filteredFiles.length; i++) {
      //     if (filteredFiles[i].status == 0) {
      //       totalProgress += filteredFiles[i].progress;
      //     }
      //   }
      //   const average =
      //     filteredFiles.length > 0 ? totalProgress / filteredFiles.length : 0;

      //   console.log(average);
      //   if (isNaN(average)) {
      //     setProgressBarValue(0);
      //   } else if (average !== 0) {
      //     setProgressBarValue(average);
      //   }
      // }
    }
  }, [productNewFiles]);

  useEffect(() => {
    if (progressBarValue > 100 || progressBarValue === 100) {
      const timer = setTimeout(() => {
        setProgressBarValue(0);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [progressBarValue]);

  const handleAddMoreFileUpload = () => {
    inputRef.current?.click();
  };

  const handleProceedButton = () => {
    if (!skip) {
      if (productFiles.length > 0) {
        for (let i = 0; i < productFiles.length; i++) {
          let extension = productFiles[i].name.split(".").pop().toLowerCase();
          if (["gltf", "glb", "fbx", "obj"].includes(extension)) {
            // setSkip(true);
            setIsProceeding(true);
            createProduct(productFiles);
            break;
          } else {
            setShowProceed(true);
          }
        }
      }
    } else {
      setIsProceeding(true);
      createProduct(productFiles);
    }
  };
  const [completed, setCompleted] = useState(false);
  const handleProductPage = (id) => {
    setProductNewFilesId(id);
    setFilesIDPResent(true);
  };

  useEffect(() => {
    if (
      filesIdSent.length === productNewFiles.length &&
      productNewFiles.length != 0
    ) {
      setIsFileSending(false);
      handleProductPage(productSlug);
    }
  }, [filesIdSent]);

  useEffect(() => {
    if (filesIdPresent && productSlug) {
      if (is_admin) {
        window.location.href = `${window.location.origin}/admin/product/${productSlug}`;
      } else {
        window.location.href = `${window.location.origin}/sell-3d/product/${productSlug}`;
      }
    }
  }, [filesIdPresent, filesId]);

  const handleSwitchChange = () => {
    setViewerSwitch(!viewerSwitch);
  };
  // const send_to_server = async (id, title) => {
  //   const formData = new FormData();
  //   formData.append("product", title);

  //   const response = await axios.post(`/product/${id}/send_to_server/`);
  //   if (response.status == 200) {
  //     ////consolelog(response.data)
  //   }
  // };

  const sendHash = async (id, hash, i) => {
    try {
      const response = await axios.post(`product/${id}/verify_hash/`, {
        client_md5_hash: hash,
      });
      if (response.status == 200) {
        setFilesIdSent((prevState) => [...prevState, id]);
        setProductNewFiles([
          ...productNewFiles.map((item, index) => {
            if (index == i) {
              const temp = item;
              temp.status = 1;
              return temp;
            }
            return item;
          }),
        ]);
      } else {
        setProductNewFiles([
          ...productNewFiles.map((item, index) => {
            if (index == i) {
              const temp = item;
              temp.status = 2;
              return temp;
            }
            return item;
          }),
        ]);
      }
    } catch (e) {
      ////consolelog(e);
      setProductNewFiles([
        ...productNewFiles.map((item, index) => {
          if (index == i) {
            const temp = item;
            temp.status = 2;
            return temp;
          }
          return item;
        }),
      ]);
    }
  };

  const uploadFile = (x, id, i) => {
    x = x.file;
    // console.log(x, id, i);
    const total = x.size;
    let loaded = 0;
    let step = 1024 * 1024;
    let start = 0;

    let reader = new FileReader();
    let pValue = 0;

    reader.onprogress = function (e) {
      ////consolelog("loaded", loaded);
      pValue = (loaded / total) * 100;
    };

    reader.onload = function (e) {
      let xhr = new XMLHttpRequest();
      let upload = xhr.upload;
      let result = e.target.result;

      upload.addEventListener(
        "load",
        function (e) {
          loaded += step;
          pValue = (loaded / total) * 100;
          // console.log(loaded, total, pValue);

          if (loaded < total) {
            blob = x.slice(loaded, loaded + step);
            reader.readAsBinaryString(blob);
            setProductNewFiles([
              ...productNewFiles.map((item, index) => {
                if (index == i) {
                  const temp = item;
                  temp.progress = pValue;
                  temp.md5 = md5(result);
                  return temp;
                }
                return item;
              }),
            ]);
          } else {
            setCompleted(true);
            loaded = total;
            setProductNewFiles([
              ...productNewFiles.map((item, index) => {
                if (index == i) {
                  const temp = item;
                  temp.progress = pValue;
                  temp.md5 = md5(result);
                  sendHash(id, temp.md5, i);
                  temp.status = 1;
                  return temp;
                }
                return item;
              }),
            ]);
          }
        },
        false
      );

      const formData = new FormData();
      xhr.open(
        "PUT",
        `${process.env.REACT_APP_BASE_URL}product/sendchunks/${id}/`
      );
      xhr.overrideMimeType("application/octet-stream");

      const completedString = JSON.stringify(completed);
      xhr.setRequestHeader("Content-Type", "application/json");

      // ////consolelog(e.target.result);
      if (!xhr.sendAsBinary) {
        xhr.sendAsBinary = function (datastr) {
          function byteValue(x) {
            return x.charCodeAt(0) & 0xff;
          }
          var ords = Array.prototype.map.call(datastr, byteValue);
          var ui8a = new Uint8Array(ords);
          this.send(ui8a.buffer);
        };
      }

      xhr.sendAsBinary(e.target.result);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          // callback(xhr.response);
          ////consolelog(xhr.response);
        }
      };
    };
    let blob = x.slice(start, step);
    reader.readAsBinaryString(blob);
  };

  const startUpload = async (productNewFiles, slug) => {
    // console.log(productNewFiles);

    for (let i = 0; i < productNewFiles.length; i++) {
      if (productNewFiles[i].status == 0 && productNewFiles[i].file.size) {
        const formData = new FormData();
        formData.append("file", new Blob([]), productNewFiles[i].file.name);
        const response = await axios.post(
          `/product/${slug}/upload_document_create/`,
          formData
        );
        if (response.status == 200) {
          const data = response.data["product_files"];
          setProductSlug(slug);
          uploadFile(productNewFiles[i], data.id, i);
        }
      }
    }
    //consolelog(productNewFiles[productNewFiles.length-1]?.status)
    // if(productNewFiles.length === updatedFiles.length){
    //   handleProductPage(slug);
    // }
  };

  const createProduct = async (productFiles) => {
    if (productFiles?.length > 0) {
      setIsFileSending(true);
      const formData = new FormData();
      formData.append("title", productFiles[0].name.split(".")[0]);
      formData.append("is_in_draft", true);
      const response = await axios.post(`/product/create_product/`, formData);
      if (response.status == 200) {
        startUpload(productNewFiles, response?.data?.slug);
      }
    }
  };

  // const handleFileChange = (acceptedFiles) => {
  // console.log("##a",acceptedFiles)
  // console.log("##", productFiles);
  // if (productFiles.length > 0){

  // const updatedFiles = [
  //   ...productFiles,
  //   ...Array.from(acceptedFiles).map((item) => {
  //     return { file: item, progress: 0, md5: "secretKey", status: 0 };
  //   }),
  // ];
  // console.log(updatedFiles)

  // setProductNewFiles(updatedFiles);
  // setProductFiles(updatedFiles);
  // setIsFileSending(true)

  // setProductNewFiles(updatedFiles);
  // };

  // const handleFileUpload = async (productFiles) => {
  //   if (productFiles?.length > 0) {
  //     for (let i = 0; i < productFiles.length; i++) {
  //       const formData = new FormData(form.current);
  //       formData.append("productFile", productFiles[i]);
  //       formData.append("title", productFiles[0].name.split(".")[0]);
  //       // formData.append("product", product);

  //       const response = await axios.post(
  //         `/product/upload_document_single/`,
  //         formData
  //       );
  //       if (response.status == 200) {
  //         ////consolelog("fileuploaded", response.data);
  //         const id = response.data.slug;
  //         setProductSlug(response.data.slug);
  //         handleProductPage(id);
  //         setIsProceeding(false);
  //       } else {
  //         ////consolelog("erroorsss");
  //       }
  //     }
  //   }
  // };

  return (
    <React.Fragment>
      <Breakpoint large up>
        <Container
          className="d-flex flex-column align-items-center mt-5 pt-5 home-background-categories"
          // fluid
          style={styles.parentContainer}
        >
          <Form ref={form} onSubmit={(e) => handleCreate(e)}>
            <Container className="p-5 text-white" fluid>
              <div className="poppins-500 subtitle px-1">Upload Files</div>
              <p className="body-text3">
                These are the files user can download when he/she buys your
                product.{" "}
                <b
                  className="cursor-pointer text-primary"
                  onClick={() => {
                    setSeeHowModal(true);
                  }}
                >
                  {" "}
                  See How?
                </b>
              </p>
              <Row>
                <Col sm={8} className="position-relative">
                  {/* {editorExtensionsPresent && viewerSwitch && (
                    <RButton
                      variant="dark"
                      className="explore-border-button-round explore-button-large-no-neo-round position-absolute"
                      style={{
                        backgroundColor: "#27292A",
                        right: "40px",
                        top: "30px",
                        zIndex: 20,
                      }}
                      onClick={() => {
                        setEditorEnabled(true);
                      }}
                    >
                      <span className="poppins">EDITOR</span>
                    </RButton>
                  )} */}
                  {/* {editorExtensionsPresent && viewerSwitch && (
                    <>
                      <Card
                        className="m-2 border-R20 overflow-hidden admin-filedrop-card"
                        dark={true}
                        style={{
                          backgroundColor: "#141415",
                          border: "1px solid #3D3D3D",
                          // width: "100%",
                          // height: "60vh",
                          height: "436px",
                          // width: ",
                        }}
                      >
                        <Try3DFile
                        
                          files={productFiles}
                          data={setEditorValues}
                        />
                      </Card>
                      
                    </>
                  )} */}
                  {/* {viewerSwitch && ( */}

                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      setProductFiles(productFiles.concat(...acceptedFiles));
                      toast.success("Your file is added to product bucket.");
                      // Do something with the files
                    }}
                    noKeyboard={true}
                    noDragEventsBubbling={true}
                    noClick={true}
                  >
                    {({ getRootProps, getInputProps, isDragActive, open }) => (
                      <Card
                        className="m-2 border-R20 d-flex justify-content-center align-items-center overflow-hidden h-100 admin-filedrop-card"
                        dark="true"
                        style={{
                          backgroundColor: "#141415",
                          border: "1px solid #3D3D3D",
                          width: "738px",
                          height: "460px",
                          borderRadius: "16px",
                          overflow: "hidden",
                        }}
                      >
                        {isFileSending && (
                          <>
                            <div>
                              <ProgressBar
                                now={progressBarValue}
                                style={{
                                  // position: "absolute",
                                  width: "400px",
                                }}
                              />
                              <p
                                style={{
                                  marginTop: "10px",
                                  color: "#FFFFFF",
                                  left: "162px",
                                  position: "absolute",
                                }}
                              >
                                Thank you for your patience.
                              </p>
                              <div
                                style={{
                                  position: "absolute",
                                  top: "185px",
                                  left: "167px",
                                  color: "#FFFFFF",
                                }}
                              >
                                {progressBarName.slice(0, 15)}...
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  top: "185px",
                                  right: "168px",
                                  color: "#FFFFFF",
                                }}
                              >
                                {Math.floor(progressBarValue)}/100
                              </div>
                            </div>
                          </>
                        )}

                        {!editorExtensionsPresent &&
                          viewerSwitch &&
                          !isFileSending && (
                            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                              <div
                                // {...getRootProps({ onClick: (e) => e.stopPropagation() })}
                                {...getRootProps()}
                                className="text-center d-flex justify-content-center align-items-center h-100 w-100"
                                onClick={open}
                              >
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                  // <p>Drop the files here ...</p>
                                  <div className="d-flex justify-content-center align-items-center h-100 w-100">
                                    Drop the files here ...
                                  </div>
                                ) : (
                                  <div>
                                    <Image
                                      className="mb-4"
                                      src={upload}
                                      alt="Upload"
                                    />

                                    <p className="m-1">
                                      Drag and drop here
                                      <br />
                                      OR
                                    </p>
                                    <RButton
                                      className="p-3 mb-5 explore-border-button"
                                      style={{
                                        zIndex: 99,
                                        backgroundColor: "#27292a",
                                        border: 0,
                                        outline: 0,
                                      }}
                                    >
                                      <span className="m-4 poppins text-white">
                                        BROWSE
                                      </span>
                                    </RButton>
                                    {/* <p className="m-2">
                                    <i>
                                      NOTE - Upload the <b>GLTF</b> file along
                                      with other files ( like{" "}
                                      <b>FBX, OBJ & many more</b> )
                                    </i>
                                    <br />
                                    <i>to view your model in the 3D Viewer.</i>
                                    <br />
                                    You can also upload an archive like ZIP, RAR
                                    containing your textures, matertials and
                                    mesh.
                                  </p> */}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        {viewerSwitch &&
                          editorExtensionsPresent &&
                          !isFileSending && (
                            <Try3DFile
                              files={productFiles}
                              data={setEditorValues}
                            />
                          )}
                        {!viewerSwitch && !isFileSending && (
                          <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <div
                              className="text-center"
                              style={{ fontSize: 14 }}
                            >
                              Your 3D viewer is off. Turn it on to let<br></br>{" "}
                              the world see your 3D creation!
                            </div>
                            <br />
                            <div className="mt-2">
                              <input
                                ref={inputRef}
                                type="file"
                                name="filename"
                                hidden
                                multiple
                                onChange={() => {
                                  ////consolelog(inputRef.current.files);
                                  setProductFiles(
                                    productFiles.concat(
                                      ...inputRef.current.files
                                    )
                                  );
                                  ////consolelog(
                                  //   "&&&&&",
                                  //   inputRef.current.files.filelist
                                  // );
                                  // handleFileChange(inputRef.current.files);
                                  // handleFileUpload(inputRef.current.files);
                                  toast.success(
                                    "Your file is added to the product bucket."
                                  );
                                }}
                              />
                              <RButton
                                variant="dark"
                                className=" w-100 explore-border-button d-flex explore-button-large-no-neo"
                                size="lg"
                                // style={{height:36,width:200}}
                                onClick={handleAddMoreFileUpload}
                              >
                                <span
                                  className="poppins-500"
                                  style={{ fontSize: 14 }}
                                >
                                  UPLOAD MORE FILES
                                </span>
                              </RButton>
                            </div>
                          </div>
                        )}
                      </Card>
                    )}
                  </Dropzone>
                  {/* )} */}
                </Col>

                <Col sm={4}>
                  <Card
                    className="m-2 p-0 py-4 h-100 d-flex flex-column border-R20"
                    style={{
                      backgroundColor: "#141415",
                      border: "1px solid #3D3D3D",
                      width: 368,
                      height: 460,
                      borderRadius: 16,
                    }}
                  >
                    {productFiles.length == 0 && (
                      <>
                        <div className="d-flex border-0 text-white p-2">
                          <div className="h5 poppins-500 text-center mx-4">
                            Guidelines
                          </div>
                        </div>
                        <br></br>

                        <RButton
                          variant=" mx-4 dark col-4"
                          className="explore-border-button d-flex explore-button-large-no-neo"
                          onClick={() => setSeeHelpModal(true)}
                          style={{
                            backgroundColor: "#27292A",
                            alignItems: "center",
                            padding: 0,
                            height: 50,
                            justifyContent: "center",
                          }}
                        >
                          <span style={{ fontSize: 20 }}>HELP</span>
                          <p className="p-0 m-0 mx-1" style={{ top: "6px" }}>
                            <AiOutlinePlayCircle
                              className="position-relative"
                              size={24}
                            />
                          </p>
                        </RButton>
                        <hr></hr>
                        <div className="body-text2 px-4">
                          <p>
                            Upload the GLTF file along with other files ( like
                            FBX, OBJ & many more ) to view your model in the 3D
                            Viewer
                          </p>
                          <p>
                            You can also upload an archive like ZIP, RAR
                            containing your textures, materials and mesh.
                          </p>
                          <p>File size limit - upto 500MB</p>
                        </div>
                      </>
                    )}

                    {productFiles.length > 0 && (
                      <>
                        <div className="d-flex border-0 text-white p-2 align-items-center">
                          <div className="h4 poppins-500 text-center mx-3">
                            File Contains ({productFiles?.length})
                          </div>
                        </div>
                        <div
                          className="body-text1 px-2 overflow-scroll"
                          style={{ maxHeight: "350px" }}
                        >
                          {productFiles.length > 0 ? (
                            <>
                              {productFiles.map((item, index) => {
                                return (
                                  <div
                                    className="d-flex justify-content-between p-2 mx-2 rounded"
                                    style={styles.fileNames}
                                    key={index}
                                  >
                                    <Card
                                      className="w-100 p-2 me-2"
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        backgroundColor: "#0d0d0d",
                                        border: "1px solid #3D3D3D",
                                        borderRadius: "4px 4px 4px 4px",
                                      }}
                                    >
                                      {item.name
                                        ? item.name
                                        : item.file?.replace(/^.*[\\\/]/, "")}
                                    </Card>
                                    <Card
                                      className="p-2 d-flex justify-content-center align-items-center cursor-pointer"
                                      style={{
                                        backgroundColor: "#292929",
                                        border: "1px solid #3D3D3D",
                                      }}
                                      onClick={() => {
                                        setProductFiles(
                                          productFiles.filter(
                                            (item1, index1) => index1 != index
                                          )
                                        );
                                        setIsFileSending(false);
                                      }}
                                    >
                                      <MdDelete color="#fff" size="20" />
                                    </Card>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <div className="my-5 px-4 py-5">
                              Not Uploaded Yet!
                            </div>
                          )}
                        </div>

                        <div className="px-4">
                          <input
                            ref={inputRef}
                            type="file"
                            name="filename"
                            hidden
                            multiple
                            onChange={() => {
                              setProductFiles(
                                productFiles.concat(...inputRef.current.files)
                              );

                              toast.success(
                                "Your file is added to product bucket."
                              );
                            }}
                          />
                          <RButton
                            className="explore-button-no-neo w-100"
                            dark={true}
                            size="lg"
                            onClick={handleAddMoreFileUpload}
                          >
                            <span className="poppins">Add More</span>
                          </RButton>
                        </div>
                      </>
                    )}
                  </Card>
                </Col>
              </Row>
              <Col sm={8}>
                <Row>
                  <Col sm={4} className="mx-3">
                    <Row>
                      <Card
                        className="m-2 mt-4 w-100 p-0 d-flex flex-row align-items-center"
                        dark={true}
                        style={{
                          backgroundColor: "#3D3D3D",
                          border: "1px solid #3D3D3D",
                          height: "64px",
                        }}
                      >
                        <div className="m-0 mx-3 col-6 text-white d-flex align-items-center">
                          <p
                            className="text-white m-0"
                            style={{ fontSize: 20 }}
                          >
                            3D VIEWER{" "}
                            <span ref={tooltipRef} style={{ marginBottom: 5 }}>
                              <AiOutlineInfoCircle
                                size={16}
                                onClick={() =>
                                  setSeeInfoTooltip(!seeInfoTooltip)
                                }
                              />
                            </span>
                            <Overlay
                              show={seeInfoTooltip}
                              target={tooltipRef.current}
                              placement="bottom-start"
                              containerPadding={20}
                              onHide={() => setSeeInfoTooltip(false)}
                            >
                              <Tooltip
                                id="custom-tooltip"
                                className="custom-tooltip"
                              >
                                <div
                                  className=""
                                  style={{
                                    maxWidth: "424px",
                                    maxHeight: "80px",
                                  }}
                                >
                                  <p
                                    className=" poppins-400 p-0 "
                                    style={{
                                      width: "422px",
                                      height: "80px",
                                      fontSize: "12px",
                                      marginLeft: "-6px",
                                    }}
                                  >
                                    We are still improving our 3D viewer so
                                    sometimes it might happen that your model is
                                    not looking good in the 3D viewer. At that
                                    time,you can turn off the 3D viewer option.
                                  </p>
                                </div>
                              </Tooltip>
                            </Overlay>
                          </p>
                        </div>

                        <Switch
                          checkedIcon={false}
                          uncheckedIcon={false}
                          checked={viewerSwitch}
                          onColor="#2ED15C"
                          offColor="FF4136"
                          onHandleColor="#1f1f1f"
                          offHandleColor="#292929"
                          onChange={handleSwitchChange}
                          activeBoxShadow="none"
                          className="custom-switch col-3 text-end m-0"
                        />
                      </Card>
                    </Row>
                  </Col>
                  <Col sm={5}>
                    {productFiles.length === 0 && (
                      <RButton
                        className="mt-4 w-100 text-white btn btn-primary"
                        dark={true}
                        size="lg"
                        style={{
                          height: "60px",
                          backgroundColor: "grey",
                          border: "0px",
                        }}
                        onClick={() => {
                          toast.warning("No Files Uploaded");
                        }}
                      >
                        <span className="poppins"> PROCEED </span>
                      </RButton>
                    )}

                    {productFiles.length > 0 && (
                      // <OverlayTrigger
                      //   trigger="click"
                      //   placement="bottom"
                      //   show={showProceed}
                      //   overlay={popover}
                      //   rootClose={true}
                      //   // onHide={() => setShowProceed(false)}
                      // >
                      <RButton
                        className="mt-4 explore-button-no-neo btn btn-ptimary"
                        dark={true}
                        // placement="bottom"
                        disabled={isProceeding}
                        size="lg"
                        onMouseEnter={() => setChangingBroder(true)}
                        onMouseLeave={() => setChangingBroder(false)}
                        style={{
                          height: "64px",
                          position: "absolute",
                          width: "280px",
                          border: changingBorder ? "0px" : "0px",
                        }}
                        onClick={handleProceedButton}
                      >
                        {isProceeding ? (
                          <span>
                            <Spinner
                              className="me-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            CREATING ...
                          </span>
                        ) : (
                          <span className="poppins"> PROCEED </span>
                        )}
                      </RButton>
                    )}
                  </Col>
                  {showProceed && (
                    <div className="d-flex justify-content-end">
                      <Popover
                        placement="bottom"
                        className="position-relative"
                        style={{
                          backgroundColor: "#555",
                          border: "1px solid white",
                        }}
                      >
                        <Popover.Header
                          className="text-white body-text1"
                          style={{
                            backgroundColor: "#333",
                            fontSize: "14px",
                          }}
                        >
                          YOU CAN UPLOAD .gltf,.fbx,.obj files too
                        </Popover.Header>

                        <Popover.Body>
                          <div>
                            <RButton
                              dark={true}
                              size="lg"
                              onClick={() => {
                                setShowProceed(false);
                              }}
                              style={{
                                fontSize: "12px",
                                // height : "55px"
                              }}
                              type="button"
                              className=" w-100 explore-button-no-neo caption btn btn-primary"
                            >
                              CANCEL
                            </RButton>
                            <RButton
                              dark={true}
                              size="sm"
                              onClick={() => {
                                setShowProceed(false);
                                setSkip(true);
                              }}
                              style={{
                                fontSize: "12px",
                                // height : "60px"
                              }}
                              className=" w-100  my-2 explore-button-no-neo btn btn-primary"
                            >
                              SKIP FOR NOW
                            </RButton>
                          </div>
                        </Popover.Body>
                      </Popover>
                    </div>
                  )}
                </Row>
              </Col>
            </Container>
          </Form>
        </Container>
      </Breakpoint>

      <Modal
        style={{ borderRadius: "20px" }}
        show={seeHelpModal}
        onHide={() => setSeeHelpModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={"static"}
        // backdropClassName="modal-backdrop login-modal-backdrop"
        contentClassName="border-R20 user-select-none"
        onExited={() => {
          setSeeHelpModal(false);
        }}
      >
        <IconButton
          size="large"
          className="d-flex justify-content-center removeButtonRight position-absolute text-white"
          dark={true}
          style={{ zIndex: 1000 }}
          onClick={() => setSeeHelpModal(false)}
        >
          <AiOutlineClose size={20} />
        </IconButton>
        <Modal.Body
          className="d-flex justify-content-center align-items-center"
          style={{
            border: "2.5px solid #BEBEBE",
            backgroundColor: "#1B1C1D",
            borderRadius: "20px 20px 20px 20px",
          }}
        >
          <video
            autoPlay="autoplay"
            loop="loop"
            playsInline
            className="w-100"
            style={{ borderRadius: 24 }}
          >
            <source
              src={
                // "https://dukqh1e9lwqr5.cloudfront.net/static/znanye/videos/Help%20-%20Product%20Upload.webm"
                "https://api.znanye.com/static/znanye/videos/Help%20-%20Product%20Upload.webm"
              }
              alt="Help Video"
              type="video/webm"
            />
          </video>
        </Modal.Body>
      </Modal>
      <Modal
        style={{ borderRadius: "20px" }}
        show={seeHowModal}
        onHide={() => setSeeHowModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={"static"}
        // backdropClassName="modal-backdrop login-modal-backdrop"
        contentClassName="border-R20 user-select-none"
        onExited={() => {
          setSeeHowModal(false);
        }}
      >
        <IconButton
          size="large"
          className="d-flex justify-content-center removeButtonRight position-absolute text-white"
          dark={true}
          style={{ zIndex: 1000 }}
          onClick={() => setSeeHowModal(false)}
        >
          <AiOutlineClose size={20} />
        </IconButton>
        <Modal.Body
          className="d-flex justify-content-center align-items-center"
          style={{
            border: "2.5px solid #BEBEBE",
            backgroundColor: "#1B1C1D",
            borderRadius: "20px 20px 20px 20px",
          }}
        >
          <Image
            src={seehow_popup}
            alt="See How"
            style={{ width: "450px", borderRadius: "20px" }}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    // width:700,
    // height:370
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  submitButton: {
    width: 250,
    height: 50,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },

  fileNames: {
    backgroundColor: "#141414",
  },

  priceCard: {
    borderLeft: "1px solid #4e4e4e",
    marginTop: "10px",
    marginBottom: "10px",
    color: "#555555",
  },
  selectedModeButton: {
    width: "115px",
    backgroundColor: "#fff",
    border: "none",
    color: "black",
  },
  unselectedModeButton: {
    width: "115px",
    backgroundColor: "#0D0D0D",
    border: "none",
    color: "white",
  },
};

export default ProductFiles;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const AboutUsPolicy = () => {
  const location = useLocation();
  return (
    <React.Fragment>
      {/* <Helmet>
        <title>{`Znanye | About Us and all you need to know to trust us`}</title>
        <meta name="description" content="Discover the passion and drive that fuels Znanye - a company built on innovation, hard work, and a shared vision for Virtual Reality! " />
        <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
      </Helmet> */}
      <Container
        className="d-flex flex-column h-100 text-left text-white px-3 px-sm-4 px-md-5 py-2 py-md-4"
        fluid
        style={styles.parentContainer}
      >
        <h4>About Us</h4>
        <hr className="line-below-heading" />
        <Row>
          <Col
            className="text-white my-2"
            style={styles.privacyPolicyDescription}
            xs={12}
          >
            <p className="text-justify">
              ZNANYE ® is a Virtual Reality (VR) Platform that will allow
              visitors to Buy and Sell VR assets, experience VR games and
              experiences, and much more. The new emerging world of virtual
              reality is about of explode on us and ZNANYE ® intends to act as
              one of the leading platforms for users to take part in this
              digital metamorphosis. ZNANYE ® will also allow users to buy VR
              headsets of their choice. Since one size does not fit all, ZNANYE
              ® will allow users to contact the team for customized offering.
            </p>
          </Col>
          <Col
            className="text-white text-justify"
            style={styles.privacyPolicyDescription}
            xs={12}
          >
            <p className="text-justify">
              The parent organization for ZNANYE ® is{" "}
              <a href="https://www.pastelcube.com/">
                Pastelcube Technologies Private Limited
              </a>{" "}
              and was founded with the aim to make use of available technology
              to enrich lives.
            </p>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    marginTop: "80px",
  },
};

export default AboutUsPolicy;

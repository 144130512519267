import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const UserTermsOfUse = () => {
  const location = useLocation();
  return (
    <React.Fragment>
      {/* <Helmet>
        <title>{`Znanye | User's Terms of Use`}</title>
        <meta name="description" content="User's Terms of Use" />
        <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
      </Helmet> */}
      <Container
        className="d-flex flex-column h-100 text-left text-white px-3 px-sm-4 px-md-5 py-2 py-md-4"
        fluid
        style={styles.parentContainer}
      >
        <h4 className="mb-2">TERMS OF USE</h4>
        <hr className="line-below-heading" />
        <Row>
          <Col
            className="text-white my-2"
            style={styles.privacyPolicyDescription}
            xs={12}
          >
            <p className="text-justify">
              These Terms of Use (<b>"Terms”</b>) regulate the legal
              relationship between you (<b>"User”</b> or <b>“you”</b> or{" "}
              <b>“your”</b>) and Pastelcube Technologies Private Limited (
              <b>“Znanye”</b> or <b>“we”</b> or <b>“us”</b> or <b>“our”</b>) as
              a virtual reality asset marketplace (<b>“Platform”</b>). Please
              note that by continuing the use of our Platform, you signify your
              acceptance of these Terms.
            </p>
            <p className="text-justify">
              These "Terms" constitute an electronic record within the meaning
              of the applicable law. This electronic record is generated by a
              computer system and does not require any physical or digital
              signatures.
            </p>
          </Col>
          <Col
            className="text-white text-justify"
            style={styles.privacyPolicyDescription}
            xs={12}
          >
            <div className="">
              <h4>1. DEFINITIONS</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                For the purposes of this Term, the below terms shall have the
                following meanings unless otherwise stated:
              </p>
              <div className="d-flex">
                <span className="ms-4 me-2">1.1.</span>
                <p className="text-justify">
                  <b>“Assets”</b> shall mean (i) software designed in order to
                  facilitate the development of electronic applications and
                  digital media; and (ii) content (for example- without
                  limitation- computer graphics, including 3D computer graphics,
                  sounds and music), tutorials and other digital materials
                  created in order to become incorporated and embedded
                  components of electronic applications and digital media.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">1.2.</span>
                <p className="text-justify">
                  <b>“Seller”</b> shall mean sellers of the Assets listed on the
                  Platform.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">1.3.</span>
                <p className="text-justify">
                  <b>“Account”</b> shall mean the account created by you on the
                  Platform to avail Znanye services.
                </p>
              </div>
            </div>
            <div className="">
              <h4>2. ACCEPTANCE</h4>
              <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">2.1.</span>
                <p className="text-justify">
                  These Terms form a legally binding contract between you and
                  Znanye, with respect to your use of the Platform. In the
                  event, you disagree with these Terms you shall immediately
                  cease to use the Platform.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">2.2.</span>
                <p className="text-justify">
                  Any person using the Platform on your behalf represents and
                  warrants that they have the legal authority to bind you under
                  these Terms.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">2.3.</span>
                <p className="text-justify">
                  Some of these Assets may be offered by us directly while
                  others may be made available by the sellers.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">2.4.</span>
                <p className="text-justify">
                  Additionally, some Assets may be made available to you at no
                  charge while other Assets may be purchased for a fee. You
                  agree that you are solely responsible for all fees associated
                  with purchases you make on the Platform. <b>“Account”</b>{" "}
                  shall mean the account created by you on the Platform to avail
                  Znanye services.
                </p>
              </div>
            </div>
            <div className="">
              <h4>3. YOUR ACCOUNT</h4>
              <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">3.1.</span>
                <p className="text-justify">
                  In order to use most services, you must register an account on
                  Znanye website. To create an account, you will be required to
                  provide certain information and you will establish a username
                  and a password.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">3.2.</span>
                <p className="text-justify">
                  You agree to provide accurate, current and complete
                  information during the registration process and to update such
                  information to keep it accurate, current and complete. Znanye
                  reserves the right to suspend or terminate your account if any
                  information provided during the registration process or
                  thereafter proves to be inaccurate, not current or incomplete.
                  You are responsible for safeguarding your password. You agree
                  not to disclose your password to any third party and to take
                  sole responsibility for any activities or actions under your
                  account, whether or not you have authorized such activities or
                  actions.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">3.3.</span>
                <p className="text-justify">
                  You are responsible for safeguarding your One Time Password
                  (OTP) and to take sole responsibility for any activities or
                  actions, whether authorised or unauthorised under your
                  account. In the event you observe any unauthorized activity in
                  your account, you agree to notify us immediately.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">3.4.</span>
                <p className="text-justify">
                  You may cancel your Znanye Account at any time by sending an
                  email to talk2us@znanye.com. Cancelling your Znanye Account
                  does not relieve you of the obligation to pay any and all
                  remaining amounts owing for your existing use of our services.
                  We may, in our sole discretion, refuse to offer the services
                  to any person or entity.
                </p>
              </div>
            </div>
            <div className="">
              <h4>4. PRIVACY</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                Please review our{" "}
                <a href="/privacy_policy">Privacy Policy,</a> which also
                governs your visit to the Platform, to understand our practices.
                The personal information / data provided to us by you during the
                course of usage of our Platform will be treated as strictly
                confidential and in accordance with the Privacy Policy and
                applicable laws and regulations. If you object to your
                information being transferred or used, please do not use this
                Platform.
              </p>
            </div>
            <div className="">
              <h4>5. USE OF THE PLATFORM</h4>
              <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">5.1.</span>
                <p className="text-justify">
                  You agree to use the Platform only for purposes that are
                  permitted by (a) these Terms and (b) any applicable law,
                  regulation, or generally accepted practices or guidelines in
                  the relevant jurisdictions.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">5.2.</span>
                <p className="text-justify">
                  You agree to not engage in any activity, that may interfere
                  with, disrupts, damage or access the Platform or any devices,
                  servers, networks, or other properties or services in an
                  unauthorized manner.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">5.3.</span>
                <p className="text-justify">
                  You shall not use the Platform to sell, market or/and make
                  available any product or services to primarily facilitate the
                  sale of an asset outside the Platform.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">5.4.</span>
                <p className="text-justify">
                  You agree and acknowledge that you shall be solely responsible
                  for breach of any obligations under these Terms, applicable
                  third-party contract, applicable law or regulation, and for
                  the consequences of any such breach.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">5.5.</span>
                <p className="text-justify">
                  You understand and acknowledge that in addition to the
                  purchase price of the Asset, you may be charged certain amount
                  as ‘Platform handling charges.’
                </p>
              </div>
            </div>
            <div className="">
              <h4>6. USER CONDUCT AND RULES ON THE PLATFORM</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                You agree, undertake and confirm that your use of the Platform
                shall be strictly governed by the following binding principles:
              </p>
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <span className="ms-4 me-2">6.1.</span>
                  <p className="text-justify">
                    You shall not host, display, upload, modify, publish,
                    transmit, update or share any information which:
                  </p>
                </div>
                <div className="ms-4">
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.1.</span>
                    <p className="text-justify">
                      belongs to another person and to which you do not have any
                      right to;
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.2.</span>
                    <p className="text-justify">
                      is grossly harmful, harassing, blasphemous, defamatory,
                      obscene, pornographic, paedophilic, libellous, invasive of
                      another's privacy, hateful, or racially, ethnically
                      objectionable, disparaging, relating or encouraging money
                      laundering or gambling, or otherwise unlawful in any
                      manner whatever
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.3.</span>
                    <p className="text-justify">is misleading in any way</p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.4.</span>
                    <p className="text-justify">
                      involves the transmission of "junk mail", "chain letters",
                      or unsolicited mass mailing or "spamming"
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.5.</span>
                    <p className="text-justify">
                      promotes illegal activities or conduct that is abusive,
                      threatening, obscene, defamatory or libellous
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.6.</span>
                    <p className="text-justify">
                      infringes upon or violates any third party's rights
                      including, but not limited to, intellectual property
                      rights, rights of privacy (including without limitation
                      unauthorized disclosure of a person's name, email address,
                      physical address or phone number) or rights of publicity
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.7.</span>
                    <p className="text-justify">
                      contains restricted or password-only access pages, or
                      hidden pages or images (those not linked to or from
                      another accessible page)
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.8.</span>
                    <p className="text-justify">
                      provides instructional information about illegal
                      activities such as making or buying illegal weapons,
                      violating someone's privacy, or providing or creating
                      computer viruses
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.9.</span>
                    <p className="text-justify">
                      contains video, photographs, or images of another person
                      (with a minor or an adult).
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.10.</span>
                    <p className="text-justify">
                      tries to gain unauthorized access or exceeds the scope of
                      authorized access to the Platform or to profiles, blogs,
                      communities, account information, bulletins, friend
                      request, or other areas of the Platform or solicits
                      passwords or personal identifying information for
                      commercial or unlawful purposes from other users
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.11.</span>
                    <p className="text-justify">
                      interferes with another user's use and enjoyment of the
                      Platform or any other individual's user and enjoyment of
                      similar services
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.12.</span>
                    <p className="text-justify">
                      infringes any patent, trademark, copyright or other
                      proprietary rights or third party's trade secrets or
                      rights of publicity or privacy or shall not be fraudulent
                      or involve the sale of counterfeit or stolen products.
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.13.</span>
                    <p className="text-justify">
                      violates any law for the time being in force.
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.1.14.</span>
                    <p className="text-justify">
                      threatens the unity, integrity, defence, security or
                      sovereignty of India, friendly relations with foreign
                      states, or public order or causes incitement to the
                      commission of any cognizable offence or prevents
                      investigation of any offence or is insulting any other
                      nation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <span className="ms-4 me-2">6.2.</span>
                  <p className="text-justify">
                    A User may be considered fraudulent or loss to business due
                    to fraudulent activity in the event:
                  </p>
                </div>
                <div className="ms-4">
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.2.1.</span>
                    <p className="text-justify">
                      Users doesn't reply to the payment verification mail sent
                      by Znanye
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.2.2.</span>
                    <p className="text-justify">
                      Users fails to produce adequate documents during the
                      payment details verification
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.2.3.</span>
                    <p className="text-justify">
                      Misuse of another User's phone/email
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.2.4.</span>
                    <p className="text-justify">
                      Users uses invalid address, email and phone no.
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.2.5.</span>
                    <p className="text-justify">
                      Users refuses to pay for an order
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">6.2.6.</span>
                    <p className="text-justify">
                      Miscellaneous activities conducted with the sole intention
                      to cause loss to Znanye
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <span className="me-2">6.3.</span>
                <p className="text-justify">
                  You agree and undertake that when accessing, viewing and/or
                  posting any user generated Content on these pages, you will
                  not imitate, abuse, harass, any User or violate and exploit,
                  any of these Terms.
                </p>
              </div>
              <div className="d-flex">
                <span className="me-2">6.4.</span>
                <p className="text-justify">
                  You shall not use any “deep-link”, “page-scrape”, “robot”,
                  “spider” or other automatic device, program, algorithm or
                  methodology, or any similar or equivalent manual process, to
                  access, acquire, copy or monitor any portion of the Platform
                  or any Content, or in any way reproduce or circumvent the
                  navigational structure or presentation of the Platform or any
                  Content, to obtain or attempt to obtain any materials,
                  documents or information through any means not purposely made
                  available through the Platform. We reserve our right to bar
                  any such activity.
                </p>
              </div>
              <div className="d-flex">
                <span className="me-2">6.5.</span>
                <p className="text-justify">
                  You shall not attempt to gain unauthorized access to any
                  portion or feature of the Platform, or any other systems or
                  networks connected to the Platform or to any server, computer,
                  network, or to any of the services offered on or through the
                  Platform, by hacking, password “mining” or any other
                  illegitimate means.
                </p>
              </div>
              <div className="d-flex">
                <span className="me-2">6.6.</span>
                <p className="text-justify">
                  You may not pretend that you are, or that you represent,
                  someone else, or impersonate any other individual or entity.
                </p>
              </div>
              <div className="d-flex">
                <span className="me-2">6.7.</span>
                <p className="text-justify">
                  You shall at all times ensure full compliance with the
                  applicable provisions of the Information Technology Act, 2000
                  and rules thereunder as applicable and as amended from time to
                  time and also all applicable domestic laws, rules and
                  regulations (including the provisions of any applicable
                  Exchange Control Laws or Regulations in force) and
                  International Laws, Foreign Exchange Laws, Statutes,
                  Ordinances and Regulations (including, but not limited to
                  Sales Tax/VAT, Income Tax, Octroi, service Tax, Central
                  Excise, Custom Duty, Local Levies) regarding your use of our
                  Platformy and your listing, purchase, solicitation of offers
                  to purchase, and sale of products or services. You shall not
                  engage in any transaction in an item or service, which is
                  prohibited by the provisions of any applicable law including
                  exchange control laws or regulations for the time being in
                  force.
                </p>
              </div>
              <div className="d-flex">
                <span className="me-2">6.8.</span>
                <p className="text-justify">
                  You shall not engage in advertising to, or solicitation of,
                  other users of the Platform to buy or sell any products or
                  services, including, but not limited to, products or services
                  related to that being displayed on the Platform or related to
                  us.
                </p>
              </div>
              <div className="d-flex">
                <span className="me-2">6.9.</span>
                <p className="text-justify">
                  The Content posted does not necessarily reflect Znanye’s
                  views. In no event shall Znanye assume or have any
                  responsibility or liability for any Content posted or for any
                  claims, damages or losses resulting from use of Content and/or
                  appearance of Content on the Platform. You hereby represent
                  and warrant that you have all necessary rights in and to all
                  Content which you provide and all information it contains and
                  that such Content shall not infringe any proprietary or other
                  rights of third parties or contain any libellous, tortious, or
                  otherwise unlawful information.
                </p>
              </div>
              <div className="d-flex">
                <span className="me-2">6.10.</span>
                <p className="text-justify">
                  In the event that you return any Asset and/or receive a
                  refund, all license rights granted with respect to the said
                  Asset or the service shall terminate and you shall immediately
                  destroy any and all copies contained on any type of media
                  under your control or possession.
                </p>
              </div>
            </div>
            <div className="">
              <h4>7. CONTENTS POSTED ON PLATFORM</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                All text, graphics, user interfaces, visual interfaces,
                photographs, trademarks, logos, sounds, music and artwork
                (collectively, "Content"), is a third-party user generated
                Content and Znanye has no control over such third-party user
                generated content as Znanye is merely an intermediary for the
                purposes of these Terms. Such Content will become our property
                and you grant us the worldwide, perpetual and transferable
                rights in such Content. We shall be entitled to, consistent with
                our Privacy Policy as adopted in accordance with applicable law,
                use the Content or any of its elements for any type of use
                forever, including but not limited to promotional and
                advertising purposes and in any media whether now known or
                hereafter devised, including the creation of derivative works
                that may include the Content you provide.
              </p>
              <p className="text-justify">
                You may use information on the products and services purposely
                made available on the Platform for downloading, provided that
                you:
              </p>
              <div className="d-flex">
                <span className="ms-4 me-2">7.1.</span>
                <p className="text-justify">
                  do not remove any proprietary notice language in all copies of
                  such documents;
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">7.2.</span>
                <p className="text-justify">
                  use such information only for your personal, non-commercial
                  informational purpose and do not copy or post such information
                  on any networked computer or broadcast it in any media;
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">7.3.</span>
                <p className="text-justify">
                  make no modifications to any such information, and
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">7.4.</span>
                <p className="text-justify">
                  do not make any additional representations or warranties
                  relating to such documents.
                </p>
              </div>
            </div>
            <div className="">
              <h4>8. COMMUNICATION</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                You consent to receive electronic communications from us, by
                means of email or by posting notices on the Platform. You agree
                that all agreements, notices, disclosures and other
                communications that we provide to you, or that you provide to
                us, electronically satisfy any legal requirement that such
                communications be in writing. We are not responsible for any
                automatic filtering that may be applied to any email notices
                that we send to the email address you have provided.
              </p>
            </div>
            <div className="">
              <h4>9. AGE</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                Except as expressly provided otherwise on the Platform, this
                Platform and the services hereunder are intended for persons
                above the age of 18 (Eighteen). If you are under the age of 18
                (Eighteen) or the age of legal majority as per place from where
                you access the Platform, you may purchase access to the services
                only with the involvement of your legal guardian and you
                represent and warrant that your legal guardian has read,
                understood and agreed to these Terms.
              </p>
            </div>
            <div className="">
              <h4>10. INTELLECTUAL PROPERTY RIGHTS</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                By using the services, you will encounter{" "}
                <b>“Znanye Content”</b> which includes all of the images, text,
                information, data, audio, video, graphics and other material
                included on or otherwise made available through the services,
                excluding the Content. The Platform, software, developer
                services, communities and Platform content are protected by
                copyright, trademark, and other applicable laws. Except as
                expressly provided in these Terms, Znanye and its sellers
                exclusively own all right, title and interest in and to the
                services, including all associated intellectual property rights.
                You will not remove, alter or obscure any copyright, trademark,
                service mark or other proprietary rights notices incorporated in
                or accompanying the services.
              </p>
            </div>
            <div className="">
              <h4>11. DISCLAIMER</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                YOU ACKNOWLEDGE AND UNDERTAKE THAT:
              </p>
              <div className="d-flex">
                <span className="ms-4 me-2">i.</span>
                <p>
                  YOU SHALL ASSESS THE SERVICES AND USE YOUR BEST AND PRUDENT
                  JUDGMENT BEFORE ENTERING INTO ANY TRANSACTIONS ON THE WEBSITE;
                  AND
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">ii.</span>
                <p>
                  ANY TRANSACTION ON THE WEBSITE INITIATED BY YOU SHALL BE AT
                  YOUR OWN RISK.
                </p>
              </div>
              <div>
                <p className="text-justify">
                  WE SHALL NEITHER BE LIABLE NOR RESPONSIBLE FOR ANY ACTIONS OR
                  INACTIONS OF SELLERS NOR ANY BREACH OF CONDITIONS,
                  REPRESENTATIONS OR WARRANTIES BY THE SELLERS OR MANUFACTURERS
                  OF THE PRODUCTS AND HEREBY EXPRESSLY DISCLAIM AND ANY ALL
                  RESPONSIBILITY AND LIABILITY IN THAT REGARD. WE SHALL NOT
                  MEDIATE OR RESOLVE ANY DISPUTE OR DISAGREEMENT BETWEEN YOU AND
                  THE SELLERS OR MANUFACTURERS OF THE PRODUCTS.
                </p>
                <p className="text-justify">
                  THE SERVICES ARE PROVIDED BY ZNANYE ON AN “AS IS” AND “AS
                  AVAILABLE” BASIS, WITHOUT ANY REPRESENTATIONS, WARRANTIES,
                  COVENANTS OR CONDITIONS OF ANY KIND. ZNANYE DO NOT WARRANT OR
                  REPRESENT THAT THE SERVICES, OR ANY PART THEREOF, WILL OPERATE
                  UNINTERRUPTED OR ERROR-FREE. ZNANYE DISCLAIM ALL WARRANTIES
                  AND REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR WRITTEN),
                  WHETHER ALLEGED TO ARISE BY OPERATION OF LAW, BY REASON OF
                  CUSTOM OR USAGE IN THE TRADE, BY COURSE OF DEALING OR
                  OTHERWISE, INCLUDING ANY AND ALL: (I) WARRANTIES OF
                  MERCHANTABILITY; (II) WARRANTIES OF FITNESS OR SUITABILITY FOR
                  ANY PURPOSE (WHETHER OR NOT ZNANYE KNOWS, HAS REASON TO KNOW,
                  HAS BEEN ADVISED OR IS OTHERWISE AWARE OF ANY SUCH PURPOSE);
                  AND (III) WARRANTIES OF NON-INFRINGEMENT OR CONDITION OF
                  TITLE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                  OBTAINED FROM ZNANYE OR ELSEWHERE WILL CREATE ANY WARRANTY OR
                  CONDITION NOT EXPRESSLY STATED IN THESE TERMS.
                </p>
                <p className="text-justify">
                  YOU SHALL BE SOLELY RESPONSIBLE FOR THE ACCURACY AND QUALITY
                  OF USER CONTENT, AND YOU UNDERSTAND THAT YOU MUST EVALUATE AND
                  BEAR ALL RISKS ASSOCIATED WITH YOUR USE OF THE SERVICES, OR
                  YOUR RELIANCE ON THE ACCURACY, COMPLETENESS, OR USEFULNESS OF
                  THE SERVICES.
                </p>
              </div>
            </div>
            <div className="">
              <h4>12. LIMITATION OF LIABILITY</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                IN NO EVENT SHALL ZNANYE BE LIABLE FOR ANY DIRECT, INDIRECT,
                SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL
                DAMAGES (INCLUDING LOSS OF DATA, BUSINESS, PROFITS, GOODWILL,
                SERVICE INTERRUPTION, COMPUTER DAMAGE, SYSTEM FAILURE OR ABILITY
                TO EXECUTE) OR FOR THE COST OF PROCURING SUBSTITUTE PRODUCTS OR
                SERVICES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE
                PLATFORM OR THE EXECUTION OR PERFORMANCE OF THE SERVICES, OR
                FROM THE USE OR INABILITY TO USE THE PLATFORM OR PLATFORM
                CONTENT, OR FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS
                WITH OTHERS AS A RESULT OF YOUR USE OF THE PLATFORM, WHETHER
                SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT,
                WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR
                OTHERWISE, AND WHETHER OR NOT ZNANYE HAVE BEEN ADVISED OF THE
                POSSIBILITY OF SUCH LOSS OR DAMAGE.
              </p>
              <p className="text-justify">
                THE FOREGOING LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY
                LIMITED REMEDY SPECIFIED IN THESE TERMS IS FOUND TO HAVE FAILED
                OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE
                LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY
                NOT APPLY TO YOU.
              </p>
            </div>
            <div className="">
              <h4>13. INDEMNITY</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                You agree to indemnify and hold Znanye and its affiliates,
                subsidiaries, owners, directors, officers, employees and agents
                harmless from and against any and all claims, demands, suits,
                proceedings, liabilities, judgments, losses, damages, expenses
                and costs (including without limitation reasonable attorneys'
                fees) assessed or incurred by Znanye directly or indirectly,
                with respect to or arising out of: (i) your breach of your
                obligations under these Terms; (ii) your use of the rights
                granted hereunder, including without limitation any claims made
                by any third parties; (iii) your violation of any third party
                right or applicable law, including without limitation any
                intellectual property, publicity, or privacy, right or law;
                and/or (iv) any claim that any of your User Content caused
                damage to a third party.
              </p>
            </div>
            <div className="">
              <h4>14. GOVERNING LAW AND JURISDICTION</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                These Terms are governed by and construed in accordance with the
                laws of India, without regard to or application of conflict of
                laws rules or principles. The courts of Pune, India shall
                exclusive jurisdiction for any matter related to these Terms.
              </p>
            </div>
            <div className="">
              <h4>15. ARBITRATION</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                All differences, disputes, issues arising out of these
                Termsshall be resolved by a sole arbitrator appointed by Znanye.
                The Arbitration shall be conducted in accordance with the
                Arbitration & Conciliation Act, 1996 and the accompanying rules.
                The costs of the arbitration shall be borne equally by you and
                Znanye. The decision of the arbitrator shall be final and
                binding. The arbitration proceedings shall be in English. The
                venue of the arbitration proceedings shall exclusively be at
                Pune.
              </p>
            </div>
            <div className="">
              <h4>16. GENERAL TERMS</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                All differences, disputes, issues arising out of these
                Termsshall be resolved by a sole arbitrator appointed by Znanye.
                The Arbitration shall be conducted in accordance with the
                Arbitration & Conciliation Act, 1996 and the accompanying rules.
                The costs of the arbitration shall be borne equally by you and
                Znanye. The decision of the arbitrator shall be final and
                binding. The arbitration proceedings shall be in English. The
                venue of the arbitration proceedings shall exclusively be at
                Pune.
              </p>
              <div className="d-flex">
                <span className="ms-4 me-2">16.1.</span>
                <p className="text-justify">
                  Znanye may use consultants and other contractors in connection
                  with the performance of obligations and exercise of rights
                  under these Terms.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.2.</span>
                <p className="text-justify">
                  These Term constitutes the entire understanding between you
                  and Znanye and replaces any prior Terms between you and Znanye
                  in relation to the Platform.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.3.</span>
                <p className="text-justify">
                  You acknowledge that Znanye’s failure to exercise or enforce
                  any legal right or remedy provided in these Terms (or which
                  Znanye has the benefit of under any applicable law), will not
                  be construed as a formal waiver of Znanye's rights and that
                  Znanye will still be entitled to use those rights or remedies.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.4.</span>
                <p className="text-justify">
                  Any provision of these Terms that is determined by the
                  competent authority to be invalid or unenforceable in whole or
                  in part, shall not affect the legality of the remainder of
                  these Terms.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.5.</span>
                <p className="text-justify">
                  You accept and agree that each member of the group of
                  companies to which Znanye belongs is a - third party
                  beneficiary and that they have right to directly enforce, and
                  rely upon, any provision of these Terms that confers a benefit
                  on (or gives rights in favour of) them.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.6.</span>
                <p className="text-justify">
                  Any business loss (including loss of profits, revenue,
                  contracts, anticipated savings, data, goodwill or wasted
                  expenditure) or any other indirect or consequential loss that
                  is not reasonably foreseeable to both you and us when you
                  first started using the Platform are not covered by Znanye’s
                  policies.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.7.</span>
                <p className="text-justify">
                  You are not permitted to assign or transfer any rights you
                  have under these Terms without Znanye’s prior written consent.
                  Furthermore, Znanye can grant, assign, delegate or transfer
                  its rights, responsibilities, and obligations of these Terms
                  without your prior approval.
                </p>
              </div>
            </div>
            <div className="">
              <h4>17. GRIEVANCE OFFICER</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                In the event of any complaint or issue with respect to the
                contents the Platform, you may contact our Grievance Officer at:
              </p>
            </div>
            <div>
              <div>
                <b>Name: </b>Reema Maheshwari
              </div>
              <div>
                <b>Designation: </b>Co-Founder
              </div>
              <div>
                <b>Address: </b>Pune, Maharashtra
              </div>
              <div>
                <b>Email: </b>talk2us@znanye.com
              </div>
              <div>
                <b>Phone: </b>1800-266-4020
              </div>
              <div>
                <b>Time: </b>10:00 IST to 16:00 IST
              </div>
            </div>
          </Col>
          <Col
            className="text-white text-center text-lg-start my-5"
            style={styles.privacyPolicyDescription}
            xs={12}
          >
            <b>Last Updated: August 2022</b>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    marginTop: "80px",
  },
};

export default UserTermsOfUse;

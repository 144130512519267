import { darkden_news, ceo_news, link_news, partial_link_news } from "assets";
import { useState } from "react";
import { Link } from "react-router-dom";

const WeAreInNewsPage = () => {
    const info = [
        {
            img: darkden_news,
            title: 'Get ready to enter a new world of gaming with the launch of "Dark Den"',
            subtitle: 'New Delhi [India], February 28 (ANI/SRV):',
            body: 'The wait is finally over for gaming enthusiasts as Dark Den, the latest VR game, has hit the market. Developed by a team of expert designers and programmers, the game promises to provide an unparalleled immersive experience, unlike anything gamers have experienced before. Dark Den transports players to an extraordinary...',
            url: 'https://www.usworldtoday.com/news/get-ready-to-enter-a-new-world-of-gaming-with-the-launch-of-dark-den20230228181136/'
        },
        {
            img: ceo_news,
            title: 'Online Marketplace for VR Assets and Hardware Launches in India',
            subtitle: 'The Week Focus Updated: October 17, 2022 16:14 IST',
            body: 'A new online marketplace for Virtual Reality digital assets, experiences, and hardware is bringing consumers and buyers together in India. Znanye is a one-stop shop for VR-related products. Users can purchase 3D digital assets for use in VR....',
            url: 'https://www.theweek.in/news/sci-tech/2022/10/17/online-marketplace-for-vr-assets-and-hardware-launches-in-india.html'
        },
    ]
    return (
        <>
            <div className="px-3 mx-lg-5" style={styles.parentContainer}>
                <p className="text-primary cursor-pointer mb-4" >We are in the News </p>
                {info.map((i) => {
                    // console.log(i); 
                    return <WeAreInNewsCard key={i.title} information={i} /> })}
            </div>
        </>
    )
}

const WeAreInNewsCard = ({ key, information }) => {

    const [hoveredLink, setHoveredLink] = useState(null)

    return (
        <>
            <div className="w-100 mb-4 p-3" style={styles.card}>
                <div className="row flex-column flex-lg-row">
                    <div className="col-12 col-lg-2 d-flex justify-content-start justify-content-lg-center">
                        <img src={information.img} alt={'site'} style={styles.imgNews} />
                    </div>
                    <div className="col-12 col-lg-8">
                        <p style={styles.title}>{information.title}</p>
                        <p style={styles.subtitle}>{information.subtitle}</p>
                        <p style={styles.body}>{information.body}</p>
                    </div>
                    <div className="col-12 col-lg-2 d-flex justify-content-center align-items-center px-0">
                        <a
                            href={information.url}
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="d-none d-lg-flex flex-column justify-content-center align-items-center"
                            style={hoveredLink ? styles.hoveredLink : styles.link}
                            onMouseOver={() => setHoveredLink(true)}
                            onMouseOut={() => setHoveredLink(false)}
                        >
                            {hoveredLink ?
                                <>
                                    <img src={partial_link_news} alt="link"  style={styles.linkIcon} />
                                    <p className="mb-0">OPEN LINK</p>
                                </>
                                :
                                <>
                                    <img src={link_news} alt="link" style={styles.linkIcon} />
                                    <p className="mb-0">OPEN LINK</p>
                                </>
                            }
                        </a>
                        <a href={information.url }  className="d-flex d-lg-none flex-column justify-content-center align-items-center" style={styles.linkMobile}>
                            <img src={link_news} alt="link" style={styles.linkIcon} />
                            <p className="mb-0">OPEN LINK</p>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

const styles = {
    card: {
        borderRadius: 24 + 'px',
        background: '#141415',
        border: '1px solid #3D3D3D',
        minHeight: 200
    },
    parentContainer: {
        minHeight: 80 + 'vh',
        marginTop: 100
    },
    title: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: 150 + '%',
        color: '#FFFFFF',
    },
    subtitle: {
        fontFamily: 'Poppins',
        fontStyle: 'italic',
        fontWeight: 700,
        fontSize: 14 + 'px',
        lineHeight: 150 + '%',
        letterSpacing: '0.25px',
        color: '#FFFFFF',
    },
    body: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14 + 'px',
        lineHeight: 150 + '%',
        letterSpacing: 0.25 + 'px',
        color: '#FFFFFF',
    },
    link: {
        borderRadius: 50 + '%',
        backgroundColor: '#292929',
        color: '#51C0FF',
        width: '129px',
        height: '129px',
        cursor: 'pointer',
        textDecoration: 'none'
    },
    hoveredLink: {
        borderRadius: 50 + '%',
        backgroundColor: '#292929',
        color: '#51C0FF',
        width: '129px',
        height: '129px',
        cursor: 'pointer',
        textDecoration: 'none',
        border: "8px solid #51C0FF"
    },
    linkMobile: {
        borderRadius: 32 + 'px',
        backgroundColor: '#292929',
        color: '#51C0FF',
        width: '90%',
        height: '64px',
        cursor: 'pointer',
        textDecoration: 'none'
    },
    linkIcon: {
        width: 24 + 'px',
        height: 24 + 'px',
    },
    imgNews: {
        width: 168 + 'px',
        height: 168 + 'px',
    }
}

export default WeAreInNewsPage;
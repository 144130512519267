import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const SellerTermsOfUse = () => {
  const location = useLocation();
  return (
    <React.Fragment>
      {/* <Helmet>
        <title>{`Znanye | User's Terms of Use`}</title>
        <meta name="description" content="User's Terms of Use" />
        <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
      </Helmet> */}
      <Container
        className="d-flex flex-column h-100 text-left text-white px-3 px-sm-4 px-md-5 py-2 py-md-4"
        fluid
        style={styles.parentContainer}
      >
        <h4 className="mb-2">TERMS OF USE</h4>
        <hr className="line-below-heading" />
        <Row>
          <Col
            className="text-white my-2"
            style={styles.privacyPolicyDescription}
            xs={12}
          >
            <p className="text-justify">
              These Terms of Use (<b>"Terms”</b>) regulate the legal
              relationship between you (<b>"Seller”</b> or <b>“you”</b> or
              <b>“your”</b>) and Pastelcube Technologies Private Limited (
              <b>“Znanye”</b> or <b>“we”</b> or <b>“us”</b> or <b>“our”</b>) as
              the operator of the virtual reality asset platform (“Platform”).
              The purpose of these Terms is to establish the legal framework for
              your use of this Platform. Please note that by continuing the use
              of our Platform, you signify your acceptance of these Terms.
            </p>
            <p className="text-justify">
              These <b>"Terms"</b> constitute an electronic record within the
              meaning of the applicable law. This electronic record is generated
              by a computer system and does not require any physical or digital
              signatures.
            </p>
            <p className="text-justify">
              Both Znanye and Seller shall hereinafter individually be referred
              to as a <b>“Party”</b> and collectively as
              <b>“Parties”</b>.
            </p>
          </Col>
          <Col
            className="text-white text-justify"
            style={styles.privacyPolicyDescription}
            xs={12}
          >
            <div className="">
              <h4>1. DEFINITIONS</h4> <hr className="line-below-heading" />
              <p className="text-justify">
                For the purposes of this Term, the below terms shall have the
                following meanings unless otherwise stated:
              </p>
              <div className="d-flex">
                <span className="ms-4 me-2">1.1.</span>
                <p className="text-justify">
                  <b>“Assets”</b> shall mean (i) software designed in order to
                  facilitate the development of electronic applications and
                  digital media; and (ii) content (for example- without
                  limitation- computer graphics, including 3D computer graphics,
                  sounds and music), tutorials and other digital materials
                  created in order to become incorporated and embedded
                  components of electronic applications and digital media.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">1.2.</span>
                <p className="text-justify">
                  <b>“Customer”</b> shall mean a purchaser of an Asset listed on
                  the Platform.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">1.3.</span>
                <p className="text-justify">
                  <b>“Sale Price”</b> shall mean the amount paid by the Customer
                  for the purchase of the Asset, exclusive of taxes, convenience
                  fees or any other applicable charges.
                </p>
              </div>
            </div>
            <div className="">
              <h4>2. ACCEPTANCE</h4> <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">2.1.</span>
                <p className="text-justify">
                  These Terms form a legally binding contract between you and
                  Znanye, with respect to your use of the Platform to sell
                  Assets. In the event, you disagree with these Terms you shall
                  immediately cease to use the Platform.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">2.2.</span>
                <p className="text-justify">
                  Any person using the Platform on your behalf represents and
                  warrants that they have the legal authority to bind you under
                  these Terms.
                </p>
              </div>
            </div>
            <div className="">
              <h4>3. YOUR ACCOUNT</h4> <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">3.1.</span>
                <p className="text-justify">
                  In order to use the services, you shall register to create an
                  account on the Platform. You will be required to provide
                  certain information and create a username and a password.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">3.2.</span>
                <p className="text-justify">
                  You agree to provide accurate and complete information during
                  the registration process and update such information, from
                  time to time, to maintain the accuracy and authenticity of the
                  information provided. Znanye reserves the right to suspend or
                  terminate your account if any information provided during the
                  registration process or thereafter proves to be inaccurate,
                  outdated or incomplete.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">3.3.</span>
                <p className="text-justify">
                  You are responsible for safeguarding your password/One Time
                  Password (OTP) and be solely responsible for any activities or
                  actions, whether authorised or unauthorised, in your account.
                  In the event you observe any unauthorized activity in your
                  account, you agree to notify us immediately at the contact
                  provided hereinbelow.
                </p>
              </div>
            </div>
            <div className="">
              <h4>4. PRICING AND PAYMENTS</h4>
              <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">4.1.</span>
                <p className="text-justify">
                  The Platform will display the price of the Assets in United
                  States Dollar (USD). Znanye may choose to set different prices
                  in other currencies and shall not be responsible for the
                  accuracy of the prices set, or for currency and conversion
                  rates.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">4.2.</span>
                <p className="text-justify">
                  Upon sale of the Asset on the Platform, a part of Sale Price
                  will be remitted to you on or before 10th day of each
                  subsequent month and the remainder will be retained by us.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">4.3.</span>
                <p className="text-justify">
                  All payments by us towards such sale will be subject to
                  applicable tax deductions.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">4.4.</span>
                <p className="text-justify">
                  Znanye shall be entitled to set off or adjust any or all
                  charges including but not limited to refund and cancellation
                  charges from the sale proceeds payable to you.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">4.5.</span>
                <p className="text-justify">
                  We shall share the understanding with respect to the
                  percentage of Sale Price payable to you vide an e-mail. Your
                  registration as a Seller on the Platform shall be subject to
                  acceptance of the commercials shared by us in such e-mail.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">4.6.</span>
                <p className="text-justify">
                  You shall provide Znanye with any and all information and
                  documentation as Znanye deem necessary from time to time, for
                  Znanye to account for the performance of each of our taxation
                  obligations. Without limitation of any other remedies under
                  these Terms or at law, Znanye shall be entitled to
                  indefinitely suspend payment to you, without incurring any
                  interest of penalty, in the event you fail to provide such
                  information or documentation, till such time that the
                  information or documentation is delivered to Znanye.
                </p>
              </div>
            </div>
            <div className="">
              <h4>5. USE OF THE PLATFORM BY SELLER</h4>
              <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">5.1.</span>
                <p className="text-justify">
                  You agree to use the Platform only for purposes that are
                  permitted by (a) these Terms and (b) any applicable law,
                  regulation, or generally accepted practices or guidelines in
                  the relevant jurisdictions.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">5.2.</span>
                <p className="text-justify">
                  You agree to protect the privacy and legal rights of
                  Customers, in the event your Assets causes personal or
                  sensitive information of customer to be stored with you. You
                  further agree to duly obtain consent of the Customers before
                  you save any personal or sensitive information which belongs
                  to them. You understand and acknowledge that such information
                  shall be stored in a safe and secure manner and in accordance
                  with the applicable law, these Terms and our{" "}
                  <a href="/privacy_policy">Privacy Policy</a>.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">5.3.</span>
                <p className="text-justify">
                  You acknowledge and agree to be solely responsible for any
                  complaints initiated by any Customer for any Assets sold
                  through the Platform.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">5.4.</span>
                <p className="text-justify">
                  You shall be responsible for ensuring that the details of the
                  Asset uploaded on the Platform are accurate and updated and
                  shall match the description of such Asset.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">5.5.</span>
                <p className="text-justify">
                  You agree to not engage in any activity, that may interfere
                  with, disrupts, damage or access the Platform or any devices,
                  servers, networks, or other properties or services in an
                  unauthorized manner.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">5.6.</span>
                <p className="text-justify">
                  You shall not use the Platform to sell, market or/and make
                  available any Asset to primarily facilitate the sale of an
                  asset outside the Platform.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">5.7.</span>
                <p className="text-justify">
                  You agree and acknowledge that you shall be solely responsible
                  for breach of any obligations under these Terms, applicable
                  third-party contract, applicable law or regulation, and for
                  the consequences of any such breach.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">5.8.</span>
                <p className="text-justify">
                  You shall be responsible for uploading and upgrading the
                  latest version of the Assets with the Platform along with the
                  Asset description and understanding of the security
                  permissions necessary for the Asset to function on Customer's
                  equipment, subject to our review. Assets that are not properly
                  uploaded as per Znanye’s terms and policies, will not be
                  published on the Platform.
                </p>
              </div>
            </div>
            <div className="">
              <h4>6. ASSET TAKEDOWNS</h4> <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">6.1.</span>
                <p className="text-justify">
                  You agree not to upload, post, or otherwise transmit via the
                  Platform any Asset or other content, which is inaccurate,
                  harmful, obscene, pornographic, defamatory, racist, violent,
                  offensive, harassing, or otherwise objectionable to Znanye,
                  Customers or under applicable law.
                </p>
              </div>
              <div>
                <div className="d-flex">
                  <span className="ms-4 me-2">6.2.</span>
                  <p className="text-justify">
                    While Znanye is not under any legal obligation to monitor or
                    control the Assets or it’s content, Znanye reserves the
                    right to do so. In the event, we notice that an Asset or any
                    portion thereof that you have uploaded to the Platform does
                    not adhere to these Terms or;
                  </p>
                </div>
                <div className="ms-4">
                  <div className="d-flex">
                    <span className="ms-4 me-2">a.</span>
                    <p className="text-justify">
                      violates the intellectual property rights or any other
                      rights of any third party;
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">b.</span>
                    <p className="text-justify">
                      violates any applicable law or is subject to an
                      injunction;
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">c.</span>
                    <p className="text-justify">
                      violates these Terms or any of Znanye's policies, or other
                      terms of service as may be updated by Znanye from time to
                      time;
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">d.</span>
                    <p className="text-justify">
                      is being sold by you improperly;
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">e.</span>
                    <p className="text-justify">
                      may create liability for Znanye;
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">f.</span>
                    <p className="text-justify">
                      is deemed by Znanye to have a virus or is deemed to be
                      malware, spyware or have an adverse impact on Znanye;
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="ms-4 me-2">g.</span>
                    <p className="text-justify">
                      the display of the Asset is impacting the integrity of
                      Znanye servers.
                    </p>
                  </div>
                  <p className="text-justify ms-4">
                    then Znanye shall be entitled to demand changes in the
                    Asset, including but not limited to changes in materials and
                    descriptions that form part of the Asset, at our sole
                    discretion.
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">6.3.</span>
                <p className="text-justify">
                  In addition to the reasons specified in this Section, Znanye
                  may remove Assets from the Platform at Znanye's sole
                  discretion after providing 30 days’ (Thirty) notice.
                </p>
              </div>
            </div>
            <div className="">
              <h4>7. PRIVACY</h4> <hr className="line-below-heading" />
              <div className="d-flex">
                <p className="text-justify">
                  Please review our{" "}
                  <a href="/privacy_policy">Privacy Policy</a>, which
                  also governs your visit to the Platform, to understand our
                  practices. The personal information / data provided to us by
                  you during the course of usage of the Platform will be treated
                  as strictly confidential and in accordance with the Privacy
                  Policy and applicable laws and regulations. If you object to
                  your information being transferred or used, please do not use
                  the Platform.
                </p>
              </div>
            </div>
            <div className="">
              <h4>8. INTELLECTUAL PROPERTY RIGHTS</h4>
              <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">8.1.</span>
                <p className="text-justify">
                  You expressly acknowledge and agree to hold all intellectual
                  property rights necessary for you to grant Znanye the rights
                  set forth in these Terms, including all necessary patent,
                  trademark, trade secret, copyright, or other proprietary
                  rights, in and to your Assets. In the event, you use
                  third-party materials, you shall ensure that you have the
                  right to use or sell such third-party material.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">8.2.</span>
                <p className="text-justify">
                  You agree to not submit material to the Platform that is
                  copyrighted, protected by trade secret, or otherwise subject
                  to third party proprietary rights, including patent, privacy,
                  and publicity rights, unless you are the owner of such rights
                  or have permission from the rightful owner to submit the
                  material.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">8.3.</span>
                <p className="text-justify">
                  You accept and agree that Znanye shall be entitled to forward
                  your details including but not limited to the name, address,
                  and other contact details to any third party that reasonably
                  claims that you do not have all necessary intellectual
                  property rights, including all necessary patent, trademark,
                  trade secret, copyright or other proprietary rights.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">8.4.</span>
                <p className="text-justify">
                  Except as expressly stated herein, these Terms do not grant
                  you any intellectual property rights whatsoever on the
                  website, Platform, digital idea, and its related software, and
                  all rights are reserved by Znanye. Any form, database, or
                  software that is altered, conceived, made, or developed in
                  whole or in part by Znanye during or as a result of our
                  relationship with you shall become and remain the sole and
                  exclusive property of Znanye. You agree not to any claims in
                  the rights or ownership of any such form, idea, database,
                  software, platform, information, digital idea and any other
                  reasonable intellectual property of Znanye. You further agree,
                  not to use any trademark, service mark, trade name, logo of
                  any company or organization that causes or intends to cause
                  confusion about the owner or authorized user of such marks,
                  names or logos.
                </p>
              </div>
            </div>
            <div className="">
              <h4>9. LICENSE GRANTS</h4> <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">9.1.</span>
                <p className="text-justify">
                  You hereby grant Znanye a non-exclusive, worldwide, and
                  royalty-free license to: (a) copy, perform, distribute,
                  modify, display, and use the Assets for administrative,
                  marketing and demonstration purposes in connection with the
                  operation and marketing of the Platform and Znanye's other
                  products; (b) reproduce, license, and sell your Assets on your
                  behalf; and to publicly display, digitally perform, or
                  transmit for promotional and commercial purposes; (c) create
                  and use samples of the Assets and the contents thereof for the
                  purpose of demonstrating or promoting your Assets or those of
                  the Platform; (d) use any trademarks, service marks or trade
                  names incorporated in your Asset in connection with your
                  material.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">9.2.</span>
                <p className="text-justify">
                  You may discontinue the sale of certain Assets subject to
                  reasonable written notice to Znanye.
                </p>
              </div>
            </div>
            <div className="">
              <h4>10. TERMINATING THESE TERM</h4>
              <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">10.1.</span>
                <div>
                  <p className="text-justify">
                    Znanye may at any time, terminate the understanding
                    hereunder with immediate effect, in the event:
                  </p>
                  <div className="d-flex">
                    <span className="me-2">a.</span>
                    <p className="text-justify">
                      you are in breach of these Term;
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="me-2">b.</span>
                    <p className="text-justify">
                      you are in breach of the applicable law;
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="me-2">c.</span>
                    <p className="text-justify">
                      you become or are declared insolvent; or
                    </p>
                  </div>
                  <div className="d-flex">
                    <span className="me-2">d.</span>
                    <p className="text-justify">
                      your act affects Znanye’s reputation adversely.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">10.2.</span>
                <p className="text-justify">
                  Znanye may terminate the arrangement laid down by these Terms
                  by providing you a 30 (thirty) days’ notice.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">10.3.</span>
                <p className="text-justify">
                  Upon termination, all of the legal rights, obligations, and
                  liabilities that you and Znanye have benefited from, been
                  subject to (or which have accrued over time whilst the Term
                  has been in force) or which are expressed to continue
                  indefinitely shall be unaffected by this cessation.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">10.4.</span>
                <p className="text-justify">
                  In the event of any suspension, disablement or termination,
                  you acknowledge that: (i) Znanye will have no further
                  obligation to provide any services to you; (ii) all rights
                  granted to you under these Terms will immediately cease; (iii)
                  we will cease to display your Assets for sale; Any suspension,
                  disablement or termination will not affect your obligations to
                  Znanye (including, without limitation, proprietary
                  intellectual property rights and ownership, indemnification
                  and limitation of liability), which by their sense and context
                  are intended to survive such suspension, disablement or
                  termination.
                </p>
              </div>
            </div>
            <div className="">
              <h4>11. DISCLAIMER</h4> <hr className="line-below-heading" />
              <p className="text-justify">
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE YOUR USE OF THE
                PLATFORM IS AT THE YOUR SOLE RISK AND THAT THE PLATFORM IS
                PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT A WARRANTY OF ANY
                KIND. YOUR USE OF THE PLATFORM AND ANY MATERIAL DOWNLOADED OR
                OTHERWISE OBTAINED THROUGH THE USE OF THE PLATFORM IS AT YOUR
                OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY
                DAMAGE TO THE YOUR DEVICE OR OTHER EQUIPMENT OR LOSS OF DATA
                THAT RESULTS FROM SUCH USE. YOU EXPRESSLY UNDERSTAND AND AGREE
                THAT ZNANYE IS NOT RESPONSIBLE AND LIABLE FOR ANY UNAUTHORIZED
                USE OF YOUR ASSETS OUTSIDE THE PLATFORM INCLUDING – WITHOUT
                LIMITATION – ANY SALE OR OTHER KIND OF DISTRIBUTION OF ASSETS
                FROM PRIVATE WEBSITES OR THE LIKE. ZNANYE EXPRESSLY DISCLAIMS
                ANY SUCH LIABILITY. ZNANYE FURTHER EXPRESSLY DISCLAIMS ALL
                WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR
                IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES
                AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, AND NON-INFRINGEMENT.
              </p>
            </div>
            <div className="">
              <h4>12. LIMITATION OF LIABILITY</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT ZNANYE OR
                ITS SUBSIDIARIES, HOLDING COMPANIES, AND OTHER AFFILIATES SHALL
                BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF
                DATA, ASSETS, BUSINESS, PROFITS OR ABILITY TO EXECUTE) OR FOR
                THE COST OF PROCURING SUBSTITUTE PRODUCTS ARISING OUT OF OR IN
                CONNECTION WITH THESE TERMS OR YOUR USE OF THE PLATFORM OR ANY
                ASSETS DOWNLOADED OR OTHERWISE OBTAINED FROM THE PLATFORM,
                WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON
                CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT
                LIABILITY OR OTHERWISE, AND WHETHER OR NOT ZNANYE HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. THE FOREGOING
                LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY
                SPECIFIED IN THIS TERM IS FOUND TO HAVE FAILED IN ITS ESSENTIAL
                PURPOSE.
              </p>
            </div>
            <div className="">
              <h4>13. INDEMNIFICATIONS</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                You agree to defend, indemnify and hold harmless Znanye, its
                affiliates, and its respective directors, officers, Customers,
                employees, and agents, from and against any and all third-party
                claims, actions, suits or proceedings, as well as any and all
                losses, liabilities, damages, costs and expenses (including
                reasonable attorney’s fees) arising out of or accruing from (a)
                your use of the Platform in violation of these Terms; (b) Your
                Assets that infringe any copyright, trademark, trade secret,
                trade dress, patent or other intellectual property right of any
                person or defame any person or violate their rights of publicity
                or privacy.
              </p>
            </div>
            <div className="">
              <h4>14. FORCE MAJEURE</h4> <hr className="line-below-heading" />
              <p className="text-justify">
                Notwithstanding anything to the contrary under these Terms,
                Znanye shall not be liable for failure to perform or delay in
                performing any obligation under these Terms to the extent that
                such failure or delay is due to legal prohibition, governmental
                action, insurrection, damage, lapse of security, hacking, or any
                other similar cause beyond the reasonable control of Znanye;
              </p>
            </div>
            <div className="">
              <h4>15. CHANGES TO THE TERM</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                Znanye may make changes to these Terms at any time by posting a
                revised Term on the Platform and updating the date of such
                revision.
              </p>
            </div>
            <div className="">
              <h4>16. GENERAL TERMS</h4> <hr className="line-below-heading" />
              <div className="d-flex">
                <span className="ms-4 me-2">16.1.</span>
                <p className="text-justify">
                  Znanye may use consultants and other contractors in connection
                  with the performance of obligations and exercise of rights
                  under these Terms.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.2.</span>
                <p className="text-justify">
                  these Term constitutes the entire understanding between you
                  and Znanye and replaces any prior Terms between you and Znanye
                  in relation to the Platform.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.3.</span>
                <p className="text-justify">
                  You acknowledge that Znanye’s failure to exercise or enforce
                  any legal right or remedy provided in these Terms (or which
                  Znanye has the benefit of under any applicable law), will not
                  be construed as a formal waiver of Znanye's rights and that
                  Znanye will still be entitled to use those rights or remedies.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.4.</span>
                <p className="text-justify">
                  Any provision of these Terms that is determined by the
                  competent authority to be invalid or unenforceable in whole or
                  in part, shall not affect the legality of the remainder of
                  these Terms.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.5.</span>
                <p className="text-justify">
                  You accept and agree that each member of the group of
                  companies to which Znanye belongs is a third- party
                  beneficiary and that they have right to directly enforce, and
                  rely upon, any provision of these Terms that confers a benefit
                  on (or gives rights in favor of) them.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.6.</span>
                <p className="text-justify">
                  Any business losses (including loss of profits, revenue,
                  contracts, anticipated savings, data, goodwill or wasted
                  expenditure) or any other indirect or consequential loss that
                  is not reasonably foreseeable to both you and us when you
                  first started using the Platform are not covered by Znanye’s
                  policies. Znanye may at any time, terminate the understanding
                  hereunder with immediate effect, in the event:
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.7.</span>
                <p className="text-justify">
                  You are not permitted to assign or transfer any rights you
                  have under these Terms without Znanye’s prior written consent.
                  Furthermore, Znanye can grant, assign, delegate or transfer
                  its rights, responsibilities, and obligations of these Terms
                  without your prior approval.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.8.</span>
                <p className="text-justify">
                  The parties agree to submit to the exclusive jurisdiction of
                  the courts of Pune, and these Terms shall be governed by and
                  construed in accordance with the Indian laws.
                </p>
              </div>
              <div className="d-flex">
                <span className="ms-4 me-2">16.9.</span>
                <p className="text-justify">
                  A single arbitrator chosen by Znanye shall settle any
                  disagreements, disputes, and issues arising out of these
                  Terms. These Terms shall be governed by the Arbitration &
                  Conciliation Act, 1996 and the accompanying rules. The costs
                  of the arbitration shall be borne equally by you and Znanye.
                  The arbitrator’s decision shall be conclusive and binding. The
                  arbitration proceedings shall be conducted in English. The
                  sole venue for the arbitration proceedings shall be Pune.
                </p>
              </div>
            </div>
            <div className="">
              <h4>17. GRIEVANCE OFFICER</h4>
              <hr className="line-below-heading" />
              <p className="text-justify">
                In the event of any complaint or issue with respect to the
                contents the Platform, you may contact our Grievance Officer at:
              </p>
            </div>
            <div>
              <div>
                <b>Name: </b>Reema Maheshwari
              </div>
              <div>
                <b>Designation: </b>Co-Founder
              </div>
              <div>
                <b>Address: </b>Pune, Maharashtra
              </div>
              <div>
                <b>Email: </b>grievance@znanye.com
              </div>
              <div>
                <b>Phone: </b>1800-266-4020
              </div>
              <div>
                <b>Time: </b>10:00 IST to 16:00 IST
              </div>
            </div>
          </Col>
          <Col
            className="text-white text-center text-lg-start my-5"
            style={styles.privacyPolicyDescription}
            xs={12}
          >
            <b>Last Updated: August 2022</b>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    marginTop: "80px",
  },
};

export default SellerTermsOfUse;

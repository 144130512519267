import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Container, Image, Form, Button, Card, Spinner } from "react-bootstrap";
import * as actions from "actions/AdminActions";
import * as authActions from "actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-date-picker';
import "core-ui/pages/Payouts.scss";
// import DatePicker from 'react-date-picker/dist/entry.nostyle';

const Coupons = () => {
    const dispatch = useDispatch();
    const form = useRef(null);
    const uploadExcel = useRef(null);
    const [fetch, setFetch] = useState(false);
    const [payoutsInfo, setPayoutsInfo] = useState([]);
    const [date, setDate] = useState(new Date());

    const monthsOption = [
        { key: "1", value: "January" },
        { key: "2", value: "February" },
        { key: "3", value: "March" },
        { key: "4", value: "April" },
        { key: "5", value: "May" },
        { key: "6", value: "June" },
        { key: "7", value: "July" },
        { key: "8", value: "August" },
        { key: "9", value: "September" },
        { key: "10", value: "October" },
        { key: "11", value: "November" },
        { key: "12", value: "December" },
    ];

    // For downloading the payouts details in .CSV
    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ",";
        const lineDelimiter = "\n";
        const data = payoutsInfo;
        const keys = Object.keys(data[0]);

        result = "";
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach((item) => {
            let ctr = 0;
            keys.forEach((key) => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    }

    function downloadCSV(array) {
        const link = document.createElement("a");
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = "export.csv";

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    // Downloading CSV from backend
    // const exportCSV = async () => {
    //     const response = await actions.export_payouts(payoutsInfo);
    // }

    const fetchPayout = async (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        setFetch(true)
        const response = await actions.payouts(formData);
        if (response) {
            setPayoutsInfo(response);
        } else {
            alert("No Data Found!");
        }
        setFetch(false);
    };
    console.log("payoutsInfo", payoutsInfo);

    // const onChange = (e) => {
    //     const [file] = e.target.files;
    //     const reader = new FileReader();

    //     reader.onload = (evt) => {
    //         const bstr = evt.target.result;
    //         const wb = XLSX.read(bstr, { type: "binary" });
    //         const wsname = wb.SheetNames[0];
    //         const ws = wb.Sheets[wsname];
    //         const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    //         console.log("Imported Data:", data);
    //     };
    //     console.log(reader.readAsBinaryString(file));
    // };

    const createPayouts = (e) => {
        console.log(e.target.files[0]);
        const formData = new FormData();
        formData.append("imported_file", e.target.files[0])
        const response = authActions.createPayouts(formData);
    };

    return (
        <React.Fragment>
            <Container
                className="d-flex flex-column align-items-center h-100 mt-5 pt-5 home-background-categories"
                fluid
                style={styles.parentContainer}
            >
                <Container className="p-5 text-white" fluid>
                    <Form ref={form} onSubmit={(e) => fetchPayout(e)}>
                        <div className="d-flex justify-content-center" style={{ paddingTop: 40 }}>
                            <Col>
                                <Form.Group className="mx-5 mb-5">
                                    <div className="d-flex flex-column">
                                        <Form.Label className="text-white">Select Month:</Form.Label>
                                        <DatePicker name="month" format="MM-y" value={date} onChange={setDate} maxDetail="year" />
                                    </div>
                                    {/* <Form.Select
                                        required
                                        name="month"
                                        type="text"
                                        className="text-white body-text1"
                                        style={{
                                            background: "#282929",
                                            padding: 10,
                                            border: 0,
                                            maxWidth: 485,
                                        }}>
                                        {monthsOption.map((option) => {
                                            return (
                                                <option value={option.key}>{option.value}</option>
                                            );
                                        })}
                                    </Form.Select> */}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mx-5 mb-5">
                                    <Form.Label className="text-white">Set Cut Percentage:</Form.Label>
                                    <Form.Control
                                        required
                                        name="commission"
                                        type="number"
                                        step="0.1"
                                        className="text-white body-text1"
                                        placeholder="5.0"
                                        style={{
                                            background: "#282929",
                                            padding: 10,
                                            border: 0,
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <div className="d-flex mt-4">
                                    <Button
                                        className="explore-button text-white"
                                        type="submit"
                                        disabled={fetch}
                                    >
                                        {fetch ? (
                                            <span>
                                                <Spinner
                                                    className="me-2"
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                FETCHING...
                                            </span>
                                        ) : (
                                            <span className="poppins">FETCH</span>
                                        )}
                                    </Button>
                                </div>
                            </Col>
                        </div>
                    </Form>
                    <Row>
                        <Col sm={12}>
                            <Card
                                className="m-2 d-flex flex-column align-items-center h-100 border-R20 admin-filedrop-card"
                                style={{ background: "#141415", border: "1px solid #3d3d3d" }}
                            >
                                <Container className="h-100 w-100 px-5" fluid>
                                    <Row className="subtitle pb-4 mt-4 d-flex align-items-center border-bottom">
                                        <Col sm={2}>Seller</Col>
                                        <Col className="text-center">Sold Count</Col>
                                        <Col sm={3} className="text-center">Total Amount ($)</Col>
                                        <Col className="text-center">Commission ($)</Col>
                                        <Col className="text-center">Total Payable ($)</Col>
                                        <Col className="text-center">Status</Col>
                                    </Row>
                                    {payoutsInfo.map((item, index) => {
                                        return (
                                            <Row
                                                key={item.id}
                                                className="py-3 body-text1 d-flex align-items-center"
                                                style={{ borderBottom: "1px solid #444" }}
                                            >
                                                <Col sm={2}>{item.seller_name}</Col>
                                                <Col className="text-center">
                                                    {item.sold_count}
                                                </Col>
                                                <Col sm={3} className="text-center">{item.earned}</Col>
                                                <Col className="text-center">{item.commission}</Col>
                                                <Col className="text-center">{item.payable}</Col>
                                                <Col className="text-center">{item.status}</Col>
                                            </Row>
                                        );
                                    })}
                                </Container>
                                <div className="d-flex">
                                    <div className={`${payoutsInfo.length !== 0 ? "py-4 mx-4" : "d-none"}`}>
                                        <Button
                                            className="explore-button text-white"
                                            dark={true}
                                            size="large"
                                            onClick={() => downloadCSV(payoutsInfo)}
                                        // onClick={() => exportCSV()}
                                        >
                                            EXPORT</Button>
                                    </div>
                                    <input
                                        ref={uploadExcel}
                                        type="file"
                                        style={{ display: 'none' }}
                                        accept=".csv"
                                        onChange={(e) => createPayouts(e)}
                                    />
                                    <div className={"py-4"}>
                                        <Button
                                            className="explore-button text-white"
                                            dark={true}
                                            size="large"
                                            onClick={() => uploadExcel.current.click()}
                                        >
                                            IMPORT</Button>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </React.Fragment>
    );
};

const styles = {
    parentContainer: {
        minHeight: "100vh",
    },
    parentContainer90: {
        minHeight: "90vh",
    },
    parentContainer85: {
        minHeight: "85vh",
    },
    parentContainerDark: {
        minHeight: "85vh",
        backgroundColor: "#141415",
    },
    categoryCard: {
        width: 245,
        height: 280,
    },
    checkedIcons: {
        width: 20,
        height: 20,
    },
    cardAction: {
        backgroundColor: "#333333",
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    fileNames: {
        backgroundColor: "#141414",
    },
};

export default Coupons;

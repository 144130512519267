import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Navbar,
  Nav,
  Row,
  Col,
  Modal,
  Image,
  Form,
  Button as RButton,
  DropdownButton,
  Dropdown,
  NavDropdown,
  Spinner,
} from "react-bootstrap";

import {
  Button as NuButton,
  Card,
  CardContent,
  IconButton,
  CardAction,
  Subtitle2,
  Body2,
  H5,
  Badge,
} from "ui-neumorphism";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as actions from "actions/AuthActions";
import * as userActions from "actions/UserActions";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import {
  znanyelogin,
  reset_password_mascot_mobile,
  reset_password_mascot,
  eye,
  eye_off,
  login_mascot,
  create_account_mascot,
  celebrate_mascot,
  forgot_mascot,
  glad,
  email_sent_mobile,
  happy,
  excited,
  login_background,
  forgot_mascot_mobile,
  email_sent,
} from "assets";
import { Breakpoint } from "react-socks";

const ResetPasswordBox = ({ token, forgotPassword, setForgotPassword }) => {
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  const [countryCode, setCountryCode] = useState("+91");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [timer, setTimer] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetPassword, setResetPassword] = useState(true);
  const [successful, setSuccessful] = useState(false);
  const [eyeOn, setEyeOn] = useState(false);
  const [eyeOnTwo, setEyeOnTwo] = useState(false);
  const [resetSending, setResetSending] = useState(false);
  // const [forgotPassword, setForgotPassword] = useState(false);
  const [username, setUsername] = useState([]);
  const [emailResetNote, setEmailResetNote] = useState(false);

  const [currency, setCurrency] = useState(
    useSelector((state) => state.authReducer.currency?.name?.toLowerCase())
  );
  const [countries, setCountries] = useState([]);
  const loginForm = useRef(null);
  const verifyForm = useRef(null);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  // useEffect(() => {
  //     dispatch(userActions.loadCategories());
  //     return () => {
  //         setTimer(0);
  //     };
  // }, []);

  const onLogin = async (e) => {
    e.preventDefault();
    setIsSending(true);
    const formData = new FormData(loginForm.current);
    const response = await actions.loggingIn(formData);
    if (response) {
      dispatch(actions.login(response));
      setIsSending(false);
    } else {
      setIsSending(false);
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();
    setResetSending(true);
    if (newPassword == confirmPassword) {
      const response = await actions.reset_password({
        password: newPassword,
        token: token,
      });
      if (response) {
        switch (response.status) {
          case "LINK_EXPIRED":
          case "DOES_NOT_EXIST":
            toast.error(response.msg);
            break;
          case "SUCCESSFUL":
            // toast.success(response.msg);
            setResetPassword(false);
            setSuccessful(true);
            setTimeout(() => {
              toast.success("Login using new password");
              if (response.is_seller) {
                handleNavigationClick("/sell-3d");
                // navigate("/sell-3d");
              } else {
                // navigate("/");
                handleNavigationClick("/");
              }
            }, 5000);
            break;
          default:
            setResetSending(false);
            alert("Some error");
        }
      }
    } else {
      setResetSending(false);

      toast.error(
        "Passwords not matching. Please enter same New Password and Confirm Password."
      );
    }
    setResetSending(false);
  };

  const handleResetLink = async (e) => {
    e.preventDefault();
    setResetSending(true);
    let email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (username.match(email_regex)) {
      const response = await actions.reset_password_request({
        email: username,
      });
      if (response) {
        switch (response.status) {
          case "DOES_NOT_EXIST":
            // toast.error(response.msg);
            break;
          case "EMAIL_SENT":
            setForgotPassword(false);
            setEmailResetNote(true);
            break;
          default:
            alert("some error");
        }
      }
    } else {
      setResetSending(false);

      toast.error("Please enter a valid email!");
    }
    setResetSending(false);
  };

  useEffect(async () => {
    const response = await userActions.loadCountries();
    setCountries(response);
  }, []);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  return (
    <>
      <Breakpoint large up>
        <div
          className="w-dialog w-contentBox w-dialog shadow"
          style={{ border: "3px solid black" }}
        >
          <Container className="w-contentBox shadow-lg overflow-hidden">
            <Row>
              <Col
                sm={6}
                className="p-5 login_background overflow-auto"
                style={{ height: 600 }}
              >
                <Form
                  ref={loginForm}
                  onSubmit={(e) => handleReset(e)}
                  className={`text-start ${
                    resetPassword ? "d-block" : "d-none"
                  }`}
                >
                  <div className={`${isAuthenticated ? "d-none" : "d-block"}`}>
                    <Form.Group className="w-100">
                      <Form.Label className="text-white subtitle mt-4">
                        Reset Your Password
                      </Form.Label>
                      <p className="text-white caption">Keep Password Safe</p>
                      <div className="d-flex flex-column pt-4">
                        <Form.Group className="d-flex justify-content-end align-items-center position-relative mb-3">
                          <Form.Control
                            required
                            name="password"
                            type={`${eyeOn ? "text" : "password"}`}
                            size="lg"
                            placeholder="New Password"
                            className="text-white"
                            style={{
                              background: "#141414",
                              height: 50,
                              borderColor: "#3D3D3D",
                              borderRadius: 10,
                            }}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                            }}
                          />
                          {!eyeOn && (
                            <RButton
                              type="button"
                              className="wrapped-button position-absolute mx-1"
                              style={{ backgroundColor: "#141414", border: 0 }}
                              onKeyDown={(e) => e.preventDefault()}
                              onClick={(e) => {
                                e.preventDefault();
                                setEyeOn(true);
                              }}
                            >
                              <Image src={eye} alt="Eye" />
                            </RButton>
                          )}
                          {eyeOn && (
                            <RButton
                              type="button"
                              className="wrapped-button position-absolute mx-1"
                              style={{ backgroundColor: "#141414", border: 0 }}
                              onKeyDown={(e) => e.preventDefault()}
                              onClick={(e) => {
                                e.preventDefault();
                                setEyeOn(false);
                              }}
                            >
                              <Image src={eye_off} alt="Eye Off" />
                            </RButton>
                          )}
                        </Form.Group>
                        <Form.Group className="d-flex justify-content-end align-items-center position-relative mb-3">
                          <Form.Control
                            required
                            name="confirm_password"
                            type={`${eyeOnTwo ? "text" : "password"}`}
                            size="lg"
                            placeholder="Confirm Password"
                            className="text-white"
                            style={{
                              background: "#141414",
                              height: 50,
                              borderColor: "#3D3D3D",
                              borderRadius: 10,
                            }}
                            // onKeyDown={handleKeyDown}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                          />
                          {!eyeOnTwo && (
                            <RButton
                              type="button"
                              className="wrapped-button position-absolute mx-1"
                              style={{ backgroundColor: "#141414", border: 0 }}
                              onKeyDown={(e) => e.preventDefault()}
                              onClick={(e) => {
                                e.preventDefault();
                                setEyeOnTwo(true);
                              }}
                            >
                              <Image src={eye} alt="Eye" />
                            </RButton>
                          )}
                          {eyeOnTwo && (
                            <RButton
                              type="button"
                              className="wrapped-button position-absolute mx-1"
                              style={{ backgroundColor: "#141414", border: 0 }}
                              onKeyDown={(e) => e.preventDefault()}
                              onClick={(e) => {
                                e.preventDefault();
                                setEyeOnTwo(false);
                              }}
                            >
                              <Image src={eye_off} alt="Eye Off" />
                            </RButton>
                          )}
                        </Form.Group>
                      </div>
                    </Form.Group>
                    <div className="text-center">
                      <RButton
                        className="btn btn-link border-0  wrapped-button w-100 p-0"
                        style={{
                          background: "transparent",
                          textDecoration: "none",
                        }}
                        type="submit"
                      >
                        <RButton
                          className="mt-5 w-100 explore-button-no-neo btn btn-ptimary"
                          size="lg"
                          dark={true}
                          style={{ border: "0px", borderRadius: "10px" }}
                          disabled={resetSending}
                          onClick={async (e) => {
                            e.preventDefault();
                            setResetSending(true);
                            if (newPassword == confirmPassword) {
                              const response = await actions.reset_password({
                                password: newPassword,
                                token: token,
                              });
                              if (response) {
                                switch (response.status) {
                                  case "LINK_EXPIRED":
                                  case "DOES_NOT_EXIST":
                                    toast.error(response.msg);
                                    break;
                                  case "SUCCESSFUL":
                                    // toast.success(response.msg);
                                    setResetPassword(false);
                                    setSuccessful(true);
                                    setTimeout(() => {
                                      toast.success("Login using new password");
                                      if (response.is_seller) {
                                        handleNavigationClick("/sell-3d");
                                        // navigate("/sell-3d");
                                      } else {
                                        handleNavigationClick("/");
                                        // navigate("/");
                                      }
                                    }, 5000);
                                    break;
                                  default:
                                    alert("Some error");
                                }
                              }
                            } else {
                              setResetSending(false);

                              toast.error(
                                "Passwords not matching. Please enter same New Password and Confirm Password."
                              );
                            }
                            setResetSending(false);
                          }}
                        >
                          {resetSending ? (
                            <span>
                              <Spinner
                                className="me-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              SUBMITTING...
                            </span>
                          ) : (
                            <span className="poppins">RESET MY PASSWORD</span>
                          )}
                        </RButton>
                      </RButton>
                    </div>
                    <div className="my-3 d-flex justify-content-center align-items-center">
                      <div className="text-white caption">
                        {/* Already have an account! */}
                        Link Expired ?
                      </div>
                      <RButton
                        // as={Link}
                        // to={`/`}
                        className="wrapped-button caption"
                        variant="link"
                        onClick={() => {
                          setForgotPassword(true);
                          setResetPassword(false);
                        }}
                        // onClick={() => {
                        // setLogin(true);}}
                      >
                        Request Here!
                      </RButton>
                    </div>
                  </div>
                </Form>

                <Form
                  ref={verifyForm}
                  className="text-start"
                  onSubmit={(e) => handleResetLink(e)}
                >
                  <div
                    className={`${
                      forgotPassword && !isAuthenticated ? "d-block" : "d-none"
                    }`}
                  >
                    <Form.Group className="w-100">
                      <Form.Label className="text-white subtitle mt-4">
                        Forgot Password
                      </Form.Label>
                      <p className="text-white caption">
                        Enter Your Registered Email Id
                      </p>
                      <br />
                      <div className="d-flex flex-column">
                        <Form.Control
                          required
                          name="username"
                          type="email"
                          size="lg"
                          placeholder="Enter your Email"
                          className="text-white"
                          style={{
                            background: "#141414",
                            height: 50,
                            borderColor: "#3D3D3D",
                            borderRadius: 10,
                          }}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                        />
                      </div>
                    </Form.Group>
                    <div className="text-center">
                      <RButton
                        className="btn btn-link border-0  wrapped-button w-100 p-0"
                        style={{
                          background: "transparent",
                          textDecoration: "none",
                        }}
                        type="submit"
                      >
                        <RButton
                          className="mt-5 mb-2 explore-button-no-neo btn btn-ptimary"
                          dark={true}
                          size="lg"
                          disabled={resetSending}
                          style={{
                            border: "0px",
                          }}
                          onClick={async (e) => {
                            e.preventDefault();
                            setResetSending(true);
                            let email_regex =
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                            // alert(username)
                            if (username.match(email_regex)) {
                              const response =
                                await actions.reset_password_request({
                                  email: username,
                                });
                              if (response) {
                                switch (response.status) {
                                  case "DOES_NOT_EXIST":
                                    // toast.error(response.msg);
                                    break;
                                  case "EMAIL_SENT":
                                    setForgotPassword(false);
                                    setEmailResetNote(true);
                                    // toast.success(response.msg);
                                    break;
                                  default:
                                    alert("some error");
                                }
                              }
                            } else {
                              setResetSending(false);
                              toast.error("Please enter a valid email!");
                            }
                            setResetSending(false);
                          }}
                        >
                          {resetSending ? (
                            <span>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              SENDING...
                            </span>
                          ) : (
                            <span className="poppins">
                              SEND ME A RESET LINK
                            </span>
                          )}
                        </RButton>
                      </RButton>
                    </div>
                    {/* <div className="d-flex justify-content-center align-items-center">
                      <div className="text-white caption">
                        Already have an account{" "}
                      </div>
                      <RButton
                        className="wrapped-button  caption"
                        onClick={() => {
                          setForgotPassword(false);
                        }}
                        variant="link"
                      >
                        Log In Here
                      </RButton>
                      <div
                        className={`${
                          timer !== 0 ? "text-white mx-2 caption" : "d-none"
                        }`}
                      >
                        {`after ${timer} seconds`}
                      </div>
                    </div> */}
                  </div>
                </Form>

                <div
                  className={`${
                    emailResetNote && !isAuthenticated
                      ? "d-flex justify-content-center align-items-center h-100"
                      : "d-none"
                  }`}
                >
                  <div
                    className="text-white d-flex align-items-center h4 m-4"
                    style={{ fontWeight: 400 }}
                  >
                    <p>
                      We have shared a link for reset your password on your
                      email Id{" "}
                      <b>
                        <i>{username}</i>{" "}
                      </b>
                      Kindly check your email.
                    </p>
                  </div>
                </div>

                <div
                  className={`${
                    successful && !isAuthenticated
                      ? "d-flex flex-column justify-content-center align-items-center h-100"
                      : "d-none"
                  }`}
                >
                  <div
                    className="text-white d-flex align-items-center h4 m-4"
                    style={{ fontWeight: 400 }}
                  >
                    <p>Yeahhh !!! Your password has been successfully reset!</p>
                  </div>
                  {/* <div>
                                <p className="explore-text">Login Here</p>
                            </div> */}
                </div>
              </Col>
              <Col
                sm={6}
                className="d-flex flex-column justify-content-between align-items-center"
                style={{ background: emailResetNote && "rgb(41,41,41)" }}
              >
                <div
                  className={`${
                    emailResetNote || successful ? "d-none" : "d-flex"
                  } justify-content-center align-items-end mt-5`}
                >
                  <div className="p-4 text-white notch-div text-center">
                    {resetPassword && <span>Keep it safe !</span>}
                    {forgotPassword && <span>Oops !</span>}
                  </div>
                  <div className="notch"></div>
                </div>
                <div className="h-100 d-flex align-items-center mt-4">
                  {resetPassword && (
                    <Image
                      src={reset_password_mascot}
                      alt="Reset Password Mascot"
                    />
                  )}
                  {forgotPassword && (
                    <Image src={forgot_mascot} alt="Password Mascot" />
                  )}
                  {emailResetNote && (
                    <Image src={email_sent} alt="Email Sent" loading="lazy" />
                  )}
                  {successful && (
                    <Image src={celebrate_mascot} alt="Celebrating Mascot" />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Breakpoint>
      <Breakpoint
        className={`w-100 ${
          emailResetNote
            ? "mobile-email-sent-background"
            : "mobile-auth-background"
        }`}
        // className="mobile-auth-background d-flex flex-column justify-content-between"
        medium
        down
      >
        <div>
          <Form
            // ref={loginForm}
            className={`text-start ${
              resetPassword
                ? "d-flex mobile-auth-background d-flex flex-column justify-content-between px-3"
                : "d-none"
            }`}
          >
            <div className={`${isAuthenticated ? "d-none" : "d-block"}`}>
              <Form.Group className="w-100">
                <Form.Label className="text-white  subtitle mt-4">
                  Reset Your Password
                </Form.Label>
                <p className="text-white caption">Keep Password Safe</p>
                <div className="d-flex flex-column pt-4">
                  <Form.Group className="d-flex justify-content-end align-items-center position-relative mb-3">
                    <Form.Control
                      name="password"
                      type={`${eyeOn ? "text" : "password"}`}
                      size="lg"
                      placeholder="New Password"
                      className="text-white"
                      style={{
                        background: "#141414",
                        height: 50,
                        borderColor: "#3D3D3D",
                        borderRadius: 10,
                      }}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                    />
                    {!eyeOn && (
                      <RButton
                        type="button"
                        className="wrapped-button position-absolute m-1"
                        style={{ backgroundColor: "#141414", border: 0 }}
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                          e.preventDefault();
                          setEyeOn(true);
                        }}
                      >
                        <Image src={eye} alt="Eye" />
                      </RButton>
                    )}
                    {eyeOn && (
                      <RButton
                        type="button"
                        className="wrapped-button position-absolute m-1"
                        style={{ backgroundColor: "#141414", border: 0 }}
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                          e.preventDefault();
                          setEyeOn(false);
                        }}
                      >
                        <Image src={eye_off} alt="Eye Off" />
                      </RButton>
                    )}
                  </Form.Group>
                  <Form.Group className="d-flex justify-content-end align-items-center position-relative">
                    <Form.Control
                      name="confirm_password"
                      type={`${eyeOnTwo ? "text" : "password"}`}
                      size="lg"
                      placeholder="Confirm Password"
                      className="text-white"
                      style={{
                        background: "#141414",
                        height: 50,
                        borderColor: "#3D3D3D",
                        borderRadius: 10,
                      }}
                      // onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                    {!eyeOnTwo && (
                      <RButton
                        type="button"
                        className="wrapped-button position-absolute m-1"
                        style={{ backgroundColor: "#141414", border: 0 }}
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                          e.preventDefault();
                          setEyeOnTwo(true);
                        }}
                      >
                        <Image src={eye} alt="Eye" />
                      </RButton>
                    )}
                    {eyeOnTwo && (
                      <RButton
                        type="button"
                        className="wrapped-button position-absolute m-1"
                        style={{ backgroundColor: "#141414", border: 0 }}
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                          e.preventDefault();
                          setEyeOnTwo(false);
                        }}
                      >
                        <Image src={eye_off} alt="Eye Off" />
                      </RButton>
                    )}
                  </Form.Group>
                </div>
              </Form.Group>
              <div className="text-center">
                <RButton
                  className="my-4  mt-5 w-100 d-flex justify-content-center align-items-center body-text2 explore-button-no-neo explore-button-large btn btn-ptimary"
                  dark={true}
                  disabled={resetSending}
                  size="lg"
                  style={{ borderRadius: "8px", height: "48px", border: "0px" }}
                  onClick={async (e) => {
                    e.preventDefault();
                    setResetSending(true);
                    if (newPassword == confirmPassword) {
                      const response = await actions.reset_password({
                        password: newPassword,
                        token: token,
                      });
                      if (response) {
                        switch (response.status) {
                          case "LINK_EXPIRED":
                          case "DOES_NOT_EXIST":
                            toast.error(response.msg);
                            break;
                          case "SUCCESSFUL":
                            // toast.success(response.msg);
                            setResetPassword(false);
                            setSuccessful(true);
                            setTimeout(() => {
                              toast.success("Login using new password");
                              handleNavigationClick("/login");
                              // navigate("/login");
                            }, 5000);
                            break;
                          default:
                            alert("Some error");
                        }
                        // setForOtp(true);
                        // setNext(false);
                        // setTimer(response.time_left);
                      }
                    } else {
                      setResetSending(false);
                      toast.error(
                        "Passwords not matching. Please enter same New Password and Confirm Password."
                      );
                    }
                    setResetSending(false);
                  }}
                >
                  {resetSending ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      SUBMITTING...
                    </span>
                  ) : (
                    <span className="poppins">RESET MY PASSWORD</span>
                  )}
                </RButton>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div className="text-white caption">
                  {/* Already have an account!{" "}
                   */}
                  Link Expired ?
                </div>
                <RButton
                  // as={Link}
                  // to={"/login"}
                  className="mx-2 wrapped-button caption p-0"
                  variant="link"
                  onClick={() => {
                    setForgotPassword(true);
                    setResetPassword(false);
                  }}
                >
                  Request Here!!
                </RButton>
              </div>
            </div>
          </Form>

          <Form
            ref={verifyForm}
            // className="text-start"
            className={`text-start ${
              forgotPassword
                ? "d-flex mobile-auth-background d-flex flex-column justify-content-between px-3"
                : "d-none"
            }`}
            onSubmit={(e) => handleResetLink(e)}
          >
            <div
              className={`${
                forgotPassword && !isAuthenticated ? "d-block" : "d-none"
              }`}
            >
              <div className="mt-5 pt-5">
                {forgotPassword && (
                  <span className="text-white h4" style={{ fontWeight: 275 }}>
                    Don't worry! we've got your back
                  </span>
                )}
                <Image
                  className="mx-2"
                  style={{ width: 22 }}
                  src={happy}
                  alt="Happy"
                />
              </div>

              <Form.Group className="w-100">
                <Form.Label className="text-white subtitle mt-4">
                  Forgot Password
                </Form.Label>
                <p className="text-white caption">
                  Enter Your Registered Email Id
                </p>
                <br />
                <div className="d-flex flex-column">
                  <Form.Control
                    required
                    name="username"
                    type="email"
                    size="lg"
                    placeholder="Enter your Email"
                    className="text-white"
                    style={{
                      background: "#141414",
                      height: 50,
                      borderColor: "#3D3D3D",
                      borderRadius: 10,
                    }}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </div>
              </Form.Group>
              <div className="text-center">
                <RButton
                  className="btn btn-link border-0  wrapped-button w-100 p-0"
                  style={{
                    background: "transparent",
                    textDecoration: "none",
                  }}
                  type="submit"
                >
                  <RButton
                    className="mt-5 mb-2 explore-button-no-neo btn btn-ptimary"
                    dark={true}
                    size="lg"
                    disabled={resetSending}
                    style={{
                      border: "0px",
                    }}
                    onClick={async (e) => {
                      e.preventDefault();
                      setResetSending(true);
                      let email_regex =
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                      // alert(username)
                      if (username.match(email_regex)) {
                        const response = await actions.reset_password_request({
                          email: username,
                        });
                        if (response) {
                          switch (response.status) {
                            case "DOES_NOT_EXIST":
                              // toast.error(response.msg);
                              break;
                            case "EMAIL_SENT":
                              setForgotPassword(false);
                              setEmailResetNote(true);
                              // setTimeout(() => {
                              //   setEmailResetNote(false);

                              //   setResetPassword(true)
                              // }, 8000);
                              // toast.success(response.msg);
                              break;
                            default:
                              alert("some error");
                          }
                        }
                      } else {
                        setResetSending(false);
                        toast.error("Please enter a valid email!");
                      }
                      setResetSending(false);
                    }}
                  >
                    {resetSending ? (
                      <span>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        SENDING...
                      </span>
                    ) : (
                      <span className="poppins">SEND ME A RESET LINK</span>
                    )}
                  </RButton>
                </RButton>
              </div>
              {/* <div className="d-flex justify-content-center align-items-center">
                      <div className="text-white caption">
                        Already have an account{" "}
                      </div>
                      <RButton
                        className="wrapped-button  caption"
                        onClick={() => {
                          setForgotPassword(false);
                        }}
                        variant="link"
                      >
                        Log In Here
                      </RButton>
                      <div
                        className={`${
                          timer !== 0 ? "text-white mx-2 caption" : "d-none"
                        }`}
                      >
                        {`after ${timer} seconds`}
                      </div>
                    </div> */}
            </div>
          </Form>
        </div>
        <div className="d-flex align-items-end justify-content-center pt-5">
          {resetPassword && (
            <Image
              src={reset_password_mascot_mobile}
              alt="Reset Password Mascot"
            />
          )}
          {forgotPassword && (
            <Image src={forgot_mascot_mobile} alt="Password Forgot Mascot" />
          )}
        </div>
        <div
          className={`${
            emailResetNote && !isAuthenticated
              ? "d-flex flex-column justify-content-center align-items-center w-100 p-0"
              : "d-none"
          }`}
          style={{
            //  backgroundColor: "rgb(30,30,30)".
            minHeight: "90vh",
          }}
        >
          <Image style={{ width: 140 }} src={email_sent_mobile} alt="Mail" />
          <div
            className="text-white text-center d-flex align-items-center h4 m-4 poppins-400"
            style={{ fontSize: "14px", lineHeight: "21px" }}
          >
            <p>
              We have shared a link for reset your password on your email Id
              &nbsp;
              <b>
                <i>{username}</i>
              </b>
              &nbsp; Kindly check your email.
            </p>
          </div>
        </div>
        <div
          className={`${
            successful && !isAuthenticated
              ? "d-flex justify-content-center align-items-center h-100"
              : "d-none"
          }`}
        >
          <div
            className="text-white d-flex flex-column align-items-center h4 m-4"
            style={{ fontWeight: 400 }}
          >
            <p className="h3 text-center">
              Yeahhh ! Your password is set successfully!!
            </p>
            <p className="text-center">You can login to Znanye now.</p>
            <RButton
              className="wrapped-button"
              as={Link}
              to={"/login"}
              variant="link"
            >
              Log In Here
            </RButton>
            <Image src={celebrate_mascot} alt="Celebrating Mascot" />
          </div>
        </div>
      </Breakpoint>
    </>
  );
};

const styles = {
  parentContainer: {
    // minHeight: "100vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
};

export default ResetPasswordBox;

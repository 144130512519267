import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Spinner,
  Button as RButton,
  Card as RCard,
} from "react-bootstrap";
import { Button, Card, CardContent, IconButton } from "ui-neumorphism";

import "core-ui/pages/MyAccount.scss";

import {
  BasicInformation,
  GSTInformation,
  BusinessInformation,
  BankAccount,
  ProductCard,
  SalesTable,
} from "components";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "actions/AuthActions";
import * as adminActions from "actions/AdminActions";
import { toast } from "react-toastify";
import "./../../core-ui/pages/Profile.scss";
import { Link } from "react-router-dom";
import { FiPhoneCall, FiMail, FiEdit2 } from "react-icons/fi";
import { FcCheckmark } from "react-icons/fc";
import { profilepicture } from "assets";
import { Breakpoint, useCurrentBreakpointName } from "react-socks";

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.user);
  const seller = useSelector((state) => state.authReducer.seller);
  const form = useRef(null);
  const form2 = useRef(null);
  const form3 = useRef(null);
  const form4 = useRef(null);
  const gstform = useRef(null);
  const [next1, setNext1] = useState(false);
  const [next2, setNext2] = useState(false);
  const [next3, setNext3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState(user?.region_data?.code);
  const [hoverPicture, setHoverPicture] = useState(false);
  const [hoverDescription, setHoverDescription] = useState(false);

  const [drafts, setDrafts] = useState(false);
  const [pendingApprovals, setPendingApprovals] = useState(false);
  const [personalDetails, setPersonalDetails] = useState(true);
  const [bankDetails, setBankDetails] = useState(false);
  const [sales, setSales] = useState(false);

  const [profilePicture, setProfilePicture] = useState(null);
  const [descriptionBox, setDescriptionBox] = useState(false);
  const [isPaypal, setIsPaypal] = useState(seller?.bankaccount ? (seller.bankaccount.isPaypal ? true : false) : true);
  const [isIndividual, setIsIndividual] = useState(seller?.is_individual ? true : false);
  const [totalSoldCount, setTotalSoldCount] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [monthlySales, setMonthlySales] = useState([]);
  const pictureRef = useRef();
  const breakpoint = useCurrentBreakpointName();
  // const [userCountry,setUserCountry] = useState(null)

  useEffect(() => {
    dispatch(adminActions.loadProducts());
    // dispatch(adminActions.loadDraftProducts());
  }, []);

  // New Products
  const products = useSelector((state) => state.adminReducer.products);
  // const draftProducts = products.filter(
  //   (product) => product.created_by === user.id && product.is_in_draft === true
  // );
  // const underApprovalProducts = products.filter(
  //   (product) =>
  //     product.created_by === user.id &&
  //     product.is_in_draft === false &&
  //     product.is_verified === false
  // );

  // Updated Products
  // const updatedProducts = useSelector(
  //   (state) => state.adminReducer.draftproducts
  // );
  // const draftUpdatedProducts = updatedProducts.filter(
  //   (product) =>
  //     product.productdraft_created_by === user.id &&
  //     product.is_in_draft === true
  // );
  // const underApprovalUpdatedProducts = updatedProducts.filter(
  //   (product) =>
  //     product.productdraft_created_by === user.id &&
  //     product.is_in_draft === false &&
  //     product.is_verified === false
  // );

  const myProducts = products.filter(
    (product) => product.created_by === user.id
  );
  //consolele.log("myProducts", myProducts);

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setNext1(true);
    const infoData = new FormData(form.current);
    infoData.append("region", region);
    const gstData = new FormData(gstform.current);
    const infoResponse = await actions.updateProfile(infoData, user.id);
    const gstResponse = await actions.updateProfile(gstData, user.id);
    const object = {}
    infoData.forEach((value, key) => object[key] = value);
    gstData.forEach((value, key) => object[key] = value)
    // console.log("SUBMISSION", object)
    const response = await actions.updateProfile(object, user.id);
    if (response) {
      dispatch(actions.profileUpdate(response));
      setNext1(false);
    }
  };

  const handleBussInfoUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(form2.current);
    formData.append("is_individual", isIndividual)
    formData.append('country',region)
    setNext2(true);
    const response = await actions.updateSeller(formData, seller.id);
    if (response) {
      setNext2(false);
      dispatch(actions.getMySellerProfile(user.id));
    }
    setNext2(false);
  };

  const handleBankDetailsSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(form3.current);
    if (
      formData.get("account_number") === formData.get("confirm_account_number")
    ) {
      setNext3(true);
      if (isPaypal) {
        formData.append("isPaypal", true);
      } else if (!isPaypal) {
        formData.append("isPaypal", false);
      }
      formData.append("seller", seller.id);
      formData.append("account_created_by", user.id);
      formData.append("account_modified_by", user.id);
      if (seller.bankaccount) {
        const response = await actions.updateBankAccount(formData, seller.bankaccount.id)
        if (response) {
          setNext3(false);
          dispatch(actions.getMySellerProfile(user.id));
        }
      } else {
        const response = await actions.createBankAccount(formData);
        if (response) {
          setNext3(false);
          dispatch(actions.getMySellerProfile(user.id));
        }
      }
    } else {
      toast.error("Account Number doesn't match");
    }
  };

  const handleSellerPictureUpdate = async () => {
    const formData = new FormData();
    formData.append("picture", profilePicture);
    const response = await actions.updateSellerPicture(formData, seller.id);
    if (response) {
      dispatch(actions.getMySellerProfile(user.id));
    }
  };

  const handleSellerDescriptionUpdate = async (e) => {
    e.preventDefault();
    setNext1(true);
    const formData = new FormData(form4.current);
    const response = await actions.updateSeller(formData, seller.id);
    if (response) {
      setNext1(false);
      setDescriptionBox(false);
      dispatch(actions.getMySellerProfile(user.id));
    }
  };

  useEffect(async () => {
    const data = { user_id: user.id };
    const response_soldcount = await actions.totalSoldCount(data);
    if (response_soldcount) {
      setTotalSoldCount(response_soldcount["total_sold_count"]);
    }

    if (seller.id) {
      const response_earnings = await actions.totalEarnings(seller.id);
      if (response_earnings) {
        setTotalEarnings(response_earnings["total_earnings"]);
      }

      const response_sales = await actions.monthlySales(seller.id);
      if (response_sales) {
        setMonthlySales(response_sales);
      }
    }
  }, []);

  const handleGstInfoUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(gstform.current);
    const response = await actions.updateProfile(formData, user.id);
    if (response) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profilePicture) {
      handleSellerPictureUpdate();
    }
  }, [profilePicture]);


  return (
    <>
      <Breakpoint large up>
        <Container
          id="demoForm"
          className="p-lg-5 p-3 home-background-categories"
          fluid
          style={{ paddingTop: 100 }}
        >
          <div
            className={`${seller?.id && breakpoint !== "xsmall"
              ? "d-none"
              : "w-100"
              }`}
          >
            <div className="d-flex justify-content-center align-items-center w-100">
              <Col lg={4} className="d-flex justify-content-center mt-5 mx-2">
                <BasicInformation
                  user={user}
                  form={form}
                  handleProfileSubmit={handleProfileSubmit}
                  next={next1}
                  region={region}
                  setRegion={setRegion}
                />
              </Col>
              <Col lg={4} className="d-flex justify-content-center mt-5 mx-2">
                <RCard style={{ borderRadius: 40, border: "1px solid #3d3d3d", maxWidth: 600, minHeight: 573, backgroundColor: "#141415" }}>
                  <div className="h4 mt-4 text-center text-white">Gst Details</div>
                  <hr className="text-white" />
                  <GSTInformation
                    form={gstform}
                    handleGstInfoUpdate={handleGstInfoUpdate}
                    gstForm={true}
                    loading={loading}
                    mode="update"
                    user={user}
                    profileGst={true}
                  />
                </RCard>
              </Col>
            </div>
            <div className="text-center mt-4">
              <RButton
                className="btn btn-link border-0 wrapped-button"
                style={{ background: "transparent", textDecoration: "none" }}
                type="submit"
              >
                <RButton
                  className="my-3 btn btn-primary  explore-button-no-neo"
                  variant="dark"
                  disabled={next1}
                  size="large"
                  onClick={handleProfileSubmit}
                >
                  {next1 ? (
                    <span>
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      SAVING...
                    </span>
                  ) : (
                    <span className="poppins">SAVE CHANGES</span>
                  )}
                </RButton>
              </RButton>
            </div>
          </div>
          <div
            className={`${seller?.id && breakpoint !== "xsmall" ? "mt-5" : "d-none"
              }`}
          >
            <Container
              className="home-background-categories"
              // className="home-background-categories"
              fluid
              style={styles.parentContainer}
            >
              <Container
                className="mt-5 px-5"
                style={styles.descriptionDiv}
                fluid
                onMouseLeave={() => {
                  setHoverDescription(false);
                  setHoverPicture(false);
                }}
                onMouseEnter={() => {
                  setHoverDescription(true);
                }}
              >
                <Container
                  className="position-relative"
                  style={{ width: "90%" }}
                  fluid
                >
                  <Row
                    className="text-white position-absolute"
                    style={{ left: 0, right: 0, marginTop: -140 }}
                  >
                    <Col sm={2}>
                      <div
                        onMouseEnter={() => setHoverPicture(true)}
                        onMouseLeave={() => setHoverPicture(false)}
                      >
                        <RCard
                          style={styles.imageCardDiv}
                          dark={true}
                          className="d-flex"
                        >
                          <Image
                            src={seller?.picture ? seller.picture : profilepicture}
                            alt="Profile Picture"
                            style={styles.imageDiv}
                          />
                          <input
                            ref={pictureRef}
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={(e) => {
                              //consolele.log("Picture:", e);
                              setProfilePicture(e.target.files[0]);
                            }}
                          />
                          {hoverPicture && (
                            <button
                              className="d-flex justify-content-center align-items-center explore-icon-profile-button position-absolute"
                              style={{ marginTop: 10, left: 120, maxWidth: "35px", maxHeight: "35px" }}
                              onClick={() => pictureRef.current.click()}
                            >
                              <FiEdit2 size={15} />
                            </button>
                          )}
                        </RCard>
                      </div>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="d-flex">
                          <div className="h4">{seller?.business_name}</div>
                        </Col>
                        <Col className="d-flex">
                          <FiPhoneCall className="ms-5" size={25} />
                          <div className="ms-4 subtitle">
                            {seller?.phone_number}
                          </div>
                        </Col>
                        <Col className="d-flex">
                          <FiMail className="ms-5" size={25} />
                          <div className="ms-4 subtitle">{seller?.email}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row
                            className="border-R20 text-center"
                            style={styles.statsDiv}
                          >
                            <Col sm={4} className="py-2">
                              <div className="subtitle pb-1">
                                {myProducts.length}
                              </div>
                              <div className="body-text2">Total Items</div>
                            </Col>
                            <Col sm={4} className="py-2">
                              <div className="subtitle pb-1">{totalSoldCount}</div>
                              <div className="body-text2">Total Sales</div>
                            </Col>
                            <Col sm={4} className="py-2">
                              <div className="subtitle pb-1">{totalEarnings}$</div>
                              <div className="body-text2">Total Earnings</div>
                            </Col>
                          </Row>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Col>
                  </Row>
                  {hoverDescription && (
                    <button
                      className="d-flex justify-content-center align-items-center explore-icon-profile-button position-absolute"
                      style={{ marginTop: -80, right: -50 }}
                      onClick={() => setDescriptionBox(true)}
                    >
                      <FiEdit2 size={20} />
                    </button>
                  )}
                </Container>
                <div
                  className={`${descriptionBox
                    ? "d-none"
                    : "d-flex text-white mt-5 pt-2 mx-5 px-4 w-100 h-100"
                    }`}
                  onMouseEnter={() => {
                    setHoverDescription(true);
                    setHoverPicture(false);
                  }}
                // style={{minHeight: 100}}
                >
                  {/* <p className="text-white mt-5 pt-2 mx-5 px-4 w-100"
                onMouseEnter={() => {
                  setHoverDescription(true);
                  setHoverPicture(false);
                }}
                style={{ wordBreak: "break-all" }}
              > */}
                  {seller?.business_description
                    ? seller.business_description
                    : "Tell us something about your Business!"}
                  {/* </p> */}
                </div>
                <div
                  className={`${descriptionBox ? "mt-5 mx-5 px-4 pt-2" : "d-none"}`}
                >
                  <Form
                    ref={form4}
                    onSubmit={(e) => handleSellerDescriptionUpdate(e)}
                  >
                    <Card className="mt-5 textarea-container" dark={true} flat>
                      <textarea
                        required
                        name="business_description"
                        className="form-control label textarea-input-box text-white"
                        id="review"
                        placeholder="Tell us something about your Business!"
                        defaultValue={
                          seller?.business_description
                            ? seller.business_description
                            : ""
                        }
                        rows="4"
                        style={{ backgroundColor: "#333333" }}
                      // onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </Card>
                    <div className="text-center mt-3 d-flex justify-content-end">
                      <RButton
                        className="explore-button-no-neo"
                        disabled={next1}
                        dark={true}
                        size="large"
                        type="submit"
                      >
                        {next1 ? (
                          <span>
                            <Spinner
                              className="me-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            SUBMITTING ...
                          </span>
                        ) : (
                          <span className="poppins">SUBMIT</span>
                        )}
                      </RButton>
                      <RButton
                        className="ms-4 explore-gray-border-button-no-neo"
                        variant="dark"
                        disabled={next1}
                        size="large"
                        onClick={() => setDescriptionBox(false)}
                      >
                        CANCEL
                      </RButton>
                    </div>
                  </Form>
                </div>
              </Container>
              <div className="d-flex justify-content-start mt-5 mx-2">
                <RButton
                  // style={{ width: "222px", height: "48px" }}
                  className={`${personalDetails ? "explore-button-no-neo" : "explore-gray-border-button-no-neo"
                    } p-4 me-5`}
                  variant="dark"
                  onClick={() => {
                    setDrafts(false);
                    setPendingApprovals(false);
                    setSales(false);
                    setPersonalDetails(true);
                    setBankDetails(false);
                  }}
                >
                  PERSONAL DETAILS
                </RButton>
                <RButton
                  // style={{ width: "189px", height: "48px" }}
                  className={`${bankDetails ? "explore-button-no-neo" : "explore-gray-border-button-no-neo"
                    } p-4 me-5`}
                  variant="dark"
                  onClick={() => {
                    setDrafts(false);
                    setPendingApprovals(false);
                    setSales(false);
                    setPersonalDetails(false);
                    setBankDetails(true);
                  }}
                >
                  BANK DETAILS
                </RButton>
                <RButton
                  // style={{ width: "222px", height: "48px" }}
                  className={`${sales ? "explore-button-no-neo" : "explore-gray-border-button-no-neo"
                    } p-4`}
                  variant="dark"
                  onClick={() => {
                    setDrafts(false);
                    setPendingApprovals(false);
                    setSales(true);
                    setPersonalDetails(false);
                    setBankDetails(false);
                  }}
                >
                  SALES BY MONTHS
                </RButton>
              </div>
              <Form ref={form2} onSubmit={(e) => handleBussInfoUpdate(e)}>
                <Container
                  className={`${personalDetails ? "mt-5 px-5" : "d-none"}`}
                  style={styles.bankAccountDiv}
                  fluid
                >
                  <span className="d-block ms-5 mb-2 body-text3 text-white">I am</span>
                  <RCard className="ms-5 py-1 d-flex flex-row justify-content-center align-items-center" style={{ backgroundColor: "#0D0D0D", border: "1px solid #3d3d3d", borderRadius: "5px", maxWidth: "280px" }}>
                    <RButton
                      className="wrapped-button ms-1 my-1 py-2 body-text3"
                      style={isIndividual ? styles.selectedModeButton : styles.unselectedModeButton}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsIndividual(true);
                      }}
                    >
                      Individual
                    </RButton>
                    <RButton
                      className="wrapped-button my-1 py-2 body-text3"
                      style={!isIndividual ? styles.selectedModeButton : styles.unselectedModeButton}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsIndividual(false);
                      }}
                    >
                      Business
                    </RButton>
                  </RCard>
                  <BusinessInformation
                    user={user}
                    seller={seller}
                    region ={region}
                    setRegion ={setRegion}
                    // mode="update"
                    // next={next2}
                    asBusiness={!isIndividual}
                  // form={form2}
                  // handleBussInfoUpdate={handleBussInfoUpdate}
                  />
                </Container>
                <div className="d-flex justify-content-center align-items-center">
                  <RButton
                    className={`${personalDetails ? "mt-5 explore-button-no-neo" : "d-none"}`}
                    type="submit"
                  >
                    {next2 ? (
                      <span>
                        <Spinner
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        SAVING...
                      </span>
                    ) : (
                      <span className="poppins">SAVE CHANGES</span>
                    )}
                  </RButton>
                </div>
              </Form>
              <Container
                className={`${sales ? "mt-5 px-3 text-white text-center" : "d-none"
                  } py-0`}
                style={styles.descriptionDiv}
                fluid
              >
                <SalesTable monthlySales={monthlySales} />
              </Container>
              <Form ref={form3} onSubmit={(e) => handleBankDetailsSubmit(e)}>
                <Container
                  className={`${bankDetails ? "mt-5 px-5" : "d-none"}`}
                  style={styles.bankAccountDiv}
                  fluid
                >
                  <span className="d-block ms-5 mb-2 body-text3 text-white">I preferred</span>
                  <RCard className="ms-5 py-1 d-flex flex-row justify-content-center align-items-center" style={{ backgroundColor: "#0D0D0D", border: "1px solid #3d3d3d", borderRadius: "5px", maxWidth: "280px" }}>
                    <RButton
                      className="wrapped-button ms-1 my-1 py-2 body-text3"
                      style={isPaypal ? styles.selectedModeButton : styles.unselectedModeButton}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsPaypal(true);
                      }}
                    >
                      Paypal
                    </RButton>
                    <RButton
                      className="wrapped-button my-1 py-2 body-text3"
                      style={!isPaypal ? styles.selectedModeButton : styles.unselectedModeButton}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsPaypal(false);
                      }}
                    >
                      Wire Transfer
                    </RButton>
                  </RCard>
                  <BankAccount
                    seller={seller}
                    // mode={seller.bankaccount ? "update" : "create"}
                    // form={form3}
                    isPaypal={isPaypal}
                  // next={next3}
                  // handleBankDetailsUpdate={handleBankDetailsUpdate}
                  />
                </Container>
                <div className="d-flex justify-content-center align-items-center">
                  <RButton
                    className={`${bankDetails ? "mt-5 explore-button-no-neo" : "d-none"}`}
                    type="submit"
                  >
                    {next3 ? (
                      <span>
                        <Spinner
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        SAVING...
                      </span>
                    ) : (
                      <span className="poppins">SAVE CHANGES</span>
                    )}
                  </RButton>
                </div>
              </Form>
            </Container>
          </div>
        </Container >
      </Breakpoint >

      <Breakpoint medium down>
        <Container
          id="demoForm"
          className=" home-background-categories"
          fluid
          style={{ paddingTop: 85, paddingRight: 0, paddingLeft: 0 }}
        >
          <div className="w-100">
            <div className="d-flex justify-content-center ">
              <BasicInformation
                user={user}
                form={form}
                handleProfileSubmit={handleProfileSubmit}
                next={next1}
                region={region}
                setRegion={setRegion}
              />
            </div>
            <div className="w-100 mt-5">
              <RCard className="w-100" style={{ border: "1px solid #3d3d3d", backgroundColor: "#141415" }}>
                <div className="h3 poppins-500   mt-4 text-center text-white">Gst Details</div>
                <hr className="text-white" />
                <GSTInformation
                  form={gstform}
                  handleGstInfoUpdate={handleGstInfoUpdate}
                  gstForm={true}
                  loading={loading}
                  mode="update"
                  user={user}
                  profileGst={true}
                />
              </RCard>
              <div>
              </div>
              <div className="text-center mt-4">
                <RButton
                  className="btn btn-link border-0 wrapped-button"
                  style={{ background: "transparent", textDecoration: "none" }}
                  type="submit"
                >
                  <RButton
                    className="my-5 btn btn-primary  explore-button-no-neo"
                    variant="dark"
                    disabled={next1}
                    size="large"
                    onClick={handleProfileSubmit}
                  >
                    {next1 ? (
                      <span>
                        <Spinner
                          className="me-2 poppins-500"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        SAVING...
                      </span>
                    ) : (
                      <span className="poppins-500">SAVE CHANGES</span>
                    )}
                  </RButton>
                </RButton>
              </div>
            </div>
          </div>
        </Container >
      </Breakpoint >
    </>
  );
};
const styles = {
  parentContainer: {
    minHeight: "100vh",
    paddingTop: 50,
  },
  descriptionDiv: {
    borderRadius: "40px",
    border: "1px solid #3D3D3D",
    backgroundColor: "#141415",
    padding: 100,
  },
  bankAccountDiv: {
    borderRadius: "40px",
    border: "1px solid #3D3D3D",
    backgroundColor: "#141415",
    padding: 40,
  },
  imageDiv: {
    borderRadius: "20px",
    maxWidth: 155,
    maxHeight: 155,
    marginTop: "2px"
  },
  imageCardDiv: {
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    backgroundColor: "#1F1F1F",
    borderColor: "#3D3D3D",
    width: 160,
    height: 160,
  },
  statsDiv: {
    borderRadius: "10px",
    backgroundColor: "#1f1f1f",
    border: "1px solid #3D3D3D",
    marginTop: 30,
    padding: 10,
  },
  selectedModeButton: {
    width: "130px",
    backgroundColor: "#fff",
    border: "none",
    color: "black",
  },
  unselectedModeButton: {
    width: "130px",
    backgroundColor: "#0D0D0D",
    border: "none",
    color: "white",
  },
  underline: {
    height: 2,
    backgroundColor: "#292929",
  },
};
export default Profile;

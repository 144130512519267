import React, { useEffect, useState } from "react";
import { Row, Col, Image, Button as RButton, Card } from "react-bootstrap";
import { sample_blog } from "assets";
import { MdChevronRight } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { Breakpoint } from "react-socks";

const BlogCard = ({ blog, read_url }) => {
  const { title, content, published_date, author_name, read_time, blog_image } =
    blog;

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  return (
    <>
      <Col lg={4}>
        <Breakpoint large up>
          <a
            href={read_url}
            onClick={() => handleNavigationClick(read_url)}
            className="text-decoration-none"
            state={{ previousPage: "seller" }}
          >
            <Card
              className="border-0 blog-card overflow-hidden d-flex justify-content-center align-items-center mt-5"
              dark={true}
              outlined
              style={{
                marginTop: 32,
                height: 320,
                border: "1px solid #3D3D3D",
              }}
            >
              <Image
                style={{ borderRadius: "20px" }}
                className="position-absolute mx-auto h-100"
                src={blog_image ? blog_image : null}
                alt={title}
              />
            </Card>
            <div className="w-100" style={{ zIndex: 10 }}>
              <div
                className="text-center text-white mt-3 p-4"
                style={styles.titleBox}
              >
                {title}
              </div>
            </div>
          </a>
        </Breakpoint>
      </Col>
      <Breakpoint medium down>
        <a
          href={read_url}
          onClick={() => handleNavigationClick(read_url)}
          className="text-decoration-none"
        >
          <Card
            className="border-0 blog-card overflow-hidden d-flex justify-content-center align-items-center mb-0 pb-0"
            style={{
              borderRadius: "20px",
              height: 184,
              border: "1px solid #3D3D3D",
            }}
          >
            <Image
              style={{ height: 288 }}
              className="position-absolute w-100"
              src={blog_image ? blog_image : null}
              alt={title}
            />
          </Card>
          <div className="w-100 mt-0 pt-0 mb-4" style={{ zIndex: 10 }}>
            <div
              className="text-center text-white mt-2 p-4"
              style={styles.titleBox}
            >
              {title}
            </div>
          </div>
        </a>
      </Breakpoint>
    </>
  );
};

const styles = {
  titleBox: {
    backgroundColor: "#141415",
    borderRadius: 10,
    fontSize: 12,
    border: "1px solid #3D3D3D",
  },
};

export default BlogCard;

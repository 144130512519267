import React, { useState, useRef, useEffect } from "react";
import { Col, Form, Spinner, Button as RButton, Image } from "react-bootstrap";
import { Button, Card, CardAction, IconButton, Badge } from "ui-neumorphism";
import { toast } from "react-toastify";
import { AiOutlineClose, AiOutlineCheck, AiOutlineLeft } from "react-icons/ai";
import { emptycart, znanyelogin, greentick, greytick } from "assets";
import { Breakpoint } from "react-socks";

const GSTInformation = (props) => {
  const { form, handleGstInfoUpdate, gstForm, loading, mode, user, profileGst=false } = props;
  // console.log(user);

  useEffect(() => {
    console.log(mode)
   
  }, [mode]);
  return (
    <>
  <Breakpoint large up>
    <Form
      ref={form}
      className={`${mode === "update" ? "px-5 py-4 my-3" : "px-4 my-3"}body-text2 text-white`}
      onSubmit={(e) => {
        e.preventDefault();
        if (user.phone_code === "+91" || user.phone_code === "91") {
          if (e.target[0].value.length === 15) {
            handleGstInfoUpdate(e);
          } else {
            toast.error("Enter correct GST number");
          }
        } else {
          handleGstInfoUpdate(e);
        }
      }}
    >
      {/* <div className={`${gstForm ? "d-block" : "d-none"}`}> */}
      <Form.Group className="text-white  font-size18 mb-3">
        {mode === "update" && ""}
        {mode != "update" &&
          mode != "createmobile" &&
          "Please enter the following details"}

        {mode === "createmobile" && (
          <>
            <div className="font-size18 mx-1 my-4 poppins-500">
              {/* <AiOutlineLeft size={20}/> */}
              Please enter the following details
            </div>
          </>
        )}
      </Form.Group>
      {/* <div> */}
      <Form.Group className="mb-4 text-white">
        <Form.Label className="text-white body-text2">
          GST Number(VAT Number)
        </Form.Label>
        <Form.Control
          required
          name="gst_number"
          type="text"
          placeholder="000000000000000"
          defaultValue={user ? user.gst_number : ""}
          className="text-white"
          style={{
            background: "#0d0d0d",
            height: 50,
            border: "1px solid #292929",
            borderRadius: "10px",
          }}
          maxLength="15"
        />
      </Form.Group>
      <Form.Group className="mb-4 text-white">
        <Form.Label className="text-white body-text2">Business Name</Form.Label>
        <Form.Control
          required
          name="business_name"
          type="text"
          placeholder="pastelcube technologies"
          defaultValue={user ? user.business_name : ""}
          className="text-white"
          style={{
            background: "#0d0d0d",
            height: 50,
            border: "1px solid #292929",
            borderRadius: "10px",
          }}
        />
      </Form.Group>
      <Form.Group className="mb-4 text-white">
        <Form.Label className="text-white body-text2">
          Billing Address
        </Form.Label>
        <Form.Control
          required
          name="billing_address"
          as="textarea"
          rows={5}
          placeholder="Pune"
          defaultValue={user ? user.billing_address : ""}
          className="text-white"
          style={{
            background: "#0d0d0d",
            border: "1px solid #292929",
            borderRadius: "10px",
          }}
        />
      </Form.Group>
      {/* </div> */}
      {!profileGst && <div className="text-center">
        <RButton
          className="btn btn-link border-0 wrapped-button"
          style={{ background: "transparent", textDecoration: "none" }}
          type="submit"
        >
          <RButton
            className="body-text2 btn btn-ptimary explore-button-no-neo mb-3"
            variant="dark"
            disabled={loading}
            size="lg"
            type="submit"
          >
            {loading ? (
              <span>
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {mode === "update" ? "UPDATING..." : "SUBMITING..."}
              </span>
            ) : (
              <span className="poppins">
                {mode === "update" ? "UPDATE" : "THAT'S IT"}
              </span>
            )}
          </RButton>
        </RButton>
      </div>}
    </Form>
    </Breakpoint>
    <Breakpoint medium down   >
    <Form
      ref={form}
      className={`${mode === "update" ? " py-4 my-3" : " my-3"}body-text2 text-white`}
      onSubmit={(e) => {
        e.preventDefault();
        if (user.phone_code === "+91" || user.phone_code === "91") {
          if (e.target[0].value.length === 15) {
            handleGstInfoUpdate(e);
          } else {
            toast.error("Enter correct GST number");
          }
        } else {
          handleGstInfoUpdate(e);
        }
      }}
    >
      {/* <div className={`${gstForm ? "d-block" : "d-none"}`}> */}
      <Form.Group className="text-white  font-size18 mb-3">
        {mode === "update" && ""}
        {mode != "update" &&
          mode != "createmobile" &&
          "Please enter the following details"}

        {mode === "createmobile" && (
          <>
            <div className="font-size18 mx-1 my-4 poppins-500">
              {/* <AiOutlineLeft size={20}/> */}
              Please enter the following details
            </div>
          </>
        )}
      </Form.Group>
      {/* <div> */}
      <Form.Group className="mb-4 mx-3 text-white">
        <Form.Label className="text-white  font-size18">
          GST Number(VAT Number)
        </Form.Label>
        <Form.Control
          required
          name="gst_number"
          type="text"
          placeholder="000000000000000"
          defaultValue={user ? user.gst_number : ""}
          className="text-white"
          style={{
            background: "#0d0d0d",
            height: 50,
            border: "1px solid #292929",
            borderRadius: "10px",
          }}
          maxLength="15"
        />
      </Form.Group>
      <Form.Group className="mb-4  mx-3 text-white">
        <Form.Label className="text-white font-size18 ">Business Name</Form.Label>
        <Form.Control
          required
          name="business_name"
          type="text"
          placeholder="pastelcube technologies"
          defaultValue={user ? user.business_name : ""}
          className="text-white"
          style={{
            background: "#0d0d0d",
            height: 50,
            border: "1px solid #292929",
            borderRadius: "10px",
          }}
        />
      </Form.Group>
      <Form.Group className="mb-4   mx-3 text-white">
        <Form.Label className="text-white font-size18 ">
          Billing Address
        </Form.Label>
        <Form.Control
          required
          name="billing_address"
          as="textarea"
          rows={5}
          placeholder="Pune"
          defaultValue={user ? user.billing_address : ""}
          className="text-white"
          style={{
            background: "#0d0d0d",
            border: "1px solid #292929",
            borderRadius: "10px",
          }}
        />
      </Form.Group>
      {/* </div> */}
      {!profileGst && <div className="text-center">
        <RButton
          className="btn btn-link border-0 wrapped-button"
          style={{ background: "transparent", textDecoration: "none" }}
          type="submit"
        >
          <RButton
            className="body-text2 btn btn-ptimary explore-button-no-neo mb-3"
            variant="dark"
            disabled={loading}
            size="lg"
            type="submit"
          >
            {loading ? (
              <span>
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {mode === "update" ? "UPDATING..." : "SUBMITING..."}
              </span>
            ) : (
              <span className="poppins">
                {mode === "update" ? "UPDATE" : "THAT'S IT"}
              </span>
            )}
          </RButton>
        </RButton>
      </div>}
    </Form>
    </Breakpoint>


    </>
  );
};

export default GSTInformation;

import React, { useState, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Container, Navbar, Nav, Row, Col } from "react-bootstrap";
import "core-ui/pages/Cart.scss";
import { useSelector } from "react-redux";

const Cart = () => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";
  //consolelog("location", location);

  const user = useSelector((state) => state.authReducer.user);
  const cartProducts = user.cartproducts;

  return (
    <>
      <Outlet />
    </>
  );
};

const styles = {
  progressNavBar: {
    minHeight: "23vh",
  },
  connector: {
    height: 2,
    flex: 1,
  },
  currentPage: {
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
  },
};

export default Cart;

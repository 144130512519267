import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Row,
  Col,
  Button as BootstrapButton,
  Card,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import { Button, IconButton, CardAction } from "ui-neumorphism";
import { Link } from "react-router-dom";
import { download, imagenotavailable, raiserefund, znanyelogin } from "assets";
import * as actions from "actions/AuthActions";
import { useSelector } from "react-redux";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";
import getSymbolFromCurrency from "currency-symbol-map";
import { Breakpoint } from "react-socks";

const DownloadProduct = ({
  orderproduct,
  fileExtensions,
  refundableItems,
  index,
  timeLeft,
  item,
  type,
}) => {
  const [downloading, setDownloading] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundProducts, setRefundProducts] = useState([]);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const currency = useSelector((state) => state.authReducer.currency);
  const productFileDownload = async (version, productId, productName) => {
    setDownloading(true);
    const response = await actions.downloadProductFile(
      version,
      productId,
      productName
    );
    if (response) {
      setDownloading(false);
    }
  };

  let message =
    "Hello Team I would like to discuss a refund on the following products: \n\n";
  for (let i = 0; i < refundProducts.length; i++) {
    message += refundProducts[i] + "        ";
  }

  //   useEffect(() => {
  //     if (item?.payment_paid_timestamp) {
  //       const paymentTime = moment(item?.payment_paid_timestamp).add(7, "days");
  //       setTimeLeft(moment(paymentTime).diff(moment(today), "seconds") * 1000);
  //     }
  //   }, [item]);

  switch (type) {
    case "mobile":
      return (
        <>
          <div
            className={`${index === 0 ? "d-none" : "d-block w-100"}`}
            style={styles.orderDivision}
          />
          <div className="d-flex">
            <div className="d-flex flex-column">
              <a
                href={`/3dmodels/${orderproduct?.product?.category_data?.slug}/${orderproduct?.product?.slug}`}
                onClick={() =>
                  handleNavigationClick(
                    `/3dmodels/${orderproduct?.product?.category_data?.slug}/${orderproduct?.product?.slug}`
                  )
                }
              >
                <Card
                  className="ms-4 mt-4 d-flex justify-content-center align-items-center cursor-pointer"
                  style={styles.itemImageMobileCard}
                  dark={true}
                >
                  <Image
                    src={
                      orderproduct.product?.productimage_set.length !== 0
                        ? orderproduct.product?.productimage_set[0]?.image_file
                        : imagenotavailable
                    }
                    alt={orderproduct.product?.title}
                    style={{ maxWidth: 100, maxHeight: 100 }}
                  />
                </Card>
              </a>
              <h4 className="text-primary mt-4 ms-4 text-center">
                {orderproduct?.coupon_code ? (
                  orderproduct?.coupon_category == "ABSOLUTE" ? (
                    getSymbolFromCurrency(currency.name) +
                    (
                      (orderproduct?.price - orderproduct?.coupon_flat_off) *
                      currency.rate
                    ).toFixed(2)
                  ) : (
                    getSymbolFromCurrency(currency.name) +
                    (
                      (orderproduct?.price -
                        (orderproduct?.price * orderproduct?.coupon_flat_off) /
                          100) *
                      currency.rate
                    ).toFixed(2)
                  )
                ) : (
                  <p>
                    {getSymbolFromCurrency(currency.name) +
                      (orderproduct?.price * currency.rate).toFixed(2)}
                  </p>
                )}
              </h4>
              <strike
                className={`${
                  orderproduct?.coupon_code ? "d-block" : "d-none"
                } text-white ms-4 text-center`}
              >
                {getSymbolFromCurrency(currency.name) +
                  (orderproduct?.product?.price * currency.rate).toFixed(2)}
              </strike>
            </div>
            <div className="ms-3 mt-4">
              <div className="body-text2 text-white">Product Name</div>
              <div className="subtitle text-white mb-4">
                {orderproduct?.product?.title}
              </div>
              <div className="body-text2 text-white">File Format Available</div>
              <div className="subtitle text-white mb-4">
                {fileExtensions?.map((extension) => {
                  return <>{extension} </>;
                })}
              </div>
              <div className="body-text2 text-white">Size</div>
              <div className="subtitle text-white mb-1">
                {orderproduct?.product?.file_statistics?.total_size}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center px-3 pb-3">
            <div className="mt-3 col-6 mx-1">
              {item.order_status == "PL" ? (
                <a
                  href={`/3dmodels/${orderproduct?.product?.category_data?.slug}/${orderproduct?.product?.slug}#review`}
                  onClick={() =>
                    handleNavigationClick(
                      `/3dmodels/${orderproduct?.product?.category_data?.slug}/${orderproduct?.product?.slug}#review`
                    )
                  }
                  style={{ textDecoration: "none" }}
                >
                  <BootstrapButton
                    className="h-100 w-100 text-white"
                    style={{
                      backgroundColor: "#292929",
                      border: "0px",
                      fontFamily: "poppins",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    WRITE A REVIEW
                  </BootstrapButton>
                </a>
              ) : (
                <></>
              )}
            </div>
            {item.order_status != "CL" && (
              <div className="mt-3 mx-1 col-6">
                {item.order_status == "PL" ? (
                  <BootstrapButton
                    className="explore-button-order btn btn-primary w-100 h-100 text-white"
                    disabled={downloading}
                    dark={true}
                    style={{ border: "0px" }}
                    onClick={() =>
                      productFileDownload(
                        orderproduct?.version,
                        orderproduct?.product?.id,
                        orderproduct?.product?.title
                      )
                    }
                  >
                    {downloading ? (
                      <span>
                        <Spinner
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        DOWNLOAD
                      </span>
                    ) : (
                      <span className="poppins">DOWNLOAD</span>
                    )}
                  </BootstrapButton>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </>
      );
  }

  return (
    <>
      <Breakpoint large up>
        <div
          className={`${index === 0 ? "d-none" : "d-block"}`}
          style={styles.orderDivision}
        />
        <Row>
          <Col
            sm="auto"
            // as={Link}
            // to={`/3dmodels/${orderproduct?.product?.category_data?.slug}/${orderproduct?.product?.slug}`}
            onClick={() =>
              handleNavigationClick(
                `/3dmodels/${orderproduct?.product?.category_data?.slug}/${orderproduct?.product?.slug}`
              )
            }
            style={{ textDecoration: "none" }}
          >
            <Card
              className="mt-5 ms-5 d-flex justify-content-center align-items-center border-0 cursor-pointer"
              style={styles.itemImageCard}
              dark={true}
              outlined
            >
              <Image
                src={
                  orderproduct.product?.productimage_set.length !== 0
                    ? orderproduct.product?.productimage_set[0]?.image_file
                    : imagenotavailable
                }
                alt={orderproduct?.title}
                style={{
                  maxWidth: 95,
                  maxHeight: 95,
                  borderRadius: 10,
                }}
              />
            </Card>
          </Col>
          <Col sm="5" className="mt-5 body-text2">
            <p>
              Product Name - <b>{orderproduct?.title}</b>
            </p>
            <p>
              File Formats -{" "}
              <b>
                {fileExtensions.map((extension) => {
                  return <>{extension} </>;
                })}
              </b>
            </p>
            <p>
              File Size -{" "}
              <b>{orderproduct?.product?.file_statistics?.total_size}</b>
            </p>
          </Col>
          <Col
            sm={{ span: 3, offset: 1 }}
            className="mt-5 d-flex justify-content-start "
          >
            <div>
              <div
                className={`${
                  index === 0 ? "d-flex justify-content-between" : "d-none"
                }`}
              >
                {refundableItems.length > 0 && item.order_status == "PL" && (
                  <div className="d-flex justify-content-between align-items-center body-text2">
                    <div
                      className={`${
                        timeLeft > 0
                          ? "d-flex justify-content-center align-items-center mb-3"
                          : "d-none"
                      }`}
                    >
                      <span>Raise Refund - &nbsp;</span>
                      <b>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowRefundModal(true);
                          }}
                          style={{ textDecoration: 0 }}
                        >
                          <span className="mx-2 text-primary">Apply</span>
                          <Image
                            src={raiserefund}
                            alt="Raise Fund"
                            width="20"
                          />
                          &nbsp;&nbsp;
                        </a>
                      </b>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center body-text2">
                <div>Price -</div>
                <div className="d-flex">
                  <div className="text-primary mb-0 text-center body-text-2 poppins-500 mx-2">
                    {orderproduct?.coupon_code ? (
                      orderproduct?.coupon_category == "ABSOLUTE" ? (
                        getSymbolFromCurrency(currency.name) +
                        (
                          (orderproduct?.price -
                            orderproduct?.coupon_flat_off) *
                          currency.rate
                        ).toFixed(2)
                      ) : (
                        getSymbolFromCurrency(currency.name) +
                        (
                          (orderproduct?.price -
                            (orderproduct?.price *
                              orderproduct?.coupon_flat_off) /
                              100) *
                          currency.rate
                        ).toFixed(2)
                      )
                    ) : (
                      <span className="mt-3">
                        {getSymbolFromCurrency(currency.name) +
                          (orderproduct?.price * currency.rate).toFixed(2)}
                      </span>
                    )}
                  </div>
                  <div
                    className={`${
                      orderproduct?.coupon_code ? "d-flex" : "d-none"
                    } flex-column justify-content-center align-items-center`}
                  >
                    <strike className="text-white">
                      {getSymbolFromCurrency(currency.name) +
                        (orderproduct?.price * currency.rate).toFixed(2)}
                    </strike>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          show={showRefundModal}
          onHide={() => setShowRefundModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          backdropClassName="refund-modal-backdrop"
          onExited={() => {
            setShowRefundModal(false);
          }}
        >
          <Modal.Header
            className="d-flex flex-row justify-content-center"
            style={{
              backgroundColor: "#1F1F1F",
              border: "2.5px solid #BEBEBE",
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <Image src={znanyelogin} alt="Znanye Login" className="my-4" />
            </Modal.Title>
          </Modal.Header>
          <IconButton
            size="large"
            className="d-flex justify-content-center removeButtonRight position-absolute text-white"
            dark={true}
          >
            <AiOutlineClose
              size={20}
              onClick={() => setShowRefundModal(false)}
            />
          </IconButton>
          <Modal.Body
            style={{
              border: "2.5px solid #BEBEBE",
              backgroundColor: "#2E2E2E",
            }}
          >
            <Form className="text-start">
              <div>
                <Form.Label className="text-white subtitle mb-3">
                  Initiate Refund
                </Form.Label>
                {refundableItems.map((orderproduct) => {
                  return (
                    <Form.Group className="mb-3 text-white">
                      <Form.Check
                        type="checkbox"
                        label={orderproduct?.product?.title}
                        onClick={() =>
                          setRefundProducts([
                            ...refundProducts,
                            orderproduct?.product?.zsin +
                              " - (" +
                              orderproduct?.product?.title +
                              ")",
                          ])
                        }
                      />
                    </Form.Group>
                  );
                })}
                <div className="text-center">
                  <a
                    href={`mailto:talk2us@znanye.com?subject=Request a Refund&body=${message}`}
                  >
                    <span className="poppins">RAISE REFUND</span>
                  </a>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <div
          className={`${
            item.order_status == "PL" ? "d-block my-3 mx-5 " : "d-none"
          }`}
          style={styles.underline}
        ></div>
        <div
          className={`mx-5 flex-column justify-content-between ${
            item.order_status == "PL" ? "d-flex" : "d-none"
          }`}
        >
          <Row className="">
            <Col sm={6} className="mt-3">
              {item.order_status == "PL" ? (
                <a
                  href={`/3dmodels/${orderproduct?.product?.category_data?.slug}/${orderproduct?.product?.slug}#review`}
                  onClick={() =>
                    handleNavigationClick(
                      `/3dmodels/${orderproduct?.product?.category_data?.slug}/${orderproduct?.product?.slug}#review`
                    )
                  }
                  style={{ textDecoration: "none" }}
                >
                  <BootstrapButton
                    dark={true}
                    className="grey-button text-white"
                  >
                    WRITE A REVIEW
                  </BootstrapButton>
                </a>
              ) : (
                <></>
              )}
            </Col>
            {item.order_status != "CL" && (
              <Col sm={6} className="mt-3 d-flex justify-content-end">
                {item.order_status == "PL" && (
                  <BootstrapButton
                    className="explore-button-no-neo body-text-2 btn btn-primary"
                    disabled={downloading}
                    style={{ border: "0px" }}
                    dark={true}
                    onClick={() =>
                      productFileDownload(
                        orderproduct?.version,
                        orderproduct?.product?.id,
                        orderproduct?.product?.title
                      )
                    }
                  >
                    {downloading ? (
                      <span>
                        <Spinner
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        DOWNLOADING...
                      </span>
                    ) : (
                      <span className="poppins">DOWNLOAD</span>
                    )}
                  </BootstrapButton>
                )}
              </Col>
            )}
          </Row>
          <div className="my-3"></div>
        </div>
      </Breakpoint>
    </>
  );
};
const styles = {
  itemImageCard: {
    width: 95,
    height: 95,
    backgroundColor: "#2E2E2E",
    borderRadius: 10,
  },
  itemImageMobileCard: {
    width: 100,
    height: 100,
    backgroundColor: "#2E2E2E",
  },
  itemPriceCard: {
    width: 130,
    height: 55,
    backgroundColor: "#2E2E2E",
  },
  underline: {
    height: 2,
    backgroundColor: "#292929",
  },
  orderDivision: {
    height: 1,
    width: "100%",
    backgroundColor: "#666666",
  },
  successfulImageCard: {
    width: 80,
    height: 80,
    backgroundColor: "#1f1f1f",
  },
  successfulImage: {
    width: 70,
    height: 70,
  },
  znanyeDownloadButton: {
    width: 10,
    height: 20,
    borderRadius: "4px",
  },
  downloadItem: {
    width: "24px",
    height: "24px",
  },
  cardAction: {
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#111",
  },
};

export default DownloadProduct;

import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import { LoginBox } from "components";
import * as actions from "actions/AuthActions";
import { toast } from "react-toastify";
import { znanyeloader } from "assets";
import { Image } from "react-bootstrap";

const VerifyAccount = () => {
  const { email_hash } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [linkExpired, setLinkExpired] = useState(true);
  const [doesNotExist, setDoesNotExist] = useState(true);
  const [verified, setVerified] = useState(true);
  const [msg, setMsg] = useState("");

  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";

  useEffect(async () => {
      const response = await actions.verifyAccount({ token: email_hash });
      if (response) {
          switch (response.status) {
              case "LINK_EXPIRED":
                  setMsg(response.msg);
                  setLinkExpired(true);
                  break;
              case "DOES_NOT_EXIST":
                  setMsg(response.msg);
                  setDoesNotExist(true);
                  break;
              case "VERIFIED":
                  setMsg("Your account is successfully verified !! You can login now!!");
                  setVerified(true);
                  break;
              default:
                  setLoading(false);
          }
          setLoading(false);
      }
  }, []);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "70vh", paddingTop: 50 }}
      >
        <Image
          src={znanyeloader}
          alt="Znanye Loader"
          loading="lazy"
          style={{ width: "60px", height: "60px" }}
        />
      </div>
    );
  }

  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center my-5 py-5"
      style={styles.mainContainer}
    >
      <div className="text-white pb-5">{msg}</div>
      <LoginBox type={param1} />
    </Container>
  );
};

const styles = {
  mainContainer: {
    minHeight: "100vh",
    paddingTop: 100,
  },
};

export default VerifyAccount;

import React, { useState } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import { CardAction } from "ui-neumorphism";
import { Link } from "react-router-dom";
import { CartItem } from "components";

import { swiftdezire, mitsubishi, tick, znanyeloader } from "assets";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "actions/AuthActions";

import "core-ui/pages/Cart.scss";
import { useEffect } from "react";

const OrderSuccessful = (props) => {
  const dispatch = useDispatch();
  const [thisOrder, setThisOrder] = useState(null);
  const user = useSelector((state) => state.authReducer.user);
  useEffect(() => {
    if (user) {
      dispatch(actions.getMyProfile());
    }
  }, []);
  // let { orderId } = useParams();
  const search = useLocation().search;
  const orderId = new URLSearchParams(search).get("orderId");
  //consolelog(orderId)
  const allOrders = useSelector((state) => state.authReducer.user.orders);
  //consolelog("allOrders", allOrders);

  useEffect(() => {
    if (allOrders) {
      const successfulOrder = allOrders.find(
        (order) => order.id === parseInt(orderId)
      );
      setThisOrder(successfulOrder);
    }
  }, [allOrders]);

  //consolelog(thisOrder)

  return (
    <React.Fragment>
      <Container
        className="d-flex flex-row justify-content-center align-items-lg-center mt-5 py-5 home-background-categories"
        fluid
        style={styles.successfulContainer}
      >
        <Card className="successful-order-card p-0">
          <CardAction
            className="successful-card-action text-white m-0 d-flex justify-content-center align-items-center"
            style={styles.cardAction}
          >
            <div className="m-3 d-flex justify-content-center align-items-center">
              <Image src={tick} alt="Tick" />
              <h4 className="m-2 p-0">
                {thisOrder?.total >= 0.5 ? "Payment" : "Purchase"} Successful
              </h4>
            </div>
          </CardAction>
          <div>
            {thisOrder ? (
              thisOrder.orderproducts?.map((orderproduct) => {
                return <CartItem type="successful" items={orderproduct} />;
              })
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "300px" }}
              >
                <Image
                  src={znanyeloader}
                  loading="lazy"
                  alt="Znanye Loader"
                  style={{ width: 60, height: 60 }}
                />
                {/* <video muted autoPlay loop width={60} height={60}>
                  <source src={znanyeloader} type="video/webm" />
                  Your browser does not support the video tag.
                </video> */}
              </div>
            )}
          </div>
          <div className="m-4 d-flex flex-column justify-content-center align-items-center">
            <a
              // to="/"
              href="/"
              style={{ textDecoration: "none" }}
              onClick={() => (window.location.href = "/")}
            >
              <Button
                dark={true}
                className="explore-button text-white mt-2 mb-4 body-text py-3"
                size="large"
              >
                CONTINUE SHOPPING
              </Button>
            </a>
          </div>
        </Card>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  successfulContainer: {
    minHeight: "60vh",
  },
  underline: {
    height: 0.5,
    backgroundColor: "#595959",
  },
  cardAction: {
    backgroundColor: "#2ED15C",
  },
};

export default OrderSuccessful;

import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Breadcrumb,
  Row,
  Col,
  Image,
  Badge,
  Form,
  Spinner,
  Button as RButton,
  OverlayTrigger,
  Tooltip,
  Card,
  Modal,
} from "react-bootstrap";
// import { useHistory } from 'react-router-dom';

import Select from "react-select";
import {
  Card as NCard,
  CardContent,
  Subtitle2,
  H5,
  Body2,
  CardAction,
  Button,
  Divider,
  IconButton,
} from "ui-neumorphism";
import { product } from "assets";
import {
  ZSlider,
  ToggleButton,
  AddToCartButton,
  ImageModal,
  Loader,
} from "components";
import Timer from "react-compound-timer";
import moment from "moment";

import "core-ui/pages/Product.scss";
import {
  checkedtrue,
  checkedfalse,
  imagenotavailable,
  orbit,
  zoom,
  pan,
  help_circle,
} from "assets";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  downarrows,
  view3d,
  view3dnew,
  added_to_cart,
  added_to_cart_gif,
  view3d_znanye,
} from "assets";
import * as actions from "actions/UserActions";
import * as authActions from "actions/AuthActions";
import { CustomSlider } from "components";
import ReactStars from "react-rating-stars-component";
import { BsChevronDoubleDown, BsSend } from "react-icons/bs";
import { BiComment } from "react-icons/bi";
import { FaStarHalfAlt, FaStar, FaRegStar } from "react-icons/fa";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { MdOutlineHelpCenter, MdOutlineReviews } from "react-icons/md";
import { Breakpoint, useCurrentBreakpointName } from "react-socks";
import { FiDivideSquare } from "react-icons/fi";
import { RiFullscreenFill } from "react-icons/ri";
import { BiFullscreen } from "react-icons/bi";
import { ImStarEmpty, ImStarHalf, ImStarFull } from "react-icons/im";
import { useTransition, animated, useSpring } from "react-spring";
import * as easings from "d3-ease";
import getSymbolFromCurrency from "currency-symbol-map";
import axios from "axios";
import { toast } from "react-toastify";
import Error from "pages/Error/Error";
import { Helmet } from "react-helmet";
import { Try3D } from "pages";
import { AiOutlineCaretDown } from "react-icons/ai";
import { link_news, partial_link_news } from "assets";

const CustomDropdownIndicator = (props) => {
  return (
    <div
      {...props.innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: "8px", // Adjust the spacing as needed
      }}
    >
      <AiOutlineCaretDown />
    </div>
  );
};

const customStyles = {
  menu: () => ({
    marginTop: -4.5,
    padding: 0,
    position: "absolute",
    width: "100%",
    zIndex: 999,
    fontSize: "20px",
    borderRadius: "50px !important",
    overflow: "visible",
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderRadius: "15px",
    textAlign: "center",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#0D0D0D",
    color: state.isSelected ? "#777" : "white",
    marginTop: 0,
    cursor: "pointer",
    textAlign: "left",
    paddingLeft: "10px",
    fontSize: "16px",
    padding: "8px",
  }),

  control: (provided, state) => ({
    ...provided,
    color: "#fff",
    backgroundColor: "#0D0D0D",
    display: "flex",
    fontSize: 16,
    fontFamily: "poppins",
    width: 150,
    borderRadius: "10px",
    border: "1px solid #3D3D3D",
    boxShadow: "none",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const color = "#fff";
    const transition = "opacity 300ms";
    const padding = "8px 8px 8px 0px";

    return { ...provided, opacity, transition, color, padding };
  },
};

const sortOptions = [
  { label: "LATEST", value: 1 },
  { label: "OLDEST", value: 2 },
];

const Product = (props) => {
  const breakpoint = useCurrentBreakpointName();

  let params = new URLSearchParams(document.location.search);
  let ordering = params.get("ordering");

  const { setLoginShow } = props;
  // const [review, setReview] = useState(null);
  const myRefReview = useRef(null);
  const myRefProduct = useRef(null);
  // const history = useHistory();

  const form = useRef(null);
  let { productId } = useParams();
  // let fileExtensions = null;
  var today = new Date();
  const location = useLocation();
  const navigate = useNavigate();
  const products = useSelector((state) => state.userReducer.products);
  const categories = useSelector((state) => state.userReducer.categories);
  const user = useSelector((state) => state.authReducer.user);
  const cartItems = useSelector(
    (state) => state.authReducer.user?.cartproducts
  );
  const [productComment, setProductComment] = useState();

  const IndicatorSeparator = () => {
    return (
      <div
        style={{
          height: "1px",
          width: "1px",
          backgroundColor: "black",
          padding: 0,
        }}
      />
    );
  };

  const sortOptionRef = useRef();
  const currency = useSelector((state) => state.authReducer.currency);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  const [thisProduct, setThisProduct] = useState(null);
  const [errorPage, setErrorPage] = useState(false);
  const [fileExtensions, setFileExtensions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [changingBorder, setChangingBroder] = useState(false);
  const [productCommentSet, setProductCommentSet] = useState();
  const thisCategory = categories.find(
    (item) => item.id === thisProduct?.category
  );
  const favoriteProduct = useSelector((state) =>
    state.authReducer.user?.favourites.find(
      (item) => item.product.slug === productId
    )
  );
  const bestCoupon = thisProduct?.coupons_data?.find(
    (item) => item.id === thisProduct.best_coupon
  );

  const reviews = useSelector((state) => state.userReducer.reviews);

  const [isSending, setIsSending] = useState(false);
  const [descriptionLength, setDescriptionLength] = useState(1000);
  const [description, setDescription] = useState(null);
  const [rating, setRating] = useState(0);
  const [currentImage, setCurrentImage] = useState(-1);
  const [reviewCount, setReviewCount] = useState(5);
  const [openAddedToCartCard, setOpenAddedToCartCard] = useState(false);
  const [x, setX] = useState(true);
  const [y, setY] = useState(false);
  const [hovered, setHovered] = useState(false);
  // const [soldCount, setSoldCount] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isReviewAllowed, setIsReviewAllowed] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [reviewComment, setReviewComment] = useState("review");
  const [addReview, setAddReview] = useState(false);
  // const [isFree, setIsFree] = useState(false);

  const [wantHelp, setWantHelp] = useState(false);
  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    if (thisProduct) {
      console.log(location);
      if (location.hash === "#review") {
        myRefReview.current.scrollIntoView();
      } else if (location.hash === "#viewproduct") {
        myRefProduct.current.scrollIntoView();
      }
    }
  }, [location, thisProduct]);

  useEffect(() => {
    if (bestCoupon) {
      setTimeLeft(
        moment(bestCoupon?.valid_till).diff(moment(today), "seconds") * 1000
      );
    }
  }, [bestCoupon]);

  useEffect(() => {
    if (currentImage === -1) {
      setHovered(true);
    } else {
      setHovered(false);
    }
  }, [currentImage]);

  // let timeLeft =
  //   moment(bestCoupon?.valid_till).diff(moment(today), "seconds") * 1000;

  const onSelectSliderImage = (selected) => {
    setCurrentImage(selected - 1);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Help
    </Tooltip>
  );

  const [productReviews, setProductReviews] = useState([]);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [isCommentSending, setIsCommentSending] = useState(false);
  const [replyTo, setReplyTo] = useState("");
  const [commentID, setCommentID] = useState(null);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const dispatch = useDispatch();

  const loadReviews = async () => {
    if (productId) {
      const reviews = await actions.loadReviews(
        productId,
        "/review/product_reviews/"
      );
      if (reviews) {
        setProductReviews(reviews);
        if (reviews.count > 0) {
          setReviewsCount(reviews.count);
        }
      }
    }
  };

  const loadProductDetails = async () => {
    const response = await actions.loadProduct(productId);
    if (response) {
      setThisProduct(response);
      dispatch(actions.loadCategories());
      loadReviews();
      loadComments();
    } else {
      //consolelog("Wrong Enter");
      setErrorPage(true);
      setLoading(false);
      setThisProduct(null);
    }
  };

  // useEffect(() => {
  //   const previousRoute = history.location.state?.from || null;
  //   console.log('Previous Route:', previousRoute);
  // }, [history.location.state]);

  // useEffect(() => {
  //   const previousRoute = sessionStorage.getItem('previousRoute') || null;
  //   console.log('Previous Route:', previousRoute);
  // }, []);

  useEffect(() => {
    loadProductDetails();
    setLoading(false);
  }, []);

  useEffect(async () => {
    if (thisProduct) {
      setDescription(thisProduct?.description?.substring(0, descriptionLength));
      setCurrentImage(thisProduct?.is_threed_enabled ? -1 : 0);
    }

    if (user) {
      user?.orders.map((order) => {
        if (order.order_status === "PL") {
          order.orderproducts.map((orderproduct) => {
            //consolelog(orderproduct.product?.slug, productId);
            if (orderproduct.product?.slug === productId) {
              setIsReviewAllowed(true);
            }
          });
        }
      });
    }

    // //consolelog("$$$",thisProduct)
    //  FOR SOLD COUNT
    // const data = { "product_id": thisProduct?.id };
    // const response = await authActions.soldCount(data);
    // if (response) {
    //   setSoldCount(response["sold_count"]);
    // }
  }, [thisProduct]);

  useEffect(async () => {
    if (isAuthenticated) {
      await authActions.upgradeView(productId);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let s_fileExtensions = null;
    if (thisProduct?.file_statistics?.extension_stats) {
      s_fileExtensions = Object.keys(
        thisProduct?.file_statistics?.extension_stats
      );
      setFileExtensions(s_fileExtensions);
    }
  }, [thisProduct]);

  const loadComments = async () => {
    if (productId) {
      const comments = await actions.loadComments(
        productId,
        ordering,
        "/comment/product_comments/"
      );
      if (comments) {
        setProductCommentSet(comments);
        if (comments.count > 0) {
          setCommentsCount(comments.count);
        }
      }
    }
  };

  const loadNextComments = async () => {
    const comments = productCommentSet.results;
    const newComments = await actions.loadComments(
      thisProduct.slug,
      ordering,
      productCommentSet.next
    );
    if (newComments) {
      const newResults = comments.concat(newComments.results);
      newComments.results = newResults;
      setProductCommentSet(newComments);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    setIsCommentSending(true);
    if (productComment) {
      const formData = new FormData(form.current);
      formData.append("comment_by", user.id);
      formData.append("product", thisProduct?.id ? thisProduct.id : "");
      formData.append("comment_message", productComment);
      formData.append("comment_id", commentID);

      const response = await actions.createComment(formData);
      if (response) {
        form.current.reset();
        setIsCommentSending(false);
        loadComments(null);
      } else {
        setIsCommentSending(false);
      }
    } else {
      toast.warning("Please write a comment!");
      setIsCommentSending(false);
    }
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    if (rating > 0) {
      const formData = new FormData(form.current);
      formData.append("product", thisProduct.id);
      formData.append("review_by", user.id);
      formData.append("rating", rating);
      const response = await actions.createReview(formData);
      if (response) {
        form.current.reset();
        setRating(0);
        setIsSending(false);
        loadReviews();
        setAddReview(false);
      }
    } else {
      toast.warning("Please rate the product!");
      setIsSending(false);
    }
    setIsSending(false);
  };

  const handleFavorite = (flag) => {
    //consolelog("isAuthenticated", isAuthenticated);
    if (isAuthenticated) {
      let fav = { product_id: thisProduct?.id };
      if (flag) {
        dispatch(authActions.addFavorite(fav, user.id));
      } else {
        dispatch(authActions.removeFavorite(fav, user.id));
      }
    } else {
      if (
        breakpoint === "xsmall" ||
        breakpoint === "small" ||
        breakpoint === "medium"
      ) {
        navigate("/login");
      } else {
        setLoginShow(true);
      }
    }
  };

  const transitionsX = useTransition(x, {
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
    // reverse: toggle,
    delay: 500,
    config: { duration: 1000, easing: easings.easeCubicOut },
    onRest: () => {
      setY(true);
      setX(false);
    },
  });

  const transitionsY = useTransition(y, {
    from: { opacity: 0, scale: 0.8, x: -100 },
    enter: { opacity: 1, scale: 1, x: 0 },
    leave: { opacity: 1 },
    // reverse: toggle,
    delay: 500,
    config: { duration: 1000, tension: 300 },
    // onRest: () => {

    // },
  });

  const spring = useSpring({
    from: { scaleX: 0 },
    to: { scaleX: 1 },
    delay: 500,
    config: { duration: 1000, tension: 300 },
  });

  const cartCallback = (addCard) => {
    if (!addCard) {
      setY(false);
    }
    setOpenAddedToCartCard(addCard);
    setX(true);
  };

  const loadNextReviews = async () => {
    const reviews = productReviews.results;
    const newReviews = await actions.loadReviews(
      thisProduct.slug,
      productReviews.next
    );
    if (newReviews) {
      const newResults = reviews.concat(newReviews.results);
      newReviews.results = newResults;
      setProductReviews(newReviews);
    }
  };

  const deleteReview = async (reviewID) => {
    const response = await actions.removeReview(reviewID);
    if (response) {
      loadReviews();
    }
  };

  const addToCart = () => {
    try {
      // setIsLoading(true);
      dispatch(authActions.addToCartBuyNow(thisProduct, user?.id));

      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    loadComments(ordering);
  }, [ordering]);

  const onChangeFilters = () => {
    const searchOption = sortOptionRef.current.state.focusedOption;
    let order = "";
    if (searchOption?.value === 1) {
      order += "-created_at";
    } else if (searchOption?.value === 2) {
      order += "created_at";
    }

    navigate(
      `/3dmodels/${thisCategory?.slug}/${thisProduct?.slug}?ordering=${order}`
    );
  };

  let totalprice = 0;
  cartItems?.map((item) => {
    totalprice = totalprice + item?.product?.price;
  });

  let saved = 0;
  cartItems?.map((item) => {
    if (item?.applied_coupon) {
      if (item?.applied_coupon?.coupon_category === "ABSOLUTE") {
        saved = saved + item?.applied_coupon?.flat_off;
      } else {
        saved =
          saved + (item?.product?.price * item?.applied_coupon?.flat_off) / 100;
      }
    }
  });

  if (loading) {
    return <Loader />;
  }

  if (errorPage) {
    return <Error />;
  }

  return (
    <React.Fragment>
      {/* <Helmet>
        <title>{`${thisProduct?.meta_title
          ? thisProduct?.meta_title
          : "Znanye | Product | " + thisProduct?.title
          }`}</title>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <meta
          name="description"
          content={
            thisProduct?.meta_description
              ? thisProduct?.meta_description
              : thisProduct?.description?.replace(/(<([^>]+)>)/gi, "")
          }
        />
        <link
          rel="canonical"
          href={`https://www.znanye.com${location.pathname}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.znanye.com${location.pathname}`}
        />
      </Helmet> */}
      <Container
        className="d-flex flex-column align-items-center h-100 text-center mt-5 pt-5 px-0 home-background-categories"
        fluid
        style={{
          // minHeight: "100vh",
          padding: "var(--bs-gutter-x, 0.75rem) !important",
        }}
        // style={styles.parentContainer}
      >
        <Container
          ref={myRefProduct}
          className="px-lg-5 px-0"
          style={{
            height: "100%",
            padding: "var(--bs-gutter-x, 0.75rem) !important",
          }}
          fluid
        >
          <ul
            className="breadcrumb subtitle mx-lg-4 px-4
           mb-3"
            style={{ padding: "var(--bs-gutter-x, 0.75rem) !important" }}
          >
            <li>
              <a href="/3dmodels">3D Models</a>
            </li>
            <li>
              <a href={`/3dmodels/` + thisCategory?.slug}>
                {thisCategory?.title}
              </a>
            </li>
            <li>{thisProduct?.title}</li>
          </ul>
          <Breakpoint large up>
            <Row>
              <Col sm={10} className="px-lg-4">
                {openAddedToCartCard && (
                  <div className="d-flex justify-content-left align-items-center my-3 mx-2">
                    <NCard
                      dark={true}
                      className="d-flex justify-content-between custom-card align-items-center text-start"
                    >
                      <div
                        className="d-flex justify-content-start p-4 pe-5"
                        style={{ borderRight: "1px solid #4e4e4e" }}
                      >
                        <NCard
                          dark={true}
                          className="d-flex justify-content-center custom-card align-items-center"
                          style={{ width: 80, height: 80, borderRadius: 10 }}
                        >
                          <Image
                            src={
                              thisProduct?.productimage_set[0]
                                ? thisProduct?.productimage_set[0]
                                    ?.thumbnail_file
                                : imagenotavailable
                            }
                            alt={thisProduct?.title}
                            style={{ width: 79, height: 79, borderRadius: 10 }}
                          />
                        </NCard>
                        <div className="mx-3">
                          <div className="caption">
                            Added to Cart
                            <Image
                              className="mx-2"
                              style={{ width: 20, height: 20 }}
                              src={added_to_cart}
                              alt="Add To Cart"
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-between">
                            <div className="subtitle">{thisProduct?.title}</div>
                            <div className="caption">
                              {thisProduct?.category_data?.title}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="position-relative d-flex align-items-center"
                        style={{
                          minWidth: 150,
                          height: 100,
                          userSelect: "none",
                        }}
                      >
                        {transitionsX(
                          (styleProps, item) =>
                            item && (
                              <animated.div
                                className="position-absolute"
                                style={styleProps}
                              >
                                <Image
                                  className="mx-4"
                                  style={{ width: 100, height: 100 }}
                                  src={added_to_cart_gif}
                                  alt="Add To Cart"
                                />
                              </animated.div>
                            )
                        )}
                        <animated.div style={spring}>
                          {y &&
                            transitionsY(
                              (styleProps, item) =>
                                item && (
                                  <animated.div
                                    className="px-5 d-flex justify-content-between"
                                    style={{ ...styleProps, left: 0 }}
                                  >
                                    <div>
                                      <div className="subtitle">
                                        {cartItems?.length}
                                      </div>
                                      <div className="caption">
                                        Total Cart Items
                                      </div>
                                    </div>
                                    <div className="mx-5">
                                      <div className="subtitle">
                                        {getSymbolFromCurrency(currency.name) +
                                          " " +
                                          (
                                            (totalprice - saved) *
                                            currency.rate
                                          ).toFixed(2)}
                                      </div>
                                      <div className="caption">
                                        Total Cart Price
                                      </div>
                                    </div>
                                    <a
                                      href="/cart"
                                      onClick={() =>
                                        handleNavigationClick("/cart")
                                      }
                                      className="text-decoration-none"
                                    >
                                      <RButton
                                        className="explore-button-no-neo btn btn-ptimary"
                                        dark={true}
                                        size="lg"
                                        style={{
                                          border: changingBorder
                                            ? "0px"
                                            : "0px",
                                        }}
                                        onMouseEnter={() =>
                                          setChangingBroder(true)
                                        }
                                        onMouseLeave={() =>
                                          setChangingBroder(false)
                                        }
                                      >
                                        <span className="poppins text-decoration-none">
                                          GO TO CART
                                        </span>
                                      </RButton>
                                    </a>
                                  </animated.div>
                                )
                            )}
                        </animated.div>
                      </div>
                      {/* <div>
                            <Image className="mx-4" style={{ width: 100, height: 100 }} src={added_to_cart_gif} />
                          </div> */}
                    </NCard>
                    <div className="ps-5">
                      <IconButton
                        size="large"
                        dark={true}
                        onClick={() => {
                          setOpenAddedToCartCard(false);
                          setX(true);
                        }}
                      >
                        <AiOutlineClose
                          size={20}
                          color="white"
                          // onClick={() => handleRemoveCartproduct(item.product?.id)}
                        />
                      </IconButton>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={9} className="px-lg-4">
                <div>
                  <Card
                    className="m-lg-2 my-2 d-flex flex-column align-items-center h-100 border-R20 position-relative user-select-none overflow-hidden"
                    style={{ backgroundColor: "#2e2e2e" }}
                  >
                    <div
                      className="position-absolute"
                      style={{ right: 0, zIndex: 999 }}
                    >
                      <ToggleButton
                        type="favorite"
                        array={favoriteProduct}
                        handleFavorite={handleFavorite}
                      />
                    </div>
                    {/* </CardAction> */}
                    <div className="w-100">
                      <div
                        className="w-100 d-flex align-items-center justify-content-center"
                        style={{ height: 500, backgroundColor: "#2e2e2e" }}
                      >
                        {!hovered && currentImage != -1 && (
                          <Image
                            src={
                              thisProduct?.productimage_set[currentImage]
                                ? thisProduct?.productimage_set[currentImage]
                                    ?.image_file
                                : imagenotavailable
                            }
                            alt={thisProduct?.title}
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                            // style={styles.productImage}
                          />
                        )}
                        {currentImage === -1 && (
                          // <iframe
                          //   title="custom 3d viewer"
                          //   className="position-absolute"
                          //   style={{ top: 0, left: 0, borderRadius: 20 }}
                          //   src={`${axios.defaults.baseURL}viewer/${productId}`}
                          //   width="100%"
                          //   height="100%"
                          // />
                          <div style={{ width: "100%", height: "100%" }}>
                            {thisProduct && <Try3D product={thisProduct} />}
                          </div>
                        )}
                        <div
                          className={`${
                            wantHelp ? "d-flex" : "d-none"
                          } w-100 h-100 position-absolute justify-content-end p-2`}
                          style={{ zIndex: 1000 }}
                        >
                          <div
                            className="w-100 border-R20 px-4"
                            style={{ backgroundColor: `rgba(0,0,0,0.9)` }}
                          >
                            <div
                              className="d-flex position-absolute"
                              style={{ right: 0 }}
                            >
                              <div
                                className="m-3"
                                style={{ backgroundColor: "#2F2F2F" }}
                              >
                                <IconButton
                                  size="large"
                                  dark={true}
                                  onClick={() => {
                                    setWantHelp(false);
                                  }}
                                >
                                  <AiOutlineClose
                                    size={20}
                                    color="white"
                                    // onClick={() => handleRemoveCartproduct(item.product?.id)}
                                  />
                                </IconButton>
                              </div>
                            </div>
                            <Container className="h-100">
                              <Row className="body-text2 h-100 text-white">
                                <Col
                                  sm={6}
                                  className="d-flex align-items-center"
                                >
                                  <div className="d-flex flex-column align-items-start text-start">
                                    <Image
                                      src={orbit}
                                      alt="Orbit"
                                      className="my-2"
                                      style={{
                                        width: 22,
                                        height: 22,
                                        color: "white",
                                      }}
                                    />
                                    <div className="fw-bold my-2">ORBIT</div>
                                    <div>Left Click or Ctrl + Right Click</div>
                                    <div>One finger move (Touchscreen)</div>
                                  </div>
                                </Col>
                                <Col
                                  sm={6}
                                  className="d-flex align-items-center"
                                >
                                  <div className="d-flex flex-column align-items-start text-start">
                                    <Image
                                      src={zoom}
                                      alt="Zoom"
                                      className="my-2"
                                      style={{
                                        width: 22,
                                        height: 22,
                                        color: "white",
                                      }}
                                    />
                                    <div className="fw-bold my-2">ZOOM</div>
                                    <div>
                                      Scroll Button or Middle Mouse Button
                                    </div>
                                    <div>
                                      Pinch In and Pinch out (Touchscreen)
                                    </div>
                                  </div>
                                </Col>
                                <Col
                                  sm={6}
                                  className="d-flex align-items-center"
                                >
                                  <div className="d-flex flex-column align-items-start text-start">
                                    <Image
                                      src={pan}
                                      alt="Pan"
                                      className="my-2"
                                      style={{
                                        width: 22,
                                        height: 22,
                                        color: "white",
                                      }}
                                    />
                                    <div className="fw-bold my-2">PAN</div>
                                    <div>Right Click or Ctrl + Left Click</div>
                                    <div>Two finger move (Touchscreen)</div>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </div>
                    </div>
                    <IconButton
                      size="large"
                      // color="var(--warning)"
                      dark={true}
                      className="position-absolute"
                      style={{ right: 30, bottom: 20 }}
                    >
                      {/* <Link to={`${currentImage === -1 ? 'viewer/' : ('imageviewer/?imagesrc=' + (thisProduct?.productimage_set[currentImage] ? thisProduct?.productimage_set[currentImage]?.image_file : imagenotavailable))}`}> */}
                      {/* <BiFullscreen style={{ width: 30, height: 30, color: "white" }} /> */}
                      <Image
                        src={view3d}
                        alt="View 3D"
                        style={{ width: 30, height: 30 }}
                        onClick={() => {
                          if (currentImage === -1) {
                            // navigate("viewer/");
                            handleNavigationClick(
                              `/3dmodels/${thisCategory?.slug}/${thisProduct?.slug}/viewer/`
                            );
                            // navigate(
                            //   `/3dmodels/${thisCategory?.slug}/${thisProduct?.slug}/viewer/`
                            // );
                          } else {
                            setImageModalVisible(true);
                          }
                        }}
                      />
                      {/* </Link> */}
                    </IconButton>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <IconButton
                        size="large"
                        dark={true}
                        // color="#2E2E2E"
                        className="position-absolute"
                        // disabled
                        style={{ right: 80, bottom: 20 }}
                        onClick={() => setWantHelp(true)}
                      >
                        {/* <MdOutlineHelpCenter style={{ width: 40, height: 40, color: "#2E2E2E" }} /> */}
                        <Image
                          src={help_circle}
                          alt="Help Circle"
                          style={{ width: 30, height: 30 }}
                        />
                      </IconButton>
                    </OverlayTrigger>
                    {/* </CardAction> */}
                  </Card>
                </div>
                <Breakpoint large up>
                  <CustomSlider
                    type="image_3d_slider"
                    is_threed_enabled={thisProduct?.is_threed_enabled}
                    items={
                      thisProduct?.productimage_set
                        ? [null, ...thisProduct?.productimage_set]
                        : [null]
                    }
                    selectCallback={onSelectSliderImage}
                    selected={currentImage + 1}
                  />
                  <Container className="my-5" fluid>
                    <Row
                      className="text-white"
                      style={{
                        backgroundColor: "#141415",
                        borderRadius: "20px",
                        border: "1px solid #3D3D3D",
                      }}
                    >
                      <Col className="py-4">
                        <div className="subtitle pb-1 non-selectable">
                          {thisProduct?.title}
                        </div>
                        <div className="body-text2 non-selectable">
                          Product Name
                        </div>
                      </Col>
                      <Col className="py-4" style={styles.detailCard}>
                        <div className="subtitle non-selectable">
                          {fileExtensions?.map((extension) => {
                            return <>{extension} </>;
                          })}
                        </div>
                        <div className="body-text2 non-selectable">
                          File Format Available
                        </div>
                      </Col>
                      <Col className="py-4">
                        <div className="subtitle non-selectable">
                          {thisProduct?.view_count}
                        </div>
                        <div className="body-text2 non-selectable">Views</div>
                      </Col>
                      {/* <Col className={`${soldCount > 0 ? "py-4" : "d-none"}`} style={styles.detailCardLeftBorder}>
                      <div className="subtitle">{soldCount}</div>
                      <div className="body-text2">Sold Count</div>
                    </Col> */}
                    </Row>
                  </Container>
                </Breakpoint>
                <Breakpoint large up>
                  <Container
                    className="text-white text-start my-lg-5 py-lg-4 px-2"
                    fluid
                  >
                    <div className="subtitle my-lg-4">Product Description</div>
                    <div className="body-text2">
                      {thisProduct?.description != null ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: thisProduct?.description,
                          }}
                        />
                      ) : (
                        "No description available"
                      )}
                    </div>
                  </Container>
                </Breakpoint>
                <Breakpoint medium down>
                  <Container
                    className="text-white text-start mb-5 px-1 overflow-scroll"
                    fluid
                  >
                    <div className="subtitle mb-4">Product Description</div>
                    <div className="body-text2">
                      {thisProduct?.description != null
                        ? description
                        : "No description available"}
                    </div>
                    <div
                      className={`${
                        thisProduct?.description &&
                        descriptionLength < thisProduct?.description.length
                          ? "d-block"
                          : "d-none"
                      } text-secondary text-center mt-3 mb-5 poppins-300 cursor-pointer`}
                      onClick={() => {
                        setDescriptionLength(thisProduct?.description.length);
                        setDescription(thisProduct?.description);
                      }}
                    >
                      READ MORE <BsChevronDoubleDown size="18" />
                    </div>
                  </Container>
                </Breakpoint>
                <Breakpoint large up>
                  <Container className="p-0 m-0" ref={myRefReview}>
                    <div className="d-flex mt-4">
                      <div
                        className={`${
                          reviewComment === "review"
                            ? "text-primary"
                            : "text-white"
                        } subtitle d-flex flex-column mx-4 cursor-pointer`}
                        onClick={() => setReviewComment("review")}
                      >
                        <span>
                          {reviewsCount > 0 ? reviewsCount : ""} Reviews
                        </span>
                        <span
                          className={`${
                            reviewComment === "review" ? "d-block" : ""
                          } mt-2`}
                          style={
                            reviewComment === "review"
                              ? styles.selectedSectionThree
                              : styles.unselectedSectionThree
                          }
                        />
                      </div>
                      <div
                        className={`${
                          reviewComment === "comment"
                            ? "text-primary"
                            : "text-white"
                        } subtitle d-flex flex-column mx-4 cursor-pointer`}
                        onClick={() => {
                          setReviewComment("comment");
                        }}
                      >
                        <span>
                          {productCommentSet?.results?.length > 0
                            ? productCommentSet?.results?.length
                            : ""}{" "}
                          Comment
                        </span>
                        <span
                          className={`${
                            reviewComment === "comment" ? "d-block" : ""
                          } mt-2`}
                          style={
                            reviewComment === "comment"
                              ? styles.selectedSectionThree
                              : styles.unselectedSectionThree
                          }
                        />
                      </div>
                    </div>
                  </Container>
                  {reviewComment === "review" && (
                    <>
                      <Row>
                        <Col sm={2} className="text-start px-4 text-white">
                          <h3 className="mt-5">
                            {thisProduct?.total_rating_count > 0
                              ? (
                                  (thisProduct?.five_star_count * 5 +
                                    thisProduct?.four_star_count * 4 +
                                    thisProduct?.three_star_count * 3 +
                                    thisProduct?.two_star_count * 2 +
                                    thisProduct?.one_star_count * 1) /
                                  thisProduct?.total_rating_count
                                ).toPrecision(2)
                              : 0}
                          </h3>
                          {thisProduct && (
                            <ReactStars
                              count={5}
                              onChange={(e) => setRating(e)}
                              size={24}
                              value={(
                                (thisProduct?.five_star_count * 5 +
                                  thisProduct?.four_star_count * 4 +
                                  thisProduct?.three_star_count * 3 +
                                  thisProduct?.two_star_count * 2 +
                                  thisProduct?.one_star_count * 1) /
                                thisProduct?.total_rating_count
                              ).toPrecision(1)}
                              isHalf={true}
                              edit={false}
                              activeColor="#f78d1d"
                            />
                          )}
                          <p>
                            RATED BY - {thisProduct?.total_rating_count} USERS
                          </p>
                        </Col>
                        <Col sm={10} className="text-start text-white mt-5">
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col sm={1} className="text-end">
                              5
                            </Col>
                            <Col sm={8} className="text-start">
                              <Card
                                style={{
                                  backgroundColor: "#3d3d3d",
                                  width: 500,
                                  height: 14,
                                  borderRadius: 7,
                                }}
                              >
                                {thisProduct?.five_star_count > 0 && (
                                  <Card
                                    style={{
                                      backgroundColor: "#fff",
                                      width:
                                        thisProduct?.five_star_count > 0
                                          ? (thisProduct?.five_star_count *
                                              500) /
                                            thisProduct?.total_rating_count
                                          : 0,
                                      height: 14,
                                      borderRadius: 7,
                                    }}
                                  />
                                )}
                              </Card>
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col sm={1} className="text-end">
                              4
                            </Col>
                            <Col sm={8} className="text-start">
                              <Card
                                style={{
                                  backgroundColor: "#3d3d3d",
                                  width: 500,
                                  height: 14,
                                  borderRadius: 7,
                                }}
                              >
                                {thisProduct?.four_star_count > 0 && (
                                  <Card
                                    style={{
                                      backgroundColor: "#fff",
                                      width:
                                        thisProduct?.four_star_count > 0
                                          ? (thisProduct?.four_star_count *
                                              500) /
                                            thisProduct?.total_rating_count
                                          : 0,
                                      height: 14,
                                      borderRadius: 7,
                                    }}
                                  />
                                )}
                              </Card>
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col sm={1} className="text-end">
                              3
                            </Col>
                            <Col sm={8} className="text-start">
                              <Card
                                style={{
                                  backgroundColor: "#3d3d3d",
                                  width: "500px",
                                  height: "14px",
                                  borderRadius: "7px",
                                }}
                              >
                                {thisProduct?.three_star_count > 0 && (
                                  <Card
                                    style={{
                                      backgroundColor: "#fff",
                                      width:
                                        thisProduct?.three_star_count > 0
                                          ? (thisProduct?.three_star_count *
                                              500) /
                                            thisProduct?.total_rating_count
                                          : 0,
                                      height: "14px",
                                      borderRadius: "7px",
                                    }}
                                  />
                                )}
                              </Card>
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col sm={1} className="text-end">
                              2
                            </Col>
                            <Col sm={8} className="text-start">
                              <Card
                                style={{
                                  backgroundColor: "#3d3d3d",
                                  width: "500px",
                                  height: "14px",
                                  borderRadius: "7px",
                                }}
                              >
                                {thisProduct?.two_star_count > 0 && (
                                  <Card
                                    style={{
                                      backgroundColor: "#fff",
                                      width:
                                        thisProduct?.two_star_count > 0
                                          ? (thisProduct?.two_star_count *
                                              500) /
                                            thisProduct?.total_rating_count
                                          : 0,
                                      height: "14px",
                                      borderRadius: "7px",
                                    }}
                                  />
                                )}
                              </Card>
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-start align-items-center">
                            <Col sm={1} className="text-end">
                              1
                            </Col>
                            <Col sm={8} className="text-start">
                              <Card
                                style={{
                                  backgroundColor: "#3d3d3d",
                                  width: "500px",
                                  height: "14px",
                                  borderRadius: "7px",
                                }}
                              >
                                {thisProduct?.one_star_count > 0 && (
                                  <Card
                                    style={{
                                      backgroundColor: "#fff",
                                      width:
                                        thisProduct?.one_star_count > 0
                                          ? (thisProduct?.one_star_count *
                                              500) /
                                            thisProduct?.total_rating_count
                                          : 0,
                                      height: "14px",
                                      borderRadius: "7px",
                                    }}
                                  />
                                )}
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Container
                        className={`${
                          isReviewAllowed ? "d-block" : "d-none"
                        } text-white text-start my-4 px-2`}
                        fluid
                      >
                        <RButton
                          className="text-white explore-button"
                          onClick={() => setAddReview(true)}
                        >
                          ADD REVIEW
                          <MdOutlineReviews className="mx-2" />
                        </RButton>
                      </Container>
                      <Container
                        className="text-white text-start my-4 py-3 px-2"
                        fluid
                      >
                        {productReviews?.results?.length === 0 ? (
                          isAuthenticated ? (
                            <p>
                              No reviews posted yet! Be the first to post a
                              review!
                            </p>
                          ) : (
                            <p>
                              No reviews posted yet! Please login to Review!
                            </p>
                          )
                        ) : (
                          <></>
                        )}
                        {productReviews?.results?.map((item, index) => {
                          return (
                            <div>
                              <div className="d-flex justify-content-between">
                                <div key={item.id}>
                                  <div style={styles.commentReviewUser}>
                                    {item.review_by_data.name
                                      ? item.review_by_data.name
                                      : "Anonymous User"}
                                  </div>
                                  <div className="body-text2 mt-2">
                                    {item.review_message}
                                  </div>
                                </div>
                                <div
                                  className={`${
                                    user.id === item.review_by
                                      ? "d-flex justify-content-center align-items-end cursor-pointer"
                                      : "d-none"
                                  }`}
                                >
                                  <AiOutlineDelete
                                    size={20}
                                    onClick={() => deleteReview(item.id)}
                                  />
                                </div>
                              </div>
                              <hr
                                className={`${
                                  index === reviews.length - 1
                                    ? "d-none"
                                    : "d-block"
                                }`}
                              />
                            </div>
                          );
                        })}
                        <div
                          className={`${
                            productReviews.next === null ? "d-none" : "d-block"
                          } subtitle text-primary text-center my-5 poppins-300 cursor-pointer`}
                          onClick={() => loadNextReviews()}
                        >
                          {reviewsCount > 0 && (
                            <div>
                              LOAD MORE REVIEWS{" "}
                              <BsChevronDoubleDown size="20" />
                            </div>
                          )}
                        </div>
                      </Container>
                    </>
                  )}
                  {reviewComment === "comment" && (
                    <>
                      <Container className="mt-5" fluid>
                        {isAuthenticated && (
                          <Card
                            style={{
                              backgroundColor: "#0d0d0d",
                              border: "1px solid #3D3D3D",
                              borderRadius: "16px",
                              minHeight: "96px",
                            }}
                          >
                            <Row>
                              <Col>
                                <Form ref={form}>
                                  <Form.Group
                                    className="py-2"
                                    controlId="formComment"
                                  >
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      type="text"
                                      placeholder="Type your message"
                                      style={{
                                        backgroundColor: "#0d0d0d",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "16px",
                                        resize: "none",
                                      }}
                                      onChange={(e) =>
                                        setProductComment(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                </Form>
                              </Col>
                              <Col
                                sm={1}
                                className="d-flex justify-content-end py-2 me-2"
                              >
                                <Card
                                  className="d-flex justify-content-center align-items-center cursor-pointer"
                                  style={{
                                    backgroundColor: "#292929",
                                    borderRadius: "8px",
                                    border: "1px solid #3D3D3D",
                                    minWidth: "64px",
                                    minHeight: "80px",
                                  }}
                                  onMouseEnter={() => setChangingBroder(true)}
                                  onMouseLeave={() => setChangingBroder(false)}
                                  onClick={(e) => handleCommentSubmit(e)}
                                >
                                  {isCommentSending ? (
                                    <span>
                                      <Spinner
                                        className="me-2 text-white"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : (
                                    <BsSend style={{ color: "#F7941D" }} />
                                  )}
                                </Card>
                              </Col>
                            </Row>
                          </Card>
                        )}
                        <Row className="text-white">
                          <Col className="text-start body-text3 my-4">
                            <BiComment size={20} />
                            &nbsp;COMMENTED BY -{" "}
                            {productCommentSet?.results?.length} USERS
                          </Col>
                          <Col sm={3} className="text-start my-4">
                            <div className="d-flex justify-content-end align-items-center">
                              <div className="body-text3">SORT BY</div>
                              <Select
                                className="ms-2 text-white"
                                ref={sortOptionRef}
                                required={true}
                                options={sortOptions}
                                isSearchable={false}
                                components={{
                                  IndicatorSeparator,
                                  DropdownIndicator: CustomDropdownIndicator,
                                }}
                                styles={customStyles}
                                defaultValue={
                                  sortOptions.length > 0 ? sortOptions[0] : ""
                                }
                                onChange={(value) => onChangeFilters()}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Container>

                      <Container
                        className="text-white text-start mb-5 px-4"
                        fluid
                      >
                        {productCommentSet?.results?.map((comment, index) => (
                          <div>
                            <div style={styles.commentReviewUser}>
                              {comment?.comment_by_data?.name}
                            </div>
                            <p className="mt-2 body-text2">
                              {comment?.comment_message}
                            </p>
                            <hr></hr>
                            {/* <RButton
                              onClick={(e) => {
                                setCommentID(comment.id);
                                handleCommentSubmit(e);
                              }}
                            >
                              Reply
                            </RButton> */}
                          </div>
                        ))}
                        {/* <div
                          className={`${productCommentSet.next === null ? "d-none" : "d-block"
                            } subtitle text-primary text-center my-5 poppins-300 cursor-pointer`}
                          onClick={() => loadNextComments()}
                        >
                          {commentsCount > 0 && (
                            <div>
                              LOAD MORE COMMENTS{" "}
                              <BsChevronDoubleDown size="20" />
                            </div>
                          )}
                        </div> */}
                      </Container>
                    </>
                  )}
                </Breakpoint>
              </Col>
              <Col sm={3}>
                <Card
                  className="p-0 d-flex flex-column justify-content-between border-R20 my-lg-2 overflow-none"
                  style={{
                    backgroundColor: "#141415",
                    border: "1px solid #3D3D3D",
                  }}
                >
                  <div>
                    <div
                      className={`${
                        bestCoupon
                          ? "mb-4 px-3 py-3 d-flex bg-white border-0 text-secondary flex-row justify-content-center align-items-center"
                          : "d-none"
                      }`}
                      style={{
                        borderTopRightRadius: 20,
                        borderTopLeftRadius: 20,
                      }}
                    >
                      <div style={{ fontSize: 24, fontWeight: 500 }}>
                        {bestCoupon?.coupon_category === "ABSOLUTE"
                          ? getSymbolFromCurrency(currency.name) +
                            (bestCoupon?.flat_off * currency.rate).toFixed(2) +
                            " off"
                          : bestCoupon?.flat_off + "% off"}
                      </div>
                      <div className="text-center d-flex align-items-center ms-3 body-text2">
                        <div className="text-secondary">
                          Offer ends in &nbsp;
                        </div>
                        {timeLeft && (
                          <Timer
                            initialTime={timeLeft}
                            startImmediately={true}
                            direction="backward"
                          >
                            {({
                              start,
                              resume,
                              pause,
                              stop,
                              reset,
                              timerState,
                            }) => (
                              <React.Fragment>
                                <div
                                  className={`${
                                    timeLeft >= 86400000 ? "d-block" : "d-none"
                                  }`}
                                >
                                  <div className="p-0 m-0">
                                    <Timer.Days />
                                    <span>{" Days"}</span>
                                  </div>
                                </div>
                                <br />
                                <div
                                  className={`${
                                    timeLeft <= 86400000 ? "d-block" : "d-none"
                                  }`}
                                >
                                  <div className="p-0 m-0">
                                    <Timer.Hours />
                                    {" : "}
                                    <Timer.Minutes />
                                    {" : "}
                                    <Timer.Seconds />
                                    <span>{" Hrs"}</span>
                                  </div>
                                </div>
                                <br />
                              </React.Fragment>
                            )}
                          </Timer>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="my-2 d-flex flex-column justify-content-center align-items-center">
                    <div
                      className="text-primary pt-5 text-shadow"
                      style={{ fontSize: "32px" }}
                    >
                      {bestCoupon ? (
                        bestCoupon?.coupon_category === "ABSOLUTE" ? (
                          <p>
                            <span>{`${getSymbolFromCurrency(
                              currency.name
                            )}`}</span>
                            &nbsp;&nbsp;
                            <span>{`${(
                              (thisProduct?.price - bestCoupon?.flat_off) *
                              currency.rate
                            ).toFixed(2)}`}</span>
                          </p>
                        ) : (
                          <p>
                            <span>{`${getSymbolFromCurrency(currency.name)} ${(
                              (thisProduct?.price -
                                (thisProduct?.price * bestCoupon?.flat_off) /
                                  100) *
                              currency.rate
                            ).toFixed(2)}`}</span>
                          </p>
                        )
                      ) : (
                        <p>
                          <span
                            style={{ fontWeight: 250 }}
                          >{`${getSymbolFromCurrency(currency.name)} ${(
                            thisProduct?.price * currency.rate
                          ).toFixed(2)}`}</span>
                        </p>
                      )}
                    </div>

                    <div
                      className={`${
                        bestCoupon ? "d-block" : "d-none"
                      } h3 text-info mt-2`}
                      style={{ fontWeight: 250 }}
                    >
                      <strike style={{ textDecorationThickness: 0.5 }}>
                        {`${getSymbolFromCurrency(currency.name)} ${(
                          thisProduct?.price * currency.rate
                        ).toFixed(2)}`}
                      </strike>
                    </div>
                  </div>
                  <div className="mx-4">
                    <AddToCartButton
                      product={thisProduct}
                      callBack={cartCallback}
                    />
                  </div>
                </Card>
                <Card
                  className="my-2 py-4 px-4 border-R20 text-start mt-5 text-white"
                  style={{
                    backgroundColor: "#141415",
                    border: "1px solid #3D3D3D",
                  }}
                >
                  <div className="subtitle pb-3">Product Details</div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>License</Col>
                    <Col className="d-flex">
                      <div>{thisProduct?.license}</div>
                      <span className="ms-2">
                        <img
                          src={link_news}
                          alt="link"
                          style={styles.linkIcon}
                          // onClick={() => navigate("/license/")}
                          onClick={() => handleNavigationClick("/license/")}
                        />
                      </span>
                    </Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>Published Date</Col>
                    <Col>
                      {moment(thisProduct?.modified_at).format("DD/MM/YYYY")}
                    </Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>Animated</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_animated ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_animated ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>Rigged</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_rigged ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_rigged ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>AR / VR / Low Poly</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_lowPoly ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_lowPoly ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>PBR</Col>
                    <Col>
                      <Image
                        src={thisProduct?.is_pbr ? checkedtrue : checkedfalse}
                        alt={thisProduct?.is_pbr ? "Available" : "Unavailable"}
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>Geometry</Col>
                    <Col>{thisProduct?.geometry}</Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>Polygons</Col>
                    <Col>{thisProduct?.polygons}</Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>Vertices</Col>
                    <Col>{thisProduct?.vertices}</Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>Texture</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_texture ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_texture ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>Material</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_material ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_material ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>UV Mapping</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_uvMapping ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_uvMapping
                            ? "Available"
                            : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>Unwrapped UVs</Col>
                    <Col>{thisProduct?.unwrapped_uvs}</Col>
                  </div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>Plugins</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_plugin ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_plugin ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                </Card>
                <Card
                  className="my-5 py-4 px-4 border-R20 text-start text-white"
                  style={{
                    backgroundColor: "#141415",
                    border: "1px solid #3D3D3D",
                  }}
                >
                  <div className="subtitle">File Details</div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col md={4}>Software</Col>
                    <Col md={8}>{thisProduct?.software_used}</Col>
                  </div>
                  {/* <div className="my-3 body-text2 d-flex justify-content-between">
                    <div>Contains</div>
                    <div>
                      {fileExtensions?.map((extension) => {
                        return (
                          <div>
                            {
                              thisProduct?.file_statistics?.extension_stats[
                              extension
                              ]
                            }{" "}
                            x {extension}
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col md={4}>Contains</Col>
                    <Col md={8} className="overflow-scroll">
                      {thisProduct?.file_names?.map((item) => {
                        return <div>{item}</div>;
                      })}
                    </Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col md={4}>Size</Col>
                    <Col md={8}>{thisProduct?.file_statistics?.total_size}</Col>
                  </div>
                </Card>
                <Card
                  className={`${
                    thisProduct?.tags_data?.length === 0
                      ? "d-none"
                      : "my-4 py-4 px-4 border-R20 text-start"
                  } text-white`}
                  style={{
                    backgroundColor: "#141415",
                    border: "1px solid #3D3D3D",
                  }}
                >
                  <div className="subtitle">Product Tags</div>
                  <div className="my-3 body-text2">
                    <div>
                      {thisProduct?.tags_data?.map((tag) => {
                        return (
                          <Badge
                            className="py-2 px-4 m-1 body-text2"
                            bg="black-gray"
                          >
                            {tag?.name}
                          </Badge>
                        );
                      })}
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Breakpoint>
          <Breakpoint medium down>
            <div className="px-lg-4 px-4">
              <Card
                className="my-2 d-flex flex-column align-items-center align-items-center h-100 border-R20 position-relative"
                dark={true}
                style={{
                  backgroundColor: "#141415",
                  padding: "var(--bs-gutter-x, 0.75rem)  !important",
                }}
              >
                <div
                  className="w-100 d-flex justify-content-end align-items-end position-absolute"
                  style={{ top: -20, right: -20, zIndex: 20 }}
                >
                  <ToggleButton
                    type="favorite"
                    array={favoriteProduct}
                    handleFavorite={handleFavorite}
                  />
                </div>
                <CardContent
                  className="w-100 d-flex align-items-center justify-content-center"
                  style={{ height: 200, backgroundColor: "#141415" }}
                >
                  <ZSlider
                    type="mobile-product"
                    items={thisProduct?.productimage_set}
                  />
                </CardContent>
                {thisProduct?.is_threed_enabled && (
                  <div
                    className="w-100 d-flex justify-content-end align-items-end position-absolute"
                    style={{ bottom: 5, left: -5 }}
                  >
                    <IconButton size="large" color="var(--warning)">
                      <a
                        onClick={
                          () => {
                            handleNavigationClick(
                              `/3dmodels/${thisCategory?.slug}/${thisProduct?.slug}/viewer/`
                            );
                          }
                          // handleNavigationClick("  `/3dmodels/${thisCategory?.slug}/${thisProduct?.slug}/viewer/`")
                        }
                        href={`/3dmodels/${thisCategory?.slug}/${thisProduct?.slug}/viewer/`}
                      >
                        <Image
                          src={view3d_znanye}
                          alt="View 3D"
                          style={{ minWidth: 40 }}
                        />
                      </a>
                    </IconButton>
                  </div>
                )}
              </Card>
            </div>
            <Row
              className="text-white d-flex"
              style={{
                paddingLeft: "var(--bs-gutter-x, 0.75rem)",
                paddingRight: "var(--bs-gutter-x, 0.75rem)",
              }}
            >
              <Col>
                <Row className="d-flex justify-content-center align-items-start">
                  <div className="col-6">
                    <div className="d-flex flex-column justify-content-start align-items-start">
                      <div className="body-text2 mt-3 mb-2">Product Name</div>
                      <div className="subtitle text-start">
                        {thisProduct?.title}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex flex-column justify-content-start align-items-start">
                      <div className="body-text2  mt-3 mb-2">Views</div>
                      <div className="subtitle">{thisProduct?.view_count}</div>
                    </div>
                  </div>
                </Row>
                {/* old */}
                {/* <div className="body-text2 mt-5 mb-2">Product Name</div>
                  <div className="subtitle text-start">
                    {thisProduct?.title}
                  </div>
                  
                  <div className="body-text2 mt-5 mb-2">File Format Available</div>
                  <div className="subtitle text-start">
                    {fileExtensions?.map((extension) => {
                      return <>{extension} </>;
                    })}
                  </div> */}
                {/* old */}

                <Row className="d-flex justify-content-center align-items-start">
                  <div className="col-6">
                    <div className="d-flex flex-column justify-content-start align-items-start">
                      <div className="body-text2 mt-5 mb-2">
                        File Format Available
                      </div>
                      <div className="subtitle text-start">
                        {fileExtensions?.map((extension) => {
                          return <>{extension} </>;
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex flex-column justify-content-start align-items-start">
                      <div className="body-text2 mt-5  mb-2">Price</div>
                      <div className="d-flex justify-content-center align-items-start">
                        <div className="subtitle text-primary text-shadow">
                          {bestCoupon ? (
                            bestCoupon?.coupon_category === "ABSOLUTE" ? (
                              getSymbolFromCurrency(currency.name) +
                              (
                                (thisProduct?.price - bestCoupon?.flat_off) *
                                currency.rate
                              ).toFixed(2)
                            ) : (
                              getSymbolFromCurrency(currency.name) +
                              (
                                (thisProduct?.price -
                                  (thisProduct?.price * bestCoupon?.flat_off) /
                                    100) *
                                currency.rate
                              ).toFixed(2)
                            )
                          ) : (
                            <p>
                              {getSymbolFromCurrency(currency.name) +
                                (thisProduct?.price * currency.rate).toFixed(2)}
                            </p>
                          )}
                        </div>
                        <div>
                          <div
                            className={`${bestCoupon ? "d-block" : "d-none"}`}
                          >
                            <div className="d-flex bg-primary ms-2 text-black flex-row justify-content-center align-items-center">
                              <div style={{ fontSize: 13, fontWeight: "600" }}>
                                {bestCoupon?.coupon_category === "ABSOLUTE"
                                  ? getSymbolFromCurrency(currency.name) +
                                    (
                                      bestCoupon?.flat_off * currency.rate
                                    ).toFixed(2) +
                                    " off"
                                  : bestCoupon?.flat_off + "% off"}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              bestCoupon ? "d-block" : "d-none"
                            } h4 text-info`}
                          >
                            <strike>
                              {getSymbolFromCurrency(currency.name) +
                                (thisProduct?.price * currency.rate).toFixed(2)}
                            </strike>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
              {/* <Col> */}
              {/* <div className="d-flex flex-column justify-content-center align-items-start">
                  {/* FOR SOLD COUNT */}
              {/* <div className="body-text2 mt-5">Views/{soldCount > 0 ? "Sold Count" : ""}</div> */}
              {/* <div className="body-text2 mt-5 mb-2">Views</div> */}
              {/* FOR SOLD COUNT */}
              {/* <div className="subtitle">{thisProduct?.view_count}/{soldCount > 0 ? soldCount : ""}</div> */}
              {/* <div className="subtitle">{thisProduct?.view_count}</div> */}
              {/* <div className="body-text2 mt-5  mb-2">Price</div> */}
              {/* <div className="d-flex justify-content-center align-items-start"> */}
              {/* <div className="subtitle text-primary text-shadow">
                {bestCoupon ? (
                  bestCoupon?.coupon_category === "ABSOLUTE" ? (
                    getSymbolFromCurrency(currency.name) +
                    (
                      (thisProduct?.price - bestCoupon?.flat_off) *
                      currency.rate
                    ).toFixed(2)
                  ) : (
                    getSymbolFromCurrency(currency.name) +
                    (
                      (thisProduct?.price -
                        (thisProduct?.price * bestCoupon?.flat_off) / 100) *
                      currency.rate
                    ).toFixed(2)
                  )
                ) : (
                  <p>
                    {getSymbolFromCurrency(currency.name) +
                      (thisProduct?.price * currency.rate).toFixed(2)}
                  </p>
                )}
              </div>
              <div>
                <div className={`${bestCoupon ? "d-block" : "d-none"}`}>
                  <div className="d-flex bg-primary ms-2 text-black flex-row justify-content-center align-items-center">
                    <div style={{ fontSize: 13, fontWeight: "600" }}>
                      {bestCoupon?.coupon_category === "ABSOLUTE"
                        ? getSymbolFromCurrency(currency.name) +
                          (bestCoupon?.flat_off * currency.rate).toFixed(2) +
                          " off"
                        : bestCoupon?.flat_off + "% off"}
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    bestCoupon ? "d-block" : "d-none"
                  } h4 text-info`}
                >
                  <strike>
                    {getSymbolFromCurrency(currency.name) +
                      (thisProduct?.price * currency.rate).toFixed(2)}
                  </strike>
                </div>
              </div> */}
              {/* </div> */}
              {/* </div> */}
              {/* </Col> */}
            </Row>
            <Row
              style={{
                paddingLeft: "var(--bs-gutter-x, 0.75rem)",
                paddingRight: "var(--bs-gutter-x, 0.75rem)",
              }}
            >
              <Col xs={12} className="text-white text-start">
                <div className="font-size18 mt-5 mb-3">Product Description</div>
                <div className="body-text3">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: thisProduct?.description,
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Breakpoint>

          <Breakpoint medium down>
            <Card
              className="my-2 text-start p-0 text-white"
              dark={true}
              style={{ backgroundColor: "#141415" }}
            >
              <Row>
                <Col
                  xs={12}
                  className="text-white text-start"
                  style={{
                    padding: "32px",
                    borderTop: "1px solid #3D3D3D",
                    borderBottom: "1px solid #3D3D3D",
                  }}
                >
                  <div className="subtitle pb-3">Product Details</div>
                  <div className="my-2 body-text2 d-flex justify-content-between">
                    <Col>License</Col>
                    <Col className="d-flex">
                      <div>{thisProduct?.license}</div>
                      <span className="ms-2">
                        <img
                          src={link_news}
                          alt="link"
                          style={styles.linkIcon}
                          // onClick={() => navigate("/license/")}
                          onClick={() => handleNavigationClick("/license/")}
                        />
                      </span>
                    </Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>Published Date</Col>
                    <Col>
                      {moment(thisProduct?.modified_at).format("DD/MM/YYYY")}
                    </Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>Animated</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_animated ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_animated ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>Rigged</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_rigged ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_rigged ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>AR / VR / Low Poly</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_lowPoly ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_lowPoly ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>PBR</Col>
                    <Col>
                      <Image
                        src={thisProduct?.is_pbr ? checkedtrue : checkedfalse}
                        alt={thisProduct?.is_pbr ? "Available" : "Unavailable"}
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>Geometry</Col>
                    <Col>{thisProduct?.geometry}</Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>Polygons</Col>
                    <Col>{thisProduct?.polygons}</Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>Vertices</Col>
                    <Col>{thisProduct?.vertices}</Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>Texture</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_texture ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_texture ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>Material</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_material ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_material ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>UV Mapping</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_uvMapping ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_uvMapping
                            ? "Available"
                            : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>Unwrapped UVs</Col>
                    <Col>{thisProduct?.unwrapped_uvs}</Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col>Plugins</Col>
                    <Col>
                      <Image
                        src={
                          thisProduct?.is_plugin ? checkedtrue : checkedfalse
                        }
                        alt={
                          thisProduct?.is_plugin ? "Available" : "Unavailable"
                        }
                        style={styles.checkedIcons}
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  className="text-white text-start"
                  style={{
                    padding: "32px",
                  }}
                >
                  <div className="subtitle">File Details</div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col xs={4}>Software</Col>
                    <Col xs={8}>{thisProduct?.software_used}</Col>
                  </div>

                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col xs={4}>Contains</Col>
                    <Col xs={8} className="overflow-scroll">
                      {thisProduct?.file_names?.map((item) => {
                        return <div>{item}</div>;
                      })}
                    </Col>
                  </div>
                  <div className="my-3 body-text2 d-flex justify-content-between">
                    <Col xs={4}>Size</Col>
                    <Col xs={8}>{thisProduct?.file_statistics?.total_size}</Col>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  className={`${
                    thisProduct?.tags_data?.length === 0
                      ? "d-none"
                      : "text-start"
                  }text-white`}
                  style={{
                    borderTop: "1px solid #3D3D3D",
                    borderBottom: "1px solid #3D3D3D",
                    padding: "32px",
                  }}
                >
                  <div className="subtitle">Product Tags</div>
                  <div className="my-3 body-text2">
                    <div>
                      {thisProduct?.tags_data?.map((tag) => {
                        return (
                          <Badge
                            className="py-2 px-4 m-1 body-text2"
                            bg="black-gray"
                          >
                            {tag?.name}
                          </Badge>
                        );
                      })}
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Breakpoint>

          <Row className="my-5">
            <Col sm={9}></Col>
            <Col sm={3} className="mx-sm-0 px-me-0"></Col>
            <Breakpoint medium down>
              <Row>
                <Col
                  sm={2}
                  style={{ paddingLeft: "32px", paddingRight: "32px" }}
                  className="text-start text-white"
                >
                  <div
                    style={{
                      fontSize: "32px",
                      fontWeight: 500,
                      lineHeight: "45px",
                    }}
                    className="mt-3"
                  >
                    {thisProduct?.total_rating_count > 0
                      ? (
                          (thisProduct?.five_star_count * 5 +
                            thisProduct?.four_star_count * 4 +
                            thisProduct?.three_star_count * 3 +
                            thisProduct?.two_star_count * 2 +
                            thisProduct?.one_star_count * 1) /
                          thisProduct?.total_rating_count
                        ).toPrecision(2)
                      : 0}
                  </div>
                  {thisProduct && (
                    <ReactStars
                      count={5}
                      onChange={(e) => setRating(e)}
                      size={24}
                      value={(
                        (thisProduct?.five_star_count * 5 +
                          thisProduct?.four_star_count * 4 +
                          thisProduct?.three_star_count * 3 +
                          thisProduct?.two_star_count * 2 +
                          thisProduct?.one_star_count * 1) /
                        thisProduct?.total_rating_count
                      ).toPrecision(1)}
                      isHalf={true}
                      edit={false}
                      activeColor="#f78d1d"
                    />
                  )}
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: "1.5px",
                    }}
                  >
                    RATED BY - {thisProduct?.total_rating_count} USERS
                  </p>
                </Col>
                <Col
                  xs={10}
                  // style={{ paddingLeft: "32px", paddingRight: "32px" }}
                  className="text-start text-white mt-3 mx-4"
                >
                  RATED
                  <Row className="py-1 d-flex justify-content-start align-items-center">
                    <Col xs={1} className="text-end">
                      5
                    </Col>
                    <Col xs={8} className="text-start">
                      <Card
                        style={{
                          backgroundColor: "#3d3d3d",
                          width: 300,
                          height: 14,
                          borderRadius: 7,
                        }}
                      >
                        {thisProduct?.five_star_count > 0 && (
                          <Card
                            style={{
                              backgroundColor: "#fff",
                              width:
                                thisProduct?.five_star_count > 0
                                  ? (thisProduct?.five_star_count * 300) /
                                    thisProduct?.total_rating_count
                                  : 0,
                              height: 14,
                              borderRadius: 7,
                            }}
                          />
                        )}
                      </Card>
                    </Col>
                  </Row>
                  <Row className="py-1 d-flex justify-content-start align-items-center">
                    <Col xs={1} className="text-end">
                      4
                    </Col>
                    <Col xs={8} className="text-start">
                      <Card
                        style={{
                          backgroundColor: "#3d3d3d",
                          width: 300,
                          height: 14,
                          borderRadius: 7,
                        }}
                      >
                        {thisProduct?.four_star_count > 0 && (
                          <Card
                            style={{
                              backgroundColor: "#fff",
                              width:
                                thisProduct?.four_star_count > 0
                                  ? (thisProduct?.four_star_count * 300) /
                                    thisProduct?.total_rating_count
                                  : 0,
                              height: 14,
                              borderRadius: 7,
                            }}
                          />
                        )}
                      </Card>
                    </Col>
                  </Row>
                  <Row className="py-1 d-flex justify-content-start align-items-center">
                    <Col xs={1} className="text-end">
                      3
                    </Col>
                    <Col xs={8} className="text-start">
                      <Card
                        style={{
                          backgroundColor: "#3d3d3d",
                          width: 300,
                          height: "14px",
                          borderRadius: "7px",
                        }}
                      >
                        {thisProduct?.three_star_count > 0 && (
                          <Card
                            style={{
                              backgroundColor: "#fff",
                              width:
                                thisProduct?.three_star_count > 0
                                  ? (thisProduct?.three_star_count * 300) /
                                    thisProduct?.total_rating_count
                                  : 0,
                              height: "14px",
                              borderRadius: "7px",
                            }}
                          />
                        )}
                      </Card>
                    </Col>
                  </Row>
                  <Row className="py-1 d-flex justify-content-start align-items-center">
                    <Col xs={1} className="text-end">
                      2
                    </Col>
                    <Col xs={8} className="text-start">
                      <Card
                        style={{
                          backgroundColor: "#3d3d3d",
                          width: 300,
                          height: "14px",
                          borderRadius: "7px",
                        }}
                      >
                        {thisProduct?.two_star_count > 0 && (
                          <Card
                            style={{
                              backgroundColor: "#fff",
                              width:
                                thisProduct?.two_star_count > 0
                                  ? (thisProduct?.two_star_count * 300) /
                                    thisProduct?.total_rating_count
                                  : 0,
                              height: "14px",
                              borderRadius: "7px",
                            }}
                          />
                        )}
                      </Card>
                    </Col>
                  </Row>
                  <Row className="py-1 d-flex justify-content-start align-items-center">
                    <Col xs={1} className="text-end">
                      1
                    </Col>
                    <Col xs={8} className="text-start">
                      <Card
                        style={{
                          backgroundColor: "#3d3d3d",
                          width: 300,
                          height: "14px",
                          borderRadius: "7px",
                        }}
                      >
                        {thisProduct?.one_star_count > 0 && (
                          <Card
                            style={{
                              backgroundColor: "#fff",
                              width:
                                thisProduct?.one_star_count > 0
                                  ? (thisProduct?.one_star_count * 300) /
                                    thisProduct?.total_rating_count
                                  : 0,
                              height: "14px",
                              borderRadius: "7px",
                            }}
                          />
                        )}
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div>
                <div className="d-flex mt-4">
                  <div
                    className={`${
                      reviewComment === "review" ? "text-primary" : "text-white"
                    } subtitle d-flex flex-column mx-4 cursor-pointer`}
                    onClick={() => setReviewComment("review")}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "27px",
                        letterSpacing: 0.15,
                      }}
                    >
                      {reviewsCount > 0 ? reviewsCount : ""} Reviews
                    </span>
                    <span
                      className={`${
                        reviewComment === "review" ? "d-block" : ""
                      } mt-2`}
                      style={
                        reviewComment === "review"
                          ? styles.selectedSectionThree
                          : styles.unselectedSectionThree
                      }
                    />
                  </div>
                  <div
                    className={`${
                      reviewComment === "comment"
                        ? "text-primary"
                        : "text-white"
                    } subtitle d-flex flex-column mx-4 cursor-pointer`}
                    onClick={() => {
                      setReviewComment("comment");
                      loadComments();
                    }}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: "27px",
                        letterSpacing: 0.15,
                      }}
                    >
                      {productCommentSet?.results?.length > 0
                        ? productCommentSet?.results?.length
                        : ""}{" "}
                      Comment
                    </span>
                    <span
                      className={`${
                        reviewComment === "comment" ? "d-block" : ""
                      } mt-2`}
                      style={
                        reviewComment === "comment"
                          ? styles.selectedSectionThree
                          : styles.unselectedSectionThree
                      }
                    />
                  </div>
                </div>
              </div>
              {reviewComment === "review" && (
                <>
                  <Container
                    className={`${
                      isReviewAllowed ? "d-block" : "d-none"
                    } text-white text-start my-4 px-4`}
                    fluid
                  >
                    <RButton
                      className="text-white explore-button"
                      onClick={() => setAddReview(true)}
                    >
                      ADD REVIEW
                      <MdOutlineReviews className="mx-2" />
                    </RButton>
                  </Container>
                  <Container className="text-white text-start mt-2 pt-4" fluid>
                    {productReviews?.results?.length === 0 ? (
                      isAuthenticated ? (
                        <p>
                          No reviews posted yet! Be the first to post a review!
                        </p>
                      ) : (
                        <p>No reviews posted yet! Please login to Review!</p>
                      )
                    ) : (
                      <></>
                    )}
                    {productReviews?.results?.map((item, index) => {
                      return (
                        <div>
                          <div className="d-flex justify-content-between">
                            <div key={item.id}>
                              <div style={styles.commentReviewUser}>
                                {item.review_by_data.name
                                  ? item.review_by_data.name
                                  : "Anonymous User"}
                              </div>
                              <div className="body-text2 mt-2">
                                {item.review_message}
                              </div>
                            </div>
                            <div
                              className={`${
                                user.id === item.review_by
                                  ? "d-flex justify-content-center align-items-end cursor-pointer"
                                  : "d-none"
                              }`}
                            >
                              <AiOutlineDelete
                                size={20}
                                onClick={() => deleteReview(item.id)}
                              />
                            </div>
                          </div>
                          <hr
                            className={`${
                              index === reviews.length - 1
                                ? "d-none"
                                : "d-block"
                            }`}
                          />
                        </div>
                      );
                    })}
                    <div
                      className={`${
                        productReviews.next === null ? "d-none" : "d-block"
                      } subtitle text-primary text-center my-5 poppins-300 cursor-pointer`}
                      onClick={() => loadNextReviews()}
                    >
                      {reviewsCount > 0 && (
                        <div>
                          LOAD MORE REVIEWS <BsChevronDoubleDown size="20" />{" "}
                        </div>
                      )}
                    </div>
                  </Container>
                </>
              )}
              {reviewComment === "comment" && (
                <>
                  <Container className="my-5" fluid>
                    {isAuthenticated && (
                      <Card
                        style={{
                          backgroundColor: "#0d0d0d",
                          border: "1px solid #3D3D3D",
                          borderRadius: "16px",
                          minHeight: "96px",
                        }}
                      >
                        <Row>
                          <Col>
                            <Form ref={form}>
                              <Form.Group
                                className="py-2"
                                controlId="formComment"
                              >
                                <Form.Control
                                  as="textarea"
                                  rows={1}
                                  type="text"
                                  placeholder="Type your message"
                                  style={{
                                    backgroundColor: "#0d0d0d",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "16px",
                                    resize: "none",
                                  }}
                                  onChange={(e) =>
                                    setProductComment(e.target.value)
                                  }
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col
                            sm={1}
                            className="d-flex justify-content-end py-2 me-2"
                          >
                            <Card
                              className="d-flex justify-content-center align-items-center cursor-pointer"
                              style={{
                                marginRight: "15px",
                                backgroundColor: "#292929",
                                borderRadius: "8px",
                                border: "1px solid #3D3D3D",
                                minWidth: "54px",
                                minHeight: "34px",
                              }}
                              onMouseEnter={() => setChangingBroder(true)}
                              onMouseLeave={() => setChangingBroder(false)}
                              onClick={(e) => handleCommentSubmit(e)}
                            >
                              {isCommentSending ? (
                                <span>
                                  <Spinner
                                    className="me-2 text-white"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : (
                                <BsSend style={{ color: "#F7941D" }} />
                              )}
                            </Card>
                          </Col>
                        </Row>
                      </Card>
                    )}
                  </Container>

                  <Container className="text-white text-start px-4" fluid>
                    {productCommentSet?.results?.map((comment, index) => (
                      <div>
                        <div style={styles.commentReviewUser}>
                          {comment?.comment_by_data?.name}
                        </div>
                        <p className="mt-2 body-text2">
                          {comment?.comment_message}
                        </p>
                        <hr></hr>
                        {/* <RButton
                              onClick={(e) => {
                                setCommentID(comment.id);
                                handleCommentSubmit(e);
                              }}
                            >
                              Reply
                            </RButton> */}
                      </div>
                    ))}
                  </Container>
                </>
              )}
            </Breakpoint>
            <Breakpoint medium down>
              <div
                className="position-fixed"
                style={{
                  bottom: 0,
                  zIndex: 1,
                }}
              >
                <div>
                  <a
                    onClick={() => handleNavigationClick("/cart")}
                    className="text-decoration-none"
                    href="/cart"
                  >
                    <RButton
                      className="explore-button-cart btn btn-primary d-flex justify-content-center align-items-center position-fixed text-white w-50 py-4 px-0"
                      text
                      size="large"
                      dark={true}
                      style={{
                        right: 0,
                        bottom: 0,
                        border: 0,
                        borderRadius: 0,
                        zIndex: 1,
                        height: "60px",
                      }}
                      flat
                      onClick={addToCart}
                      onMouseEnter={() => setChangingBroder(true)}
                      onMouseLeave={() => setChangingBroder(false)}
                    >
                      BUY NOW
                    </RButton>
                  </a>
                </div>
                <div>
                  <AddToCartButton product={thisProduct} type="productmobile" />
                </div>
              </div>
            </Breakpoint>
          </Row>
        </Container>
        <Modal
          show={addReview}
          size="md"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          contentClassName="border-R20 user-select-none"
          onHide={() => setAddReview(false)}
        >
          <Modal.Body
            className="text-white"
            style={{
              backgroundColor: "#141415",
              border: "2.5px solid #2e2e2e",
              borderRadius: "20px 20px 20px 20px",
            }}
          >
            <div className="d-flex justify-content-center mt-2">
              <Card
                dark={true}
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#2e2e2e",
                  width: 100,
                  height: 100,
                  borderRadius: "16px",
                }}
              >
                <Image
                  src={
                    thisProduct?.productimage_set[0]
                      ? thisProduct?.productimage_set[0]?.thumbnail_file
                      : imagenotavailable
                  }
                  alt={thisProduct?.title}
                  style={{ maxHeight: 60, maxWidth: 60 }}
                />
              </Card>
            </div>
            <h4 className="m-3 p-0 d-flex justify-content-center">
              {thisProduct?.title}
            </h4>
            <div className="d-flex justify-content-center mt-4 body-text2">
              RATE THIS PRODUCT
            </div>
            <Form
              className="mt-3 d-flex flex-column justify-content-center align-items-center"
              ref={form}
              onSubmit={(e) => handleReviewSubmit(e)}
            >
              <ReactStars
                // className="m-4"
                size={40}
                count={5}
                onChange={(e) => setRating(e)}
                value={0}
                isHalf={false}
                color="#666666"
                activeColor="#f78d1d"
                emptyIcon={<ImStarEmpty />}
                halfIcon={<ImStarHalf />}
                fullIcon={<ImStarFull />}
              />
              <Card
                className="w-100"
                style={{
                  borderRadius: "16px",
                  border: "1px solid #3d3d3d",
                  backgroundColor: "#3d3d3d",
                }}
              >
                <textarea
                  // required
                  name="review_message"
                  className="form-control label textarea-input-box text-white wrapped-button w-100"
                  id="review"
                  placeholder="Write a review here (optional)"
                  rows="4"
                  style={{
                    backgroundColor: "#0d0d0d",
                    borderRadius: "16px",
                    border: "1px solid #3d3d3d",
                  }}
                ></textarea>
              </Card>
              <div className="text-center mt-4 d-flex justify-content-end">
                <RButton
                  className="text-white my-3 explore-button"
                  style={
                    {
                      // background: "transparent",
                      // textDecoration: "none",
                      // border: changingBorder ? "0px" : "0px",
                    }
                  }
                  onMouseEnter={() => setChangingBroder(true)}
                  onMouseLeave={() => setChangingBroder(false)}
                  type="submit"
                >
                  {/* <Button className="explore-button" dark={true} size="large"> */}
                  {isSending ? (
                    <span>
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      SUBMITTING ...
                    </span>
                  ) : (
                    <span className="poppins">POST</span>
                  )}
                  {/* </Button> */}
                </RButton>
              </div>
            </Form>
          </Modal.Body>
          <IconButton
            size="large"
            className="d-flex justify-content-center reviewCloseButtonRight position-absolute text-white"
            dark={true}
            bgColor="#2e2e2e"
            onClick={() => {
              setAddReview(false);
            }}
          >
            <AiOutlineClose size={20} />
          </IconButton>
        </Modal>
        <ImageModal
          visible={imageModalVisible}
          setVisible={setImageModalVisible}
          productimage_set={
            thisProduct?.productimage_set
              ? [...thisProduct?.productimage_set]
              : []
          }
          imagesrc={
            thisProduct?.productimage_set?.length > 0
              ? thisProduct?.productimage_set[currentImage]?.thumbnail_file
              : imagenotavailable
          }
          setCurrentImage={setCurrentImage}
          currentImage={currentImage}
        />
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  detailCard: {
    borderLeft: "1px solid #4e4e4e",
    borderRight: "1px solid #4e4e4e",
  },
  detailCardLeftBorder: {
    borderLeft: "1px solid #4e4e4e",
  },
  selectedSectionThree: {
    height: "2px",
    backgroundColor: "#f7941d",
  },
  unselectedSectionThree: {
    height: "0px",
  },
  commentReviewUser: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "1.25px",
    textAlign: "left",
  },
  commentReviewText: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.25px",
    textAlign: "left",
  },
  linkIcon: {
    width: 18 + "px",
    height: 18 + "px",
    cursor: "pointer",
  },
};

export default Product;

import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button as RButton,
  Spinner,
} from "react-bootstrap";
// import {
//     Button as NuButton,
//     Card,
//     CardContent,
//     IconButton,
//     CardAction,
//     Subtitle2,
//     Body2,
//     H5,
//     Badge,
// } from "ui-neumorphism";
// import { Button as BootstrapButton } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as actions from "actions/AuthActions";
import * as userActions from "actions/UserActions";
// import { AiOutlineClose } from "react-icons/ai";
// import { znanyelogin } from "assets";
import { useNavigate } from "react-router-dom";
import { login_mascot, celebrate_mascot, eye, eye_off } from "assets";

const LoginBox = ({ type }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [countryCode, setCountryCode] = useState("+91");
  const [timer, setTimer] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [login, setLogin] = useState(true);
  // const [resetPassword, setResetPassword] = useState(false);
  // const [accountCreated, setAccountCreated] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [emailSentNote, setEmailSentNote] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [eyeOn, setEyeOn] = useState(false);
  const [changingBorder, setChangingBroder] = useState(false);

  const [currency, setCurrency] = useState(
    useSelector((state) => state.authReducer.currency?.name?.toLowerCase())
  );
  const [countries, setCountries] = useState([]);
  const loginForm = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (type === "verify_seller") {
        // navigate("/sell-3d/uploadproducts");
        handleNavigationClick("/sell-3d/uploadproducts");
      } else {
        navigate("/");
      }
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //     dispatch(userActions.loadCategories());
  //     return () => {
  //         setTimer(0);
  //     };
  // }, []);

  const loadCountriesAsync = async () => {
    const response = await userActions.loadCountries();
    setCountries(response);
  };

  useEffect(() => {
    loadCountriesAsync();
  }, []);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  // return (
  //     <div>
  //         <div
  //             className="d-flex flex-row justify-content-center"
  //             style={{
  //                 backgroundColor: "#1F1F1F",
  //                 border: "2.5px solid #BEBEBE",
  //             }}
  //         >
  //             <div id="contained-modal-title-vcenter">
  //                 <Image src={znanyelogin} className="my-4" />
  //             </div>
  //         </div>

  //         <IconButton
  //             size="large"
  //             className="d-flex justify-content-center removeButtonRight position-absolute text-white"
  //             dark={true}
  //         >
  //             {/* <AiOutlineClose size={20} onClick={() => setLoginShow(false)} /> */}
  //         </IconButton>
  //         <div
  //             className="p-3"
  //             style={{
  //                 border: "2.5px solid #BEBEBE",
  //                 backgroundColor: "#2E2E2E",
  //             }}
  //         >
  //             <Form
  //                 ref={loginForm}
  //                 className="text-start"
  //             >
  //                 <div
  //                     className={`${isAuthenticated ? "d-none" : "d-block"
  //                         }`}
  //                 >
  //                     <Form.Group className="w-100">
  //                         <Form.Label className="text-white">Login</Form.Label>
  //                         <div className="d-flex flex-column">
  //                             {/* <Form.Select
  //                       required
  //                       name="phone_code"
  //                       type="text"
  //                       className="text-white subtitle my-3 pe-0 me-2"
  //                       style={{
  //                         background: "#141414",
  //                         width: 100,
  //                       }}
  //                       value={countryCode}
  //                       onChange={(e) => setCountryCode(e.target.value)}
  //                     >
  //                       {countries?.map((option) => {
  //                         return (
  //                           <option value={option.phone_code}>
  //                             {option.phone_code} {option.code}
  //                           </option>
  //                         );
  //                       })}
  //                     </Form.Select> */}
  //                             <Form.Control
  //                                 name="username"
  //                                 type="email"
  //                                 size="lg"
  //                                 placeholder="Enter Email"
  //                                 className="mb-3 text-white"
  //                                 style={{ background: "#141414", height: 50 }}
  //                                 onChange={(e) => {
  //                                     setUsername(e.target.value);
  //                                 }}
  //                             />
  //                             <Form.Control
  //                                 name="password"
  //                                 type="password"
  //                                 size="lg"
  //                                 placeholder="Enter Password"
  //                                 className="text-white"
  //                                 style={{ background: "#141414", height: 50 }}
  //                                 // onKeyDown={handleKeyDown}
  //                                 onChange={(e) => {
  //                                     setPassword(e.target.value);
  //                                     // if (e.target.value.length >= 8 && username.length == 10) {
  //                                     //   // setNext(true);
  //                                     // } else {
  //                                     //   // setNext(false);
  //                                     // }
  //                                 }}
  //                             />
  //                             <div className="d-flex justify-content-end align-items-center">
  //                                 <div className="text-white text-end my-2">
  //                                     <RButton className="wrapped-button" variant="link">
  //                                         Forgot Password
  //                                     </RButton>
  //                                 </div>
  //                             </div>
  //                         </div>
  //                     </Form.Group>
  //                     <div className="text-center">
  //                         <NuButton
  //                             className={`my-4 body-text2 explore-button`}
  //                             // disabled={!next}
  //                             size="large"
  //                             dark={true}
  //                             onClick={async (e) => {
  //                                 e.preventDefault();
  //                                 let email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //                                 if (username.match(email_regex)) {
  //                                     const response = await actions.loggingIn({
  //                                         email: username,
  //                                         password: password,
  //                                     });
  //                                     if (response) {
  //                                         dispatch(actions.login(response));
  //                                         setIsSending(false);
  //                                     }
  //                                 } else {
  //                                     toast.error("Please enter a valid email!");
  //                                 }
  //                             }}
  //                         >
  //                             <span className="poppins">LOGIN</span>
  //                         </NuButton>
  //                     </div>
  //                 </div>

  //                 {/* <div
  //                     className={`${!isAuthenticated ? "d-block" : "d-none"
  //                         }`}
  //                 >
  //                     <div className="d-flex justify-content-center align-items-center">
  //                         <div
  //                             className={`${timer != 0
  //                                 ? "text-muted"
  //                                 : "explore-text cursor-pointer"
  //                                 }`}
  //                             disabled={timer != 0}
  //                             onClick={async (e) => {
  //                                 if (timer == 0) {
  //                                     e.preventDefault();
  //                                     const response = await actions.sendOTP({
  //                                         username: username,
  //                                         phone_code: countryCode,
  //                                     });
  //                                     if (response) {
  //                                         setForOtp(true);
  //                                         setNext(false);
  //                                         setTimer(response.time_left);
  //                                     }
  //                                 }
  //                             }}
  //                         >
  //                             Resend OTP
  //                         </div>
  //                         <div
  //                             className={`${timer != 0 ? "text-white mx-2 caption" : "d-none"
  //                                 }`}
  //                         >
  //                             {`after ${timer} seconds`}
  //                         </div>
  //                     </div>
  //                     <div className="text-center">
  //                         <RButton
  //                             className="btn btn-link border-0 my-4 wrapped-button"
  //                             style={{
  //                                 background: "transparent",
  //                                 textDecoration: "none",
  //                             }}
  //                             type="submit"
  //                         >
  //                             <NuButton
  //                                 className={`body-text2 ${next ? "explore-button" : "znanye-button"
  //                                     }`}
  //                                 disabled={!next}
  //                                 size="large"
  //                                 dark={true}
  //                                 type="submit"
  //                                 onClick={() => {
  //                                     setNext(false);
  //                                 }}
  //                             >
  //                                 {isSending ? (
  //                                     <span>
  //                                         <Spinner
  //                                             // className="me-2"
  //                                             as="span"
  //                                             animation="border"
  //                                             size="sm"
  //                                             role="status"
  //                                             aria-hidden="true"
  //                                         />
  //                                         LOGGING ...
  //                                     </span>
  //                                 ) : (
  //                                     <span className="poppins">LOGIN</span>
  //                                 )}
  //                             </NuButton>
  //                         </RButton>
  //                     </div>
  //                 </div> */}
  //             </Form>
  //         </div>
  //     </div>
  // );
  return (
    <div
      className="w-dialog w-contentBox shadow"
      style={{ border: "1px solid black" }}
    >
      <Container className="w-contentBox shadow-lg overflow-hidden">
        <Row>
          <Col
            sm={6}
            className="p-lg-5 login_background overflow-auto verify-container"
          >
            <Form
              ref={loginForm}
              className={`text-start ${login ? "d-block" : "d-none"}`}
            >
              <div className={`${isAuthenticated ? "d-none" : "d-block"}`}>
                <Form.Group className="w-100">
                  <Form.Label className="text-white subtitle mt-4">
                    Login To Znanye
                  </Form.Label>
                  <p className="text-white caption">
                    Buy 3D models, Made By Awesome Artists
                  </p>
                  <div className="d-flex flex-column pt-4">
                    <Form.Control
                      name="username"
                      type="email"
                      size="lg"
                      placeholder="Enter Email"
                      className="mb-3 text-white"
                      style={{
                        background: "#141414",
                        height: 50,
                        borderColor: "#3D3D3D",
                        borderRadius: 10,
                      }}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                    <Form.Group className="d-flex justify-content-end align-items-center position-relative mb-4">
                      <Form.Control
                        name="password"
                        type={`${eyeOn ? "text" : "password"}`}
                        size="lg"
                        placeholder="Enter Password"
                        className="text-white"
                        style={{
                          background: "#141414",
                          height: 50,
                          borderColor: "#3D3D3D",
                          borderRadius: 10,
                        }}
                        // onKeyDown={handleKeyDown}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      {!eyeOn && (
                        <RButton
                          type="button"
                          className="wrapped-button position-absolute mx-2"
                          style={{ backgroundColor: "#141414", border: 0 }}
                          onKeyDown={(e) => e.preventDefault()}
                          onClick={(e) => {
                            e.preventDefault();
                            setEyeOn(true);
                          }}
                        >
                          <Image src={eye} alt="Eye" />
                        </RButton>
                      )}
                      {eyeOn && (
                        <RButton
                          type="button"
                          className="wrapped-button position-absolute mx-2"
                          style={{ backgroundColor: "#141414", border: 0 }}
                          onKeyDown={(e) => e.preventDefault()}
                          onClick={(e) => {
                            e.preventDefault();
                            setEyeOn(false);
                          }}
                        >
                          <Image src={eye_off} alt="Eye Off" />
                        </RButton>
                      )}
                    </Form.Group>
                    {/* <div className="text-white text-end my-2">
                                            <RButton className="wrapped-button caption" onClick={() => {
                                                setLogin(false);
                                                setForgotPassword(true);
                                                setCreateAccount(false);
                                                setEmailSentNote(false);
                                                setAccountCreated(false);
                                            }} variant="link">
                                                Forgot Password
                                            </RButton>
                                        </div> */}
                  </div>
                </Form.Group>
                <div className="text-center">
                  <RButton
                    className="mt-5 btn btn-ptimary explore-button-no-neo"
                    dark={true}
                    type="submit"
                    size="lg"
                    style={{ border: changingBorder ? "0px" : "0px" }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}
                    onClick={async (e) => {
                      e.preventDefault();
                      setIsSending(true);
                      let email_regex =
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                      if (username.match(email_regex)) {
                        const response = await actions.loggingIn({
                          email: username,
                          password: password,
                        });

                        if (response) {
                          // console.log(response)
                          dispatch(actions.login(response));
                          // setForOtp(true);
                          // setNext(false);
                          // setTimer(response.time_left);
                        }
                      } else {
                        toast.error("Please enter a valid email!");
                      }
                      setIsSending(false);
                    }}
                  >
                    {isSending ? (
                      <span>
                        <Spinner
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        LOGGING ...
                      </span>
                    ) : (
                      <span className="poppins">GET ME IN</span>
                    )}
                  </RButton>
                </div>
                {/* <div className="d-flex justify-content-center align-items-center">
                                    <div className="text-white caption">Already have an account! </div>
                                    <RButton className="wrapped-button caption" onClick={() => {
                                        // setCreateAccount(true);
                                        // setForgotPassword(false);
                                        // setLogin(false);
                                        // setEmailSentNote(false);
                                        // setAccountCreated(false);
                                    }} variant="link">
                                        Log In
                                    </RButton>
                                    <div
                                        className={`${timer != 0 ? "text-white mx-2 caption" : "d-none"
                                            }`}
                                    >
                                        {`after ${timer} seconds`}
                                    </div>
                                </div> */}
              </div>
            </Form>
            <div
              className={`${
                successful && !isAuthenticated
                  ? "d-flex flex-column justify-content-center align-items-center h-100"
                  : "d-none"
              }`}
            >
              <div
                className="text-white d-flex align-items-center h4 m-4"
                style={{ fontWeight: 400 }}
              >
                <p>Yeahhh !!! Your password has been successfully reset!</p>
              </div>
              {/* <div>
                                <p className="explore-text">Login Here</p>
                            </div> */}
            </div>
          </Col>
          <Col
            sm={6}
            className="d-flex flex-column justify-content-between align-items-center"
          >
            <div
              className={`${
                successful ? "d-none" : "d-flex"
              } justify-content-center align-items-end h-100`}
            >
              <div className="p-4 text-white notch-div text-center my-4">
                {login && (
                  <span>
                    Hope you find <br /> what you want !
                  </span>
                )}
              </div>
              <div className="notch"></div>
            </div>
            <div className="h-100 d-flex align-items-center">
              <div className="w-100 overflow-hidden">
                {login && (
                  <Image
                    className="mw-100"
                    src={login_mascot}
                    alt="Login Mascot"
                  />
                )}
                {successful && (
                  <Image
                    className="mw-100"
                    src={celebrate_mascot}
                    alt="Celebrating Mascot"
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginBox;

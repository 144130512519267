import React, { useState } from "react";
import { Form, Spinner, Col, Button as RButton } from "react-bootstrap";
import { Button, Card, IconButton } from "ui-neumorphism";
import { toast } from "react-toastify";
import ifsc from "ifsc";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const BankAccount = (props) => {
    const {
        seller,
        mode,
        form,
        // handleBankDetailsSubmit,
        // handleBankDetailsUpdate,
        next,
        isPaypal,
        isWire,
        setStep,
    } = props;
    const [ifscCode, setIfscCode] = useState(
        seller?.bankaccount ? seller?.bankaccount?.ifsc_code : ""
    );
    const [bankName, setBankName] = useState(
        seller?.bankaccount ? seller?.bankaccount?.bank_name : ""
    );
    const [bankLocation, setBankLocation] = useState(
        seller?.bankaccount ? seller?.bankaccount?.bank_location : ""
    );
    const [branchName, setBranchName] = useState(
        seller?.bankaccount ? seller?.bankaccount?.branch_name : ""
    );

    const accountOptions = [
        { key: "savings", value: "SAVINGS" },
        { key: "current", value: "CURRENT" },
    ];

    if (isPaypal) {
        return (
            <>
                {/* <Form
                    ref={form}
                    className={`${mode === "update" ? "w-100" : "my-5 body-text2 text-white"}`}
                onSubmit={(e) => {
                    e.preventDefault();
                    if (seller?.bankaccount) {
                        handleBankDetailsUpdate(e);
                    } else {
                        handleBankDetailsSubmit(e);
                    }
                }}
                > */}
                <div
                // className="d-flex justify-content-center"
                // style={styles.fieldCenter}
                >
                    <Col></Col>
                    <Col md={4}>
                        <Form.Group className="mt-4 mx-5">
                            <Form.Label className="text-white body-text2">
                                Paypal Email Address
                            </Form.Label>
                            <Form.Control
                                required
                                name="paypal_email"
                                type="text"
                                className="text-white body-text1"
                                placeholder="Enter PayPal Email Address"
                                defaultValue={seller ? seller?.bankaccount?.paypal_email : ""}
                                style={styles.fieldStyle}
                            />
                        </Form.Group>
                    </Col>
                    <Col></Col>
                </div>
                {/* <div
                        className={`${mode === "create" ? "d-flex justify-content-end" : "d-none"
                            }`}
                        style={styles.nextBackPayPalButton}
                    >
                        <RButton
                            className="btn btn-link border-0 wrapped-button mx-2"
                            style={{ background: "transparent", textDecoration: "none" }}
                            onClick={(e) => {
                                e.preventDefault();
                                setStep(2);
                            }}
                        >
                            <IconButton
                                className="d-flex justify-content-center align-items-center explore-icon-button"
                                size="large"
                                dark={true}
                                text={false}
                            >
                                <IoIosArrowBack size={20} />
                            </IconButton>
                        </RButton>
                        <RButton
                            className="btn btn-link border-0 wrapped-button mx-2"
                            style={{ background: "transparent", textDecoration: "none" }}
                            type="submit"
                        >
                            <IconButton
                                className="d-flex justify-content-center align-items-center explore-icon-button"
                                size="large"
                                dark={true}
                                text={false}
                            // type="submit"
                            >
                                <IoIosArrowForward size={20} />
                            </IconButton>
                        </RButton>
                    </div> */}
                {/* <div className={`${mode === "update" ? "text-center" : "d-none"}`}> */}
                {/* <div className="text-center">
                    <RButton
                        className="btn btn-link border-0 wrapped-button"
                        style={{ background: "transparent", textDecoration: "none" }}
                        type="submit"
                    >
                        <RButton
                            className="explore-button-no-neo"
                            variant="dark"
                            size="lg"
                            type="submit"
                        >
                            {next ? (
                                <span>
                                    <Spinner
                                        className="me-2"
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    SAVING...
                                </span>
                            ) : (
                                <span className="poppins">SAVE CHANGES</span>
                            )}
                        </RButton>
                    </RButton>
                </div> */}
                {/* </Form> */}
            </>
        );
    }

    return (
        <>
            {/* <div
                className={`${mode === "create" ? "d-flex justify-content-center pt-5" : "d-none"
                    }`}
            >
                <h3 className="text-white p-lg-2 mt-lg-0 mt-5">
                    Kindly Enter Your Bank Details
                </h3>
            </div> */}
            {/* <div className={`${mode === "update" ? "mx-5" : "d-none"}`}>
                <h3 className="text-white p-lg-2 mt-lg-0">Bank Details</h3>
            </div> */}
            {/* <Form
                ref={form}
                className={`${mode === "update" ? "my-4" : "my-5 body-text2 text-white"}`}
                onSubmit={(e) => {
                    if (mode === "update") {
                        handleBankDetailsUpdate(e);
                    } else {
                        handleBankDetailsSubmit(e);
                    }
                }}
            > */}
            <div
                className="d-flex justify-content-center mt-4"
                style={{ paddingTop: 0 }}
            >
                <Col>
                    <Form.Group className="mx-5">
                        <Form.Label className="text-white body-text2">
                            Account Holder's Name:
                        </Form.Label>
                        <Form.Control
                            required
                            name="account_holder_name"
                            type="text"
                            className="text-white body-text1"
                            placeholder="Enter Account Holders Name"
                            defaultValue={
                                seller ? seller?.bankaccount?.account_holder_name : ""
                            }
                            style={styles.fieldStyle}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mx-5">
                        <Form.Label className="text-white body-text2">Account Type:</Form.Label>
                        <Form.Select
                            required
                            name="account_type"
                            type="text"
                            className="text-white body-text1 bank-type-select"
                        >
                            {accountOptions.map((option, index) => {
                                return (
                                    <option
                                        value={
                                            seller
                                                ? seller?.bankaccount?.account_type
                                                : option.value
                                        }
                                        key={index}
                                    >
                                        {option.value}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mx-5">
                        <Form.Label className="text-white body-text2">Swift( BIC )</Form.Label>
                        <Form.Control
                            required
                            name="swift"
                            type="text"
                            className="text-white body-text1"
                            placeholder="Enter Swift"
                            defaultValue={seller ? seller?.bankaccount?.swift : ""}
                            style={styles.fieldStyle}
                        />
                    </Form.Group>
                </Col>
            </div>
            <div className="d-flex justify-content-center mt-4">
                <Col>
                    <Form.Group className="mx-5">
                        <Form.Label className="text-white">Account Number:</Form.Label>
                        <Form.Control
                            required
                            name="account_number"
                            type="password"
                            className="text-white body-text1"
                            placeholder="Enter Account Number"
                            defaultValue={seller ? seller?.bankaccount?.account_number : ""}
                            style={styles.fieldStyle}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mx-5">
                        <Form.Label className="text-white">
                            Re-enter Account Number:
                        </Form.Label>
                        <Form.Control
                            required
                            name="confirm_account_number"
                            type="text"
                            className="text-white body-text1"
                            placeholder="Re-Enter Account Number"
                            defaultValue={seller ? seller?.bankaccount?.account_number : ""}
                            style={styles.fieldStyle}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mx-5">
                        <Form.Label className="text-white">IFSC Code:</Form.Label>
                        <Form.Control
                            required
                            name="ifsc_code"
                            type="text"
                            className="text-white body-text1"
                            placeholder="Enter IFSC Code"
                            maxLength={11}
                            value={ifscCode}
                            defaultValue={seller ? seller?.bankaccount?.ifsc_code : ""}
                            style={styles.fieldStyle}
                            onChange={(e) => {
                                // console.log(e.target.value);
                                setIfscCode(e.target.value);
                                let code = e.target.value;
                                if (code.length === 11) {
                                    // console.log("We are in", code);
                                    if (ifsc.validate(code)) {
                                        ifsc.fetchDetails(code).then(function (res) {
                                            // console.log(res);
                                            setBankName(res["BANK"]);
                                            setBankLocation(res["ADDRESS"]);
                                            setBranchName(res["BRANCH"]);
                                        });
                                    } else {
                                        toast.error("Incorrect IFSC Code");
                                    }
                                }
                            }}
                        />
                    </Form.Group>
                </Col>
            </div>
            <div
                className="d-flex justify-content-center mt-4"
            >
                <Col>
                    <Form.Group className="mx-5">
                        <Form.Label className="text-white">Bank Name:</Form.Label>
                        <Form.Control
                            required
                            readOnly
                            name="bank_name"
                            type="text"
                            className="text-white body-text1"
                            placeholder="Bank Name"
                            value={bankName}
                            style={styles.fieldStyle}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mx-5">
                        <Form.Label className="text-white">Branch Name:</Form.Label>
                        <Form.Control
                            required
                            readOnly
                            name="branch_name"
                            type="text"
                            className="text-white body-text1"
                            placeholder="Branch Name"
                            value={branchName}
                            style={styles.fieldStyle}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mx-5">
                        <Form.Label className="text-white">Bank Location:</Form.Label>
                        <Form.Control
                            required
                            readOnly
                            name="bank_location"
                            as="textarea"
                            rows={2}
                            className="text-white body-text1"
                            placeholder="Bank Location"
                            value={bankLocation}
                            style={styles.fieldStyle}
                        />
                    </Form.Group>
                </Col>
            </div>
            {/* <div
                className={`${mode === "create" ? "d-flex justify-content-end" : "d-none"
                    }`}
                style={styles.nextBackButton}
            >
                <RButton
                    className="btn btn-link border-0 wrapped-button mx-3"
                    style={{ background: "transparent", textDecoration: "none" }}
                    onClick={(e) => {
                        e.preventDefault();
                        setStep(2);
                    }}
                >
                    <IconButton
                        className="d-flex justify-content-center align-items-center explore-icon-button"
                        size="large"
                        dark={true}
                        text={false}
                    >
                        <IoIosArrowBack size={20} />
                    </IconButton>
                </RButton>
                <RButton
                    className="btn btn-link border-0 wrapped-button"
                    style={{ background: "transparent", textDecoration: "none" }}
                    type="submit"
                >
                    <IconButton
                        className="d-flex justify-content-center align-items-center explore-icon-button"
                        size="large"
                        dark={true}
                        text={false}
                    >
                        <IoIosArrowForward size={20} />
                    </IconButton>
                </RButton>
            </div>
            <div
                // className={`${mode === "update" ? "text-center" : "d-none"}`}
                className="text-center"
                style={{ marginTop: 70 }}
            >
                <RButton
                    className="btn btn-link border-0 wrapped-button"
                    style={{ background: "transparent", textDecoration: "none" }}
                    type="submit"
                >
                    <Button
                        className="explore-button"
                        dark={true}
                        size="large"
                        type="submit"
                    >
                        {next ? (
                            <span>
                                <Spinner
                                    className="me-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                SAVING...
                            </span>
                        ) : (
                            <span className="poppins">SAVE CHANGES</span>
                        )}
                    </Button>
                </RButton>
            </div > */}
            {/* </Form> */}
        </>
    );

    // return (
    //     <>
    //         <div className="d-flex justify-content-center mt-5 text-shadow">
    //             <h3 className="text-white p-lg-2 mt-lg-0 mt-5">Bank Details</h3>
    //         </div>
    //         <Col lg={4}>
    //             <Card dark={true} >
    //                 <Form ref={form} className="px-5 py-4 my-5 body-text2 text-white" onSubmit={(e) => handleBankDetailsSubmit(e)}>
    //                     <Form.Group className="mt-4">
    //                         <Form.Label className="text-white">Account Holder's Name:</Form.Label>
    //                         <Form.Control
    //                             required
    //                             name="account_holder_name"
    //                             type="text"
    //                             className="text-white body-text1"
    //                             placeholder="Enter Account Holders Name"
    //                             defaultValue={seller ? seller?.bankaccount?.account_holder_name : "Tejas Ingale"}
    //                             style={{
    //                                 background: "#141414",
    //                                 padding: 10,
    //                                 border: 0,
    //                             }}
    //                         />
    //                     </Form.Group>
    //                     <Form.Group className="mt-4">
    //                         <Form.Label className="text-white">Account Type:</Form.Label>
    //                         <Form.Select
    //                             required
    //                             name="account_type"
    //                             type="text"
    //                             className="text-white body-text1 bg bg-black">
    //                             {accountOptions.map((option) => {
    //                                 return (
    //                                     <option value={seller ? seller?.bankaccount?.account_type : option.value}>{option.value}</option>
    //                                 );
    //                             })}
    //                         </Form.Select>
    //                     </Form.Group>
    //                     <Form.Group className="mt-4">
    //                         <Form.Label className="text-white">Account Number:</Form.Label>
    //                         <Form.Control
    //                             required
    //                             name="account_number"
    //                             type="password"
    //                             className="text-white body-text1"
    //                             placeholder="Enter Account Number"
    //                             defaultValue={seller ? seller?.bankaccount?.account_number : "900000123456789"}
    //                             style={{
    //                                 background: "#141414",
    //                                 padding: 10,
    //                                 border: 0,
    //                             }}
    //                         />
    //                     </Form.Group>
    //                     <Form.Group className="mt-4">
    //                         <Form.Label className="text-white">Re-enter Account Number:</Form.Label>
    //                         <Form.Control
    //                             required
    //                             name="confirm_account_number"
    //                             type="text"
    //                             className="text-white body-text1"
    //                             placeholder="Re-Enter Account Number"
    //                             defaultValue={seller ? seller?.bankaccount?.account_number : "900000123456789"}
    //                             style={{
    //                                 background: "#141414",
    //                                 padding: 10,
    //                                 border: 0,
    //                             }}
    //                         />
    //                     </Form.Group>
    //                     <Form.Group className="mt-4">
    //                         <Form.Label className="text-white">IFSC Code:</Form.Label>
    //                         <Form.Control
    //                             required
    //                             name="ifsc_code"
    //                             type="text"
    //                             className="text-white body-text1"
    //                             placeholder="Enter IFSC Code"
    //                             maxLength={11}
    //                             value={ifscCode}
    //                             defaultValue={seller ? seller?.bankaccount?.ifsc_code : "TES00000001"}
    //                             onChange={(e) => {
    //                                 console.log(e.target.value);
    //                                 setIfscCode(e.target.value);
    //                                 let code = e.target.value
    //                                 if (code.length === 11) {
    //                                     console.log("We are in", code);
    //                                     if (ifsc.validate(code)) {
    //                                         ifsc.fetchDetails(code).then(function (res) {
    //                                             console.log(res);
    //                                             setBankName(res["BANK"]);
    //                                             setBankLocation(res["ADDRESS"]);
    //                                             setBranchName(res["BRANCH"]);
    //                                         });
    //                                     } else {
    //                                         toast.error("Incorrect IFSC Code");
    //                                     }
    //                                 }
    //                             }}
    //                             style={{
    //                                 background: "#141414",
    //                                 padding: 10,
    //                                 border: 0,
    //                             }}
    //                         />
    //                     </Form.Group>
    //                     <Form.Group className="mt-4">
    //                         <Form.Label className="text-white">Bank Name:</Form.Label>
    //                         <Form.Control
    //                             required
    //                             readOnly
    //                             name="bank_name"
    //                             type="text"
    //                             className="text-white body-text1"
    //                             placeholder="Bank Name"
    //                             value={bankName}
    //                             style={{
    //                                 background: "#141414",
    //                                 padding: 10,
    //                                 border: 0,
    //                             }}
    //                         />
    //                     </Form.Group>
    //                     <Form.Group className="mt-4">
    //                         <Form.Label className="text-white">Bank Location:</Form.Label>
    //                         <Form.Control
    //                             required
    //                             readOnly
    //                             name="bank_location"
    //                             as="textarea" rows={3}
    //                             className="text-white body-text1"
    //                             placeholder="Bank Location"
    //                             value={bankLocation}
    //                             style={{
    //                                 background: "#141414",
    //                                 padding: 10,
    //                                 border: 0,
    //                             }}
    //                         />
    //                     </Form.Group>
    //                     <Form.Group className="mt-4">
    //                         <Form.Label className="text-white">Branch Name:</Form.Label>
    //                         <Form.Control
    //                             required
    //                             readOnly
    //                             name="branch_name"
    //                             type="text"
    //                             className="text-white body-text1"
    //                             placeholder="Branch Name"
    //                             value={branchName}
    //                             style={{
    //                                 background: "#141414",
    //                                 padding: 10,
    //                                 border: 0,
    //                             }}
    //                         />
    //                     </Form.Group>
    //                     <div className="text-center mt-4">
    //                         <RButton
    //                             className="btn btn-link my-3 p-0 border-0 wrapped-button"
    //                             style={{
    //                                 background: "transparent",
    //                                 textDecoration: "none",
    //                             }}
    //                             type="submit"
    //                         >
    //                             <Button
    //                                 className="explore-button"
    //                                 dark={true}
    //                                 size="large"
    //                                 type="submit"
    //                             >
    //                                 {next ? (
    //                                     <span>
    //                                         <Spinner
    //                                             className="me-2"
    //                                             as="span"
    //                                             animation="border"
    //                                             size="sm"
    //                                             role="status"
    //                                             aria-hidden="true"
    //                                         />
    //                                         {seller?.bankaccount ? "UPDATING..." : "SAVING..."}
    //                                     </span>
    //                                 ) : (
    //                                     <span className="poppins">{seller?.bankaccount ? "UPDATE" : "SAVE"}</span>
    //                                 )}
    //                             </Button>
    //                         </RButton>
    //                     </div>
    //                 </Form >
    //             </Card>
    //         </Col>
    //     </>
    // );
};

const styles = {
    fieldCenter: {
        paddingTop: 50,
        paddingBottom: 197,
    },
    nextBackButton: {
        marginRight: 100,
    },
    nextBackPayPalButton: {
        marginRight: 92,
    },
    fieldStyle: {
        background: "#0c0d0c",
        padding: 10,
        border: "1px solid #3d3d3d",
        borderRadius: "10px",
    }
};

export default BankAccount;

import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Card } from "ui-neumorphism";

import { MdArrowBack, MdClose } from "react-icons/md";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Error from "pages/Error/Error";
import { Loader } from "components";
import { Helmet } from "react-helmet";
import { Try3D } from "pages";
import * as actions from "actions/UserActions";

const Viewer = () => {
  let { productId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [errorPage, setErrorPage] = useState(false);
  const [product, setProduct] = useState(null);
  let previous_page = null;

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  if (location.state) {
    previous_page = location.state["previousPage"];
  }
  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";

  useEffect(async () => {
    try {
      // await axios.get(`${axios.defaults.baseURL}viewer/${productId}`);
      // setLoading(false);
      const response = await actions.loadProduct(productId);
      if (response) {
        setProduct(response);
      }
    } catch (err) {
      setErrorPage(true);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (errorPage) {
    return <Error />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <link
          rel="canonical"
          href={`https://www.znanye.com${location.pathname}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.znanye.com${location.pathname}`}
        />
      </Helmet>
      <Container
        fluid
        className="d-flex flex-column align-items-center h-100 home-background-categories p-0"
        style={styles.parentContainer}
      >
        <Container fluid className="position-relative">
          <div
            className="position-absolute p-2 cursor-pointer shadow-lg"
            style={{
              top: 20,
              right: 20,
              backgroundColor: "#323232",
              borderRadius: 50,
            }}
            onClick={() => {
              if (previous_page === "seller") {
                navigate(-1);
              } else {
                handleNavigationClick(
                  `/3dmodels/${product?.category_data?.slug}/${productId}`
                );
                // navigate(`/3dmodels/${product?.category_data?.slug}/${productId}`)
              }
            }}
          >
            <MdClose color="#ccc" size="30" />
          </div>
          {/* <Card dark={true} style={{ borderRadius: 20, paddingBottom: 0 }}>
          <iframe
            style={{ borderRadius: 20 }}
            src={`${axios.defaults.baseURL}viewer/${productId}`}
            width="100%"
            height="650"
          />
        </Card> */}
        </Container>
        {/* <iframe
          className="d-flex flex-grow-1"
          // style={{ borderRadius: 20 }}
          src={`${axios.defaults.baseURL}viewer/${productId}`}
          width="100%"
          height="100vh"
        /> */}
        <div style={{ width: "100%", height: "100vh" }}>
          {product && <Try3D product={product} />}
        </div>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
};
export default Viewer;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const RefundAndCancellation = () => {
  const location = useLocation();
  return (
    <React.Fragment>
      {/* <Helmet>
        <title>{`Znanye - if you want your moneyback, you can have it.`}</title>
        <meta name="description" content="Znanye follows a no-question asked refund policy, in the event a request for return is made by you within 48 hours of the purchase of an asset from the Platform" />
        <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
      </Helmet> */}
      <Container
        className="d-flex flex-column h-100 text-left text-white px-3 px-sm-4 px-md-5 py-2 py-md-4"
        fluid
        style={styles.parentContainer}
      >
        <h4 className="mb-2">REFUND POLICY</h4>
        <hr className="line-below-heading" />
        <Row>
          <Col
            className="text-white my-2"
            style={styles.privacyPolicyDescription}
            xs={12}
          >
            <p className="text-justify">
              This Refund Policy (“<b>Policy</b>”) regulate the legal
              relationship between you (“<b>User</b>” or “<b>you</b>” or “
              <b>your</b>”) and Pastelcube Technologies Private Limited (“
              <b>Znanye</b>” or “<b>we</b>” or “<b>us</b>” or “<b>our</b>”) as a
              virtual reality asset marketplace (“<b>Platform</b>”). Please note
              that by continuing the use of our Platform, you signify your
              acceptance of this Policy.
            </p>
            <p className="text-justify">
              We endeavour to provide you with the product and experience as
              accurately as possible. Our goal is to help you find what you
              need. However, there may be instances when you may want to return
              the asset.
            </p>
            <p className="text-justify">
              Znanye follows a no-question asked refund policy, in the event a
              request for return is made by you within 48 (Forty-eight) hours of
              the purchase of an asset from the Platform. The refund shall be
              subject to deduction of applicable taxes, statutory levies and
              other charges.
            </p>
            <p className="text-justify">
              You may expect a refund within 14 (Fourteen) working days from the
              date we receive the refund request from you. Refund by Znanye will
              be subject to verification of the details provided by you.
            </p>
            <p className="text-justify">
              In the event you return any asset and/or receive a refund, all
              license rights granted herein shall automatically stand terminated
              and you will be instructed to immediately destroy any and all
              copies of the asset held in any formats under your control or
              possession.
            </p>
          </Col>
          <Col
            className="text-white text-justify"
            style={styles.privacyPolicyDescription}
            xs={12}
          >
            <h5 className="mb-2">Refund Procedure:</h5>
            <hr className="line-below-heading" />
            <p className="text-justify">
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>
            <ul>
              <li>
                <div>Go to "My Orders"</div>
              </li>
              <li>
                <div>Click on “Refund” option on the asset</div>
              </li>
              <li>
                <div>Enter details requested and create a refund request</div>
              </li>
              <li>
                <div>
                  Before proceeding with your purchase:
                  <ol>
                    <li>
                      Be sure that you are purchasing the correct model using a
                      3D viewer.
                    </li>
                    <li>
                      Be sure that the model is compatible with your software by
                      checking the included file formats.
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                If you have any concerns regarding the misrepresentation of a
                product or any questions about the technical aspects of a model,
                please contact us at{" "}
                <a href="mailto:talk2us@znanye.com">talk2us@znanye.com</a>
              </li>
            </ul>
          </Col>
          <Col
            className="text-white text-center text-lg-start my-4"
            style={styles.privacyPolicyDescription}
            xs={12}
          >
            Pastelcube &copy; 2022
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    marginTop: "80px",
  },
};

export default RefundAndCancellation;

import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Container,
  Breadcrumb,
  Row,
  Col,
  Image,
  Badge,
  Form,
  Spinner,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Button as RButton,
  Modal,
  Card,
  Overlay,
  Popover,
} from "react-bootstrap";
import {
  // Card,
  // CardContent,
  Button,
  IconButton,
} from "ui-neumorphism";

import { znanyeloader } from "assets";
import {
  biggreentick,
  biggreytick,
  checkedtrue,
  view3d,
  view3d_znanye,
} from "assets";
import { ZSlider, ZDropdown, ToggleButton } from "components";
import Switch from "react-switch";
import "core-ui/pages/Product.scss";
import { emptyimage, whitelink } from "assets";
import { Link, useNavigate } from "react-router-dom";
import { downarrows } from "assets";
import { MdDelete } from "react-icons/md";
import "core-ui/pages/admin/Product.scss";
import { BsCircleFill } from "react-icons/bs";
import { FiInfo } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlinePlayCircle, AiOutlineInfoCircle } from "react-icons/ai";
import Dropzone, { useDropzone } from "react-dropzone";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  upload,
  seehow,
  seehow_popup,
  freelicense,
  paidlicense,
  upload_icon,
} from "assets/admin";
import * as actions from "actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Outlet, useParams } from "react-router-dom";
import Editor from "./Editor";
import ProductFiles from "./ProductFiles";
import { TagForm } from "components";
import { CustomSlider } from "components";
import getSymbolFromCurrency from "currency-symbol-map";
import { toast } from "react-toastify";
import { FcCheckmark } from "react-icons/fc";
import moment from "moment";
import { CKEditor } from "ckeditor4-react";
import axios from "axios";
import { Breakpoint } from "react-socks";
import { Try3DFile, Try3DURLFiles } from "pages";
import ProductEditor from "pages/ProductEditor/ProductEditor";
import { FiUpload } from "react-icons/fi";
import ProgressBar from "react-bootstrap/ProgressBar";
import md5 from "md5";
import { AiOutlineCaretDown } from "react-icons/ai";

const customStyles = {
  menu: () => ({
    marginTop: 10,
    padding: 0,
    position: "absolute",
    width: "100%",
    zIndex: 999,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#141414",
    color: state.isSelected ? "#777" : "white",
    padding: 10,
    marginTop: 0,
    cursor: "pointer",
  }),
  control: () => ({
    color: "#fff",
    backgroundColor: "#141414",
    display: "flex",
    fontSize: 16,
    padding: 5,
    borderRadius: 5,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const color = "#fff";
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color };
  },
};

const ProductMain = ({ type, mode }) => {
  const today = new Date();
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param2 = params.length > 0 ? params[1] : "";
  const param3 = params.length > 1 ? params[2] : "";
  const [changingBorder, setChangingBroder] = useState(false);
  const is_admin = useSelector((state) => state.authReducer.user.is_admin);
  const [title, setTitle] = useState(null);
  const popRef = useRef();
  const [review, setReview] = useState(null);
  const [isSending, setIsproduing] = useState(false);
  const [productFiles, setProductFiles] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [seeInfoTooltip, setSeeInfoTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const [addCoupons, setAddCoupons] = useState([]);
  const [addTags, setAddTags] = useState([]);
  const [zsin, setZsin] = useState(null);
  const [progressBarFileName, setProgressBarFileName] = useState(null);
  const [progressBarFilesTotal, setProgressBarFilesTotal] = useState(0);
  const [thisProduct, setThisProduct] = useState(null);

  const [description, setDescription] = useState(null);
  const [price, setPrice] = useState(0);
  const [polygons, setPolygons] = useState(0);
  const [vertices, setVertices] = useState(0);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [tags, setTags] = useState([]);
  const [seeHowModal, setSeeHowModal] = useState(false);
  const [productLicenseDescription, setProductLicenseDescription] =
    useState(false);
  const [clickedFileIndex, setClickedFileIndex] = useState(-1);

  const [draft, setDraft] = useState(false);
  const [isDrafting, setIsDrafting] = useState(false);

  const [toVerify, setToVerify] = useState(false);
  const [sendForVerification, setSendForVerification] = useState(false);

  const [publish, setPublish] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const [target, setTarget] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [bestCoupon, setBestCoupon] = useState(null);
  const [loading, setLoading] = useState(true);
  const [freeProduct, setFreeProduct] = useState(true);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [uploadedImageIndex, setUploadedImageIndex] = useState([0]);
  const [editorExtensionsPresent, setEditorExtensionsPresent] = useState(false);
  const [editorEnabled, setEditorEnabled] = useState(false);
  const [viewerSwitch, setViewerSwitch] = useState(true);
  const [editorValues, setEditorValues] = useState({
    selectedOption: "veranda_1k.exr",
    enableEnvironment: false,
    blurEnvironment: false,
    enableRotation: false,
    intensityValue: 0.5,
    emissionIntensityValue: 0,
    backgroundColor: "#2E2E2E",
  });
  const [isDeletingFile, setIsDeletingFile] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [productNewFiles, setProductNewFiles] = useState([]);
  const [percentValue, setPercentValue] = useState(0);
  const form = useRef(null);
  const ref = useRef(null);
  const [filesId, setProductNewFilesId] = useState();
  const [filesIdPresent, setProductNewFilesIdPresent] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [productFilesInitial, setproductFilesInitial] = useState(0);
  const [isFileSending, setIsFileSending] = useState(false);
  const [filesIdSent, setFilesIdSent] = useState([]);
  const [progressBarName, setProgressBarName] = useState("");

  const unWrappedRef = useRef(null);
  const geometryRef = useRef(null);
  const softwareRef = useRef(null);
  const categoryRef = useRef(null);
  const extensionRef = useRef(null);
  const couponsRef = useRef(null);
  const tagRef = useRef(null);
  const licenseRef = useRef(null);
  const inputRef = useRef(null);
  const imageinputRef = useRef(null);
  const currency = useSelector((state) => state.authReducer.currency);

  const categoryScrollIntoRef = useRef(null);

  const [linkValues, setLinkValues] = useState([{ link: "" }]);

  const { productId } = useParams();
  //consolelog(productId);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  // const SWTCHSwitch = styled((props: SwitchProps) => (
  //   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  // ))(({ theme }) => ({
  //   width: 42,
  //   height: 26,
  //   padding: 0,
  //   '& .MuiSwitch-switchBase': {
  //     padding: 0,
  //     margin: 2,
  //     transitionDuration: '300ms',
  //     '&.Mui-checked': {
  //       transform: 'translateX(16px)',
  //       color: '#fff',
  //       '& + .MuiSwitch-track': {
  //         backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
  //         opacity: 1,
  //         border: 0,
  //       },
  //       '&.Mui-disabled + .MuiSwitch-track': {
  //         opacity: 0.5,
  //       },
  //     },
  //     '&.Mui-focusVisible .MuiSwitch-thumb': {
  //       color: '#33cf4d',
  //       border: '6px solid #fff',
  //     },
  //     '&.Mui-disabled .MuiSwitch-thumb': {
  //       color:inputRefget
  //         theme.palette.mode === 'light'
  //           ? theme.palette.grey[100]
  //           : theme.palette.grey[600],
  //     },
  //     '&.Mui-disabled + .MuiSwitch-track': {
  //       opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
  //     },
  //   },
  //   '& .MuiSwitch-thumb': {
  //     boxSizing: 'border-box',
  //     width: 22,
  //     height: 22,
  //   },
  //   '& .MuiSwitch-track': {
  //     borderRadius: 26 / 2,
  //     backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
  //     opacity: 1,
  //     transition: theme.transitions.create(['background-color'], {
  //       duration: 500,
  //     }),
  //   },
  // }));

  const getTags = async () => {
    const response = await actions.getTags();
    //consolelog(response);
    if (response) {
      setTags(
        response.map((item, index) => {
          return { value: item.id, label: item.name };
        })
      );
    }
  };

  // useEffect(() => {
  //   //consolelog(extensionRef.current);
  // }, [extensionRef.current]);

  useEffect(async () => {
    dispatch(actions.loadCategories());
    dispatch(actions.loadCoupons());
    getTags();

    // geometryRef?.current?.select?.inputRef?.required = true;
  }, []);

  const onDrop = (acceptedFiles) => {
    setProductFiles(productFiles.concat(acceptedFiles));
  };

  const onDropImages = (acceptedImages) => {
    setProductImages(productImages.concat(acceptedImages));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const [productDetails, setProductDetails] = useState([
    { id: 1, name: "Animated", set: "is_animated", value: false },
    { id: 2, name: "Rigged", set: "is_rigged", value: false },
    { id: 3, name: "Low Poly", set: "is_lowPoly", value: false },
    { id: 4, name: "PBR", set: "is_pbr", value: false },
    { id: 5, name: "Texture", set: "is_texture", value: false },
    { id: 6, name: "Material", set: "is_material", value: false },
    { id: 7, name: "Plugins", set: "is_plugin", value: false },
    { id: 8, name: "UV Mapping", set: "is_uvMapping", value: false },
  ]);

  const [productUsage, setProductUsage] = useState([
    { id: 1, name: "Trending", set: "is_trending", value: false },
    { id: 2, name: "Up For Grabs", set: "is_upforgrabs", value: false },
  ]);

  const fetchProductDetails = async () => {
    const response = await actions.getProduct(productId);
    const product = response;

    setCurrentProduct(product);
    setZsin(product.zsin);
    setTitle(product.title);
    setDescription(product.description);
    setFreeProduct(product.is_free);
    setViewerSwitch(product.is_threed_enabled);
    if (product.is_free) {
      licenseRef.current?.setValue(
        free_license_options.find((item) => item.label === product.license)
      );
    } else {
      licenseRef.current?.setValue(
        paid_license_options.find((item) => item.label === product.license)
      );
    }
    // setPrice(product.price * currency.rate);
    setPrice(product.price);
    setPolygons(product.polygons === 0 ? "" : product.polygons);
    setVertices(product.vertices === 0 ? "" : product.vertices);
    setProductDetails(
      productDetails.map((item, index) => {
        if (item.set in product) {
          item.value = product[item.set];
        }
        return item;
      })
    );
    setProductUsage(
      productUsage.map((item, index) => {
        if (item.set in product) {
          item.value = product[item.set];
        }
        return item;
      })
    );
    setEditorValues({
      selectedOption: product?.environment,
      enableEnvironment: product?.enable_environment_map,
      blurEnvironment: product?.blur_environment_map,
      enableRotation: product?.enable_rotation,
      intensityValue: product?.intensity_value,
      emissionIntensityValue: product?.emission_intensity_value,
      backgroundColor: product?.background_color,
    });

    const updatedLinkValues = product.links.map((item) => ({
      link: item.link,
    }));
    product.links?.length > 0 && setLinkValues(updatedLinkValues);
    // setLinkValues({ ...linkValues, link });

    unWrappedRef.current?.setValue(
      unwrapped_uvs_options.find((item) => item.value == product.unwrapped_uvs)
    );
    geometryRef.current?.setValue(
      geometry_options.find((item) => item.value == product.geometry)
    );
    softwareRef.current?.setValue(
      software_options.find((item) => item.value == product.software_used)
    );
    categoryRef.current?.setValue({
      value: product?.category_data?.id,
      label: product?.category_data?.title,
    });
    extensionRef.current?.setValue(
      extension_options.find((item) => item.value == product.viewer_extension)
    );
    const selectedCoupons = product?.coupons_data?.map((item) => {
      // return { value: item.id, label: item.code };
      return item;
    });
    const selectedTags = product.tags_data.map((item) => {
      return { value: item.id, label: item.name };
    });
    couponsRef.current?.setValue(selectedCoupons);
    setApplyDiscount(selectedCoupons?.length > 0 ? true : false);
    //consolelog(selectedTags);
    tagRef.current?.setValue(selectedTags);
    setProductFiles(product?.productfile_set ? product.productfile_set : null);

    setProductImages(
      product.productimage_set ? product.productimage_set : null
    );
    setAddCoupons(selectedCoupons);
    // unWrappedRef?.current?.props?.value?.value = product.unwrapped_uvs;
    setThisProduct(product);
  };

  useEffect(() => {
    console.log("productFiles", productFiles);
    // if (productFiles.length > 0) {
    //   const updatedFiles = productFiles
    //     .filter((item) => !item.hasOwnProperty("id"))
    //     .map((item) => ({
    //       file: item,
    //       progress: 0,
    //       md5: "secretKey",
    //       status: 0,
    //     }));
    //   console.log("updatedFiles", updatedFiles);
    //   setProductNewFiles(updatedFiles);
    // }
  }, [productFiles]);

  // useEffect(() => {
  //   if (productNewFiles.length > 0) {
  //     console.log(productNewFiles);
  //     const filteredFiles = productNewFiles.filter(
  //       (file) => file.progress != 0
  //     );
  //     // console.log("##", filteredFiles);

  //     if (filteredFiles.length > 0) {
  //       const largestFile = filteredFiles.reduce((prevFile, currFile) => {
  //         return currFile.file.size > prevFile.file.size ? currFile : prevFile;
  //       });prou
  //       // console.log('##',largestFile)
  //       // console.log(largestFile);
  //       setProgressBarName(largestFile.file.name);
  //       setProgressBarValue(largestFile.progress);
  //     }
  //     // let totalProgress = 0;upload_docume
  //     // for (let i = 0; i < filteredFiles.length; i++) {
  //     //   if (filteredFiles[i].status == 0) {
  //     //     totalProgress += filteredFiles[i].progress;
  //     //   }
  //     // }
  //     // const average =
  //     //   filteredFiles.length > 0 ? totalProgress / filteredFiles.length : 0;

  //     // console.log(average);
  //     // if (isNaN(average)) {
  //     //   setProgressBarValue(0);
  //     // } else if (average !== 0) {
  //     //   setProgressBarValue(average);
  //     // }
  //     // }
  //   }
  // }, [productNewFiles]);

  useEffect(() => {
    if (progressBarValue > 100 || progressBarValue === 100) {
      const timer = setTimeout(() => {
        setProgressBarValue(0);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [progressBarValue]);

  useEffect(() => {
    if (productId) {
      fetchProductDetails();
    }
    setLoading(false);
  }, [productId]);

  const categories = useSelector((state) => state.adminReducer.categories);
  const visible_categories = categories.filter(
    (category) => category.is_visible === true
  );
  const coupons = useSelector((state) => state.adminReducer.coupons);

  let category_options = [];
  visible_categories.map((category) => {
    return category_options.push({ value: category.id, label: category.title });
  });

  const validateCoupon = (coupon, price) => { };

  let availableCoupons = [];

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  coupons.map((coupon) => {
    if (moment(coupon?.valid_till).diff(moment(today), "seconds") * 1000 > 0) {
      return availableCoupons.push({
        id: coupon.id,
        label: coupon.code,
        coupon_category: coupon.coupon_category,
        flat_off: coupon.flat_off,
        valid_till: coupon,
      });
    }
  });

  useEffect(() => {
    //consolelog(addCoupons);
    let x = 0,
      tempAmount = 0;
    for (let i = 0; i < addCoupons?.length; i++) {
      if (addCoupons[i].coupon_category == "RELATIVE") {
        tempAmount = (price * addCoupons[i].flat_off) / 100;
      } else {
        tempAmount = addCoupons[i].flat_off;
      }
      if (x < tempAmount) {
        x = tempAmount;
      }
    }
    setDiscountedPrice(price - x);
  }, [addCoupons, price]);

  // useEffect(() => {
  //   console.log("links", linkValues);
  // }, [linkValues]);

  const handleLinkChange = (i, e) => {
    let newLinkValues = [...linkValues];
    newLinkValues[i][e.target.name] = e.target.value;
    // //consolelog("&&&&&&&&&&&&&&&&&&&&&&",newLinkValues);
    setLinkValues(newLinkValues);
  };
  // && linkValues["link"].value === '')

  const addLinkFields = () => {
    if (linkValues[linkValues.length - 1].link !== "") {
      setLinkValues([...linkValues, { link: "" }]);
    }
    // let toAdd = true;
    // //consolelog(toAdd, linkValues)
    // linkValues.forEach((lv, index)=>{
    //   if (lv.link === "" && index === linkValues.length-1){
    //     toAdd = false;
    //   }
    // })
    // if (toAdd){
    //   setLinkValues([...linkValues, { link: "" }]);
    // }
    // for (let i = 0; i < linkValues.length; i++) {
    //   //consolelog()
    //   if (i === linkValues.length - 1  && linkValues[i].link === ''){
    //     //consolelog("7777777",linkValues.length, linkValues[i].link)
    //     //consolelog("@@8888888@@@@@",linkValues)
    //     //consolelog("@@@@@@@@@@@",linkValues[i]);
    //   }
    //   else{
    //     //consolelog("766666677",linkValues.length, linkValues[i].link)

    //     setLinkValues([...linkValues, { link: "" }]);

    //   }
    // }
  };

  const removeLinkFields = (i) => {
    let newLinkValues = [...linkValues];
    newLinkValues.splice(i, 1);
    setLinkValues(newLinkValues);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    //consolelog("formdata", formData);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("is_free", freeProduct);
    formData.append("is_threed_enabled", viewerSwitch);

    // //consolelog(geometryRef.current.focus());
    let productUploadType = "";
    if (draft) {
      setIsDrafting(true);
      productUploadType = "draft";
      formData.append("is_in_draft", true);
      formData.append("is_verified", false);
    } else if (toVerify) {
      productUploadType = "toVerify";
      formData.append("is_in_draft", false);
      formData.append("is_verified", false);
      setSendForVerification(true);
    } else if (publish) {
      setIsPublishing(true);
      productUploadType = "publish";
      formData.append("is_in_draft", false);
      formData.append("is_verified", true);
    }
    formData.append(
      "unwrapped_uvs",
      unWrappedRef?.current?.props?.value?.value
    );
    //consolelog(geometryRef.current?.props?.value?.value);
    // formData.append("environment",editorValues? editorValues.select.values : "")
    // formData.append("enable_environment_map",editorValues? editorValues.enableEnvironment : "")
    // formData.append("blur_environment_map",editorValues? editorValues.blurenvironment : "")
    // formData.append("enable_rotation",editorValues? editorValues.rotation : "")
    // formData.append("intensity_value",editorValues? editorValues.inetnsityvalue : "")
    // formData.append("emission_intensity_value",editorValues? editorValues.emissionvalue : "")

    // formData.append("environment", "blue_grotto_1k.exr");
    // formData.append("enable_environment_map", "true");
    // formData.append("blur_environment_map", "true");
    // formData.append("enable_rotation", "true");
    // formData.append("intensity_value", 5);
    // formData.append("emission_intensity_value", 5);
    formData.append("links", JSON.stringify(linkValues));
    // formData.append("ediorValues",editorValues? editorValues.intensityvalue : "")
    // formData.append("ediorValues",editorValues? editorValues.emissionvalue : "")

    formData.append("geometry", geometryRef?.current?.props?.value?.value);
    formData.append("software_used", softwareRef?.current?.props?.value?.value);
    formData.append(
      "viewer_extension",
      extensionRef?.current?.props?.value?.value
    );
    formData.append("license", licenseRef?.current?.props?.value?.value);
    // formData.append("currency", currencyRef?.current?.props?.value?.value);
    productDetails.map((item) => {
      return formData.append(item.set, item.value);
    });
    productUsage.map((item) => {
      return formData.append(item.set, item.value);
    });

    console.log("@@@@@@", productFiles);
    productFiles.map((item) => {
      if (item.id) {
        return formData.append(
          "productfile_set",
          JSON.stringify({ id: item.id })
        );
      }
      return formData.append("productfile_set", item);
    });

    productImages.map((item) => {
      if (item.id) {
        return formData.append(
          "productimage_set",
          JSON.stringify({ id: item.id })
        );
      }
      return formData.append("productimage_set", item);
    });
    formData.append("category", categoryRef?.current?.props?.value?.value);
    addTags.map((item, index) => {
      return formData.append("tags", item.name);
    });
    addCoupons?.map((item) => {
      return formData.append("coupons", item.id);
    });

    if (currentProduct) {
      if (!categoryRef?.current?.props?.value?.value) {
        categoryScrollIntoRef.current.scrollIntoView();
        toast.warning("Please select the Product Category");
        setIsDrafting(false);
        setSendForVerification(false);
        setIsPublishing(false);
      } else if (!licenseRef?.current?.props?.value?.value) {
        toast.warning("Please select a License type");
        setIsDrafting(false);
        setSendForVerification(false);
        setIsPublishing(false);
      } else if (!freeProduct && price < 3.99) {
        toast.warning("The price should be more than or equal to 3.99$");
        setIsDrafting(false);
        setSendForVerification(false);
        setIsPublishing(false);
      } else {
        const response = await actions.updateProduct(productId, formData);
        if (response) {
          dispatch(actions.productUpdate(response));
          if (draft) {
            toast.success(
              "Your product is saved in draft. You can see your product under My Products' IN DRAFT."
            );
          } else if (toVerify) {
            toast.success(
              "We are delighted that you decided to partner with us in selling your VR Asset. Your asset is under review. If we need any clarification, we will come back; else, your asset will automatically Go Live on our store."
            );
          } else if (publish) {
            toast.success("Product updated successfully!");
          }
          switch (productUploadType) {
            case "draft":
              if (is_admin) {
                navigate("/admin/products");
              } else {
                handleNavigationClick("/sell-3d/drafts");

                // navigate("/sell-3d/drafts");
              }
              break;
            case "toVerify":
              handleNavigationClick("/sell-3d/pendingapprovals");

              // navigate("/sell-3d/pendingapprovals");
              break;
            case "publish":
              navigate("/admin/products");
              break;
            default:
            //consolelog(productUploadType);
          }
        }
      }
    } else {
      const response = await actions.createProduct(formData);

      if (response) {
        dispatch(actions.productAdd(response));
        if (draft) {
          toast.success(
            "Your product is saved in draft. You can see your product under My Products' IN DRAFT."
          );
        } else if (toVerify) {
          toast.success(
            "We are delighted that you decided to partner with us in selling your VR Asset. Your asset is under review. If we need any clarification, we will come back; else, your asset will automatically Go Live on our store."
          );
        } else if (publish) {
          toast.success("Product created successfully!");
        }
        switch (productUploadType) {
          case "draft":
            handleNavigationClick("/sell-3d/drafts");
            // navigate("/sell-3d/drafts");
            break;
          case "toVerify":
            handleNavigationClick("/sell-3d/pendingapprovals");
            // navigate("/sell-3d/pendingapprovals");
            break;
          case "publish":
            navigate("/admin/products");
            break;
          default:
          //consolelog(productUploadType);
        }
      }
    }
    setIsDrafting(false);
    setSendForVerification(false);
    setIsPublishing(false);
  };

  const customStyles = {
    menu: () => ({
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#141414",
      color: state.isSelected ? "#fff" : "white",
      padding: 10,
      marginTop: 0,
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      // width: 200,
      color: "#fff",
      backgroundColor: "#0d0d0d",
      display: "flex",
      fontSize: 16,
      padding: 5,
      border: "1px solid #3D3D3D",
      borderRadius: 10,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "#fff";
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color };
    },
  };

  // All options here
  const unwrapped_uvs_options = [
    {
      value: "NON_OVERLAPPING",
      label: "NON_OVERLAPPING",
    },
    { value: "OVERLAPPING", label: "OVERLAPPING" },
    { value: "MIXED", label: "MIXED" },
    { value: "NO", label: "NO" },
  ];

  const geometry_options = [
    { value: "POLYGON_MESH", label: "POLYGON_MESH" },
    {
      value: "SUBDIVISION_READY",
      label: "SUBDIVISION_READY",
    },
    { value: "NURBS", label: "NURBS" },
    { value: "OTHER", label: "OTHER" },
  ];

  const software_options = [
    { value: "BLENDER", label: "Blender" },
    { value: "AUTODESK_MAYA", label: "Autodesk Maya" },
    { value: "AUTODESK_3DS_MAX", label: "Autodesk 3ds Max" },
    { value: "SKETCHUP", label: "Sketchup" },
    { value: "CINEMA_4D", label: "Cinema 4D" },
    { value: "ZBRUSH", label: "ZBrush" },
    { value: "OTHERS", label: "Others" },
    // { value: "FREE_CAD", label: "FREE_CAD" },
    // { value: "FUSION_360", label: "FUSION_360" },
    // { value: "RHINOCEROS_3D", label: "RHINOCEROS_3D" },
  ];

  const free_license_options = [
    { value: "CC0 - Public Domain", label: "CC0 - Public Domain" },
    { value: "CC BY - Attribution", label: "CC BY - Attribution" },
    { value: "CC BY-SA - ShareAlike", label: "CC BY-SA - ShareAlike" },
    { value: "CC BY-NC - NonCommercial", label: "CC BY-NC - NonCommercial" },
    { value: "CC BY-ND - NoDerivatives", label: "CC BY-ND - NoDerivatives" },
    { value: "CC BY-NC-SA", label: "CC BY-NC-SA" },
    { value: "CC BY-NC-ND", label: "CC BY-NC-ND" },
  ];
  const paid_license_options = [
    { value: "Royalty Free", label: "Royalty Free" },
    { value: "Editorial", label: "Editorial" },
  ];

  const free_licenses = [
    {
      id: 1,
      title: "CC0 - Public Domain",
      description:
        "The creator has relinquished all rights to the work, allowing others to use, modify, and share it freely without any restrictions.",
      link: null,
    },
    {
      id: 2,
      title: "CC BY - Attribution",
      description:
        "Others can share, modify, and use the model, but they must give credit to the original creator.",
      link: "https://creativecommons.org/licenses/by/4.0/",
    },
    {
      id: 3,
      title: "CC BY-SA - ShareAlike",
      description:
        "Others can share, modify, and use the model, but derivative work must be shared under the same license.",
      link: "https://creativecommons.org/licenses/by-sa/4.0/",
    },
    {
      id: 4,
      title: "CC BY-NC - NonCommercial",
      description: "Others cannot use the model for commercial purposes.",
      link: "https://creativecommons.org/licenses/by-nc/4.0/",
    },
    {
      id: 5,
      title: "CC BY-ND - NoDerivatives",
      description:
        "Others can use and share the model, but it cannot be altered.",
      link: "https://creativecommons.org/licenses/by-nd/4.0",
    },
    {
      id: 6,
      title: "CC BY-NC-SA",
      description:
        "Others can remix, adapt, and build upon your work non-commercially, as long as they credit you and license their new creations under the identical terms.",
      link: "https://creativecommons.org/licenses/by-nc-sa/4.0/",
    },
    {
      id: 7,
      title: "CC BY-NC-ND",
      description:
        "Only allowing others to download your works and share them with others as long as they credit you, but they can’t change them in any way or use them commercially.",
      link: "https://creativecommons.org/licenses/by-nc-nd/4.0/",
    },
  ];

  const extension_options = [
    { value: "GLTF", label: "GLTF" },
    { value: "FBX", label: "FBX" },
    { value: "OBJ", label: "OBJ" },
  ];

  const currency_options = [
    { value: "USD", label: "USD" },
    { value: "INR", label: "INR" },
    { value: "GBP", label: "GBP" },
    { value: "EUR", label: "EUR" },
    { value: "YEN", label: "YEN" },
  ];

  // if(param2=="")
  const handleChange = (newValue, actionMeta) => {
    // //consolegroup('Value Changed');
    // //consolelog(newValue);
    // //consolelog(`action: ${actionMeta.action}`);
    // //consolegroupEnd();
    //consolelog(addTags);
    setAddTags(
      newValue.map((element) => {
        const x = { name: element.label };
        return x;
      })
    );
  };

  const editorConfig = {
    extraPlugins: ["tableresize", "uploadimage", "createpdf", "toc"],
    skin: "moono-dark",
    removePlugins: [
      "exportpdf",
      "forms",
      "smiley",
      "language",
      "iframe",
      "about",
    ],
    // filebrowserBrowseUrl: 'http://localhost:4000/media/24b358cf-8e56-4b03-9843-29fabf589b8d/template/home-advt_jzx7KBU.jpeg',
    filebrowserImageUploadUrl:
      process.env.REACT_APP_BASE_URL + `blog/upload_blog_image/`,
    fileTools_requestHeaders: axios.defaults.headers.common,
    uploadUrl: process.env.REACT_APP_BASE_URL + `blog/upload_blog_image/`,
    contentsCss: [
      // "https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css",
      process.env.REACT_APP_CLOUDFRONT_URL +
      "/static/ckeditor/4.19.0/full-all/document-style.css",
    ],
    bodyClass: "document-editor",
    // height: 900,
    // width: 1000,
    height: "15.8cm",
    // width: "18.6cm",
    width: "100%",
    allowedContent: true,
    // extraPlugins: [
    //     'timestamp'
    // ]
    // exportPdf_options: {
    //     header_html: '<div class="styled">This is the Header</div>',
    //     footer_html: '<div class="styled-counter"><span class="date></span></div>',
    //     header_and_footer_css: '.styled { font-weight: bold; padding: 10px; display: flex; color: red; } .styled-counter {font-size: 10px; color: red; }',
    //     margin_top: '2cm',
    //     margin_bottom: '10cm',
    // }
    // exportPdf_options: {
    //     format: "A3"
    // }
    exportPdf_options: {
      header_html: '<div class="styled">Header content</div>',
      footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
      header_and_footer_css:
        ".styled { font-weight: bold; padding: 10px; text-align: center; color: red; }",
      margin_left: "1cm",
      margin_right: "2cm",
      format: "A5",
      page_orientation: "landscape",
    },
    protectedSource: [/{%[\s\S]*?%}+/g, /{{[\s\S]*?}}+/g],
    // formatOutput: false,
    // formatSource: false
  };

  // if(loading){
  //   return <div>loading</div>
  // }

  const handleFileDelete = async (item, clickedindex) => {
    const updatedProductFiles = productFiles.filter((item, index) => {
      return index !== clickedindex;
    });
    setProductFiles(updatedProductFiles);
    setEditorExtensionsPresent(checkEditorExtensions(updatedProductFiles));
  };

  const checkEditorExtensions = (submittedFiles) => {
    let flag = false;
    for (let i = 0; i < submittedFiles.length; i++) {
      if (submittedFiles[i].id) {
        const fileUrl = submittedFiles[i]?.file;
        const filename = fileUrl?.split("/").pop();
        let extension = filename?.split(".").pop().toLowerCase();
        if (["gltf", "glb", "fbx", "obj"].includes(extension)) {
          flag = true;
          break;
        }
      } else {
        const fileUrl = submittedFiles[i]?.path;
        const filename = fileUrl?.split("/").pop();
        let extension = filename?.split(".").pop().toLowerCase();
        if (["gltf", "glb", "fbx", "obj"].includes(extension)) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  };

  const checkImageUploaded = (imageFiles) => {
    let imagePresent = false;
    if (imageFiles.length > 0) {
      imagePresent = true;
    }
    return imagePresent;
  };

  useEffect(() => {
    console.log("inside extension", productFiles);
    // setproductFilesInitial(
    //   currentProduct?.productfile_set?.length
    //   );
    // if (currentProduct) {
    //   if (currentProduct.productfile_set) {
    //     //consolelog(currentProduct.productfile_set);
    //     // setproductFilesInitial(currentProduct.productfile_set?.length);
    //   }
    // }

    // setproductFilesInitial(currentProduct?.productfile_set?.length)
    // if (productFiles?.length > 0) {
    //consolelog(checkEditorExtensions(productFiles));
    setEditorExtensionsPresent(checkEditorExtensions(productFiles));
    // console.log(editorExtensionsPresent);
    //consolelog("*****", productFiles, productFilesInitial, currentProduct);
    // }
  }, [productFiles]);

  useEffect(() => {
    setImageUploaded(checkImageUploaded(productImages));

    //consolelog(productImages);
  }, [productImages]);

  // useEffect(() => {
  //   if (progressBarFileName){
  //   const currFile = productNewFiles.find(item => item?.file?.name === progressBarFileName);
  //   //consolelog("bhbhbh",currFile)
  //   setProgressBarValue(currFile.progress)
  //   //consolelog("bhbhbh",progressBarValue)
  //   }

  //  }, [progressBarFileName]);

  const handleAddMoreFileUpload = () => {
    inputRef.current?.click();
  };

  if (editorEnabled) {
    return (
      <ProductEditor
        fetchProductDetails={fetchProductDetails}
        productId={productId}
        data={editorValues}
        files={productFiles}
        setEditorEnabled={setEditorEnabled}
        setEditorValues={setEditorValues}
      />
    );
  }

  const handleSwitchChange = () => {
    setViewerSwitch(!viewerSwitch);
  };

  const handleProductPage = (id) => {
    setProductNewFilesId(id);
    setProductNewFilesIdPresent(true);
  };

  const sendHash = async (id, hash, i) => {
    try {
      const response = await axios.post(`product/${id}/verify_hash/`, {
        client_md5_hash: hash,
      });
      if (response.status == 200) {
        console.log("prodfiles".productFiles);
        // setProductFiles([...productFiles, response.data]);
        setProductNewFiles([
          ...productNewFiles.map((item, index) => {
            if (index == i) {
              const temp = item;
              temp.status = 1;
              handleProductPage(id);

              return temp;
            }
            return item;
          }),
        ]);
      } else {
        setProductNewFiles([
          ...productNewFiles.map((item, index) => {
            if (index == i) {
              const temp = item;
              temp.status = 2;
              return temp;
            }
            return item;
          }),
        ]);
      }
    } catch (e) {
      //consolelog(e);sendHash
      setProductNewFiles([
        ...productNewFiles.map((item, index) => {
          if (index == i) {
            const temp = item;
            temp.status = 2;
            return temp;
          }
          return item;
        }),
      ]);
    }
  };

  const uploadFile = (x, id, i, largestFile, acceptedFiles) => {
    const y = x.file;
    const total = y.size;
    let loaded = 0;
    let step = 1024 * 1024;
    let start = 0;

    let reader = new FileReader();
    let pValue = 0;

    //consolelog("PPPPPP", loaded, total);
    reader.onprogress = function (e) {
      pValue = (loaded / total) * 100;
    };

    reader.onload = function (e) {
      let xhr = new XMLHttpRequest();
      let upload = xhr.upload;
      let result = e.target.result;

      upload.addEventListener(
        "load",
        function (e) {
          loaded += step;
          pValue = (loaded / total) * 100;
          // console.log(loaded, total, pValue);
          if (largestFile.file.size == total) {
            setProgressBarName(largestFile.file.name);
            if (pValue > 100) {
              setProgressBarValue(100);
            } else {
              setProgressBarValue(pValue);
            }
          }
          console.log(productFiles);
          if (loaded < total) {
            blob = y.slice(loaded, loaded + step);
            reader.readAsBinaryString(blob);
            setProductNewFiles([
              ...productFiles.map((item, index) => {
                if (index == i) {
                  const temp = item;
                  temp.progress = pValue;
                  temp.md5 = md5(result);
                  return temp;
                }
                return item;
              }),
            ]);
          } else {
            loaded = total;
            setIsFileSending(false);
            const finalFiles = Array.from(acceptedFiles).map((item, index) => {
              if (index === i) {
                const temp = item;
                temp.progress = pValue;
                temp.md5 = md5(result);
                sendHash(id, temp.md5, i);
                temp.status = 1;
                return temp;
              }
              return item;
            });
            setProductNewFiles([...productNewFiles, finalFiles]);
          }
        },
        false
      );

      xhr.open(
        "PUT",
        `${process.env.REACT_APP_BASE_URL}product/sendchunks/${id}/`
      );

      xhr.overrideMimeType("application/octet-stream");
      if (!xhr.sendAsBinary) {
        xhr.sendAsBinary = function (datastr) {
          function byteValue(x) {
            return x.charCodeAt(0) & 0xff;
          }
          var ords = Array.prototype.map.call(datastr, byteValue);
          var ui8a = new Uint8Array(ords);
          this.send(ui8a.buffer);
        };
      }
      xhr.sendAsBinary(e.target.result);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          // callback(xhr.response);
          //consolelog("xhr", xhr.response);
        }
      };
    };
    let blob = y.slice(start, step);
    reader.readAsBinaryString(blob);
  };

  const startUpload = async (productId, updatedFiles, acceptedFiles) => {
    if (updatedFiles.length > 0) {
      const largestFile = updatedFiles.reduce((prevFile, currFile) => {
        return currFile.file.size > prevFile.file.size ? currFile : prevFile;
      });
      for (let i = 0; i < updatedFiles.length; i++) {
        if (updatedFiles[i].status == 0 && !updatedFiles[i].file.id) {
          const formData = new FormData();
          formData.append("file", new Blob([]), updatedFiles[i].file.name);
          formData.append("product", productId);
          const response = await axios.post(
            `/product/${productId}/upload_document_create/`,
            formData
          );
          if (response.status == 200) {
            const data = response.data["product_files"];
            console.log("prodFile", productFiles);
            uploadFile(updatedFiles[i], data.id, i, largestFile, acceptedFiles);
          }
        }
      }
    }
  };

  const handleFileChange = async (acceptedFiles) => {
    console.log(productFiles);
    if (acceptedFiles.length > 0) {
      setIsFileSending(true);
      const updatedFiles = Array.from(acceptedFiles).map((item) => ({
        file: item,
        progress: 0,
        md5: "secretKey",
        status: 0,
      }));
      startUpload(productId, updatedFiles, acceptedFiles);
    }

    // setProductNewFiles(updatedFiles);
  };
  // //consolelog("^^^^", updatedFiles);

  // const handleFileUpload = async (productFiles) => {
  //   if (productFiles?.length > 0) {
  //     for (let i = 0; i < productFiles.length; i++) {
  //       const formData = new FormData(form.current);
  //       formData.append("productFile", productFiles[i]);
  //       formData.append("title", currentProduct);
  //       // formData.append("product", product);

  //       const response = await axios.post(
  //         `/product/upload_document_files/`,
  //         formData
  //       );
  //       if (response.status == 200) {
  //         //consolelog("fileuploaded", response.data);
  //         const id = response.data.slug;
  //         // setProductSlug(response.data.slug)
  //         // handleProductPage(id);
  //         // setIsProceeding(false);
  //       } else {
  //         //consolelog("erroorsss");
  //       }
  //     }
  //   }
  // };

  return (
    <React.Fragment>
      <Breakpoint large up>
        <Container
          className="d-flex flex-column align-items-center h-100 mt-5 pt-5 home-background-categories"
          // fluid
          style={styles.parentContainer}
        >
          {/* {type === "update" ? <Editor /> : ""} */}
          {/* {type === "upload" ? <ProductFiles/> :""} */}
          <Form ref={form} onSubmit={(e) => handleCreate(e)}>
            <Container className="p-5 text-white" fluid>
              <div className="poppins-500 subtitle px-0">Upload Files</div>
              <p className="body-text3 pb-0 mb-0">
                These are the files user can download when he/she buys your
                product.{" "}
                <b
                  className="cursor-pointer text-primary"
                  onClick={() => setSeeHowModal(true)}
                >
                  {" "}
                  See How?
                </b>
              </p>
              <p className="body-text3 py-0 pb-0 my-0">
                NOTE : Avoid Refreshing (Refreshing might lead to loss of the
                current product progress)
              </p>
              <Row>
                <Col sm={8} className="position-relative">
                  {editorExtensionsPresent && viewerSwitch && (
                    <RButton
                      variant="dark"
                      className="explore-border-button-round explore-button-large-no-neo-round position-absolute"
                      style={{
                        backgroundColor: "#27292A",
                        right: "40px",
                        top: "30px",
                        zIndex: 20,
                      }}
                      onClick={() => {
                        setEditorEnabled(true);
                      }}
                    >
                      <span className="poppins">EDITOR</span>
                    </RButton>
                  )}

                  {isFileSending && (
                    <Card
                      className="m-2 border-R20 d-flex justify-content-center align-items-center overflow-hidden h-100 admin-filedrop-card"
                      dark={true}
                      style={{
                        backgroundColor: "#141415",
                        border: "1px solid #3D3D3D",
                        // width: "100%",
                        height: "60vh",
                        position: "relative",
                      }}
                    >
                      <>
                        <ProgressBar
                          now={progressBarValue}
                          style={{ position: "absolute", width: "400px" }}
                        />
                        <p
                          style={{
                            marginTop: "66px",
                            color: "#FFFFFF",
                            left: "162px",
                            position: "absolute",
                          }}
                        >
                          Thank you for your patience.
                        </p>
                        <div
                          style={{
                            position: "absolute",
                            top: "200px",
                            left: "167px",
                            color: "#FFFFFF",
                          }}
                        >
                          {progressBarName.slice(0, 15)}...
                        </div>

                        <div
                          style={{
                            position: "absolute",
                            top: "185px",
                            right: "168px",
                            color: "#FFFFFF",
                          }}
                        >
                          {Math.floor(progressBarValue)}/100
                        </div>
                      </>
                    </Card>
                  )}
                  {editorExtensionsPresent &&
                    viewerSwitch &&
                    !isFileSending && (
                      <Card
                        className="m-2 border-R20 d-flex justify-content-center align-items-center overflow-hidden h-100 admin-filedrop-card overflow-none"
                        dark={true}
                        style={{
                          backgroundColor: "#141415",
                          border: "1px solid #3D3D3D",
                          // width: "100%",
                          height: "60vh",
                          position: "relative",
                        }}
                      >
                        <>
                          <Try3DURLFiles
                            files={productFiles}
                            setIsFileSending={setIsFileSending}
                            enableRotation={editorValues?.enableRotation}
                            enableEnvironment={editorValues?.enableEnvironment}
                            blurEnvironment={editorValues?.blurEnvironment}
                            environment={editorValues?.selectedOption}
                            intensityValue={editorValues?.intensityValue}
                            backgroundColor={editorValues?.backgroundColor}
                            emissionIntensityValue={
                              editorValues?.emissionIntensityValue
                            }
                          />
                        </>
                      </Card>
                    )}

                  {!editorExtensionsPresent &&
                    viewerSwitch &&
                    !isFileSending && (
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          setProductFiles((productFiles) => [
                            ...productFiles,
                            ...acceptedFiles,
                          ]);
                          handleFileChange(acceptedFiles);
                          // handleFileUpload(acceptedFiles);
                          toast.success(
                            "Your file is added to product bucket."
                          );
                          // //consolelog("Accepted Files", acceptedFiles);
                          // //consolelog(productFiles.concat(acceptedFiles));

                          // setProductFiles(productFiles.concat(acceptedFiles));
                          // Do something with the files
                        }}
                        noKeyboard={true}
                        noDragEventsBubbling={true}
                        noClick={true}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          open,
                        }) => (
                          <Card
                            className="m-2 d-flex flex-column align-items-center h-100 border-R20 admin-filedrop-card"
                            dark={true}
                            style={{
                              backgroundColor: "#141415",
                              border: "1px solid #3D3D3D",
                            }}
                          >
                            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                              <div
                                // {...getRootProps({ onClick: (e) => e.stopPropagation() })}
                                {...getRootProps()}
                                className="text-center d-flex justify-content-center align-items-center h-100 w-100"
                                onClick={open}
                              >
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                  // <p>Drop the files here ...</p>
                                  <div className="d-flex justify-content-center align-items-center h-100 w-100">
                                    Drop the files here ...
                                  </div>
                                ) : (
                                  <div>
                                    <Image
                                      className="mb-4"
                                      src={upload}
                                      alt="Upload"
                                    />

                                    <p className="m-1">
                                      Drag and drop here
                                      <br />
                                      OR
                                    </p>
                                    <RButton
                                      className="p-3 mb-5 explore-border-button"
                                      style={{
                                        zIndex: 99,
                                        backgroundColor: "#27292a",
                                        border: 0,
                                        outline: 0,
                                      }}
                                    >
                                      <span className="m-4 poppins text-white">
                                        BROWSE
                                      </span>
                                    </RButton>
                                    <p className="m-2">
                                      <i>
                                        NOTE - Upload the <b>GLTF</b> file along
                                        with other files ( like{" "}
                                        <b>FBX, OBJ & many more</b> )
                                      </i>
                                      <br />
                                      <i>
                                        to view your model in the 3D Viewer.
                                      </i>
                                      <br />
                                      You can also upload an archive like ZIP,
                                      RAR containing your textures, matertials
                                      and mesh.
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Card>
                        )}
                      </Dropzone>
                    )}
                  {!viewerSwitch && !isFileSending && (
                    <Card
                      className="m-2 d-flex flex-column align-items-center border-R20 admin-filedrop-card"
                      dark={true}
                      style={{
                        backgroundColor: "#141415",
                        border: "1px solid #3D3D3D",
                        width: "738px",
                        height: "460px",
                        borderRadius: "16px",
                        overflow: "hidden",
                      }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <div className="text-center" style={{ fontSize: 14 }}>
                          Your 3D viewer is off. Turn it on to let<br></br> the
                          world see your 3D creation!
                        </div>
                        <br />
                        <div className="mt-2">
                          <input
                            ref={inputRef}
                            type="file"
                            // name="filename"
                            hidden
                            multiple
                            onChange={() => {
                              //consolelog(inputRef.current.files);
                              setProductFiles((productFiles) => [
                                ...productFiles,
                                ...inputRef.current.files,
                              ]);
                              //consolelog(
                              //   "&&&&&",
                              //   inputRef.current.files.filelist
                              // );
                              handleFileChange(inputRef.current.files);
                              // handleFileUpload(inputRef.current.files);
                              toast.success(
                                "Your file is added to the product bucket."
                              );
                            }}
                          />
                          <RButton
                            variant="dark"
                            className=" w-100 explore-border-button d-flex explore-button-large-no-neo"
                            size="lg"
                            // style={{height:36,width:200}}
                            onClick={handleAddMoreFileUpload}
                          >
                            <span
                              className="poppins-500"
                              style={{ fontSize: 14 }}
                            >
                              UPLOAD MORE FILES
                            </span>
                          </RButton>
                        </div>
                      </div>
                    </Card>
                  )}
                </Col>

                <Col sm={4}>
                  <Card
                    className="m-2 p-0 py-4 h-100 d-flex flex-column border-R20"
                    style={{
                      backgroundColor: "#141415",
                      border: "1px solid #3D3D3D",
                    }}
                  >
                    <div className="d-flex border-0 text-white p-2 align-items-center">
                      <div className="h4 poppins-500 text-center mx-3">
                        File Contains ({productFiles?.length})
                      </div>
                    </div>
                    <div
                      className="body-text1 px-2 overflow-scroll"
                      style={{ maxHeight: 400 }}
                    >
                      {productFiles.length > 0 ? (
                        <>
                          {productFiles.map((item, index) => {
                            const isClicked = clickedFileIndex === index;

                            return (
                              <>
                                <div
                                  className="d-flex justify-content-between p-2 mx-2 rounded"
                                  style={styles.fileNames}
                                  key={index}
                                >
                                  <Card
                                    className="w-100 p-2 me-2"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      backgroundColor: "#0d0d0d",
                                      border: "1px solid #3D3D3D",
                                      borderRadius: "4px 4px 4px 4px",
                                      fontSize: 14,
                                    }}
                                  >
                                    {item.name
                                      ? item.name
                                      : item.file?.replace(/^.*[\\\/]/, "")}
                                    ``{" "}
                                  </Card>
                                  <Card
                                    className="p-2 d-flex justify-content-center align-items-center cursor-pointer"
                                    style={{
                                      backgroundColor: "#292929",
                                      border: "1px solid #3D3D3D",
                                    }}
                                    onClick={(e) => {
                                      console.log("card clicked");
                                      handleFileDelete(item, index);
                                    }}
                                  >
                                    <MdDelete color="#fff" size="20" />
                                  </Card>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <div className="my-5 px-4 py-5">Not Uploaded Yet!</div>
                      )}
                    </div>
                    {productFiles.length > 0 && (
                      <div className="px-4">
                        <input
                          ref={inputRef}
                          type="file"
                          // name="filename"
                          hidden
                          multiple
                          onChange={() => {
                            setProductFiles((productFiles) => [
                              ...productFiles,
                              ...inputRef.current.files,
                            ]);

                            handleFileChange(inputRef.current.files);

                            toast.success(
                              "Your file is added to product bucket."
                            );

                            // startUpload(updatedFiles)
                          }}
                        />
                        <RButton
                          className="explore-button-no-neo w-100"
                          dark={true}
                          size="lg"
                          onClick={handleAddMoreFileUpload}
                        // style={styles.submitButton}
                        >
                          <span className="poppins">ADD MORE</span>
                        </RButton>
                      </div>
                    )}
                  </Card>
                </Col>
                <Col sm={8}>
                  <Row>
                    <Col sm={4} className="mx-3">
                      <Row>
                        <Card
                          className="m-2 mt-4 w-100 p-0 d-flex flex-row align-items-center"
                          dark={true}
                          style={{
                            backgroundColor: "#3D3D3D",
                            border: "1px solid #3D3D3D",
                            height: "64px",
                          }}
                        >
                          <div className="m-0 mx-3 col-6 text-white d-flex align-items-center">
                            <p
                              className="text-white m-0"
                              style={{ fontSize: 20 }}
                            >
                              3D VIEWER{" "}
                              <span
                                ref={tooltipRef}
                                style={{ marginBottom: 5 }}
                              >
                                <AiOutlineInfoCircle
                                  size={16}
                                  onClick={() =>
                                    setSeeInfoTooltip(!seeInfoTooltip)
                                  }
                                />
                              </span>
                              <Overlay
                                show={seeInfoTooltip}
                                target={tooltipRef.current}
                                placement="bottom-start"
                                containerPadding={20}
                                onHide={() => setSeeInfoTooltip(false)}
                              >
                                <Tooltip
                                  id="custom-tooltip"
                                  className="custom-tooltip"
                                >
                                  <div
                                    className=""
                                    style={{
                                      maxWidth: "424px",
                                      maxHeight: "80px",
                                    }}
                                  >
                                    <p
                                      className=" poppins-400 p-0 "
                                      style={{
                                        width: "422px",
                                        height: "80px",
                                        fontSize: "12px",
                                        marginLeft: "-6px",
                                      }}
                                    >
                                      We are still improving our 3D viewer so
                                      sometimes it might happen that your model
                                      is not looking good in the 3D viewer. At
                                      that time,you can turn off the 3D viewer
                                      option.
                                    </p>
                                  </div>
                                </Tooltip>
                              </Overlay>
                            </p>
                          </div>

                          <Switch
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={viewerSwitch}
                            onColor="#2ED15C"
                            offColor="FF4136"
                            onHandleColor="#1f1f1f"
                            offHandleColor="#292929"
                            onChange={handleSwitchChange}
                            activeBoxShadow="none"
                            className="custom-switch col-3 text-end m-0"
                          />
                        </Card>
                      </Row>
                    </Col>
                  </Row>
                  {/* <Card
                    className="m-2 mt-4 p-0 d-flex flex-column border-R20"
                    dark={true}
                    style={{
                      backgroundColor: "#141415",
                      border: "1px solid #3D3D3D",
                    }}
                  > */}
                  {/* <Col sm={6}> */}
                  {/* <div
                      className="row my-4 text-white mx-2"
                      style={{ flex: 1, flexWrap: "wrap" }}
                    > */}
                  {/* <div className="body-text2 poppins-500 text-start"> */}
                  {/* <p className="col-8 text-white"> */}
                  {/* We are still improving our 3d viewer so sometimes it
                        might happen that your model is not looking good in 3d
                        viewer at that time you can turn off the 3D viewer
                        option */}
                  {/* </div> */}
                  {/* </p> */}

                  {/* </Col> */}
                  {/* <Col sm={2}> */}
                  {/* <div
                        className="col-4"
                        style={{ flex: 1, flexWrap: "wrap" }}
                      > */}
                  {/* <div className="app" style={{ textAlign: "center" }}> */}
                  {/* <Switch
                            checkedIcon={false}
                            // defaultChecked={true}
                            uncheckedIcon={false}
                            checked={viewerSwitch}
                            onColor="#2ED15C"
                            offColor="FF4136"
                            onHandleColor="#1f1f1f"
                            offHandleColor="#292929"
                            onChange={handleSwitchChange}
                            activeBoxShadow="none"
                            className="custom-switch"
                          /> */}
                  {/* <Form>

                           <Form.Check // prettier-ignore

                              type="switch"
                              id="custom-switch"
                              size={20}
                              label="Check this switch"
                              />
                          </Form> */}
                  {/* <FormControlLabel
                              control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                              label="iOS style"
                            /> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </Col> */}
                  {/* </div> */}
                  {/* </Card> */}
                </Col>
              </Row>
            </Container>
            <Container className="p-5 text-white" fluid>
              <div className="poppins-500 subtitle px-1">Upload Images</div>
              <Row>
                <Col sm={8} className="position-relative">
                  <input
                    ref={imageinputRef}
                    type="file"
                    // name="filename"
                    hidden
                    accept="image/*"
                    multiple
                    onChange={() => {
                      //consolelog(imageinputRef.current.files);
                      setProductImages(
                        productImages.concat(...imageinputRef.current.files)
                      );
                    }}
                  />
                  {imageUploaded && (
                    <>
                      <RButton
                        variant="dark"
                        className="explore-border-button-round explore-button-large-no-neo-round position-absolute"
                        style={{
                          backgroundColor: "#27292A",
                          right: "40px",
                          top: "30px",
                          zIndex: 20,
                        }}
                        onClick={() => {
                          imageinputRef.current?.click();
                        }}

                      // setEditorEnabled(true);
                      >
                        <span className="poppins">
                          <FiUpload size={20} />
                        </span>
                      </RButton>
                    </>
                  )}

                  {imageUploaded && productImages.length > 0 && (
                    <Card
                      className="m-2 border-R20 overflow-hidden d-flex justify-content-center align-items-center"
                      dark={true}
                      style={{
                        backgroundColor: "#141415",
                        border: "1px solid #3D3D3D",
                        //  width: "85vh",
                        height: "60vh",
                        //  minHeight:450,
                        //  minWidth:720,
                        //  maxHeight
                      }}
                    >
                      <Image
                        className="my-3 p-4"
                        src={
                          productImages[uploadedImageIndex]?.image_file != null
                            ? productImages[uploadedImageIndex]?.image_file
                            : URL.createObjectURL(
                              productImages[uploadedImageIndex]
                            )
                        }
                        alt="Image Unavailable"
                        style={{ maxWidth: 500, maxHeight: 500 }}
                      />
                    </Card>
                  )}

                  {!imageUploaded && (
                    <Dropzone
                      accept={{
                        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
                      }}
                      useFsAccessApi={true}
                      // accept={["image/png"]}
                      onDrop={(acceptedFiles) => {
                        // //consolelog("88888888888888888",acceptedFiles);
                        // if (acceptedFiles[0].path.split(".").pop() === "jpg") //consolelog("The file has a 'sour' extension.");
                        // //consolelog(productFiles.concat(acceptedFiles));
                        toast.success(
                          "Your image file is added to product bucket."
                        );
                        // //consolelog("$$$$$$$$$$$$$$$$$$$$", productImages);
                        setProductImages(productImages.concat(acceptedFiles));
                        //consolelog(acceptedFiles);
                        // Do something with the files
                      }}
                      // accept= {'image/png': ['.png']}
                      noKeyboard={true}
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        open,
                      }) => (
                        <Card
                          className="m-2 d-flex flex-column align-items-center justify-content-center h-100 border-R20 admin-filedrop-card"
                          style={{
                            backgroundColor: "#141415",
                            border: "1px solid #3D3D3D",
                          }}
                        >
                          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                            <div
                              {...getRootProps()}
                              className="text-center h-100 w-100 d-flex justify-content-center align-items-center w-100 h-100"
                              onClick={open}
                            >
                              <input {...getInputProps()} />
                              {isDragActive ? (
                                <div className="d-flex justify-content-center align-items-center h-100 w-100">
                                  Drop the files here ...
                                </div>
                              ) : (
                                <div>
                                  <Image
                                    className="mb-4"
                                    src={upload}
                                    alt="Upload"
                                  />
                                  <p className="m-1">
                                    Drag and drop here
                                    <br />
                                    OR
                                  </p>
                                  <RButton
                                    className="p-3 mb-5 explore-border-button"
                                    style={{
                                      zIndex: 99,
                                      backgroundColor: "#27292a",
                                      border: 0,
                                      outline: 0,
                                    }}
                                  >
                                    <span className="m-4 poppins text-white">
                                      BROWSE
                                    </span>
                                  </RButton>
                                  {/* <p>
                                  These are the rendered images user can see when he/she buys the product.
                                </p> */}
                                </div>
                              )}
                            </div>
                          </div>
                        </Card>
                      )}
                    </Dropzone>
                  )}
                </Col>

                <Col sm={4}>
                  <Card
                    className="m-2 p-0 py-4 h-100 d-flex flex-column border-R20"
                    style={{
                      backgroundColor: "#141415",
                      border: "1px solid #3D3D3D",
                    }}
                  >
                    <div className="d-flex border-0 text-white p-2 align-items-center">
                      <div className="h5 poppins-500 text-center mx-4">
                        Guidelines
                      </div>
                    </div>
                    <div className="body-text2 px-3">
                      <ListGroup className="border-0 text-white itemGroup">
                        <ListGroup.Item className="listItem text-white d-flex">
                          <div>
                            <BsCircleFill size="10" className="mx-2" />
                          </div>
                          <div>First Image is primary Image</div>
                        </ListGroup.Item>
                        <ListGroup.Item className="listItem text-white d-flex">
                          <div>
                            <BsCircleFill size="10" className="mx-2" />
                          </div>
                          <div>
                            Include 2D texture. Base Color/Normal in your
                            renders
                          </div>
                        </ListGroup.Item>
                        {/* <ListGroup.Item className="listItem text-white d-flex">
                        <div>
                          <BsCircleFill size="10" className="mx-2" />
                        </div>
                        <div>
                          You can upload maximum 10 images ( more images , more
                          chances to buy )
                        </div>
                      </ListGroup.Item> */}
                        <ListGroup.Item className="listItem text-white d-flex">
                          <div>
                            <BsCircleFill size="10" className="mx-2" />
                          </div>
                          <div>
                            You can upload maximum 50 images ( more images ,
                            more chances to buy )
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="listItem text-white d-flex">
                          <div>
                            <BsCircleFill size="10" className="mx-2" />
                          </div>
                          <div>Image Resolution 1920*1080 ( Ideal )</div>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
            {productImages.length > 0 ? (
              <div>
                {/* <ZSlider type="admin-uploads" items={productImages} /> */}
                <CustomSlider
                  items={productImages}
                  setUploadedImageIndex={setUploadedImageIndex}
                  setItems={setProductImages}
                  type="upload_images"
                  deleteCallback={(index) => {
                    setProductImages(
                      productImages.filter((item1, index1) => index1 !== index)
                    );
                  }}
                />
              </div>
            ) : (
              <Card
                className="mx-5 mt-4 h-100 border-R20 d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#141415",
                  border: "1px solid #3D3D3D",
                  width: "120px",
                  minHeight: "120px",
                }}
              >
                <Image
                  src={emptyimage}
                  alt="Empty Image"
                  style={{ width: "36px", height: "36px" }}
                />
              </Card>
            )}
            <Container className="p-5 text-white" fluid>
              <div className="poppins-500 subtitle px-1 my-2">
                Upload Video Link
              </div>
              <Row>
                <Col sm={8}>
                  {linkValues.map((element, index) => (
                    <Row className="my-3 d-flex" key={index}>
                      <Col cm={10}>
                        <Form.Control
                          className="text-white border-R10"
                          name="link"
                          type="text"
                          // value={element.link || ""}
                          value={element.link || ""}
                          onChange={(e) => handleLinkChange(index, e)}
                          style={{
                            background: "#141415",
                            height: 50,
                            border: "1px solid #3D3D3D",
                          }}
                        />
                      </Col>
                      <Col sm={2} className="d-flex justify-content-start">
                        {element.link && index !== linkValues.length - 1 ? (
                          <Card
                            className="p-2 d-flex justify-content-center align-items-center"
                            style={{
                              backgroundColor: "#292929",
                              border: "1px solid #3D3D3D",
                            }}
                          >
                            <MdDelete
                              onClick={() => removeLinkFields(index)}
                              color="#fff"
                              size="20"
                            />
                          </Card>
                        ) : (
                          <RButton
                            className="p-2 explore-border-button"
                            style={{
                              zIndex: 99,
                              backgroundColor: "#27292a",
                              border: 0,
                              outline: 0,
                            }}
                            onClick={() => addLinkFields()}
                          >
                            <span className="m-4 poppins text-white">ADD</span>
                          </RButton>
                        )}
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col sm={4}>
                  <Card
                    className="m-2 p-0 py-4 h-100 d-flex flex-column border-R20"
                    style={{
                      backgroundColor: "#141415",
                      border: "1px solid #3D3D3D",
                    }}
                  >
                    <div className="d-flex border-0 text-white p-2 align-items-center">
                      <div className="h5 poppins-500 text-center mx-4">
                        Guidelines
                      </div>
                    </div>
                    <div className="body-text2 px-3">
                      <ListGroup className="border-0 text-white itemGroup">
                        <ListGroup.Item className="listItem text-white d-flex">
                          <div>
                            <BsCircleFill size="10" className="mx-2" />
                          </div>
                          <div>
                            Youtube, Vimeo, Marmoset Video links are acceptable
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="listItem text-white d-flex">
                          <div>
                            <BsCircleFill size="10" className="mx-2" />
                          </div>
                          <div>
                            Include animations or rig in video will increase
                            chances of sell
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
            <Container className="p-5 text-white" fluid>
              <div className="poppins-500 subtitle px-1 my-2">
                Product Description and Tags
              </div>
              <Row>
                <Col sm={12}>
                  <Card
                    className="m-2 d-flex flex-column border-R20 admin-filedrop-card"
                    style={{
                      backgroundColor: "#141415",
                      border: "1px solid #3D3D3D",
                    }}
                  >
                    <Row>
                      <Col className="p-5 body-text py-4 mb-0">
                        <Form.Label className="required">Title</Form.Label>
                        <Form.Group className="d-flex justify-content-end align-items-center position-relative mb-4">
                          <Form.Control
                            name="title"
                            type="text"
                            value={title}
                            required
                            className="text-white border-R10"
                            placeholder="Enter Title"
                            style={{
                              background: "#0d0d0d",
                              height: 50,
                              border: "1px solid #3D3D3D",
                            }}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                          />
                          <div className="position-absolute d-flex">
                            <RButton
                              type="button"
                              disabled
                              className="wrapped-button mx-1 border-R10"
                              style={{
                                backgroundColor: "#3d3d3d",
                                border: 0,
                                color: "#ffffff",
                              }}
                              onKeyDown={(e) => e.preventDefault()}
                            >
                              <div className="px-2 py-1">3D Model</div>
                            </RButton>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="p-5 body-text py-4 mb-0">
                        <Form.Label>
                          Description &nbsp;{" "}
                          <span style={{ color: "#666666" }}>
                            (Maximum up to 5000 words)
                          </span>
                        </Form.Label>
                        {/* <Form.Control
                        name="description"
                        as="textarea"
                        rows={6}
                        value={description}
                        className="text-white"
                        placeholder="Enter Description"
                        style={{
                          background: "#141414",
                          border: 0,
                        }}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      /> */}
                        {/* {!loading && !description && (
                          <CKEditor
                            initData={""}
                            config={editorConfig}
                            editorUrl={
                              process.env.REACT_APP_CLOUDFRONT_URL +
                              "/static/ckeditor/4.19.0/full-all/ckeditor.js"
                            }
                            onInstanceReady={(e) => {
                              e.editor.document
                                .getBody()
                                .setStyle("color", "white");
                              e.editor.document
                                .getBody()
                                .setStyle("background-color", "#0d0d0d");
                              e.editor.on("contentDom", function () {
                                e.editor.document
                                  .getBody()
                                  .setStyle("background-color", "#0d0d0d");
                              });
                            }}
                            onChange={(event) => {
                              const data = event.editor.getData();
                              setDescription(data);
                            }}
                          />
                        )} */}
                        {!loading && thisProduct && (
                          <CKEditor
                            initData={description}
                            config={editorConfig}
                            editorUrl={
                              process.env.REACT_APP_CLOUDFRONT_URL +
                              "/static/ckeditor/4.19.0/full-all/ckeditor.js"
                            }
                            onInstanceReady={(e) => {
                              e.editor.document
                                .getBody()
                                .setStyle("color", "white");
                              e.editor.document
                                .getBody()
                                .setStyle("background-color", "#0d0d0d");
                              e.editor.on("contentDom", function () {
                                e.editor.document
                                  .getBody()
                                  .setStyle("background-color", "#0d0d0d");
                              });
                            }}
                            onChange={(event) => {
                              const data = event.editor.getData();
                              setDescription(data);
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} className="p-5 body-text pt-4 pb-5">
                        <Form.Label>
                          Tags (Enter or Select multiple tags that applies to
                          your model)
                        </Form.Label>
                        {/* <ZDropdown
                        options={tags}
                        setAddTags={setAddTags}
                        formReference={tagRef}
                        mode={"multitagselect"}
                      /> */}
                        <CreatableSelect
                          ref={tagRef}
                          isMulti
                          options={tags}
                          onChange={handleChange}
                          styles={customStyles}
                        />
                        {/* <p className="my-2">(Enter or Select multiple tags that applies to your model)</p> */}
                      </Col>
                      {/* <Col
                        sm={6}
                        className="p-5 body-text py-4 d-flex align-items-end"
                      >
                      </Col> */}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
            <Container
              className="p-5 text-white"
              fluid
              ref={categoryScrollIntoRef}
            >
              <div className="poppins-500 subtitle px-1 my-2">
                Technical Details
              </div>
              <Card
                className="m-2 d-flex flex-column border-R20 admin-filedrop-card"
                style={{
                  backgroundColor: "#141415",
                  border: "1px solid #3D3D3D",
                }}
              >
                <div className="ms-4 mt-4 body-text">
                  Select features that applies to your model
                </div>
                <Row className="row-cols-4 m-3">
                  {productDetails.map((item, index) => {
                    return (
                      <ToggleButton
                        type="productDetails"
                        item={item}
                        array={productDetails}
                        setArray={setProductDetails}
                        key={index}
                      />
                    );
                  })}
                </Row>
                <Row className="mx-3">
                  <Col sm={4} className="body-text">
                    <Form.Label className="required">Unwrapped UVs</Form.Label>
                    <ZDropdown
                      options={unwrapped_uvs_options}
                      mode={"productselect"}
                      formReference={unWrappedRef}
                    />
                  </Col>
                  <Col sm={4} className="body-text text-white">
                    <Form.Label className="required">Geometry</Form.Label>
                    <ZDropdown
                      options={geometry_options}
                      mode={"productselect"}
                      formReference={geometryRef}
                    />
                  </Col>
                </Row>
                <Row className="mx-3 mt-4">
                  <Col sm={3} className="body-text">
                    <Form.Group>
                      <Form.Label className="required">Polygons</Form.Label>
                      <Form.Control
                        name="polygons"
                        type="text"
                        required
                        defaultValue={0}
                        value={polygons}
                        onChange={(e) => {
                          setPolygons(e.target.value);
                        }}
                        className="text-white"
                        style={{
                          background: "#0d0d0d",
                          height: 50,
                          border: "1px solid #3D3D3D",
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={3} className="body-text">
                    <Form.Label className="required">Vertices</Form.Label>
                    <Form.Control
                      name="vertices"
                      type="text"
                      required
                      defaultValue={0}
                      value={vertices}
                      onChange={(e) => {
                        setVertices(e.target.value);
                      }}
                      className="text-white"
                      style={{
                        background: "#0d0d0d",
                        height: 50,
                        border: "1px solid #3D3D3D",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mx-3 my-4">
                  <Col sm={4} className="body-text">
                    <Form.Label className="required">
                      Product Category
                    </Form.Label>
                    <ZDropdown
                      options={category_options}
                      mode={"productselect"}
                      formReference={categoryRef}
                    />
                  </Col>
                  <Col sm={4} className="body-text">
                    <Form.Label className="required">Software Used</Form.Label>
                    <ZDropdown
                      options={software_options}
                      mode={"productselect"}
                      formReference={softwareRef}
                    />
                  </Col>
                  <Col sm={4} className="body-text">
                    <Form.Label className="required">
                      Viewer Extension
                    </Form.Label>
                    <ZDropdown
                      options={extension_options}
                      mode={"productselect"}
                      formReference={extensionRef}
                    />
                  </Col>
                </Row>
                {is_admin && (
                  <div>
                    <div className="ms-4 body-text">Product Usage</div>
                    <Row className="row-cols-4 m-3">
                      {productUsage.map((item, index) => {
                        return (
                          <ToggleButton
                            type="productUsage"
                            item={item}
                            array={productUsage}
                            setArray={setProductUsage}
                            key={index}
                          />
                        );
                      })}
                    </Row>
                  </div>
                )}
              </Card>
            </Container>

            <Container className="p-5 text-white" fluid>
              <div className="poppins-500 subtitle px-1 my-2">
                Pricing & License
              </div>
              <Card
                className="m-2 border-R20"
                style={{
                  backgroundColor: "#141415",
                  border: "1px solid #3D3D3D",
                }}
              >
                <Row>
                  <Col sm={4}>
                    <Card
                      className="m-5 d-flex flex-row"
                      style={{
                        backgroundColor: "#0D0D0D",
                        border: "1px solid #3D3D3D",
                      }}
                    >
                      <RButton
                        className="wrapped-button m-2 py-2"
                        style={
                          !freeProduct
                            ? styles.selectedModeButton
                            : styles.unselectedModeButton
                        }
                        onClick={() => setFreeProduct(false)}
                      >
                        Paid
                      </RButton>
                      <RButton
                        className="wrapped-button m-2 py-2"
                        style={
                          freeProduct
                            ? styles.selectedModeButton
                            : styles.unselectedModeButton
                        }
                        onClick={() => {
                          setFreeProduct(true);
                          setPrice(0);
                        }}
                      >
                        Free
                      </RButton>
                    </Card>
                  </Col>
                </Row>
                {freeProduct && (
                  <Row className="px-5 pb-5">
                    <div className="pb-3 d-flex align-items-center">
                      <h4 className="py-0 my-0 me-2 required">
                        License&nbsp;
                        <FiInfo
                          className="text-white"
                          size={20}
                          onClick={(e) => {
                            e.preventDefault();
                            setProductLicenseDescription(true);
                          }}
                        />
                      </h4>
                    </div>
                    <Col sm={6} className="body-text">
                      <ZDropdown
                        options={free_license_options}
                        mode={"license"}
                        formReference={licenseRef}
                      />
                    </Col>
                  </Row>
                )}
                <Modal
                  style={{ borderRadius: "20px" }}
                  show={productLicenseDescription}
                  onHide={() => setProductLicenseDescription(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  backdrop={"static"}
                  contentClassName="border-R20 user-select-none p-0"
                  onExited={() => {
                    setProductLicenseDescription(false);
                  }}
                >
                  <IconButton
                    size="large"
                    className="d-flex justify-content-center removeButtonRight position-absolute text-white"
                    dark={true}
                    style={{ zIndex: 1000 }}
                  >
                    <AiOutlineClose
                      size={20}
                      onClick={() => setProductLicenseDescription(false)}
                    />
                  </IconButton>
                  <Modal.Body
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      border: "2.5px solid #BEBEBE",
                      backgroundColor: "#1f1f1f",
                      borderRadius: "20px 20px 20px 20px",
                    }}
                  >
                    {/* {freeProduct ? (
                      <Image
                        src={freelicense}
                        alt="Free License Description"
                        className="p-0 m-0"
                        style={{ borderRadius: "20px" }}
                      />
                    ) : (
                      <Image
                        src={paidlicense}
                        alt="Paid License Description"
                        className="p-0 m-0"
                        style={{ borderRadius: "20px" }}
                      />
                    )} */}
                    {freeProduct ? (
                      <div className="my-5 mx-3">
                        {free_licenses.map((free_license, index) => {
                          return (
                            <ul>
                              <li className="text-white subtitle">
                                {free_license.title} :{" "}
                                {free_license.description}
                              </li>
                              {free_license.link && (
                                <a
                                  href={free_license.link}
                                  target="_blank"
                                  className="text-decoration-none"
                                >
                                  <u
                                    className="text-white me-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    LINK
                                  </u>
                                  <Image
                                    src={whitelink}
                                    style={{ width: 14, height: 14 }}
                                  />
                                </a>
                              )}
                            </ul>
                          );
                        })}
                      </div>
                    ) : (
                      <Image
                        src={paidlicense}
                        alt="Paid License Description"
                        className="p-0 m-0"
                        style={{ borderRadius: "20px" }}
                      />
                    )}
                  </Modal.Body>
                </Modal>
                {!freeProduct && (
                  <>
                    <Row className="px-5 pb-4">
                      <Col sm={4}>
                        <div className="pb-2 d-flex align-items-center">
                          <h4 className="py-0 my-0">Price</h4>
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip
                                id="button-tooltip"
                                style={{ width: 500 }}
                              >
                                <div className="px-2">
                                  WE CHARGE 9 % ON EACH SELL OF YOUR PRODUCT And
                                  Trascation charges 2% for Domestic Buyers and
                                  3% for international buyers
                                </div>
                              </Tooltip>
                            }
                          >
                            <IconButton
                              dark={true}
                              onClick={(e) => e.preventDefault()}
                            >
                              <FiInfo className="text-white" size={20} />
                            </IconButton>
                          </OverlayTrigger>
                        </div>
                        <Form.Group className="d-flex justify-content-end align-items-center position-relative mb-4">
                          <Form.Control
                            type="text"
                            size="lg"
                            required
                            className="text-white"
                            defaultValue={0}
                            value={price}
                            onChange={(e) => {
                              setPrice(e.target.value);
                            }}
                            style={{
                              background: "#0D0D0D",
                              height: 64,
                              borderColor: "#3D3D3D",
                              borderRadius: 5,
                              fontSize: 26,
                            }}
                          />
                          <div className="position-absolute d-flex">
                            <div
                              style={{
                                backgroundColor: "#fff",
                                border: 0,
                                width: 1,
                              }}
                            ></div>
                            <RButton
                              type="button"
                              className="wrapped-button mx-2 text-white"
                              style={{
                                backgroundColor: "#0D0D0D",
                                border: 0,
                                fontSize: 20,
                              }}
                              onKeyDown={(e) => e.preventDefault()}
                            >
                              USD
                            </RButton>
                          </div>
                        </Form.Group>
                        {!applyDiscount && (
                          <div className="mt-4 poppins d-flex align-items-center">
                            <span className="h4 p-0 m-0">
                              {(price - price * 0.115).toFixed(2)} $
                            </span>
                            <span className="poppins body-text2">
                              Approx Amount you will receive
                            </span>
                          </div>
                        )}
                      </Col>
                      <Col sm={6} className="body-text">
                        <div className="pb-3 d-flex align-items-center">
                          <h4 className="py-0 my-0 me-2 required">
                            License&nbsp;
                            <FiInfo
                              className="text-white"
                              size={20}
                              onClick={(e) => {
                                e.preventDefault();
                                setProductLicenseDescription(true);
                              }}
                            />
                          </h4>
                        </div>
                        <ZDropdown
                          options={paid_license_options}
                          mode={"license"}
                          formReference={licenseRef}
                        />
                      </Col>
                    </Row>
                    {availableCoupons.length > 0 && (
                      <div className="d-flex align-items-center px-5 pb-4">
                        {applyDiscount ? (
                          <div className="d-flex align-items-center mb-3">
                            <Image
                              src={biggreentick}
                              alt="Selected"
                              onClick={(e) => {
                                e.preventDefault();
                                if (applyDiscount) {
                                  setAddCoupons([]);
                                  couponsRef.current?.setValue([]);
                                }
                                setApplyDiscount(!applyDiscount);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="d-flex align-items-center mb-3">
                            <Image
                              src={biggreytick}
                              alt="Not Selected"
                              onClick={(e) => {
                                e.preventDefault();
                                setApplyDiscount(true);
                                if (!applyDiscount) {
                                  setAddCoupons([]);
                                  couponsRef.current?.setValue([]);
                                }
                              }}
                            />
                          </div>
                        )}
                        <div className="ms-3 h5">
                          I want to Apply Discount for this product
                        </div>
                      </div>
                    )}
                  </>
                )}
                <Container
                  className={`px-5 text-white ${applyDiscount ? "d-block" : "d-none"
                    }`}
                  fluid
                  style={{ backgroundColor: "#0d0d0d" }}
                >
                  <div className="py-3">Please Select coupons to apply</div>
                  <Row className="row-cols-4 m-2">
                    {availableCoupons.map((item, index) => {
                      return (
                        <ToggleButton
                          type="coupons"
                          item={item}
                          array={addCoupons}
                          setArray={setAddCoupons}
                          key={index}
                        />
                      );
                    })}
                  </Row>
                </Container>
                <Container
                  className={`p-5 text-white ${applyDiscount ? "d-block" : "d-none"
                    }`}
                  fluid
                >
                  <div className="d-flex align-items-center">
                    <h4 className="p-0 m-0">Discounted Price</h4>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip" style={{ width: 500 }}>
                          <div className="px-2">
                            WE CHARGE 9 % ON EACH SELL OF YOUR PRODUCT And
                            Trascation charges 2% for Domestic Buyers and 3% for
                            international buyers
                          </div>
                        </Tooltip>
                      }
                    >
                      <IconButton
                        dark={true}
                        onClick={(e) => e.preventDefault()}
                      >
                        <FiInfo className="text-white" size={20} />
                      </IconButton>
                    </OverlayTrigger>
                  </div>
                  <Row className="mt-4">
                    <Col sm={5}>
                      <Form.Group className="d-flex justify-content-end align-items-center position-relative mb-4">
                        <Form.Control
                          type="text"
                          size="lg"
                          className="text-white"
                          value={discountedPrice}
                          defaultValue={0}
                          onChange={(e) => {
                            setPrice(e.target.value);
                          }}
                          disabled
                          style={{
                            background: "#0D0D0D",
                            height: 64,
                            borderColor: "#3D3D3D",
                            borderRadius: 5,
                            fontSize: 26,
                          }}
                        />
                        <div className="position-absolute d-flex">
                          <div
                            style={{
                              backgroundColor: "#fff",
                              border: 0,
                              width: 1,
                            }}
                          ></div>
                          <RButton
                            type="button"
                            className="wrapped-button mx-2 text-white"
                            style={{
                              backgroundColor: "#0D0D0D",
                              border: 0,
                              fontSize: 20,
                            }}
                            onKeyDown={(e) => e.preventDefault()}
                          >
                            USD
                          </RButton>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <div className="d-flex flex-column justify-content-center">
                        <div className="poppins h4">
                          {(discountedPrice - discountedPrice * 0.115).toFixed(
                            2
                          )}
                          {" $"}
                        </div>
                        <div className="poppins body-text2">
                          Approx Amount you will receive
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Card>
            </Container>
            <div className="d-flex py-5 justify-content-center align-items-center">
              <RButton
                className="btn btn-link border-0 wrapped-button"
                style={{ background: "transparent", textDecoration: "none" }}
                type="submit"
                disabled={isDrafting}
              >
                <RButton
                  className="explore-border-button explore-button-large-no-neo"
                  variant="dark"
                  size="large"
                  style={styles.submitButton}
                  disabled={isDrafting}
                  onClick={() => {
                    setDraft(true);
                    setToVerify(false);
                    setPublish(false);
                  }}
                >
                  {isDrafting ? (
                    <span className="SENDD">
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      SAVING DRAFT...
                    </span>
                  ) : (
                    <span className="poppins">SAVE DRAFT</span>
                  )}
                </RButton>
              </RButton>

              {/* <RButton
                className={`${mode === "seller" ? "btn btn-link border-0 wrapped-button" : "d-none"}`}
                style={{ background: "transparent", textDecoration: "none" }}
                type="submit"
                disabled={sendForVerification}
              >
              <RButton
                  className="explore-border-button explore-button-large-no-neo"
                  variant="dark"
                  size="large"
                  style={styles.submitButton}>
                    <span className="poppins">PREVIEW</span>
                </RButton>
                </RButton> */}

              <RButton
                className={`${mode === "seller"
                  ? "btn btn-link border-0 wrapped-button"
                  : "d-none"
                  }`}
                style={{ background: "transparent", textDecoration: "none" }}
                type="submit"
                disabled={sendForVerification}
              >
                <RButton
                  className="explore-button-no-neo"
                  dark={true}
                  size="lg"
                  // style={styles.submitButton}
                  disabled={sendForVerification}
                  style={{
                    width: 250,
                    height: 50,
                    border: changingBorder ? "0px" : "0px",
                  }}
                  onMouseEnter={() => setChangingBroder(true)}
                  onClick={() => {
                    setDraft(false);
                    setToVerify(true);
                    setPublish(false);
                  }}
                >
                  {sendForVerification ? (
                    <span className="SENDD">
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      SENDING...
                    </span>
                  ) : (
                    <span className="poppins">SEND FOR VERIFICATION</span>
                  )}
                </RButton>
              </RButton>
              <RButton
                className={`${mode === "admin"
                  ? "btn btn-link border-0 wrapped-button"
                  : "d-none"
                  }`}
                style={{ background: "transparent", textDecoration: "none" }}
                type="submit"
                disabled={isPublishing}
              >
                <RButton
                  className="explore-button-no-neo"
                  dark={true}
                  size="lg"
                  disabled={isPublishing}
                  style={{
                    width: 250,
                    height: 50,
                    border: changingBorder ? "0px" : "0px",
                  }}
                  onClick={() => {
                    setDraft(false);
                    setToVerify(false);
                    setPublish(true);
                  }}
                >
                  {isPublishing ? (
                    <span className="SENDD">
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      PUBLISHING...
                    </span>
                  ) : (
                    <span className="poppins">PUBLISH</span>
                  )}
                </RButton>
              </RButton>
            </div>
          </Form>
          {/* {currentProduct &&
            <RButton
              ref={ref}
              className="btn btn-link border-0 wrapped-button"
              style={{ background: "transparent", textDecoration: "none" }}
              onClick={(e) => {
                setIsDeleting(true)
                setTarget(e.target);
              }}
            >
              <Button
                dark={true}
                className="explore-border-button m-4 body-text py-4"
                size="large"
                style={styles.submitButton}
              >
                <span className="poppins">DELETE</span>
              </Button>
            </RButton>
          }
          <Overlay
            show={isDeleting}
            target={target}
            placement="top"
            container={ref}
            containerPadding={20}
          >
            <Popover id="popover-contained"
              style={{ backgroundColor: "#555", border: "1px solid white" }}>
              <Popover.Header className="text-white body-text1" style={{ backgroundColor: "#333" }}>Confirm Product Delete</Popover.Header>
              <Popover.Body>
                <Button
                  dark={true}
                  onClick={() => {
                    setIsDeleting(false);
                    dispatch(actions.archiveProduct(currentProduct?.id));
                    navigate(-1)
                  }}
                >
                  Confirm
                </Button>
                <Button dark={true} onClick={() => setIsDeleting(false)} className="">Cancel</Button>
              </Popover.Body>
            </Popover>
          </Overlay> */}
        </Container>
      </Breakpoint>
      <Modal
        style={{ borderRadius: "20px" }}
        show={seeHowModal}
        onHide={() => setSeeHowModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={"static"}
        // backdropClassName="modal-backdrop login-modal-backdrop"
        contentClassName="border-R20 user-select-none"
        onExited={() => {
          setSeeHowModal(false);
        }}
      >
        {/* <Modal.Header> */}
        <IconButton
          size="large"
          className="d-flex justify-content-center removeButtonRight position-absolute text-white"
          dark={true}
          style={{ zIndex: 1000 }}
          onClick={() => setSeeHowModal(false)}
        >
          <AiOutlineClose size={20} />
        </IconButton>
        {/* </Modal.Header> */}
        <Modal.Body
          className="d-flex justify-content-center align-items-center"
          style={{
            border: "2.5px solid #BEBEBE",
            backgroundColor: "#1B1C1D",
            borderRadius: "20px 20px 20px 20px",
          }}
        >
          <Image
            src={seehow_popup}
            alt="See How"
            style={{ width: "450px", borderRadius: "20px" }}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  submitButton: {
    width: 250,
    height: 50,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  fileNames: {
    backgroundColor: "#141414",
  },
  priceCard: {
    borderLeft: "1px solid #4e4e4e",
    marginTop: "10px",
    marginBottom: "10px",
    color: "#555555",
  },
  selectedModeButton: {
    width: "115px",
    backgroundColor: "#fff",
    border: "none",
    color: "black",
  },
  unselectedModeButton: {
    width: "115px",
    backgroundColor: "#0D0D0D",
    border: "none",
    color: "white",
  },
};

export default ProductMain;

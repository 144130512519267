import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Container,
  Breadcrumb,
  Row,
  Col,
  Image,
  Badge,
  Form,
  Spinner,
  ListGroup,
} from "react-bootstrap";
import {
  Card,
  CardContent,
  Subtitle2,
  H5,
  Body2,
  CardAction,
  Button,
  Divider,
  IconButton,
} from "ui-neumorphism";
import { product } from "assets";
import { ZSlider, ZDropdown, ToggleButton } from "components";

import "core-ui/pages/Product.scss";
import { checkedtrue, checkedfalse } from "assets";
import { Link } from "react-router-dom";
import { downarrows, view3d } from "assets";
import { MdDelete } from "react-icons/md";
import "core-ui/pages/admin/Product.scss";
import { BsCircleFill } from "react-icons/bs";
import Dropzone, { useDropzone } from "react-dropzone";
import Select from "react-select";
// import { upload } from "assets_old/admin";
import * as actions from "actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Outlet, useParams } from "react-router-dom";
import Editor from "./Editor";
import { ProductMain } from "..";

const Product = ({ mode }) => {
  console.log(mode);
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param2 = params.length > 0 ? params[1] : "";
  const param3 = params.length > 1 ? params[2] : "";

  // alert(param1);
  // alert(param2);

  const { productId } = useParams();
  // alert(productId);

  if (param3 === "editor" || productId !== undefined) {
    return <Outlet />;
  }

  return <ProductMain mode={mode} />;
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  fileNames: {
    backgroundColor: "#141414",
  },
};

export default Product;

import axios from "axios";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { persistor } from "store";

export const notify = (msg, type) => {
  switch (type) {
    case "success":
      toast.success(msg);
      break;
    case "error":
      toast.error(msg);
      break;
    case "warning":
      toast.warning(msg);
      break;
    default:
      toast.error(msg);
  }
  // alert();
};

////////////////////////// USERS - Action Creator //////////////////////////

export const otp = (data) => {
  return {
    type: "SEND_OTP",
    payload: data,
  };
};

export const login = (data) => {
  return {
    type: "LOGIN",
    payload: data,
  };
};

export const logout = () => {
  // alert();
  return {
    type: "LOGOUT",
    payload: null,
  };
};

export const clearCart = () => {
  // alert();
  return {
    type: "CLEAR_CART",
    payload: null,
  };
};

export const myProfile = (data) => {
  return {
    type: "MY_PROFILE",
    payload: data,
  };
};

export const profileUpdate = (data) => {
  return {
    type: "UPDATE_PROFILE",
    payload: data,
  };
};

// export const updateCurrency = (data) => {
//   return {
//     type: "UPDATE_CURRENCY",
//     payload: data,
//   }
// }

////////////////////////// FAVOURITES - Action Creator //////////////////////////

export const favoriteLoad = (data) => {
  return {
    type: "LOAD_FAVORITES",
    payload: data,
  };
};

export const favoriteAdd = (data) => {
  return {
    type: "ADD_FAVORITE",
    payload: data,
  };
};

export const favoriteRemove = (data) => {
  return {
    type: "REMOVE_FAVORITE",
    payload: data,
  };
};

////////////////////////// SAVEFORLATER - Action Creator //////////////////////////

export const saveForLaterLoad = (data) => {
  return {
    type: "LOAD_SAVEFORLATER",
    payload: data,
  };
};

export const saveForLaterAdd = (data) => {
  return {
    type: "ADD_SAVEFORLATER",
    payload: data,
  };
};

export const saveForLaterRemove = (data) => {
  return {
    type: "REMOVE_SAVEFORLATER",
    payload: data,
  };
};

////////////////////////// CART PRODUCTS - Action Creator //////////////////////////

export const cartProductLoad = (data) => {
  return {
    type: "LOAD_CART_PRODUCT",
    payload: data,
  };
};

export const cartProductAdd = (data) => {
  return {
    type: "ADD_CART_PRODUCT",
    payload: data,
  };
};

export const cartProductUpdate = (data) => {
  //consolelog(data);
  return {
    type: "UPDATE_CART_PRODUCT",
    payload: data,
  };
};

export const cartProductRemove = (data) => {
  return {
    type: "REMOVE_CART_PRODUCT",
    payload: data,
  };
};

////////////////////////// ORDERS - Action Creator //////////////////////////

export const orderLoad = (data) => {
  return {
    type: "LOAD_ORDERS",
    payload: data,
  };
};

export const orderCreate = (data) => {
  return {
    type: "CREATE_ORDER",
    payload: data,
  };
};

////////////////////////// SELLER - Action Creator //////////////////////////

export const sellerLoad = (data) => {
  return {
    type: "LOAD_SELLER",
    payload: data,
  };
};

export const sellerAdd = (data) => {
  return {
    type: "ADD_SELLER",
    payload: data,
  };
};

export const sellerUpdate = (data) => {
  return {
    type: "UPDATE_SELLER",
    payload: data,
  };
};

////////////////////////// BANK ACCOUNT - Action Creator //////////////////////////

export const bankAccountLoad = (data) => {
  return {
    type: "LOAD_BANK_ACCOUNT",
    payload: data,
  };
};

export const bankAccountAdd = (data) => {
  return {
    type: "ADD_BANK_ACCOUNT",
    payload: data,
  };
};

export const bankAccountUpdate = (data) => {
  return {
    type: "UPDATE_BANK_ACCOUNT",
    payload: data,
  };
};

////////////////////////// CURRENCY - Action Creator //////////////////////////

export const currencyRateLoad = (data) => {
  return {
    type: "LOAD_CURRENT_CURRENY_RATE",
    payload: data,
  };
};

////////////////////////// OFFER - Action Creator //////////////////////////

export const updateOfferStatus = (data) => {
  return {
    type: "RELOAD_OFFER",
    payload: data,
  };
};

export const updateBannerOfferStatus = (data) => {
  return {
    type: "RELOAD_BANNER_OFFER",
    payload: data,
  };
};

////////////////////////// USERS - Actions //////////////////////////
export const signUp = async (data) => {
  // return async (dispatch) => {
  try {
    const response = await axios.post(`/user/signup/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    //consolelog("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err.response.data.msg);
    notify(err.response.data.msg, "error");
  }
  // };
};

export const loggingIn = async (data) => {
  // return async (dispatch) => {
  try {
    const response = await axios.post(`/user/login/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status === 200) {
      notify("You are successfully logged in.", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("res", err?.response?.data?.msg);
    notify(err?.response?.data?.msg, "error");
  }
  // };
};

export const sendOTP = async (data) => {
  try {
    const response = await axios.post(`/user/sendotp/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    //consolelog("Response", response);
    if (response.status === 200) {
      // dispatch(login(response.data));
      //consolelog(response.data);
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err);
    notify("Error in login! Try again.", "error");
  }
};

export const verifyAccount = async (data) => {
  try {
    const response = await axios.post("/user/verify/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const reset_password_request = async (data) => {
  try {
    const response = await axios.post("/user/reset_password/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const reset_password = async (data) => {
  try {
    const response = await axios.put("/user/reset_password/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify(err?.response?.data?.msg, "error");
    return err?.response?.data;
  }
};

export const loggingOut = (data) => {
  return async (dispatch) => {
    try {
      // console.log("@@",data)
      const response = await axios.post(`/user/logout/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      //consolelog("Response for logout:", response);
      if (response.status === 204) {
        dispatch(logout());
        notify("You are successfully logged out.", "success");
        console.log("@@",data)
        if (data.type === "seller") {
          window.location.href = "/sell-3d";
        }
      }
        } 
      catch (err) {
      //consolelog("Error", err);
      dispatch(logout());
      persistor.purge();
      // notify("Error in login! Try again.", "error");
    }
    window.location.href="/"
  };
};

export const getMyProfile = (cartProducts) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/user/my_profile/`, cartProducts, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      //consolelog("Response for My Profile:", response);
      if (response.status === 201) {
        if (response.data.id) {
          dispatch(myProfile(response.data));
        }
      } else {
        dispatch(myProfile(cartProducts));
      }
    } catch (err) {
      //consolelog("Error", err);
    }
  };
};

export const updateProfile = async (data, userID) => {
  // return async (dispatch) => {
  try {
    const response = await axios.put(`/user/` + userID + `/profile/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    //consolelog("Response:", response);
    if (response.status === 201) {
      // dispatch(profileUpdate(response.data));
      notify("Profile updated!", "success");
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err);
    if (err.response.status === 402) {
      notify("Email already registered! Try another email.", "error");
    } else {
      notify("Profile could not be updated!", "error");
    }
  }
  // };
};

export const checkAccountVerification = async (data) => {
  try {
    const response = await axios.post(`/user/accountverificationconfirmation/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    //consolelog("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err.response.data.msg);
    notify(err.response.data.msg, "error");
  }
};

////////////////////////// FAVORITES - Actions //////////////////////////

export const addFavorite = (data, userID) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/user/` + userID + `/favourites/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //consolelog("Response", response);
      if (response.status === 201) {
        dispatch(favoriteAdd(response.data));
      }
    } catch (err) {
      //consolelog("Error", err);
      notify("Product could not be added to Favorites!", "error");
    }
  };
};

export const removeFavorite = (data, userID) => {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/user/` + userID + `/favourites/`, {
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      //consolelog("Response", response);
      if (response.status === 204) {
        dispatch(favoriteRemove(data));
      }
    } catch (err) {
      //consolelog("Error", err);
      notify("Product could not be removed from Favourites!", "error");
    }
  };
};

////////////////////////// SAVEFORLATER - Actions //////////////////////////

export const addToSaveForLater = async (data, userID) => {
  // return async (dispatch) => {
  try {
    const response = await axios.post(
      `/user/` + userID + `/saveforlater/`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    //consolelog("Response", response);
    if (response.status === 201) {
      // dispatch(saveForLaterAdd(response.data));
      return response.data;
    }
  } catch (err) {
    // notify("Product could not be added to Save For Later!", "error");
    return false;
  }
  // };
};

export const removeFromSaveForLater = async (data, userID) => {
  // return async (dispatch) => {
  try {
    const response = await axios.delete(
      `/user/` + userID + `/saveforlater/`,
      {
        data,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    //consolelog("Response", response);
    if (response.status === 204) {
      // dispatch(saveForLaterRemove(data));
      return true;
    }
  } catch (err) {
    // notify("Product could not be removed from Save For Later!", "error");
    return false;
  }
  // };
};

////////////////////////// CART PRODUCTS - Actions //////////////////////////

export const addCartproduct = (data, userID) => {
  return async (dispatch) => {
    if (userID) {
      try {
        //consolelog("Cart product data:", data);
        const response = await axios.post(
          `/user/` + userID + `/cartproducts/`,
          { product_id: data.id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        //consolelog("Response tp add cart", response);
        if (response.status === 201) {
          // console.log("##",response)

          dispatch(cartProductAdd(response.data));
        }
      } catch (err) {

        // console.log("##",err,errMsg,err.response.data);

        // //consolelog("Error", err.response.data.msg);
        notify("Product could not be added to Cart!", "error");
      }
    } else {
      let applied_coupon = data.coupons_data.find(
        (item) => item.id === data.best_coupon
      );
      let new_data = {
        applied_coupon: applied_coupon,
        applied_coupon_id: data.best_coupon,
        product: data,
        product_id: data.id,
        user: null,
      };
      dispatch(cartProductAdd(new_data));
    }
  };
};
export const addToCartBuyNow = (data, userID) => {
  return async (dispatch) => {
    if (userID) {
      try {
        //consolelog("Cart product data:", data);
        const response = await axios.post(
          `/user/` + userID + `/cartproducts/`,
          { product_id: data.id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        //consolelog("Response tp add cart", response);
        if (response.status === 201) {
          // console.log("##",response)

          dispatch(cartProductAdd(response.data));
        }
      } catch (err) {
        if (err.response.data.msg === "This product exists in cart.") {
          // console.log("Error", err.response.data.msg);
          notify("Product exists in Cart!", "error");
        }
        else {
          notify("Product could not be added to Cart!", "error");


        }

        // console.log("##",err,errMsg,err.response.data);

        //consolelog("Error", err.response.data.msg);
      }
    } else {
      let applied_coupon = data.coupons_data.find(
        (item) => item.id === data.best_coupon
      );
      let new_data = {
        applied_coupon: applied_coupon,
        applied_coupon_id: data.best_coupon,
        product: data,
        product_id: data.id,
        user: null,
      };
      dispatch(cartProductAdd(new_data));
    }
  };
};

export const addProductToCart = async (data, userID) => {
  if (userID) {
    try {
      //consolelog("Cart product data:", data);
      const response = await axios.post(
        `/user/` + userID + `/cartproducts/`,
        { product_id: data.id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //consolelog("Response tp add cart", response);
      if (response.status === 201) {
        return response.data
      }
    } catch (err) {
      // notify("Product could not be added to Cart!", "error");
      return false;
    }
  } else {
    let applied_coupon = data.coupons_data.find(
      (item) => item.id === data.best_coupon
    );
    let new_data = {
      applied_coupon: applied_coupon,
      applied_coupon_id: data.best_coupon,
      product: data,
      product_id: data.id,
      user: null,
    };
    // dispatch(cartProductAdd(new_data));
    return new_data;
  }
};

export const updateCartproduct = (data, userID) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `/user/` + userID + `/cartproducts/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //consolelog("Response", response);
      if (response.status === 201) {
        dispatch(cartProductUpdate(response.data));
      }
    } catch (err) {
      //consolelog("Error", err);
      notify("Cart Product could not be upated!", "error");
    }
  };
};

export const removeCartproduct = (data, userID) => {
  return async (dispatch) => {
    if (userID) {
      //consolelog("Product removed from user:", userID);
      try {
        const response = await axios.delete(
          `/user/` + userID + `/cartproducts/`,
          {
            data,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        //consolelog("Response", response);
        if (response.status === 204) {
          dispatch(cartProductRemove(data));
        }
      } catch (err) {
        //consolelog("Error", err);
        notify("Product could not be removed from Cart!", "error");
      }
    } else {
      dispatch(cartProductRemove(data));
    }
  };
};

export const removeProductFromCart = async (data, userID) => {
  if (userID) {
    //consolelog("Product removed from user:", userID);
    try {
      const response = await axios.delete(
        `/user/` + userID + `/cartproducts/`,
        {
          data,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //consolelog("Response", response);
      if (response.status === 204) {
        return data;
        // dispatch(cartProductRemove(data));
      }
    } catch (err) {
      // notify("Product could not be removed from Cart!", "error");
      return false;
    }
  } else {
    return data;
    // dispatch(cartProductRemove(data));
  }
};

////////////////////////// ORDERS - Actions //////////////////////////

export const loadOrders = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/order/my_orders/`);
      //consolelog("Response", response);
      dispatch(orderLoad(response.data));
    } catch (err) {
      //consolelog("Error", err);
      notify("Orders could not be loaded!!", "error");
    }
  };
};

export const createOrder = async (data) => {
  // return async (dispatch) => {
  try {
    const response = await axios.post(`/order/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    //consolelog("Response", response);
    if (response.status === 201) {
      // dispatch(orderCreate(response.data));
      // notify("Your order is ready for payment", "success");
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err.response);
    if (err?.response?.data?.status === "error") {
      notify(err?.response?.data?.msg, "error");
    } else {
      notify("Problem while checking out.", "error");
    }
    return false;
  }
  // };
};

export const upgradeZeroDollarOrder = async (data) => {
  try {
    const response = await axios.post(`/order/upgrade_zero_dollar_order/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response
    }
  } catch (err) {
    // console.log("Error", err.response);
    return false;
  }
}

export const soldCount = async (data) => {
  try {
    const response = await axios.post(`/order/sold_count/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    //consolelog("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err.response);
    return 0;
  }
};

export const latestSell = async (data) => {
  try {
    const response = await axios.post(`/order/last_bought/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    //consolelog("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err.response);
    return 0;
  }
};

export const totalSoldCount = async (data) => {
  try {
    const response = await axios.post(`/order/total_sold_count/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    //consolelog("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err.response);
    return 0;
  }
};

////////////////////////// PRODUCTS - Actions //////////////////////////

export const downloadProductFile = async (version, productId, productName) => {
  // return async (dispatch) => {
  try {
    const response = await axios.get(
      `/product/${productId}/downloadFiles/?version=${version}`,
      {
        responseType: "blob",
      }
    );
    //consolelog("FILE DOWNLOAD RESPONSE:", response);
    fileDownload(response.data, productName + "_product_files.zip");
    return true;
  } catch (err) {
    //consolelog(err);
  }
  // };
};

export const downloadInvoice = async (orderId) => {
  try {
    const response = await axios.get(`/order/` + orderId + `/invoice/`, {
      responseType: "blob",
    });
    //consolelog("FILE DOWNLOAD RESPONSE:", response);
    fileDownload(response.data, "invoice.pdf");
  } catch (err) {
    //consolelog(err);
  }
};

export const upgradeOrder = async (params) => {
  try {
    const response = await axios.post(`/order/upgrade_order/`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      // notify("Your order is completed.", "success");
      return response.data;
    }
  } catch (err) {
    notify("Some problem while fetching details", "error");
    //consolelog(err);
  }
};

export const upgradePaypalOrder = async (params) => {
  try {
    const response = await axios.post(`/order/upgrade_order/`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      // notify("Your order is completed.", "success");
      return response.data;
    }
  } catch (err) {
    notify("Some problem while fetching details", "error");
    //consolelog(err);
  }
};

export const upgradeView = async (id) => {
  try {
    const response = await axios.post(`/product/${id}/update_view/`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    notify("Some problem", "error");
  }
};

////////////////////////// SELLER - Actions //////////////////////////

export const getMySellerProfile = (userID) => {
  return async (dispatch) => {
    if (userID) {
      try {
        const response = await axios.get(
          `/user/seller/` + userID + `/my_seller_profile/`
        );
        //consolelog("Response", response);
        if (response.status === 200) {
          dispatch(sellerLoad(response.data));
        }
      } catch (err) {
        // notify("Seller could not be loaded!!", "error");
        //consolelog(err);
      }
    } else {
      //consolelog("Error occurred");
    }
  };
};

export const createSeller = async (data) => {
  // return async (dispatch) => {
  try {
    const response = await axios.post(`/user/seller/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    //consolelog("Response", response);
    // dispatch(sellerAdd(response.data));
    if (response.status === 201) {
      // notify("Seller created!", "success");
      return response.data;
    }
  } catch (err) {
    // notify("Seller could not be created!", "error");
    //consolelog(err);
  }
  // };
};

export const updateSeller = async (data, sellerID) => {
  // return async (dispatch) => {
  try {
    const response = await axios.put(`/user/seller/` + sellerID + `/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    //consolelog("Response", response);
    // dispatch(sellerUpdate(response.data));
    if (response.status === 200) {
      notify("Seller updated!", "success");
      return response.data;
    }
  } catch (err) {
    notify("Seller could not be updated!", "error");
    //consolelog(err);
  }
  // };
};

export const updateSellerPicture = async (data, sellerID) => {
  try {
    const response = await axios.put(`/user/seller/` + sellerID + `/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    //consolelog("Response", response);
    if (response.status === 200) {
      notify("Profile Picture updated successfully!", "success");
      return response.data;
    }
  } catch (err) {
    //consolelog(err);
  }
};

////////////////////////// BANK ACCOUNT - Actions //////////////////////////

export const loadBankAccount = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/user/bankaccount/`);
      //consolelog("Response", response);
      dispatch(bankAccountLoad(response.data));
    } catch (err) {
      notify("Bank Account could not be loaded!!", "error");
      //consolelog(err);
    }
  };
};

export const createBankAccount = async (data) => {
  // return async (dispatch) => {
  try {
    const response = await axios.post(`/user/bankaccount/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    //consolelog("Response", response);
    // dispatch(bankAccountAdd(response.data));
    if (response.status === 201) {
      notify("Bank Account created!", "success");
      return response.data;
    }
  } catch (err) {
    notify("Bank Account could not be created!", "error");
    //consolelog(err);
  }
  // };
};

export const updateBankAccount = async (data, sellerID) => {
  // return async (dispatch) => {
  try {
    const response = await axios.put(
      `/user/bankaccount/` + sellerID + `/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    //consolelog("Response", response);
    // dispatch(bankAccountUpdate(response.data));
    if (response.status === 200) {
      notify("Bank Account updated!", "success");
      return response.data;
    }
  } catch (err) {
    notify("Bank Account could not be updated!", "error");
    //consolelog(err);
  }
  // };
};

////////////////////////// CURRENCY - Actions //////////////////////////

export const loadCurrencyRate = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/currencyexchanger/?currency=` + data);
      //consolelog("Response", response);
      dispatch(currencyRateLoad(response.data));
    } catch (err) {
      notify("Currency Rate could not be updated!!", "error");
      //consolelog(err);
    }
  };
};

////////////////////////// PAYOUTS - Actions //////////////////////////

export const createPayouts = async (data) => {
  try {
    const response = await axios.post(`/user/payouts/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    //consolelog("Response", response);
    if (response.status === 201) {
      notify("Payouts imported successfully!!", "success");
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err);
    notify("Payouts could not be imported!!", "error");
  }
};

export const totalEarnings = async (sellerID) => {
  try {
    const response = await axios.get(
      `/user/payouts/` + sellerID + `/total_earnings/`
    );
    //consolelog("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err.response);
    return 0;
  }
};

export const monthlySales = async (sellerID) => {
  try {
    const response = await axios.get(
      `/user/payouts/` + sellerID + `/monthly_sales/`
    );
    //consolelog("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err.response);
    return 0;
  }
};

export const downloadPayoutInvoice = async (payoutID) => {
  try {
    const response = await axios.get(
      `/user/payouts/` + payoutID + `/invoice/`,
      {
        responseType: "blob",
      }
    );
    //consolelog("FILE DOWNLOAD RESPONSE:", response);
    fileDownload(response.data, "invoice.pdf");
  } catch (err) {
    //consolelog(err);
  }
};

////////////////////  Notifications  /////////////////////////
export const fetchNotifications = async (email) => {
  try {
    const response = await axios.get(
      `/notification/?user__email=${email}`
    );
    //consolelog("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err.response);
    notify("Notifications cannot be loaded!!", "error");
  }
}

export const archiveNotifications = async (email) => {
  try {
    const response = await axios.post(
      `/notification/archive_notifications/`, { email: email }
    );
    //consolelog("Response", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    //consolelog("Error", err.response);
    notify("Notifications cannot be loaded!!", "error");
  }
}
//////////////////////////////////////////////////////////////


export const searchWord = async (data) => {
  try {
    
    const response = await axios.post(`/user/search_word/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 201) {
      return response.data;
    }
  } catch (err) {
    return false;
  }
};
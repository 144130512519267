import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ResetPasswordBox } from "components";
import * as actions from "actions/AuthActions";
import { toast } from "react-toastify";
import { Breakpoint, useCurrentBreakpointName } from "react-socks";

const ResetPassword = () => {
  const { email_hash } = useParams();
  const [loading, setLoading] = useState(true);
  const [linkExpired, setLinkExpired] = useState(true);
  const [doesNotExist, setDoesNotExist] = useState(true);
  const [verified, setVerified] = useState(true);
  const [msg, setMsg] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);

  useEffect(async () => {
    const response = await actions.verifyAccount({ token: email_hash });
    if (response) {
      switch (response.status) {
        case "LINK_EXPIRED":
          setMsg(response.msg);
          setLinkExpired(true);
          break;
        case "DOES_NOT_EXIST":
          setMsg(response.msg);
          setDoesNotExist(true);
          break;
        case "VERIFIED":
          setMsg("Reset your password here!");
          setVerified(true);
          break;
        default:
          setLoading(false);
      }
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Breakpoint large up>
        <Container
          className="d-flex flex-column justify-content-center align-items-center py-lg-5 "
          style={styles.mainContainer}
        >
          {!forgotPassword && <div className="text-white pb-5">{msg}</div>}

          <ResetPasswordBox
            token={email_hash}
            forgotPassword={forgotPassword}
            setForgotPassword={setForgotPassword}
          />
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          className="d-flex flex-column justify-content-center align-items-center mx-0 px-0 h-100 "
          style={styles.mainContainer}
        >
          {!forgotPassword && <div className="text-white pb-5">{msg}</div>}

          <ResetPasswordBox
            token={email_hash}
            forgotPassword={forgotPassword}
            setForgotPassword={setForgotPassword}
          />
        </Container>
      </Breakpoint>
    </>
  );
};

const styles = {
  mainContainer: {
    minHeight: "100vh",
    // paddingTop: 100
  },
};

export default ResetPassword;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VRTrainingPrivacyPolicy = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/vrsolutions/privacy_policy')
  }, [])

  return (
    <></>
  )
};

export default VRTrainingPrivacyPolicy;

import { useEffect, useState, forwardRef } from "react";
import {
  dummy_user_notification,
  notification_icons,
  styles,
} from "./notification_commons";
import NoNotifications from "./NoNotifications";
import * as authActions from "actions/AuthActions";
import "./notifications.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

const DesktopNotification = forwardRef(
  (
    {
      notificationsForUser,
      fetchUserNotifications,
      clearNotifications,
      onClick,
      onBlur,
      onFocus,
      onMouseEnter,
    },
    ref
  ) => {
    // const [notificationsForUser, setNotificationsForUser] = useState([]);
    // const user = useSelector(state => state.authReducer.user);

    // const fetchUserNotifications = async () => {
    //     // setNotificationsForUser(dummy_user_notification);
    //     const response = await authActions.fetchNotifications(user.email);.focus
    //     if (response) {
    //         setNotificationsForUser(response);
    //     }
    // }

    // const clearNotifications = async () => {
    //     const response = await authActions.archiveNotifications(user.email);
    //     if(response){
    //         setNotificationsForUser([]);
    //     }
    // }

    return (
      <div
        ref={ref}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onBlur={onBlur}
        onFocus={onFocus}
        id="notifications-desktop"
        style={styles.desktopContainer}
        tabIndex={0}
      >
        <div
          id="notification_header"
          className="d-flex flex-row justify-content-between"
          style={styles.header}
        >
          <h6 className="py-2 text-white m-0">Notifications</h6>
          <p
            className="py-2 m-0"
            style={
              notificationsForUser.length > 0
                ? styles.clear
                : styles.clearDisabled
            }
            onClick={clearNotifications}
          >
            clear all
          </p>
        </div>
        <hr className="mt-0" style={styles.hr} />
        <div>
          {notificationsForUser?.length > 0 ? (
            notificationsForUser?.map((notification, index) => (
              <>
                <a className="text-decoration-none" href={notification.link}>
                  <Row
                    className="d-flex flex-row justify-content-start align-items-start overflow-hidden px-3 py-1"
                    style={styles.notification_container}
                  >
                    <Col
                      sm={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img
                        src={`${process.env.REACT_APP_CLOUDFRONT_URL}${notification.image_link}`}
                        alt={notification.type}
                        style={styles.icon}
                      />
                    </Col>
                    <Col sm={10}>
                      <p
                        style={styles.notification_title}
                        dangerouslySetInnerHTML={{
                          __html: notification.description,
                        }}
                      ></p>
                    </Col>
                  </Row>
                </a>
                <>
                  {index < notificationsForUser.length - 1 && (
                    <hr className="my-1" style={styles.hr} />
                  )}
                </>
              </>
            ))
          ) : (
            <NoNotifications />
          )}
        </div>
      </div>
    );
  }
);

export default DesktopNotification;

import React, { useState, useRef, useEffect } from "react";
import { Col, Form, Spinner, Button as RButton, Card } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
// import { Button, Card } from "ui-neumorphism";
import "core-ui/pages/MyAccount.scss";
import { ZDropdown } from "components";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import Flags from "country-flag-icons/react/3x2";
import * as userActions from "actions/UserActions";
import { Breakpoint } from "react-socks";


const BasicInformation = (props) => {
  // const { user, form, handleProfileSubmit, next, region, setRegion } = props;
  const { form, handleProfileSubmit, next, region, setRegion } = props;

  const user = useSelector((state) => state.authReducer.user);


  const [countries, setCountries] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const uniqueCountries = [];

  const loadTheCountries = async () => {
    const response = await userActions.loadCountries();
    setCountries(response);
  };

  useEffect(() => {
    loadTheCountries();
  }, [])
  // useEffect(() => {
  //   console.log("##",region)
  // }, [region])
  // useEffect(() => {
  //   console.log("##",setRegion)
  // }, [setRegion])


  const FlagIcon = ({ country }) => {
    const Flag = Flags[country?.code];

    return (
      <Flag
        title={country?.name}
        className="text-white"
        style={{ height: 30 }}
      />
    );
  };

  useEffect(() => {
    if (countries.length > 0) {
      let result = []
      for (let i = 0; i < countries.length; i++) {
        let data = {
          value: countries[i]?.code,
          label: (
            <div className="d-flex subtitle" key={countries[i]?.id}>

              <FlagIcon country={countries[i]} />
              &nbsp;&nbsp;{countries[i]?.name}
            </div>
          )
        }
        result.push(data);
      }
      result.sort((a, b) => a.label.props.children[2].localeCompare(b.label.props.children[2]));
      setCountryList(result);
    }
  }, [countries])

  // const regionOptions = [
  //   {
  //     value: "AU",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.AU
  //           title="Australia"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Australia
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "BR",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.BR
  //           title="Brazil"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Brazil
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "CA",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.CA
  //           title="Canada"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Canada
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "CN",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.CN
  //           title="China"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;China
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "EG",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.EG
  //           title="Egypt"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;nbsp;&Egypt
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "FR",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.FR
  //           title="France"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;France
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "DE",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.DE
  //           title="Germany"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Germany
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "IN",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.IN
  //           title="India"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;India
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "IT",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.IT
  //           title="Italy"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Italy
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "JP",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.JP
  //           title="Japan"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Japan
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "MX",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.MX
  //           title="Mexico"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Mexico
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "NL",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.NL
  //           title="Netherlands"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Netherlands
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "PL",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.PL
  //           title="Poland"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Poland
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "SA",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.SA
  //           title="Saudi Arabia"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Saudi Arabia
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "SG",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.SG
  //           title="Singapore"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Singapore
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "ES",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.ES
  //           title="Spain"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Spain
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "SE",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.SE
  //           title="Sweden"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Sweden
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "TR",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.TR
  //           title="Turkey"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Turkey
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "AE",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.AE
  //           title="United Arab Emirates"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;United Arab Emirates
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "GB",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.GB
  //           title="United Kingdom"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;United Kingdom
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "US",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.US
  //           title="United States"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;United States
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "PK",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.PK
  //           title="Pakistan"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Pakistan
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "BD",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.BD
  //           title="Bangladesh"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Bangladesh
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "VE",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.VE
  //           title="Venezuela"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Venezuela
  //       </div>
  //     ),
  //   },
  //   {
  //     value: "RO",
  //     label: (
  //       <div className="d-flex subtitle">
  //         <Flags.RO
  //           title="Romania"
  //           className="text-white"
  //           style={{ height: 30 }}
  //         />
  //         &nbsp;&nbsp;Romania
  //       </div>
  //     ),
  //   },
  // ];

  // console.log(region);

  return (
    <>
      {/* <div className="d-flex justify-content-center mt-5 text-shadow">
        <h3 className="text-white p-lg-2 mt-lg-0 mt-5">My Profile</h3>
      </div> */}
      <Breakpoint large up>

        <Card style={{ borderRadius: 40, border: "1px solid #3d3d3d", maxWidth: 600, backgroundColor: "#141415" }}>
          <div className="h4 mt-4 text-center text-white">Personal Details</div>
          <hr className="text-white" />
          <Form
            ref={form}
            onSubmit={(e) => handleProfileSubmit(e)}
            className="px-5 py-4 my-3 body-text2 text-white"
          >
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                className="text-white"
                placeholder="Enter your Name"
                style={styles.inputField}
                defaultValue={user?.name}
                required
              />
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="text"
                className="text-white"
                placeholder="example@gmail.com"
                style={styles.inputField}
                defaultValue={user?.email}
                required
                disabled
              />
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Label>Phone no</Form.Label>
            </Form.Group>
            <Form.Group className="d-flex justify-content-start align-items-center mb-4">
              <RButton
                type="button"
                className="wrapped-button d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#0d0d0d",
                  color: "#888A8C",
                  border: "1px solid #3d3d3d",
                  fontSize: 16,
                  height: 50,
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderRight: "0px",
                }}
                onKeyDown={(e) => e.preventDefault()}
              >
                {/* {user.phone_code} */}
                {countries.filter((country) => country.code === region)[0]?.phone_code}
              </RButton>
              <Form.Control
                name="mobile"
                type="text"
                // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                pattern="[0-9]{+}"
                className="text-white"
                placeholder="Enter your Mobile"
                style={{
                  background: "#0d0d0d",
                  border: "1px solid #3d3d3d",
                  height: 50,
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
                defaultValue={user.mobile}
                required
                onInvalid={(e) => {
                  return e.target.setCustomValidity(
                    "Please enter valid number."
                  );
                }}
                onChange={(e) => {
                  return e.target.setCustomValidity("");
                }}
              />
            </Form.Group>
            <div className="mt-4">
              <Form.Label>Region</Form.Label>
              {/* {regionOptions.map((region) => {
                if (region.value === user.region.code) {
                  return region.label;
                }
              })} */}
              <ZDropdown
                options={countryList}
                mode="region"
                region={region}
                setRegion={setRegion}
              />
              {/* {console.log(getUnicodeFlagIcon('US'))} */}
            </div>
            {/* <div className="text-center mt-4">
              <RButton
                className="btn btn-link border-0 wrapped-button"
                style={{ background: "transparent", textDecoration: "none" }}
                type="submit"
              >
                <RButton
                  className="my-3 btn btn-primary  explore-button-no-neo"
                  variant="dark"
                  disabled={next}
                  size="large"
                  type="submit"
                >
                  {next ? (
                    <span>
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      UPDATING...
                    </span>
                  ) : (
                    <span className="poppins">UPDATE</span>
                  )}
                </RButton>
              </RButton>
            </div> */}
          </Form>
        </Card>
      </Breakpoint>
      <Breakpoint medium down className="w-100">

        <Card className="my-3 w-100" style={{ border: "1px solid #3d3d3d", backgroundColor: "#141415" }}>
          <div className=" h3 poppins-500 mt-4 mb-4 text-center text-white">
            Personal Details
          </div>
          <div style={styles.underline}></div>
          <div className="">
            <Form
              ref={form}
              onSubmit={(e) => handleProfileSubmit(e)}
              className="  body-text2 text-white"
            >
              <Form.Group className="mt-4 mx-3">
                <Form.Label className="font-size18">Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  className="text-white "
                  placeholder="Enter your Name"
                  style={styles.inputField}
                  defaultValue={user?.name}
                  required
                />
              </Form.Group>
              <Form.Group className="mt-4 mx-3">
                <Form.Label className="font-size18">Email</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  className="text-white"
                  placeholder="example@gmail.com"
                  style={styles.inputField}
                  defaultValue={user?.email}
                  required
                  disabled
                />
              </Form.Group>
              <Form.Group className="mt-4 mx-3">
                <Form.Label className="font-size18">Phone no</Form.Label>
              </Form.Group>
              <Form.Group className="d-flex justify-content-start align-items-center mb-4 mx-3">
                <RButton
                  type="button"
                  className="wrapped-button d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#0d0d0d",
                    color: "#888A8C",
                    border: "1px solid #3d3d3d",
                    fontSize: 16,
                    height: 50,
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderRight: "0px",
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                >
                  {/* {user.phone_code} */}
                  {countries.filter((country) => country.code === region)[0]?.phone_code}
                </RButton>
                <Form.Control
                  name="mobile"
                  type="text"
                  // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  pattern="[0-9]{+}"
                  className="text-white"
                  placeholder="Enter your Mobile"
                  style={{
                    background: "#0d0d0d",
                    border: "1px solid #3d3d3d",
                    height: 50,
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  }}
                  defaultValue={user.mobile}
                  required
                  onInvalid={(e) => {
                    return e.target.setCustomValidity(
                      "Please enter valid number."
                    );
                  }}
                  onChange={(e) => {
                    return e.target.setCustomValidity("");
                  }}
                />
              </Form.Group>
              <div className="mt-4 mx-3 mb-4">
                <Form.Label className="font-size18">Region</Form.Label>
                {/* {regionOptions.map((region) => {
            if (region.value === user.region.code) {
              return region.label;
            }
          })} */}
                <ZDropdown
                  options={countryList}
                  mode="region"
                  region={region}
                  setRegion={setRegion}
                />
                {/* {console.log(getUnicodeFlagIcon('US'))} */}
              </div>
              {/* <div className="text-center mt-4">
          <RButton
            className="btn btn-link border-0 wrapped-button"
            style={{ background: "transparent", textDecoration: "none" }}
            type="submit"
          >
            <RButton
              className="my-3 btn btn-primary  explore-button-no-neo"
              variant="dark"
              disabled={next}
              size="large"
              type="submit"
            >
              {next ? (
                <span>
                  <Spinner
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  UPDATING...
                </span>
              ) : (
                <span className="poppins">UPDATE</span>
              )}
            </RButton>
          </RButton>
        </div> */}
            </Form>
          </div>
        </Card>
      </Breakpoint>

    </>
  );
};
const styles = {
  inputField: {
    background: "#0d0d0d",
    border: "1px solid #3d3d3d",
    height: 50,
  },
  cartCard: {
    height: 500,
  },
  underline: {
    height: 2,
    backgroundColor: "#292929",
  },
};
export default BasicInformation;

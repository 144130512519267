import React, { useState, useEffect } from "react";
import Select from "react-select";
import { AiOutlineCaretDown } from "react-icons/ai";

const ZDropdown = ({
  options,
  mode,
  couponCategoryRef,
  couponOption,
  formReference,
  setAddCoupons,
  clearCoupon,
  handleUpdateCartproduct,
  thisProductId,
  setAddTags,
  is_disabled,
  setSelectedFilter,
  region,
  setRegion,
}) => {


  const CustomDropdownIndicator = (props) => {
    return (
      <div
        {...props.innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingRight: "8px", // Adjust the spacing as needed
        }}
      >
        <AiOutlineCaretDown />
      </div>
    );
  };
  const IndicatorSeparator = () => {
    return (
      <div
        style={{
          height: "1px",
          width: "1px",
          backgroundColor: "black",
          padding: 0,
        }}
      />
    );
  };

  const customStyles2 = {
    menu: () => ({
      marginTop: -4.5,
      padding: 0,
      position: "absolute",
      width: "100%",
      zIndex: 999,
      fontSize: "20px",
      borderRadius: "50px !important",
      overflow: "visible",
    }),
    menuList: (provided, state) => ({
      ...provided,
      borderRadius: "15px",
      textAlign: "center",

    }),

    input: (provided) => ({
      ...provided,
      color: 'white',
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#0D0D0D",
      color: state.isSelected ? "#777" : "white",
      marginTop: 0,
      cursor: "pointer",
      textAlign: "left",
      paddingLeft: "10px",
      fontSize: "16px",
      padding: "8px",
    }),

    control: (provided, state) => ({
      ...provided,
      color: "#fff",
      height: mode === "region" ? "60px" : "",
      backgroundColor: "#0D0D0D",
      display: "flex",
      fontSize: 16,
      fontFamily: "poppins",
      // padding: ,
      borderRadius: "10px",
      border: "1px solid #3D3D3D",
      boxShadow: "none",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "#fff";
      const transition = "opacity 300ms";
      const padding = "8px 8px 8px 0px";

      return { ...provided, opacity, transition, color, padding };
    },

  };

  const customStyles = {
    // menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: () => ({
      marginTop: 10,
      padding: 0,
      position: "absolute",
      width: "100%",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#0D0D0D",
      color: state.isSelected ? "#777" : "white",
      padding: 10,
      marginTop: 0,
      cursor: "pointer",
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      // width: 200,
      height: mode === "license" ? "64px" : "",
      color: "#fff",
      backgroundColor: mode === "filters" ? "141414" : "#0D0D0D",
      display: "flex",
      // fontSize: 16,
      fontSize: mode === "license" ? 24 : 16,
      padding: 5,
      // border: "1px solid #fff",
      borderRadius: 5,
      border: mode === "filters" ? "" : "1px solid #3D3D3D",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "#fff";
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color };
    },
  };

  const customStyles3 = {
    // menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: () => ({
      marginTop: -4,
      padding: 0,
      position: "absolute",
      width: "100%",
      zIndex: 999,
    }),

    menuList: (provided, state) => ({
      ...provided,
      borderRadius: "15px",


    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#0D0D0D",
      color: state.isSelected ? "#777" : "white",
      padding: 10,
      paddingLeft: 16,
      marginTop: 0,
      cursor: "pointer",
      textAlign: "left",
    }),
    control: () => ({
      height: mode === "license" ? "64px" : "",
      color: "#fff",
      backgroundColor: mode === "filters" ? "141414" : "#0D0D0D",
      display: "flex",
      borderRadius: "10px",
      fontSize: mode === "license" ? 24 : 16,
      padding: 6,
      fontFamily: "poppins",
      border: "1px solid #3D3D3D",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "#fff";
      const transition = "opacity 300ms";
      const padding = "8px 8px 8px 0px";

      return { ...provided, opacity, transition, color, padding };
    }
  };


  const cartCustomStyles = {
    // menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: () => ({
      marginTop: 10,
      padding: 0,
      position: "absolute",
      width: "100%",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#141414",
      color: state.isSelected ? "#777" : "white",
      padding: 10,
      marginTop: 0,
      cursor: "pointer",
      border: "1px solid #3D3D3D",
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      // width: 200,
      color: "#fff",
      backgroundColor: mode = "filters" ? "#0D0D0D" : "#141414",
      display: "flex",
      fontSize: 16,
      padding: 5,
      // border: "1px solid #fff",
      borderRadius: 5,
      border: "2px solid #3D3D3D",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "#fff";
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color };
    },
  };

  const customStyles1 = {
    // menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: () => ({
      marginTop: 10,
      padding: 0,
      position: "absolute",
      width: "100%",
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#0D0D0D",
      color: state.isSelected ? "#777" : "white",
      padding: 5,
      marginTop: 0,
      cursor: "pointer",
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      // width: 200,
      height: mode === "license" ? "64px" : "",
      color: "#fff",
      backgroundColor: mode === "filters" ? "141414" : "#0D0D0D",
      display: "flex",
      // fontSize: 16,
      fontSize: mode === "license" ? 24 : 16,
      padding: 5,
      // border: "1px solid #fff",
      borderRadius: 5,
      border: "1px solid #3D3D3D",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "#fff";
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color };
    },
  };

  // useEffect(() => {
  //   if (formReference) {
  //     // formReference?.current?.select?.inputRef?.required = true;
  //   }
  // }, [formReference])

  const handleChange = (options) => {
    // console.log(options)
    setAddCoupons(options);
  };

  const handleTagsChange = (options) => {
    setAddTags(options);
  };

  if (mode === "singleselect") {
    return <Select required={true} options={options} styles={customStyles} />;
  } else if (mode === "multiselect") {
    return (
      <Select
        isMulti
        simpleValue
        placeholder="Enter Tag(s)"
        options={options}
        styles={customStyles}
      />
    );
  } else if (mode === "couponselect") {
    const defaultValue = options.filter((option) =>
      couponOption === option.value ? option : ""
    );
    return (
      <Select
        required={true}
        options={options}
        styles={customStyles}
        ref={couponCategoryRef}
        defaultValue={defaultValue.length > 0 ? defaultValue[0] : ""}
      />
    );
  } else if (mode === "multicouponselect") {
    return (
      <Select
        isMulti
        simpleValue
        placeholder="Search Coupon Here"
        options={options}
        styles={customStyles}
        onChange={handleChange}
        ref={clearCoupon}
      />
    );
  } else if (mode === "multitagselect") {
    return (
      <Select
        isMulti
        simpleValue
        placeholder="Search Tag Here"
        options={options}
        styles={customStyles}
        onChange={handleTagsChange}
        ref={formReference}
      />
    );
  } else if (mode === "productselect") {
    return (
      <Select
        required={true}
        options={options}
        styles={customStyles2}
        components={{
          IndicatorSeparator,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        isSearchable={false}

        ref={formReference}
      />
    );
  } else if (mode === "license") {
    return (
      <Select
        required
        options={options}
        styles={customStyles3}
        components={{
          IndicatorSeparator,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        isSearchable={false}
        ref={formReference}
      />
    );
  } else if (mode === "cartcouponselect") {
    return (
      <Select
        isDisabled={is_disabled}
        required={true}
        options={options}
        styles={cartCustomStyles}
        // isSearchable={false}
        defaultValue={options.map((option) =>
          couponOption === option.value ? option : null
        )}
        onChange={(selectedCoupon) =>
          handleUpdateCartproduct(selectedCoupon, thisProductId)
        }
      />
    );
  }
  else if (mode === "region") {
    return (
      <Select
        options={options}
        styles={customStyles2}
        value={options.map((option) =>
          region === option.value ? option : null
        )}
        components={{
          IndicatorSeparator,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        onChange={(e) => {
          setRegion(e.value);
          // console.log(options)
        }}
        menuShouldScrollIntoView={false}
        menuPosition="absolute"
        menuPlacement="bottom"
        filterOption={({ label }, inputValue) => {
          // console.log("Label:", label.props.children[2]);
          return String(label.props.children[2]).toLowerCase().includes(inputValue.toLowerCase());
        }}

      />
    );
  }
  else if (mode === "filters") {
    return (
      <Select
        options={options}
        styles={customStyles}
        onChange={(e) => setSelectedFilter(e)}
      />
    );
  }
};

export default ZDropdown;

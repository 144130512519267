import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Breadcrumb,
  Form,
  Tooltip,
} from "react-bootstrap";
import {
  Card,
  CardContent,
  CardAction,
  Button,
  IconButton,
} from "ui-neumorphism";
import { ZSlider, ZCard, Searchbar } from "components";
import {
  useNavigate,
  useParams,
  Outlet,
  useLocation,
  Link,
} from "react-router-dom";
import * as actions from "actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { Breakpoint, useCurrentBreakpointName } from "react-socks";
import Select from "react-select";
import { components } from "react-select";
import { RangeSlider } from "rsuite";
import "core-ui/pages/Categories.scss";
import "core-ui/pages/Home.scss";
import "rsuite/dist/rsuite.min.css";
import { Helmet } from "react-helmet";
import {
  assured_product,
  dollar,
  models3d_custom,
  models3d_custom_mobile,
  refund_policy_peace,
  viewer3d,
  znanyeloader,
} from "assets";
import Error from "pages/Error/Error";
import { Button as BootstrapButton } from "react-bootstrap";
import { AiOutlineCaretDown } from "react-icons/ai";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";

const CustomDropdownIndicator = (props) => {
  return (
    <div
      {...props.innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: "8px",
      }}
    >
      <AiOutlineCaretDown />
    </div>
  );
};
const customStyles = {
  menu: () => ({
    marginTop: -4.5,
    padding: 0,
    position: "absolute",
    width: "100%",
    zIndex: 999,
    fontSize: "20px",
    borderRadius: "50px !important",
    overflow: "visible",
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderRadius: "15px",
    textAlign: "center",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#0D0D0D",
    color: state.isSelected ? "#777" : "white",
    marginTop: 0,
    cursor: "pointer",
    textAlign: "left",
    paddingLeft: "18px",
    fontSize: "16px",
    padding: "8px",
  }),

  control: (provided, state) => ({
    ...provided,
    color: "#fff",
    backgroundColor: "#0D0D0D",
    display: "flex",
    fontSize: 16,
    fontFamily: "poppins",
    padding: 6,
    borderRadius: "10px",
    border: "1px solid #3D3D3D",
    boxShadow: "none",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const color = "#fff";
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color };
  },
};

const customStylesMobile = {
  // menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: () => ({
    marginTop: 10,
    padding: 0,
    position: "absolute",
    width: "100%",
    zIndex: 999,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#141414",
    color: state.isSelected ? "#777" : "white",
    padding: 10,
    marginTop: 0,
    cursor: "pointer",
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    color: "#fff",
    backgroundColor: "#141414",
    display: "flex",
    fontSize: 16,
    padding: 5,
    // border: "1px solid #fff",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const color = "#fff";
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color };
  },
};

const formatOptions = [
  {
    label: "File Format",
    value: "",
  },
  {
    label: "FBX",
    value: "FBX",
  },
  {
    label: "GLTF",
    value: "GLTF GLB",
  },
  {
    label: "GLB",
    value: "GLB GLTF",
  },
  {
    label: "OBJ",
    value: "OBJ",
  },
  {
    label: "RAR",
    value: "RAR",
  },
];

const polyCountOptions = [
  {
    label: "Poly Count",
    value: "",
  },
  {
    label: "Up to 5k",
    value: 0,
    min: 0,
    max: 5000,
  },
  {
    label: "5k to 10k",
    value: 1,
    min: 5000,
    max: 10000,
  },
  {
    label: "10k to 50k",
    value: 2,
    min: 10000,
    max: 50000,
  },
  {
    label: "50k to 100k",
    value: 3,
    min: 50000,
    max: 100000,
  },
  {
    label: "100k to 250k",
    value: 4,
    min: 100000,
    max: 250000,
  },
  {
    label: "250k+",
    value: 5,
    min: 250000,
  },
];

const searchOptions = [
  { label: "Best Match", value: 0 },
  // { label: "Top Selling", value: 1 },
  { label: "Newest", value: 2 },
  { label: "Oldest", value: 3 },
  { label: "Lower Price", value: 4 },
  { label: "Higher Price", value: 5 },
];

const railStyle = {
  position: "absolute",
  width: "100%",
  height: 10,
  borderRadius: 5,
  backgroundColor: "#ddd",
};

const handleStyle = {
  position: "absolute",
  marginLeft: -10,
  marginTop: -5,
  width: 20,
  height: 20,
  borderRadius: "50%",
  backgroundColor: "white",
  border: "2px solid #3498ff",
  zIndex: 1,
  cursor: "pointer",
};

const trackStyle = {
  position: "absolute",
  height: 10,
  borderRadius: 5,
  backgroundColor: "#3498ff",
};

const Categories = () => {
  const breakpoint = useCurrentBreakpointName();

  let { categorySlug, productId } = useParams();
  const location = useLocation();
  let previous_page = null;
  if (location.state) {
    previous_page = location.state["previousPage"];
  }

  let params = new URLSearchParams(document.location.search);
  let categoryTitle = params.get("category")
    ? params.get("category")
    : categorySlug;
  let ordering = params.get("ordering");
  let polygons__gt = params.get("polygons__gt");
  let polygons__lt = params.get("polygons__lt");
  let price__gt = params.get("price__gt") ? params.get("price__gt") : -1;
  let price__lt = params.get("price__lt") ? params.get("price__lt") : 1000;
  let format__icontains = params.get("format__icontains");
  // format__icontains = format__icontains ? format__icontains : null;

  const [categoryProducts, setCategoryProducts] = useState([]);
  const [range, setRange] = useState([0, 1000]);
  const [categoryData, setCategoryData] = useState(null);
  const [errorPage, setErrorPage] = useState(false);
  const [changingBorder, setChangingBroder] = useState(false);
  const polyCountOptionRef = useRef();
  const formatOptionRef = useRef();
  const searchOptionRef = useRef();
  const rangeSliderRef = useRef();
  const [touchStartPosition, setTouchStartPosition] = useState(0);
  const [touchStartValue, setTouchStartValue] = useState(range);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const categories = useSelector((state) => state.userReducer.categories);

  const IndicatorSeparator = () => {
    return (
      <div
        style={{
          height: "1px",
          width: "1px",
          backgroundColor: "black",
          padding: 0,
        }}
      />
    );
  };

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const getProducts = async () => {
    setRange([price__gt, price__lt]);
    //console.log({ price__gt, price__lt });
    const response = await actions.searchCategoryProduct(
      categoryTitle,
      ordering,
      polygons__gt,
      polygons__lt,
      price__gt === 0 ? -1 : price__gt,
      price__lt,
      format__icontains
    );
    // //console.log(response);
    // //console.log(searchOptionRef.current.selectOption(4))
    // searchOptionRef.current.state.value = { label: "Lower Price", value: 4 };
    // if (response?.length > 0) {
    setCategoryProducts(response);
    setLoading(false);
    // }
    // setCategoryProducts(response);
  };

  useEffect(() => {
    getProducts();
  }, [
    categoryTitle,
    ordering,
    polygons__gt,
    polygons__lt,
    price__gt,
    price__lt,
    format__icontains,
  ]);

  useEffect(() => {
    if (categoryTitle && categories.length > 0) {
      const cat = categories.find(
        (category) => category.slug === categoryTitle
      );
      if (cat) {
        setCategoryData(cat);
      } else {
        setErrorPage(true);
      }
    }
  }, [categoryTitle, categories]);

  useEffect(() => {
    // console.log("location.pathname", location.pathname);
    // console.log("previous_page", previous_page);
    const handleBackButton = () => {
      if (previous_page) {
        if (location.pathname === previous_page) {
          handleNavigationClick(`/3dmodels`);
        } else {
          handleNavigationClick(previous_page);
        }
      }
    };
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  if (errorPage) {
    return <Error />;
  }

  if (productId) {
    return <Outlet />;
  }

  //console.log("Category Products:", categoryProducts);
  //console.log("Category Data:", categoryData);

  const onChangeFilters = (value, type) => {
    // debugger;
    // alert(value[0]);
    const formatOption = formatOptionRef.current?.state.focusedOption;
    const polyCountOption = polyCountOptionRef.current?.state.focusedOption;
    const searchOption = searchOptionRef.current?.state.focusedOption;
    // console.log("formatOption", formatOptionRef.current?.state);
    // console.log("polyCountOption", polyCountOptionRef.current?.state);

    let order = "";
    if (searchOption?.value === 4) {
      order += "price";
    } else if (searchOption?.value === 5) {
      order += "-price";
      // searchOption.current.setValue({ label: "Higher Price", value: 4 });
    }

    if (searchOption?.value === 3) {
      order += "created_at";
      //console.log(searchOptionRef);
      // searchOption.current.setValue({ label: "Lower Price", value: 4 });
    } else if (searchOption?.value === 2) {
      order += "-created_at";
      // searchOption.current.setValue({ label: "Higher Price", value: 4 });
    }

    let paramString = "";
    if (formatOption) {
      paramString += `&format__icontains=${formatOption?.value}`;
    }
    paramString += `&ordering=${order}`;
    if (polyCountOption?.min) {
      paramString += `&polygons__gt=${polyCountOption?.min}`;
    }
    if (polyCountOption?.max) {
      paramString += `&polygons__lt=${polyCountOption?.max}`;
    }
    if (type === "slider") {
      if (value[0]) {
        paramString += `&price__gt=${value[0]}`;
      } else {
        paramString += `&price__gt=${-1}`;
      }
      if (value[1] && value[1] !== 30) {
        paramString += `&price__lt=${value[1]}`;
      }
    } else {
      if (range[0]) {
        paramString += `&price__gt=${range[0]}`;
      } else {
        paramString += `&price__gt=${-1}`;
      }
      if (range[1] && range[1] !== 30) {
        paramString += `&price__lt=${range[1]}`;
      }
    }

    navigate(
      `/3dmodels/${categorySlug}?category=${categorySlug}${paramString}`
    );
  };

  return (
    <React.Fragment>
      {/* <Helmet>
        <title>{`${categoryData?.meta_title ? categoryData.meta_title : "Znanye | 3D Models | " + categoryTitle}`}</title>
        <meta name="description" content={categoryData?.meta_description ? categoryData.meta_description : categoryData?.details} />
        <link rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
      </Helmet> */}
      <Container
        className="d-flex flex-column align-items-center h-100 p-0 text-center mt-3 pt-5 home-background-categories"
        fluid
        style={styles.parentContainer}
      >
        <Breakpoint large up style={{ width: "100%" }}>
          <Container style={{ height: "100%" }}>
            <hr style={{ color: "#ddd" }} />
            <div className="d-flex align-items-center justify-content-between text-white">
              <div className="d-flex align-items-center">
                <div className="me-3" style={{ width: 200 }}>
                  <div className="d-flex justify-content-between">
                    <Form.Label>$0</Form.Label>
                    <Form.Label>$1000+</Form.Label>
                  </div>
                  <RangeSlider
                    ref={rangeSliderRef}
                    min={0}
                    max={30}
                    defaultValue={[price__gt, price__lt]}
                    value={range}
                    onChange={(value) => setRange(value)}
                    onChangeCommitted={(value, event) => {
                      onChangeFilters(value, "slider");
                      // console.log("filter",value)
                    }}
                    // onChangeCommitted={(value) => {
                    //   //console.log(value);
                    //   alert();
                    //   onChangeFilters(value, "slider")
                    // }}
                  />
                </div>

                <div className="mx-3" style={{ width: 200 }}>
                  <Select
                    ref={formatOptionRef}
                    required={true}
                    components={{
                      IndicatorSeparator,
                      DropdownIndicator: CustomDropdownIndicator,
                    }}
                    options={formatOptions}
                    styles={customStyles}
                    isSearchable={false}
                    defaultValue={
                      formatOptions.length > 0 ? formatOptions[0] : ""
                    }
                    onChange={(value) => onChangeFilters(value, "format")}
                  />
                </div>
                <div className="mx-3" style={{ width: 200 }}>
                  <Select
                    ref={polyCountOptionRef}
                    required={true}
                    components={{
                      IndicatorSeparator,
                      DropdownIndicator: CustomDropdownIndicator,
                    }}
                    isSearchable={false}
                    options={polyCountOptions}
                    styles={customStyles}
                    defaultValue={
                      polyCountOptions.length > 0 ? polyCountOptions[0] : ""
                    }
                    onChange={(value) => onChangeFilters(value, "polyCount")}
                  />
                </div>
                {/* <div className="mx-3" style={{ minWidth: 200, maxWidth: 400 }}>
                <Select
                  isMulti
                  required={true}
                  options={polyCountOptions}
                  styles={customStyles}
                  defaultValue={polyCountOptions.length > 0 ? polyCountOptions[0] : ""}
                />
              </div> */}
              </div>
              <div className="d-flex align-items-center">
                <div className="mx-3" style={{ minWidth: 200, maxWidth: 400 }}>
                  <Select
                    ref={searchOptionRef}
                    required={true}
                    options={searchOptions}
                    isSearchable={false}
                    components={{
                      IndicatorSeparator,
                      DropdownIndicator: CustomDropdownIndicator,
                    }}
                    styles={customStyles}
                    defaultValue={
                      searchOptions.length > 0 ? searchOptions[0] : ""
                    }
                    onChange={(value) => onChangeFilters(value, "searchOption")}
                  />
                </div>
              </div>
            </div>
            <hr style={{ color: "#ddd" }} />
          </Container>
        </Breakpoint>
        <Breakpoint medium down className="custom-width-class">
          <Container style={{ height: "100%", width: "100%" }}>
            <div className="my-4 d-flex flex-column align-items- justify-content-between w-100 text-white">
              <div className="d-flex align-items-center">
                <div className="ms-2 me-3" style={{ width: 150 }}>
                  <div className="d-flex justify-content-between">
                    <Form.Label>$0</Form.Label>
                    <Form.Label>$1000+</Form.Label>
                  </div>
                  <div style={{ width: "160px", margin: "auto" }}>
                    {/* separate slider for mobile  view */}
                    <Slider
                      rootStyle={{ position: "relative", height: 10 }}
                      // domain={[0, 1000]}
                      step={1}
                      min={0}
                      max={30}
                      defaultValue={[price__gt, price__lt]}
                      values={range}
                      // onChange={(value) => {setRange(value);console.log(value)}}
                      onChange={(values) => {
                        {
                          onChangeFilters(values, "slider");
                          console.log("filter", values);
                        }
                      }}
                    >
                      <Rail>
                        {({ getRailProps }) => (
                          <div style={railStyle} {...getRailProps()} />
                        )}
                      </Rail>
                      <Handles>
                        {({ handles, getHandleProps }) => (
                          <div>
                            {handles.map((handle) => (
                              <div
                                key={handle.id}
                                style={{
                                  ...handleStyle,
                                  left: `${handle.percent}%`,
                                }}
                                {...getHandleProps(handle.id)}
                              >
                                {/* <Tooltip
                                  placement="top"
                                  className="in"
                                  id="tooltip-top"
                                >
                                  Tooltip top
                                </Tooltip> */}
                              </div>
                            ))}
                          </div>
                        )}
                      </Handles>
                      <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                          <div>
                            {tracks.map((track) => (
                              <div
                                key={track.id}
                                style={{
                                  ...trackStyle,
                                  left: `${track.source.percent}%`,
                                  width: `${
                                    track.target.percent - track.source.percent
                                  }%`,
                                }}
                                {...getTrackProps()}
                              />
                            ))}
                          </div>
                        )}
                      </Tracks>
                    </Slider>
                  </div>

                  {/* this below is the old  one */}

                  {/* <RangeSlider
                    handleClassName="cursor-pointer"
                    ref={rangeSliderRef}
                    min={0}
                    max={1000}
                    progress={true}
                    defaultValue={[price__gt, price__lt]}
                    value={range}
                    onChange={(value) => setRange(value)}
                    onChangeCommitted={(value, event) => {
                      onChangeFilters(value, "slider");
                    }}
                  /> */}
                </div>
              </div>
            </div>
          </Container>
          <Container className="p-0" style={{ width: "100%" }}>
            <div className="mt-2 mb-3 d-flex flex-row w-100 align-items-center">
              <div
                className="col-4"
                style={{
                  borderTop: "1px solid #3D3D3D",
                  borderBottom: "1px solid #3D3D3D",
                }}
              >
                <Select
                  ref={formatOptionRef}
                  required={true}
                  options={formatOptions}
                  components={{
                    IndicatorSeparator,
                    DropdownIndicator: CustomDropdownIndicator,
                  }}
                  isSearchable={false}
                  styles={customStylesMobile}
                  defaultValue={
                    formatOptions.length > 0 ? formatOptions[0] : ""
                  }
                  onChange={(value) => onChangeFilters(value, "format")}
                />
              </div>
              <div className="col-4" style={{ border: "1px solid #3D3D3D" }}>
                <Select
                  ref={polyCountOptionRef}
                  required={true}
                  components={{
                    IndicatorSeparator,
                    DropdownIndicator: CustomDropdownIndicator,
                  }}
                  isSearchable={false}
                  options={polyCountOptions}
                  styles={customStylesMobile}
                  defaultValue={
                    polyCountOptions.length > 0 ? polyCountOptions[0] : ""
                  }
                  onChange={(value) => onChangeFilters(value, "polyCount")}
                />
              </div>
              <div
                className="col-4"
                style={{
                  borderTop: "1px solid #3D3D3D",
                  borderBottom: "1px solid #3D3D3D",
                }}
              >
                <Select
                  ref={searchOptionRef}
                  required={true}
                  components={{
                    IndicatorSeparator,
                    DropdownIndicator: CustomDropdownIndicator,
                  }}
                  isSearchable={false}
                  options={searchOptions}
                  styles={customStylesMobile}
                  defaultValue={
                    searchOptions.length > 0 ? searchOptions[0] : ""
                  }
                  onChange={(value) => onChangeFilters(value, "searchOption")}
                />
              </div>
            </div>
          </Container>
        </Breakpoint>
        <Container
          className="d-flex flex-column justify-content-center align-items-start"
          style={{ paddingLeft: "16", paddingRight: "16" }}
        >
          {/* <Breakpoint large up className="w-100" style={{ zIndex: 1000 }}>
            <Searchbar type="main" />
          </Breakpoint>
          <Breakpoint medium down style={{ zIndex: 1000 }}>
            <Searchbar />
          </Breakpoint> */}
          <ul className="breadcrumb subtitle mx-0 my-2">
            <li className="d-flex justify-content-center align-items-center">
              <a href="/3dmodels" className="body-text3">
                3D Models
              </a>
            </li>
            <li className="d-flex justify-content-center align-items-center">
              {" "}
              <span className="text-primary body-text3">
                {categoryData?.title.charAt(0).toUpperCase() +
                  categoryData?.title.slice(1)}{" "}
                3D Model{" "}
              </span>
            </li>
          </ul>
        </Container>
        <Container
          className="mb-3"
          style={{ paddingLeft: "5", paddingRight: "5" }}
        >
          <div className="w-100 my-lg-4">
            <div className="w-100 my-4 text-start" style={{ weight: 500 }}>
              <div
                className="poppins-500 text-white search-label mb-5 user-select-none h3 p-0"
                style={{ size: 32 }}
              >
                Explore Our{" "}
                <span className="my-1 mx-0 text-primary">
                  {`${
                    categoryData?.title.charAt(0).toUpperCase() +
                    categoryData?.title.slice(1)
                  } `}
                  3D&nbsp;Models
                </span>
              </div>
              <div className="text-white mb-5" style={{ maxWidth: 900 }}>
                {/* Buy Professional 3D Models. Our designer community has crafted professional 3D models
                for you to use in your projects, games, production, and others. Buy Professional 3D
                Models are industry-accepted formats like OBJ, FBX, GLTF, 3DS, and others. There are
                also free downloads that are available for you to use. Try out the models on our 3D
                viewer to ensure you choose the right product. We also offer a no questions asked refund. */}
                <p
                  dangerouslySetInnerHTML={{ __html: categoryData?.details }}
                />
              </div>
            </div>
          </div>
          <div className="w-100">
            {categoryProducts?.length !== 0 ? (
              <Row
                className={`${
                  breakpoint === "xsmall" ? "" : "row-cols-5 px-2"
                }`}
              >
                {!loading &&
                  categoryProducts?.map((item, index) => {
                    return (
                      <ZCard
                        key={item.id}
                        index={index}
                        item={item}
                        type="product"
                      />
                    );
                  })}
              </Row>
            ) : (
              <Row className="text-white d-flex justify-content-center">
                {/* {loading && <video muted autoPlay loop width={60} height={60}>
                  <source src={znanyeloader} type="video/webm" />
                  Your browser does not support the video tag.
                </video>} */}
                {loading && (
                  <Image
                    src={znanyeloader}
                    loading="lazy"
                    alt="Znanye Loader"
                    style={{ width: 80 }}
                  />
                )}
                {!loading && <h4>No Products Available yet!</h4>}
              </Row>
            )}
          </div>
          <Breakpoint large up>
            <div className="w-100 my-lg-5 position-relative">
              <div
                className="w-100 my-5 text-center bg-black p-5 d-flex flex-column align-items-center position-relative custom-category-background"
                style={{ borderRadius: 40, border: "1px solid #3D3D3D" }}
              >
                {/* <Image className="position-absolute w-100" src={models3d_custom} style={{ top: 0, bottom: 0, zIndex: 0 }} /> */}
                <h2 className="poppins-400 text-white search-label mb-5 user-select-none h3 p-0">
                  Couldn't Find{" "}
                  <span className="text-primary">What You Want ?</span>
                </h2>
                <div className="text-white mb-5 px-5 mx-5">
                  We believe that one size does not fit all. In this
                  fast-changing environment, what makes you stand out is your
                  key differentiator. We help you stand out with customized
                  offerings. Get in touch with us and we'll help you explore the
                  infinite possibilities out there.
                </div>
                <a
                  className="text-decoration-none"
                  href="/contact-us/#Customized-3D-model"
                  onClick={() =>
                    handleNavigationClick("/contact-us/#Customized-3D-model")
                  }
                >
                  <BootstrapButton
                    className="mt-5 explore-button-no-neo"
                    dark={true}
                    size="lg"
                    style={{ border: changingBorder ? "0px" : "0px" }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}
                    // style={{ zIndex: 99 }}
                  >
                    <span className="poppins">GET A CUSTOM 3D MODEL</span>
                  </BootstrapButton>
                </a>
              </div>
            </div>
          </Breakpoint>
          <Breakpoint medium>
            <div className="w-100 my-lg-5 position-relative">
              <div
                className="w-100 my-5 text-center bg-black p-5 d-flex flex-column align-items-center position-relative"
                style={{ borderRadius: 40 }}
              >
                <Image
                  className="position-absolute w-100"
                  src={models3d_custom}
                  alt="Custom Model Background"
                  style={{ top: 0, bottom: 0 }}
                />
                <h2 className="poppins-400 text-white search-label mb-5 user-select-none h2 p-0">
                  Couldn't Find{" "}
                  <span className="text-primary">What You Want ?</span>
                </h2>
                <div className="text-white mb-5 px-5 mx-5">
                  We believe that one size does not fit all. In this
                  fast-changing environment, what makes you stand out is your
                  key differentiator. We help you stand out with customized
                  offerings. Get in touch with us and we'll help you explore the
                  infinite possibilities out there.
                </div>
                <a
                  className="text-decoration-none"
                  href="/contact-us/#Customized-3D-model"
                  onClick={() =>
                    handleNavigationClick("/contact-us/#Customized-3D-model")
                  }
                >
                  <BootstrapButton
                    className="mt-5 explore-button-no-neo"
                    dark={true}
                    size="lg"
                    style={{ border: changingBorder ? "0px" : "0px" }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}
                  >
                    <span className="poppins">GET A CUSTOM 3D MODEL</span>
                  </BootstrapButton>
                </a>
              </div>
            </div>
          </Breakpoint>
          <Breakpoint small down>
            <div
              className="w-100 my-5 py-5 position-relative custom-category-background"
              style={{ paddingLeft: "16", paddingRight: "16" }}
            >
              <div className="poppins-500 text-white search-label mb-5 user-select-none font-size32 p-0">
                Couldn't Find{" "}
                <span className="text-primary">What You Want ?</span>
              </div>
              <div className="text-white mb-5" style={{ fontSize: 18 }}>
                We believe that one size does not fit all. In this fast-changing
                environment, what makes, you stand out is your key
                differentiator. We help you stand out with customized offerings.
                Get in touch with us and we'll help you explore the infinite
                possibilities out there.
              </div>
              <Link
                className="text-decoration-none"
                to="/contact-us/#Customized-3D-model"
              >
                <BootstrapButton
                  className="mt-5 explore-button-no-neo"
                  dark={true}
                  size="lg"
                  style={{ border: changingBorder ? "0px" : "0px" }}
                  onMouseEnter={() => setChangingBroder(true)}
                  onMouseLeave={() => setChangingBroder(false)}
                >
                  <span className="poppins">GET A CUSTOM 3D MODEL</span>
                </BootstrapButton>
              </Link>
            </div>
          </Breakpoint>

          <Breakpoint large up>
            <div className="w-100 my-lg-5">
              <Row
                className="w-100 my-5 text-start p-5 row"
                style={{ color: "#808080", width: 220, marginLeft: -10 }}
              >
                <Col lg={3}>
                  <div className="px-5">
                    <Image src={dollar} alt="Dollar" className="my-5" />
                    <p className="poppins-500 h5">
                      You save more because you pay less
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="px-5">
                    <Image
                      src={viewer3d}
                      alt="View in 3D Viewer"
                      className="my-5"
                    />
                    <p className="poppins-500 h5">
                      See All Products in powerful 3D Viewer
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="px-5">
                    <Image
                      src={assured_product}
                      alt="Assured Product"
                      className="my-5"
                    />
                    <p className="poppins-500 h5">
                      You have lower stress as we host only Znanye Assured 3D
                      models
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="px-5">
                    <Image
                      src={refund_policy_peace}
                      alt="Refund Policy"
                      className="my-5"
                    />
                    <p className="poppins-500 h5">
                      You have peace of mind as we have no questions asked
                      refund policy
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Breakpoint>

          <Breakpoint small down>
            <div className="w-100 my-lg-5">
              <Row
                className="my-5 text-start"
                style={{ color: "#808080", width: 220, paddingLeft: 16 }}
              >
                <Col lg={3}>
                  <div>
                    <Image src={dollar} alt="Dollar" className="my-5" />
                    <p className="poppins-500">
                      You save more because you pay less
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div>
                    <Image
                      src={viewer3d}
                      alt="View in 3D Viewer"
                      className="my-5"
                    />
                    <p className="poppins-500">
                      See All Products in powerful 3D Viewer
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div>
                    <Image
                      src={assured_product}
                      alt="Assured Product"
                      className="my-5"
                    />
                    <p className="poppins-500">
                      You have lower stress as we host only Znanye Assured 3D
                      models
                    </p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div>
                    <Image
                      src={refund_policy_peace}
                      alt="Refund Policy"
                      className="my-5"
                    />
                    <p className="poppins-500">
                      You have peace of mind as we have no questions asked
                      refund policy
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Breakpoint>
        </Container>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
};

export default Categories;

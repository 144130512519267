import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image, Form, Button as RButton } from "react-bootstrap";
import { Button, Card, CardAction } from "ui-neumorphism";
import { ZCard, Loader } from "components";
import { useSelector, useDispatch } from "react-redux";

import "core-ui/pages/MyAccount.scss";

import { emptycart, swiftdezire, mitsubishi } from "assets";
import { Link } from "react-router-dom";
import * as actions from "actions/AdminActions";
import { Searchbar, ProductCard, ZDropdown, SearchBarMyProduct } from "components";

const Products = () => {
    const [filteredAllProducts, setFilteredAllProducts] = useState(null);
    const [filteredMyProducts, setFilteredMyProducts] = useState([]);
    // const [allProducts, setAllProducts] = useState([]);
    const [isMyProductsEnabled, setIsMyProductsEnabled] = useState(false);
    const [myProducts, setMyProducts] = useState([]);
    const [products, setProducts] = useState(null)
    const [loader, setLoader] = useState(true)
    const [reloadProducts, setReloadProducts] = useState(false);
    const dispatch = useDispatch();
    const adminProducts = useSelector((state) => state.adminReducer.products);

    const loadAllProducts = async () => {
        const response = await actions.loadAdminProducts();
        if (response) {
            setProducts(response)
            dispatch(actions.productLoad(response))
            setLoader(false)
        }
    }

    useEffect(() => {
        loadAllProducts()
    }, []);

    useEffect(() => {
        if (reloadProducts) {
            setFilteredAllProducts(adminProducts);
        }
    }, [reloadProducts, adminProducts]);

    // const products = useSelector((state) => state.adminReducer.products);
    const user = useSelector((state) => state.authReducer.user);
    //consolelog("Uploaded Product:", products, user);

    // uncomment to test the empty purchase list page
    // const filteredAllProducts = [];

    const filters = [
        { value: "most_views", label: "MOST VIEWED" },
        // { value: "most_downloads", label: "MOST DOWNLOADS" },
        { value: "most_likes", label: "MOST LIKED" },
        { value: "latest", label: "LATEST" },
        { value: "oldest", label: "OLDEST" },
    ];

    function compareOnViews(a, b) {
        if (a.view_count > b.view_count) {
            return -1;
        }
        if (a.view_count < b.view_count) {
            return 1;
        }
        return 0;
    }

    function compareOnFavourites(a, b) {
        if (a.favourite_count > b.favourite_count) {
            return -1;
        }
        if (a.favourite_count < b.favourite_count) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        if (products) {
            setFilteredAllProducts(products);
            const my_products = products.filter((product) => product.created_by === user?.id);
            setMyProducts(my_products);
            setFilteredMyProducts(my_products);
        }
    }, [products, user]);

    //consolelog(filteredAllProducts)

    if (loader) {
        return <Loader />;
    }

    if (filteredAllProducts && filteredAllProducts.length === 0) {
        return (
            <React.Fragment>
                <Container
                    className="d-flex w-100 justify-content-center align-items-center h-100 mt-5 pt-5 ps-5 home-background-categories"
                    fluid
                    style={styles.emptyPurchaseListContainer}
                >
                    <div>
                        <div className="d-flex justify-content-center">
                            <Image src={emptycart} alt="Empty Cart" style={{ width: 200 }} />
                        </div>
                        <div className="d-flex justify-content-center mt-5 mb-5">
                            <h4 className="text-primary m-1">OOPS ! </h4>
                            <h4 className="text-white m-1">
                                You have not uploaded any product yet
                            </h4>
                        </div>
                        <div className="d-flex justify-content-center mt-5 mb-5">
                            <Link to="/sell-3d/uploadproducts" style={{ textDecoration: "none" }}>
                                <RButton
                                    dark={true}
                                    className="h-100 explore-button-no-neo btn btn-ptimary"
                                    size="lg"
                                >
                                    UPLOAD PRODUCTS
                                </RButton>
                            </Link>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <Container
                className="w-100 px-5 home-background-categories"
                fluid
                // style={{ paddingTop: 100 }}
                style={styles.mainContainer}
            >
                <div className="d-flex justify-content-start mt-5 mx-2">
                    <RButton
                        className={`${!isMyProductsEnabled ? "explore-button-no-neo btn btn-ptimary" : "explore-border-button explore-button-large-no-neo"} p-4 me-5`}
                        dark={true}
                        size="lg"
                        style={{}}
                        variant="dark"
                        onClick={() => {
                            setIsMyProductsEnabled(false);
                        }}
                    >
                        <span className="poppins">ALL PRODUCTS</span>
                    </RButton>
                    <RButton
                        className={`${isMyProductsEnabled ? "explore-button-no-neo btn btn-ptimary" : "explore-border-button explore-button-large-no-neo"} p-4`}
                        dark={true}
                        variant="dark"
                        onClick={() => {
                            setIsMyProductsEnabled(true);
                        }}
                    >
                        <span className="poppins">MY PRODUCTS</span>
                    </RButton>
                </div>
                <Container className={`${isMyProductsEnabled ? "d-none" : "mt-5"}`} fluid>
                    <SearchBarMyProduct type="main" uploadedProducts={products} updateProducts={(filteredProducts) => {
                        setFilteredAllProducts(filteredProducts);
                    }} />
                    <Row className="d-flex justify-content-start">
                        {filteredAllProducts?.map((item, index) => {
                            return (
                                <ZCard
                                    key={item.id}
                                    index={index}
                                    item={item}
                                    type="admin_product"
                                    reloadProducts={reloadProducts}
                                    setReloadProducts={setReloadProducts}
                                />
                            );
                        })}
                    </Row>
                </Container>
                <Container className={`${isMyProductsEnabled ? "mt-5" : "d-none"}`} fluid>
                    <SearchBarMyProduct type="main" uploadedProducts={myProducts} updateProducts={(filteredProducts) => {
                        setFilteredMyProducts(filteredProducts);
                    }} />
                    <Row>
                        {filteredMyProducts?.map((item, index) => {
                            return (
                                <ZCard
                                    key={item.id}
                                    index={index}
                                    item={item}
                                    type="admin_product"
                                    reloadProducts={reloadProducts}
                                    setReloadProducts={setReloadProducts}
                                />
                            );
                        })}
                    </Row>
                </Container>
            </Container>
        </React.Fragment>
    );
};

const styles = {
    mainContainer: {
        minHeight: "100vh",
        paddingTop: 100
    },
    emptyPurchaseListContainer: {
        minHeight: "80vh",
    },
    progressBarContainer: {
        minHeight: 100,
    },
    cartCard: {
        height: 500,
    },
    cardAction: {
        backgroundColor: "#2E2E2E",
    },
    underline: {
        height: 1,
        backgroundColor: "white",
    },
    youSavedColor: {
        color: "#58D31B",
    },
};
export default Products;

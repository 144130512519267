import React, { useState, useEffect, useRef } from "react";
import { Loader } from "components";

import "core-ui/pages/Product.scss";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "actions/UserActions";
import Error from "pages/Error/Error";


const RedirectUrl = () => {
  let { productId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [thisCategory, setThisCategory] = useState(null);

  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";
  const param3 = params.length > 2 ? params[2] : "";
  const param4 = params.length > 3 ? params[3] : "";

  //consolelog(param1, param2, param3, productId)

  let extendedParams = new URLSearchParams(document.location.search);
  let searchCategoryTitle = extendedParams.get("category");

  useEffect(() => {
    dispatch(actions.loadProducts());
    dispatch(actions.loadCategories());
  }, []);

  const products = useSelector((state) => state.userReducer.products);
  const categories = useSelector((state) => state.userReducer.categories);

  useEffect(() => {
    if (products) {
      setAllProducts(products);
      setLoading(true);
    }
  }, [products])

  useEffect(() => {
    if (categories && searchCategoryTitle) {
      const x = categories.find((cat) => cat.title === searchCategoryTitle);
      setThisCategory(x);
      setLoading(true);
    }
  }, [categories, searchCategoryTitle])

  //consolelog("thisCategory", thisCategory)

  useEffect(() => {
    setLoading(true);
    if (allProducts.length > 0 && productId && param1 === "product") {
      const pro = products.find((product) => product.slug === productId)
      //consolelog("Fetched PRoduct: ", pro)
      if (pro) {
        if (param3 === "viewer") {
          // navigate(`/3dmodels/${pro?.category_data?.slug}/${pro?.slug}/viewer`)
          window.location.href = `/3dmodels/${pro?.category_data?.slug}/${pro?.slug}/viewer`
        } else {
          // navigate(`/3dmodels/${pro?.category_data?.slug}/${pro?.slug}`)
          window.location.href = `/3dmodels/${pro?.category_data?.slug}/${pro?.slug}`
        }
      } else {
        //consolelog("Sorry Product not found!")
        setLoading(false)
        setShowError(true)
      }
    }
  }, [allProducts, productId])

  useEffect(() => {
    setLoading(true);
    if (param1 === "categories") {
      if (param1 === "categories" && param2 === "") {
        // navigate(`/3dmodels/`)
        window.location.href = `/3dmodels/`
      } else if (thisCategory) {
        if (param1 === "categories" && param2 === "product") {
          // navigate(`/3dmodels/${thisCategory?.slug}`)
          window.location.href = `/3dmodels/${thisCategory?.slug}`
        } else {
          setLoading(false)
          setShowError(true)
        }
      }
    }
  }, [param1, param2, thisCategory])


  if (loading) {
    return <Loader />;
  } else if (showError) {
    return <Error />
  }

  return <Loader />

};

export default RedirectUrl;

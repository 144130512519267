import React, { useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";

import {
  Card,
  CardContent,
  CardAction,
  Button,
  IconButton,
} from "ui-neumorphism";

import { ZSlider, ZCard } from "components";
import { Searchbar } from "components";
import { CategoryProducts } from "pages/categories";

import * as actions from "./../../actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useCurrentBreakpointName } from "react-socks";
import { Breakpoint } from "react-socks";
import { Helmet } from "react-helmet";
import {
  assured_product,
  dollar,
  refund_policy_peace,
  sitemap,
  viewer3d,
} from "assets";

const Categories = () => {
  let { categorySlug } = useParams();

  const breakpoint = useCurrentBreakpointName();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.loadCategories());
  }, []);
  const categories = useSelector((state) => state.userReducer.categories);
  const visible_categories = categories.filter(
    (category) => category.is_visible === true
  );

  // console.log("Categories", categories);
  // console.log("Visible Categories", visible_categories);

  if (categorySlug) {
    return <Outlet />;
  }

  return (
    <React.Fragment>
      {/* <Helmet>
                <title>{`Znanye | SiteMap`}</title>
                <meta name="description" content="Contact Us" />
                <a rel="canonical" href={`https://www.znanye.com${location.pathname}`} />
                <a rel="alternate" hreflang="x-default" href={`https://www.znanye.com${location.pathname}`} />
            </Helmet> */}
      <Container
        className="d-flex flex-column align-items-center h-100 text-center mt-5 pt-5 home-background-categories"
        fluid
        style={styles.parentContainer}
      >
        <Container className="text-white" style={{ zIndex: 99 }}>
          <h4 className="text-start">Sitemap</h4>
          <Row>
            <Col lg={2} xs={6} className="d-flex flex-column text-start my-5">
              <p>Buyer</p>
              <div className="my-3">
                <a className="sitemap-link text-decoration-none" href="/">
                  Shop / Home
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/3dmodels"
                >
                  3D Models
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/business"
                >
                  Business
                </a>
              </div>
              {/* <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/">
                                    Contact Us
                                </a>
                            </div> */}
              <div className="my-3">
                <a className="sitemap-link text-decoration-none" href="/blogs">
                  Blogs
                </a>
              </div>
              <div className="my-3">
                <a className="sitemap-link text-decoration-none" href="/cart">
                  Cart
                </a>
              </div>
              {/* <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/">
                                    Login
                                </a>
                            </div>
                            <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/">
                                    Sign Up
                                </a>
                            </div> */}
            </Col>
            <Col lg={2} xs={6} className="d-flex flex-column text-start my-5">
              <p>Seller</p>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/sell-3d"
                >
                  Sell / Home
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/sell-3d-login"
                >
                  Login
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/sell-3d-login"
                >
                  Sign Up
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/3dmodels/food/burger-3d-model/viewer/"
                >
                  3D Viewer
                </a>
              </div>
            </Col>
            <Col lg={2} xs={6} className="d-flex flex-column text-start my-5">
              <p>3D Models</p>
              {categories.map((category) => {
                return (
                  <div className="my-3">
                    <a
                      className="sitemap-link text-decoration-none"
                      href={`/3dmodels/${category.slug}/`}
                    >
                      {category.title}
                    </a>
                  </div>
                );
              })}
              {/* <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/3dmodels/food/">
                                    Food
                                </a>
                            </div>
                            <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/3dmodels/furniture/">
                                    Furniture
                                </a>
                            </div>
                            <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/3dmodels/game/">
                                    Game
                                </a>
                            </div>
                            <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/3dmodels/plants/">
                                    Plants
                                </a>
                            </div>
                            <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/3dmodels/space/">
                                    Space
                                </a>
                            </div>
                            <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/3dmodels/vehicles/">
                                    Vehicle
                                </a>
                            </div> */}
            </Col>
            <Col lg={2} xs={6} className="d-flex flex-column text-start my-5">
              <p>Company Info</p>
              <div className="my-3">
                {/* <a className="sitemap-link text-decoration-none" href="https://www.theweek.in/news/sci-tech/2022/10/17/online-marketplace-for-vr-assets-and-hardware-launches-in-india.html" target="_blank">
                                    We Are in news!
                                </a> */}
                <a
                  className="sitemap-link text-decoration-none"
                  href="/we-are-in-news/"
                >
                  We Are in news!
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/about-us-policy/"
                >
                  About Us
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/contact-us/"
                >
                  Contact Us
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/refund_policy/"
                >
                  Refund Policy
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/cookies_policy/"
                >
                  Cookies Policy
                </a>
              </div>
            </Col>
            <Col lg={2} xs={6} className="d-flex flex-column text-start my-5">
              <p>Policies</p>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/privacy_policy"
                >
                  Privacy Policy
                </a>
              </div>
              {/* <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/terms_and_conditions">
                                    Terms of Use
                                </a>
                            </div> */}
              {/* <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/privacy_policy_user">
                                    User's Privacy Policy
                                </a>
                            </div> */}
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/terms_of_use_user"
                >
                  User's Terms of Use
                </a>
              </div>
              {/* <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="/privacy_policy_seller">
                                    Seller's Privacy Policy
                                </a>
                            </div> */}
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/terms_of_use_seller"
                >
                  Seller's Terms of Use
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="/license"
                >
                  License
                </a>
              </div>
            </Col>
            <Col lg={2} xs={6} className="d-flex flex-column text-start my-5">
              <p>Social Media</p>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="https://www.facebook.com/znanye/"
                  target="_blank"
                >
                  Facebook
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="https://www.instagram.com/znanye.vr/"
                  target="_blank"
                >
                  Instagram
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="https://www.linkedin.com/company/znanye/"
                  target="_blank"
                >
                  Linked In
                </a>
              </div>
              {/* <div className="my-3">
                                <a className="sitemap-link text-decoration-none" href="https://www.youtube.com/channel/UC6WkOycpHXCiVGO1rIcBe9w" target="_blank">
                                    Youtube
                                </a>
                            </div> */}
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="https://in.pinterest.com/znanye/"
                  target="_blank"
                >
                  Pinterest
                </a>
              </div>
              <div className="my-3">
                <a
                  className="sitemap-link text-decoration-none"
                  href="https://discord.gg/ZfzRt98CPe"
                  target="_blank"
                >
                  Discord
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <Image
          className="position-absolute"
          src={sitemap}
          alt="SiteMap"
          style={{ right: 0, bottom: 0 }}
        />
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
  },
  parentContainer90: {
    minHeight: "90vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "85vh",
    backgroundColor: "#141415",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  checkedIcons: {
    width: 20,
    height: 20,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
};

export default Categories;

import { no_notifications_bell } from "assets";
import { styles } from "./notification_commons";


const NoNotifications = () => {

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={no_notifications_bell} alt="no notifications bell" />
            <p style={styles.notification_title}>You don’t have any new notifications !</p>
        </div>
    )
}

export default NoNotifications;
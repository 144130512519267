import { sample_blog } from "assets";
import { FeaturedBlogCard, BlogTopSection, SearchBarBlogs } from "components";
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Image, Card } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as adminActions from "actions/AdminActions";
import { MdModeEdit } from "react-icons/md";
import { avtar } from "assets";
import "core-ui/pages/Blog.scss";
import { Helmet } from "react-helmet";
import { Breakpoint } from "react-socks";

const Blog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [blog, setBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [blogContent, setBlogContent] = useState("");

  useEffect(async () => {
    const response = await adminActions.loadBlog(id);
    const allBlogs = await adminActions.loadBlogs();
    setBlog(response);
    setBlogs(allBlogs);
  }, []);

  useEffect(() => {
    let x = blog?.content;
    let divElement = document.createElement("div");
    divElement.innerHTML = x;

    // looping through ALL DOM elements insidie the divElement
    let elements = divElement.getElementsByTagName("*");
    for (let i = 0; i < elements.length; i++) {
      // remove the style attribute enterily if it contains font-size property
      // console.log("ELEM", elements[i].getAttribute('style'))
      if ((elements[i].getAttribute("style") || "").includes("font-family")) {
        elements[i].removeAttribute("style");
      }
    }
    // console.log("divveleb", divElement.innerHTML)
    setBlogContent(divElement.innerHTML);
  }, [blog]);

  return (
    <React.Fragment>
      <ul className="breadcrumb subtitle mx-lg-5 mt-lg-5 pt-lg-5 px-lg-5 mt-5 pt-4 px-3">
        <li>
          <a className="poppins-400 text-white" href="/blogs">
            Blogs
          </a>
        </li>
        <li>
          <a href={`/blogs?blog_type=${blog?.blog_type_data?.slug}`}>
            {blog?.blog_type_data?.title}
          </a>
        </li>
        <li>{blog?.title}</li>
      </ul>
      <Container className="my-lg-5 py-lg-5 user-select-none d-flex flex-column justify-content-center align-items-center">
        <Breakpoint large up>
          <div className="mb-4 font-size48 d-flex justify-content-center align-items-center text-white">
            {blog?.title}
          </div>
        </Breakpoint>
        <Breakpoint medium down>
          <div className="mb-4 font-size32 d-flex justify-content-center align-items-center text-white">
            {blog?.title}
          </div>
        </Breakpoint>
        <div
          className="d-flex justify-content-center align-items-center overflow-hidden"
          style={{
            maxWidth: 840,
            maxHeight: "auto",
            borderRadius: 30,
            border: "1px solid #3D3D3D",
          }}
        >
          <Image
            className="w-100"
            src={blog?.blog_image ? blog?.blog_image : ""}
            alt={blog?.title}
          />
        </div>
      </Container>
      <Container className="my-5 py-5 user-select-none" fluid>
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "#27292A",
            height: "3px",
          }}
        >
          <Card
            className="border-0 my-5 p-4 d-flex justify-content-center"
            style={{
              backgroundColor: "#27292A",
              borderRadius: "20px",
              maxHeight: "128px",
              maxWidth: "351px",
            }}
          >
            <Row>
              <Col
                xs={4}
                className="d-flex justify-content-start align-items-start"
              >
                <div
                  className="p-0 m-0"
                  style={{ backgroundColor: "#fff", borderRadius: 45 }}
                >
                  <Image src={avtar} alt="Avtar" />
                </div>
              </Col>
              <Col
                xs={8}
                className="text-white d-flex flex-column justify-content-center"
              >
                <div>
                  <p className="subtitle">{blog?.author_name}</p>
                </div>
                <div
                  className="d-flex flex-column justify-content-between"
                  style={{ fontSize: 10 }}
                >
                  <div>
                    {blog?.published_date}&nbsp;&nbsp;&nbsp;&nbsp;
                    {blog?.read_time}
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </Container>
      <Container
        className="py-1 user-select-none poppins"
        style={{ maxWidth: 840 }}
      >
        <div
          className="text-white my-5"
          dangerouslySetInnerHTML={{ __html: blogContent }}
        />
        {/* <div className="text-white my-5" dangerouslySetInnerHTML={{ __html: blog?.content }} /> */}
      </Container>
      <Container className="mb-5 pb-5 user-select-none" fluid>
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "#27292A",
            height: "3px",
          }}
        >
          <Card
            className="border-0 my-5 p-4 px-5 d-flex justify-content-center align-items-center text-white"
            style={{
              backgroundColor: "#27292A",
              borderRadius: "20px",
              maxHeight: "128px",
              maxWidth: "351px",
            }}
          >
            <div>END</div>
          </Card>
        </div>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    height: "100vh",
  },
  boxedParentContainer: {
    height: "100vh",
    borderRight: "1px solid #CCC",
  },
};

export default Blog;

import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Image,
  Form,
  Button as RButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Button, Card, CardContent, IconButton } from "ui-neumorphism";
import { Link } from "react-router-dom";
// import { edit, trash, square } from "assets_old/admin";
import { edit, trash, square } from "assets/admin";

import { imagenotavailable } from "assets";
import { ZDropdown } from "components";
import { AiOutlineClose } from "react-icons/ai";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import Dropzone, { useDropzone } from "react-dropzone";
import * as actions from "actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "ckeditor4-react";
import axios from "axios";

import "./CategoryForm.scss";

const CategoryForm = (props) => {
  const {
    form,
    handleCreate,
    handleUpdate,
    handleCouponUpdate,
    categoryActivity,
    category,
    coupons,
    setShowForm,
    handleDelete,
    categoryImage,
    setCategoryImage,
    setAddCoupons,
    clearCoupon,
    gifRef,
    gif,
    setGif,
    gifUpdateRef,
    gifUpdate,
    setGifUpdate,
    setVisible,
  } = props;

  const [hoverGif, setHoverGif] = useState(false);
  const [details, setDetails] = useState(null);
  const [isVisible, setIsVisible] = useState(
    category?.is_visible ? true : false
  );

  const unusedOptions = coupons?.filter(
    (coupon) => !category?.coupons_data.find((item) => item.id === coupon.id)
  );
  // console.log("Unused Coupon Options:", unusedOptions);

  const [categoryProducts, setCategoryProducts] = useState([]);
  useEffect(async () => {
    const response = await actions.searchCategoryProduct(category?.title);
    setCategoryProducts(response);
  }, []);

  useEffect(() => {
    if (setVisible) {
      setVisible(category?.is_visible ? true : false);
    }
    if (category) {
      setDetails(category.details);
    }
  }, [category, setVisible]);

  // console.log("categoryProducts", categoryProducts);

  const unusedCoupons = [];
  unusedOptions?.map((coupon) => {
    return unusedCoupons.push({ value: coupon.id, label: coupon.code });
  });

  const dispatch = useDispatch();

  const renderTooltip = (props, visible) => (
    <Tooltip id="button-tooltip" {...props}>
      {visible
        ? "Click to hide this category"
        : "Click to make visible this category"}
    </Tooltip>
  );

  const editorConfig = {
    extraPlugins: ["tableresize", "uploadimage", "createpdf", "toc"],
    skin: "moono-dark",
    removePlugins: [
      "exportpdf",
      "forms",
      "smiley",
      "language",
      "iframe",
      "about",
    ],
    // filebrowserBrowseUrl: 'http://localhost:4000/media/24b358cf-8e56-4b03-9843-29fabf589b8d/template/home-advt_jzx7KBU.jpeg',
    filebrowserImageUploadUrl:
      process.env.REACT_APP_BASE_URL + `blog/upload_blog_image/`,
    fileTools_requestHeaders: axios.defaults.headers.common,
    uploadUrl: process.env.REACT_APP_BASE_URL + `blog/upload_blog_image/`,
    contentsCss: [
      // "https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css",
      process.env.REACT_APP_CLOUDFRONT_URL +
        "/static/ckeditor/4.19.0/full-all/document-style.css",
    ],
    bodyClass: "document-editor",
    // height: 900,
    // width: 1000,
    height: "8cm",
    // width: "18.6cm",
    width: "100%",
    allowedContent: true,
    // extraPlugins: [
    //     'timestamp'
    // ]
    // exportPdf_options: {
    //     header_html: '<div class="styled">This is the Header</div>',
    //     footer_html: '<div class="styled-counter"><span class="date></span></div>',
    //     header_and_footer_css: '.styled { font-weight: bold; padding: 10px; display: flex; color: red; } .styled-counter {font-size: 10px; color: red; }',
    //     margin_top: '2cm',
    //     margin_bottom: '10cm',
    // }
    // exportPdf_options: {
    //     format: "A3"
    // }
    exportPdf_options: {
      header_html: '<div class="styled">Header content</div>',
      footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
      header_and_footer_css:
        ".styled { font-weight: bold; padding: 10px; text-align: center; color: red; }",
      margin_left: "1cm",
      margin_right: "2cm",
      format: "A5",
      page_orientation: "landscape",
    },
    protectedSource: [/{%[\s\S]*?%}+/g, /{{[\s\S]*?}}+/g],
    // formatOutput: false,
    // formatSource: false
  };

  return (
    <Form
      ref={form}
      onSubmit={(e) =>
        categoryActivity == "add"
          ? handleCreate(e, details)
          : handleUpdate(e, details)
      }
      className="my-4"
    >
      <Row>
        <Col sm={3} className="d-flex justify-content-end ps-5">
          <div
            className={`${
              categoryActivity == "show" ? "d-none" : "d-block"
            } mx-2`}
          >
            <Dropzone
              onDrop={(acceptedImage) => {
                // console.log("Accepted Files", acceptedImage);
                setCategoryImage(acceptedImage);
              }}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <Card
                  className="mt-4 d-flex justify-content-center align-items-center cursor-pointer"
                  style={styles.productImagesCard}
                  dark={true}
                >
                  <CardContent className="h-100 w-100 d-flex align-items-center justify-content-center">
                    <div
                      {...getRootProps()}
                      className="text-center h-100 w-100 d-flex justify-content-center align-items-center"
                    >
                      <input name="image_file" {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <div>
                          <Image src={square} alt="Add Button" />
                          <div style={{ fontSize: 14 }}>Add Image</div>
                        </div>
                      )}
                    </div>
                  </CardContent>
                </Card>
              )}
            </Dropzone>
          </div>
          <div
            className={`${
              categoryActivity == "show" ? "d-none" : "d-block"
            } mx-3`}
          >
            <Dropzone
              onDrop={(acceptedImage) => {
                // console.log("Accepted Files", acceptedImage);
                setGif(acceptedImage);
              }}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <Card
                  className="mt-4 d-flex justify-content-center align-items-center cursor-pointer"
                  style={styles.productImagesCard}
                  dark={true}
                >
                  <CardContent className="h-100 w-100 d-flex align-items-center justify-content-center">
                    <div
                      {...getRootProps()}
                      className="text-center h-100 w-100 d-flex justify-content-center align-items-center"
                    >
                      <input name="gif_file" {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <div>
                          <Image src={square} alt="Add Button" />
                          <div style={{ fontSize: 14 }}>Add Gif</div>
                        </div>
                      )}
                    </div>
                  </CardContent>
                </Card>
              )}
            </Dropzone>
          </div>
          <div
            className={`${categoryActivity == "show" ? "d-flex" : "d-none"}`}
          >
            <Card
              className="mt-4 d-flex justify-content-center align-items-center cursor-pointer mx-2"
              style={styles.productImagesCard}
              dark={true}
            >
              <Image
                src={
                  category?.image_file ? category.image_file : imagenotavailable
                }
                alt={category?.title}
                style={styles.productImagesCardImage}
              />
            </Card>
            <Card
              className="mt-4 d-flex justify-content-center align-items-center cursor-pointer mx-3"
              style={styles.productImagesCard}
              dark={true}
            >
              <Image
                src={category?.gif_file ? category.gif_file : imagenotavailable}
                alt={category?.title}
                style={styles.productImagesCardImage}
              />
            </Card>
          </div>
        </Col>

        {/* <div
          className={`${categoryActivity == "show" ? "d-none" : "d-block"
            } body-text`}
        >
          <div
            className="position-relative"
            onMouseEnter={() => setHoverGif(true)}
            onMouseLeave={() => setHoverGif(false)}
          >
            <Card
              style={styles.gifCardDiv}
              dark={true}
              className="d-flex"
            >
              <Image
                className={`${categoryActivity == "add" ? "" : "d-none"}`}
                src={gif}
                style={styles.gifDiv}
              />
              <input
                className={`${categoryActivity == "add" ? "" : "d-none"}`}
                ref={gifRef}
                name={`${categoryActivity == "add" ? "gif_file" : ""}`}
                type="file"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={(e) => {
                  console.log(e.target.files[0]);
                  setGif(URL.createObjectURL(e.target.files[0]));
                }}
              />
              <Image
                className={`${categoryActivity == "update" ? "" : "d-none"}`}
                src={gifUpdate}
                style={styles.gifDiv}
              />
              <input
                className={`${categoryActivity == "update" ? "" : "d-none"}`}
                ref={gifUpdateRef}
                name={`${categoryActivity == "update" ? "gif_file" : ""}`}
                type="file"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={(e) => {
                  console.log(e.target.files[0]);
                  setGifUpdate(URL.createObjectURL(e.target.files[0]));
                }}
              />
            </Card>
            {hoverGif && (
              <Button
                className="position-absolute"
                dark={true}
                style={{ marginTop: -65, left: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  if (categoryActivity === "add") {
                    gifRef.current.click();
                  } else if (categoryActivity === "update") {
                    gifUpdateRef.current.click();
                  }
                }}
              >
                SELECT
              </Button>
            )}
          </div>
        </div> */}
        <Col sm={9} className="pt-4 pe-5">
          <Card
            className="d-flex flex-column align-items-center border-R20 me-5"
            dark={true}
          >
            <CardContent className="w-100 ps-5">
              <Row>
                <Col sm={2} className="body-text mt-2">
                  Category
                </Col>
                {/* <Col
                  sm={2}
                  className={`${categoryActivity == "show" ? "d-none" : "d-block ms-5"
                    } body-text mt-2`}
                >
                  .gif File
                </Col> */}
                <Col
                  sm={2}
                  className={`${
                    categoryActivity == "show" ? "d-block" : "d-none"
                  } body-text mt-2`}
                >
                  Total Models
                </Col>
                <Col sm={`${categoryActivity == "show" ? 8 : 10}`}>
                  <div className="d-flex justify-content-end w-100 p-2 px-3">
                    <Link
                      className="mx-2"
                      to={`product/?category=` + category?.title}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        dark={true}
                        className={`${
                          categoryActivity == "show" ? "d-block" : "d-none"
                        } explore-border-button text-white d-flex justify-content-center align-items-center px-5`}
                        size="large"
                      >
                        View All 3D Models
                      </Button>
                    </Link>

                    <div
                      className={`${
                        categoryActivity == "show" ? "d-block" : "d-none"
                      }`}
                    >
                      <IconButton
                        className="body-text1 text-white mx-2"
                        text={false}
                        dark={true}
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowForm(category.id);
                        }}
                      >
                        <Image src={edit} className="m-2" />
                      </IconButton>
                      {/* <IconButton
                        className="body-text1 text-white mx-2"
                        text={false}
                        dark={true}
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete(category.id);
                        }}
                      >
                        <Image src={trash} className="m-1" />
                      </IconButton> */}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => renderTooltip(props, isVisible)}
                      >
                        <IconButton
                          className="body-text1 text-white mx-2"
                          text={false}
                          dark={true}
                          size="large"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsVisible(!isVisible);
                            const formData = new FormData();
                            formData.append("id", category.id);
                            formData.append("is_visible", !isVisible);
                            if (setVisible) {
                              setVisible(!isVisible);
                            }
                            dispatch(actions.partialUpdateCategory(formData));
                          }}
                        >
                          {/* <Image src={isVisible ? trash : ''} className="m-1" /> */}
                          {!isVisible && <FaRegEye size={25} />}
                          {isVisible && <FaRegEyeSlash size={25} />}
                        </IconButton>
                      </OverlayTrigger>
                    </div>
                  </div>
                </Col>
                {/* <Col sm={2}>
                </Col>
                <Col sm={2}>
                </Col> */}
              </Row>
              <Row>
                <Col sm={2} className="body-text">
                  <h4>
                    {categoryActivity == "show" ? (
                      category.title
                    ) : (
                      <Form.Control
                        required
                        name="title"
                        type="text"
                        className="text-white body-text1"
                        placeholder="Enter Category"
                        defaultValue={
                          categoryActivity == "update" ? category.title : ""
                        }
                        style={{
                          background: "#141414",
                          padding: 10,
                          border: 0,
                        }}
                      />
                    )}
                  </h4>
                </Col>
                {/* <Col
                  sm={2}
                  className={`${categoryActivity == "show" ? "d-none" : "d-block"
                    } mx-2 mt-2`}
                >
                  <Button
                    dark={true}
                    type="submit"
                    className="explore-button text-white"
                    size="large"
                  >
                    {categoryActivity == "add" ? "SAVE" : "UPDATE"}
                  </Button>
                </Col> */}
                <Col sm={2} className="body-text">
                  <h4>
                    {categoryActivity == "show" ? categoryProducts?.length : ""}
                  </h4>
                </Col>
              </Row>
              <div className="mt-5">Description</div>
              <Row>
                <Col
                  sm={categoryActivity == "show" ? 11 : 9}
                  className="body-text"
                >
                  <p className="h5 mt-3">
                    {categoryActivity == "show" ? (
                      <p
                        dangerouslySetInnerHTML={{ __html: category.details }}
                      />
                    ) : (
                      // <Form.Control
                      //   as="textarea"
                      //   rows="5"
                      //   required
                      //   name="details"
                      //   type="text"
                      //   className="text-white body-text1"
                      //   placeholder="Enter Description"
                      //   defaultValue={
                      //     categoryActivity == "update" ? category.details : ""
                      //   }
                      //   style={{
                      //     background: "#141414",
                      //     padding: 10,
                      //     border: 0,
                      //   }}
                      // />
                      <CKEditor
                        name="details"
                        initData={category?.details}
                        config={editorConfig}
                        editorUrl={
                          process.env.REACT_APP_CLOUDFRONT_URL +
                          "/static/ckeditor/4.19.0/full-all/ckeditor.js"
                        }
                        onInstanceReady={(e) => {
                          e.editor.document
                            .getBody()
                            .setStyle("color", "white");
                          e.editor.document
                            .getBody()
                            .setStyle("background-color", "#1F1F1F");
                          // in case the user switches to source and back
                          e.editor.on("contentDom", function () {
                            e.editor.document
                              .getBody()
                              .setStyle("background-color", "#1F1F1F");
                          });
                        }}
                        onChange={(event) => {
                          const data = event.editor.getData();
                          setDetails(data);
                        }}
                      />
                    )}
                  </p>
                </Col>
                <Col
                  sm={categoryActivity == "show" ? 0 : 2}
                  className={`${
                    categoryActivity == "show" ? "d-none" : "d-block"
                  } d-flex justify-content-center align-items-center`}
                >
                  <RButton
                    className="btn btn-link border-0 wrapped-button"
                    style={{
                      background: "transparent",
                      textDecoration: "none",
                    }}
                    type="submit"
                  >
                    <Button
                      dark={true}
                      type="submit"
                      className="explore-button text-white"
                      size="large"
                    >
                      {categoryActivity == "add" ? "SAVE" : "UPDATE"}
                    </Button>
                  </RButton>
                </Col>
              </Row>
              <div
                className={`${
                  categoryActivity == "show" ? "d-block" : "d-none"
                }`}
              >
                {/* <div className="d-flex justify-content-start mt-5">
                  <Button
                    className={`${isVisible ? "" : "explore-button explore-button-large"
                      } explore-button-large`}
                    dark={true}
                    size="medium"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsVisible(!isVisible)
                      const formData = new FormData();
                      formData.append("id", category.id);
                      formData.append("is_visible", !isVisible);
                      dispatch(actions.partialUpdateCategory(formData));
                    }}
                  >
                    <span className="poppins">{`${isVisible ? "HIDE CATEGORY" : "MAKE CATEGORY VISIBLE"
                      }`}</span>
                  </Button>
                </div> */}
                <Container className="pb-5 pt-2 text-white px-1" fluid>
                  <Row>
                    <Col sm={6} className="body-text py-2 px-0">
                      <ZDropdown
                        options={unusedCoupons}
                        mode={"multicouponselect"}
                        setAddCoupons={setAddCoupons}
                        clearCoupon={clearCoupon}
                      />
                    </Col>
                    <Col sm={2}>
                      <Button
                        dark={true}
                        className="explore-button m-2 body-text text-white"
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCouponUpdate(
                            category?.id,
                            null,
                            category?.coupons_data,
                            "add"
                          );
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </Container>
                <Row className="ms-1 mb-2">Coupons Applied</Row>
                <div>
                  {category?.coupons_data.map((coupon) => {
                    return (
                      <div className="coupon-delete-button body-text1 text-white ps-2 my-2 me-3">
                        <span>{coupon.code}</span>
                        <IconButton
                          dark={true}
                          onClick={(e) => {
                            e.preventDefault();
                            handleCouponUpdate(
                              category.id,
                              coupon.id,
                              category?.coupons_data,
                              "remove"
                            );
                          }}
                        >
                          <AiOutlineClose className="text-white" />
                        </IconButton>
                      </div>
                    );
                  })}
                </div>
                {/* <Container className="pb-5 pt-2 ps-0 text-white" fluid>
                  <div className="body-text py-2 px-0">
                    <Card className="mt-5 textarea-container" dark={true} flat>
                      <textarea
                        required
                        name="details"
                        className="form-control label textarea-input-box text-white"
                        id="review"
                        placeholder="Enter details about the Category"
                        // defaultValue={
                        //   seller?.business_description
                        //     ? seller.business_description
                        //     : ""
                        // }
                        rows="4"
                        style={{ backgroundColor: "#333333" }}
                      ></textarea>
                    </Card>
                  </div>
                  <Button
                    dark={true}
                    className="explore-button m-2 body-text text-white"
                    size="large"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCouponUpdate(
                        category?.id,
                        null,
                        category?.coupons_data,
                        "add"
                      );
                    }}
                  >
                    Add
                  </Button>
                </Container> */}
              </div>
            </CardContent>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

const styles = {
  productImagesCard: {
    width: 120,
    height: 120,
    borderRadius: 15,
  },
  productImagesCardImage: {
    width: 105,
    height: 60,
  },
  couponCard: {
    width: 110,
    minHeight: 40,
  },
  gifCardDiv: {
    borderRadius: "20px",
    width: 100,
    height: 100,
  },
  gifDiv: {
    borderRadius: "20px",
    maxWidth: 100,
    maxHeight: 100,
  },
};

export default CategoryForm;

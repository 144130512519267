import React, { useRef, useEffect, useState, lazy } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Card as BCard,
  Modal,
} from "react-bootstrap";
import { Searchbar } from "components";
import axios from "axios";
import { Button, Card, IconButton } from "ui-neumorphism";
import { Button as BootstrapButton } from "react-bootstrap";
import {
  lamp,
  cycle,
  houseplant,
  lowproductprices,
  refundwaiting,
  assuredproducts,
  tailoredbike,
  tailoredbikesmall,
  vrheadsetbackground,
  vrheadsetbackgroundsmall,
  vrheadsetbackgroundlighter,
  vrheadsetbackgroundlightersmall,
  oculus3,
  oculus3small,
  getintouchwithznanye,
  offermodal,
  znanyeloader,
} from "assets";

import "core-ui/pages/Home.scss";

import { Link, useLocation } from "react-router-dom";
import { ZSlider, ContactForm } from "components";
import * as actions from "actions/UserActions";
import * as authActions from "actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { useCurrentBreakpointName } from "react-socks";
import { Breakpoint } from "react-socks";
import { IoClose } from "react-icons/io5";
import { Helmet } from "react-helmet";

import moment from "moment";

const viewer_products = [
  { id: 1, image: cycle, file3d: "bicycle", title: "Bicycle 3D Model" },
  { id: 2, image: houseplant, file3d: "plant", title: "House Plant 3D Model" },
  { id: 3, image: lamp, file3d: "lamp", title: "Lamp 3D Model" },
];

const sixReasons = [
  {
    id: 1,
    image: lowproductprices,
    title: "You save more because you pay less",
  },
  {
    id: 2,
    image: assuredproducts,
    title: "You have lower stress as we host only Znanye Assured 3D models",
  },
  {
    id: 3,
    image: refundwaiting,
    title: "You have peace of mind as we have no questions asked refund policy",
  },
];

// const sortByTrendingPriority = (a, b) => {
//   return b.trending_priority - a.trending_priority;
// }

const Home = () => {
  const frontendURL = process.env.REACT_APP_FRONTEND_URL;
  const form = useRef(null);
  const myRefForm = useRef(null);
  const dispatch = useDispatch();
  const breakpoint = useCurrentBreakpointName();
  const [displayItem, setDisplayItem] = useState(0);
  const [fastMoving, setFastMoving] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [showOffer, setShowOffer] = useState(false);
  const user = useSelector((state) => state.authReducer.user);
  // const offer_status_popup_expiry = useSelector(state => state.authReducer.offer_status_popup_expiry);
  const [changingBorder, setChangingBroder] = useState(false);

  const closeOffer = (e) => {
    e.preventDefault();
    setShowOffer(false);
    dispatch(
      authActions.updateOfferStatus(moment(new Date()).add(10, "m").toDate())
    );
  };

  useEffect(() => {
    async function doStuff() {
      dispatch(actions.loadCategories());
      // dispatch(actions.loadProducts());
      dispatch(actions.loadAdvertises());

      let trendingObjects = await actions.loadTrendingProducts();
      if (trendingObjects) {
        // console.log("fast", trendingObjects);
        setTrendingProducts(trendingObjects);
      }

      let fastmovingObjects = await actions.loadFastMoving();
      if (fastmovingObjects) {
        // console.log("fastmovingObjects", fastmovingObjects);
        setFastMoving(
          fastmovingObjects.filter((item) => item.is_verified === true)
        );
      }
      let now = moment(new Date());

      // Uncomment to view Pop up offer
      // if (!offer_status_popup_expiry || moment(offer_status_popup_expiry).diff(now, 'seconds') < 0) {
      //   setShowOffer(true);
      // }
    }
    doStuff();
  }, []);

  const categories = useSelector((state) => state.userReducer.categories);
  const visible_categories = categories.filter(
    (category) => category.is_visible === true
  );
  const products = useSelector((state) => state.userReducer.products);
  const advertises = useSelector((state) => state.userReducer.advertises);
  // //console.log("categories", categories);
  // //console.log("products", products);

  const handleSearch = () => {
    dispatch(actions.searchProduct());
  };

  const location = useLocation();
  const myRefShop = useRef(null);
  const oculusQuestRef = useRef(null);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    if (location.hash === "#shopnow") {
      myRefShop.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (location.hash === "#byvrheadset") {
      oculusQuestRef.current.scrollIntoView();
    }
  }, []);

  return (
    <React.Fragment>
      {/* <Helmet>
        <title>{`Buy & Sell 3D Digital Assets on Znanye | 3D models for VR`}</title>
        <meta
          name="description"
          content="Experience the future of virtual reality with our wide selection of VR assets and 3D models. Buy and sell high-quality VR content on our platform."
        />
        <link
          rel="canonical"
          href={`https://www.znanye.com${location.pathname}`}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={`https://www.znanye.com${location.pathname}`}
        />
      </Helmet> */}
      <Breakpoint large up className="user-select-none">
        {/* <div className="text-center mt-5 pt-4 text-black">
          <Image loading="lazy" className="w-100" src={offerbg} style={{ top: 0, left: 0, zIndex: 1, minHeight: 100 }} />
        </div> */}
        <Container
          ref={myRefShop}
          className="d-flex justify-content-start align-items-center h-100 text-center home-background-first"
          fluid
          style={styles.parentContainer}
        >
          <div
            className="d-flex justify-content-start align-items-center p-0 m-5 pb-5"
            style={{ height: "100vh", backdropFilter: "blur(1.5px)" }}
          >
            <div className="pb-5">
              <h1 className="h3 poppins-400 text-white search-label mb-5 user-select-none text-start">
                Download 3D Assets for your AR,
                <br /> VR and Gaming Applications
              </h1>
              <Searchbar type="main" />
            </div>
          </div>
        </Container>
      </Breakpoint>
      <Modal
        show={showOffer}
        onHide={(e) => closeOffer(e)}
        backdrop="static"
        keyboard={false}
        size="lg"
        contentClassName="bg-transparent border-0"
        dialogClassName="user-select-none"
        centered
      >
        <Modal.Header className="bg-transparent d-flex justify-content-end border-0">
          <IconButton
            className="d-flex justify-content-center align-items-center bg-white text-black"
            size="large"
            dark={true}
            text={false}
            outlined
            rounded
            onClick={(e) => closeOffer(e)}
          >
            <IoClose size={20} />
          </IconButton>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Breakpoint large up>
            <Image src={offermodal} alt="Offer" loading="lazy" />
          </Breakpoint>
          <Breakpoint medium down>
            <Image
              src={offermodal}
              alt="Offer"
              style={{ width: "300px" }}
              loading="lazy"
            />
          </Breakpoint>
        </Modal.Body>
      </Modal>
      <Breakpoint medium down className="user-select-none">
        {/* <div className="text-center mt-5 pt-4 text-black">
          <Image loading="lazy" className="w-100" src={offerbgmobile} style={{ top: 0, left: 0, zIndex: 1, minHeight: 100 }} />
        </div> */}
        <Container
          ref={myRefShop}
          className="d-flex flex-column justify-content-center align-items-center h-100 text-center home-background-first"
          fluid
          style={styles.mobileParentContainer}
        >
          <Container className="d-flex flex-column justify-content-center align-items-center">
            <div
              className=" text-white my-1 text-start"
              style={{ fontFamily: "Poppins", weight: 500, fontSize: 32 }}
            >
              {/* <h1 className=" text-white my-1 text-start"> */}
              Download 3D Assets for your AR,VR and <br /> Gaming Applications
              {/* </h1> */}
              <Searchbar type="mobilehome" />
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint large up>
        <Container
          className="d-flex flex-column justify-content-center align-items-center h-100 text-center home-background-categories"
          fluid
          style={styles.parentContainer}
        >
          <Container
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <div className="w-100 my-5">
              <h2 className="poppins-400 text-white search-label mb-5 user-select-none h3 p-0">
                Explore Our <span className="text-primary">3D Models</span>
              </h2>
              <div className="text-white mb-5 px-5">
                Buy Professional 3D Models. Our designer community has crafted
                professional 3D models for you to use in your projects, games,
                production, and others. Buy Professional 3D Models are
                industry-accepted formats like OBJ, FBX, GLTF, 3DS, and others.
                There are also free downloads that are available for you to use.
                Try out the models on our
                {/* <Link
                  className="text-decoration-none"
                  to={`/3dmodels/food/burger-3d-model/viewer/`}
                  onClick={() =>
                    handleNavigationClick(
                      `/3dmodels/food/burger-3d-model/viewer/`
                    )
                  }
                > */}
                <a
                  className="text-decoration-none mx-1"
                  href={`/3dmodels/food/burger-3d-model/viewer/`}
                >
                  3D viewer
                </a>
                to ensure you choose the right product. We also offer a no
                questions asked refund.
              </div>
              <div>
                {/* const n=len(visible_categories)
              console.log("this is length",n) */}
              </div>
              <ZSlider type="category" items={visible_categories} />

              {/* <Link className="text-decoration-none" to="/3dmodels"> */}
              <a className="text-decoration-none" href="/3dmodels">
                <BootstrapButton
                  className="mt-5 explore-button-no-neo btn btn-ptimary"
                  dark={true}
                  size="lg"
                >
                  <span className="poppins">EXPLORE ALL</span>
                </BootstrapButton>
              </a>
              {/* </Link> */}
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          className="d-flex flex-column justify-content-center align-items-center h-100 text-center home-background-categories"
          fluid
          // style={styles.parentContainer}
        >
          <Container
            className="d-flex flex-column   justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <div className="w-100 my-5 ">
              <div className="font-size32 poppins-500 text-start text-white search-label mb-5 user-select-none">
                Explore Our <span className="text-primary">3D Models</span>
              </div>
              <div className="text-white mb-5">
                Buy Professional 3D Models. Our designer community has crafted
                professional 3D models for you to use in your projects, games,
                production, and others. Buy Professional 3D Models are
                industry-accepted formats like OBJ, FBX, GLTF, 3DS, and others.
                There are also free downloads that are available for you to use.
                Try out the models on our
                {/* <Link
                  className="text-decoration-none "
                  to={`/3dmodels/food/burger-3d-model/viewer/`}
                  onClick={() =>
                    handleNavigationClick(
                      `/3dmodels/food/burger-3d-model/viewer/`
                    )
                  }
                >
                  3D viewer
                </Link>{" "} */}
                <a
                  className="text-decoration-none mx-1"
                  href={`/3dmodels/food/burger-3d-model/viewer/`}
                >
                  3D viewer
                </a>
                to ensure you choose the right product. We also offer a no
                questions asked refund.
                {/* Buy professional 3D models from our designer community, crafted
                specifically for use in your projects, games, and productions.
                Our models are available in industry-accepted formats like OBJ,
                FBX, GLTF, 3DS, and more. We also offer free downloads for your
                convenience. To ensure you choose the right product, try our
                models on our 3D viewer. Additionally, we offer a
                no-questions-asked refund policy. */}
              </div>
              <ZSlider type="category" items={visible_categories} />
              {/* <Link className="text-decoration-none " to="/3dmodels"> */}
              <a className="text-decoration-none " href="/3dmodels">
                <BootstrapButton
                  className="mt-5 explore-button-no-neo btn btn-ptimary"
                  dark={true}
                  size="lg"
                  onClick={() => handleNavigationClick("/3dmodels")}
                  style={{ border: changingBorder ? "0px" : "0px" }}
                  onMouseEnter={() => setChangingBroder(true)}
                  onMouseLeave={() => setChangingBroder(false)}
                >
                  <span className="poppins">EXPLORE ALL</span>
                </BootstrapButton>
              </a>
              {/* </Link> */}
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <Container
        className="d-flex flex-column justify-content-center align-items-center h-100 text-center home-background-carousel"
        fluid
        style={
          breakpoint === "small" || breakpoint === "xsmall"
            ? styles.parentContainer90
            : styles.parentContainer
        }
      >
        <Container
          className="d-flex flex-column justify-content-center align-items-center mt-5 px-0 mx-0 trendingsliderclass"
          style={{ height: "100%" }}
        >
          <Breakpoint large up>
            <h3 className="h3 p-0 poppins-400 text-white mb-5 user-select-none">
              The Show <span className="text-primary">Stoppers</span>
            </h3>
            <div className="text-white mb-5 mx-lg-5">
              Behold the show-stoppers. Please browse our online store, and fill
              your shopping cart with
              {/* <Link
                className="text-decoration-none"
                to={`/3dmodels`}
                onClick={() => handleNavigationClick(`/3dmodels`)}
              >
                </Link> */}
              <a className="text-decoration-none mx-1" href={`/3dmodels`}>
                professional 3D models
              </a>
              . You can choose from industry-accepted formats of 3D assets for
              your projects, games, and productions. These trending 3D models in
              our 3D asset stores will help you build your 3D portfolio. Enhance
              your online shopping experience of these trending 3D models using
              our 3D viewer.
            </div>
          </Breakpoint>
          <Breakpoint medium down>
            <div className="poppins-500 font-size32 text-white p-0 mx-3 mb-4">
              The Show <span className="text-primary">Stoppers</span>
            </div>
          </Breakpoint>
          <div
            className={`${
              breakpoint === "xsmall"
                ? "w-100"
                : "w-100 my-5 px-0 trendingsliderclass"
            }`}
          >
            {trendingProducts?.length > 0 && (
              <ZSlider type="carousel" items={trendingProducts} />
            )}
            {/* {trendingProducts.length === 0 && <Spinner className="my-5" animation="border" style={{ color: "#555" }}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>} */}
            {/* {trendingProducts.length === 0 && <video muted autoPlay loop width={60} height={60}>
              <source src={znanyeloader} type="video/webm" />
              Your browser does not support the video tag.
            </video>} */}
            {trendingProducts.length === 0 && (
              <Image
                src={znanyeloader}
                alt="Znanye Loader"
                loading="lazy"
                style={{ width: 60, height: 60 }}
              />
            )}
          </div>
        </Container>
      </Container>
      <Breakpoint large up>
        <Container
          className="d-flex flex-column justify-content-center align-items-center h-100 text-center home-background-categories"
          fluid
          style={styles.parentContainer85}
        >
          <Container
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <div className="m-5 w-100">
              <h4 className="poppins-400 text-white mb-5 user-select-none h3 p-0">
                <span className="text-primary">Fast</span> Moving Items
              </h4>
              {fastMoving.length > 0 && (
                <ZSlider type="product" items={fastMoving} />
              )}
              {/* {fastMoving.length === 0 && <Spinner className="my-5" animation="border" style={{ color: "#555" }}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>} */}
              {/* {fastMoving.length === 0 && <video muted autoPlay loop width={60} height={60}>
                <source src={znanyeloader} type="video/webm" />
                Your browser does not support the video tag.
              </video>} */}
              {fastMoving.length === 0 && (
                <Image
                  loading="lazy"
                  src={znanyeloader}
                  alt="Znanye Loader"
                  style={{ width: 60, height: 60 }}
                />
              )}
              {/* <Button
                className="mt-5 explore-button"
                dark={true}
                size="large"
              >
                <span className="poppins">EXPLORE ALL</span>
              </Button> */}
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          className="d-flex flex-column justify-content-center align-items-center h-100 text-center home-background-categories"
          fluid
          style={styles.parentContainerDark}
        >
          <Container
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <div className="m-5 w-100">
              <div className="poppins-500 font-size32 text-white p-0 mx-3 mb-4">
                <span className="text-primary">Fast</span> Moving Items
              </div>
              {fastMoving.length > 0 && (
                <ZSlider type="product" items={fastMoving} />
              )}
              {/* {fastMoving.length === 0 && <Spinner className="my-5" animation="border" style={{ color: "#555" }}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>} */}
              {/* {fastMoving.length === 0 && <video muted autoPlay loop width={60} height={60}>
                <source src={znanyeloader} type="video/webm" />
                Your browser does not support the video tag.
              </video>} */}
              {fastMoving.length === 0 && (
                <Image
                  loading="lazy"
                  src={znanyeloader}
                  alt="Znanye Loader"
                  style={{ width: 60, height: 60 }}
                />
              )}
              {/* <Button className="mt-5 explore-button" dark={true} size="large">
                <span className="poppins">EXPLORE ALL</span>
              </Button> */}
            </div>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint large up>
        <Container
          className="d-flex flex-column justify-content-center align-items-center h-100 text-center home-background-categories"
          fluid
          style={styles.parentContainer90}
        >
          <Container
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <Container
              style={{ backgroundColor: "#141415", borderRadius: "40px" }}
            >
              <div className="my-5 w-100 pb-4">
                <div className="poppins-500 text-white search-label user-select-none h3 p-0">
                  <span className="text-primary">Reasons</span> To Buy On Znanye
                </div>
              </div>
              <Row className="row-cols-3">
                {sixReasons.map((reason) => {
                  return (
                    <div className="d-flex flex-column justify-content-start align-items-center mb-5 px-5">
                      <BCard
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          borderRadius: "64px",
                          minWidth: "160px",
                          minHeight: "160px",
                        }}
                      >
                        {/* <Image src={reason.image} alt={reason.title} width="96" height="96" /> */}
                        <video muted autoPlay loop width="96" height="96">
                          <source src={reason.image} type="video/webm" />
                          Your browser does not support the video tag.
                        </video>
                        {/* <Image loading="lazy" src={reason.image} alt={reason.title} width="96" height="96" /> */}
                      </BCard>
                      <p className="text-white m-3 subtitle">{reason.title}</p>
                    </div>
                  );
                })}
              </Row>
            </Container>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          className="d-flex flex-column justify-content-center align-items-center h-100 text-center home-background-categories"
          fluid
          style={styles.parentContainer90}
        >
          <Container
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <Container
              style={{ backgroundColor: "#141415", borderRadius: "40px" }}
            >
              <div className="my-5 w-100">
                <div className="font-size32 p-0 poppins-500 text-white search-label user-select-none">
                  <span className="text-primary">Reasons</span> To Buy On Znanye
                </div>
              </div>
              <Row className="row-cols-1">
                {sixReasons.map((reason) => {
                  return (
                    <div className="d-flex flex-column justify-content-center align-items-center mb-3 px-4">
                      <BCard
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          borderRadius: "40px",
                          minWidth: "96px",
                          minHeight: "96px",
                        }}
                      >
                        {/* <Image loading="lazy" src={reason.image} alt={reason.title} width="64" height="64" /> */}
                        <video muted autoPlay loop width="64" height="64">
                          <source src={reason.image} type="video/webm" />
                          Your browser does not support the video tag.
                        </video>
                      </BCard>
                      <p className="text-white m-4 body-text2 mx-5">
                        {reason.title}
                      </p>
                    </div>
                  );
                })}
              </Row>
            </Container>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint large up>
        <Container
          className="d-flex w-80 flex-column align-items-center px-lg-5 py-5 home-background-categories text-white mb-5"
          fluid
        >
          <Container>
            <Row className="my-4">
              <Col
                lg={6}
                className="d-flex justify-content-center align-items-center"
              >
                <div className=" me-lg-5 text-center text-lg-start">
                  <h5 className="poppins-400 text-white search-label user-select-none h3 p-0">
                    Tailored to <span className="text-primary">your needs</span>
                  </h5>
                  <p className="mt-5 subtitle text-center text-lg-start user-select-none">
                    We believe that one size does not fit all. In this
                    fast-changing environment, what makes you stand out is your
                    key differentiator. We help you stand out with customized
                    offerings. Get in touch with us and we'll help you explore
                    the infinite possibilities out there.
                  </p>
                  <a
                    className="text-decoration-none my-5"
                    href="/contact-us/#Buyer"
                  >
                    <BootstrapButton
                      className="mt-5  btn btn-ptimary explore-button-no-neo"
                      dark={true}
                      size="large"
                      onClick={() => handleNavigationClick("contact-us/#Buyer")}
                      style={{ border: changingBorder ? "0px" : "0px" }}
                      onMouseEnter={() => setChangingBroder(true)}
                      onMouseLeave={() => setChangingBroder(false)}
                    >
                      <span className="poppins">GET IN TOUCH</span>
                    </BootstrapButton>
                  </a>
                </div>
              </Col>
              <Col
                lg={6}
                className="d-flex justify-content-end align-items-end user-select-none"
              >
                <Image loading="lazy" src={tailoredbike} alt="Bike" />
              </Col>
            </Row>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          className="d-flex w-100 flex-column align-items-lg-center px-lg-5 py-5 home-background-categories text-white mb-5"
          fluid
        >
          <Container>
            <Row className="my-4">
              <Col
                lg={6}
                className="d-flex justify-content-lg-center align-items-center"
              >
                <div className="pe-lg-5 me-lg-5 text-start text-lg-start">
                  <div className="poppins-500 text-white search-label user-select-none font-size32 p-0">
                    Tailored to <span className="text-primary">your needs</span>
                  </div>
                  <p className="mt-5 text-start text-lg-start user-select-none">
                    We believe that one size does not fit all. In this
                    fast-changing environment, what makes you stand out is your
                    key differentiator. We help you stand out with customized
                    offerings. Get in touch with us and we'll help you explore
                    the infinite possibilities out there.
                  </p>
                  <a
                    className="text-decoration-none d-lg-flex my-5"
                    href="/contact-us/#Buyer"
                  >
                    <BootstrapButton
                      className="mt-5 btn btn-ptimary explore-button-no-neo"
                      dark={true}
                      size="large"
                      onClick={() => handleNavigationClick("contact-us/#Buyer")}
                      style={{ border: changingBorder ? "0px" : "0px" }}
                      onMouseEnter={() => setChangingBroder(true)}
                      onMouseLeave={() => setChangingBroder(false)}
                    >
                      <span className="poppins">GET IN TOUCH</span>
                    </BootstrapButton>
                  </a>
                </div>
              </Col>
              <Col
                lg={6}
                className="d-flex justify-content-center align-items-center user-select-none"
              >
                <Image loading="lazy" src={tailoredbikesmall} alt="Bike" />
              </Col>
            </Row>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint large up>
        <Container
          className="d-flex w-100 flex-column align-items-center px-lg-5 py-5 home-background-categories text-white mb-5"
          fluid
          style={styles.parentContainer90}
          ref={oculusQuestRef}
        >
          <Container
            className="p-0"
            style={{
              backgroundImage: `url(${vrheadsetbackgroundlighter})`,
              backgroundRepeat: "no-repeat",
              margin: "50px 0px 0px 140px",
            }}
          >
            <Container
              className="p-0"
              style={{
                backgroundImage: `url(${vrheadsetbackground})`,
                backgroundRepeat: "no-repeat",
                margin: "-100px 0px 0px -70px",
              }}
            >
              <div className="d-flex flex-column mt-5">
                <h7 className="poppins-400 text-white search-label user-select-none h3">
                  <div className="d-flex justify-content-center align-items-center mt-5">
                    Oculus Quest At&nbsp;
                    <span className="text-primary">Fair Prices</span>&nbsp;
                  </div>
                  <div className="h5 text-center mt-4">
                    (Shipping Available in India Only)
                  </div>
                  {/* <div className="d-flex justify-content-center align-items-center">
                </div> */}
                </h7>
                <a
                  className="text-decoration-none d-flex justify-content-center my-5"
                  href="/contact-us/#Buy-VR-Headset"
                >
                  <BootstrapButton
                    className="btn btn-ptimary explore-button-no-neo"
                    dark={true}
                    size="large"
                    onClick={() =>
                      handleNavigationClick("/contact-us/#Buy-VR-Headset")
                    }
                    style={{ border: changingBorder ? "0px" : "0px" }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}
                  >
                    <span className="poppins">GET MY VR HEADSET</span>
                  </BootstrapButton>
                </a>
                <div className="d-flex justify-content-center pt-5">
                  <Image loading="lazy" src={oculus3} alt="Oculus" />
                </div>
              </div>
            </Container>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container
          className="d-flex w-100 flex-column align-items-center px-lg-5 pt-5 home-background-categories text-white overflow-hidden"
          fluid
          style={{ minHeight: "60vh" }}
          ref={oculusQuestRef}
        >
          <Container
            className="p-0"
            style={{
              backgroundImage: `url(${vrheadsetbackgroundlightersmall})`,
              backgroundRepeat: "no-repeat",
              margin: "30px 0px 0px 120px",
            }}
          >
            <Container
              className="d-flex justify-content-center align-items-center"
              style={{
                backgroundImage: `url(${vrheadsetbackgroundsmall})`,
                margin: "-130px 0px 0px -60px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="pt-5 mt-5">
                <div className="mx-4 smt-5 pt-5 h2 poppins-500 text-white  text-center search-label user-select-none ">
                  Get Your <span className="text-primary">VR Headset</span> from
                  Znanyne and{" "}
                  <span className="text-primary"> Get Discount! </span>
                </div>
                <div className="py-1">
                  <a
                    className="my-4 text-decoration-none d-flex justify-content-center "
                    href="/contact-us/#Buy-VR-Headset"
                  >
                    <BootstrapButton
                      className="explore-button-no-neo btn btn-ptimary"
                      dark={true}
                      size="large"
                      onClick={() =>
                        handleNavigationClick("/contact-us/#Buy-VR-Headset")
                      }
                      style={{ border: changingBorder ? "0px" : "0px" }}
                      onMouseEnter={() => setChangingBroder(true)}
                      onMouseLeave={() => setChangingBroder(false)}
                    >
                      <span className="poppins">GET MY VR HEADSET</span>
                    </BootstrapButton>
                  </a>
                </div>

                <div className="d-flex justify-content-center">
                  <Image loading="lazy" src={oculus3small} alt="Oculus" />
                </div>
              </div>
            </Container>
          </Container>
        </Container>
      </Breakpoint>
      <Container
        className="d-flex w-100 flex-column align-items-center px-lg-5 py-5 home-background-categories text-white mb-5"
        fluid
        style={styles.parentContainer85}
      >
        <Container>
          <Row className="my-4">
            <Col lg={5} className="d-flex align-items-center">
              <div className="pe-lg-5 me-lg-5 text-start d-flex flex-column align-items-start text-lg-start user-select-none">
                <Breakpoint large up>
                  <h8 className="h3 text-start">
                    Try <span className="text-primary">our 3D Viewer</span> For
                    Your Next Project
                  </h8>
                  <p className="mt-5 subtitle text-center text-lg-start">
                    Don’t miss out on the opportunity to showcase your product
                    in the most effective manner. Your competition is doing the
                    same. Get yourself a 3D viewer. We will build one for you.
                    Contact us.
                  </p>
                </Breakpoint>
                <Breakpoint medium down>
                  <h8 className="h2 text-start">
                    Try <span className="text-primary">our 3D Viewer</span> For
                    Your Next Project
                  </h8>
                  <p className="mt-5 text-start" styles={{ fontSize: 16 }}>
                    Don’t miss out on the opportunity to showcase your product
                    in the most effective manner. Your competition is doing the
                    same. Get yourself a 3D viewer. We will build one for you.
                    Contact us.
                  </p>
                </Breakpoint>
                <a
                  className="text-decoration-none"
                  href={"/contact-us/#Interested-in-3D-Viewer"}
                >
                  <BootstrapButton
                    dark={true}
                    className="explore-button-no-neo btn btn-ptimary explore-button-large-no-neo my-5"
                    size="large"
                    onClick={() =>
                      handleNavigationClick(
                        "contact-us/#Interested-in-3D-Viewer"
                      )
                    }
                    style={{ border: changingBorder ? "0px" : "0px" }}
                    onMouseEnter={() => setChangingBroder(true)}
                    onMouseLeave={() => setChangingBroder(false)}
                  >
                    <span className="poppins">CONTACT US</span>
                  </BootstrapButton>
                </a>
              </div>
            </Col>
            <Col lg={7}>
              <BCard
                className="w-100 p-2 py-3 py-lg-5 d-flex flex-column justify-content-center align-items-center"
                dark={true}
                style={{
                  borderRadius: "20px",
                  backgroundColor: "#1b1c1d",
                  border: "1px solid #3D3D3D",
                }}
              >
                <Breakpoint large up>
                  <div className="w-50">
                    <iframe
                      title="1"
                      style={{ backgroundColor: "transparent", aspectRatio: 2 }}
                      src={`${axios.defaults.baseURL}viewer/interest/${viewer_products[displayItem].file3d}`}
                      width={600}
                      height={400}
                    />
                  </div>
                </Breakpoint>
                <Breakpoint medium down>
                  <iframe
                    title="2"
                    style={{ backgroundColor: "transparent" }}
                    src={`${axios.defaults.baseURL}viewer/interest/${viewer_products[displayItem].file3d}`}
                    width={300}
                    height={200}
                  />
                </Breakpoint>
                <div className="d-flex justify-content-center">
                  {viewer_products.map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setDisplayItem(index);
                        }}
                      >
                        <Breakpoint large up>
                          <Card
                            className="mx-4 d-flex justify-content-center cursor-pointer"
                            outlined
                            dark={true}
                            style={{
                              width: 120,
                              height: 80,
                              borderRadius: 5,
                              borderWidth: 2,
                              borderColor:
                                displayItem === index ? "#FFFFFF" : "#292929",
                            }}
                          >
                            <Image
                              loading="lazy"
                              src={item.image}
                              alt={item.title}
                            />
                          </Card>
                        </Breakpoint>
                        <Breakpoint medium down>
                          <Card
                            className="mx-2 d-flex justify-content-center cursor-pointer"
                            outlined
                            dark={true}
                            style={{
                              width: 90,
                              height: 60,
                              borderRadius: 5,
                              borderWidth: 2,
                              borderColor:
                                displayItem === index ? "#FFFFFF" : "#292929",
                            }}
                          >
                            <Image
                              loading="lazy"
                              src={item.image}
                              alt={item.title}
                            />
                          </Card>
                        </Breakpoint>
                      </div>
                    );
                  })}
                </div>
              </BCard>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        fluid
        className="d-flex w-100 flex-column align-items-center px-lg-5 py-5 home-background-categories text-white mb-5"
        style={styles.parentContainer85}
        ref={myRefForm}
      >
        <Container
          className="p-0 mb-5"
          style={{
            width: "100%",
            backgroundColor: "#292929",
            borderRadius: "40px",
            margin: "0px 150px 0px 0px",
          }}
        >
          <Container
            className="p-0"
            style={{
              width: "100%",
              backgroundColor: "#141415",
              borderRadius: "40px",
              margin: "-70px 70px 70px 70px",
            }}
          >
            <Row className="">
              <Col
                lg={6}
                className="d-flex justify-content-center align-items-center"
              >
                <Breakpoint large up>
                  <div className="">
                    <Image
                      loading="lazy"
                      src={getintouchwithznanye}
                      alt="Get in Touch with Znanye"
                    />
                  </div>
                </Breakpoint>
                <Breakpoint medium down className="text-center">
                  <div className="mt-5 d-flex justify-content-center align-items-center">
                    <p className="h3 poppins-400 text-white search-label user-select-none">
                      Get In <span className="text-primary">Touch</span>
                    </p>
                  </div>
                </Breakpoint>
              </Col>
              <Col lg={6} className={`my-3`}>
                <Row>
                  <Col lg={10}>
                    <ContactForm form={form} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
    </React.Fragment>
  );
};

const styles = {
  mobileParentContainer: {
    minHeight: "100vh",
  },
  parentContainer: {
    minHeight: "110vh",
  },
  parentContainer90: {
    minHeight: "80vh",
  },
  parentContainer85: {
    minHeight: "85vh",
  },
  parentContainerDark: {
    minHeight: "70vh",
  },
  categoryCard: {
    width: 245,
    height: 280,
  },
  cardAction: {
    backgroundColor: "#333333",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  posterImageCard: {
    background: "none",
    borderBottomLeftRadius: 10,
  },
};

export default Home;

import React from "react";
import { Container, Spinner, Image } from 'react-bootstrap';
import { znanyeloader } from "assets";

const Loader = ({ faded }) => {
    return (
        <Container
            fluid
            className="d-flex justify-content-center align-items-center position-fixed w-100 h-100"
            style={{ background: faded ? "rgba(10, 10, 10, 0.8)" : "#222", ...styles.parentContainer }}
        >
            {/* <Spinner animation="border" style={{ color: "#555" }}>
                <span className="visually-hidden">Loading...</span>
            </Spinner> */}
            <Image src={znanyeloader} loading="lazy" style={{ width: 60, height: 60 }} />
            {/* <video muted autoPlay loop width={60} height={60}>
              <source src={znanyeloader} type="video/webm" />
              Your browser does not support the video tag.
            </video> */}
        </Container>
    );
}

const styles = {
    parentContainer: {
        zIndex: 9999,
        top: 0,
        bottom: 0
    }
}

export default Loader;
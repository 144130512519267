import React, { useRef, useState, useEffect } from "react";

import { Container, Row, Col, Image, Carousel } from "react-bootstrap";
import { Card, CardAction, Button, IconButton } from "ui-neumorphism";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Breakpoint } from "react-socks";

// import {
//   bigentrygate,
//   smallentrygate,
//   girlvr,
//   boyvr,
//   ferriswheel,
//   merrygoround,
//   archery,
//   imagenotavailable,
//   rushikeshveer,
//   // ballsyvrjan,
//   // deshmanepatil,
//   // pastelcube,
// } from "assets_old/business";

import { ballsyvrjan, deshmanepatil, pastelcube } from "assets/business";

import "components/ZSlider/ZSlider.scss";

const reviews = [
  {
    id: 1,
    name: "BallsyVRJan",
    review: `It's the only VR theme park around!
      Look, it's not without imperfections, but it's free and it gives you a virtual reality theme park, there is literally no other Quest 2 game like it! You can see a full exploration and review here: https://youtu.be/Ge75lcVRapU</a>`,
    image: ballsyvrjan,
  },
  {
    id: 2,
    name: "deshmane-patil",
    review:
      "Imaginative Theme Park. Visitors can enjoy rides, as well as an entire theme park, equipped with a food area, the park to play the game.",
    image: deshmanepatil,
  },
  {
    id: 3,
    name: "Pastelcube",
    review: `Comfort. Goes easy on the eyes. Testimonials for ZNanyeland Case study`,
    image: pastelcube,
  },
];

const AnimatedSlider = () => {
  const scrollRef = useRef(null);
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const [active, setActive] = useState(1);

  const [selected, setSelected] = useState(0);

  const scroll = async (scrollOffset) => {
    // console.log(scrollRef);
    let counter = 0;
    let absScrollOffset = scrollOffset < 0 ? -1 * scrollOffset : scrollOffset;
    // alert(scrollOffset);
    while (counter < absScrollOffset) {
      scrollOffset > 0
        ? (scrollRef.current.scrollLeft += 20)
        : (scrollRef.current.scrollLeft -= 20);
      counter += 20;
      await sleep(10);
    }
  };

  useEffect(() => {
    // if (selectCallback) {
    // //   selectCallback(selected);
    // }
  }, [selected]);

  //   if (items?.length == 0 || items == null) {
  //     return <div />;
  //   }

  return (
    <Container
      className="p-0 d-flex flex-row justify-content-start align-items-center px-3"
      fluid
    >
      {reviews.length > 3 && (
        <IconButton
          dark={true}
          rounded
          text={false}
          color="#fff"
          onClick={(e) => {
            e.preventDefault();
            scroll(-390);
            if (active > 1) {
              setActive(active - 1);
            }
          }}
        >
          <FaChevronLeft />
        </IconButton>
      )}
      <div
        // dark={true}
        className="d-flex my-4 mx-3 user-select-none noScrollBar overflow-hidden"
        style={{
          width: 1200,
          overflowX: "scroll",
          borderRadius: 5,
        }}
        ref={scrollRef}
      >
        {reviews?.map((item, index) => {
          return (
            <Col
              lg={4}
              sm={1}
              className="px-4 p-lg-3 d-flex justify-content-center"
              key={item}
              onClick={() => {
                if (index < 1) {
                  setActive(1);
                } else if (index > reviews.length - 2) {
                  setActive(reviews.length - 2);
                } else {
                  if (index == active) {
                    return;
                  }
                  if (index > active) {
                    scroll(400);
                  } else {
                    scroll(-400);
                  }
                  setActive(index);
                }
              }}
              //   style={{height: 400}}
            >
              <div
                className={`d-flex flex-column justify-content-end align-items-center py-4 ${
                  index == active ? "px-1" : "px-3"
                }`}
              >
                <Card
                  className="position-relative my-5 d-flex flex-column align-items-center w-100 custom-card"
                  dark={true}
                  style={{
                    height: index == active ? 360 : 300,
                    backgroundColor: "#000000",
                  }}
                >
                  <Image
                    src={item.image}
                    alt={item.name}
                    className="cardImgTop"
                  />
                  <div className="d-flex flex-column align-items-center py-3">
                    <div className="mt-5 mb-0 text-center">
                      <p>{item.name}</p>
                    </div>
                    <div style={styles.underline}></div>
                    <div
                      className={`mt-4 text-center p-2 p-lg-3 overflow-auto ${
                        index == active ? "reviewCardActive" : "reviewCard"
                      }`}
                      style={{ overflow: "hidden" }}
                    >
                      <p dangerouslySetInnerHTML={{ __html: item.review }} />
                    </div>
                  </div>
                </Card>
              </div>
            </Col>
          );
        })}
      </div>
      {reviews.length > 3 && (
        <IconButton
          dark={true}
          rounded
          text={false}
          color="#fff"
          onClick={(e) => {
            e.preventDefault();
            scroll(390);
            if (active < reviews.length - 2) {
              setActive(active + 1);
            }
          }}
        >
          <FaChevronRight />
        </IconButton>
      )}
    </Container>
  );
};

const styles = {
  underline: {
    height: 1,
    width: 120,
    backgroundColor: "white",
  },
};

export default AnimatedSlider;

// import axios from "axios";
import { toast } from "react-toastify";

const axios = require("axios").default;

const notify = (msg, type) => {
  switch (type) {
    case "success":
      toast.success(msg);
      break;
    case "error":
      toast.error(msg);
      break;
    case "warning":
      toast.warning(msg);
      break;
    default:
      toast.error(msg);
  }
  // alert();
};

////////////////////////// CATEGORY - Action Creator //////////////////////////

export const categoryLoad = (data) => {
  return {
    type: "LOAD_ALL_CATEGORIES",
    payload: data,
  };
};

////////////////////////// PRODUCT - Action Creator //////////////////////////

export const productLoad = (data) => {
  return {
    type: "LOAD_ALL_PRODUCTS",
    payload: data,
  };
};

export const advertisesLoad = (data) => {
  return {
    type: "LOAD_ALL_ADVERTISES",
    payload: data,
  };
};

export const productSearch = (data) => {
  return {
    type: "SEARCH_PRODUCTS",
    payload: data,
  };
};

export const categoryProductSearch = (data) => {
  return {
    type: "SEARCH_PRODUCTS_OF_CATEGORY",
    payload: data,
  };
};

////////////////////////// MARKETING - Action Creator //////////////////////////

export const sendMessage = (data) => {
  return {
    type: "SEND_MESSAGE",
    payload: data,
  };
};

////////////////////////// REVIEWS - Action Creator //////////////////////////

export const reviewLoad = (data) => {
  return {
    type: "LOAD_ALL_REVIEWS",
    payload: data,
  };
};

export const reviewCreate = (data) => {
  return {
    type: "ADD_REVIEW",
    payload: data,
  };
};

export const reviewRemove = (data) => {
  return {
    type: "REMOVE_REVIEW",
    payload: data,
  };
};

////////////////////////// CATEGORY - Actions //////////////////////////

export const loadCategories = () => {
  return async (dispatch) => {
    try {
      // console.log("Loading Categories");
      const response = await axios.get(`/category/`);
      // console.log("Response", response);
      dispatch(categoryLoad(response.data));
    } catch (err) {
      // console.log("Error", err);
    }
  };
};

////////////////////////// PRODUCT - Actions //////////////////////////

export const loadProducts = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/product/?is_verified=true&&is_archived=false`
      );
      // console.log("&&&&&&&&&&&&&&&&&", response)
      dispatch(productLoad(response.data));
    } catch (err) {
      // console.log("777777777777777Error", err);
    }
  };
};

export const loadProduct = async (slug) => {
  try {
    const response = await axios.get(
      `/product/${slug}/`
    );
    // console.log("Response", response);
    return response.data;
  } catch (err) {
    // console.log("Error", err);
  }
};

  export const searchProduct = async (data,cancelToken) => {
    try {
      const response = await axios.get(
        `/product/?is_verified=true&is_archived=false&search=` + data
      );
      // dispatch(productSearch(response.data));
      return response.data;
    } catch (err) {
      // console.log("Error", err);
    }
    // };
  };

export const searchCategoryProduct = async (
  categoryTitle,
  ordering,
  polygons__gt,
  polygons__lt,
  price__gt,
  price__lt,
  format__icontains
) => {
  // return async () => {

  let paramString = "";
  if (polygons__gt) {
    paramString += `&polygons__gt=${polygons__gt}`;
  }
  if (polygons__lt) {
    paramString += `&polygons__lt=${polygons__lt}`;
  }
  if (price__lt && price__lt != 1000) {
    paramString += `&price__lt=${price__lt}`;
  }
  if (price__gt) {
    paramString += `&price__gt=${price__gt}`;
  }
  if (format__icontains != undefined) {
    paramString += `&format__icontains=${format__icontains}`;
  }
  if (ordering) {
    paramString += `&ordering=${ordering}`;
  }
  try {
    const response = await axios.get(
      `/product/?is_verified=true&is_archived=false&category__slug=${categoryTitle}${paramString}`
    );
    // console.log("Response", response);
    // dispatch(categoryProductSearch(response.data));
    return response.data;
  } catch (err) {
    // console.log("Error", err);
  }
  // };
};

////////////////////////// MARKETING - Actions //////////////////////////

export const createMessage = async (data) => {
  try {
    const response = await axios.post(`/marketing/contact/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    if (response.status == 200) {
      return response.data;
    }
  } catch (err) {
    // console.log("@@@ Error", err);
    notify("Message could not be sent!", "error");
  }
};

export const loadAdvertises = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/marketing/posters/`);
      // console.log("Response", response);
      dispatch(advertisesLoad(response.data));
    } catch (err) {
      // console.log("Error", err);
    }
  };
};

////////////////////////// REVIEW - Actions //////////////////////////

export const loadReviews = async (productSlug, url) => {
  // return async (dispatch) => {
  try {
    // console.log("Loading Categories");
    const response = await axios.post(url, {
      productSlug: productSlug,
    });
    // console.log("Response", response);
    if (response.status == 200) {
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Error in loading reviews", "error");
  }
};

export const createReview = async (data) => {
  try {
    const response = await axios.post(`/review/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      notify("Your review has been recorded!", "success");
      return response.data;
    }
  } catch (err) {
    if (err.response.status === 402){
      notify("You can only Review once","error")
    }else{
      alert("Review could not be added!");
    }
    // console.log("@@", err,err.response.data);
  }
  // };
};
export const removeReview = async (reviewID) => {
  // return async (dispatch) => {
  try {
    const response = await axios.delete(`/review/` + reviewID + "/");
    // console.log("Response", response);
    notify("Your review has been removed!", "success");
    return response;
  } catch (err) {
    // console.log("Error", err);
    notify("Review could not be deleted!", "error");
  }
  // };
};

////////////////////////// ORDERS - Actions //////////////////////////

export const loadFastMoving = async () => {
  try {
    const response = await axios.get(`/order/fast_moving/`);
    // console.log("Response for fast moving:", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
  }
};

export const loadTrendingProducts = async () => {
  try {
    const response = await axios.get(`/product/?is_trending=true&ordering=-trending_priority`);
    // console.log("Response for fast moving:", response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
  }
};

////////////////////////// COUNTRY - Actions //////////////////////////

export const loadCountries = async () => {
  try {
    const response = await axios.get(`/user/country/`);
    // console.log(response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
  }
};


////////////////////////// Comment - Actions //////////////////////////

export const createComment = async (data) => {
  try {
    const response = await axios.post(`/comment/`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response", response);
    // dispatch(reviewCreate(response.data));
    if (response.status == 200) {
      notify("Your comment has been recorded!", "success");
      return response.data;
    }
  } catch (err) {
    // console.log("Error", err);
    notify("Comment could not be recorded!", "failure");
  }
};

export const loadComments = async (productSlug, ordering, url) => {
  // return async (dispatch) => {
    let paramString = "";
    console.log("ordering",ordering)
    if (ordering === null || ordering === "" || !ordering) {
      paramString = "-created_at";
    } else {
      paramString = ordering;
    }
  
  try {
    console.log(paramString)
    const response = await axios.post(url, {
        productSlug: productSlug,
        ordering : paramString
      });
      if (response.status == 200) {
        return response.data;
      }
   
  } catch (err) {
    // console.log("Error", err);
    notify("Error in loading comments", "error");
  }
};
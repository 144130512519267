import React, { useState, useRef, useEffect } from "react";
import { Container, Card, Image } from "react-bootstrap";
import { Breakpoint } from "react-socks";
import { whitelink } from "assets";

const License = () => {
    const free_licenses = [
        {
            id: 1,
            title: "CC0 - Public Domain",
            description:
                "The creator has relinquished all rights to the work, allowing others to use, modify, and share it freely without any restrictions.",
            link: null,
        },
        {
            id: 2,
            title: "CC BY - Attribution",
            description:
                "Others can share, modify, and use the model, but they must give credit to the original creator.",
            link: "https://creativecommons.org/licenses/by/4.0/",
        },
        {
            id: 3,
            title: "CC BY-SA - ShareAlike",
            description:
                "Others can share, modify, and use the model, but derivative work must be shared under the same license.",
            link: "https://creativecommons.org/licenses/by-sa/4.0/",
        },
        {
            id: 4,
            title: "CC BY-NC - NonCommercial",
            description: "Others cannot use the model for commercial purposes.",
            link: "https://creativecommons.org/licenses/by-nc/4.0/",
        },
        {
            id: 5,
            title: "CC BY-ND - NoDerivatives",
            description:
                "Others can use and share the model, but it cannot be altered.",
            link: "https://creativecommons.org/licenses/by-nd/4.0",
        },
        {
            id: 6,
            title: "CC BY-NC-SA",
            description:
                "Others can remix, adapt, and build upon your work non-commercially, as long as they credit you and license their new creations under the identical terms.",
            link: "https://creativecommons.org/licenses/by-nc-sa/4.0/",
        },
        {
            id: 7,
            title: "CC BY-NC-ND",
            description:
                "Only allowing others to download your works and share them with others as long as they credit you, but they can’t change them in any way or use them commercially.",
            link: "https://creativecommons.org/licenses/by-nc-nd/4.0/",
        },
    ];

    const paid_licenses = [
        {
            id: 1,
            title: 'Editorial license',
            description: 'This option allows only educational, journalistic, and newsworthy uses of the model, and commercial use is prohibited.',
        },
        {
            id: 2,
            title: 'Royalty-free license',
            description: 'This option permits buyers to use the model for commercial and non-commercial purposes indefinitely, without needing to pay additional fees. However, buyers cannot sell, give, or transfer the model to others in its original downloaded form.',
        },
    ]

    return (
        <React.Fragment>
            <Breakpoint large up>
                <Container
                    className="h-100 mt-5 pt-5 casestudy-background-case px-5"
                    fluid
                >
                    <ul className="breadcrumb subtitle mx-4 mb-3">
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li className="text-primary">License</li>
                    </ul>
                    <div className="d-flex justify-content-center">
                        <Card
                            className="m-5 h-100 d-flex justify-content-center"
                            style={{
                                backgroundColor: "#141415",
                                border: "1px solid #3D3D3D",
                                borderRadius: "24px",
                                maxWidth: "1400px"
                            }}
                        >
                            <ul className="m-5">
                                {free_licenses.map((free_license, index) => {
                                    return (
                                        <>
                                            <li className="text-white subtitle">{free_license.title} :</li>
                                            <div
                                                className={`${index === free_licenses.length - 1 ? free_license.link ? "mb-2": "" : free_license.link ? "mb-2" : "mb-4"}`}
                                                style={{ color: "#A1A1A1", fontSize: "17px", fontWeight: 400, letterSpacing: 0.5 }}
                                            >
                                                {free_license.description}
                                            </div>
                                            {free_license.link && (
                                                <div
                                                    className={`${index === free_licenses.length - 1 ? "" : "mb-4"}`}>

                                                    <a
                                                        href={free_license.link}
                                                        target="_blank"
                                                        className="text-decoration-none "
                                                    >
                                                        <u
                                                            className="text-white me-2"
                                                            style={{ fontSize: "14px" }}
                                                        >
                                                            LINK
                                                        </u>
                                                        <Image
                                                            src={whitelink}
                                                            style={{ width: 14, height: 14 }}
                                                        />
                                                    </a>
                                                </div>
                                            )}
                                        </>
                                    )
                                })}
                            </ul>
                            <div
                                style={{ height: 1, width: "100%", backgroundColor: "#3D3D3D" }}
                            />
                            <ul className="m-5">
                                {paid_licenses.map((paid_license, index) => {
                                    return (
                                        <>
                                            <li className="text-white subtitle">{paid_license.title} :</li>
                                            <div
                                                className={`${index === paid_license.length - 1 ? "" : "mb-4"} subtitle`}
                                                style={{ color: "#A1A1A1", fontSize: "17px", fontWeight: 400, letterSpacing: 0.5 }}
                                            >
                                                {paid_license.description}
                                            </div>


                                        </>
                                    )
                                })}
                            </ul>
                        </Card>
                    </div>
                </Container>
            </Breakpoint>
            <Breakpoint medium down>
                <Container
                    className="h-100 mt-5 pt-5 casestudy-background-case px-0"
                    fluid
                    style={{ minHeight: "150vh" }}
                >
                    <ul className="breadcrumb subtitle mx-4 mb-5">
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li className="text-primary">License</li>
                    </ul>
                    <div className="d-flex justify-content-center">
                        <Card
                            className="h-100 d-flex justify-content-center"
                            style={{
                                backgroundColor: "#141415",
                                border: "1px solid #3D3D3D",
                                borderRadius: "24px",
                                maxWidth: "1400px"
                            }}
                        >
                            <div className="my-5 mx-3">
                                {free_licenses.map((free_license, index) => {
                                    return (
                                        <>
                                            <div className="text-white h3">{free_license.title} :</div>
                                            <div
                                                className={`${index === free_licenses.length - 1 ? free_license.link ? "mb-1": "" : free_license.link ? "mb-2" : "mb-4"}`}
                                                style={{ color: "#A1A1A1", fontSize: "18px", fontWeight: 400, letterSpacing: 0.15 }}
                                            >
                                                {free_license.description}
                                            </div>
                                            {free_license.link && (
                                                <div
                                                    className={`${index === free_licenses.length - 1 ? "" : "mb-4"}`}>
                                                        {console.log(index)}


                                                    <a
                                                        href={free_license.link}
                                                        target="_blank"
                                                        className="text-decoration-none "
                                                    >
                                                        <u
                                                            className="text-white me-2"
                                                            style={{ fontSize: "14px" }}
                                                        >
                                                            LINK
                                                        </u>
                                                        <Image
                                                            src={whitelink}
                                                            style={{ width: 14, height: 14 }}
                                                        />
                                                    </a>
                                                </div>
                                            )}
                                        </>
                                    )
                                })}
                            </div>
                            <div
                                style={{ height: 1, width: "100%", backgroundColor: "#3D3D3D" }}
                            />
                            <div className="my-5 mx-3">
                                {paid_licenses.map((paid_license, index) => {
                                    return (
                                        <>
                                            <div className="text-white h3">{paid_license.title} :</div>
                                            <div
                                                className={`${index === paid_license.length - 1 ? "" : "mb-4"} subtitle`}
                                                style={{ color: "#A1A1A1", fontSize: "18px", fontWeight: 400, letterSpacing: 0.15 }}
                                            >
                                                {paid_license.description}
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </Card>
                    </div>
                </Container>
            </Breakpoint>
        </React.Fragment >
    )
}

export default License; 
import React, { useState, useEffect } from "react";
import * as authActions from "actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { addtocart_animation } from "assets";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
const AddToCartItem = ({ product, type, callBack }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.authReducer.user);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  const currentProduct = useSelector((state) =>
    state.authReducer.user?.cartproducts?.some(
      (item) => item.product_id == product?.id
    )
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const GradientText = styled.span`
    background: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `;

  const addToCart = () => {
    setTimeout(1000);
    setIsLoading(true);
    dispatch(authActions.addCartproduct(product, user?.id));
    if (callBack != undefined) {
      callBack(true);
    }
    // setIsLoading(false);
  };

  const removeFromCart = () => {
    const cartProduct = { product_id: product?.id };
    dispatch(authActions.removeCartproduct(cartProduct, user.id));
    if (callBack != undefined) {
      callBack(false);
    }
    setIsLoading(false);
  };

  const handleClick = () => {
    setIsLoading(true);

    // Perform the appropriate action (add to cart or remove from cart)
    if (currentProduct) {
      handleNavigationClick("/cart");
      // navigate("/cart");
    } else {
      addToCart();
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Adjust the duration as needed
  };

  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item != "");
  const param1 = params.length > 0 ? params[0] : "";

  if (param1 === "favourites") {
    return (
      <Button
        className={`${
          currentProduct
            ? "explore-gray-border-button-no-neo"
            : "explore-button-no-neo"
        }`}
        variant="dark"
        size="large"
        onClick={currentProduct ? removeFromCart : addToCart}
        style={{ width: 225, height: 50 }}
      >
        <span className="poppins">{`${
          currentProduct ? "REMOVE FROM CART" : "ADD TO CART"
        }`}</span>
      </Button>
    );
  }

  if (type === "productmobile") {
    return (
      <Button
        className="white-button d-flex justify-content-center align-items-center position-fixed w-50 py-4"
        size="large"
        // dark={true}
        style={{
          left: 0,
          bottom: 0,
          border: 0,
          borderRadius: 0,
          zIndex: 1,
          height: "60px",
          background: "white",
        }}
        onClick={handleClick}
        flat
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <img
              src={addtocart_animation}
              alt="ADD TO CART"
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            <GradientText>
              {currentProduct ? "GO TO CART" : "ADD TO CART"}
            </GradientText>
          )}
        </div>
      </Button>
    );
  }

  return (
    <Button
      className={`${
        currentProduct
          ? "explore-gray-border-button-no-neo"
          : "explore-button-no-neo"
      } my-4 w-100`}
      variant="dark"
      size="large"
      onClick={currentProduct ? removeFromCart : addToCart}
    >
      <span className="poppins">{`${
        currentProduct ? "REMOVE FROM CART" : "ADD TO CART"
      }`}</span>
    </Button>
  );
};

export default AddToCartItem;

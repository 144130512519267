import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image, Form,Button as RButton, } from "react-bootstrap";
import { Button, Card, CardAction } from "ui-neumorphism";
import { ZCard } from "components";
import { useSelector, useDispatch } from "react-redux";

import "core-ui/pages/MyAccount.scss";

import { emptycart, swiftdezire, mitsubishi } from "assets";
import { Link } from "react-router-dom";
import * as actions from "actions/AdminActions";
import { Searchbar, ProductCard, ZDropdown } from "components";

const Approvals = () => {
    const [uploadedProducts, setUploadedProducts] = useState([]);
    const [uploadedDraftProducts, setUploadedDraftProducts] = useState([]);
    const [newProducts, setNewProducts] = useState(true);
    const [updatedProducts, setUpdatedProducts] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadProducts());
        dispatch(actions.loadDraftProducts());
    }, []);

    const products = useSelector((state) => state.adminReducer.products);
    const draftProducts = useSelector((state) => state.adminReducer.draftproducts);
    const user = useSelector((state) => state.authReducer.user);
    //consolele.log("Uploaded Product:", products, user);
    //consolele.log("Uploaded Draft Product:", draftProducts, user);

    // uncomment to test the empty purchase list page
    // const uploadedProducts = [];

    const filters = [
        { value: "most_views", label: "MOST VIEWED" },
        // { value: "most_downloads", label: "MOST DOWNLOADS" },
        { value: "most_likes", label: "MOST LIKED" },
        { value: "latest", label: "LATEST" },
        { value: "oldest", label: "OLDEST" },
    ];

    function compareOnViews(a, b) {
        if (a.view_count > b.view_count) {
            return -1;
        }
        if (a.view_count < b.view_count) {
            return 1;
        }
        return 0;
    }

    function compareOnFavourites(a, b) {
        if (a.favourite_count > b.favourite_count) {
            return -1;
        }
        if (a.favourite_count < b.favourite_count) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        setUploadedProducts(products.filter((product) => product.is_in_draft === false && product.is_verified === false));
    }, [products]);

    useEffect(() => {
        setUploadedDraftProducts(draftProducts.filter((product) => product.is_in_draft === false && product.is_verified === false));
    }, [draftProducts]);

    if (uploadedProducts.length === 0) {
        return (
            <React.Fragment>
                <Container
                    className="d-flex w-100 justify-content-center align-items-center h-100 mt-5 pt-5 ps-5 home-background-categories"
                    fluid
                    style={styles.emptyPurchaseListContainer}
                >
                    <div>
                        <div className="d-flex justify-content-center">
                            <Image src={emptycart} alt="Empty Cart" style={{ width: 200 }} />
                        </div>
                        <div className="d-flex justify-content-center mt-5 mb-5">
                            <h4 className="text-primary m-1">OOPS ! </h4>
                            <h4 className="text-white m-1">
                                There are no products for approval!
                            </h4>
                        </div>
                        <div className="d-flex justify-content-center mt-5 mb-5">
                            <Link to="/sell-3d/uploadproducts" style={{ textDecoration: "none" }}>
                                <RButton
                                    dark={true}
                                    className="h-100 explore-button-no-neo btn btn-ptimary"
                                    size="lg"
                                >
                                    UPLOAD PRODUCTS
                                </RButton>
                            </Link>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <Container
                className="w-100 px-5 home-background-categories"
                fluid
                // style={{ paddingTop: 100 }}
                style={styles.mainContainer}
            >
                <div className="d-flex justify-content-start mt-5 mx-2">
                    <RButton
                        className={`${newProducts ? "explore-button-no-neo btn btn-ptimary" : "explore-border-button explore-button-large-no-neo"} p-4`}
                        dark={true}
                        variant="dark"
                        onClick={() => {
                            setNewProducts(true);
                            setUpdatedProducts(false);
                        }}
                    >
                        NEW PRODUCTS
                    </RButton>
                    <RButton
                        className={`${updatedProducts ? "explore-button-no-neo btn btn-ptimary" : "explore-border-button explore-button-large-no-neo"} p-4 mx-5`}
                        dark={true}
                        variant="dark"
                        onClick={() => {
                            setNewProducts(false);
                            setUpdatedProducts(true);
                        }}
                    >
                        UPDATED PRODUCTS
                    </RButton>
                </div>
                <Row className={`${newProducts ? "mt-5" : "d-none"}`} fluid>
                    {uploadedProducts?.map((uploadedProduct) => <ProductCard item={uploadedProduct} type="approvereject" newProduct={true} />)}
                </Row>
                <Row className={`${updatedProducts ? "mt-5" : "d-none"}`} fluid>
                    {uploadedDraftProducts?.map((uploadedProduct) => <ProductCard item={uploadedProduct} type="approvereject" newProduct={false} />)}
                </Row>
            </Container>
        </React.Fragment>
    );
};

const styles = {
    mainContainer: {
        minHeight: "100vh",
        paddingTop: 100
    },
    emptyPurchaseListContainer: {
        minHeight: "80vh",
    },
    progressBarContainer: {
        minHeight: 100,
    },
    cartCard: {
        height: 500,
    },
    cardAction: {
        backgroundColor: "#2E2E2E",
    },
    underline: {
        height: 1,
        backgroundColor: "white",
    },
    youSavedColor: {
        color: "#58D31B",
    },
};
export default Approvals;

import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button as RButton,
  Card,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import "core-ui/pages/MyAccount.scss";

import { emptycart, swiftdezire, mitsubishi } from "assets";
import { Link, useNavigate } from "react-router-dom";
import * as actions from "actions/AdminActions";
import {
  Searchbar,
  ProductCard,
  ZDropdown,
  SearchBarMyProduct,
  Loader,
} from "components";
import { Breakpoint } from "react-socks";
import axios from "axios";

const MyProducts = ({ type }) => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [pendingProducts, setPendingProducts] = useState(null);
  const [approvedProducts, setApprovedProducts] = useState(null);
  const [draftProducts, setDraftProducts] = useState(null);
  const [uploadedProducts, setUploadedProducts] = useState(null);

  const [allSellerProducts, setAllSellerProducts] = useState([]);
  const [productType, setProductType] = useState("verifiedProduct");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useRef(null);
  const products = useSelector((state) => state.adminReducer.products);
  const user = useSelector((state) => state.authReducer.user);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  // uncomment to test the empty purchase list page
  // const uploadedProducts = [];

  const filters = [
    { value: "most_views", label: "MOST VIEWED" },
    // { value: "most_downloads", label: "MOST DOWNLOADS" },
    { value: "most_likes", label: "MOST LIKED" },
    { value: "latest", label: "LATEST" },
    { value: "oldest", label: "OLDEST" },
  ];

  const compareOnViews = (a, b) => {
    if (a.view_count > b.view_count) {
      return -1;
    }
    if (a.view_count < b.view_count) {
      return 1;
    }
    return 0;
  };

  const compareOnFavourites = (a, b) => {
    if (a.favourite_count > b.favourite_count) {
      return -1;
    }
    if (a.favourite_count < b.favourite_count) {
      return 1;
    }
    return 0;
  };

  const compareOnLatest = (a, b) => {
    if (a.created_at > b.created_at) {
      return -1;
    }
    if (a.created_at < b.crusereated_at) {
      return 1;
    }
    return 0;
  };

  const compareOnOldest = (a, b) => {
    if (a.created_at < b.created_at) {
      return -1;
    }
    if (a.created_at > b.created_at) {
      return 1;
    }
    return 0;
  };

  // useEffect(() => {
  //   setUploadedProducts(products.filter((product) => product.created_by === user.id && product.is_verified === true));
  //   setAllSellerProducts(products.filter((product) => product.created_by === user.id && product.is_verified === true));
  // }, [products]);

  const sellerProducts = async (user) => {
    // const formData = new FormData();
    // formData.append("seller", user);
    const response = await actions.sellerProducts();
    if (response) {
      // console.log("@@",response)
      setApprovedProducts(response.approved_products);
      setDraftProducts(response.drafts);
      setPendingProducts(response.pending_products);
    }
  };
  useEffect(() => {
    if (selectedFilter?.value === "most_views") {
      setSelectedFilter(uploadedProducts?.sort(compareOnViews));
    } else if (selectedFilter?.value === "most_likes") {
      setSelectedFilter(uploadedProducts?.sort(compareOnFavourites));
    } else if (selectedFilter?.value === "latest") {
      setSelectedFilter(uploadedProducts?.sort(compareOnLatest));
    } else if (selectedFilter?.value === "oldest") {
      setSelectedFilter(uploadedProducts?.sort(compareOnOldest));
    }
  }, [selectedFilter]);

  useEffect(async () => {
    switch (type) {
      case "approved_products":
        const resp1 = await actions.approved_products();
        if (resp1) {
          setUploadedProducts(resp1);
          // setApprovedProducts(resp1.length);
          setAllSellerProducts(resp1);
          setProductType("verifiedProduct");
        }
        break;
      case "pending_approvals":
        const resp2 = await actions.pending_approvals();
        if (resp2) {
          //consolelog(resp2);
          setUploadedProducts(resp2);
          // setPendingProducts(resp2.length);
          setAllSellerProducts(resp2);
          setProductType("underApprovalProduct");
        }
        break;
      case "drafts":
        const resp3 = await actions.drafts();
        if (resp3) {
          setUploadedProducts(resp3);
          // setDraftProducts(resp3.length);
          setAllSellerProducts(resp3);
          setProductType("");
        }
        break;
      default:
    }
  }, [type, products]);

  useEffect(() => {
    sellerProducts(user);
  }, [products]);

  return (
    <React.Fragment>
      <Breakpoint large up>
        <Container
          className="w-100 px-5 home-background-categories"
          fluid
          // style={{ paddingTop: 100 }}
          style={styles.mainContainer}
        >
          <Row className="mx-2 mb-5">
            <Col sm={3} style={{ zIndex: 1000 }}>
              <SearchBarMyProduct
                type="main"
                uploadedProducts={allSellerProducts}
                updateProducts={(filteredProducts) => {
                  setUploadedProducts(filteredProducts);
                }}
              />
            </Col>
            <Col sm={3} className="d-flex flex-column justify-content-center">
              <Card
                className="p-1"
                style={{
                  backgroundColor: "#0D0D0D",
                  border: 0,
                  border: "1px solid #3d3d3d",
                  borderRadius: "5px",
                }}
              >
                <ZDropdown
                  options={filters}
                  mode="filters"
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                />
              </Card>
            </Col>
            <Col
              sm={6}
              className="d-flex flex-column justify-content-center align-items-start"
            >
              <Card
                className="p-1 d-flex flex-row"
                style={{
                  backgroundColor: "#0D0D0D",
                  minWidth: "300px",
                  border: "1px solid #3d3d3d",
                  borderRadius: "5px",
                }}
              >
                <RButton
                  className="wrapped-button ms-1 my-1 py-2 px-2"
                  style={
                    type === "approved_products"
                      ? styles.selectedModeButton
                      : styles.unselectedModeButton
                  }
                  // onClick={() => navigate("/sell-3d/myproducts")}
                  onClick={() => handleNavigationClick("/sell-3d/myproducts")}
                >
                  Approved [<span>{approvedProducts}</span>]
                </RButton>
                <RButton
                  className="wrapped-button my-1 py-2"
                  style={
                    type === "pending_approvals"
                      ? styles.selectedModeButton
                      : styles.unselectedModeButton
                  }
                  // onClick={() => navigate("/sell-3d/pendingapprovals")}
                  onClick={() =>
                    handleNavigationClick("/sell-3d/pendingapprovals")
                  }
                >
                  Pending [<span>{pendingProducts}</span>]
                </RButton>
                <RButton
                  className="wrapped-button me-1 my-1 py-2"
                  style={
                    type === "drafts"
                      ? styles.selectedModeButton
                      : styles.unselectedModeButton
                  }
                  // onClick={() => navigate("/sell-3d/drafts")}
                  onClick={() => handleNavigationClick("/sell-3d/drafts")}
                >
                  Draft [<span>{draftProducts}</span>]
                </RButton>
              </Card>
            </Col>
          </Row>
          <Row className="px-3">
            {/* <h3 className="text-white pb-lg-2 mx-lg-4 ps-2 ps-lg-3 pt-lg-5 subtitle">My Products</h3> */}
            {/* <Row className="row-cols-5 pb-lg-2 mx-lg-4 ps-lg-3 pt-lg-5">
            {uploadedProducts.map((item, index) => {
              return (
                <ZCard
                  key={item}
                  index={index}
                  item={item}
                  type="myproducts"
                />
              );
            })}
          </Row> */}
            {uploadedProducts?.map((uploadedProduct) => (
              <ProductCard item={uploadedProduct} type={productType} />
            ))}
            {!uploadedProducts && <Loader />}
            {uploadedProducts?.length === 0 && (
              <Container
                className="d-flex w-100 justify-content-center align-items-center home-background-categories"
                fluid
                style={styles.emptyPurchaseListContainer}
              >
                <div>
                  <div className="d-flex justify-content-center">
                    <Image
                      src={emptycart}
                      alt="Empty Cart"
                      style={{ width: 200 }}
                    />
                  </div>
                  <div className="d-flex justify-content-center mt-5 mb-5">
                    <h4 className="text-primary m-1">OOPS ! </h4>
                    <h4 className="text-white m-1">
                      You have not uploaded any product yet
                    </h4>
                  </div>
                  <div className="d-flex justify-content-center mt-5 mb-5">
                    <Link
                      // to="/sell-3d/uploadproducts"
                      onClick={() =>
                        handleNavigationClick("/sell-3d/uploadproducts")
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <RButton
                        dark={true}
                        className="h-100 explore-button-no-neo btn btn-ptimary"
                        size="lg"
                      >
                        UPLOAD PRODUCTS
                      </RButton>
                    </Link>
                  </div>
                </div>
              </Container>
            )}
          </Row>
        </Container>
      </Breakpoint>
    </React.Fragment>
  );
};

const styles = {
  mainContainer: {
    minHeight: "100vh",
    paddingTop: 100,
  },
  emptyPurchaseListContainer: {
    minHeight: "80vh",
  },
  progressBarContainer: {
    minHeight: 100,
  },
  cartCard: {
    height: 500,
  },
  cardAction: {
    backgroundColor: "#2E2E2E",
  },
  underline: {
    height: 1,
    backgroundColor: "white",
  },
  youSavedColor: {
    color: "#58D31B",
  },
  selectedModeButton: {
    width: "130px",
    backgroundColor: "#fff",
    border: "none",
    color: "black",
  },
  unselectedModeButton: {
    width: "130px",
    backgroundColor: "#0D0D0D",
    border: "none",
    color: "white",
  },
};
export default MyProducts;

import * as authActions from "actions/AuthActions";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { Spinner } from "react-bootstrap";

export default function PayPalButtonWrapper({ order, setShowPaypalCheckout }) {
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PaypalCheckout
        order={order}
        setShowPaypalCheckout={setShowPaypalCheckout}
      />
    </PayPalScriptProvider>
  );
}

function PaypalCheckout({ order, setShowPaypalCheckout }) {
  const [{ isPending }] = usePayPalScriptReducer();

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const createPaypalOrder = (data, actions) => {
    //consolelog({ order });
    return order.paypal_order_id;
  };

  const onPaypalApprove = (data, actions) => {
    return actions.order.capture().then(async (details) => {
      //consolelog({ details });
      details["payment_method_used"] = "PAYPAL";
      const response = await authActions.upgradePaypalOrder(details);
      //consolelog({ response });
      // const name = details.payer.name.given_name;
      // alert(`Transaction completed by ${name}`);
      // navigate(`/cart/thankyou?orderId=${response.order_id}`)
      handleNavigationClick(`/cart/thankyou?orderId=${response.order_id}`);
    });
  };

  return (
    <>
      {isPending ? (
        <Spinner
          className="me-2"
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : null}
      <PayPalButtons
        createOrder={createPaypalOrder}
        onApprove={onPaypalApprove}
        onCancel={() => {
          alert("Transaction Cancelled!");
          setShowPaypalCheckout(false);
        }}
        onError={() => {
          alert("Transaction Failed!");
        }}
      />
    </>
  );
}

import {
  znanyecomparelogo,
  cgtradercomparelogo,
  sketchfabcomparelogo,
  turbosquidcomparelogo
} from "assets";
import { Col, Container, Image, Row, Card, Button, Table } from "react-bootstrap";
import { FcCheckmark } from "react-icons/fc"
import { AiOutlineClose, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";


const MobileTableComparison = () => {
  const comparison = [
    {
      id: 1,
      header: "Upload Limit Per Month",
      text1: "Unlimited",
      text2: "Unlimited",
      text3: "10",
      text4: "Unlimited",
    },
    {
      id: 2,
      header: "Upload Size Per Model",
      text1:
        <div className="d-flex justify-content-start align-items-center">
          <div>
            500MB
            {/* <span className="body-text2" style={{ color: "#666666" }}><br />( Beta )</span> */}
          </div>

        </div>,
      text2: "5.5 GB",
      text3: "100 MB",
      text4: "-",
    },
    {
      id: 3,
      header: "Royalty Rate",
      text1: "upto 91 %",
      text2: "70 - 80 %",
      text3: "88 %",
      text4: "40 - 60 %",
    },
    {
      id: 4,
      header: "3D Viewer to showcase your model",
      text1: <FcCheckmark size={25} />,
      text2: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text3: <FcCheckmark size={25} />,
      text4: <AiOutlineClose style={{ color: "red" }} size={25} />,
    },
    {
      id: 5,
      header: "Editor for 3D viewer",
      text1: <FcCheckmark size={25} />,
      text2: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text3: <FcCheckmark size={25} />,
      text4: <AiOutlineClose style={{ color: "red" }} size={25} />,
    },
    {
      id: 6,
      header: "Able to upload renders",
      text1: <FcCheckmark size={25} />,
      text2: <FcCheckmark size={25} />,
      text3: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text4: <FcCheckmark size={25} />,
    },
    {
      id: 7,
      header: "Analytics",
      text1:
        <div className="d-flex justify-content-start align-items-start flex-wrap nowrap" >
          <FcCheckmark size={25} />
          {/* <span className="body-text2 align-self-end" style={{ color: "#666666"}}>
            &nbsp;( Beta )</span> */}
        </div>,
      text2: <FcCheckmark size={25} />,
      text3: <FcCheckmark size={25} />,
      text4: <AiOutlineClose style={{ color: "red" }} size={25} />,
    },
    {
      id: 8,
      header: "Marketing Campaign for your models",
      text1: <FcCheckmark size={25} />,
      text2: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text3: <FcCheckmark size={25} />,
      text4: <AiOutlineClose style={{ color: "red" }} size={25} />,
    },
    {
      id: 9,
      header: "Quality Inspection for 3D model",
      text1: <FcCheckmark size={25} />,
      text2: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text3: <AiOutlineClose style={{ color: "red" }} size={25} />,
      text4: <FcCheckmark size={25} />,
    },
  ]
  return (
    <>
      <div className="w-100" style={tableStyles.container}>
        <Table style={tableStyles.table}>
          <thead className="mb-2" style={tableStyles.thead}>
            <th className="mx-4" style={tableStyles.thBlank}></th>
            <th className="mx-4" style={tableStyles.thZnanye}><Image className="py-0" src={znanyecomparelogo} style={tableStyles.thImages} /><br />(free version)</th>
            <th className="mx-4" style={tableStyles.thCompetitors} ><Image className="py-0" src={cgtradercomparelogo} style={tableStyles.thImages} /></th>
            <th className="mx-4" style={tableStyles.thCompetitors} ><Image className="py-0" src={sketchfabcomparelogo} style={tableStyles.thImages} /></th>
            <th className="mx-4" style={tableStyles.thCompetitors} ><Image className="py-0" src={turbosquidcomparelogo} style={tableStyles.thImages} /></th>
          </thead>
          <tbody>
            {comparison.map((company) => {
              return (
                <>
                  <tr style={tableStyles.tr}>
                    <td style={tableStyles.trHeader}>{company.header}</td>
                    <td style={tableStyles.trZnanye}>{company.text1}</td>
                    <td style={tableStyles.trCompetitors}>{company.text2}</td>
                    <td style={tableStyles.trCompetitors}>{company.text3}</td>
                    <td style={tableStyles.trCompetitors}>{company.text4}</td>
                  </tr>

                </>

              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

const tableStyles = {
  container: {
    overflowX: 'scroll',
    backgroundColor: "#141415",
  },
  table: {
    color: "white",
    verticalAlign: "middle",
    borderCollapse: 'separate',
    borderSpacing: '0px 20px',
    position: 'relative',
  },
  thead: {
    verticalAlign: "middle",
    backgroundColor: '#0D0D0D',
  },
  thBlank: {
    position: "sticky",
    top: 0,
    left: 0,
    padding: 40 + 'px',
    minWidth: 100 + 'px',
    backgroundColor: '#141415',
    borderTop: '2px solid #3D3D3D',
    borderBottom: '2px solid #3D3D3D',
    zIndex: 2,
  },
  thCompetitors: {
    position: 'sticky',
    zIndex: 1,
    backgroundColor: '#141415',
    textAlign: 'center',
    borderTop: '2px solid #3D3D3D',
    borderBottom: '2px solid #3D3D3D'
  },
  thZnanye: {
    position: "sticky",
    left: 102,
    color: "#666666",
    fontSize: 12 + 'px',
    textAlign: 'center',
    minWidth: 130 + 'px',
    backgroundColor: '#141415',
    borderTop: '2px solid #3D3D3D',
    borderBottom: '2px solid #3D3D3D',
    zIndex: 2
  },
  thImages: {
    width: 100 + 'px'
  },
  tr: {
    backgroundColor: '#141415',
    borderColor: '#3D3D3D',
    borderWidth: 1 + 'px'
  },
  trHeader: {
    position: "sticky",
    left: 0,
    color: "#666666",
    minWidth: 100 + 'px',
    backgroundColor: '#141415',
    textAlign: 'left',
    borderTop: '2px solid #3D3D3D'
  },
  trZnanye: {
    position: "sticky",
    left: 102,
    minWidth: 130 + 'px',
    backgroundColor: '#141415',
    textAlign: 'left',
    borderTop: '2px solid #3D3D3D'
  },
  trCompetitors: {
    minWidth: 130 + 'px',
    backgroundColor: '#141415',
    textAlign: 'left',
    borderTop: '2px solid #3D3D3D'
  }
}

export default MobileTableComparison;